import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';
import { apiClientInstance } from '~services/auth/ApiClientInstance';

import { ShowErrorAction } from '~store/infra/errors';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import ExcelSourceFields, { ExcelSourceFieldsType } from '~enums/fields/excelSource';

import { SortDescriptor } from "@progress/kendo-data-query";
import { DataRequest } from '~models/dataRequests';
import ExcelSourceGridItem from '~models/excelSource/excelSourceGridItem';
import ExcelSourceCreateModel from '~models/excelSource/excelSourceCreateModel';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';
import { FilterHandler } from '~models/filters';


export interface ManagementExcelSourcesState {
    isLoading: boolean;
    sources: Array<ExcelSourceGridItem>;
    count: number;
    skip: number;
    updatedField?: ExcelSourceFieldsType;
    createStatus: ServerOperationStatus;
    updateStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface ExcelSourcesRequestAction {
    type: 'MANAGEMENT_EXCELSOURCES_REQUEST';
    skip: number;
}
interface ExcelSourcesReceiveAction {
    type: 'MANAGEMENT_EXCELSOURCES_RECEIVE';
    sources: Array<ExcelSourceGridItem>;
    count: number;
}
interface ExcelSourcesErrorAction {
    type: 'MANAGEMENT_EXCELSOURCES_ERROR';
}

interface ExcelSourcesCreateSendAction {
    type: 'MANAGEMENT_EXCELSOURCES_CREATE_SEND';
}
interface ExcelSourcesCreateSuccessAction {
    type: 'MANAGEMENT_EXCELSOURCES_CREATE_SUCCESS';
    newItem: ExcelSourceGridItem;
}
interface ExcelSourcesCreateErrorAction {
    type: 'MANAGEMENT_EXCELSOURCES_CREATE_ERROR';
}

interface ExcelSourcesUpdateSendAction {
    type: 'MANAGEMENT_EXCELSOURCES_UPDATE_SEND';
    field: ExcelSourceFieldsType;
}
interface ExcelSourcesUpdateSuccessAction {
    type: 'MANAGEMENT_EXCELSOURCES_UPDATE_SUCCESS';
    object: ExcelSourceGridItem;
}
interface ExcelSourcesUpdateErrorAction {
    type: 'MANAGEMENT_EXCELSOURCES_UPDATE_ERROR';
}

interface ExcelSourcesDeleteStartAction {
    type: 'MANAGEMENT_EXCELSOURCES_DELETE_START';
}
interface ExcelSourcesDeleteSendAction {
    type: 'MANAGEMENT_EXCELSOURCES_DELETE_SEND';
}
interface ExcelSourcesDeleteSuccessAction {
    type: 'MANAGEMENT_EXCELSOURCES_DELETE_SUCCESS';
}
interface ExcelSourcesDeleteCancelAction {
    type: 'MANAGEMENT_EXCELSOURCES_DELETE_CANCEL';
}
interface ExcelSourcesDeleteErrorAction {
    type: 'MANAGEMENT_EXCELSOURCES_DELETE_ERROR';
}

type KnownAction = ExcelSourcesRequestAction | ExcelSourcesReceiveAction | ExcelSourcesErrorAction
    | ExcelSourcesCreateSendAction | ExcelSourcesCreateSuccessAction | ExcelSourcesCreateErrorAction
    | ExcelSourcesUpdateSendAction | ExcelSourcesUpdateSuccessAction | ExcelSourcesUpdateErrorAction
    | ExcelSourcesDeleteStartAction | ExcelSourcesDeleteSendAction
    | ExcelSourcesDeleteSuccessAction | ExcelSourcesDeleteCancelAction
    | ExcelSourcesDeleteErrorAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestSources: (skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExcelSources && !appState.managementExcelSources.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<ExcelSourceGridItem>>('/v1/excel-sources/search', 'POST', {}, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ManagementExcelSources}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ManagementExcelSources}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'MANAGEMENT_EXCELSOURCES_RECEIVE', sources: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'MANAGEMENT_EXCELSOURCES_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_EXCELSOURCES_REQUEST', skip: skip, });
        }
    },
    updateField: (id: number, serverField: ExcelSourceFieldsType, value: any, onSuccess?: () => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExcelSources) {
            apiClientInstance.fetchRequest(
                `v1/excel-sources/${id}`,
                'PATCH',
                { field: serverField, value: value }
            )
                .then((data) => {
                    let source = {...appState.managementExcelSources?.sources.find(x => x.excelSourceConfigId === id) ?? {} as ExcelSourceGridItem};

                    switch (serverField) {
                        case ExcelSourceFields.SourceName:
                            source.sourceName = (data as FieldUpdateResult<string>).value;
                            break;
                        case ExcelSourceFields.TableInv:
                            source.tableInv = (data as FieldUpdateResult<string>).value;
                            break;
                        case ExcelSourceFields.TableGeneralInfo:
                            source.tableGeneralInfo = (data as FieldUpdateResult<string>).value;
                            break;
                        case ExcelSourceFields.TableSkids:
                            source.tableSkids = (data as FieldUpdateResult<string>).value;
                            break;
                        case ExcelSourceFields.FieldEmfId:
                            source.fieldEmfId = (data as FieldUpdateResult<string>).value;
                            break;
                        case ExcelSourceFields.ItemType:
                            source.itemType = (data as FieldUpdateResult<string>).value;
                            break;
                        case ExcelSourceFields.SkidType:
                            source.skidType = (data as FieldUpdateResult<string>).value;
                            break;
                        case ExcelSourceFields.ShipmentType:
                            source.shipmentType = (data as FieldUpdateResult<string>).value;
                            break;
                        case ExcelSourceFields.MaxRecords:
                            source.maxRecords = (data as FieldUpdateResult<number>).value;
                            break;
                    }

                    !!onSuccess && onSuccess();

                    dispatch({ type: 'MANAGEMENT_EXCELSOURCES_UPDATE_SUCCESS', object: source, });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_EXCELSOURCES_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_EXCELSOURCES_UPDATE_SEND', field: serverField });
        }
    },
    createSource: (model: ExcelSourceCreateModel, onSuccess?: (result: ExcelSourceGridItem) => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExcelSources) {
            apiClientInstance.fetchRequest<number>(
                `v1/excel-sources/`,
                'POST',
                { ...model }
            )
                .then((data) => {
                    let newItem = {...model, excelSourceConfigId: data,} as ExcelSourceGridItem;

                    !!onSuccess && onSuccess(newItem);

                    dispatch({ type: 'MANAGEMENT_EXCELSOURCES_CREATE_SUCCESS', newItem: newItem });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_EXCELSOURCES_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_EXCELSOURCES_CREATE_SEND' });
        }
    },
    startDeleteSource: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExcelSources) {
            dispatch({ type: 'MANAGEMENT_EXCELSOURCES_DELETE_START' });
        }
    },
    submitDeleteSource: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExcelSources) {
            apiClientInstance.fetchRequest(
                `/v1/excel-sources/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'MANAGEMENT_EXCELSOURCES_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'MANAGEMENT_EXCELSOURCES_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_EXCELSOURCES_DELETE_SEND' });
        }
    },
    cancelDeleteSource: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExcelSources) {
            dispatch({ type: 'MANAGEMENT_EXCELSOURCES_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementExcelSourcesState = {
    isLoading: false,
    sources: [],
    count: 0,
    skip: 0,
    createStatus: ServerOperationStatus.NONE,
    updateStatus: ServerOperationStatus.NONE,
    deleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementExcelSourcesState> = (state: ManagementExcelSourcesState | undefined, incomingAction: Action): ManagementExcelSourcesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_EXCELSOURCES_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'MANAGEMENT_EXCELSOURCES_RECEIVE':
            return {
                ...state,
                sources: action.sources,
                count: action.count,
                isLoading: false,
            };
        case 'MANAGEMENT_EXCELSOURCES_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'MANAGEMENT_EXCELSOURCES_UPDATE_SEND':
            return {
                ...state,
                updatedField: action.field,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_EXCELSOURCES_UPDATE_SUCCESS':
            {
                let list = [...state.sources];
                let index = state.sources.findIndex(x => x.excelSourceConfigId === action.object.excelSourceConfigId);

                list[index] = action.object;

                return {
                    ...state,
                    sources: list,
                    updatedField: undefined,
                    updateStatus: ServerOperationStatus.SUCCESS
                };
            };
        case 'MANAGEMENT_EXCELSOURCES_UPDATE_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_EXCELSOURCES_CREATE_SEND':
            return {
                ...state,
                createStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_EXCELSOURCES_CREATE_SUCCESS':
            return {
                ...state,
                createStatus: ServerOperationStatus.SUCCESS,
                sources: [...state.sources, action.newItem],
            };
        case 'MANAGEMENT_EXCELSOURCES_CREATE_ERROR':
            return {
                ...state,
                createStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_EXCELSOURCES_DELETE_START':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.READY,
            };
        case 'MANAGEMENT_EXCELSOURCES_DELETE_SEND':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_EXCELSOURCES_DELETE_CANCEL':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.NONE,
            };
        case 'MANAGEMENT_EXCELSOURCES_DELETE_SUCCESS':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'MANAGEMENT_EXCELSOURCES_DELETE_ERROR':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};