import { PersonBase } from "~models/person/personBase";

const parsePersonString = (person?: PersonBase) => {
    return !!person ? 
        `${person.firstName} ${person.lastName}`.replaceAll('  ', ' ').trim() : 
        '';
};

export {
    parsePersonString
}