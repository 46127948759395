import { Grid } from '@mui/material';
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import AMWGridFilterToolbar from '~components/datagrid/AMWGridFilterToolbar';
import { AMWGridFilterToolbarWrapperProps } from '~components/datagrid/types';
import ProjectsFilterPanel from './ProjectsFilterPanel';
import LocalizationKeys from '~enums/localizationKeys';


type ProjectsToolbarProps = {
    onNewProjectClick: () => void,
    onDeleteProjectClick: () => void,
} & AMWGridFilterToolbarWrapperProps;

const ProjectsToolbar = (props: ProjectsToolbarProps) => {
    const localization = useLocalization();

    return (
        <AMWGridFilterToolbar {...props} filterPanel={ProjectsFilterPanel}>
            <Grid item className={"ButtonGroup"}>
                <Button
                    fillMode={'flat'}
                    className={"Button--bold Button--orange"}
                    onClick={props.onNewProjectClick}
                    disabled={props.isGridBusy}
                    size={"large"}
                    icon={"plus"}
                    title={localization.toLanguageString(LocalizationKeys.Projects.Grid.Toolbar.NewProject, "%New project")}
                />
            </Grid>

            <Grid item className={"ButtonGroup"}>
                <Button
                    onClick={props.onDeleteProjectClick}
                    disabled={!props.selectedItem || props.isGridBusy}
                    icon={"delete"}
                    title={localization.toLanguageString(LocalizationKeys.Shared.Buttons.Delete, "%Delete")}
                />
            </Grid>
        </AMWGridFilterToolbar>
    );
};

export default ProjectsToolbar;