import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import LocalizationKeys from '~enums/localizationKeys';
import AMWDialog from '~controls/AMWDialog';

type ConfirmationDialogProps = {
    open: boolean;
    text: string;
    title: string;
    onSubmit: () => any;
    onCancel: () => any;
}

const ConfirmationDialog : React.FunctionComponent<ConfirmationDialogProps> = props => {
    let localization = useLocalization();

    return (
        <AMWDialog
            open={props.open}
            onClose={props.onCancel}
            titleContent={
                <React.Fragment>
                    <span>{props.title}</span>

                    <IconButton onClick={props.onCancel}>
                        <CloseIcon></CloseIcon>
                    </IconButton>
                </React.Fragment>
            }
            content={
                <Typography>
                    {props.text}
                </Typography>
            }
            actionsContent={
                <React.Fragment>
                    <Button onClick={props.onSubmit}>
                        {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Confirm, "Confirm")}
                    </Button>

                    <Button onClick={props.onCancel}>
                        {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Cancel, "Cancel")}
                    </Button>
                </React.Fragment>
            }
        />
    );
}

export default ConfirmationDialog;