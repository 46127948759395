import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '~store/ApplicationState';
import * as CRMStore from '~store/pages/mainViews/crm';
import * as UsersStore from '~store/auth/user';
import { v4 as uuidv4, v5 as uuidgen } from 'uuid';

import CRMCalendarGridFilters from '~enums/gridFilters/crmCalendar';
import { FilterList } from '~models/filters';

import { Grid } from '@mui/material';
import {
	Scheduler,
	MonthView,
	SchedulerViewChangeEvent,
	SchedulerDateChangeEvent,
	DayView,
	WeekView,
} from "@progress/kendo-react-scheduler";

import CalendarFilterPanel from './CalendarFilterPanel';
import { CalendarEventTemplate } from './CalendarEventTemplate';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import CRMCalendarScreenSettings from '~enums/screenSettings/crmCalendar';


const calendarNamespace = uuidv4();


type CalendarProps = {
	filters: FilterList;
	settings: {[key:string]: any};
} &
	CRMStore.CalendarTabState &
	typeof CRMStore.calendarActionCreators &
    typeof UsersStore.actionCreators;

type CalendarState = {
	userIds: Array<number>;
	company: string;
	description: string;
	date: Date;
	viewMode: string;
	showAppointments: boolean;
	showTasks: boolean;
};

export interface CalendarEventItem {
    id: string;
    title: string;
    description: string;
    start: Date;
    end: Date;
}

class Calendar extends React.Component<CalendarProps, CalendarState> {
	constructor(props: Readonly<CalendarProps>) {
		super(props);
		this.state = {
			userIds: this.props.filters[CRMCalendarGridFilters.OwnedBy]?.value ?? [],
			company: this.props.filters[CRMCalendarGridFilters.Company]?.value ?? '',
			description: this.props.filters[CRMCalendarGridFilters.Description]?.value ?? '',
			date: new Date(),
			viewMode: this.props.settings[CRMCalendarScreenSettings.ViewMode] ?? 'day',
			showAppointments: this.props.settings[CRMCalendarScreenSettings.ShowAppointments] ?? true,
			showTasks: this.props.settings[CRMCalendarScreenSettings.ShowTasks] ?? true,
		};
	}

	public componentDidMount() {
		this.filterApply();
	}

	private handleViewChange = (event: SchedulerViewChangeEvent) => {
		this.setState({
			viewMode: event.value,
		}, () => {
			this.props.updateUserPreferences({
				[`${UserPreferencePage.CRMCalendar}-${UserPreferenceKey.Settings}`]:
					{...this.props.settings, [CRMCalendarScreenSettings.ViewMode]: this.state.viewMode}
			});
			this.filterApply();
		});
	}

	private handleDateChange = (event: SchedulerDateChangeEvent) => {
		this.setState({
			date: event.value,
		}, () => {
			this.filterApply();
		});
	}

	private filterApply() {
		this.props.requestData(this.parseFilters)
	}

	private filterReset() {
		this.setState({
			userIds: [],
			company: '',
			description: '',
		}, () => {
			this.props.requestData(this.parseFilters);
		})
	}

	private get parseFilters() : FilterList {
		return {
			[CRMCalendarGridFilters.OwnedBy]: { value: this.state.userIds, },
			[CRMCalendarGridFilters.Company]: { value: this.state.company },
			[CRMCalendarGridFilters.Description]: { value: this.state.description },
			[CRMCalendarGridFilters.DateFrom]: { value: this.serverDateFrom },
			[CRMCalendarGridFilters.DateTo]: { value: this.serverDateTo },
		};
	}

	private get serverDateFrom() {
		let date = this.state.date;
		return this.state.viewMode === 'day' ?
			new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0) :
			this.state.viewMode === 'week' ?
				new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay(), 0, 0, 0) :
				new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
	}

	private get serverDateTo() {
		let date = this.state.date;
		return this.state.viewMode === 'day' ?
			new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59) :
			this.state.viewMode === 'week' ?
				new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 6, 23, 59, 59) :
				new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
	}

	private get eventData() {
		let events: Array<CalendarEventItem> = [];

		if (this.state.showAppointments) {
			for (let x of this.props.appointments) {
				events.push({
					id: uuidgen( `Appointment-${x.appointmentId}`, calendarNamespace),
					title: x.subject,
					description: x.details,
					start: x.startDate,
					end: x.endDate,
				})
			}
		}

		if (this.state.showTasks) {
			for (let x of this.props.tasks) {
				events.push({
					id: uuidgen( `Task-${x.taskId}`, calendarNamespace),
					title: x.subject,
					description: x.details,
					start: x.startDate,
					end: x.dueDate,
				})
			}
		}

		return events;
	}

	private updateUserIds(ids: Array<number>) {
		this.setState({
			userIds: ids,
		});
	}

	private updateCompany(company: string) {
		this.setState({
			company: company,
		});
	}

	private updateDescription(description: string) {
		this.setState({
			description: description,
		});
	}

	private updateShowAppointments(show: boolean) {
		this.setState({
			showAppointments: show,
		});
	}

	private updateShowTasks(show: boolean) {
		this.setState({
			showTasks: show,
		});
	}

	render() {
		return (
			<Grid container className={"CRM__tab CRM__tab__root"}>
				<Grid item xs={2}>
					<CalendarFilterPanel
						userIds={this.state.userIds}
						updateUserIds={this.updateUserIds.bind(this)}
						company={this.state.company}
						updateCompany={this.updateCompany.bind(this)}
						description={this.state.description}
						updateDescription={this.updateDescription.bind(this)}
						showAppointments={this.state.showAppointments}
						updateShowAppointments={this.updateShowAppointments.bind(this)}
						showTasks={this.state.showTasks}
						updateShowTasks={this.updateShowTasks.bind(this)}
						applyFilters={this.filterApply.bind(this)}
						resetFilters={this.filterReset.bind(this)}
					/>
				</Grid>

				<Grid item xs={10}>
					<Scheduler
						item={CalendarEventTemplate}
						data={this.eventData}
						view={this.state.viewMode}
						onViewChange={this.handleViewChange}
						date={this.state.date}
						onDateChange={this.handleDateChange}
						height="100%"
					>
						{/* <TimelineView /> */}
						<DayView />
						<WeekView />
						<MonthView />
						{/* <AgendaView /> */}
					</Scheduler>
				</Grid>
			</Grid>
		);
	}
}

export default connect(
	(state: ApplicationState) => {
		return {
			...state.crm?.calendar,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.CRMCalendar}-${UserPreferenceKey.Filters}`] ?? {},
			settings: state.user?.userData?.preferences[`${UserPreferencePage.CRMCalendar}-${UserPreferenceKey.Settings}`] ?? {}
		};
	},
	{
		...CRMStore.calendarActionCreators,
		...UsersStore.actionCreators,
	},
)(Calendar as any);