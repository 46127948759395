const ManagementSupplierCostFields = {
    CostRef: "SupplierRouteField.CostRef",
    SupplierId: "SupplierRouteField.SupplierId",
    SupplierRole: "SupplierRouteField.SupplierRole",
    Option1: "SupplierRouteField.Option1",
    Rate1: "SupplierRouteField.Rate1",
    Option2: "SupplierRouteField.Option2",
    Rate2: "SupplierRouteField.Rate2",
    Option3: "SupplierRouteField.Option3",
    Rate3: "SupplierRouteField.Rate3",
} as const;

export type ManagementSupplierCostFieldsType = typeof ManagementSupplierCostFields[keyof typeof ManagementSupplierCostFields];

export default ManagementSupplierCostFields;