const ObjectType = Object.freeze({
    //TODO swap when entities are updated
    Appointment: "Appointment",
    Campaign: "Campaign",
    ClientFile: "ClientFile",
    Opportunity: "Opportunity",
    Party: "Party",
    PurchaseOrder: "PurchaseOrder",

    Expense: "Expense",
    Invoice: "Invoice",
    Quotation: "Quotation",
    Survey: "Survey",
    Task: "Task",
    TransactionOps: "TransactionOps",

    // Communication: "Communication",
    // ClientFile: "Enum.ObjectType.ClientFile",
    // Expense: "Enum.ObjectType.Expense",
    // Invoice: "Enum.ObjectType.Invoice",
    // Party: "Enum.ObjectType.Party",
    // Quotation: "Enum.ObjectType.Quotation",
    // Survey: "Enum.ObjectType.Survey",
    // TransactionOps: "Enum.ObjectType.TransactionOps",
    // Task: "Enum.ObjectType.Task",
    // Appointment: "Enum.ObjectType.Appointment",
    // Opportunity: "Enum.ObjectType.Opportunity",
    // Communication: "Enum.ObjectType.Communication",
    // Campaign: "Enum.ObjectType.Campaign",
});

export default ObjectType;