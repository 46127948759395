import React from 'react';
import ProjectsGridFilters from '~enums/gridFilters/projectsFilters';
import { FilterHandler } from '~models/filters';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import FilterContainer from '~components/filtering/FilterContainer';
import FilterItem from '~components/filtering/FilterItem';
import { FilterPanel, FilterPanelProps } from '~components/filtering/FilterPanel';
import DatePickerWrapper from '~components/controls/DatePickerWrapper';
import LocalizationKeys from '~enums/localizationKeys';
import TextBoxWrapper from '~components/controls/TextBoxWrapper';


type ProjectsFilterPanelProps = FilterPanelProps;

export default class ProjectsFilterPanel extends FilterPanel<ProjectsFilterPanelProps> {

    public render() {
        const localization = provideLocalizationService(this);

        return (
            <FilterContainer
                applyClassName={'Button--orange'}
                clearClassName={'Button--gray'}
                filterApply={this.props.filterApply}
                filterClear={this.props.filterClear}
            >

                <FilterItem>
                    <DatePickerWrapper
                        id="startDate"
                        name={ProjectsGridFilters.StartDate}
                        label={localization.toLanguageString(LocalizationKeys.Projects.Filters.StartDate, "%Start Date")}
                        value={FilterHandler.getDateValue(this.props.filters, ProjectsGridFilters.StartDate)}
                        onChange={this.onDateChange.bind(this)}
                        max={FilterHandler.getDateValue(this.props.filters, ProjectsGridFilters.EndDate) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="endDate"
                        name={ProjectsGridFilters.EndDate}
                        label={localization.toLanguageString(LocalizationKeys.Projects.Filters.EndDate, "%End Date")}
                        value={FilterHandler.getDateValue(this.props.filters, ProjectsGridFilters.EndDate)}
                        onChange={this.onDateChange.bind(this)}
                        min={FilterHandler.getDateValue(this.props.filters, ProjectsGridFilters.StartDate) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="projectNr"
                        name={ProjectsGridFilters.ProjectNr}
                        label={localization.toLanguageString(LocalizationKeys.Projects.Filters.ProjectNr, "%Project #")}
                        value={FilterHandler.getValue(this.props.filters, ProjectsGridFilters.ProjectNr)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="surveyor"
                        name={ProjectsGridFilters.Surveyor}
                        label={localization.toLanguageString(LocalizationKeys.Projects.Filters.Surveyor, "%Surveyor")}
                        value={FilterHandler.getValue(this.props.filters, ProjectsGridFilters.Surveyor)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="clientCode"
                        name={ProjectsGridFilters.ClientCode}
                        label={localization.toLanguageString(LocalizationKeys.Projects.Filters.ClientCode, "%Client code")}
                        value={FilterHandler.getValue(this.props.filters, ProjectsGridFilters.ClientCode)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="clientName"
                        name={ProjectsGridFilters.ClientName}
                        label={localization.toLanguageString(LocalizationKeys.Projects.Filters.ClientName, "%Client name")}
                        value={FilterHandler.getValue(this.props.filters, ProjectsGridFilters.ClientName)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

            </FilterContainer>
        );
    }
}
registerForLocalization(ProjectsFilterPanel as React.ComponentClass<any>);