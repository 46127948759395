import React from 'react';
import CRMRecentGridFilters from '~enums/gridFilters/crmRecentFilters';
import { FilterHandler } from '~models/filters';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import FilterContainer from '~components/filtering/FilterContainer';
import FilterItem from '~components/filtering/FilterItem';
import { FilterPanel, FilterPanelProps } from '~components/filtering/FilterPanel';
import OnDemandMultiSelect from '~controls/OnDemandMultiSelect';
import DatePickerWrapper from '~components/controls/DatePickerWrapper';
import LocalizationKeys from '~enums/localizationKeys';


type RecentFilterPanelProps = FilterPanelProps;

export default class RecentFilterPanel extends FilterPanel<RecentFilterPanelProps> {

    public render() {
        const localization = provideLocalizationService(this);

        return (
            <FilterContainer
                applyClassName={'Button--orange'}
                clearClassName={'Button--gray'}
                filterApply={this.props.filterApply}
                filterClear={this.props.filterClear}
            >
                <FilterItem>
                    <OnDemandMultiSelect
                        url="/v1/persons"  // TODO filter by permissions
                        // urlParams={{partyTypes: [PartyType.Division]}}
                        dataItemKey={'personId'}
                        textField={'fullName'}
                        id="ownedBy"
                        name={CRMRecentGridFilters.UserIds}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Recent.Filters.Users, "%Users")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMRecentGridFilters.UserIds, 'personId', 'fullName')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="dateFrom"
                        name={CRMRecentGridFilters.AddedDateFrom}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Recent.Filters.DateFrom, "%From")}
                        value={FilterHandler.getDateValue(this.props.filters, CRMRecentGridFilters.AddedDateFrom)}
                        onChange={this.onDateChange.bind(this)}
                        max={FilterHandler.getDateValue(this.props.filters, CRMRecentGridFilters.AddedDateTo) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="dateTo"
                        name={CRMRecentGridFilters.AddedDateTo}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Recent.Filters.DateTo, "%To")}
                        value={FilterHandler.getDateValue(this.props.filters, CRMRecentGridFilters.AddedDateTo)}
                        onChange={this.onDateChange.bind(this)}
                        min={FilterHandler.getDateValue(this.props.filters, CRMRecentGridFilters.AddedDateFrom) ?? undefined}
                    />
                </FilterItem>
            </FilterContainer>
        );
    }
}
registerForLocalization(RecentFilterPanel as React.ComponentClass<any>);