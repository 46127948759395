import React from 'react';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { getNonEmptyParams } from '~utils/urlUtils';
import equal from 'fast-deep-equal/react';
import { DropDownResponse, MultiSelectWrapperEvent, OnDemandMultiSelectProps, OnDemandMultiSelectState } from './types';

import {
    MultiSelectCloseEvent,
    MultiSelectFilterChangeEvent,
    MultiSelectHandle,
    MultiSelectOpenEvent,
    MultiSelectPageChangeEvent,
} from '@progress/kendo-react-dropdowns';

import MultiSelectWrapper from './MultiSelectWrapper';


class OnDemandMultiSelect extends React.Component<OnDemandMultiSelectProps, OnDemandMultiSelectState> {
    dataCaching: any[] = [];
    loadingData: any[] = [];
    pendingRequest: NodeJS.Timeout | null = null;
    requestStarted = false;
    emptyItem: any;
    pageSize: number;
    __drop?: MultiSelectHandle | null;

    constructor(props: OnDemandMultiSelectProps) {
        super(props);
        this.emptyItem = { [props.dataItemKey]: null, [props.textField]: "Loading..." };
        while (this.loadingData.length < props.pageSize) {
            this.loadingData.push({ ...this.emptyItem });
        }
        this.pageSize = props.pageSize;
        this.state = {
            data: [],
            skip: 0,
            total: 0,
            filter: "",
        };
    }

    componentDidUpdate(prevProps: OnDemandMultiSelectProps) {
        if(!equal(prevProps.urlParams, this.props.urlParams) || prevProps.url !== this.props.url) {
            this.resetData();
        }
    }

    componentWillUnmount() {
        this.resetCache();
    }

    public resetData() {
        !!this.pendingRequest && clearTimeout(this.pendingRequest);
        this.dataCaching = [];
        this.loadingData = [];
        this.requestStarted = false;
        this.setState({
            data: [],
            skip: 0,
            total: 0,
            filter: "",
        });
    }

    private resetCache() {
        this.dataCaching.length = 0;
    }

    private requestAllData() {
        let url = this.props.url;
        let params: {[key: string]: any} = {};
        params = {
            count: true,
        };
        !!this.props.urlParams && (
            params = {
                ...params,
                ...this.props.urlParams
            }
        );

        let urlParams = getNonEmptyParams(params);
        if (urlParams.toString() !== '')
            url += `?${urlParams}`;

        this.requestStarted = true;
        return apiClientInstance.fetchRequest<DropDownResponse>(url, 'GET')
            .then((json) => {
                const items = [] as any[];
                const localization = provideLocalizationService(this);

                json.data.forEach((element, index) => {
                    let item = element;
                    if (!!this.props.itemMapper) {
                        item = this.props.itemMapper(element);
                    }

                    if (!!this.props.localizeText) {
                        item[this.props.textField] =
                            localization.toLanguageString(item[this.props.textField],
                                item[this.props.textField]);
                    }
                    items.push(item);
                });

                this.requestStarted = false;

                return items;
            });
    }

    private requestData(skip: number, filter: string) {
        if (this.requestStarted) {
            !!this.pendingRequest && clearTimeout(this.pendingRequest);
            this.pendingRequest = setTimeout(() => {
                this.requestData(skip, filter);
            }, 500);
            return;
        }

        let url = this.props.url;
        let params: {[key: string]: any} = {};
        params = {
            skip: skip,
            pageSize: this.pageSize,
            count: true,
            filter: filter,
        };
        !!this.props.urlParams && (
            params = {
                ...params,
                ...this.props.urlParams
            }
        );

        let urlParams = getNonEmptyParams(params);
        if (urlParams.toString() !== '')
            url += `?${urlParams}`;

        this.requestStarted = true;
        apiClientInstance.fetchRequest<DropDownResponse>(url, 'GET')
            .then((json) => {
                const total = json.count;
                const items = [] as any[];
                const localization = provideLocalizationService(this);

                json.data.forEach((element, index) => {
                    let item = element;
                    if (!!this.props.itemMapper) {
                        item = this.props.itemMapper(element);
                    }

                    if (!!this.props.localizeText) {
                        item[this.props.textField] =
                            localization.toLanguageString(item[this.props.textField],
                                item[this.props.textField]);
                    }
                    items.push(item);
                    this.dataCaching[index + skip] = item;
                });

                if (skip === this.state.skip) {
                    this.setState({
                        data: items,
                        total: total,
                    });
                }

                this.requestStarted = false;
            });
    }

    private onFilterChange = (event: MultiSelectFilterChangeEvent) => {
        const filter = event.filter.value;

        if (event.nativeEvent.type === "click") return;

        this.resetCache();
        this.requestData(0, filter);

        this.setState({
            data: this.loadingData,
            skip: 0,
            filter: filter,
        });
    }

    private shouldRequestData(skip: number) {
        for (let i = 0; i < this.pageSize; i++) {
            if (!this.dataCaching[skip + i]) {
                return true;
            }
        }
        return false;
    }

    private getCachedData(skip: number) {
        const data = [];
        for (let i = 0; i < this.pageSize; i++) {
            data.push(this.dataCaching[i + skip] || { ...this.emptyItem });
        }
        return data;
    }

    private pageChange(event: MultiSelectPageChangeEvent) {
        const skip = event.page.skip;
        const filter = this.state.filter;

        if (this.shouldRequestData(skip)) {
            this.requestData(skip, filter);
        }

        const data = this.getCachedData(skip);

        this.setState({
            data: data,
            skip: skip,
        });
    }

    private onOpen(event: MultiSelectOpenEvent) {
        if (this.state.data.length === 0) {
            this.requestData(0, this.state.filter);
        }
    }

    private async onChange(event: MultiSelectWrapperEvent) {
        const value = event.value as Array<any>;
        if (value && value.length && value.some(x => x[this.props.textField] === this.emptyItem[this.props.textField])) return;

        if(!!this.props.onInputChange) {
            let promise = this.props.onInputChange({
                id: event.id ?? '',
                name: event.name ?? '',
                value: event.value,
                syntheticEvent: event.syntheticEvent,
                target: this.__drop,
            });
            if(!!promise) {
                await promise;
                this.__drop?.focus();
            } else {
                this.__drop?.focus();
            }
        }
    }

    private onClose(event: MultiSelectCloseEvent) {
        this.setState({
            filter: ''
        });
        !!this.props.onClose && this.props.onClose(event);
    }

    render() {
        let classes = 'OnDemandMultiSelect';
        if(!!this.props.className) classes += ` ${this.props.className}`;

        return (
            <MultiSelectWrapper
                ref={(drop) => {this.__drop = drop?.__input;}}
                {...this.props}
                className={classes}
                data-field={this.props.field}
                data={this.state.data}
                onOpen={this.onOpen.bind(this)}
                onClose={this.onClose.bind(this)}
                onInputChange={this.onChange.bind(this)}

                filterable={true}
                filter={this.state.filter}
                onFilterChange={this.onFilterChange.bind(this)}
                virtual={{
                    pageSize: this.pageSize,
                    skip: this.state.skip,
                    total: this.state.total,
                }}
                onPageChange={this.pageChange.bind(this)}

                getAllValues={this.requestAllData.bind(this)}
            />
        );
    }
}

registerForLocalization(OnDemandMultiSelect as React.ComponentClass<any>);

export default OnDemandMultiSelect;