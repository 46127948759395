import { useLocalization } from '@progress/kendo-react-intl';
import CRMCalendarGridFilters from '~enums/gridFilters/crmCalendar';
import OnDemandMultiSelect from '~controls/OnDemandMultiSelect';
import CheckboxWrapper from '~controls/CheckboxWrapper';
import { Button } from '@progress/kendo-react-buttons';
import TextBoxWrapper from '~components/controls/TextBoxWrapper';


type CalendarFilterPanelProps = {
    userIds: Array<number>;
    updateUserIds: (ids: Array<number>) => void;
    company: string;
    updateCompany: (company: string) => void;
    description: string;
    updateDescription: (description: string) => void;
    showAppointments: boolean;
    updateShowAppointments: (show: boolean) => void;
    showTasks: boolean;
    updateShowTasks: (show: boolean) => void;
    applyFilters: () => void;
    resetFilters: () => void;
};

export default function CalendarFilterPanel(props: CalendarFilterPanelProps) {
    const localization = useLocalization();

    return (
        <div className="CRM__Calendar__filters">
            <OnDemandMultiSelect
                url="/v1/persons"  // TODO filter by permissions
                // urlParams={{partyTypes: [PartyType.Division]}}
                dataItemKey={'personId'}
                textField={'fullName'}
                id="ownedBy"
                name={CRMCalendarGridFilters.OwnedBy}
                label={localization.toLanguageString("CRM.Calendar.Filters.OwnedBy", "Owned by")}
                value={props.userIds.map(x => {
                    return { personId: x, fullName: ' ' };
                })}
                pageSize={10}
                onInputChange={(event) => {
                    props.updateUserIds(event.value.map(x => x.id));
                }}
                enableSelectAll={true}
            />

            <TextBoxWrapper
                id="company"
                name={CRMCalendarGridFilters.Company}
                label={localization.toLanguageString("CRM.Calendar.Filters.Company", "Company")}
                value={props.company}
                onInputChange={(event) => {
                    props.updateCompany(event.value as string);
                }}
                clearable={true}
                onClearClick={() => {
                    props.updateCompany('');
                }}
            />

            <TextBoxWrapper
                id="description"
                name={CRMCalendarGridFilters.Description}
                label={localization.toLanguageString("CRM.Calendar.Filters.Description", "Description")}
                value={props.description}
                onInputChange={(event) => {
                    props.updateDescription(event.value as string);
                }}
                clearable={true}
                onClearClick={() => {
                    props.updateDescription('');
                }}
            />

            <CheckboxWrapper
                id="showAppointments"
                name={"ShowAppointments"}
                label={localization.toLanguageString("CRM.Calendar.Filters.ShowAppointments", "Show appointments")}
                value={props.showAppointments}
                onChange={(event) => {
                    props.updateShowAppointments(event.value);
                }}
            />

            <CheckboxWrapper
                id="showTasks"
                name={"ShowTasks"}
                label={localization.toLanguageString("CRM.Calendar.Filters.ShowTasks", "Show tasks")}
                value={props.showTasks}
                onChange={(event) => {
                    props.updateShowTasks(event.value);
                }}
            />

            <Button onClick={props.applyFilters}>
                {localization.toLanguageString("Shared.Buttons.Apply", "Apply")}
            </Button>

            <Button onClick={props.resetFilters}>
                {localization.toLanguageString("Shared.Buttons.Reset", "Reset")}
            </Button>
      </div>
  );
}