import React from 'react';

import InventoryGridFilters from '~enums/gridFilters/inventoryFilters';
import { FilterHandler } from '~models/filters';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';

import FilterContainer from '~components/filtering/FilterContainer';
import FilterItem from '~components/filtering/FilterItem';
import { FilterPanel, FilterPanelProps } from '~components/filtering/FilterPanel';
import LocalizationKeys from '~enums/localizationKeys';
import TextBoxWrapper from '~components/controls/TextBoxWrapper';


type InventoryPackagesFilterPanelOwnProps = FilterPanelProps;

export default class InventoryPackagesFilterPanel extends FilterPanel<InventoryPackagesFilterPanelOwnProps> {

    public render() {
        const localization = provideLocalizationService(this);

        return (
            <FilterContainer
                applyClassName={'Button--orange'}
                clearClassName={'Button--gray'}
                filterApply={this.props.filterApply}
                filterClear={this.props.filterClear}
            >
                <FilterItem>
                    <TextBoxWrapper
                        id="uniqueId"
                        name={InventoryGridFilters.PackageUniqueId}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Packages.Filters.PackageUniqueId, "%Unique ID/Object #")}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.PackageUniqueId)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="locationId"
                        name={InventoryGridFilters.PackageLocationId}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Packages.Filters.PackageLocationId, "%Location ID")}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.PackageLocationId)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="description"
                        name={InventoryGridFilters.PackageDescription}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Packages.Filters.PackageDescription, "%Description")}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.PackageDescription)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>
            </FilterContainer>
        );
    }
}
registerForLocalization(InventoryPackagesFilterPanel as React.ComponentClass<any>);