import * as signalR from "@microsoft/signalr";
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';
import { store } from '~src/App';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { buildApiUrl } from '~utils/urlUtils';

import Notification from "~models/notification/notification";
import { ShowErrorAction } from './errors';

export type NotificationsState = {
    isLoading: boolean,
    refreshRequired: boolean,
    notifications: Notification[],
    notificationCount: number,
    isMenuOpen: boolean,
}

const url = buildApiUrl('/hub/notifications/connect');

let connection: signalR.HubConnection | null = null;

// -----------------
// ---- ACTIONS ----
// -----------------

interface ConnectAction {
    type: 'NOTIFICATIONS_MENU_CONNECT';
    token: string;
}

interface DisconnectAction {
    type: 'NOTIFICATIONS_MENU_DISCONNECT';
}
const disconnectNotifications = () => {
    store.dispatch({type:'NOTIFICATIONS_MENU_DISCONNECT'});
};

interface CountUpdateAction {
    type: 'NOTIFICATIONS_MENU_COUNT_UPDATE';
    count: number;
}

interface RetrieveAction {
    type: 'NOTIFICATIONS_MENU_RETRIEVE_REQUEST',
}

interface RetrieveActionResult {
    type: 'NOTIFICATIONS_MENU_RETRIEVE_RESULT';
    notifications: Notification[];
}

interface CloseNotificationsAction {
    type: 'NOTIFICATIONS_MENU_CLOSE'
}

interface MarkAsReadAction {
    type: 'NOTIFICATIONS_MENU_MARK_AS_READ';
    id: number;
    status: boolean;
}

type KnownAction = ConnectAction | DisconnectAction | RetrieveAction | RetrieveActionResult |
    CloseNotificationsAction | MarkAsReadAction | CountUpdateAction |ShowErrorAction;

export const actionCreators = {
    connect: (accessToken: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.notificationMenu) {
            dispatch({ type: 'NOTIFICATIONS_MENU_CONNECT', token: accessToken });
        }
    },
    disconnect: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.notificationMenu) {
            dispatch({ type: 'NOTIFICATIONS_MENU_DISCONNECT' });
        }
    },
    openWindow: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.notificationMenu && !appState.notificationMenu.isLoading) {
            apiClientInstance.fetchRequest<Notification[]>('/v1/notifications/recent', 'GET')
                .then(data => {
                    data.forEach((notif) => notif.createdOn = new Date(notif.createdOn));

                    dispatch({ type: 'NOTIFICATIONS_MENU_RETRIEVE_RESULT', notifications: data });
                });

            dispatch({ type: 'NOTIFICATIONS_MENU_RETRIEVE_REQUEST' });
        }
    },
    closeWindow: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.notificationMenu) {
            dispatch({ type: 'NOTIFICATIONS_MENU_CLOSE' });
        }
    },
    markAsRead: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.notificationMenu) {
            const notification = appState.notificationMenu.notifications.find(x => x.notificationId === id);
            if (!!notification) {
                apiClientInstance.fetchRequest<boolean>(`v1/notifications/${id}`, 'POST')
                    .then((status) => {
                        dispatch({ type: 'NOTIFICATIONS_MENU_MARK_AS_READ', id: id, status: status });
                    })
                    .catch(err => {
                        dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err });
                    });
            }
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: NotificationsState = {
    isLoading: false,
    refreshRequired: true,
    notifications: [],
    notificationCount: 0,
    isMenuOpen: false,
 };

export const reducer: Reducer<NotificationsState> = (state: NotificationsState | undefined, incomingAction: Action): NotificationsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'NOTIFICATIONS_MENU_CONNECT':
            connection = new signalR.HubConnectionBuilder()
               .withUrl(url,
               {
                   accessTokenFactory: () => action.token,
                   transport: signalR.HttpTransportType.WebSockets,
                   skipNegotiation: true
               })
               .build();

            connection.on("initMessages", (notifCount: number) => {
                store.dispatch({
                    type: 'NOTIFICATIONS_MENU_COUNT_UPDATE',
                    count: notifCount
                });
            });

            connection.start()
                .then(() => connection?.stream('StreamNotifications').subscribe({
                    next: (notifCount: number) => {
                        store.dispatch({
                            type: 'NOTIFICATIONS_MENU_COUNT_UPDATE',
                            count: notifCount
                        })
                    },
                    error: (err) => {
                        store.dispatch({
                            type: 'ERROR_MESSAGE_SHOW',
                            error: err
                        });
                        console.error(err);
                    },
                    complete: () => { }
                }))
                .catch((err) => {
                    store.dispatch({
                        type: 'ERROR_MESSAGE_SHOW',
                        error: err
                    });
                    console.error(err);
                });
            window.addEventListener('beforeunload', disconnectNotifications);

            return {
                ...state
            };

        case 'NOTIFICATIONS_MENU_DISCONNECT':
            connection?.stop();

            window.removeEventListener('beforeunload', disconnectNotifications);

            return {
                ...state
            };

        case 'NOTIFICATIONS_MENU_COUNT_UPDATE':
            return {
                ...state,
                refreshRequired: true,
                notificationCount: action.count
            };

        case 'NOTIFICATIONS_MENU_RETRIEVE_REQUEST':
            return {
                ...state,
                isLoading: true,
                notifications: []
            };

        case 'NOTIFICATIONS_MENU_RETRIEVE_RESULT':
            return {
                ...state,
                isLoading: false,
                notifications: action.notifications,
                refreshRequired: false,
                isMenuOpen: true
            };

        case 'NOTIFICATIONS_MENU_MARK_AS_READ':
            let updatedNotif = state.notifications.find(x => x.notificationId === action.id);
            let newNotifs = [...state.notifications];
            if (!!updatedNotif) {
                newNotifs[newNotifs.indexOf(updatedNotif)] = {...updatedNotif, isRead: action.status};
            }

            return {
                ...state,
                notifications: newNotifs,
                notificationCount: state.notificationCount + (action.status ? -1 : 1)
            };

        case 'NOTIFICATIONS_MENU_CLOSE':
            return {
                ...state,
                isMenuOpen: false,
            }
    }

    return state;
};