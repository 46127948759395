export default interface TariffRangeItem {
    tariffRangeId: number;
    tariffId: number;
    fromValue: number;
    toValue: number;
    rangeRate: number;
    rateFormula: string | undefined;
}

const getTariffRangeString = (item: TariffRangeItem) => {
    return `${item.fromValue} - ${item.toValue} (${item.rangeRate})`;
};
export {
    getTariffRangeString
};