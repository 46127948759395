const CompanyFields = Object.freeze({
    Code: "CompanyField.Code",
    LegalName: "CompanyField.LegalName",
    PartyType: "CompanyField.PartyType",
    PartyCategory: "CompanyField.PartyCategory",
    Source: "CompanyField.Source",
    Terms: "CompanyField.Terms",
    Currency: "CompanyField.Currency",
    WebViewInventory: "CompanyField.WebViewInventory",
    DefaultTaxType: "CompanyField.DefaultTaxType",
    VatNumber: "CompanyField.VatNumber",
    EinNumber: "CompanyField.EinNumber",
    LengthUnits: "CompanyField.LengthUnits",
    WeightUnits: "CompanyField.WeightUnits",
    VolumeUnits: "CompanyField.VolumeUnits",
    Deactivated: "CompanyField.Deactivated",
    InsuranceRequired: "CompanyField.InsuranceRequired",
    TaxExempt: "CompanyField.TaxExempt",
    BadPayer: "CompanyField.BadPayer",
    Warning: "CompanyField.Warning",
    BillingInstructions: "CompanyField.BillingInstructions",
    LMCode: "CompanyField.LMCode",
    LegalEntity: "CompanyField.LegalEntity",
});

export type CompanyFieldsType = typeof CompanyFields[keyof typeof CompanyFields];

export default CompanyFields;