import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { SortDescriptor } from "@progress/kendo-data-query";

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import InvoiceGridItem from '~models/invoice/invoiceGridItem';
import { InvoiceRequestResult } from '~models/invoice/invoiceRequestResult';
import { FilterHandler, FilterList } from '~models/filters';
import { ShowErrorAction } from '~store/infra/errors';
import FinancialsFilters from '~enums/gridFilters/financialsFilters';


export interface FinancialsState {
    isLoading: boolean;
    invoices: Array<InvoiceGridItem>;
    count: number;
    skip: number;
    totalSelected: number;
    totalOpen: number;
    totalPaid: number;
    totalOverdue: number;
    invoicesCloneStatus: ServerOperationStatus,
    invoicesFlagAsPaidStatus: ServerOperationStatus,
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface FinancialsRequestAction {
    type: 'FINANCIALS_REQUEST';
    skip: number;
}
interface FinancialsReceiveAction {
    type: 'FINANCIALS_RECEIVE';
    invoices: InvoiceGridItem[];
    count: number;
    totalSelected: number;
    totalOpen: number;
    totalPaid: number;
    totalOverdue: number;
}
interface FinancialsErrorAction {
    type: 'FINANCIALS_ERROR';
}

interface FinancialsCloneSend {
    type: 'FINANCIALS_CLONE_SEND';
}
interface FinancialsCloneSuccess {
    type: 'FINANCIALS_CLONE_SUCCESS';
}
interface FinancialsCloneError {
    type: 'FINANCIALS_CLONE_ERROR';
}

interface FinancialsFlagAsPaidSend {
    type: 'FINANCIALS_FLAGASPAID_SEND';
}
interface FinancialsFlagAsPaidSuccess {
    type: 'FINANCIALS_FLAGASPAID_SUCCESS';
}
interface FinancialsFlagAsPaidError {
    type: 'FINANCIALS_FLAGASPAID_ERROR';
}

type KnownAction =
    FinancialsRequestAction | FinancialsReceiveAction | FinancialsErrorAction
    | FinancialsCloneSend | FinancialsCloneSuccess | FinancialsCloneError
    | FinancialsFlagAsPaidSend | FinancialsFlagAsPaidSuccess | FinancialsFlagAsPaidError
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestInvoices: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.financials && !appState.financials.isLoading) {
            let finFilters = {...filters};

            if (!!finFilters[FinancialsFilters.EndDate]?.value) {
                const dateValue = new Date(finFilters[FinancialsFilters.EndDate].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                finFilters[FinancialsFilters.EndDate] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<InvoiceRequestResult>('/v1/invoices/search', 'POST', finFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.Financials}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.Financials}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.Financials}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((inv) => {
                        !!inv.updatedDate && (inv.updatedDate = new Date(inv.updatedDate));
                        !!inv.billingDate && (inv.billingDate = new Date(inv.billingDate));
                        !!inv.dueDate && (inv.dueDate = new Date(inv.dueDate));
                    });

                    dispatch({
                        type: 'FINANCIALS_RECEIVE',
                        invoices: data.data,
                        count: data.count,
                        totalSelected: data.totalSelected,
                        totalOpen: data.totalOpen,
                        totalPaid: data.totalPaid,
                        totalOverdue: data.totalOverdue,
                    });
                })
                .catch(err => {
                    dispatch({ type: 'FINANCIALS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'FINANCIALS_REQUEST', skip: skip, });
        }
    },
    cloneInvoices: (ids: Array<number>):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipping) {
            apiClientInstance.fetchRequest(
                '/v1/invoices/clone',
                'POST',
                {
                    invoiceIds: ids
                }
            )
                .then((newId) => {
                    dispatch({ type: 'FINANCIALS_CLONE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'FINANCIALS_CLONE_SEND' });
        }
    },
    flagAsPaidInvoices: (ids: Array<number>):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipping) {
            apiClientInstance.fetchRequest(
                '/v1/invoices/paid',
                'POST',
                {
                    invoiceIds: ids
                }
            )
                .then(() => {
                    dispatch({ type: 'FINANCIALS_FLAGASPAID_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'FINANCIALS_FLAGASPAID_SEND' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: FinancialsState = {
    isLoading: false,
    invoices: [],
    count: 0,
    skip: 0,
    totalOpen: 0,
    totalOverdue: 0,
    totalPaid: 0,
    totalSelected: 0,
    invoicesCloneStatus: ServerOperationStatus.NONE,
    invoicesFlagAsPaidStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<FinancialsState> = (state: FinancialsState | undefined, incomingAction: Action): FinancialsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'FINANCIALS_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'FINANCIALS_RECEIVE':
            return {
                ...state,
                invoices: action.invoices,
                count: action.count,
                isLoading: false,
                totalSelected: action.totalSelected,
                totalOpen: action.totalOpen,
                totalOverdue: action.totalOverdue,
                totalPaid: action.totalPaid,
            };
        case 'FINANCIALS_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'FINANCIALS_CLONE_SEND':
            return {
                ...state,
                isLoading: true,
                invoicesCloneStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'FINANCIALS_CLONE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                invoicesCloneStatus: ServerOperationStatus.SUCCESS,
            };
        case 'FINANCIALS_CLONE_ERROR':
            return {
                ...state,
                isLoading: false,
                invoicesCloneStatus: ServerOperationStatus.ERROR,
            };

        case 'FINANCIALS_FLAGASPAID_SEND':
            return {
                ...state,
                isLoading: true,
                invoicesFlagAsPaidStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'FINANCIALS_FLAGASPAID_SUCCESS':
            return {
                ...state,
                isLoading: false,
                invoicesFlagAsPaidStatus: ServerOperationStatus.SUCCESS,
            };
        case 'FINANCIALS_FLAGASPAID_ERROR':
            return {
                ...state,
                isLoading: false,
                invoicesFlagAsPaidStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};