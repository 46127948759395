const ContactFields = Object.freeze({
    Phone: "ContactField.Phone",
    Mobile: "ContactField.Mobile",
    Email: "ContactField.Email",
    Fax: "ContactField.Fax",
    Web: "ContactField.Web",
    UseForGeneral: "ContactField.UseForGeneral",
    UseForMailing: "ContactField.UseForMailing",
    UseForBilling: "ContactField.UseForBilling",
    UseForShipping: "ContactField.UseForShipping",
});

export type ContactFieldsType = typeof ContactFields[keyof typeof ContactFields];

export default ContactFields;