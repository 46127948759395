import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { ShowErrorAction } from '~store/infra/errors';
import { v4 as uuidv4, v5 as uuid } from 'uuid';
import { ContainerUpdateSuccess } from '~store/modals/container';

import ShipmentFields, { ShipmentFieldsType } from '~enums/fields/shipment';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler } from '~models/filters';
import ShipmentDetails from '~models/shipment/shipmentDetails';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';
import ShipmentContainerItem from '~models/container/shipmentContainerItem';
import GroupageShipmentItem from '~models/shipment/groupageShipmentItem';
import AddressDetailsData from '~models/address/addressDetailsData';
import { SortDescriptor } from "@progress/kendo-data-query";
import { PartyListItem } from '~models/party/partyListItem';

const namespace = uuidv4();

export interface ShipmentDetailsContainers {
    isLoading: boolean;
    data: Array<ShipmentContainerItem>;
    count: number;
    skip: number;
    deleteStatus: ServerOperationStatus;
}

export interface GroupageShipments {
    isLoading: boolean;
    data: Array<GroupageShipmentItem>;
    count: number;
    skip: number;
}

export interface ShipmentDetailsState {
    isLoading: boolean;
    data: ShipmentDetails;
    updateStatus: ServerOperationStatus;
    updatedField?: ShipmentFieldsType;
    containers: ShipmentDetailsContainers;
    shipments: GroupageShipments;
}

const EmptyData: ShipmentDetails = {
    shipmentId: 0,
    clientFileId: null,
    jobId: '',
    isGroupage: false,
    uniqueId: '',
    status: '',
    transportMode: '',
    shipperId: null,
    shipperName: '',
    consigneeId: null,
    consigneeName: '',
    truckerId: null,
    truckerName: '',
    carrierId: null,
    carrierName: '',
    customsOfficeId: null,
    customsOfficeName: '',
    customsOfficeAddressId: null,
    customsOfficeAddress: undefined,
    forwardingAgentId: null,
    forwardingAgentName: '',
    forwardingAgentAddressId: null,
    forwardingAgentAddress: undefined,
    destAgentId: null,
    destAgentName: '',
    destAgentAddressId: null,
    destAgentAddress: undefined,
    etd: null,
    eta: null,
    deliveryCutOff: null,
    documentsCutOff: null,
    documentsFolder: '',
    groupageId: null,
    groupageUniqueId: '',
    reference: '',
    externalReference: '',
    voyage: '',
    vessel: '',
    harmonizationCode: '',
    pol: '',
    poe: '',
    pod: '',
    contentDescription: '',
    comments: '',
};


// -----------------
// ---- ACTIONS ----
// -----------------

interface RequestDataAction {
    type: 'SHIPMENT_REQUEST_DATA';
}
interface ReceiveDataAction {
    type: 'SHIPMENT_RECEIVE_DATA';
    data: ShipmentDetails;
}
interface ReceiveDataErrorAction {
    type: 'SHIPMENT_RECEIVE_DATA_ERROR';
}

interface UpdateDataRequestAction {
    type: 'SHIPMENT_UPDATE_DATA_REQUEST';
    field: ShipmentFieldsType;
}
interface UpdateDataAction {
    type: 'SHIPMENT_UPDATE_DATA';
    data: ShipmentDetails;
}
interface UpdateDataErrorAction {
    type: 'SHIPMENT_UPDATE_DATA_ERROR';
}

interface RequestContainers {
    type: 'SHIPMENT_CONTAINERS_REQUEST';
    skip: number;
}
interface ReceiveContainers {
    type: 'SHIPMENT_CONTAINERS_RECEIVE';
    containers: Array<ShipmentContainerItem>;
    count: number;
}
interface ReceiveContainersError {
    type: 'SHIPMENT_CONTAINERS_RECEIVE_ERROR';
}

interface ContainersDeleteStartAction {
    type: 'SHIPMENT_CONTAINERS_DELETE_START';
}
interface ContainersDeleteSendAction {
    type: 'SHIPMENT_CONTAINERS_DELETE_SEND';
}
interface ContainersDeleteSuccessAction {
    type: 'SHIPMENT_CONTAINERS_DELETE_SUCCESS';
}
interface ContainersDeleteCancelAction {
    type: 'SHIPMENT_CONTAINERS_DELETE_CANCEL';
}
interface ContainersDeleteErrorAction {
    type: 'SHIPMENT_CONTAINERS_DELETE_ERROR';
}

interface GroupageShipmentsRequestAction {
    type: 'SHIPMENT_GROUPAGE_REQUEST';
    skip: number;
}
interface GroupageShipmentReceiveAction {
    type: 'SHIPMENT_GROUPAGE_RECEIVE';
    shipments: Array<GroupageShipmentItem>;
    count: number;
}
interface GroupageShipmentReceiveErrorAction {
    type: 'SHIPMENT_GROUPAGE_RECEIVE_ERROR';
}

type KnownAction = RequestDataAction | ReceiveDataAction | ReceiveDataErrorAction
    | UpdateDataRequestAction | UpdateDataAction | UpdateDataErrorAction
    | RequestContainers | ReceiveContainers | ReceiveContainersError
    | ContainersDeleteStartAction | ContainersDeleteSendAction
    | ContainersDeleteSuccessAction | ContainersDeleteCancelAction
    | ContainersDeleteErrorAction
    | GroupageShipmentsRequestAction | GroupageShipmentReceiveAction | GroupageShipmentReceiveErrorAction
    | ContainerUpdateSuccess | ShowErrorAction;

export const actionCreators = {
    requestData: (shipmentId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipmentDetails && !appState.shipmentDetails.isLoading) {
            apiClientInstance.fetchRequest<ShipmentDetails>(`v1/shipping/${shipmentId}`)
                .then((data) => {
                    !!data.etd && (data.etd = new Date(data.etd));
                    !!data.eta && (data.eta = new Date(data.eta));
                    !!data.deliveryCutOff && (data.deliveryCutOff = new Date(data.deliveryCutOff));
                    !!data.documentsCutOff && (data.documentsCutOff = new Date(data.documentsCutOff));
                    dispatch({ type: 'SHIPMENT_RECEIVE_DATA', data: data });
                })
                .catch((err) => {
                    dispatch({ type: 'SHIPMENT_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'SHIPMENT_REQUEST_DATA' });
        }
    },
    updateField: (serverField: ShipmentFieldsType, value: any, selectedItem?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipmentDetails) {
            apiClientInstance.fetchRequest(
                `v1/shipping/${appState.shipmentDetails.data.shipmentId}`,
                'PATCH',
                { field: serverField, value: value }
            )
                .then((data) => {
                    let infoObj = {...(appState.shipmentDetails?.data ?? {} as ShipmentDetails)};

                    switch (serverField) {
                        case ShipmentFields.Status:
                            infoObj.status = (data as FieldUpdateResult<string>).value;
                            break;
                        case ShipmentFields.TransportMode:
                            infoObj.transportMode = (data as FieldUpdateResult<string>).value;
                            break;
                        case ShipmentFields.Shipper:
                            infoObj.shipperId = (data as FieldUpdateResult<number>).value;
                            infoObj.shipperName = (selectedItem as PartyListItem)?.legalName ?? '';
                            break;
                        case ShipmentFields.Consignee:
                            infoObj.consigneeId = (data as FieldUpdateResult<number>).value;
                            infoObj.consigneeName = (selectedItem as PartyListItem)?.legalName ?? '';
                            break;
                        case ShipmentFields.Trucker:
                            infoObj.truckerId = (data as FieldUpdateResult<number>).value;
                            infoObj.truckerName = (selectedItem as PartyListItem)?.legalName ?? '';
                            break;
                        case ShipmentFields.Carrier:
                            infoObj.carrierId = (data as FieldUpdateResult<number>).value;
                            infoObj.carrierName = (selectedItem as PartyListItem)?.legalName ?? '';
                            break;
                        case ShipmentFields.CustomsOffice:
                            let co = data as FieldUpdateResult<number>;
                            infoObj.customsOfficeId = co.value;
                            infoObj.customsOfficeName = (selectedItem as PartyListItem)?.legalName ?? '';
                            infoObj.customsOfficeAddressId = co.additionalInfo.addressId;
                            infoObj.customsOfficeAddress = co.additionalInfo.address;
                            break;
                        case ShipmentFields.CustomsOfficeAddress:
                            infoObj.customsOfficeAddressId = (data as FieldUpdateResult<number>).value;
                            infoObj.customsOfficeAddress = (selectedItem as AddressDetailsData) ?? undefined;
                            break;
                        case ShipmentFields.ForwardingAgent:
                            let fa = data as FieldUpdateResult<number>;
                            infoObj.forwardingAgentId = fa.value;
                            infoObj.forwardingAgentName = (selectedItem as PartyListItem)?.legalName ?? '';
                            infoObj.forwardingAgentAddressId = fa.additionalInfo.addressId;
                            infoObj.forwardingAgentAddress = fa.additionalInfo.address;
                            break;
                        case ShipmentFields.ForwardingAgentAddress:
                            infoObj.forwardingAgentAddressId = (data as FieldUpdateResult<number>).value;
                            infoObj.forwardingAgentAddress = (selectedItem as AddressDetailsData) ?? undefined;
                            break;
                        case ShipmentFields.DestAgent:
                            let da = data as FieldUpdateResult<number>;
                            infoObj.destAgentId = da.value;
                            infoObj.destAgentName = (selectedItem as PartyListItem)?.legalName ?? '';
                            infoObj.destAgentAddressId = da.additionalInfo.addressId;
                            infoObj.destAgentAddress = da.additionalInfo.address;
                            break;
                        case ShipmentFields.DestAgentAddress:
                            infoObj.destAgentAddressId = (data as FieldUpdateResult<number>).value;
                            infoObj.destAgentAddress = (selectedItem as AddressDetailsData) ?? undefined;
                            break;
                        case ShipmentFields.ETD:
                            infoObj.etd = (data as FieldUpdateResult<Date | null>).value;
                            break;
                        case ShipmentFields.ETA:
                            infoObj.eta = (data as FieldUpdateResult<Date | null>).value;
                            break;
                        case ShipmentFields.DeliveryCutOff:
                            infoObj.deliveryCutOff = (data as FieldUpdateResult<Date | null>).value;
                            break;
                        case ShipmentFields.DocumentsCutOff:
                            infoObj.documentsCutOff = (data as FieldUpdateResult<Date | null>).value;
                            break;
                        case ShipmentFields.DocumentFolder:
                            infoObj.documentsFolder = (data as FieldUpdateResult<string>).value;
                            break;
                        case ShipmentFields.Groupage:
                            infoObj.groupageId = (data as FieldUpdateResult<number | null>).value;
                            infoObj.groupageUniqueId = (selectedItem as PartyListItem)?.legalName ?? '';
                            break;
                        case ShipmentFields.Reference:
                            infoObj.reference = (data as FieldUpdateResult<string>).value;
                            break;
                        case ShipmentFields.Voyage:
                            infoObj.voyage = (data as FieldUpdateResult<string>).value;
                            break;
                        case ShipmentFields.Vessel:
                            infoObj.vessel = (data as FieldUpdateResult<string>).value;
                            break;
                        case ShipmentFields.HarmonizationCode:
                            infoObj.harmonizationCode = (data as FieldUpdateResult<string>).value;
                            break;
                        case ShipmentFields.ExternalRef:
                            infoObj.externalReference = (data as FieldUpdateResult<string>).value;
                            break;
                        case ShipmentFields.POL:
                            infoObj.pol = (data as FieldUpdateResult<string>).value;
                            break;
                        case ShipmentFields.POE:
                            infoObj.poe = (data as FieldUpdateResult<string>).value;
                            break;
                        case ShipmentFields.POD:
                            infoObj.pod = (data as FieldUpdateResult<string>).value;
                            break;
                        case ShipmentFields.ContentDescription:
                            infoObj.contentDescription = (data as FieldUpdateResult<string>).value;
                            break;
                        case ShipmentFields.Notes:
                            infoObj.comments = (data as FieldUpdateResult<string>).value;
                            break;
                    }

                    dispatch({ type: 'SHIPMENT_UPDATE_DATA', data: infoObj });
                })
                .catch((err) => {
                    dispatch({ type: 'SHIPMENT_UPDATE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'SHIPMENT_UPDATE_DATA_REQUEST', field: serverField });
        }
    },

    requestContainers: (shipmentId: number, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipmentDetails && !appState.shipmentDetails.containers.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<ShipmentContainerItem>>(`v1/shipping/${shipmentId}/containers`, 'GET', {}, skip, pageSize, sorting)
                .then((data) => {
                    dispatch({ type: 'SHIPMENT_CONTAINERS_RECEIVE', containers: data.data, count: data.count });
                })
                .catch((err) => {
                    dispatch({ type: 'SHIPMENT_CONTAINERS_RECEIVE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });
            dispatch({ type: 'SHIPMENT_CONTAINERS_REQUEST', skip: skip });
        }
    },

    startDeleteContainer: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipmentDetails) {
            dispatch({ type: 'SHIPMENT_CONTAINERS_DELETE_START' });
        }
    },
    submitDeleteContainer: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipmentDetails) {
            apiClientInstance.fetchRequest(
                `/v1/containers/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'SHIPMENT_CONTAINERS_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'SHIPMENT_CONTAINERS_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'SHIPMENT_CONTAINERS_DELETE_SEND' });
        }
    },
    cancelDeleteContainer: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipmentDetails) {
            dispatch({ type: 'SHIPMENT_CONTAINERS_DELETE_CANCEL' });
        }
    },

    requestGroupageShipments: (groupageId: number, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipmentDetails && !appState.shipmentDetails.shipments.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<GroupageShipmentItem>>(`v1/shipping/${groupageId}/groupage`, 'GET', {}, skip, pageSize, sorting)
                .then((data) => {
                    data.data.forEach((x, index) => {
                        !!x.etd && (x.etd = new Date(x.etd));
                        !!x.eta && (x.eta = new Date(x.eta));
                        x.uid = uuid(`${x.uid}-${index}`, namespace);
                    });

                    dispatch({ type: 'SHIPMENT_GROUPAGE_RECEIVE', shipments: data.data, count: data.count });
                })
                .catch((err) => {
                    dispatch({ type: 'SHIPMENT_GROUPAGE_RECEIVE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });
            dispatch({ type: 'SHIPMENT_GROUPAGE_REQUEST', skip: skip });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ShipmentDetailsState = {
    data: EmptyData,
    isLoading: false,
    updateStatus: ServerOperationStatus.NONE,
    containers: {
        isLoading: false,
        data: [],
        count: 0,
        skip: 0,
        deleteStatus: ServerOperationStatus.NONE,
    },
    shipments: {
        isLoading: false,
        data: [],
        count: 0,
        skip: 0,
    },
 };

export const reducer: Reducer<ShipmentDetailsState> = (state: ShipmentDetailsState | undefined, incomingAction: Action): ShipmentDetailsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SHIPMENT_REQUEST_DATA':
            return {
                ...state,
                data: state.data,
                isLoading: true,
                updatedField: undefined
            };
        case 'SHIPMENT_RECEIVE_DATA':
            return {
                ...state,
                data: action.data,
                isLoading: false,
                updatedField: undefined
            };
        case 'SHIPMENT_RECEIVE_DATA_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'SHIPMENT_UPDATE_DATA_REQUEST':
            return {
                ...state,
                updatedField: action.field,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'SHIPMENT_UPDATE_DATA':
            return {
                ...state,
                data: action.data,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'SHIPMENT_UPDATE_DATA_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            };

        case 'SHIPMENT_CONTAINERS_REQUEST':
            return {
                ...state,
                containers: {
                    ...state.containers,
                    isLoading: true,
                    skip: action.skip,
                }
            };
        case 'SHIPMENT_CONTAINERS_RECEIVE':
            return {
                ...state,
                containers: {
                    ...state.containers,
                    data: action.containers,
                    count: action.count,
                    isLoading: false,
                }
            };
        case 'SHIPMENT_CONTAINERS_RECEIVE_ERROR':
            return {
                ...state,
                containers: {
                    ...state.containers,
                    isLoading: false,
                },
            };

        case 'SHIPMENT_CONTAINERS_DELETE_START':
            return {
                ...state,
                containers: {
                    ...state.containers,
                    deleteStatus: ServerOperationStatus.READY,
                },
            };
        case 'SHIPMENT_CONTAINERS_DELETE_SEND':
            return {
                ...state,
                containers: {
                    ...state.containers,
                    deleteStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'SHIPMENT_CONTAINERS_DELETE_SUCCESS':
            return {
                ...state,
                containers: {
                    ...state.containers,
                    deleteStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'SHIPMENT_CONTAINERS_DELETE_CANCEL':
            return {
                ...state,
                containers: {
                    ...state.containers,
                    deleteStatus: ServerOperationStatus.NONE,
                },
            };
        case 'SHIPMENT_CONTAINERS_DELETE_ERROR':
            return {
                ...state,
                containers: {
                    ...state.containers,
                    deleteStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'CONTAINER_MODAL_UPDATE_SUCCESS':
            {
                let shipmentId = action.object.shipmentId;
                let container = state.containers.data.find(x => x.containerId === action.object.containerId);

                if (!!shipmentId && shipmentId === state.data.shipmentId && !!container) {
                    let items = [...state.containers.data];
                    let index = items.indexOf(container);
                    items[index] = action.object;

                    return {
                        ...state,
                        containers: {
                            ...state.containers,
                            data: items,
                        },
                    }
                }

                return {
                    ...state
                };
            }

        case 'SHIPMENT_GROUPAGE_REQUEST':
            return {
                ...state,
                shipments: {
                    ...state.shipments,
                    isLoading: true,
                    skip: action.skip
                },
            };
        case 'SHIPMENT_GROUPAGE_RECEIVE':
            return {
                ...state,
                shipments: {
                    ...state.shipments,
                    data: action.shipments,
                    count: action.count,
                    isLoading: false,
                },
            };
        case 'SHIPMENT_GROUPAGE_RECEIVE_ERROR':
            return {
                ...state,
                shipments: {
                    ...state.shipments,
                    isLoading: false,
                },
            };
    }

    return state;
};