import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { apiClientInstance } from '~services/auth/ApiClientInstance';

import { ShowErrorAction } from '~store/infra/errors';

import SystemUserFields, { SystemUserFieldsType } from '~enums/fields/systemUser';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';
import SystemUserDetails from '~models/user/SystemUserDetails';
import { PersonListItem } from '~models/person/personListItem';
import { parsePersonString } from '~src/utils/personUtils';
import TenantItem from '~models/tenant/tenantItem';


export interface ManagementSystemUserDetailsState {
    isLoading: boolean;
    data: SystemUserDetails;
    updatedField?: SystemUserFieldsType;
    updateStatus: ServerOperationStatus;
}

const EmptyData : SystemUserDetails = {
    id: 0,
    username: '',
    tenantId: null,
    tenantName: '',
    personId: null,
    personName: '',
    roleIds: [],
};

// -----------------
// ---- ACTIONS ----
// -----------------

interface SystemUserDetailsRequestAction {
    type: 'MANAGEMENT_SYSTEMUSERDETAILS_REQUEST';
}
interface SystemUserDetailsReceiveAction {
    type: 'MANAGEMENT_SYSTEMUSERDETAILS_RECEIVE';
    data: SystemUserDetails;
}
interface SystemUserDetailsErrorAction {
    type: 'MANAGEMENT_SYSTEMUSERDETAILS_ERROR';
}

interface SystemUserDetailsUpdateStartAction {
    type: 'MANAGEMENT_SYSTEMUSERDETAILS_UPDATE_SEND';
    field: SystemUserFieldsType;
}
interface SystemUserDetailsUpdateSuccessAction {
    type: 'MANAGEMENT_SYSTEMUSERDETAILS_UPDATE_SUCCESS';
    data: SystemUserDetails;
}
interface SystemUserDetailsUpdateErrorAction {
    type: 'MANAGEMENT_SYSTEMUSERDETAILS_UPDATE_ERROR';
}

interface SystemUserDetailsClearAction {
    type: 'MANAGEMENT_SYSTEMUSERDETAILS_CLEAR';
}

type KnownAction = SystemUserDetailsRequestAction | SystemUserDetailsReceiveAction | SystemUserDetailsErrorAction
    | SystemUserDetailsUpdateStartAction | SystemUserDetailsUpdateSuccessAction | SystemUserDetailsUpdateErrorAction
    | SystemUserDetailsClearAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestUserDetails: (id: number): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementSystemUserDetails && !appState.managementSystemUserDetails.isLoading) {
            apiClientInstance.fetchRequest<SystemUserDetails>(`/v1/users/${id}`, 'GET')
                .then(data => {
                    dispatch({ type: 'MANAGEMENT_SYSTEMUSERDETAILS_RECEIVE', data: data });
                })
                .catch(err => {
                    dispatch({ type: 'MANAGEMENT_SYSTEMUSERDETAILS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_SYSTEMUSERDETAILS_REQUEST', });
        }
    },
    updateField: (serverField: SystemUserFieldsType, value: any, selectedItem?: any) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementSystemUserDetails) {
            apiClientInstance.fetchRequest(
                `v1/files/${appState.managementSystemUserDetails.data.id}`,
                'PATCH',
                { field: serverField, value: value }
            )
                .then((data) => {
                    let dataObj = {...(appState.managementSystemUserDetails?.data ?? {} as SystemUserDetails)};

                    switch (serverField) {
                        case SystemUserFields.Username:
                            dataObj.username = (data as FieldUpdateResult<string>).value;
                            break;
                        case SystemUserFields.Tenant:
                            let tenant = selectedItem as TenantItem;
                            dataObj.tenantId = (data as FieldUpdateResult<number>).value;
                            dataObj.tenantName = tenant?.name ?? '';
                            break;
                        case SystemUserFields.Person:
                            let person = selectedItem as PersonListItem;
                            dataObj.personId = (data as FieldUpdateResult<number>).value;
                            dataObj.personName = parsePersonString(person);
                            break;
                        case SystemUserFields.Roles:
                            dataObj.roleIds = (data as FieldUpdateResult<Array<number>>).value;
                            break;
                    }

                    dispatch({ type: 'MANAGEMENT_SYSTEMUSERDETAILS_UPDATE_SUCCESS', data: dataObj, });
                })
                .catch((err) => {
                    dispatch({ type: 'MANAGEMENT_SYSTEMUSERDETAILS_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_SYSTEMUSERDETAILS_UPDATE_SEND', field: serverField });
        }
    },
    clearData: () : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementSystemUserDetails) {
            dispatch({ type: 'MANAGEMENT_SYSTEMUSERDETAILS_CLEAR', });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementSystemUserDetailsState = {
    isLoading: false,
    data: EmptyData,
    updateStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementSystemUserDetailsState> = (state: ManagementSystemUserDetailsState | undefined, incomingAction: Action): ManagementSystemUserDetailsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_SYSTEMUSERDETAILS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'MANAGEMENT_SYSTEMUSERDETAILS_RECEIVE':
            return {
                ...state,
                data: action.data,
                isLoading: false,
            };
        case 'MANAGEMENT_SYSTEMUSERDETAILS_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'MANAGEMENT_SYSTEMUSERDETAILS_UPDATE_SEND':
            return {
                ...state,
                updatedField: action.field,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_SYSTEMUSERDETAILS_UPDATE_SUCCESS':
            return {
                ...state,
                data: action.data,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'MANAGEMENT_SYSTEMUSERDETAILS_UPDATE_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_SYSTEMUSERDETAILS_CLEAR':
            return {
                ...state,
                data: EmptyData,
            };
    }

    return state;
};