const CommunicationGridFields = {
    CreatedOn: "createdOn",
    CreatedBy: "createdBy",
    CreatedByName: "createdByName",
    CommType: "commType",
    Notes: "notes",
    ParentObject: "parentObject",
} as const;

export type CommunicationGridFieldsType = typeof CommunicationGridFields[keyof typeof CommunicationGridFields];

export default CommunicationGridFields;