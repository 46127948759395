const ExtendedDimFields = Object.freeze({
    HandlingType: "ExtendedDimField.HandlingType",
    ItemCategory: "ExtendedDimField.ItemCategory",
    Depth: "ExtendedDimField.Depth",
    Width: "ExtendedDimField.Width",
    Height: "ExtendedDimField.Height",
    OverheadPercentage: "ExtendedDimField.OverheadPercentage",
});

export type ExtendedDimFieldsType = typeof ExtendedDimFields[keyof typeof ExtendedDimFields];

export default ExtendedDimFields;