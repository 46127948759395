import PartyCreateResult from "~models/party/partyCreateResult";
import { ServerOperationStatus } from "~enums/serverOperationStatus";
import { NewPartyKeysType } from "~enums/newPartyKeys";
import { NewPartyElementProps } from "./newParty";

const handlePartyCreateResult = function (this: React.Component<NewPartyElementProps, any>, key: NewPartyKeysType, handler: (result: PartyCreateResult) => any) {    
    if (this.props.partyCreation.controlKey === key && 
        this.props.partyCreation.status === ServerOperationStatus.SUCCESS && 
        !!this.props.partyCreation.createResult
    ) {
        let result = {...this.props.partyCreation.createResult};
        this.props.partyCreation.clearResult();
        handler(result);
    }
}
export { handlePartyCreateResult };
