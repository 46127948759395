import React from 'react';
import { AppBar, AppBarProps, Toolbar, Typography } from '@mui/material';
import AccountMenu from './AccountMenu';
import NotificationMenu from './NotificationMenu';

import '~css/components/navigation/TopBar.scss';


type TopBarProps = AppBarProps;

const TopBar = React.forwardRef<HTMLDivElement, TopBarProps>((props, ref) => (
    <AppBar ref={ref} className="TopBar TopBar__root" position="fixed">
        <Toolbar className={"TopBar__toolbar"}>
            <Typography className={"TopBar__title TopBar__item"}>
                ArtManager
            </Typography>

            {props.children}

            <div className="spacer"></div>
            
            <NotificationMenu />

            <AccountMenu />
        </Toolbar>
    </AppBar>
));

export default TopBar;