import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';
import { apiClientInstance } from '~src/services/auth/ApiClientInstance';
import { AMWUserData, ServerAMWUserData } from '~models/user/AMWUserData';


export interface UserState {
    isLoading: boolean;
    userData?: AMWUserData;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface RequestUserDataAction {
    type: 'USER_USERDATA_REQUEST';
}

interface ClearUserDataAction {
    type: 'USER_USERDATA_CLEAR';
}

interface ReceiveUserDataAction {
    type: 'USER_USERDATA_RECEIVE';
    userData: AMWUserData;
}

interface UpdateUserPreferenceAction {
    type: 'USER_PREFERENCES_UPDATE';
    prefs: {[key: string]: any};
}

type KnownAction = RequestUserDataAction | ReceiveUserDataAction | ClearUserDataAction |
    UpdateUserPreferenceAction;

export const actionCreators = {
    requestUserData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.user && !appState.user.isLoading && appState.user.userData === undefined) {
            apiClientInstance.fetchRequest<ServerAMWUserData>('/v1/identity/userdata')
                .then(data => {
                    data.preferences = JSON.parse(localStorage.getItem('ArtManagerWeb-UserPreferences') || '{}');

                    dispatch({ type: 'USER_USERDATA_RECEIVE', userData: new AMWUserData(data) });
                });

            dispatch({ type: 'USER_USERDATA_REQUEST' });
        }
    },
    clearUserData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: 'USER_USERDATA_CLEAR' });
    },
    updateUserPreferences: (prefs: {[key: string]: any}): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.user) {
            // TODO query server for saving appropriate preferences
            // apiClientInstance.fetchRequest<AMWUserData>('/v1/identity/userdata')
            //     .then(data => {
            //         dispatch({ type: 'RECEIVE_USERDATA', userData: data });
            //     });

            // dispatch({ type: 'REQUEST_USERDATA' });
            dispatch({ type: 'USER_PREFERENCES_UPDATE', prefs: prefs });
        }
    },
};

// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: UserState = { isLoading: false };

export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'USER_USERDATA_REQUEST':
            return {
                isLoading: true,
            };
        case 'USER_USERDATA_RECEIVE':
            return {
                userData: action.userData,
                isLoading: false,
            };
        case 'USER_USERDATA_CLEAR':
            return {
                userData: undefined,
                isLoading: false,
            };
        case 'USER_PREFERENCES_UPDATE':
            if (!!state.userData) {
                let newUserData = state.userData.clone();
                newUserData.updatePreference(action.prefs);

                localStorage.setItem('ArtManagerWeb-UserPreferences', JSON.stringify(newUserData.preferences));

                return {
                    ...state,
                    userData: newUserData,
                };
            }
            return state;
    }

    return state;
};