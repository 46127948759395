import React from 'react';
import TasksGridFilters from '~enums/gridFilters/tasksFilters';
import { PartyType } from '~enums/partyType';
import TaskStatus from '~enums/taskStatus';
import { FilterHandler } from '~models/filters';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import FilterContainer from '~components/filtering/FilterContainer';
import FilterItem from '~components/filtering/FilterItem';
import { FilterPanel, FilterPanelProps } from '~components/filtering/FilterPanel';
import OnDemandMultiSelect from '~controls/OnDemandMultiSelect';
import CheckboxWrapper from '~controls/CheckboxWrapper';
import MultiSelectWrapper from '~controls/MultiSelectWrapper';
import DatePickerWrapper from '~components/controls/DatePickerWrapper';
import LocalizationKeys from '~enums/localizationKeys';
import TextBoxWrapper from '~components/controls/TextBoxWrapper';


type TasksFilterPanelProps = FilterPanelProps;

export default class TasksFilterPanel extends FilterPanel<TasksFilterPanelProps> {

    public render() {
        const localization = provideLocalizationService(this);

        return (
            <FilterContainer
                applyClassName={'Button--orange'}
                clearClassName={'Button--gray'}
                filterApply={this.props.filterApply}
                filterClear={this.props.filterClear}
            >
                <FilterItem>
                    <TextBoxWrapper
                        id="description"
                        name={TasksGridFilters.Description}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Filters.Description, "%Description")}
                        value={FilterHandler.getValue(this.props.filters, TasksGridFilters.Description)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandMultiSelect
                        url="/v1/persons"
                        urlParams={{partyTypes: [PartyType.Division]}}
                        dataItemKey={'personId'}
                        textField={'fullName'}
                        id="ownedBy"
                        name={TasksGridFilters.OwnedBy}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Filters.OwnedBy, "%Owned by")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, TasksGridFilters.OwnedBy, 'personId', 'fullName')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <MultiSelectWrapper
                        id="status"
                        name={TasksGridFilters.Status}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Filters.Status, "%Status")}
                        data={Object.values(TaskStatus).map(x => { return {
                            id: x,
                            text: localization.toLanguageString(x, x)
                        }})}
                        dataItemKey={'id'}
                        textField={'text'}
                        value={FilterHandler.getMultiselectValue(this.props.filters, TasksGridFilters.Status, 'id', 'text')}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="dateFrom"
                        name={TasksGridFilters.DateFrom}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Filters.DateFrom, "%From")}
                        value={FilterHandler.getDateValue(this.props.filters, TasksGridFilters.DateFrom)}
                        onChange={this.onDateChange.bind(this)}
                        max={FilterHandler.getDateValue(this.props.filters, TasksGridFilters.DateTo) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="dateTo"
                        name={TasksGridFilters.DateTo}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Filters.DateTo, "%To")}
                        value={FilterHandler.getDateValue(this.props.filters, TasksGridFilters.DateTo)}
                        onChange={this.onDateChange.bind(this)}
                        min={FilterHandler.getDateValue(this.props.filters, TasksGridFilters.DateFrom) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <CheckboxWrapper
                        id="startDate"
                        name={TasksGridFilters.StartDate}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Filters.StartDate, "%Start date")}
                        value={FilterHandler.getValue(this.props.filters, TasksGridFilters.StartDate)}
                        onChange={this.onCheckChange.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <CheckboxWrapper
                        id="dueDate"
                        name={TasksGridFilters.DueDate}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Filters.DueDate, "%Due date")}
                        value={FilterHandler.getValue(this.props.filters, TasksGridFilters.DueDate)}
                        onChange={this.onCheckChange.bind(this)}
                    />
                </FilterItem>
            </FilterContainer>
        );
    }
}
registerForLocalization(TasksFilterPanel as React.ComponentClass<any>);