import * as React from 'react';
import { Navigate, Outlet, Route, Routes, useParams } from 'react-router';

import { ClientFilePage } from '~pages/clientFile/ClientFile';

import PageWrapper from '~components/layout/PageWrapper';
import AuthCallback from '~components/navigation/AuthCallback';
import { AuthCallbackSilent } from '~components/navigation/AuthCallbackSilent';
// import { ItemPageTabs } from '~pages/clientFile/subpages/Item/ItemPage';
import { CRMTabs, CRMRecentTabs } from '~pages/mainViews/crm/CRM';
import { InventoryTabs } from '~pages/mainViews/inventory/Inventory';
import { ManagementTabs } from '~pages/mainViews/management/Management';
import { QuotationsTabs } from '~pages/mainViews/quotations/Quotations';
import { AMWUserData } from '~models/user/AMWUserData';
import { connect } from 'react-redux';
import { ApplicationState } from '~store/ApplicationState';
import { UserPermissions } from '~enums/userPermissions';
import AccessDenied from './AccessDenied';
import Transactions from '~components/pages/clientFile/subpages/Transactions/Transactions';

const ClientFile = React.lazy(() => import('~pages/clientFile/ClientFile'));
const CRM = React.lazy(() => import('~pages/mainViews/crm/CRM'));
const Financials = React.lazy(() => import('~pages/mainViews/financials/Financials'));
const Inventory = React.lazy(() => import('~pages/mainViews/inventory/Inventory'));
const JobControl = React.lazy(() => import('~pages/mainViews/jobs/JobControl'));
const Management = React.lazy(() => import('~pages/mainViews/management/Management'));
const Operations = React.lazy(() => import('~pages/mainViews/operations/Operations'));
const QuickEntry = React.lazy(() => import('~pages/mainViews/jobs/QuickEntry/QuickEntry'));
const Quotations = React.lazy(() => import('~pages/mainViews/quotations/Quotations'));
const Scheduling = React.lazy(() => import('~pages/mainViews/operations/Scheduling/Scheduling'));
const Shipping = React.lazy(() => import('~pages/mainViews/shipping/Shipping'));
const GroupageDetails = React.lazy(() => import('~components/pages/mainViews/shipping/Groupage/GroupageDetails'));
const Surveys = React.lazy(() => import('~pages/mainViews/surveys/Surveys'));
const Tasks = React.lazy(() => import('~pages/mainViews/tasks/Tasks'));
const Warehouses = React.lazy(() => import('~pages/mainViews/warehouses/Warehouses'));
const ReusableCrates = React.lazy(() => import('~pages/mainViews/reusablecrates/ReusableCrates'));

// const CampaignDetails = React.lazy(() => import('~pages/mainViews/crm/Campaigns/Details/CampaignDetails'));
// const CompanyDetails = React.lazy(() => import('~pages/company/CompanyDetails'));
// const ItemDetailsPage = React.lazy(() => import('~pages/clientFile/subpages/Item/ItemPage'));
const PurchaseOrder = React.lazy(() => import('~pages/clientFile/subpages/PO/Details/PurchaseOrder'));
const GeneralInfo =  React.lazy(() => import('~pages/clientFile/subpages/GeneralInfo/GeneralInfo'));
const POItems = React.lazy(() => import('~pages/clientFile/subpages/PO/Grid/POItems'));
const CFShipping = React.lazy(() => import('~components/pages/clientFile/subpages/Shipments/Shipping'));
const ShipmentDetails = React.lazy(() => import('~components/pages/clientFile/subpages/Shipments/Details/ShipmentDetails'));


interface CFPageProps {
    page: ClientFilePage;
}

const CFPage = (props: CFPageProps) => {
    let { id, objId } = useParams();
    let cfId = Number(id);

    let children : React.ReactNode;

    switch (props.page) {
        case ClientFilePage.GENERAL_INFO: {
            children = <GeneralInfo clientFileId={cfId} />;
            break;
        }
        case ClientFilePage.POITEMS: {
            children = <POItems clientFileId={cfId} />
            break;
        }
        case ClientFilePage.PO: {
            let orderId = Number(objId);
            children = <PurchaseOrder orderId={orderId} />
            break;
        }
        case ClientFilePage.SHIPPING: {
            children = <CFShipping clientFileId={cfId} />
            break;
        }
        case ClientFilePage.SHIPMENTDETAILS: {
            let shpId = Number(objId);
            children = <ShipmentDetails clientFileId={cfId} shipmentId={shpId} />
            break;
        }
        case ClientFilePage.TRANSACTIONS: {
            children = <Transactions clientFileId={cfId} />
            break;
        }
        default: {
            children = <React.Fragment />
        }
    }

    return (
        <ClientFile fileId={cfId} page={props.page}>
            {children}
        </ClientFile>
    );
};

const Groupage = () => {
    let { id } = useParams();
    let shpId = Number(id);

    return (
        <GroupageDetails shipmentId={shpId} />
    );
};

type AppSwitchStateProps = { userData?: AMWUserData; };
type AppSwitchProps = AppSwitchStateProps;

const AppSwitch = (props: AppSwitchProps) => {
    const canViewCrm = props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View)
        || props.userData?.hasPermission(UserPermissions.CRM.Companies.View)
        || props.userData?.hasPermission(UserPermissions.CRM.Tasks.View)
        || props.userData?.hasPermission(UserPermissions.CRM.Appointments.View)
        || props.userData?.hasPermission(UserPermissions.CRM.Calendar.View)
        || props.userData?.hasPermission(UserPermissions.CRM.Campaigns.View)
        || props.userData?.hasPermission(UserPermissions.CRM.Recent.View);

    return (
        <PageWrapper>
            <Routes>
                <Route path='/' element={<React.Fragment><Outlet /></React.Fragment>}>

                    <Route index element={<Navigate to={'/openid/auth/callback'} />} />

                    <Route path='/files/:id/transactions' element={<CFPage page={ClientFilePage.TRANSACTIONS}/>} />

                    <Route path='/files/:id/shipments/:objId' element={<CFPage page={ClientFilePage.SHIPMENTDETAILS}/>} />

                    <Route path='/files/:id/shipments' element={<CFPage page={ClientFilePage.SHIPPING}/>} />

                    <Route path='/files/:id/po/:objId' element={<CFPage page={ClientFilePage.PO}/>} />

                    <Route path='/files/:id/po' element={<CFPage page={ClientFilePage.POITEMS}/>} />

                    <Route path='/files/:id' element={<CFPage page={ClientFilePage.GENERAL_INFO}/>} />

                    <Route path='/files/' element={props.userData?.hasPermission(UserPermissions.ClientFile.JobControlView) ? <JobControl /> : <AccessDenied />} />

                    <Route path='/files/quick-entry' element={<QuickEntry />} />

                    <Route path='/shipping/:id' element={<Groupage />} />

                    <Route path='/shipping' element={props.userData?.hasPermission(UserPermissions.Shipping.ShippingView) ? <Shipping /> : <AccessDenied />} />

                    <Route path='/financials' element={props.userData?.hasPermission(UserPermissions.Financials.FinancialsView) ? <Financials /> : <AccessDenied />} />

                    <Route path='/inventory/items' element={props.userData?.hasPermission(UserPermissions.Inventory.InventoryView) ? <Inventory tab={InventoryTabs.Items} /> : <AccessDenied />} />

                    <Route path='/inventory/packages' element={props.userData?.hasPermission(UserPermissions.Inventory.InventoryView) ? <Inventory tab={InventoryTabs.Packages} /> : <AccessDenied />} />

                    <Route path='/inventory/crates' element={props.userData?.hasPermission(UserPermissions.Inventory.InventoryView) ? <Inventory tab={InventoryTabs.EmptyCrates} /> : <AccessDenied />} />

                    <Route path='/inventory' element={props.userData?.hasPermission(UserPermissions.Inventory.InventoryView) ? <Inventory tab={InventoryTabs.Items} /> : <AccessDenied />} />

                    <Route path='/operations' element={props.userData?.hasPermission(UserPermissions.Operations.OperationsView) ? <Operations /> : <AccessDenied />} />

                    <Route path='/scheduling' element={<Scheduling/>} />

                    <Route path='/surveys' element={props.userData?.hasPermission(UserPermissions.Surveys.SurveysView) ? <Surveys /> : <AccessDenied />} />

                    <Route path='/quotations' element={props.userData?.hasPermission(UserPermissions.Quotations.QuotationsView) ? <Quotations tab={QuotationsTabs.Quotations} /> : <AccessDenied />} />

                    <Route path='/projects' element={props.userData?.hasPermission(UserPermissions.Quotations.ProjectsView) ? <Quotations tab={QuotationsTabs.Projects} /> : <AccessDenied />} />

                    <Route path='/crm/opportunities' element={props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View) ? <CRM tab={CRMTabs.Opportunities} /> : <AccessDenied />} />

                    <Route path='/crm/companies' element={props.userData?.hasPermission(UserPermissions.CRM.Companies.View) ? <CRM tab={CRMTabs.Companies} /> : <AccessDenied />} />

                    <Route path='/crm/tasks' element={props.userData?.hasPermission(UserPermissions.CRM.Tasks.View) ? <CRM tab={CRMTabs.Tasks} /> : <AccessDenied />} />

                    <Route path='/crm/appointments' element={props.userData?.hasPermission(UserPermissions.CRM.Appointments.View) ? <CRM tab={CRMTabs.Appointments} /> : <AccessDenied />} />

                    <Route path='/crm/calendar' element={props.userData?.hasPermission(UserPermissions.CRM.Calendar.View) ? <CRM tab={CRMTabs.Calendar} /> : <AccessDenied />} />

                    <Route path='/crm/campaigns' element={props.userData?.hasPermission(UserPermissions.CRM.Campaigns.View) ? <CRM tab={CRMTabs.Campaigns} /> : <AccessDenied />} />

                    <Route path='/crm/recent/companies' element={props.userData?.hasPermission(UserPermissions.CRM.Recent.View) ? <CRM tab={CRMTabs.RecentlyAdded} recentTab={CRMRecentTabs.Companies} /> : <AccessDenied />} />

                    <Route path='/crm/recent/contacts' element={props.userData?.hasPermission(UserPermissions.CRM.Recent.View) ? <CRM tab={CRMTabs.RecentlyAdded} recentTab={CRMRecentTabs.Contacts} /> : <AccessDenied />} />

                    <Route path='/crm/recent/opportunities' element={props.userData?.hasPermission(UserPermissions.CRM.Recent.View) ? <CRM tab={CRMTabs.RecentlyAdded} recentTab={CRMRecentTabs.Opportunities} /> : <AccessDenied />} />

                    <Route path='/crm/recent/tasks' element={props.userData?.hasPermission(UserPermissions.CRM.Recent.View) ? <CRM tab={CRMTabs.RecentlyAdded} recentTab={CRMRecentTabs.Tasks} /> : <AccessDenied />} />

                    <Route path='/crm/recent/appointments' element={props.userData?.hasPermission(UserPermissions.CRM.Recent.View) ? <CRM tab={CRMTabs.RecentlyAdded} recentTab={CRMRecentTabs.Appointments} /> : <AccessDenied />} />

                    <Route path='/crm/recent/documents' element={props.userData?.hasPermission(UserPermissions.CRM.Recent.View) ? <CRM tab={CRMTabs.RecentlyAdded} recentTab={CRMRecentTabs.Documents} /> : <AccessDenied />} />

                    <Route path='/crm/recent/communications' element={props.userData?.hasPermission(UserPermissions.CRM.Recent.View) ? <CRM tab={CRMTabs.RecentlyAdded} recentTab={CRMRecentTabs.Communications} /> : <AccessDenied />} />

                    <Route path='/crm/recent' element={props.userData?.hasPermission(UserPermissions.CRM.Recent.View) ? <CRM tab={CRMTabs.RecentlyAdded} recentTab={CRMRecentTabs.Companies} /> : <AccessDenied />} />

                    <Route path='/crm' element={canViewCrm ? <CRM /> : <AccessDenied />} />

                    <Route path='/tasks' element={props.userData?.hasPermission(UserPermissions.Tasks.TasksView) ? <Tasks /> : <AccessDenied />} />

                    <Route path='/warehouses' element={props.userData?.hasPermission(UserPermissions.Warehouses.WarehousesView) ? <Warehouses /> : <AccessDenied />} />

                    <Route path='/reusable-crates' element={props.userData?.hasPermission(UserPermissions.Crates.CratesView) ? <ReusableCrates /> : <AccessDenied />} />

                    <Route path='/management/companies' element={<Management tab={ManagementTabs.Companies} />} />

                    {/* TODO permission check */}
                    <Route path='/management/system-users' element={<Management tab={ManagementTabs.SystemUsers} />} />

                    {/* TODO permission check */}
                    <Route path='/management/roles' element={<Management tab={ManagementTabs.Roles} />} />

                    <Route path='/management/users' element={<Management tab={ManagementTabs.Users} />} />

                    <Route path='/management/resources' element={<Management tab={ManagementTabs.Resources} />} />

                    <Route path='/management/quote' element={<Management tab={ManagementTabs.Quote} />} />

                    <Route path='/management/billable-services' element={<Management tab={ManagementTabs.BillableService} />} />

                    <Route path='/management/packing' element={<Management tab={ManagementTabs.PackingType} />} />

                    <Route path='/management/supplier-costs' element={<Management tab={ManagementTabs.SupplierCost} />} />

                    <Route path='/management/excel-sources' element={<Management tab={ManagementTabs.ExcelSources} />} />

                    <Route path='/management/item-config' element={<Management tab={ManagementTabs.ItemConfig} />} />

                    <Route path='/management/extended-dims' element={<Management tab={ManagementTabs.ExtendedDims} />} />

                    <Route path='/management/configuration' element={<Management tab={ManagementTabs.Configuration} />} />

                    <Route path='/management' element={<Management tab={ManagementTabs.Companies} />} />

                {/*
                    <Route path='/items/:id' element={<React.Fragment><Outlet /></React.Fragment>}>

                        <Route index element={<ItemPage tab={ItemPageTabs.DETAILS} />} />

                        <Route path='/items/:id/transactions' element={<ItemPage tab={ItemPageTabs.TRANSACTIONHISTORY} />} />

                        <Route path='/items/:id/locations' element={<ItemPage tab={ItemPageTabs.LOCATIONHISTORY} />} />

                        <Route path='/items/:id/package' element={<ItemPage tab={ItemPageTabs.PACKAGEHISTORY} />} />

                        <Route path='/items/:id/customs' element={<ItemPage tab={ItemPageTabs.CUSTOMSDOCUMENTS} />} />

                        <Route path='/items/:id/conditions' element={<ItemPage tab={ItemPageTabs.CONDITIONS} />} />

                        <Route path='/items/:id/actionlog' element={<ItemPage tab={ItemPageTabs.ACTIONLOG} />} />

                    </Route>

                    <Route path='/companies/:id' element={<CompanyPage />} /> */}

                    <Route path='/openid/auth/callback' element={<AuthCallback />} />

                    <Route path='/openid/auth/callback-silent' element={<AuthCallbackSilent />} />

                </Route>
            </Routes>
        </PageWrapper>
    );
}

export default connect(
    (state: ApplicationState) : AppSwitchStateProps => {
        return {
            userData: state.user.userData,
        };
    },
)(AppSwitch);