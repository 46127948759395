const OperationGridFilters = {
    StartDate: "StartDate",
    EndDate: "EndDate",
    Divisions: "Divisions",
    Users: "Users",
    Resources: "Resources",
    ShowAll: "ShowAll",
    Statuses: "Statuses",
    TransactionTypes: "TransactionTypes",
    JobType: "JobType",
    JobId: "JobId",
    Trip: "Trip",
    Address: "Address",
    HideLinkedToTrips: "HideLinkedToTrips",
} as const;

export type OperationGridFiltersType = typeof OperationGridFilters[keyof typeof OperationGridFilters];

export default OperationGridFilters;