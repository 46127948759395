import { GridColumnProps } from "@progress/kendo-react-grid";
import { FilterList } from "~models/filters";
import { SortDescriptor } from "@progress/kendo-data-query";
import { FilterPanelProps } from "~components/filtering/FilterPanel";
import { AMWGridProps } from "./AMWGrid";

export const SELECTED_FIELD: string = "selected";
export const IN_EDIT_FIELD = "inEditField";
export const VALIDATION_FIELD = "validationField";

export interface GridPageable {
    pageSize: number;
}

export interface GridSortable {
    sorting: SortDescriptor[]
}

export interface GridFilterable {
    filters: FilterList;
}

export interface GridOrderable {
    columnOrder: {[key:string] : number | undefined}
}

export interface GridResizable {
    columnWidth: {[key:string] : number | undefined}
}

export type SimpleObject = {
    [key: string]: any
};

export type EditableField<T> = {
    field: string;
    mandatory?: boolean;
    validationRule?: (dataItem: T) => boolean;
    valueChangeParser?: (dataItem: T, value: any) => T;
    textRetriever?: (dataItem: T) => string;
    ignoreValidityOnChange?: boolean;
};

export type EditableGridColumnProps<T> = GridColumnProps &
    { key: string; } &
    ({
        /** if false, no other props are accepted, and editing is not allowed */
        editable: true;
        mandatory?: boolean;
        validationRule?: (item: T) => boolean;
        valueChangeParser?: (dataItem: T, value: any) => T;
        textRetriever?: (dataItem: T) => string;
        /** if true, do onItemChange of grid even if value is not valid */
        ignoreValidityOnChange?: boolean;
    } | {
        editable: false | never;
        mandatory?: never;
        validationRule?: never;
        valueChangeParser?: never;
        textRetriever?: never;
        ignoreValidityOnChange?: never;
    });


export type EditableGridProps<T extends SimpleObject> = AMWGridProps & {
    data: Array<T>;
    idGetter: (item: T) => number;
    editableFields: Array<EditableField<T>>;
    onItemUpdate?: (item: T, field: string, value: any, text?: string) => Promise<any | void>;
    onCreate?: (item: T) => Promise<T>;
    disableCreate?: boolean;

    selectedIds?: Array<number>;
};

export type EditableGridState<T extends SimpleObject> = {
    data: Array<T>;
    editedId?: number;
    editedField?: string;
    prevValue?: any;
};

export type AMWGridToolbarProps = {
    selectedItem?: number | number[] | null,
    // isGridBusy: boolean,
    children?: React.ReactNode,
    noSidePadding?: boolean,
};

export type AMWGridFilterToolbarProps = AMWGridToolbarProps & {
    requestData: (filters: FilterList) => any,
    filters: FilterList,
    filterPanel: React.ComponentClass<FilterPanelProps> | React.FunctionComponent<FilterPanelProps>,
    totalsRow?: React.ReactNode,
    noButton?: boolean,
};

export type AMWGridFilterToolbarWrapperProps = AMWGridToolbarProps & {
    requestData: (filters: FilterList) => any,
    filters: FilterList,
    totalsRow?: React.ReactNode,
    isGridBusy: boolean;
};