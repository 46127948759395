import { AMWGridFilterToolbarWrapperProps } from '~components/datagrid/types';
import AMWGridFilterToolbar from '~components/datagrid/AMWGridFilterToolbar';
import RecentFilterPanel from '../RecentFilterPanel';


type CompaniesToolbarProps = AMWGridFilterToolbarWrapperProps;

const CompaniesToolbar = (props: CompaniesToolbarProps) => {
    return (
        <AMWGridFilterToolbar {...props} 
            filterPanel={RecentFilterPanel}
        >
        </AMWGridFilterToolbar>
    );
};

export default CompaniesToolbar;