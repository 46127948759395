import React from "react";
import { FilterList } from "~models/filters";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { DatePickerChangeEvent } from "@progress/kendo-react-dateinputs";
import { NumericInputWrapperEvent } from "~controls/NumericInputWrapper";
import { CheckboxChangeEvent, RadioButtonChangeEvent } from "@progress/kendo-react-inputs";
import { MultiSelectWrapperEvent } from "~components/controls/types";
import { TextBoxWrapperEvent } from "~components/controls/TextBoxWrapper";


export type FilterPanelProps = {
    filters: FilterList,
    filterChange: (filters: {[key: string]: any}) => void,
    filterApply: () => void,
    filterClear: () => void,
    disabled?: boolean;
};

export class FilterPanel<T1 extends FilterPanelProps, S = {}, SS = any> extends React.Component<T1, S, SS> {
    onInputChange(event: TextBoxWrapperEvent) {
        this.props.filterChange({[event.name || ''] : { value: event.value }});
    }

    onNumericChange(event: NumericInputWrapperEvent) {
        this.props.filterChange({[event.name || ''] : { value: event.value }});
    }

    onDropChange(event: DropDownListChangeEvent) {
        this.props.filterChange({[event.target.props.name || ''] : { value: event.value[event.target.props.dataItemKey ?? ''], text: event.value[event.target.props.textField ?? ''] }});
    }

    onDateChange(event: DatePickerChangeEvent) {
        this.props.filterChange({[event.target.props.name || ''] : { value: event.value }});
    }

    onMultiChange(event: MultiSelectWrapperEvent) {
        let value = event.value.map((x:any) => x[event.target?.props.dataItemKey ?? '']);
        this.props.filterChange({[event.name || ''] : { value: value.length === 0 ? null : value }});
    }

    onCheckChange(event: CheckboxChangeEvent) {
        this.props.filterChange({[event.target.name || ''] : { value: event.value }});
    }

    onRadioChange(event: RadioButtonChangeEvent) {
        this.props.filterChange({[event.target.element?.name || ''] : { value: event.value }});
    }

    onFilterClear(name?: string) {
        !!name && this.props.filterChange({[name]: null});
    }
}