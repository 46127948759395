import React, { useState } from 'react';
import { FilterList } from '~models/filters';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { updateFilters } from '~components/datagrid/functions';
import { AMWGridFilterToolbarProps } from '~components/datagrid/types';
import FilterButton from '~components/datagrid/FilterButton';


const AMWGridFilterToolbar = (props: AMWGridFilterToolbarProps) => {
    const [ opened, setOpened ] = useState(false);
    const [ filters, setFilters ] = useState(props.filters ?? {} as FilterList);

    const filterChange = (newFilters: {[key: string]: any}) => {
        setFilters(updateFilters(newFilters, filters));
    }

    const filterApply = () => {
        props.requestData(filters);
    };

    const filterClear = () => {
        setFilters({});
        props.requestData({});
    };

    return (
        <Grid className={`AMWGrid__Toolbar ${!!props.noSidePadding ? 'AMWGrid__Toolbar--nosidepadding' : ''}`.trim()} container rowGap={1} direction={"column"}>
            {!!props.totalsRow && (
                <Grid container item display={'flex'} alignItems={'center'} flexWrap={'nowrap'}>
                    {props.totalsRow}
                </Grid>
            )}

            <Grid container item display={'flex'} alignItems={'center'} flexWrap={'nowrap'} columnGap={2}>
                {!props.noButton && (
                    <Grid item className={"ButtonGroup"}>
                        <FilterButton
                            opened={opened}
                            onOpen={setOpened}
                        />
                    </Grid>
                )}

                {props.children}
            </Grid>

            <Grid container item
                display={'flex'}
                alignItems={'center'}
                flexWrap={'nowrap'}
                className={`AMWGrid__Toolbar__FilterContainer ${opened ? 'AMWGrid__Toolbar__FilterContainer--opened' : ''}`}
            >
                {!props.noButton && (
                    <Accordion className={'AMWGrid__Toolbar__Filters'} expanded={opened}>
                        <AccordionSummary></AccordionSummary>
                        <AccordionDetails>
                            <props.filterPanel
                                filters={filters}
                                filterClear={filterClear}
                                filterChange={filterChange}
                                filterApply={filterApply}
                            />
                        </AccordionDetails>
                    </Accordion>
                )}

                {!!props.noButton && (
                    <props.filterPanel
                        filters={filters}
                        filterClear={filterClear}
                        filterChange={filterChange}
                        filterApply={filterApply}
                    />
                )}

            </Grid>
        </Grid>
    );
};

export default AMWGridFilterToolbar;