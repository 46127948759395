const PackingTypeFields = {
    Name: "PackingTypeField.Name",
    Depth: "PackingTypeField.Depth",
    Width: "PackingTypeField.Width",
    Height: "PackingTypeField.Height",
    Volume: "PackingTypeField.Volume",
    Weight: "PackingTypeField.Weight",
    Costs: "PackingTypeField.Costs",
    PriceType: "PackingTypeField.PriceType",
} as const;

export type PackingTypeFieldsType = typeof PackingTypeFields[keyof typeof PackingTypeFields];

export default PackingTypeFields;