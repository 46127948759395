import React from "react";
import { Grid, GridProps, Typography } from "@mui/material";

import '~css/components/layout/ControlGroup.scss';

interface ControlGroupProps extends GridProps {
    groupTitle: string
}

export default class ControlGroup extends React.PureComponent<ControlGroupProps, {}> {
    public render() {
        let classes = !!this.props.className ?
            `${this.props.className} ControlGroup ControlGroup__root` :
            `ControlGroup ControlGroup__root`;

        const { groupTitle, ...gridProps } = this.props;

        return (
            <Grid {...gridProps} className={classes}>
                <Typography className="ControlGroup__title">{this.props.groupTitle}</Typography>
                {this.props.children}
            </Grid>
        );
    }
}