import configFile from './config.json';

const config = {
    useSampleData: false,
    api: {
        uri: configFile.apiUri,
    },
    appUser: {
        authority: configFile.authority,
        clientId: configFile.clientId,
        clientSecret: configFile.clientSecret,
        redirectUri: configFile.spaUri + configFile.redirectUri,
        silentRedirectUri: configFile.spaUri + configFile.silentRedirectUri,
        postLogoutRedirectUri: configFile.spaUri + configFile.postLogoutRedirectUri,
        automaticSilentRenew: true,
        scope: configFile.scope,
        responseType: configFile.responseType
    },
};

export default config;