const CRMCampaignsGridFilters = {
    CampaignName: "CampaignName",
    Description: "Description",
    CampaignType: "CampaignType",
    OwnedBy: "OwnedBy",
    CreatedDateFrom: "CreatedDateFrom",
    CreatedDateTo: "CreatedDateTo",
    ExecutionDateFrom: "ExecutionDateFrom",
    ExecutionDateTo: "ExecutionDateTo",
    Status: "Status",
} as const;

export type CRMCampaignsGridFiltersType = typeof CRMCampaignsGridFilters[keyof typeof CRMCampaignsGridFilters];

export default CRMCampaignsGridFilters;