import ObjectType from "~enums/objectType";

export default class ParentObjectUtils {
    public static getParentObjectLink(id: number, type: string) {
        switch(type) {
            case ObjectType.Expense:
                return `/expenses/${id}`;
            case ObjectType.Invoice:
            case "INVOICE": //TODO remove later
                return `/invoices/${id}`;
            case ObjectType.Opportunity:
                return `/opportunities/${id}`;
            case ObjectType.Party:
                return `/companies/${id}`;
            case ObjectType.Quotation:
                return `/quotations/${id}`;
            case ObjectType.Survey:
                return `/surveys/${id}`;
            case ObjectType.Task:
                return `/tasks/${id}`;
            case ObjectType.TransactionOps:
            case "TRANSACTION": //TODO remove later
                return `/operations/${id}`;
            default:
                throw new Error("Unknown object type");
        }
    }
}