import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { ShowErrorAction } from '~store/infra/errors';

import CRMCompaniesFilters from '~enums/gridFilters/crmCompaniesFilters';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import { DataRequest } from '~models/dataRequests';
import { SortDescriptor } from "@progress/kendo-data-query";
import { FilterHandler, FilterList } from '~models/filters';

import OpportunityGridItem from '~models/opportunity/opportunityGridItem';
import NewOpportunityData from '~models/opportunity/newOpportunityData';
import { OpportunityRequestResult } from '~models/opportunity/opportunityRequestResult';

import CompanyGridItem from '~models/party/companyGridItem';
import TaskGridItem from '~models/task/taskGridItem';
import AppointmentGridItem from '~models/appointment/appointmentGridItem';
import CampaignGridItem from '~models/campaign/campaignGridItem';
import CompanyRecentGridItem from '~models/party/companyRecentGridItem';
import ContactRecentGridItem from '~models/person/contactRecentGridItem';
import OpportunityRecentGridItem from '~models/opportunity/opportunityRecentGridItem';
import TaskRecentGridItem from '~models/task/taskRecentGridItem';
import AppointmentRecentGridItem from '~models/appointment/appointmentRecentGridItem';
import CommunicationRecentGridItem from '~models/communication/communicationRecentGridItem';
import DocumentHistoryRecentGridItem from '~models/documentHistory/documentHistoryRecentGridItem';
import AppointmentCalendarItem from '~models/appointment/appointmentCalendarItem';
import TaskCalendarItem from '~models/task/taskCalendarItem';
import CRMOpportunitiesFilters from '~enums/gridFilters/crmOpportunitiesFilters';
import TasksGridFilters from '~enums/gridFilters/tasksFilters';
import CRMAppointmentsGridFilters from '~enums/gridFilters/crmAppointments';
import CRMCampaignsGridFilters from '~enums/gridFilters/crmCampaignsFilters';
import CRMRecentGridFilters from '~enums/gridFilters/crmRecentFilters';
import { NavigateAction } from '~store/infra/navigation';


export interface OpportunityTabState {
    isLoading: boolean;
    opportunities: Array<OpportunityGridItem>;
    count: number;
    skip: number;
    createStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
    opportunitiesNr: number;
    forecastTotal: number;
    approvalsNr: number;
    approvalTotal: number;
    declinesNr: number;
    declineTotal: number;
    proposalsNr: number;
    proposalTotal: number;
}

export interface TasksTabState {
    isLoading: boolean;
    tasks: Array<TaskGridItem>;
    count: number;
    skip: number;
}

export interface CompanyTabState {
    isLoading: boolean;
    companies: Array<CompanyGridItem>;
    count: number;
    skip: number;
}

export interface AppointmentTabState {
    isLoading: boolean;
    appointments: Array<AppointmentGridItem>;
    count: number;
    skip: number;
}

export interface CalendarTabState {
    isLoading: boolean;
    appointments: Array<AppointmentCalendarItem>;
    tasks: Array<TaskCalendarItem>;
}

export interface CampaignTabState {
    isLoading: boolean;
    campaigns: Array<CampaignGridItem>;
    count: number;
    skip: number;
    createStatus: ServerOperationStatus;
    cloneStatus: ServerOperationStatus;
}

export interface RecentCompaniesState {
    data: Array<CompanyRecentGridItem>;
    count: number;
    skip: number;
    isLoading: boolean;
}

export interface RecentContactsState {
    data: Array<ContactRecentGridItem>;
    count: number;
    skip: number;
    isLoading: boolean;
}

export interface RecentOpportunitiesState {
    data: Array<OpportunityRecentGridItem>;
    count: number;
    skip: number;
    isLoading: boolean;
}

export interface RecentTasksState {
    data: Array<TaskRecentGridItem>;
    count: number;
    skip: number;
    isLoading: boolean;
}

export interface RecentAppointmentsState {
    data: Array<AppointmentRecentGridItem>;
    count: number;
    skip: number;
    isLoading: boolean;
}

export interface RecentCommunicationsState {
    data: Array<CommunicationRecentGridItem>;
    count: number;
    skip: number;
    isLoading: boolean;
}

export interface RecentDocumentsState {
    data: Array<DocumentHistoryRecentGridItem>;
    count: number;
    skip: number;
    isLoading: boolean;
}

export interface RecentTabState {
    companies: RecentCompaniesState,
    contacts: RecentContactsState,
    opportunities: RecentOpportunitiesState,
    tasks: RecentTasksState,
    appointments: RecentAppointmentsState,
    communications: RecentCommunicationsState,
    documents: RecentDocumentsState,
}

export interface CRMState {
    opportunities: OpportunityTabState;
    companies: CompanyTabState;
    tasks: TasksTabState;
    appointments: AppointmentTabState;
    calendar: CalendarTabState;
    campaigns: CampaignTabState;
    recent: RecentTabState;

}


// -----------------
// ---- ACTIONS ----
// -----------------

interface OpportunitiesRequestAction {
    type: 'CRM_OPPORTUNITIES_REQUEST';
    skip: number;
}
interface OpportunitiesReceiveAction {
    type: 'CRM_OPPORTUNITIES_RECEIVE';
    data: OpportunityRequestResult;
}
interface OpportunitiesErrorAction {
    type: 'CRM_OPPORTUNITIES_ERROR';
}

interface OpportunitiesCreateStartAction {
    type: 'CRM_OPPORTUNITIES_CREATE_START';
}
interface OpportunitiesCreateSendAction {
    type: 'CRM_OPPORTUNITIES_CREATE_SEND';
}
interface OpportunitiesCreateSuccessAction {
    type: 'CRM_OPPORTUNITIES_CREATE_SUCCESS';
}
interface OpportunitiesCreateCancelAction {
    type: 'CRM_OPPORTUNITIES_CREATE_CANCEL';
}
interface OpportunitiesCreateErrorAction {
    type: 'CRM_OPPORTUNITIES_CREATE_ERROR';
}

interface OpportunitiesDeleteStartAction {
    type: 'CRM_OPPORTUNITIES_DELETE_START';
}
interface OpportunitiesDeleteSendAction {
    type: 'CRM_OPPORTUNITIES_DELETE_SEND';
}
interface OpportunitiesDeleteSuccessAction {
    type: 'CRM_OPPORTUNITIES_DELETE_SUCCESS';
}
interface OpportunitiesDeleteCancelAction {
    type: 'CRM_OPPORTUNITIES_DELETE_CANCEL';
}
interface OpportunitiesDeleteErrorAction {
    type: 'CRM_OPPORTUNITIES_DELETE_ERROR';
}

interface CompaniesRequestAction {
    type: 'CRM_COMPANIES_REQUEST';
    skip: number;
}
interface CompaniesReceiveAction {
    type: 'CRM_COMPANIES_RECEIVE';
    companies: CompanyGridItem[];
    count: number;
}
interface CompaniesErrorAction {
    type: 'CRM_COMPANIES_ERROR';
}

interface TasksRequestAction {
    type: 'CRM_TASKS_REQUEST';
    skip: number;
}
interface TasksReceiveAction {
    type: 'CRM_TASKS_RECEIVE';
    tasks: TaskGridItem[];
    count: number;
}
interface TasksErrorAction {
    type: 'CRM_TASKS_ERROR';
}

interface AppointmentsRequestAction {
    type: 'CRM_APPOINTMENTS_REQUEST';
    skip: number;
}
interface AppointmentsReceiveAction {
    type: 'CRM_APPOINTMENTS_RECEIVE';
    appointments: AppointmentGridItem[];
    count: number;
}
interface AppointmentsErrorAction {
    type: 'CRM_APPOINTMENTS_ERROR';
}

interface CalendarRequestAction {
    type: 'CRM_CALENDAR_REQUEST';
}
interface CalendarReceiveAction {
    type: 'CRM_CALENDAR_RECEIVE';
    appointments: Array<AppointmentCalendarItem>;
    tasks: Array<TaskCalendarItem>;
}
interface CalendarErrorAction {
    type: 'CRM_CALENDAR_ERROR';
}

interface CampaignsRequestAction {
    type: 'CRM_CAMPAIGNS_REQUEST';
    skip: number;
}
interface CampaignsReceiveAction {
    type: 'CRM_CAMPAIGNS_RECEIVE';
    campaigns: CampaignGridItem[];
    count: number;
}
interface CampaignsErrorAction {
    type: 'CRM_CAMPAIGNS_ERROR';
}
interface CampaignsCreateStartAction {
    type: 'CRM_CAMPAIGNS_CREATE_START';
}
interface CampaignsCreateSendAction {
    type: 'CRM_CAMPAIGNS_CREATE_SEND';
}
interface CampaignsCreateSuccessAction {
    type: 'CRM_CAMPAIGNS_CREATE_SUCCESS';
}
interface CampaignsCreateCancelAction {
    type: 'CRM_CAMPAIGNS_CREATE_CANCEL';
}
interface CampaignsCreateErrorAction {
    type: 'CRM_CAMPAIGNS_CREATE_ERROR';
}
interface CampaignsCloneSendAction {
    type: 'CRM_CAMPAIGNS_CLONE_SEND';
}
interface CampaignsCloneSuccessAction {
    type: 'CRM_CAMPAIGNS_CLONE_SUCCESS';
}
interface CampaignsCloneErrorAction {
    type: 'CRM_CAMPAIGNS_CLONE_ERROR';
}

interface RecentCompaniesRequestAction {
    type: 'CRM_RECENT_COMPANIES_REQUEST';
    skip: number;
}
interface RecentCompaniesReceiveAction {
    type: 'CRM_RECENT_COMPANIES_RECEIVE';
    companies: CompanyRecentGridItem[];
    count: number;
}
interface RecentCompaniesErrorAction {
    type: 'CRM_RECENT_COMPANIES_ERROR';
}

interface RecentContactsRequestAction {
    type: 'CRM_RECENT_CONTACTS_REQUEST';
    skip: number;
}
interface RecentContactsReceiveAction {
    type: 'CRM_RECENT_CONTACTS_RECEIVE';
    contacts: ContactRecentGridItem[];
    count: number;
}
interface RecentContactsErrorAction {
    type: 'CRM_RECENT_CONTACTS_ERROR';
}

interface RecentOpportunitiesRequestAction {
    type: 'CRM_RECENT_OPPORTUNITIES_REQUEST';
    skip: number;
}
interface RecentOpportunitiesReceiveAction {
    type: 'CRM_RECENT_OPPORTUNITIES_RECEIVE';
    opportunities: OpportunityRecentGridItem[];
    count: number;
}
interface RecentOpportunitiesErrorAction {
    type: 'CRM_RECENT_OPPORTUNITIES_ERROR';
}

interface RecentTasksRequestAction {
    type: 'CRM_RECENT_TASKS_REQUEST';
    skip: number;
}
interface RecentTasksReceiveAction {
    type: 'CRM_RECENT_TASKS_RECEIVE';
    tasks: TaskRecentGridItem[];
    count: number;
}
interface RecentTasksErrorAction {
    type: 'CRM_RECENT_TASKS_ERROR';
}

interface RecentAppointmentsRequestAction {
    type: 'CRM_RECENT_APPOINTMENTS_REQUEST';
    skip: number;
}
interface RecentAppointmentsReceiveAction {
    type: 'CRM_RECENT_APPOINTMENTS_RECEIVE';
    appointments: AppointmentRecentGridItem[];
    count: number;
}
interface RecentAppointmentsErrorAction {
    type: 'CRM_RECENT_APPOINTMENTS_ERROR';
}

interface RecentCommunicationsRequestAction {
    type: 'CRM_RECENT_COMMUNICATIONS_REQUEST';
    skip: number;
}
interface RecentCommunicationsReceiveAction {
    type: 'CRM_RECENT_COMMUNICATIONS_RECEIVE';
    communications: CommunicationRecentGridItem[];
    count: number;
}
interface RecentCommunicationsErrorAction {
    type: 'CRM_RECENT_COMMUNICATIONS_ERROR';
}

interface RecentDocumentsRequestAction {
    type: 'CRM_RECENT_DOCUMENTS_REQUEST';
    skip: number;
}
interface RecentDocumentsReceiveAction {
    type: 'CRM_RECENT_DOCUMENTS_RECEIVE';
    documents: DocumentHistoryRecentGridItem[];
    count: number;
}
interface RecentDocumentsErrorAction {
    type: 'CRM_RECENT_DOCUMENTS_ERROR';
}

type KnownAction =
    OpportunitiesRequestAction | OpportunitiesReceiveAction | OpportunitiesErrorAction
    | OpportunitiesCreateStartAction | OpportunitiesCreateSendAction
    | OpportunitiesCreateSuccessAction | OpportunitiesCreateCancelAction
    | OpportunitiesCreateErrorAction
    | OpportunitiesDeleteStartAction | OpportunitiesDeleteSendAction
    | OpportunitiesDeleteSuccessAction | OpportunitiesDeleteCancelAction
    | OpportunitiesDeleteErrorAction

    | CompaniesRequestAction | CompaniesReceiveAction | CompaniesErrorAction

    | TasksRequestAction | TasksReceiveAction | TasksErrorAction

    | AppointmentsRequestAction | AppointmentsReceiveAction | AppointmentsErrorAction

    | CalendarRequestAction | CalendarReceiveAction | CalendarErrorAction

    | CampaignsRequestAction | CampaignsReceiveAction | CampaignsErrorAction
    | CampaignsCreateStartAction | CampaignsCreateSendAction
    | CampaignsCreateSuccessAction | CampaignsCreateCancelAction
    | CampaignsCreateErrorAction
    | CampaignsCloneSendAction | CampaignsCloneSuccessAction | CampaignsCloneErrorAction

    | RecentCompaniesRequestAction | RecentCompaniesReceiveAction | RecentCompaniesErrorAction
    | RecentContactsRequestAction | RecentContactsReceiveAction | RecentContactsErrorAction
    | RecentOpportunitiesRequestAction | RecentOpportunitiesReceiveAction | RecentOpportunitiesErrorAction
    | RecentTasksRequestAction | RecentTasksReceiveAction | RecentTasksErrorAction
    | RecentAppointmentsRequestAction | RecentAppointmentsReceiveAction | RecentAppointmentsErrorAction
    | RecentCommunicationsRequestAction | RecentCommunicationsReceiveAction | RecentCommunicationsErrorAction
    | RecentDocumentsRequestAction | RecentDocumentsReceiveAction | RecentDocumentsErrorAction

    | NavigateAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const opportunityActionCreators = {
    requestOpportunities: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && !appState.crm.opportunities.isLoading) {
            let oppFilters = {...filters};

            if (!!oppFilters[CRMOpportunitiesFilters.CreatedTo]?.value) {
                const dateValue = new Date(oppFilters[CRMOpportunitiesFilters.CreatedTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                oppFilters[CRMOpportunitiesFilters.CreatedTo] = { value: dateValue };
            }

            if (!!oppFilters[CRMOpportunitiesFilters.RelevantTillTo]?.value) {
                const dateValue = new Date(oppFilters[CRMOpportunitiesFilters.RelevantTillTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                oppFilters[CRMOpportunitiesFilters.RelevantTillTo] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<OpportunityRequestResult>('/v1/opportunities/search', 'POST', oppFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.CRMOpportunities}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.CRMOpportunities}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.CRMOpportunities}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((file) => {
                        !!file.expireOn && (file.expireOn = new Date(file.expireOn));
                    });
                    dispatch({ type: 'CRM_OPPORTUNITIES_RECEIVE', data: data, });
                })
                .catch(err => {
                    dispatch({ type: 'CRM_OPPORTUNITIES_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_OPPORTUNITIES_REQUEST', skip: skip, });
        }
    },
    startCreateOpportunity: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm) {
            dispatch({ type: 'CRM_OPPORTUNITIES_CREATE_START' });
        }
    },
    createOpportunity: (data: NewOpportunityData):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm) {
            apiClientInstance.fetchRequest(
                '/v1/opportunities/',
                'POST',
                data
            )
                .then((newId) => {
                    dispatch({ type: 'NAVIGATION_NAVIGATE', route: `/opportunities/${newId}` });

                    dispatch({ type: 'CRM_OPPORTUNITIES_CREATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'CRM_OPPORTUNITIES_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_OPPORTUNITIES_CREATE_SEND' });
        }
    },
    cancelCreateOpportunity: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm) {
            dispatch({ type: 'CRM_OPPORTUNITIES_CREATE_CANCEL' });
        }
    },
    startDeleteOpportunity: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm) {
            dispatch({ type: 'CRM_OPPORTUNITIES_DELETE_START' });
        }
    },
    deleteOpportunity: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm) {
            apiClientInstance.fetchRequest(
                `/v1/opportunities/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'CRM_OPPORTUNITIES_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'CRM_OPPORTUNITIES_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_OPPORTUNITIES_DELETE_SEND' });
        }
    },
    cancelDeleteOpportunity: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm) {
            dispatch({ type: 'CRM_OPPORTUNITIES_DELETE_CANCEL' });
        }
    },
};

export const companiesActionCreators = {
    requestCompanies: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && !appState.crm.companies.isLoading) {
            let compFilters = {...filters};

            if (!!compFilters[CRMCompaniesFilters.To]?.value) {
                const dateValue = new Date(compFilters[CRMCompaniesFilters.To].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                compFilters[CRMCompaniesFilters.To] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<CompanyGridItem>>('/v1/parties/companies', 'POST', compFilters, skip, pageSize, sorting, {[CRMCompaniesFilters.ExcludeSystemTypes]: true})
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.CRMCompanies}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.CRMCompanies}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.CRMCompanies}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'CRM_COMPANIES_RECEIVE', companies: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'CRM_COMPANIES_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_COMPANIES_REQUEST', skip: skip });
        }
    },
};

export const tasksActionCreators = {
    requestTasks: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && !appState.crm.tasks.isLoading) {
            let tasksFilters = {...filters};

            if (!!tasksFilters[TasksGridFilters.DateTo]?.value) {
                const dateValue = new Date(tasksFilters[TasksGridFilters.DateTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                tasksFilters[TasksGridFilters.DateTo] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<TaskGridItem>>('/v1/tasks/search', 'POST', tasksFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.CRMTasks}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.CRMTasks}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.CRMTasks}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((task) => {
                        !!task.startDate && (task.startDate = new Date(task.startDate));
                        !!task.dueDate && (task.dueDate = new Date(task.dueDate));
                    });

                    dispatch({ type: 'CRM_TASKS_RECEIVE', tasks: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'CRM_TASKS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_TASKS_REQUEST', skip: skip });
        }
    },
};

export const appointmentsActionCreators = {
    requestAppointments: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && !appState.crm.appointments.isLoading) {
            let appFilters = {...filters};

            if (!!appFilters[CRMAppointmentsGridFilters.DateTo]?.value) {
                const dateValue = new Date(appFilters[CRMAppointmentsGridFilters.DateTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                appFilters[CRMAppointmentsGridFilters.DateTo] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<AppointmentGridItem>>('/v1/appointments/search', 'POST', appFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.CRMAppointments}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.CRMAppointments}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.CRMAppointments}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((app) => {
                        !!app.startDate && (app.startDate = new Date(app.startDate));
                    });

                    dispatch({ type: 'CRM_APPOINTMENTS_RECEIVE', appointments: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'CRM_APPOINTMENTS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_APPOINTMENTS_REQUEST', skip: skip });
        }
    },
};

export const calendarActionCreators = {
    requestData: (filters: FilterList): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && !appState.crm.calendar.isLoading) {
            Promise.all([
                FilterHandler.getApiFilterRequestPromise<Array<AppointmentCalendarItem>>('/v1/appointments/calendar', 'POST', filters),
                FilterHandler.getApiFilterRequestPromise<Array<TaskCalendarItem>>('/v1/tasks/calendar', 'POST', filters)
            ]).then(data => {
                dispatch(UserActions.updateUserPreferences({[`${UserPreferencePage.CRMCalendar}-${UserPreferenceKey.Filters}`]: filters}));

                let appointments = data[0];
                let tasks = data[1];

                appointments.forEach((app) => {
                    app.startDate = new Date(app.startDate);
                    app.endDate = new Date(app.endDate);
                });
                tasks.forEach((task) => {
                    task.startDate = new Date(task.startDate);
                    task.dueDate = new Date(task.dueDate);
                });

                dispatch({ type: 'CRM_CALENDAR_RECEIVE', appointments: appointments, tasks: tasks, });
            }).catch(err => {
                dispatch({ type: 'CRM_CALENDAR_ERROR' });
                dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
            });

            dispatch({ type: 'CRM_CALENDAR_REQUEST', });
        }
    },
};

export const campaignsActionCreators = {
    requestCampaigns: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && !appState.crm.campaigns.isLoading) {
            let campFilters = {...filters};

            if (!!campFilters[CRMCampaignsGridFilters.CreatedDateTo]?.value) {
                const dateValue = new Date(campFilters[CRMCampaignsGridFilters.CreatedDateTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                campFilters[CRMCampaignsGridFilters.CreatedDateTo] = { value: dateValue };
            }

            if (!!campFilters[CRMCampaignsGridFilters.ExecutionDateTo]?.value) {
                const dateValue = new Date(campFilters[CRMCampaignsGridFilters.ExecutionDateTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                campFilters[CRMCampaignsGridFilters.ExecutionDateTo] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<CampaignGridItem>>('/v1/campaigns/search', 'POST', campFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.CRMCampaigns}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.CRMCampaigns}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.CRMCampaigns}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((app) => {
                        !!app.createdOn && (app.createdOn = new Date(app.createdOn));
                        !!app.executionDate && (app.executionDate = new Date(app.executionDate));
                    });

                    dispatch({ type: 'CRM_CAMPAIGNS_RECEIVE', campaigns: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'CRM_CAMPAIGNS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_CAMPAIGNS_REQUEST', skip: skip });
        }
    },
    startCreateCampaign: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm) {
            dispatch({ type: 'CRM_CAMPAIGNS_CREATE_START' });
        }
    },
    createCampaign: (name: string):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm) {
            apiClientInstance.fetchRequest(
                '/v1/campaigns/',
                'POST',
                {campaignName: name}
            )
                .then((newId) => {
                    dispatch({ type: 'NAVIGATION_NAVIGATE', route: `/campaigns/${newId}` });

                    dispatch({ type: 'CRM_CAMPAIGNS_CREATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'CRM_CAMPAIGNS_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_CAMPAIGNS_CREATE_SEND' });
        }
    },
    cancelCreateCampaign: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm) {
            dispatch({ type: 'CRM_CAMPAIGNS_CREATE_CANCEL' });
        }
    },
    cloneCampaign: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && appState.crm.campaigns.cloneStatus !== ServerOperationStatus.INPROGRESS) {
            apiClientInstance.fetchRequest(
                '/v1/campaigns/clone',
                'POST',
                {campaignId: id}
            )
                .then((newId) => {
                    dispatch({ type: 'NAVIGATION_NAVIGATE', route: `/campaigns/${newId}` });

                    dispatch({ type: 'CRM_CAMPAIGNS_CLONE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'CRM_CAMPAIGNS_CLONE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_CAMPAIGNS_CLONE_SEND' });
        }
    },
};

export const recentActionCreators = {
    requestCompanies: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && !appState.crm.recent.companies.isLoading) {
            let recentFilters = {...filters};

            if (!!recentFilters[CRMRecentGridFilters.AddedDateTo]?.value) {
                const dateValue = new Date(recentFilters[CRMRecentGridFilters.AddedDateTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                recentFilters[CRMRecentGridFilters.AddedDateTo] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<CompanyRecentGridItem>>('/v1/parties/recent', 'POST', recentFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.RecentGrids}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.RecentCompanies}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.RecentCompanies}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'CRM_RECENT_COMPANIES_RECEIVE', companies: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'CRM_RECENT_COMPANIES_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_RECENT_COMPANIES_REQUEST', skip: skip });
        }
    },
    requestContacts: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && !appState.crm.recent.contacts.isLoading) {
            let recentFilters = {...filters};

            if (!!recentFilters[CRMRecentGridFilters.AddedDateTo]?.value) {
                const dateValue = new Date(recentFilters[CRMRecentGridFilters.AddedDateTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                recentFilters[CRMRecentGridFilters.AddedDateTo] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<ContactRecentGridItem>>('/v1/persons/recent', 'POST', recentFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.RecentGrids}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.RecentContacts}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.RecentContacts}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'CRM_RECENT_CONTACTS_RECEIVE', contacts: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'CRM_RECENT_CONTACTS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_RECENT_CONTACTS_REQUEST', skip: skip });
        }
    },
    requestOpportunities: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && !appState.crm.recent.opportunities.isLoading) {
            let recentFilters = {...filters};

            if (!!recentFilters[CRMRecentGridFilters.AddedDateTo]?.value) {
                const dateValue = new Date(recentFilters[CRMRecentGridFilters.AddedDateTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                recentFilters[CRMRecentGridFilters.AddedDateTo] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<OpportunityRecentGridItem>>('/v1/opportunities/recent', 'POST', recentFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.RecentGrids}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.RecentOpportunities}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.RecentOpportunities}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((app) => {
                        !!app.createdOn && (app.createdOn = new Date(app.createdOn));
                    });

                    dispatch({ type: 'CRM_RECENT_OPPORTUNITIES_RECEIVE', opportunities: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'CRM_RECENT_OPPORTUNITIES_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_RECENT_OPPORTUNITIES_REQUEST', skip: skip });
        }
    },
    requestAppointments: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && !appState.crm.recent.appointments.isLoading) {
            let recentFilters = {...filters};

            if (!!recentFilters[CRMRecentGridFilters.AddedDateTo]?.value) {
                const dateValue = new Date(recentFilters[CRMRecentGridFilters.AddedDateTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                recentFilters[CRMRecentGridFilters.AddedDateTo] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<AppointmentRecentGridItem>>('/v1/appointments/recent', 'POST', recentFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.RecentGrids}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.RecentAppointments}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.RecentAppointments}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((app) => {
                        !!app.createdOn && (app.createdOn = new Date(app.createdOn));
                    });

                    dispatch({ type: 'CRM_RECENT_APPOINTMENTS_RECEIVE', appointments: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'CRM_RECENT_APPOINTMENTS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_RECENT_APPOINTMENTS_REQUEST', skip: skip });
        }
    },
    requestTasks: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && !appState.crm.recent.tasks.isLoading) {
            let recentFilters = {...filters};

            if (!!recentFilters[CRMRecentGridFilters.AddedDateTo]?.value) {
                const dateValue = new Date(recentFilters[CRMRecentGridFilters.AddedDateTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                recentFilters[CRMRecentGridFilters.AddedDateTo] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<TaskRecentGridItem>>('/v1/tasks/recent', 'POST', recentFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.RecentGrids}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.RecentTasks}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.RecentTasks}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((app) => {
                        !!app.createdOn && (app.createdOn = new Date(app.createdOn));
                    });

                    dispatch({ type: 'CRM_RECENT_TASKS_RECEIVE', tasks: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'CRM_RECENT_TASKS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_RECENT_TASKS_REQUEST', skip: skip });
        }
    },
    requestCommunications: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && !appState.crm.recent.communications.isLoading) {
            let recentFilters = {...filters};

            if (!!recentFilters[CRMRecentGridFilters.AddedDateTo]?.value) {
                const dateValue = new Date(recentFilters[CRMRecentGridFilters.AddedDateTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                recentFilters[CRMRecentGridFilters.AddedDateTo] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<CommunicationRecentGridItem>>('/v1/communications/recent', 'POST', recentFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.RecentGrids}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.RecentCommunications}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.RecentCommunications}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((app) => {
                        !!app.createdOn && (app.createdOn = new Date(app.createdOn));
                    });

                    dispatch({ type: 'CRM_RECENT_COMMUNICATIONS_RECEIVE', communications: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'CRM_RECENT_COMMUNICATIONS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_RECENT_COMMUNICATIONS_REQUEST', skip: skip });
        }
    },
    requestDocuments: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.crm && !appState.crm.recent.documents.isLoading) {
            let recentFilters = {...filters};

            if (!!recentFilters[CRMRecentGridFilters.AddedDateTo]?.value) {
                const dateValue = new Date(recentFilters[CRMRecentGridFilters.AddedDateTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                recentFilters[CRMRecentGridFilters.AddedDateTo] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<DocumentHistoryRecentGridItem>>('/v1/document-history/recent', 'POST', recentFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.RecentGrids}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.RecentDocuments}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.RecentDocuments}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((app) => {
                        !!app.createdOn && (app.createdOn = new Date(app.createdOn));
                    });

                    dispatch({ type: 'CRM_RECENT_DOCUMENTS_RECEIVE', documents: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'CRM_RECENT_DOCUMENTS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CRM_RECENT_DOCUMENTS_REQUEST', skip: skip });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: CRMState = {
    opportunities: {
        isLoading: false,
        opportunities: [],
        count: 0,
        skip: 0,
        createStatus: ServerOperationStatus.NONE,
        deleteStatus: ServerOperationStatus.NONE,
        opportunitiesNr: 0,
        forecastTotal: 0,
        approvalsNr: 0,
        approvalTotal: 0,
        declinesNr: 0,
        declineTotal: 0,
        proposalsNr: 0,
        proposalTotal: 0,
    },
    companies: {
        isLoading: false,
        companies: [],
        count: 0,
        skip: 0,
    },
    tasks: {
        isLoading: false,
        tasks: [],
        count: 0,
        skip: 0,
    },
    appointments: {
        isLoading: false,
        appointments: [],
        count: 0,
        skip: 0,
    },
    calendar: {
        isLoading: false,
        appointments: [],
        tasks: [],
    },
    campaigns: {
        isLoading: false,
        campaigns: [],
        count: 0,
        skip: 0,
        createStatus: ServerOperationStatus.NONE,
        cloneStatus: ServerOperationStatus.NONE,
    },
    recent: {
        companies: {
            data: [],
            count: 0,
            isLoading: false,
            skip: 0,
        },
        contacts: {
            data: [],
            count: 0,
            isLoading: false,
            skip: 0,
        },
        opportunities: {
            data: [],
            count: 0,
            isLoading: false,
            skip: 0,
        },
        tasks: {
            data: [],
            count: 0,
            isLoading: false,
            skip: 0,
        },
        appointments: {
            data: [],
            count: 0,
            isLoading: false,
            skip: 0,
        },
        communications: {
            data: [],
            count: 0,
            isLoading: false,
            skip: 0,
        },
        documents: {
            data: [],
            count: 0,
            isLoading: false,
            skip: 0,
        },
    },
 };

export const reducer: Reducer<CRMState> = (state: CRMState | undefined, incomingAction: Action): CRMState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CRM_OPPORTUNITIES_REQUEST':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    isLoading: true,
                    skip: action.skip,
                }
            };
        case 'CRM_OPPORTUNITIES_RECEIVE':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    opportunities: action.data.data,
                    count: action.data.count,
                    opportunitiesNr: action.data.opportunitiesNr,
                    forecastTotal: action.data.forecastTotal,
                    approvalsNr: action.data.approvalsNr,
                    approvalTotal: action.data.approvalTotal,
                    declinesNr: action.data.declinesNr,
                    declineTotal: action.data.declineTotal,
                    proposalsNr: action.data.proposalsNr,
                    proposalTotal: action.data.proposalTotal,
                    isLoading: false,
                }
            };
        case 'CRM_OPPORTUNITIES_ERROR':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    isLoading: false,
                },
            };

        case 'CRM_OPPORTUNITIES_CREATE_START':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    createStatus: ServerOperationStatus.READY,
                }
            };
        case 'CRM_OPPORTUNITIES_CREATE_SEND':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    isLoading: true,
                    createStatus: ServerOperationStatus.INPROGRESS,
                }
            };
        case 'CRM_OPPORTUNITIES_CREATE_CANCEL':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    createStatus: ServerOperationStatus.NONE,
                }
            };
        case 'CRM_OPPORTUNITIES_CREATE_SUCCESS':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    isLoading: false,
                    createStatus: ServerOperationStatus.SUCCESS,
                }
            };
        case 'CRM_OPPORTUNITIES_CREATE_ERROR':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    isLoading: false,
                    createStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'CRM_OPPORTUNITIES_DELETE_START':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    deleteStatus: ServerOperationStatus.READY,
                }
            };
        case 'CRM_OPPORTUNITIES_DELETE_SEND':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    deleteStatus: ServerOperationStatus.INPROGRESS,
                }
            };
        case 'CRM_OPPORTUNITIES_DELETE_CANCEL':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    deleteStatus: ServerOperationStatus.NONE,
                }
            };
        case 'CRM_OPPORTUNITIES_DELETE_SUCCESS':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    deleteStatus: ServerOperationStatus.SUCCESS,
                }
            };
        case 'CRM_OPPORTUNITIES_DELETE_ERROR':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    deleteStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'CRM_COMPANIES_REQUEST':
            return {
                ...state,
                companies: {
                    ...state.companies,
                    isLoading: true,
                    skip: action.skip,
                }
            };
        case 'CRM_COMPANIES_RECEIVE':
            return {
                ...state,
                companies: {
                    ...state.companies,
                    companies: action.companies,
                    count: action.count,
                    isLoading: false,
                }
            };
        case 'CRM_COMPANIES_ERROR':
            return {
                ...state,
                companies: {
                    ...state.companies,
                    isLoading: false,
                },
            };

        case 'CRM_TASKS_REQUEST':
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    isLoading: true,
                    skip: action.skip,
                }
            };
        case 'CRM_TASKS_RECEIVE':
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    tasks: action.tasks,
                    count: action.count,
                    isLoading: false,
                }
            };
        case 'CRM_TASKS_ERROR':
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    isLoading: false,
                },
            };

        case 'CRM_APPOINTMENTS_REQUEST':
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    isLoading: true,
                    skip: action.skip,
                }
            };
        case 'CRM_APPOINTMENTS_RECEIVE':
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    appointments: action.appointments,
                    count: action.count,
                    isLoading: false,
                }
            };
        case 'CRM_APPOINTMENTS_ERROR':
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    isLoading: false,
                },
            };

        case 'CRM_CALENDAR_REQUEST':
            return {
                ...state,
                calendar: {
                    ...state.calendar,
                    isLoading: true,
                },
            };
        case 'CRM_CALENDAR_RECEIVE':
            return {
                ...state,
                calendar: {
                    appointments: action.appointments,
                    tasks: action.tasks,
                    isLoading: false,
                },
            };
        case 'CRM_CALENDAR_ERROR':
            return {
                ...state,
                calendar: {
                    ...state.calendar,
                    isLoading: false,
                },
            };

        case 'CRM_CAMPAIGNS_REQUEST':
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    isLoading: true,
                    skip: action.skip,
                }
            };
        case 'CRM_CAMPAIGNS_RECEIVE':
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    campaigns: action.campaigns,
                    count: action.count,
                    isLoading: false,
                }
            };
        case 'CRM_CAMPAIGNS_ERROR':
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    isLoading: false,
                },
            };
        case 'CRM_CAMPAIGNS_CREATE_START':
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    createStatus: ServerOperationStatus.READY,
                }
            };
        case 'CRM_CAMPAIGNS_CREATE_SEND':
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    createStatus: ServerOperationStatus.INPROGRESS,
                }
            };
        case 'CRM_CAMPAIGNS_CREATE_CANCEL':
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    createStatus: ServerOperationStatus.NONE,
                }
            };
        case 'CRM_CAMPAIGNS_CREATE_SUCCESS':
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    createStatus: ServerOperationStatus.SUCCESS,
                }
            };
        case 'CRM_CAMPAIGNS_CREATE_ERROR':
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    createStatus: ServerOperationStatus.ERROR,
                },
            };
        case 'CRM_CAMPAIGNS_CLONE_SEND':
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    cloneStatus: ServerOperationStatus.INPROGRESS,
                }
            };
        case 'CRM_CAMPAIGNS_CLONE_SUCCESS':
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    cloneStatus: ServerOperationStatus.SUCCESS,
                }
            };
        case 'CRM_CAMPAIGNS_CLONE_ERROR':
            return {
                ...state,
                campaigns: {
                    ...state.campaigns,
                    cloneStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'CRM_RECENT_COMPANIES_REQUEST':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    companies: {
                        ...state.recent.companies,
                        skip: action.skip,
                        isLoading: true,
                    },
                },
            };
        case 'CRM_RECENT_COMPANIES_RECEIVE':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    companies: {
                        data: action.companies,
                        count: action.count,
                        isLoading: false,
                        skip: state.recent.companies.skip,
                    },
                },
            };
        case 'CRM_RECENT_COMPANIES_ERROR':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    companies: {
                        ...state.recent.companies,
                        isLoading: false,
                    }
                },
            };
        case 'CRM_RECENT_CONTACTS_REQUEST':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    contacts: {
                        ...state.recent.contacts,
                        skip: action.skip,
                        isLoading: true,
                    },
                },
            };
        case 'CRM_RECENT_CONTACTS_RECEIVE':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    contacts: {
                        data: action.contacts,
                        count: action.count,
                        isLoading: false,
                        skip: state.recent.contacts.skip,
                    },
                },
            };
        case 'CRM_RECENT_CONTACTS_ERROR':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    contacts: {
                        ...state.recent.contacts,
                        isLoading: false,
                    },
                },
            };
        case 'CRM_RECENT_OPPORTUNITIES_REQUEST':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    opportunities: {
                        ...state.recent.opportunities,
                        skip: action.skip,
                        isLoading: true,
                    },
                },
            };
        case 'CRM_RECENT_OPPORTUNITIES_RECEIVE':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    opportunities: {
                        data: action.opportunities,
                        count: action.count,
                        isLoading: false,
                        skip: state.recent.opportunities.skip,
                    },
                },
            };
        case 'CRM_RECENT_OPPORTUNITIES_ERROR':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    opportunities: {
                        ...state.recent.opportunities,
                        isLoading: false,
                    },
                },
            };
        case 'CRM_RECENT_APPOINTMENTS_REQUEST':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    appointments: {
                        ...state.recent.appointments,
                        skip: action.skip,
                        isLoading: true,
                    },
                },
            };
        case 'CRM_RECENT_APPOINTMENTS_RECEIVE':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    appointments: {
                        data: action.appointments,
                        count: action.count,
                        isLoading: false,
                        skip: state.recent.appointments.skip,
                    },
                },
            };
        case 'CRM_RECENT_APPOINTMENTS_ERROR':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    appointments: {
                        ...state.recent.appointments,
                        isLoading: false,
                    },
                },
            };
        case 'CRM_RECENT_TASKS_REQUEST':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    tasks: {
                        ...state.recent.tasks,
                        skip: action.skip,
                        isLoading: true,
                    },
                },
            };
        case 'CRM_RECENT_TASKS_RECEIVE':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    tasks: {
                        data: action.tasks,
                        count: action.count,
                        isLoading: false,
                        skip: state.recent.tasks.skip,
                    },
                },
            };
        case 'CRM_RECENT_TASKS_ERROR':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    tasks: {
                        ...state.recent.tasks,
                        isLoading: false,
                    },
                },
            };
        case 'CRM_RECENT_COMMUNICATIONS_REQUEST':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    communications: {
                        ...state.recent.communications,
                        skip: action.skip,
                        isLoading: true,
                    },
                },
            };
        case 'CRM_RECENT_COMMUNICATIONS_RECEIVE':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    communications: {
                        data: action.communications,
                        count: action.count,
                        isLoading: false,
                        skip: state.recent.communications.skip,
                    },
                },
            };
        case 'CRM_RECENT_COMMUNICATIONS_ERROR':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    communications: {
                        ...state.recent.communications,
                        isLoading: false,
                    },
                },
            };
        case 'CRM_RECENT_DOCUMENTS_REQUEST':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    documents: {
                        ...state.recent.documents,
                        skip: action.skip,
                        isLoading: true,
                    },
                },
            };
        case 'CRM_RECENT_DOCUMENTS_RECEIVE':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    documents: {
                        data: action.documents,
                        count: action.count,
                        isLoading: false,
                        skip: state.recent.documents.skip,
                    },
                },
            };
        case 'CRM_RECENT_DOCUMENTS_ERROR':
            return {
                ...state,
                recent: {
                    ...state.recent,
                    documents: {
                        ...state.recent.documents,
                        isLoading: false,
                    },
                },
            };
    }

    return state;
};