const CampaignType = Object.freeze({
    //TODO swap when entities are updated    
    Email: "Email",
    Mailout: "Mailout",
    Callout: "Callout",
    //Email: "Enum.TaskStatus.Email",
    //Mailout: "Enum.TaskStatus.Mailout",
    //Callout: "Enum.TaskStatus.Callout",
});

export type CampaignTypeType = typeof CampaignType[keyof typeof CampaignType];

export default CampaignType;