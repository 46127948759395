import React from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material';
import { Grid } from '@mui/material';

import '~css/components/filtering/FilterItem.scss';


interface Props {
    children?: React.ReactNode;
    className?: string;
}

function FilterItem(props: Props) {
    return (
        <Grid item className={`FilterItem ${clsx(props.className)}`}>
            {props.children || 'class names'}
        </Grid>
    );
}

export default styled(FilterItem)(({ theme }) => ({
        [theme.breakpoints.up('xs')]: {
            flexGrow: '0',
            maxWidth: '50%',
            flexBasis: '50%',
        },
        [theme.breakpoints.up('sm')]: {
            flexGrow: '0',
            maxWidth: '33%',
            flexBasis: '33%',
        },
        [theme.breakpoints.up('smx')]: {
            flexGrow: '0',
            maxWidth: '25%',
            flexBasis: '25%',
        },
        [theme.breakpoints.up('mdx')]: {
            flexGrow: '0',
            maxWidth: '20%',
            flexBasis: '20%',
        },
        [theme.breakpoints.up('lg')]: {
            flexGrow: '0',
            maxWidth: '16.666667%',
            flexBasis: '16.666667%',
        },
        [theme.breakpoints.up('lgx')]: {
            flexGrow: '0',
            maxWidth: '14.285714%',
            flexBasis: '14.285714%',
        },
        [theme.breakpoints.up('xl')]: {
            flexGrow: '0',
            maxWidth: '8.333333%%',
            flexBasis: '8.333333%%',
        },
}));