import React from 'react';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { getNonEmptyParams } from '~utils/urlUtils';
import equal from 'fast-deep-equal/react';
import { DropDownResponse, OnDemandDropDownProps, OnDemandDropDownState } from './types';

import {
    DropDownList,
    DropDownListChangeEvent,
    DropDownListFilterChangeEvent,
    DropDownListOpenEvent,
    DropDownListPageChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { OptionedDropDown } from './OptionedDropDown';

import '~css/components/controls/OnDemandDropdown.scss';


class OnDemandDropDown extends React.Component<OnDemandDropDownProps, OnDemandDropDownState> {
    dataCaching: any[] = [];
    loadingData: any[] = [];
    pendingRequest: NodeJS.Timeout | null = null;
    requestStarted = false;
    emptyItem: any;
    pageSize: number;
    __drop?: DropDownList | null;
    __timeout?: NodeJS.Timeout;
    __filterTimeout?: NodeJS.Timeout;

    constructor(props: OnDemandDropDownProps) {
        super(props);
        this.emptyItem = { [props.dataItemKey]: null, [props.textField]: "Loading..." };
        while (this.loadingData.length < props.pageSize) {
            this.loadingData.push({ ...this.emptyItem });
        }
        this.pageSize = props.pageSize;
        this.state = {
            data: [],
            skip: 0,
            total: 0,
            filter: "",
        };
    }

    componentDidUpdate(prevProps: OnDemandDropDownProps) {
        if(!equal(prevProps.urlParams, this.props.urlParams) || prevProps.url !== this.props.url) {
            this.resetData();
        }
    }

    componentWillUnmount() {
        this.resetCache();
    }

    public resetData() {
        !!this.pendingRequest && clearTimeout(this.pendingRequest);
        this.dataCaching = [];
        this.loadingData = [];
        this.requestStarted = false;
        this.setState({
            data: [],
            skip: 0,
            total: 0,
            filter: "",
        });
    }

    private resetCache() {
        this.dataCaching.length = 0;
    }

    private requestData(skip: number, filter: string) {
        if (this.requestStarted) {
            !!this.pendingRequest && clearTimeout(this.pendingRequest);
            this.pendingRequest = setTimeout(() => {
                this.requestData(skip, filter);
            }, 500);
            return;
        }

        let url = this.props.url;
        let params: {[key: string]: any} = {};
        params = {
            skip: skip,
            pageSize: this.pageSize,
            count: true,
            filter: filter,
        };
        !!this.props.urlParams && (
            params = {
                ...params,
                ...this.props.urlParams
            }
        );

        let urlParams = getNonEmptyParams(params);
        if (urlParams.toString() !== '')
            url += `?${urlParams}`;

        this.requestStarted = true;
        apiClientInstance.fetchRequest<DropDownResponse>(url, 'GET')
            .then((json) => {
                const total = json.count;
                const items = [] as any[];
                const localization = provideLocalizationService(this);

                json.data.forEach((element, index) => {
                    let item = element;
                    if (!!this.props.itemMapper) {
                        item = this.props.itemMapper(element);
                    }

                    if (!!this.props.localizeText) {
                        item[this.props.textField] =
                            localization.toLanguageString(
                                item[this.props.textField],
                                item[this.props.textField]
                            );
                    }
                    items.push(item);
                    this.dataCaching[index + skip] = item;
                });

                if (skip === this.state.skip) {
                    this.setState({
                        data: items,
                        total: total,
                    });
                }

                this.requestStarted = false;
            });
    }

    private onFilterChange = (event: DropDownListFilterChangeEvent) => {
        const filter = event.filter.value;

        if (!!this.props.minFilter && filter.length < this.props.minFilter) {
            this.resetData();
            return;
        }

        !!this.__filterTimeout && clearTimeout(this.__filterTimeout);
        this.__filterTimeout = setTimeout(() => {
            this.resetCache();
            this.requestData(0, filter);

            this.setState({
                data: this.loadingData,
                skip: 0,
                filter: filter,
            });
        }, this.props.delay ?? 1000);
    }

    private shouldRequestData(skip: number) {
        for (let i = 0; i < this.pageSize; i++) {
            if (!this.dataCaching[skip + i]) {
                return true;
            }
        }
        return false;
    }

    private getCachedData(skip: number) {
        const data = [];
        for (let i = 0; i < this.pageSize; i++) {
            data.push(this.dataCaching[i + skip] || { ...this.emptyItem });
        }
        return data;
    }

    private pageChange(event: DropDownListPageChangeEvent) {
        const skip = event.page.skip;
        const filter = this.state.filter;

        if (!!this.props.minFilter && filter.length < this.props.minFilter) {
            return;
        }

        if (this.shouldRequestData(skip)) {
            !!this.__timeout && clearTimeout(this.__timeout);
            this.__timeout = setTimeout(() => {
                this.requestData(skip, filter);
            }, this.props.delay ?? 1000);
        }

        const data = this.getCachedData(skip);

        this.setState({
            data: data,
            skip: skip,
        });
    }

    private onOpen(event: DropDownListOpenEvent) {
        const filter = this.state.filter;

        if (!!this.props.minFilter && filter.length < this.props.minFilter) {
            return;
        }

        if (this.state.data.length === 0) {
            this.requestData(0, this.state.filter);
        }
    }

    private async onChange(event: DropDownListChangeEvent) {
        const value = event.target.value;
        const propsValue = this.props.value ? this.props.value[this.props.dataItemKey] : '';
        if (value && value[this.props.textField] === this.emptyItem[this.props.textField]) return;
        if (value[this.props.dataItemKey] === propsValue) return;

        if(!!this.props.onChange) {
            let promise = this.props.onChange(event);
            if(!!promise) {
                await promise;
                this.__drop?.focus();
            } else {
                this.__drop?.focus();
            }
        }
    }

    private renderNoData(element: React.ReactElement<HTMLDivElement>) {
        if (!!this.props.listNoDataRender) {
            return this.props.listNoDataRender(element, this.state.filter.length);
        }
        return (<div></div>);
    }

    render() {
        let classes = 'OnDemandDropDown';
        if(!!this.props.className) classes += ` ${this.props.className}`;

        return (

            <OptionedDropDown
                ref={(drop) => {this.__drop = drop?.__drop;}}
                {...this.props}
                id={this.props.id}
                name={this.props.name}
                label={this.props.label}
                data-field={this.props.field}

                className={classes}
                data={this.state.data}
                value={this.props.value}
                delay={this.props.delay || 1000}
                onOpen={this.onOpen.bind(this)}
                onChange={this.onChange.bind(this)}
                disabled={this.props.disabled}

                dataItemKey={this.props.dataItemKey}
                textField={this.props.textField}
                filterable={true}
                onFilterChange={this.onFilterChange.bind(this)}
                virtual={{
                    pageSize: this.pageSize,
                    skip: this.state.skip,
                    total: this.state.total,
                }}
                onPageChange={this.pageChange.bind(this)}
                listNoDataRender={!!this.props.listNoDataRender ? this.renderNoData.bind(this) : undefined}
            />
        );
    }
}

registerForLocalization(OnDemandDropDown as React.ComponentClass<any>);

export default OnDemandDropDown;