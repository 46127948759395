import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { apiClientInstance } from '~services/auth/ApiClientInstance';

import CampaignFields, { CampaignFieldsType } from '~enums/fields/campaign';
import CampaignDetails from '~models/campaign/campaignDetails';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';
import { ShowErrorAction } from '~store/infra/errors';
import { DataRequest } from '~models/dataRequests';
import { PersonListItem } from '~models/person/personListItem';
import { parsePersonString } from '~src/utils/personUtils';


export interface CampaignDetailsState {
    isLoading: boolean;
    data: CampaignDetails;
    updatedField?: CampaignFieldsType;
    companyContacts: Array<CampaignContact>;
    companyContactsCount: number;
    opportunityContacts: Array<CampaignContact>;
    opportunityContactsCount: number;
    campaignContacts: Array<CampaignContact>;
}

const EmptyData: CampaignDetails = {
    campaignId: 0,
    uniqueId: '',
    createdOn: new Date(),
    ownedBy: 0,
    ownedByName: '',
    campaignType: '',
    status: '',
    executionDate: null,
    campaignName: '',
    description: '',
    companySearch: '',
    opportunitySearch: '',
};

export type CompanySearch = {
    CompanyCode: string;
    CompanyName: string;
    CompanyType: Array<string>;
    CompanyCategory: Array<string>;
    CompanyCountry: string;
    CompanyCity: string;
    CompanyState: string;
    ContactType: Array<string>;
};

export type OpportunitySearch = {
    Competitors: string,
    Territory: Array<string>,
    Department: Array<string>,
    JobType: Array<string>,
    OwnedBy: Array<string>,
    Source: Array<string>,
    Status: Array<string>,
    Stage: Array<string>,
    RelevantTillFrom: Date | '',
    RelevantTillTo: Date | '',
};

export type CampaignContact = {
    personId: number;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    companyCode: string;
    companyName: string;
    companyType: string;
    companyCategory: string;
    address: string;
    isGeneralType: boolean;
    isMailingType: boolean;
    isBillingType: boolean;
    isShippingType: boolean;
};

export type CampaignSearchMode = "companies" | "opportunities";

// -----------------
// ---- ACTIONS ----
// -----------------

interface RequestDataAction {
    type: 'CAMPAIGN_DETAILS_REQUEST_DATA';
}
interface ReceiveDataAction {
    type: 'CAMPAIGN_DETAILS_RECEIVE_DATA';
    data: CampaignDetails;
}
interface ReceiveDataErrorAction {
    type: 'CAMPAIGN_DETAILS_RECEIVE_DATA_ERROR';
}

interface UpdateDataRequestAction {
    type: 'CAMPAIGN_DETAILS_UPDATE_DATA_REQUEST';
    field: CampaignFieldsType;
}
interface UpdateDataAction {
    type: 'CAMPAIGN_DETAILS_UPDATE_DATA';
    data: CampaignDetails;
}
interface UpdateDataErrorAction {
    type: 'CAMPAIGN_DETAILS_UPDATE_DATA_ERROR';
}

interface RequestCompanyContactsAction {
    type: 'CAMPAIGN_DETAILS_REQUEST_CONTACTS_COMPANY';
    filters: CompanySearch;
}
interface ReceiveCompanyContactsAction {
    type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_COMPANY';
    contacts: Array<CampaignContact>;
    count: number;
}
interface ReceiveCompanyContactsErrorAction {
    type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_COMPANY_ERROR';
}

interface RequestOpportunityContactsAction {
    type: 'CAMPAIGN_DETAILS_REQUEST_CONTACTS_OPPORTUNITY';
    filters: OpportunitySearch;
}
interface ReceiveOpportunityContactsAction {
    type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_OPPORTUNITY';
    contacts: Array<CampaignContact>;
    count: number;
}
interface ReceiveOpportunityContactsErrorAction {
    type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_OPPORTUNITY_ERROR';
}

interface RequestCampaignContactsAction {
    type: 'CAMPAIGN_DETAILS_REQUEST_CONTACTS_CAMPAIGN'
}
interface ReceiveCampaignContactsAction {
    type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_CAMPAIGN';
    contacts: Array<CampaignContact>;
}
interface ReceiveCampaignContactsErrorAction {
    type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_CAMPAIGN_ERROR';
}

type KnownAction = RequestDataAction | ReceiveDataAction | ReceiveDataErrorAction
    | UpdateDataRequestAction | UpdateDataAction | UpdateDataErrorAction
    | RequestCompanyContactsAction | ReceiveCompanyContactsAction | ReceiveCompanyContactsErrorAction
    | RequestOpportunityContactsAction | ReceiveOpportunityContactsAction | ReceiveOpportunityContactsErrorAction
    | RequestCampaignContactsAction | ReceiveCampaignContactsAction | ReceiveCampaignContactsErrorAction
    | ShowErrorAction;

export const actionCreators = {
    requestData: (campaignId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.campaignDetails && !appState.campaignDetails.isLoading) {
            apiClientInstance.fetchRequest<CampaignDetails>(`v1/campaigns/${campaignId}`)
                .then((data) => {
                    data.createdOn = new Date(data.createdOn);
                    !!data.executionDate && (data.executionDate = new Date(data.executionDate));
                    dispatch({ type: 'CAMPAIGN_DETAILS_RECEIVE_DATA', data: data });
                })
                .catch((err) => {
                    dispatch({ type: 'CAMPAIGN_DETAILS_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CAMPAIGN_DETAILS_REQUEST_DATA' });
        }
    },
    updateField: (serverField: CampaignFieldsType, value: any, selectedItem?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.campaignDetails) {
            apiClientInstance.fetchRequest(
                `v1/campaigns/${appState.campaignDetails.data.campaignId}`,
                'PATCH',
                { field: serverField, value: value }
            )
                .then((data) => {
                    let infoObj = {...(appState.campaignDetails?.data ?? {} as CampaignDetails)};

                    switch (serverField) {
                        case CampaignFields.OwnedBy:
                            let ownedBy = selectedItem as PersonListItem;
                            infoObj.ownedBy = (data as FieldUpdateResult<number>).value;
                            infoObj.ownedByName = parsePersonString(ownedBy);
                            break;
                        case CampaignFields.CampaignType:
                            infoObj.campaignType = (data as FieldUpdateResult<string>).value;
                            break;
                        case CampaignFields.Status:
                            infoObj.status = (data as FieldUpdateResult<string>).value;
                            break;
                        case CampaignFields.ExecutionDate:
                            let execData = (data as FieldUpdateResult<Date | null>);
                            infoObj.executionDate = execData.value;
                            break;
                        case CampaignFields.CampaignName:
                            infoObj.campaignName = (data as FieldUpdateResult<string>).value;
                            break;
                        case CampaignFields.Description:
                            infoObj.description = (data as FieldUpdateResult<string>).value;
                            break;
                    }

                    dispatch({ type: 'CAMPAIGN_DETAILS_UPDATE_DATA', data: infoObj });
                })
                .catch((err) => {
                    dispatch({ type: 'CAMPAIGN_DETAILS_UPDATE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CAMPAIGN_DETAILS_UPDATE_DATA_REQUEST', field: serverField })
        }
    },
    requestCompanyContacts: (campaignId: number, filters: CompanySearch):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.campaignDetails && !appState.campaignDetails.isLoading) {
            apiClientInstance.fetchRequest<DataRequest<CampaignContact>>(
                `v1/campaigns/${campaignId}/company-contacts`,
                'POST',
                {
                    ...filters,
                    Take: 100
                }
            )
                .then((data) => {
                    dispatch({ type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_COMPANY', contacts: data.data, count: data.count });
                })
                .catch((err) => {
                    dispatch({ type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_COMPANY_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CAMPAIGN_DETAILS_REQUEST_CONTACTS_COMPANY', filters: filters });
        }
    },
    requestOpportunityContacts: (campaignId: number, filters: OpportunitySearch):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.campaignDetails && !appState.campaignDetails.isLoading) {
            apiClientInstance.fetchRequest<DataRequest<CampaignContact>>(
                `v1/campaigns/${campaignId}/opportunity-contacts`,
                'POST',
                {
                    ...filters,
                    Take: 100
                }
            )
                .then((data) => {
                    dispatch({ type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_OPPORTUNITY', contacts: data.data, count: data.count });
                })
                .catch((err) => {
                    dispatch({ type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_OPPORTUNITY_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CAMPAIGN_DETAILS_REQUEST_CONTACTS_OPPORTUNITY', filters: filters });
        }
    },
    requestCampaignContacts: (campaignId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.campaignDetails && !appState.campaignDetails.isLoading) {
            apiClientInstance.fetchRequest<Array<CampaignContact>>(
                `v1/campaigns/${campaignId}/contacts`,
                'GET',
            )
                .then((data) => {
                    dispatch({ type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_CAMPAIGN', contacts: data });
                })
                .catch((err) => {
                    dispatch({ type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_CAMPAIGN_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CAMPAIGN_DETAILS_REQUEST_CONTACTS_CAMPAIGN' });
        }
    },
    addCampaignContacts: (campaignId: number, ids: Array<number>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.campaignDetails) {
            apiClientInstance.fetchRequest<Array<CampaignContact>>(
                `v1/campaigns/${campaignId}/contacts`,
                'POST',
                ids
            )
                .then((data) => {
                    dispatch({ type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_CAMPAIGN', contacts: data });
                })
                .catch((err) => {
                    //TODO proper actions
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CAMPAIGN_DETAILS_REQUEST_CONTACTS_CAMPAIGN' });
        }
    },
    addAllContacts: (campaignId: number, searchType: CampaignSearchMode): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.campaignDetails) {
            apiClientInstance.fetchRequest<Array<CampaignContact>>(
                `v1/campaigns/${campaignId}/contacts`,
                'POST',
                searchType === 'companies' ?
                    appState.campaignDetails.companyContacts.map(x => x.personId) :
                    appState.campaignDetails.opportunityContacts.map(x => x.personId)
            )
                .then((data) => {
                    dispatch({ type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_CAMPAIGN', contacts: data });
                })
                .catch((err) => {
                    //TODO proper actions
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CAMPAIGN_DETAILS_REQUEST_CONTACTS_CAMPAIGN' });
        }
    },
    removeContacts: (campaignId: number, ids: Array<number>, searchType?: CampaignSearchMode): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.campaignDetails) {
            switch (searchType) {
                case 'companies':
                    {
                        let newContacts = appState.campaignDetails.companyContacts
                            .filter(prs => !ids.some(x => x === prs.personId));

                        dispatch({ type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_COMPANY', contacts: newContacts, count: appState.campaignDetails.companyContactsCount });
                        break;
                    }
                case 'opportunities':
                    {
                        let newContacts = appState.campaignDetails.opportunityContacts
                            .filter(prs => !ids.some(x => x === prs.personId));

                        dispatch({ type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_OPPORTUNITY', contacts: newContacts, count: appState.campaignDetails.opportunityContactsCount });
                        break;
                    }
                default:
                    {
                        apiClientInstance.fetchRequest<Array<CampaignContact>>(
                            `v1/campaigns/${campaignId}/contacts=${ids.join(',')}`,
                            'DELETE',
                        )
                            .then((data) => {
                                dispatch({ type: 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_CAMPAIGN', contacts: data });
                            })
                            .catch((err) => {
                                //TODO proper actions
                                dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                            });

                        dispatch({ type: 'CAMPAIGN_DETAILS_REQUEST_CONTACTS_CAMPAIGN' });
                    }

            }
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: CampaignDetailsState = {
    data: EmptyData,
    isLoading: false,
    companyContacts: [],
    companyContactsCount: 0,
    opportunityContacts: [],
    opportunityContactsCount: 0,
    campaignContacts: [],
 };

export const reducer: Reducer<CampaignDetailsState> = (state: CampaignDetailsState | undefined, incomingAction: Action): CampaignDetailsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CAMPAIGN_DETAILS_REQUEST_DATA':
            return {
                ...state,
                data: state.data,
                isLoading: true,
                updatedField: undefined,
                companyContacts: [],
                opportunityContacts: [],
                campaignContacts: [],
            };
        case 'CAMPAIGN_DETAILS_RECEIVE_DATA':
            return {
                ...state,
                data: action.data,
                isLoading: false,
                updatedField: undefined
            };
        case 'CAMPAIGN_DETAILS_RECEIVE_DATA_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'CAMPAIGN_DETAILS_UPDATE_DATA_REQUEST':
            return {
                ...state,
                updatedField: action.field,
            }
        case 'CAMPAIGN_DETAILS_UPDATE_DATA':
            return {
                ...state,
                data: action.data,
                isLoading: state.isLoading,
                updatedField: undefined,
            };
        case 'CAMPAIGN_DETAILS_UPDATE_DATA_ERROR':
            return {
                ...state,
                updatedField: undefined,
            };
        case 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_CAMPAIGN':
            return {
                ...state,
                campaignContacts: action.contacts,
            };
        case 'CAMPAIGN_DETAILS_REQUEST_CONTACTS_OPPORTUNITY':
            return {
                ...state,
                data: {
                    ...state.data,
                    opportunitySearch: JSON.stringify(action.filters)
                },
            };
        case 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_OPPORTUNITY':
            return {
                ...state,
                opportunityContacts: action.contacts,
                opportunityContactsCount: action.count,
            };
        case 'CAMPAIGN_DETAILS_REQUEST_CONTACTS_COMPANY':
            return {
                ...state,
                data: {
                    ...state.data,
                    companySearch: JSON.stringify(action.filters)
                },
            };
        case 'CAMPAIGN_DETAILS_RECEIVE_CONTACTS_COMPANY':
            return {
                ...state,
                companyContacts: action.contacts,
                companyContactsCount: action.count,
            };
    }

    return state;
};