const PurchaseOrderFields = {
    PurchaseOrderNumber: "PurchaseOrderFields.Number",
    Dealer: "PurchaseOrderFields.Dealer",
    Status: "PurchaseOrderFields.Status",
    Division: "PurchaseOrderFields.Division",
    DealerAddress: "PurchaseOrderFields.DealerAddress",
    DealerContact: "PurchaseOrderFields.DealerContact",
    Currency: "PurchaseOrderFields.Currency",
    AmountToPay: "PurchaseOrderFields.AmountToPay",
    ChequeNumber: "PurchaseOrderFields.ChequeNumber",
    Reference: "PurchaseOrderFields.Reference",
    Comments: "PurchaseOrderFields.Comments",
} as const;

export type PurchaseOrderFieldsType = typeof PurchaseOrderFields[keyof typeof PurchaseOrderFields];

export default PurchaseOrderFields;