const ManagementItemFields = {
    ItemName: "ItemConfigField.ItemName",
    Description: "ItemConfigField.Description",
    Volume: "ItemConfigField.Volume",
    Weight: "ItemConfigField.Weight",
    Category: "ItemConfigField.Category",
    PackingType: "ItemConfigField.PackingType",
} as const;

export type ManagementItemFieldsType = typeof ManagementItemFields[keyof typeof ManagementItemFields];

export default ManagementItemFields;