import { Dialog, DialogActions, DialogActionsProps, DialogContent, DialogContentProps, DialogProps, DialogTitle, DialogTitleTypeMap } from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import React from "react";
import '~css/components/controls/AMWDialog.scss';

type AMWDialogProps = DialogProps & {
    titleContent?: React.ReactNode;
    titleProps?: DefaultComponentProps<DialogTitleTypeMap>;
    content: React.ReactNode;
    contentProps?: DialogContentProps;
    actionsContent?: React.ReactNode;
    actionsProps?: DialogActionsProps;
    children?: never;
};

const customProps = [
    'titleContent', 'titleProps', 'content', 'contentProps', 'actionsContent', 'actionsProps',
];

const AMWDialog = (props: AMWDialogProps) => {
    const defaultProps = () => {
        let defaultProps: {[key:string]: any} = {};

        for(let key in props){
            if (!customProps.includes(key)) {
                let entry = Object.entries(props).find(x => x[0] === key);

                defaultProps[key] = !!entry ? entry[1] : undefined;
            }
        }

        return defaultProps as DialogProps;
    };

    const className = `AMWDialog ${!!props.className ? props.className : ''}`.trim();

    return (
        <Dialog
            {...defaultProps()}
            className={className}
            disableEscapeKeyDown={true}
        >
            {!!props.titleContent && (
                <DialogTitle>
                    {props.titleContent}
                </DialogTitle>
            )}

            <DialogContent dividers={true}>
                {props.content}
            </DialogContent>

            {!!props.actionsContent && (
                <DialogActions>
                    {props.actionsContent}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default AMWDialog;