import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { ShowErrorAction } from '~store/infra/errors';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import { DataRequest } from '~models/dataRequests';
import { SortDescriptor } from "@progress/kendo-data-query";
import { FilterHandler } from '~models/filters';

import PackingTypeGridItem from '~models/packingType/packingTypeGridItem';
import PackingTypeFields, { PackingTypeFieldsType } from '~enums/fields/packingType';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';


export interface ManagementPackingTypesState {
    isLoading: boolean;
    types: Array<PackingTypeGridItem>;
    count: number;
    skip: number;
    updatedField?: PackingTypeFieldsType;
    createStatus: ServerOperationStatus;
    updateStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface PackingTypesRequestAction {
    type: 'MANAGEMENT_PACKINGTYPES_REQUEST';
    skip: number;
}
interface PackingTypesReceiveAction {
    type: 'MANAGEMENT_PACKINGTYPES_RECEIVE';
    packingtypes: Array<PackingTypeGridItem>;
    count: number;
}
interface PackingTypesErrorAction {
    type: 'MANAGEMENT_PACKINGTYPES_ERROR';
}

interface PackingTypesCreateSendAction {
    type: 'MANAGEMENT_PACKINGTYPES_CREATE_SEND';
}
interface PackingTypesCreateSuccessAction {
    type: 'MANAGEMENT_PACKINGTYPES_CREATE_SUCCESS';
    newItem: PackingTypeGridItem;
}
interface PackingTypesCreateErrorAction {
    type: 'MANAGEMENT_PACKINGTYPES_CREATE_ERROR';
}

interface PackingTypesUpdateSendAction {
    type: 'MANAGEMENT_PACKINGTYPES_UPDATE_SEND';
    field: PackingTypeFieldsType;
}
interface PackingTypesUpdateSuccessAction {
    type: 'MANAGEMENT_PACKINGTYPES_UPDATE_SUCCESS';
    object: PackingTypeGridItem;
}
interface PackingTypesUpdateErrorAction {
    type: 'MANAGEMENT_PACKINGTYPES_UPDATE_ERROR';
}

interface PackingTypesDeleteStartAction {
    type: 'MANAGEMENT_PACKINGTYPES_DELETE_START';
}
interface PackingTypesDeleteSendAction {
    type: 'MANAGEMENT_PACKINGTYPES_DELETE_SEND';
}
interface PackingTypesDeleteSuccessAction {
    type: 'MANAGEMENT_PACKINGTYPES_DELETE_SUCCESS';
}
interface PackingTypesDeleteCancelAction {
    type: 'MANAGEMENT_PACKINGTYPES_DELETE_CANCEL';
}
interface PackingtypesDeleteErrorAction {
    type: 'MANAGEMENT_PACKINGTYPES_DELETE_ERROR';
}

type KnownAction = PackingTypesRequestAction | PackingTypesReceiveAction | PackingTypesErrorAction
    | PackingTypesCreateSendAction | PackingTypesCreateSuccessAction | PackingTypesCreateErrorAction
    | PackingTypesUpdateSendAction | PackingTypesUpdateSuccessAction | PackingTypesUpdateErrorAction
    | PackingTypesDeleteStartAction | PackingTypesDeleteSendAction
    | PackingTypesDeleteSuccessAction | PackingTypesDeleteCancelAction
    | PackingtypesDeleteErrorAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestTypes: (skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementPackingTypes && !appState.managementPackingTypes.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<PackingTypeGridItem>>('/v1/packing-types/search', 'POST', {}, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ManagementCompanies}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ManagementCompanies}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'MANAGEMENT_PACKINGTYPES_RECEIVE', packingtypes: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'MANAGEMENT_PACKINGTYPES_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_PACKINGTYPES_REQUEST', skip: skip, });
        }
    },
    updateField: (id: number, serverField: PackingTypeFieldsType, value: any, onSuccess?: () => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementPackingTypes) {
            apiClientInstance.fetchRequest(
                `v1/packing-types/${id}`,
                'PATCH',
                { field: serverField, value: value }
            )
                .then((data) => {
                    let packingType = {...appState.managementPackingTypes?.types.find(x => x.packingTypeId === id) ?? {} as PackingTypeGridItem};

                    switch (serverField) {
                        case PackingTypeFields.Name:
                            packingType.packingTypeName = (data as FieldUpdateResult<string>).value;
                            break;
                        case PackingTypeFields.Depth:
                            packingType.depth = (data as FieldUpdateResult<number>).value;
                            break;
                        case PackingTypeFields.Width:
                            packingType.width = (data as FieldUpdateResult<number>).value;
                            break;
                        case PackingTypeFields.Height:
                            packingType.height = (data as FieldUpdateResult<number>).value;
                            break;
                        case PackingTypeFields.Volume:
                            packingType.volume = (data as FieldUpdateResult<number>).value;
                            break;
                        case PackingTypeFields.Weight:
                            packingType.weight = (data as FieldUpdateResult<number>).value;
                            break;
                        case PackingTypeFields.Costs:
                            packingType.costs = (data as FieldUpdateResult<number>).value;
                            break;
                        case PackingTypeFields.PriceType:
                            packingType.priceType = (data as FieldUpdateResult<string>).value;
                            break;
                    }

                    !!onSuccess && onSuccess();

                    dispatch({ type: 'MANAGEMENT_PACKINGTYPES_UPDATE_SUCCESS', object: packingType, });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_PACKINGTYPES_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_PACKINGTYPES_UPDATE_SEND', field: serverField });
        }
    },
    createPackingType: (model: PackingTypeGridItem, onSuccess?: (result: PackingTypeGridItem) => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementPackingTypes) {
            apiClientInstance.fetchRequest<number>(
                `v1/packing-types/`,
                'POST',
                { ...model }
            )
                .then((data) => {
                    let newItem = {...model, packingTypeId: data,} as PackingTypeGridItem;

                    !!onSuccess && onSuccess(newItem);

                    dispatch({ type: 'MANAGEMENT_PACKINGTYPES_CREATE_SUCCESS', newItem: newItem });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_PACKINGTYPES_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_PACKINGTYPES_CREATE_SEND' });
        }
    },
    startDeleteType: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementPackingTypes) {
            dispatch({ type: 'MANAGEMENT_PACKINGTYPES_DELETE_START' });
        }
    },
    submitDeleteType: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementPackingTypes) {
            apiClientInstance.fetchRequest(
                `/v1/packing-types/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'MANAGEMENT_PACKINGTYPES_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'MANAGEMENT_PACKINGTYPES_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_PACKINGTYPES_DELETE_SEND' });
        }
    },
    cancelDeleteType: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementPackingTypes) {
            dispatch({ type: 'MANAGEMENT_PACKINGTYPES_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementPackingTypesState = {
    isLoading: false,
    types: [],
    count: 0,
    skip: 0,
    createStatus: ServerOperationStatus.NONE,
    updateStatus: ServerOperationStatus.NONE,
    deleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementPackingTypesState> = (state: ManagementPackingTypesState | undefined, incomingAction: Action): ManagementPackingTypesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_PACKINGTYPES_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'MANAGEMENT_PACKINGTYPES_RECEIVE':
            return {
                ...state,
                types: action.packingtypes,
                count: action.count,
                isLoading: false,
            };
        case 'MANAGEMENT_PACKINGTYPES_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'MANAGEMENT_PACKINGTYPES_UPDATE_SEND':
            return {
                ...state,
                updatedField: action.field,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_PACKINGTYPES_UPDATE_SUCCESS':
            {
                let list = [...state.types];
                let index = state.types.findIndex(x => x.packingTypeId === action.object.packingTypeId);

                list[index] = action.object;

                return {
                    ...state,
                    types: list,
                    updatedField: undefined,
                    updateStatus: ServerOperationStatus.SUCCESS,
                };
            }
        case 'MANAGEMENT_PACKINGTYPES_UPDATE_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            }

        case 'MANAGEMENT_PACKINGTYPES_CREATE_SEND':
            return {
                ...state,
                createStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_PACKINGTYPES_CREATE_SUCCESS':
            return {
                ...state,
                createStatus: ServerOperationStatus.SUCCESS,
                types: [...state.types, action.newItem],
            };
        case 'MANAGEMENT_PACKINGTYPES_CREATE_ERROR':
            return {
                ...state,
                createStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_PACKINGTYPES_DELETE_START':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.READY,
            };
        case 'MANAGEMENT_PACKINGTYPES_DELETE_SEND':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_PACKINGTYPES_DELETE_CANCEL':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.NONE,
            };
        case 'MANAGEMENT_PACKINGTYPES_DELETE_SUCCESS':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'MANAGEMENT_PACKINGTYPES_DELETE_ERROR':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};