const QuotationsGridFilters = {
    ClientCode: "ClientCode",
    ClientName: "ClientName",
    ClientRef: "ClientRef",
    QuotationNr: "QuotationNr",
    Project: "Project",
    Description: "Description",
    ExpenseDetails: "ExpenseDetails",
    Division: "Division",
    User: "User",
    Status: "Status",
    StartDate: "StartDate",
    EndDate: "EndDate",
    ItemObjectNr: "ItemObjectNr",
    ItemLot: "ItemLot",
    ItemCustomsEntryNr: "ItemCustomsEntryNr",
    ItemSidemark: "ItemSidemark",
    ItemDescription: "ItemDescription",
} as const;

export type QuotationsGridFiltersType = typeof QuotationsGridFilters[keyof typeof QuotationsGridFilters];

export default QuotationsGridFilters;