const CFTransactionsGridFields = {
    TransactionType: "transactionType",
    InternalId: "internalId",
    ScheduledDate: "scheduledDate",
    ActualDate: "actualDate",
    TrackingNumber: "trackingNumber",
    InvoiceRef: "invoiceRef",
    Status: "status",
    DivisionCode: "divisionCode",
    ShipperName: "shipperName",
    ConsigneeName: "consigneeName",
    CarrierName: "carrierName",
    OwnedByName: "ownedByName",
    CreationDate: "creationDate",
    ClientBillable: "clientBillable",
    ProjectRef: "projectRef",
} as const;

export type CFTransactionsGridFieldsType = typeof CFTransactionsGridFields[keyof typeof CFTransactionsGridFields];

export default CFTransactionsGridFields;