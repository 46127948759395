const QuoteConfigFields = {
    ServiceType: "QuoteConfigField.ServiceType",
    ServiceCode: "QuoteConfigField.ServiceCode",
    ServiceDescription: "QuoteConfigField.ServiceDescription",
    Units: "QuoteConfigField.Units",
    Rate: "QuoteConfigField.Rate",
    Qty: "QuoteConfigField.Qty",
    BillingBase: "QuoteConfigField.BillingBase",
    BillingMinimum: "QuoteConfigField.BillingMinimum",
    SequenceNr: "QuoteConfigField.SequenceNr",
    SourceValue: "QuoteConfigField.SourceValue",
    SourceValueIterateBy: "QuoteConfigField.SourceValueIterateBy",
    SourceRate: "QuoteConfigField.SourceRate",
    SourceRateIterateBy: "QuoteConfigField.SourceRateIterateBy",
} as const;

export type QuoteConfigFieldsType = typeof QuoteConfigFields[keyof typeof QuoteConfigFields];

export default QuoteConfigFields;