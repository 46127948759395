import React from 'react';
import CampaignStatus from '~enums/campaignStatus';
import CampaignType from '~enums/campaignType';
import CRMCampaignsFilters from '~enums/gridFilters/crmCampaignsFilters';
import { FilterHandler } from '~models/filters';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import FilterContainer from '~components/filtering/FilterContainer';
import FilterItem from '~components/filtering/FilterItem';
import { FilterPanel, FilterPanelProps } from '~components/filtering/FilterPanel';
import MultiSelectWrapper from '~controls/MultiSelectWrapper';
import OnDemandMultiSelect from '~controls/OnDemandMultiSelect';
import DatePickerWrapper from '~components/controls/DatePickerWrapper';
import LocalizationKeys from '~enums/localizationKeys';
import TextBoxWrapper from '~components/controls/TextBoxWrapper';


type CampaignsFilterPanelProps = FilterPanelProps;

export default class CampaignsFilterPanel extends FilterPanel<CampaignsFilterPanelProps> {

    public render() {
        const localization = provideLocalizationService(this);

        return (
            <FilterContainer
                applyClassName={'Button--orange'}
                clearClassName={'Button--gray'}
                filterApply={this.props.filterApply}
                filterClear={this.props.filterClear}
            >

                <FilterItem>
                    <TextBoxWrapper
                        id="crmCampaignName"
                        name={CRMCampaignsFilters.CampaignName}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Filters.CampaignName, "%Campaign name")}
                        value={FilterHandler.getValue(this.props.filters, CRMCampaignsFilters.CampaignName)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="crmCampaignDescription"
                        name={CRMCampaignsFilters.Description}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Filters.Description, "%Description")}
                        value={FilterHandler.getValue(this.props.filters, CRMCampaignsFilters.Description)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <MultiSelectWrapper
                        data={Object.values(CampaignType).map(x => { return {
                            id: x,
                            text: localization.toLanguageString(x, x)
                        }})}
                        dataItemKey={'id'}
                        textField={'text'}
                        id="crmCampaignType"
                        name={CRMCampaignsFilters.CampaignType}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Filters.CampaignType, "%Campaign type")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMCampaignsFilters.CampaignType, 'id', 'text')}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandMultiSelect
                        url={`/v1/persons`}//TODO filter persons
                        dataItemKey={'personId'}
                        textField={'fullName'}
                        id="crmCampaignOwnedBy"
                        name={CRMCampaignsFilters.OwnedBy}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Filters.OwnedBy, "%Owned by")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMCampaignsFilters.OwnedBy, 'personId', 'fullName')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="crmCampaignCreatedFrom"
                        name={CRMCampaignsFilters.CreatedDateFrom}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Filters.CreatedFrom, "%Creation date from")}
                        placeholder={""}
                        value={FilterHandler.getDateValue(this.props.filters, CRMCampaignsFilters.CreatedDateFrom)}
                        onChange={this.onDateChange.bind(this)}
                        max={FilterHandler.getDateValue(this.props.filters, CRMCampaignsFilters.CreatedDateTo) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="crmCampaignCreatedTo"
                        name={CRMCampaignsFilters.CreatedDateTo}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Filters.CreatedTo, "%Creation date to")}
                        placeholder={""}
                        value={FilterHandler.getDateValue(this.props.filters, CRMCampaignsFilters.CreatedDateTo)}
                        onChange={this.onDateChange.bind(this)}
                        min={FilterHandler.getDateValue(this.props.filters, CRMCampaignsFilters.CreatedDateFrom) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="crmCampaignExecutedFrom"
                        name={CRMCampaignsFilters.ExecutionDateFrom}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Filters.ExecutedFrom, "%Execution date from")}
                        placeholder={""}
                        value={FilterHandler.getDateValue(this.props.filters, CRMCampaignsFilters.ExecutionDateFrom)}
                        onChange={this.onDateChange.bind(this)}
                        max={FilterHandler.getDateValue(this.props.filters, CRMCampaignsFilters.ExecutionDateTo) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="crmCampaignExecutedTo"
                        name={CRMCampaignsFilters.ExecutionDateTo}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Filters.ExecutedTo, "%Execution date to")}
                        placeholder={""}
                        value={FilterHandler.getDateValue(this.props.filters, CRMCampaignsFilters.ExecutionDateTo)}
                        onChange={this.onDateChange.bind(this)}
                        min={FilterHandler.getDateValue(this.props.filters, CRMCampaignsFilters.ExecutionDateFrom) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <MultiSelectWrapper
                        data={Object.values(CampaignStatus).map(x => { return {
                            id: x,
                            text: localization.toLanguageString(x, x)
                        }})}
                        dataItemKey={'id'}
                        textField={'text'}
                        id="crmCampaignStatus"
                        name={CRMCampaignsFilters.Status}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Filters.Status, "%Status")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMCampaignsFilters.Status, 'id', 'text')}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

            </FilterContainer>
        );
    }
}
registerForLocalization(CampaignsFilterPanel as React.ComponentClass<any>);