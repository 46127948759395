const ContainerFields = Object.freeze({
    ContainerType: "ContainerFields.ContainerType",
    LoadType: "ContainerFields.LoadType",
    NumberOfPieces: "ContainerFields.NumberOfPieces",
    SerialNumber: "ContainerFields.SerialNumber",
    SealNumber: "ContainerFields.SealNumber",
    NetVolume: "ContainerFields.NetVolume",
    GrossVolume: "ContainerFields.GrossVolume",
    ChargeableVolume: "ContainerFields.ChargeableVolume",
    NetWeight: "ContainerFields.NetWeight",
    GrossWeight: "ContainerFields.GrossWeight",
    TareWeight: "ContainerFields.TareWeight",
    Length: "ContainerFields.Length",
    Width: "ContainerFields.Width",
    Height: "ContainerFields.Height",
    BillOfLading: "ContainerFields.BillOfLading",
    AirwayBill: "ContainerFields.AirwayBill",
    BookingRef: "ContainerFields.BookingRef",
    PhotoRef: "ContainerFields.PhotoRef",
});

export type ContainerFieldsType = typeof ContainerFields[keyof typeof ContainerFields];

export default ContainerFields;