import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';
import { apiClientInstance } from '~services/auth/ApiClientInstance';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { ShowErrorAction } from '../infra/errors';
import AppointmentUpdateModel from '~models/appointment/appointmentUpdateModel';


export interface AppointmentModalState {
    updateStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface AppointmentModalSendAction {
    type: 'APPOINTMENT_MODAL_UPDATE_SEND';
}

interface AppointmentModalSuccessAction {
    type: 'APPOINTMENT_MODAL_UPDATE_SUCCESS';
}

interface AppointmentModalErrorAction {
    type: 'APPOINTMENT_MODAL_UPDATE_ERROR';
}

type KnownAction = AppointmentModalSendAction
    | AppointmentModalSuccessAction
    | AppointmentModalErrorAction
    | ShowErrorAction;

export const actionCreators = {
    updateAppointment: (model: AppointmentUpdateModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.appointmentModal && appState.appointmentModal.updateStatus !== ServerOperationStatus.INPROGRESS) {
            apiClientInstance.fetchRequest(
                `/v1/appointments/${model.appointmentId}`,
                'PATCH',
                model
            )
                .then(() => {
                    dispatch({ type: 'APPOINTMENT_MODAL_UPDATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'APPOINTMENT_MODAL_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'APPOINTMENT_MODAL_UPDATE_SEND' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: AppointmentModalState = {
    updateStatus: ServerOperationStatus.NONE,
};

export const reducer: Reducer<AppointmentModalState> = (state: AppointmentModalState | undefined, incomingAction: Action): AppointmentModalState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'APPOINTMENT_MODAL_UPDATE_SEND':
            return {
                ...state,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'APPOINTMENT_MODAL_UPDATE_SUCCESS':
            return {
                ...state,
                updateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'APPOINTMENT_MODAL_UPDATE_ERROR':
            return {
                ...state,
                updateStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};