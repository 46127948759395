const TasksGridFilters = {
    Description: "Description",
    Status: "Status",
    OwnedBy: "OwnedBy",
    DateFrom: "DateFrom",
    DateTo: "DateTo",
    StartDate: "StartDate",
    DueDate: "DueDate",
} as const;

export type TasksGridFiltersType = typeof TasksGridFilters[keyof typeof TasksGridFilters];

export default TasksGridFilters;