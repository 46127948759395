const PartyType = Object.freeze({
    //TODO swap when entities are updated
    LegalEntity: "LEGALENTITY",
    Division: "DIVISION",
    Client: "CLIENT",
    Dealer: "DEALER",
    Vendor: "VENDOR",
    Lead: "LEAD",
    // LegalEntity: "Enum.PartyType.LegalEntity",
    // Division: "Enum.PartyType.Division",
    // Client: "Enum.PartyType.Client",
    // Dealer: "Enum.PartyType.Dealer",
    // Vendor: "Enum.PartyType.Vendor",
    // Lead: "Enum.PartyType.Lead",
});

export type PartyTypeType = typeof PartyType[keyof typeof PartyType];

const nonSystemPartyTypes = Object.values(PartyType)
    .filter(x => x !== PartyType.LegalEntity && x !== PartyType.Division);

export {
    PartyType,
    nonSystemPartyTypes,
};