const ItemFields = {
    UniqueId: "ItemField.UniqueId",
    PartsQty: "ItemField.PartsQty",
    PiecesQty: "ItemField.PiecesQty",
    Description: "ItemField.Description",

    PackingType: "ItemField.PackingType",
    PBO: "ItemField.PBO",
    PackageDepth: "ItemField.PackageDepth",
    PackageWidth: "ItemField.PackageWidth",
    PackageHeight: "ItemField.PackageHeight",

    Artist: "ItemField.Artist",
    Title: "ItemField.Title",
    Materials: "ItemField.Materials",
    Circa: "ItemField.Circa",
    CountryOfOrigin: "ItemField.CountryOfOrigin",

    Depth: "ItemField.Depth",
    Width: "ItemField.Width",
    Height: "ItemField.Height",
    Volume: "ItemField.Volume",
    GrossVolume: "ItemField.GrossVolume",
    Weight: "ItemField.Weight",
    GrossWeight: "ItemField.GrossWeight",

    Value: "ItemField.Value",
    Currency: "ItemField.Currency",
    VAT: "ItemField.VAT",
    AmountToPay: "ItemField.AmountToPay",

    Condition: "ItemField.Condition",
    VendorPart: "ItemField.VendorPart",
    Sidemark: "ItemField.Sidemark",
    Lot: "ItemField.Lot",
    Tag: "ItemField.Tag",
    Category: "ItemField.Category",

    Location: "ItemField.Location",
    LastScanDate: "ItemField.LastScanDate",
    TransportMode: "ItemField.TransportMode",
    Room: "ItemField.Room",
    HandlingType: "ItemField.HandlingType",

    LengthUnits: "ItemField.LengthUnits",
    VolumeUnits: "ItemField.VolumeUnits",
    WeightUnits: "ItemField.WeightUnits",

    Bonded: "ItemField.Bonded",
    CITES: "ItemField.CITES",
    TA: "ItemField.TA",
    InCrate: "ItemField.InCrate",
    ExportLicenseRequired: "ItemField.ExportLicenseRequired",

    CollectionDate: "ItemField.CollectionDate",
    CollectionDateDone: "ItemField.CollectionDateDone",
    PackingDate: "ItemField.PackingDate",
    PackingDateDone: "ItemField.PackingDateDone",
    ReceivedDate: "ItemField.ReceivedDate",
    ReceivedDateDone: "ItemField.ReceivedDateDone",
    ReleaseDate: "ItemField.ReleaseDate",
    ReleaseDateDone: "ItemField.ReleaseDateDone",
    DeliveryDate: "ItemField.DeliveryDate",
    DeliveryDateDone: "ItemField.DeliveryDateDone",

    Comments: "ItemField.Comments",
    Registar: "ItemField.Registar",

    HammerPrice: "ItemField.HammerPrice",
    BuyerPremium: "ItemField.BuyerPremium",
    Owner: "ItemField.Owner",
    Buyer: "ItemField.Buyer",
    Seller: "ItemField.Seller",

    InCare: "ItemField.InCare",
    Assemble: "ItemField.Assemble",
    CustomsStatus: "ItemField.CustomsStatus",
    CustomsStatusDate: "ItemField.CustomsStatusDate",
    CustomsEntryNumber: "ItemField.CustomsEntryNumber",
    Disposition: "ItemField.Disposition",

    ShippingCost: "ItemField.ShippingCost",
    InsurancePremium: "ItemField.InsurancePremium",
    HSCode: "ItemField.HSCode",
    InsuredByClient: "ItemField.InsuredByClient",
    ShippingTerms: "ItemField.ShippingTerms",

    PhotoShootDate: "ItemField.PhotoShootDate",
    ShotBy: "ItemField.ShotBy",
    IsCatalogued: "ItemField.IsCatalogued",

    VatOnHammerPrice: "ItemField.VatOnHammerPrice",
    VatRateOnHammer: "ItemField.VatRateOnHammer",
    VatOnBuyerPremium: "ItemField.VatOnBuyerPremium",
    VatRateOnBuyerPremium: "ItemField.VatRateOnBuyerPremium",
    ArtistRoyalty: "ItemField.ArtistRoyalty",

    Movement: "ItemField.Movement",
    Accessories: "ItemField.Accessories",

    TransferredTo: "ItemField.TransferredTo",
} as const;

export type ItemFieldsType = typeof ItemFields[keyof typeof ItemFields];

export default ItemFields;