const InventoryGridFilters = {
    UniqueId: "UniqueId",
    Location: "Location",
    Lot: "Lot",
    SideMark: "SideMark",
    Description: "Description",
    Owner: "Owner",
    Client: "Client",
    Dealer: "Dealer",
    DivisionId: "DivisionId",
    CustomsEntry: "CustomsEntry",
    RoomDepartment: "RoomDepartment",
    CustomsStatus: "CustomsStatus",
    StartDate: "StartDate",
    EndDate: "EndDate",
    InCareOnly: "InCareOnly",
    HideParent: "HideParent",
    ShowFullHistory: "ShowFullHistory",
    PackageUniqueId: "UniqueId",
    PackageLocationId: "LocationId",
    PackageDescription: "Description",
} as const;

export type InventoryGridFiltersType = typeof InventoryGridFilters[keyof typeof InventoryGridFilters];

export default InventoryGridFilters;