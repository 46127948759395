import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '~store/ApplicationState';
import * as NavigationStore from '~store/infra/navigation';
import * as UserStore from '~store/auth/user';
import Preloader from '../infra/Preloader';

type AuthCallbackProps = UserStore.UserState
    & typeof NavigationStore.actionCreators
    & typeof UserStore.actionCreators;

class AuthCallback extends React.Component<AuthCallbackProps> {
    constructor(props: AuthCallbackProps) {
        super(props);

        this.__redirectByPermissions = this.__redirectByPermissions.bind(this);
    }

    componentDidMount() {
        console.log('auth callback mounted');
        // if (this.props.error) {
        //     return;
        // }

        this.__redirectByPermissions();

        // if (this.props.userData === undefined) {
        //     this.props.requestUserData();
        // } else {
        //     this.__redirectByPermissions();
        // }
    }

    componentDidUpdate(prevProps:AuthCallbackProps) {
        // if(!equal(prevProps.userData, this.props.userData)) {
        //     this.__redirectByPermissions();
        // }
    }

    __redirectByPermissions() {
        //TODO
        this.props.navigate('/files');
    }

    render() {
        return <Preloader />;
    }
}

export default connect(
    (state: ApplicationState) => {
        return {
            ...state.user,
        };
    },
    {
        ...UserStore.actionCreators,
        ...NavigationStore.actionCreators,
    }
)(AuthCallback as any);