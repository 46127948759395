import React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '~store/ApplicationState';
import * as AppointmentModalStore from '~store/modals/appointmentModal';
import { defaultStringMapper } from "~src/utils/dropdownUtils";
import { apiClientInstance } from "~services/auth/ApiClientInstance";

import AppointmentStatus, { AppointmentStatusType } from "~enums/appointmentStatus";
import AppointmentPriority, { AppointmentPriorityType } from "~enums/appointmentPriority";
import EnumType from "~enums/enumType";
import { ServerOperationStatus } from "~enums/serverOperationStatus";
import LocalizationKeys from "~enums/localizationKeys";
import AppointmentDetails from "~models/appointment/appointmentDetails";

import { Button } from "@progress/kendo-react-buttons";
import { provideIntlService, provideLocalizationService, registerForIntl, registerForLocalization } from "@progress/kendo-react-intl";
import { Input } from "@progress/kendo-react-inputs";
import { Close as CloseIcon } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";

import ControlGroup from "~components/layout/ControlGroup";
import AMWDialog from "~controls/AMWDialog";
import DateTimePickerWrapper from "~controls/DateTimePickerWrapper";
import DropDownListWrapper from "~controls/DropDownListWrapper";
import OnDemandDropDown from "~controls/OnDemandDropdown";
import OnDemandMultiSelect from "~controls/OnDemandMultiSelect";
import TextAreaWrapper from "~controls/TextAreaWrapper";
import TextBoxWrapper from "~controls/TextBoxWrapper";


type AppointmentModalOwnProps = {
    appointmentId: number | null;
    open: boolean;
    onClose: () => void;
}
type AppointmentModalProps = AppointmentModalOwnProps &
    AppointmentModalStore.AppointmentModalState &
    typeof AppointmentModalStore.actionCreators;

interface AppointmentModalState {
    ownedById: number | null;
    ownedByName: string;
    createdOn: Date | null;
    startDate: Date | null;
    endDate: Date | null;
    reminderDate: Date | null;
    appointmentType: string;
    appointmentTypeName: string;
    subject: string;
    details: string;
    clientFeedback: string;
    meetingLocation: string;
    status: string;
    priority: string;
    companyRepresentatives: string;
    clientRepresentatives: string;

    companyRepresentativesSelect: any[];
    clientRepresentativesSelect: any[];

    isValid: boolean,
    closeOnSuccess: boolean,
}

class AppointmentModal extends React.Component<AppointmentModalProps, AppointmentModalState> {

    constructor(props: AppointmentModalProps) {
        super(props);
        this.state = {
            ownedById: null,
            ownedByName: '',
            createdOn: null,
            startDate: null,
            endDate: null,
            reminderDate: null,
            appointmentType: '',
            appointmentTypeName: '',
            subject: '',
            details: '',
            clientFeedback: '',
            meetingLocation: '',
            status: '',
            priority: '',
            companyRepresentatives: '',
            clientRepresentatives: '',

            companyRepresentativesSelect: [],
            clientRepresentativesSelect: [],

            isValid: true,
            closeOnSuccess: false,
        };
    }

    componentDidUpdate(prevProps: AppointmentModalProps) {
        if (!!this.props.appointmentId && this.props.appointmentId !== prevProps.appointmentId) {
            this.requestData(this.props.appointmentId);
        }

        if (this.props.updateStatus !== prevProps.updateStatus &&
            this.props.updateStatus === ServerOperationStatus.SUCCESS &&
            this.state.closeOnSuccess) {
            this.props.onClose();
        }
    }

    private requestData(id: number) {
        const localization = provideLocalizationService(this);

        apiClientInstance.fetchRequest<AppointmentDetails>(`/v1/appointments/${id}`)
            .then((data) => {
                data.createdOn = new Date(data.createdOn);
                data.startDate = new Date(data.startDate);
                data.endDate = new Date(data.endDate);
                data.reminderDate = new Date(data.reminderDate);

                this.setState({
                    ...data,
                    appointmentTypeName: localization.toLanguageString(data.appointmentType, data.appointmentType),
                    companyRepresentativesSelect: [],
                    clientRepresentativesSelect: [],
                    isValid: true,
                    closeOnSuccess: false,
                });
            })
    }

    private isFormValid() {
        return !!this.state.ownedById &&
            !!this.state.startDate && !!this.state.endDate && !!this.state.reminderDate &&
            this.state.appointmentType !== '' && this.state.appointmentType !== '' &&
            this.state.status !== '' && this.state.priority !== '';
    }

    private onSubmit(close: boolean) {
        this.setState({
            closeOnSuccess: close,
            isValid: this.isFormValid()
        }, () => {
            if (!!this.props.appointmentId && this.state.isValid) {
                this.props.updateAppointment({
                    appointmentId: this.props.appointmentId,
                    ownedBy: this.state.ownedById ?? 0,
                    startDate: this.state.startDate ?? new Date(),
                    endDate: this.state.endDate ?? new Date(),
                    reminderDate: this.state.reminderDate ?? new Date(),
                    appointmentType: this.state.appointmentType,
                    subject: this.state.subject,
                    details: this.state.details,
                    clientFeedback: this.state.clientFeedback,
                    meetingLocation: this.state.meetingLocation,
                    status: this.state.status,
                    priority: this.state.priority,
                    companyRepresentatives: this.state.companyRepresentatives,
                    clientRepresentatives: this.state.clientRepresentatives,
                });
            }
        });
    }

    private onClose() {
        this.setState({
            ownedById: null,
            ownedByName: '',
            createdOn: null,
            startDate: null,
            endDate: null,
            reminderDate: null,
            appointmentType: '',
            appointmentTypeName: '',
            subject: '',
            details: '',
            clientFeedback: '',
            meetingLocation: '',
            status: '',
            priority: '',
            companyRepresentatives: '',
            clientRepresentatives: '',

            companyRepresentativesSelect: [],
            clientRepresentativesSelect: [],

            isValid: true
        }, () => {
            this.props.onClose();
        });
    }

    render() {
        const localization = provideLocalizationService(this);
        const intl = provideIntlService(this);

        return (
            <AMWDialog
                open={this.props.open}
                onClose={this.onClose.bind(this)}
                titleContent={
                    <React.Fragment>
                        <span>{localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.Title, "%Appointment form")}</span>

                        <IconButton onClick={this.props.onClose}>
                            <CloseIcon></CloseIcon>
                        </IconButton>
                    </React.Fragment>
                }
                content={
                    <Grid container direction={"column"}>
                        <Grid item className="InputHolder">
                            <OnDemandDropDown
                                url="/v1/persons"//todo Filter by proper permissions
                                // urlParams={{partyType: [PartyType.Division]}}
                                dataItemKey={'personId'}
                                textField={'fullName'}
                                id="appointmentFormOwnedBy"
                                name="appointmentFormOwnedBy"
                                label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.OwnedBy, "%Owned by")}
                                value={{fullName: this.state.ownedByName, personId: this.state.ownedById}}
                                pageSize={10}
                                onChange={(event) => {
                                    this.setState({
                                        ownedById: event.value.id,
                                        ownedByName: event.value.text,
                                    });
                                }}
                                valid={this.state.isValid || this.state.ownedById !== null}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <Input
                                id="appointmentFormCreatedDate"
                                name="appointmentFormCreatedDate"
                                label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.CreatedDate, "%Created")}
                                value={!!this.state.createdOn ? intl.formatDate(this.state.createdOn, 'g') : ''}
                                readOnly={true}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <ControlGroup
                                container
                                direction={"column"}
                                groupTitle={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.Scheduling, "%Scheduling")}
                            >
                                <Grid item className="InputHolder">
                                    <DateTimePickerWrapper
                                        id="appointmentFormStartDate"
                                        name="appointmentFormStartDate"
                                        label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.StartDate, "%Start date")}
                                        value={this.state.startDate}
                                        onChange={(event) => {
                                            this.setState({
                                                startDate: event.value
                                            });
                                        }}
                                    />
                                </Grid>

                                <Grid item className="InputHolder">
                                    <DateTimePickerWrapper
                                        id="appointmentFormEndDate"
                                        name="appointmentFormEndDate"
                                        label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.EndDate, "%End date")}
                                        value={this.state.endDate}
                                        onChange={(event) => {
                                            this.setState({
                                                endDate: event.value
                                            });
                                        }}
                                    />
                                </Grid>

                                <Grid item className="InputHolder">
                                    <DateTimePickerWrapper
                                        id="appointmentFormReminderDate"
                                        name="appointmentFormReminderDate"
                                        label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.ReminderDate, "%Reminder date")}
                                        value={this.state.reminderDate}
                                        onChange={(event) => {
                                            this.setState({
                                                reminderDate: event.value
                                            });
                                        }}
                                    />
                                </Grid>
                            </ControlGroup>
                        </Grid>

                        <Grid item className="InputHolder">
                            <ControlGroup
                                container
                                direction={"column"}
                                groupTitle={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.Representatives, "%Representatives")}
                            >
                                <Grid item className="InputHolder">
                                    <OnDemandMultiSelect
                                        url={`/v1/appointments/company-representatives`}
                                        id="appointmentCompanyRepresentatives"
                                        name="appointmentCompanyRepresentatives"
                                        label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.CompanyRepresentatives, "%Company")}
                                        value={this.state.companyRepresentativesSelect}
                                        pageSize={10}
                                        dataItemKey={"personId"}
                                        textField={"fullName"}
                                        onInputChange={(ev) => {
                                            this.setState({
                                                companyRepresentativesSelect: ev.value,
                                            });
                                        }}
                                        onClose={() => {
                                            this.setState({
                                                companyRepresentatives: mergeRepresentatives(this.state.companyRepresentatives, this.state.companyRepresentativesSelect),
                                                companyRepresentativesSelect: [],
                                            });
                                        }}
                                        onSelectAll={(data) => {
                                            this.setState({
                                                companyRepresentatives: mergeRepresentatives(this.state.companyRepresentatives, data),
                                                companyRepresentativesSelect: [],
                                            });
                                        }}
                                        enableSelectAll={true}
                                    />
                                </Grid>

                                <Grid item className="InputHolder">
                                    <TextAreaWrapper
                                        id="appointmentFormCompanyRepresentativesText"
                                        name="appointmentFormCompanyRepresentativesText"
                                        value={this.state.companyRepresentatives}
                                        maxLength={500}
                                        onInputChange={(event) => {
                                            this.setState({
                                                companyRepresentatives: event.value as string
                                            });
                                        }}
                                    />
                                </Grid>

                                <Grid item className="InputHolder">
                                    <OnDemandMultiSelect
                                        url={`/v1/appointments/${this.props.appointmentId}/client-representatives`}
                                        id="appointmentClientRepresentatives"
                                        name="appointmentClientRepresentatives"
                                        label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.ClientRepresentatives, "%Client")}
                                        value={this.state.clientRepresentativesSelect}
                                        pageSize={10}
                                        dataItemKey={"personId"}
                                        textField={"fullName"}
                                        onInputChange={(ev) => {
                                            this.setState({
                                                clientRepresentativesSelect: ev.value,
                                            });
                                        }}
                                        onClose={() => {
                                            this.setState({
                                                clientRepresentatives: mergeRepresentatives(this.state.clientRepresentatives, this.state.clientRepresentativesSelect),
                                                clientRepresentativesSelect: [],
                                            });
                                        }}
                                        onSelectAll={(data) => {
                                            this.setState({
                                                clientRepresentatives: mergeRepresentatives(this.state.clientRepresentatives, data),
                                                clientRepresentativesSelect: [],
                                            });
                                        }}
                                        enableSelectAll={true}
                                    />
                                </Grid>

                                <Grid item className="InputHolder">
                                    <TextAreaWrapper
                                        id="appointmentFormClientRepresentativesText"
                                        name="appointmentFormClientRepresentativesText"
                                        value={this.state.clientRepresentatives}
                                        maxLength={500}
                                        onInputChange={(event) => {
                                            this.setState({
                                                clientRepresentatives: event.value as string
                                            });
                                        }}
                                    />
                                </Grid>
                            </ControlGroup>
                        </Grid>

                        <Grid item className="InputHolder">
                            <ControlGroup
                                container
                                direction={"column"}
                                groupTitle={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.DetailsTitle, "%Details")}
                            >
                                <Grid item className="InputHolder">
                                    <OnDemandDropDown
                                        url="/v1/configuration"
                                        urlParams={{ type: EnumType.AppointmentType }}
                                        dataItemKey={'id'}
                                        textField={'text'}
                                        itemMapper={defaultStringMapper}
                                        localizeText={true}
                                        id="appointmentFormType"
                                        name="appointmentFormType"
                                        label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.Type, "%Type")}
                                        value={{text: this.state.appointmentTypeName, id: `${this.state.appointmentType}`}}
                                        pageSize={10}
                                        onChange={(event) => {
                                            this.setState({
                                                appointmentType: event.value.id,
                                                appointmentTypeName: event.value.text,
                                            });
                                        }}
                                        valid={this.state.isValid || this.state.appointmentType !== ''}
                                    />
                                </Grid>

                                <Grid item className="InputHolder">
                                    <TextBoxWrapper
                                        id="appointmentFormSubject"
                                        name="appointmentFormSubject"
                                        label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.Subject, "%Subject")}
                                        value={this.state.subject}
                                        onInputChange={(event) => {
                                            this.setState({
                                                subject: event.value as string
                                            });
                                        }}
                                        maxLength={150}
                                        valid={this.state.isValid || this.state.subject !== ''}
                                    />
                                </Grid>

                                <Grid item className="InputHolder">
                                    <TextAreaWrapper
                                        id="appointmentFormDetails"
                                        name="appointmentFormDetails"
                                        label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.Title, "Details")}
                                        value={this.state.details}
                                        maxLength={2048}
                                        onInputChange={(event) => {
                                            this.setState({
                                                details: event.value as string
                                            });
                                        }}
                                    />
                                </Grid>

                                <Grid item className="InputHolder">
                                    <TextAreaWrapper
                                        id="appointmentFormClientFeedback"
                                        name="appointmentFormClientFeedback"
                                        label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.ClientFeedback, "%Client feedback")}
                                        value={this.state.clientFeedback}
                                        onInputChange={(event) => {
                                            this.setState({
                                                clientFeedback: event.value as string
                                            });
                                        }}
                                    />
                                </Grid>

                                <Grid item className="InputHolder">
                                    <TextAreaWrapper
                                        id="appointmentFormMeetingLocation"
                                        name="appointmentFormMeetingLocation"
                                        label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.MeetingLocation, "%Meeting location")}
                                        value={this.state.clientFeedback}
                                        onInputChange={(event) => {
                                            this.setState({
                                                meetingLocation: event.value as string
                                            });
                                        }}
                                    />
                                </Grid>

                                <Grid item className="InputHolder">
                                    <DropDownListWrapper
                                        id="appointmentFormStatus"
                                        name="appointmentFormStatus"
                                        label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.Status, "%Status")}
                                        dataItemKey='id'
                                        textField="text"
                                        data={Object.values(AppointmentStatus).map(x => {
                                            return {
                                                id: x,
                                                text: localization.toLanguageString(x, x)
                                            };
                                        })}
                                        value={!!this.state.status ? {
                                            id: this.state.status,
                                            text: localization.toLanguageString(this.state.status, this.state.status)
                                        } : null}
                                        onChange={(event) => {
                                            this.setState({
                                                status: (event.value as any).id as AppointmentStatusType
                                            });
                                        }}
                                        valid={this.state.isValid || !!this.state.status} />
                                </Grid>

                                <Grid item className="InputHolder">
                                    <DropDownListWrapper
                                        id="appointmentFormPriority"
                                        name="appointmentFormPriority"
                                        label={localization.toLanguageString(LocalizationKeys.Appointments.AppointmentForm.Priority, "%Priority")}
                                        dataItemKey='id'
                                        textField="text"
                                        data={Object.values(AppointmentPriority).map(x => {
                                            return {
                                                id: x,
                                                text: localization.toLanguageString(x, x)
                                            };
                                        })}
                                        value={!!this.state.priority ? {
                                            id: this.state.priority,
                                            text: localization.toLanguageString(this.state.priority, this.state.priority)
                                        } : null}
                                        onChange={(event) => {
                                            this.setState({
                                                priority: (event.value as any).id as AppointmentPriorityType
                                            });
                                        }}
                                        valid={this.state.isValid || !!this.state.priority} />
                                </Grid>
                            </ControlGroup>
                        </Grid>
                    </Grid>
                }
                actionsContent={
                    <React.Fragment>
                        <Button
                            className={"Button--bold Button--orange"}
                            onClick={() => {this.onSubmit.apply(this, [false])}}
                            disabled={this.props.updateStatus === ServerOperationStatus.INPROGRESS}
                        >
                            {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Save, "%Save")}
                        </Button>
                        <Button
                            onClick={() => {this.onSubmit.apply(this, [true])}}
                            disabled={this.props.updateStatus === ServerOperationStatus.INPROGRESS}
                        >
                            {localization.toLanguageString(LocalizationKeys.Shared.Buttons.SaveAndClose, "%Save and close")}
                        </Button>
                        <Button
                            onClick={this.onClose.bind(this)}
                            disabled={this.props.updateStatus === ServerOperationStatus.INPROGRESS}
                        >
                            {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Cancel, "%Cancel")}
                        </Button>
                    </React.Fragment>
                }
            />
        )
    }
}

registerForLocalization(AppointmentModal as React.ComponentClass<any>);
registerForIntl(AppointmentModal as React.ComponentClass<any>);

export default connect(
    (state: ApplicationState, ownProps: AppointmentModalOwnProps) => {
        return {
            ...ownProps,
            ...state.appointmentModal,
        };
    },
    {
        ...AppointmentModalStore.actionCreators
    }
)(AppointmentModal as any);

function mergeRepresentatives(currentValue: string, newValues: any[]) {
    let result = currentValue;
    if (result.trim().length > 0 && newValues.length > 0) result += ", ";

    return `${result}${newValues.map(x => x.fullName).join(", ")}`.substring(0, 500);
}