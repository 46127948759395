import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal/react';
import GridUtils from '~src/utils/gridUtils';
import { ApplicationState } from '~store/ApplicationState';
import * as CRMStore from '~store/pages/mainViews/crm';
import * as UsersStore from '~store/auth/user';
import * as NavigationStore from '~store/infra/navigation';
import * as ScreenStore from '~store/infra/screen';

import { checkboxColumnWidth } from '~enums/constants';
import CRMOpportunitiesGridFields from '~enums/gridFields/crmOpportunity';
import LocalizationKeys from '~enums/localizationKeys';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import NewOpportunityData from '~models/opportunity/newOpportunityData';
import OpportunityGridItem from '~models/opportunity/opportunityGridItem';

import { Grid } from '@mui/material';
import { getter } from "@progress/kendo-react-common";
import {
    GridColumn,
    GridToolbar,
    GridPageChangeEvent,
    GridCellProps,
    GridRowDoubleClickEvent,
    GridSelectionChangeEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { provideLocalizationService, registerForLocalization, } from '@progress/kendo-react-intl';
import { Link } from 'react-router-dom';

import AMWGrid from '~components/datagrid/AMWGrid';
import CustomCell from '~components/datagrid/CustomCell';
import { GridFilterable, GridPageable, GridSortable, SELECTED_FIELD } from '~components/datagrid/types';
import ConfirmationDialog from '~components/infra/ConfirmationDialog';
import { GridContainer, GridContainerDispatchProps, GridContainerStateProps } from '~components/infra/GridContainer';
import OpportunitiesToolbar from './OpportunitiesToolbar';
import NewOpportunityModal from './NewOpportunityModal';


const DATA_ITEM_KEY: string = "opportunityId";
const idGetter = getter(DATA_ITEM_KEY);

type OpportunitiesTabStateProps = ScreenStore.ScreenState
    & CRMStore.OpportunityTabState
    & GridFilterable
    & GridPageable
    & GridSortable
    & GridContainerStateProps;

type OpportunitiesTabDispatchProps = typeof CRMStore.opportunityActionCreators
    & typeof NavigationStore.actionCreators
    & GridContainerDispatchProps;

type OpportunitiesTabProps = OpportunitiesTabStateProps
    & OpportunitiesTabDispatchProps;

type OpportunitiesTabState = {
    containerWidth: number;
    selectedId?: number;
};
const minColumnWidth = {
    [SELECTED_FIELD]: 50,
    [CRMOpportunitiesGridFields.UniqueId]: 100,
    [CRMOpportunitiesGridFields.CreatedOn]: 110,
    [CRMOpportunitiesGridFields.LeadName]: 150,
    [CRMOpportunitiesGridFields.OwnedByName]: 125,
    [CRMOpportunitiesGridFields.Description]: 150,
    [CRMOpportunitiesGridFields.ExpireOn]: 125,
    [CRMOpportunitiesGridFields.Stage]: 100,
    [CRMOpportunitiesGridFields.Status]: 100,
    [CRMOpportunitiesGridFields.ForecastTotal]: 100,
    [CRMOpportunitiesGridFields.Certainty]: 100,
    [CRMOpportunitiesGridFields.Priority]: 100,
};

class OpportunitiesTab extends GridContainer<OpportunitiesTabProps, OpportunitiesTabState> {
    constructor(props: Readonly<OpportunitiesTabProps>) {
        super(props);
        this.state = {
            containerWidth: 1,
        };

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.requestOpportunities(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);

        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.resize);

        let element = document.querySelector('#OpportunitiesGridContainer .k-grid-header');
        let tabs = document.querySelector('#CRMTabs');
        !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );

        this.setState({
            containerWidth: document.getElementById('OpportunitiesGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    componentDidUpdate(prevProps: OpportunitiesTabProps) {
        if (!equal(prevProps.opportunities, this.props.opportunities)) {
            this.setState({ selectedId: undefined, });
        }

        if (prevProps.deleteStatus !== this.props.deleteStatus && this.props.deleteStatus === ServerOperationStatus.SUCCESS) {
            this.props.requestOpportunities(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);
        }

        if (!equal(prevProps.topbarHeight, this.props.topbarHeight)) {
            let element = document.querySelector(`#OpportunitiesGridContainer .k-grid-header`);
            let tabs = document.querySelector('#CRMTabs');
            !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("orientationchange", this.resize);
    }

    private resize() {
        this.setState({
            containerWidth: document.getElementById('OpportunitiesGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    private onRowDoubleClick(event: GridRowDoubleClickEvent) {
        let opportunity = event.dataItem as OpportunityGridItem;
        this.props.navigate(`/opportunities/${opportunity.opportunityId}`);
    }

    private onRowSelect(event: GridSelectionChangeEvent) {
        if (event.nativeEvent.target.tagName === 'A') {
            return false;
        }

        this.setState({
            selectedId: GridUtils.getSelectedIds(event, !!this.state.selectedId ? [this.state.selectedId] : [], DATA_ITEM_KEY)[0],
        });
    }

    private onNewOpportunityClick() {
        this.props.startCreateOpportunity();
    }

    private onNewOpportunitySubmit = (data: NewOpportunityData) => {
        this.props.createOpportunity(data);
    }

    private onNewOpportunityCancel() {
        this.props.cancelCreateOpportunity();
    }

    private onDeleteOpportunityClick() {
        this.props.startDeleteOpportunity();
    }

    private onDeleteOpportunityClose() {
        this.props.cancelDeleteOpportunity();
    }

    private onDeleteOpportunitySubmit = () => {
        !!this.state.selectedId && this.props.deleteOpportunity(this.state.selectedId);
    }

    private calculateColumnWidth(field: string) : number | undefined {
        return GridUtils.calculateColumnWidth(
            field,
            this.props.columnWidth,
            this.state.containerWidth,
            minColumnWidth
        );
    }

    private get columns() {
        const localization = provideLocalizationService(this);

        let columns = [
            (
                <GridColumn
                    key={"opportunities-check"}
                    field={SELECTED_FIELD}
                    width={checkboxColumnWidth}
                    headerClassName={'grid__header--empty'}
                    className={'grid__cell--checkbox'}
                    reorderable={false}
                    orderIndex={0}
                    resizable={false}
                />
            ),
            (
                <GridColumn
                    key={"opportunities-number"}
                    field={CRMOpportunitiesGridFields.UniqueId}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Grid.UniqueId, "%Opportunity #")}
                    cell={(
                        (props: GridCellProps) => {
                            const opportunity = props.dataItem as OpportunityGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Link to={`/opportunities/${opportunity.opportunityId}`}>{opportunity.uniqueId}</Link>
                                </CustomCell>
                            );
                        }
                    )}
                    className={'grid__cell--link'}
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.UniqueId) ?? 100}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.UniqueId]} />
            ),
            (
                <GridColumn
                    key={"opportunities-leadName"}
                    field={CRMOpportunitiesGridFields.LeadName}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Grid.LeadName, "%Company")}
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.LeadName)}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.LeadName]} />
            ),
            (
                <GridColumn
                    key={"opportunities-ownedBy"}
                    field={CRMOpportunitiesGridFields.OwnedByName}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Grid.OwnedBy, "%Owned by")}
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.OwnedByName)}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.OwnedByName]} />
            ),
            (
                <GridColumn
                    key={"opportunities-description"}
                    field={CRMOpportunitiesGridFields.Description}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Grid.Description, "%Description")}
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.Description)}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.Description]} />
            ),
            (
                <GridColumn
                    key={"opportunities-expireOn"}
                    field={CRMOpportunitiesGridFields.ExpireOn}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Grid.ExpireOn, "%Relevant till")}
                    format={'{0:d}'}
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.ExpireOn)}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.ExpireOn]} />
            ),
            (
                <GridColumn
                    key={"opportunities-stage"}
                    field={CRMOpportunitiesGridFields.Stage}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Grid.Stage, "%Stage")}
                    cell={(
                        (props: GridCellProps) => {
                            const opportunity = props.dataItem as OpportunityGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(opportunity.stage, opportunity.stage)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.Stage)}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.Stage]} />
            ),
            (
                <GridColumn
                    key={"opportunities-status"}
                    field={CRMOpportunitiesGridFields.Status}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Grid.Status, "%Status")}
                    cell={(
                        (props: GridCellProps) => {
                            const opportunity = props.dataItem as OpportunityGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(opportunity.status, opportunity.status)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.Status)}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.Status]} />
            ),
            (
                <GridColumn
                    key={"opportunities-forecastTotal"}
                    field={CRMOpportunitiesGridFields.ForecastTotal}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Grid.ForecastTotal, "%Forecast total")}
                    format="{0:n2}"
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.ForecastTotal)}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.ForecastTotal]} />
            ),
            (
                <GridColumn
                    key={"opportunities-certainty"}
                    field={CRMOpportunitiesGridFields.Certainty}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Grid.Certainty, "%Certainty")}
                    cell={(
                        (props: GridCellProps) => {
                            const opportunity = props.dataItem as OpportunityGridItem;
                            return (
                                <CustomCell {...props}>
                                    {`${opportunity.certainty}%`}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.Certainty)}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.Certainty]} />
            ),
            (
                <GridColumn
                    key={"opportunities-priority"}
                    field={CRMOpportunitiesGridFields.Priority}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Grid.Priority, "%Priority")}
                    cell={(
                        (props: GridCellProps) => {
                            const opportunity = props.dataItem as OpportunityGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(opportunity.priority, opportunity.priority)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(CRMOpportunitiesGridFields.Priority)}
                    orderIndex={this.props.columnOrder[CRMOpportunitiesGridFields.Priority]} />
            ),
        ];

        return columns;
    }

    private onPageChange = (event: GridPageChangeEvent) => {
        this.props.requestOpportunities(this.props.filters, event.page.skip, event.page.take, this.props.sorting);
    }

    private onSortChange = (event: GridSortChangeEvent) => {
        this.props.requestOpportunities(this.props.filters, this.props.skip, this.props.pageSize, event.sort);
    }

    render() {
        const localization = provideLocalizationService(this);

        let isGridBusy = this.props.isLoading ||
            this.props.createStatus === ServerOperationStatus.INPROGRESS ||
            this.props.deleteStatus === ServerOperationStatus.INPROGRESS;

        return (
            <Grid id={'OpportunitiesGridContainer'}>
                <AMWGrid
                    sticky={true}
                    scrollable={'scrollable'}

                    data={this.props.opportunities.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: this.state.selectedId === idGetter(item),
                    }))}
                    dataItemKey={DATA_ITEM_KEY}
                    total={this.props.count}

                    pageable={{ buttonCount: 4, pageSizes: true }}
                    skip={this.props.skip}
                    pageSize={this.props.pageSize}
                    onPageChange={this.onPageChange.bind(this)}

                    sortable={true}
                    sort={this.props.sorting}
                    onSortChange={this.onSortChange.bind(this)}

                    selectedField={SELECTED_FIELD}
                    selectable={{enabled: true, mode:'single'}}
                    onSelectionChange={this.onRowSelect.bind(this)}

                    reorderable={true}
                    onColumnReorder={this.onColumnReorder.bind(this)}

                    resizable={true}
                    onColumnResize={this.onColumnResize.bind(this)}

                    onRowDoubleClick={this.onRowDoubleClick.bind(this)}
                >
                    <GridToolbar>
                        <OpportunitiesToolbar
                            selectedItem={this.state.selectedId}
                            isGridBusy={isGridBusy}
                            filters={this.props.filters}
                            requestData={(filters) => {
                                this.props.requestOpportunities(filters, this.props.skip, this.props.pageSize, this.props.sorting);
                            }}
                            onNewOpportunityClick={this.onNewOpportunityClick.bind(this)}
                            onDeleteOpportunityClick={this.onDeleteOpportunityClick.bind(this)}
                            opportunitiesNr={this.props.opportunitiesNr}
                            forecastTotal={this.props.forecastTotal}
                            approvalsNr={this.props.approvalsNr}
                            approvalTotal={this.props.approvalTotal}
                            declinesNr={this.props.declinesNr}
                            declineTotal={this.props.declineTotal}
                            proposalsNr={this.props.proposalsNr}
                            proposalTotal={this.props.proposalTotal}
                        />
                    </GridToolbar>

                    {this.columns}
                </AMWGrid>

                <ConfirmationDialog
                    open={this.props.deleteStatus !== ServerOperationStatus.NONE &&
                        this.props.deleteStatus !== ServerOperationStatus.SUCCESS}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Delete.Title, "%Delete opportunity")}
                    text={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Delete.Text, "%Selected opportunity will be permanently deleted. Proceed?")}
                    onSubmit={this.onDeleteOpportunitySubmit.bind(this)}
                    onCancel={this.onDeleteOpportunityClose.bind(this)}
                />

                {this.props.createStatus !== ServerOperationStatus.NONE &&
                    this.props.createStatus !== ServerOperationStatus.SUCCESS &&
                (
                    <NewOpportunityModal
                        open={true}
                        onSubmit={this.onNewOpportunitySubmit.bind(this)}
                        onClose={this.onNewOpportunityCancel.bind(this)}
                        createStatus={this.props.createStatus}
                    />
                )}
            </Grid>
        );
    }
}
registerForLocalization(OpportunitiesTab);

export default connect(
    (state: ApplicationState) :OpportunitiesTabStateProps => {
        return {
            pageKey: UserPreferencePage.CRMOpportunities,
            ...state.crm?.opportunities,
            ...state.screen,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.CRMOpportunities}-${UserPreferenceKey.Filters}`] ?? {},
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.CRMOpportunities}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.CRMOpportunities}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.CRMOpportunities}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.CRMOpportunities}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        };
    },
    (dispatch) : OpportunitiesTabDispatchProps => {
        return {
            ...bindActionCreators({...CRMStore.opportunityActionCreators}, dispatch),
            ...bindActionCreators({...NavigationStore.actionCreators}, dispatch),
            ...bindActionCreators({...UsersStore.actionCreators}, dispatch),
        };
    },
)(OpportunitiesTab as any);