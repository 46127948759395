import {
    ArrowRight as ArrowClosed
} from '@mui/icons-material';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization, } from '@progress/kendo-react-intl';
import LocalizationKeys from '~enums/localizationKeys';

type FilterButtonProps = {
    opened: boolean,
    onOpen: (open: boolean) => void,
};

const FilterButton = (props: FilterButtonProps) => {
    const localization = useLocalization();

    return (
        <Button
            className={`AMWGrid__Toolbar__FilterButton ${props.opened ? 'AMWGrid__Toolbar__FilterButton--opened' : ''}`}
            fillMode="flat"
            onClick={() => {props.onOpen(!props.opened);}}
            title={localization.toLanguageString(LocalizationKeys.Shared.Buttons.Filter, '%Filter')}
            icon={"filter"}
        >
            <ArrowClosed />
        </Button>
    );
}

export default FilterButton;