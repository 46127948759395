import React from 'react';
import {
    DropDownList,
} from '@progress/kendo-react-dropdowns';
import { LinearProgress } from '@mui/material';
import { DropDownListWrapperProps } from './types';

import '~css/components/controls/DropDownListWrapper.scss';


export default class DropDownListWrapper extends React.Component<DropDownListWrapperProps> {
    __drop?: DropDownList | null;

    render() {
        let classes = `DropDownListWrapper ${this.props.className ?? ''}`;

        let popupSettings = this.props.popupSettings ?? {};
        popupSettings.className = `${popupSettings.className ?? ''}`;
        !popupSettings.className.includes('DropDownListWrapper__popup') && (popupSettings.className += ' DropDownListWrapper__popup');

        return (
            <div className={classes}>
                <DropDownList
                    ref={(drop) => {this.__drop = drop;}}
                    {...this.props}
                    popupSettings={popupSettings}
                    onOpen={(event) => {
                        let doc = document.documentElement;
                        doc.style.setProperty('--currentDropdownWidth', `${event.target.element?.clientWidth}px`);

                        !!this.props.onOpen && this.props.onOpen(event);
                    }}
                />

                {!!this.props.inProgress && (
                    <LinearProgress className={`DropDownListWrapper__Progress`} />
                )}
            </div>
        );
    }
}