const AddressFields = Object.freeze({
    Street1: "AddressField.Street1",
    Street2: "AddressField.Street2",
    Street3: "AddressField.Street3",
    City: "AddressField.City",
    ZIP: "AddressField.ZIP",
    Country: "AddressField.Country",
    State: "AddressField.State",
    AddressType: "AddressField.AddressType",
    Disabled: "AddressField.Disabled",
    Comments: "AddressField.Comments",
    UseForMailing: "AddressField.UseForMailing",
    UseForShipping: "AddressField.UseForShipping",
    UseForBilling: "AddressField.UseForBilling",
    MailingDefault: "AddressField.MailingDefault",
    ShippingDefault: "AddressField.ShippingDefault",
    BillingDefault: "AddressField.BillingDefault",
});

export type AddressFieldsType = typeof AddressFields[keyof typeof AddressFields];

export default AddressFields;