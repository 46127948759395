import * as React from 'react';
import '~css/components/layout/Preloader.scss';

type PreloaderSize = ("medium" | "small" | "verysmall");

export default class Preloader extends React.PureComponent<{size?: PreloaderSize}> {
    render() {
        let addClass = '';
        if (!!this.props.size) {
            switch(this.props.size) {
                case "medium":
                    addClass = "Preloader--medium";
                    break;
                case "small":
                    addClass = "Preloader--small";
                    break;
                case "verysmall":
                    addClass = "Preloader--verysmall";
                    break;
                default:
                    throw new Error(`Incorrect size prop, accepted values are (medium | small | verysmall)`);
            }
        }
        return (
            <div className="Preloader__root">
                <div className={`Preloader__loader ${addClass}`}></div>
            </div>
        )
    }
}