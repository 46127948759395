import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { ShowErrorAction } from '~store/infra/errors';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import WarehouseGridItem from '~models/warehouse/warehouseGridItem';
import WarehouseCreateModel from '~models/warehouse/warehouseCreateModel';
import { DataRequest } from '~models/dataRequests';
import { SortDescriptor } from "@progress/kendo-data-query";
import WarehouseUpdateModel from '~models/warehouse/warehouseUpdateModel';
import LocationGridItem from '~models/location/locationGridItem';
import LocationCreateModel from '~models/location/locationCreateModel';
import LocationUpdateModel from '~models/location/locationUpdateModel';
import { FilterHandler } from '~models/filters';

export interface WarehouseLocationsState {
    isLoading: boolean;
    locations: Array<LocationGridItem>;
    count: number;
    skip: number;

    locationCreateStatus: ServerOperationStatus;
    locationUpdateStatus: ServerOperationStatus;
    locationDeleteStatus: ServerOperationStatus;
}

export interface WarehousesState {
    isLoading: boolean;
    warehouses: Array<WarehouseGridItem>;
    // count: number;
    // skip: number;
    warehouseCreateStatus: ServerOperationStatus;
    warehouseUpdateStatus: ServerOperationStatus;

    locations: WarehouseLocationsState
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface WarehousesRequestAction {
    type: 'WAREHOUSES_REQUEST';
    // skip: number;
}
interface WarehousesReceiveAction {
    type: 'WAREHOUSES_RECEIVE';
    warehouses: WarehouseGridItem[];
    // count: number;
}
interface WarehousesErrorAction {
    type: 'WAREHOUSES_ERROR';
}

interface WarehousesCreateStartAction {
    type: 'WAREHOUSES_CREATE_START';
}
interface WarehousesCreateSendAction {
    type: 'WAREHOUSES_CREATE_SEND';
}
interface WarehousesCreateSuccessAction {
    type: 'WAREHOUSES_CREATE_SUCCESS';
}
interface WarehousesCreateCancelAction {
    type: 'WAREHOUSES_CREATE_CANCEL';
}
interface WarehousesCreateErrorAction {
    type: 'WAREHOUSES_CREATE_ERROR';
}

interface WarehousesUpdateStartAction {
    type: 'WAREHOUSES_UPDATE_START';
}
interface WarehousesUpdateSendAction {
    type: 'WAREHOUSES_UPDATE_SEND';
}
interface WarehousesUpdateSuccessAction {
    type: 'WAREHOUSES_UPDATE_SUCCESS';
}
interface WarehousesUpdateCancelAction {
    type: 'WAREHOUSES_UPDATE_CANCEL';
}
interface WarehousesUpdateErrorAction {
    type: 'WAREHOUSES_UPDATE_ERROR';
}

interface WarehousesLocationsRequestAction {
    type: 'WAREHOUSES_LOCATIONS_REQUEST';
    skip: number;
}
interface WarehousesLocationsReceiveAction {
    type: 'WAREHOUSES_LOCATIONS_RECEIVE';
    locations: LocationGridItem[];
    count: number;
}
interface WarehousesLocationsErrorAction {
    type: 'WAREHOUSES_LOCATIONS_ERROR';
}

interface WarehousesLocationsCreateStartAction {
    type: 'WAREHOUSES_LOCATIONS_CREATE_START';
}
interface WarehousesLocationsCreateSendAction {
    type: 'WAREHOUSES_LOCATIONS_CREATE_SEND';
}
interface WarehousesLocationsCreateSuccessAction {
    type: 'WAREHOUSES_LOCATIONS_CREATE_SUCCESS';
}
interface WarehousesLocationsCreateCancelAction {
    type: 'WAREHOUSES_LOCATIONS_CREATE_CANCEL';
}
interface WarehousesLocationsCreateErrorAction {
    type: 'WAREHOUSES_LOCATIONS_CREATE_ERROR';
}

interface WarehousesLocationsUpdateStartAction {
    type: 'WAREHOUSES_LOCATIONS_UPDATE_START';
}
interface WarehousesLocationsUpdateSendAction {
    type: 'WAREHOUSES_LOCATIONS_UPDATE_SEND';
}
interface WarehousesLocationsUpdateSuccessAction {
    type: 'WAREHOUSES_LOCATIONS_UPDATE_SUCCESS';
}
interface WarehousesLocationsUpdateCancelAction {
    type: 'WAREHOUSES_LOCATIONS_UPDATE_CANCEL';
}
interface WarehousesLocaitonsUpdateErrorAction {
    type: 'WAREHOUSES_LOCATIONS_UPDATE_ERROR';
}

interface WarehousesLocationsDeleteStartAction {
    type: 'WAREHOUSES_LOCATIONS_DELETE_START';
}
interface WarehousesLocationsDeleteSendAction {
    type: 'WAREHOUSES_LOCATIONS_DELETE_SEND';
}
interface WarehousesLocationsDeleteSuccessAction {
    type: 'WAREHOUSES_LOCATIONS_DELETE_SUCCESS';
}
interface WarehousesLocationsDeleteCancelAction {
    type: 'WAREHOUSES_LOCATIONS_DELETE_CANCEL';
}
interface WarehousesLocationsDeleteErrorAction {
    type: 'WAREHOUSES_LOCATIONS_DELETE_ERROR';
}

interface WarehousesLocationsClearAction {
    type: 'WAREHOUSES_LOCATIONS_CLEAR';
}


type KnownAction = WarehousesRequestAction | WarehousesReceiveAction | WarehousesErrorAction
    | WarehousesCreateStartAction | WarehousesCreateSendAction
    | WarehousesCreateSuccessAction | WarehousesCreateCancelAction
    | WarehousesCreateErrorAction
    | WarehousesUpdateStartAction | WarehousesUpdateSendAction
    | WarehousesUpdateSuccessAction | WarehousesUpdateCancelAction
    | WarehousesUpdateErrorAction
    | WarehousesLocationsRequestAction | WarehousesLocationsReceiveAction
    | WarehousesLocationsErrorAction | WarehousesLocationsClearAction
    | WarehousesLocationsCreateStartAction | WarehousesLocationsCreateSendAction
    | WarehousesLocationsCreateSuccessAction | WarehousesLocationsCreateCancelAction
    | WarehousesLocationsCreateErrorAction
    | WarehousesLocationsUpdateStartAction | WarehousesLocationsUpdateSendAction
    | WarehousesLocationsUpdateSuccessAction | WarehousesLocationsUpdateCancelAction
    | WarehousesLocaitonsUpdateErrorAction
    | WarehousesLocationsDeleteStartAction | WarehousesLocationsDeleteSendAction
    | WarehousesLocationsDeleteSuccessAction | WarehousesLocationsDeleteCancelAction
    | WarehousesLocationsDeleteErrorAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;

export const buildLocationUrl = (warehouseId: number, skip?: number, pageSize?: number, sorting?: Array<SortDescriptor>, filter?: string): string => {
    let url = `/v1/warehouses/${warehouseId}/locations`;

    let params = new URLSearchParams({});
    if (skip !== undefined) params.append('skip', `${skip}`);
    if (pageSize !== undefined) params.append('pageSize', `${pageSize}`);
    if (sorting !== undefined && sorting.length > 0) {
        params.append('sortBy', sorting[0].field);
        params.append('sortDirection', sorting[0].dir ?? 'asc');
    }
    if (filter !== undefined && filter.length > 0) params.append('filter', filter);
    if (Array.from(params).length > 0) url += `?${params}`;

    return url;
};

export const actionCreators = {
    requestWarehouses: (): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses && !appState.warehouses.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<WarehouseGridItem>>('/v1/warehouses/search', 'POST', {})
                .then(data => {
                    // dispatch(UserActions.updateUserPreferences(`${UserPreferencePage.Warehouses}-${UserPreferenceKey.Sorting}`, sorting));
                    // dispatch(UserActions.updateUserPreferences(`${UserPreferencePage.Warehouses}-${UserPreferenceKey.PageSize}`, pageSize));

                    dispatch({ type: 'WAREHOUSES_RECEIVE', warehouses: data.data });
                })
                .catch(err => {
                    dispatch({ type: 'WAREHOUSES_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'WAREHOUSES_REQUEST' });
        }
    },
    startCreateWarehouse: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            dispatch({ type: 'WAREHOUSES_CREATE_START' });
        }
    },
    createWarehouse: (data: WarehouseCreateModel):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            apiClientInstance.fetchRequest(
                '/v1/warehouses/',
                'POST',
                data
            )
                .then((newId) => {
                    dispatch({ type: 'WAREHOUSES_CREATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'WAREHOUSES_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'WAREHOUSES_CREATE_SEND' });
        }
    },
    cancelCreateWarehouse: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            dispatch({ type: 'WAREHOUSES_CREATE_CANCEL' });
        }
    },
    startUpdateWarehouse: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            dispatch({ type: 'WAREHOUSES_UPDATE_START' });
        }
    },
    updateWarehouse: (data: WarehouseUpdateModel):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            apiClientInstance.fetchRequest(
                `/v1/warehouses/${data.warehouseId}`,
                'PATCH',
                data
            )
                .then(() => {
                    dispatch({ type: 'WAREHOUSES_UPDATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'WAREHOUSES_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'WAREHOUSES_UPDATE_SEND' });
        }
    },
    cancelUpdateWarehouse: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            dispatch({ type: 'WAREHOUSES_UPDATE_CANCEL' });
        }
    },

    requestLocations: (warehouseId: number, skip: number, pageSize: number, sorting: Array<SortDescriptor>, filter: string): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses && !appState.warehouses.locations.isLoading) {
            let url = buildLocationUrl(warehouseId, skip, pageSize, sorting, filter);

            apiClientInstance.fetchRequest<DataRequest<LocationGridItem>>(url, 'GET')
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.WarehouseLocations}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.WarehouseLocations}-${UserPreferenceKey.PageSize}`]: pageSize
                    }));

                    dispatch({ type: 'WAREHOUSES_LOCATIONS_RECEIVE', locations: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'WAREHOUSES_LOCATIONS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'WAREHOUSES_LOCATIONS_REQUEST', skip: skip, });
        }
    },
    clearLocations: (): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            dispatch({ type: 'WAREHOUSES_LOCATIONS_CLEAR', });
        }
    },

    startCreateLocation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            dispatch({ type: 'WAREHOUSES_LOCATIONS_CREATE_START' });
        }
    },
    createLocation: (data: LocationCreateModel):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            apiClientInstance.fetchRequest(
                '/v1/locations/',
                'POST',
                data
            )
                .then((newId) => {
                    dispatch({ type: 'WAREHOUSES_LOCATIONS_CREATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'WAREHOUSES_LOCATIONS_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'WAREHOUSES_LOCATIONS_CREATE_SEND' });
        }
    },
    cancelCreateLocation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            dispatch({ type: 'WAREHOUSES_LOCATIONS_CREATE_CANCEL' });
        }
    },

    startUpdateLocation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            dispatch({ type: 'WAREHOUSES_LOCATIONS_UPDATE_START' });
        }
    },
    updateLocation: (data: LocationUpdateModel):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            apiClientInstance.fetchRequest(
                `/v1/locations/${data.locationId}`,
                'PATCH',
                data
            )
                .then(() => {
                    dispatch({ type: 'WAREHOUSES_LOCATIONS_UPDATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'WAREHOUSES_LOCATIONS_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'WAREHOUSES_LOCATIONS_UPDATE_SEND' });
        }
    },
    cancelUpdateLocation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            dispatch({ type: 'WAREHOUSES_LOCATIONS_UPDATE_CANCEL' });
        }
    },

    startDeleteLocation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            dispatch({ type: 'WAREHOUSES_LOCATIONS_DELETE_START' });
        }
    },
    deleteLocation: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            apiClientInstance.fetchRequest(
                `/v1/locations/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'WAREHOUSES_LOCATIONS_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'WAREHOUSES_LOCATIONS_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'WAREHOUSES_LOCATIONS_DELETE_SEND' });
        }
    },
    cancelDeleteLocation: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.warehouses) {
            dispatch({ type: 'WAREHOUSES_LOCATIONS_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: WarehousesState = {
    isLoading: false,
    warehouses: [],
    warehouseCreateStatus: ServerOperationStatus.NONE,
    warehouseUpdateStatus: ServerOperationStatus.NONE,

    locations: {
        isLoading: false,
        locations: [],
        count: 0,
        skip: 0,
        locationCreateStatus: ServerOperationStatus.NONE,
        locationUpdateStatus: ServerOperationStatus.NONE,
        locationDeleteStatus: ServerOperationStatus.NONE,
    }
 };

export const reducer: Reducer<WarehousesState> = (state: WarehousesState | undefined, incomingAction: Action): WarehousesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'WAREHOUSES_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'WAREHOUSES_RECEIVE':
            return {
                ...state,
                warehouses: action.warehouses,
                isLoading: false,
            };
        case 'WAREHOUSES_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'WAREHOUSES_CREATE_START':
            return {
                ...state,
                warehouseCreateStatus: ServerOperationStatus.READY,
            };
        case 'WAREHOUSES_CREATE_SEND':
            return {
                ...state,
                isLoading: true,
                warehouseCreateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'WAREHOUSES_CREATE_CANCEL':
            return {
                ...state,
                warehouseCreateStatus: ServerOperationStatus.NONE,
            };
        case 'WAREHOUSES_CREATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                warehouseCreateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'WAREHOUSES_CREATE_ERROR':
            return {
                ...state,
                isLoading: false,
                warehouseCreateStatus: ServerOperationStatus.ERROR,
            };

        case 'WAREHOUSES_UPDATE_START':
            return {
                ...state,
                warehouseUpdateStatus: ServerOperationStatus.READY,
            };
        case 'WAREHOUSES_UPDATE_SEND':
            return {
                ...state,
                warehouseUpdateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'WAREHOUSES_UPDATE_CANCEL':
            return {
                ...state,
                warehouseUpdateStatus: ServerOperationStatus.NONE,
            };
        case 'WAREHOUSES_UPDATE_SUCCESS':
            return {
                ...state,
                warehouseUpdateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'WAREHOUSES_UPDATE_ERROR':
            return {
                ...state,
                warehouseUpdateStatus: ServerOperationStatus.ERROR,
            };

        case 'WAREHOUSES_LOCATIONS_REQUEST':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    isLoading: true,
                    skip: action.skip,
                },
            };
        case 'WAREHOUSES_LOCATIONS_RECEIVE':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locations: action.locations,
                    count: action.count,
                    isLoading: false,
                },
            };
        case 'WAREHOUSES_LOCATIONS_ERROR':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    isLoading: false,
                },
            };
        case 'WAREHOUSES_LOCATIONS_CLEAR':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locations: [],
                    count: 0,
                    skip: 0,
                }
            };

        case 'WAREHOUSES_LOCATIONS_CREATE_START':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationCreateStatus: ServerOperationStatus.READY,
                }
            };
        case 'WAREHOUSES_LOCATIONS_CREATE_SEND':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationCreateStatus: ServerOperationStatus.INPROGRESS,
                }
            };
        case 'WAREHOUSES_LOCATIONS_CREATE_CANCEL':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationCreateStatus: ServerOperationStatus.NONE,
                }
            };
        case 'WAREHOUSES_LOCATIONS_CREATE_SUCCESS':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationCreateStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'WAREHOUSES_LOCATIONS_CREATE_ERROR':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationCreateStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'WAREHOUSES_LOCATIONS_UPDATE_START':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationUpdateStatus: ServerOperationStatus.READY,
                },
            };
        case 'WAREHOUSES_LOCATIONS_UPDATE_SEND':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationUpdateStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'WAREHOUSES_LOCATIONS_UPDATE_CANCEL':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationUpdateStatus: ServerOperationStatus.NONE,
                }
            };
        case 'WAREHOUSES_LOCATIONS_UPDATE_SUCCESS':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationUpdateStatus: ServerOperationStatus.SUCCESS,
                }
            };
        case 'WAREHOUSES_LOCATIONS_UPDATE_ERROR':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationUpdateStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'WAREHOUSES_LOCATIONS_DELETE_START':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationDeleteStatus: ServerOperationStatus.READY,
                },
            };
        case 'WAREHOUSES_LOCATIONS_DELETE_SEND':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationDeleteStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'WAREHOUSES_LOCATIONS_DELETE_CANCEL':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationDeleteStatus: ServerOperationStatus.NONE,
                },
            };
        case 'WAREHOUSES_LOCATIONS_DELETE_SUCCESS':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationDeleteStatus: ServerOperationStatus.SUCCESS,
                }
            };
        case 'WAREHOUSES_LOCATIONS_DELETE_ERROR':
            return {
                ...state,
                locations: {
                    ...state.locations,
                    locationDeleteStatus: ServerOperationStatus.ERROR,
                },
            };
    }

    return state;
};