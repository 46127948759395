const PhotoGalleryType = Object.freeze({
    Condition: "Condition",
    Item: "Item",
    Package: "Package",
    QuickEntry: "QuickEntry",
    TransactionOp: "TransactionOp",
});

export type PhotoGalleryTypeType = typeof PhotoGalleryType[keyof typeof PhotoGalleryType];

export default PhotoGalleryType;