import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';
import { ShowErrorAction } from '~store/infra/errors';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { SortDescriptor } from "@progress/kendo-data-query";

import BillableServiceConfigFields, { BillableServiceConfigFieldsType } from '~enums/fields/billableServiceConfig';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import BillableServiceConfigCreateModel from '~models/billableServiceConfig/billableServiceConfigCreateModel';
import BillableServiceConfigGridItem from '~models/billableServiceConfig/billableServiceConfigGridItem';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';


export interface ManagementBillableServiceConfigState {
    isLoading: boolean;
    configs: Array<BillableServiceConfigGridItem>;
    count: number;
    skip: number;
    updatedField?: BillableServiceConfigFieldsType;
    createStatus: ServerOperationStatus;
    updateStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface BillableServiceConfigRequestAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_REQUEST';
    skip: number;
}
interface BillableServiceConfigReceiveAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_RECEIVE';
    configs: Array<BillableServiceConfigGridItem>;
    count: number;
}
interface BillableServiceConfigErrorAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_ERROR';
}

interface BillableServiceConfigCreateSendAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_CREATE_SEND';
}
interface BillableServiceConfigCreateSuccessAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_CREATE_SUCCESS';
    newItem: BillableServiceConfigGridItem;
}
interface BillableServiceConfigCreateErrorAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_CREATE_ERROR';
}

interface BillableServiceConfigUpdateSendAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_UPDATE_SEND';
    field: BillableServiceConfigFieldsType;
}
interface BillableServiceConfigUpdateSuccessAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_UPDATE_SUCCESS';
    object: BillableServiceConfigGridItem;
}
interface BillableServiceConfigUpdateErrorAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_UPDATE_ERROR';
}

interface BillableServiceConfigDeleteStartAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_START';
}
interface BillableServiceConfigDeleteSendAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_SEND';
}
interface BillableServiceConfigDeleteSuccessAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_SUCCESS';
}
interface BillableServiceConfigDeleteCancelAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_CANCEL';
}
interface BillableServiceConfigDeleteErrorAction {
    type: 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_ERROR';
}

type KnownAction = BillableServiceConfigRequestAction | BillableServiceConfigReceiveAction | BillableServiceConfigErrorAction
    | BillableServiceConfigCreateSendAction | BillableServiceConfigCreateSuccessAction | BillableServiceConfigCreateErrorAction
    | BillableServiceConfigUpdateSendAction | BillableServiceConfigUpdateSuccessAction | BillableServiceConfigUpdateErrorAction
    | BillableServiceConfigDeleteStartAction | BillableServiceConfigDeleteSendAction
    | BillableServiceConfigDeleteSuccessAction | BillableServiceConfigDeleteCancelAction
    | BillableServiceConfigDeleteErrorAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestConfigs: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementBillableServices && !appState.managementBillableServices.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<BillableServiceConfigGridItem>>('/v1/billable-service-configs/search', 'POST', filters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ManagementBillableServiceConfig}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.ManagementBillableServiceConfig}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ManagementBillableServiceConfig}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_RECEIVE', configs: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_REQUEST', skip: skip, });
        }
    },
    updateField: (id: number, serverField: BillableServiceConfigFieldsType, value: any, text?: string, onSuccess?: () => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementBillableServices) {
            apiClientInstance.fetchRequest(
                `v1/billable-service-configs/${id}`,
                'PATCH',
                { field: serverField, value: value }
            )
                .then((data) => {
                    let config = {...appState.managementBillableServices?.configs.find(x => x.billableServiceConfigId === id) ?? {} as BillableServiceConfigGridItem};

                    switch (serverField) {
                        case BillableServiceConfigFields.DivisionId:
                            config.divisionId = (data as FieldUpdateResult<number>).value;
                            config.divisionName = text ?? '';
                            break;
                        case BillableServiceConfigFields.JobType:
                            config.jobType = (data as FieldUpdateResult<string>).value;
                            break;
                        case BillableServiceConfigFields.TransportMode:
                            config.transportMode = (data as FieldUpdateResult<string>).value;
                            break;
                        case BillableServiceConfigFields.Rate:
                            config.rate = (data as FieldUpdateResult<number>).value;
                            break;
                        case BillableServiceConfigFields.Uom:
                            config.uom = (data as FieldUpdateResult<string>).value;
                            break;
                        case BillableServiceConfigFields.VoxmeTransaction:
                            config.voxmeTransaction = (data as FieldUpdateResult<string>).value.split(',').map(x => x.trim());
                            break;
                        case BillableServiceConfigFields.Context:
                            config.context = (data as FieldUpdateResult<string>).value.split(',').map(x => x.trim());
                            break;
                        case BillableServiceConfigFields.CodeDescription:
                            config.codeDescription = (data as FieldUpdateResult<string>).value;
                            break;
                        case BillableServiceConfigFields.TransactionDescription:
                            config.transactionDescription = (data as FieldUpdateResult<string>).value;
                            break;
                        case BillableServiceConfigFields.TariffId:
                            config.tariffId = (data as FieldUpdateResult<number | null>).value;
                            config.tariffName = text ?? '';
                            break;
                        case BillableServiceConfigFields.Gl:
                            config.gl = (data as FieldUpdateResult<string>).value;
                            break;
                        case BillableServiceConfigFields.TaxStatus:
                            config.taxStatus = (data as FieldUpdateResult<number>).value;
                            break;
                        case BillableServiceConfigFields.TaxType:
                            config.taxType = (data as FieldUpdateResult<string>).value;
                            break;
                    }

                    !!onSuccess && onSuccess();

                    dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_UPDATE_SUCCESS', object: config, });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_UPDATE_SEND', field: serverField });
        }
    },
    createConfig: (model: BillableServiceConfigCreateModel, onSuccess?: (result: BillableServiceConfigGridItem) => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementBillableServices) {
            apiClientInstance.fetchRequest<number>(
                `v1/billable-service-configs/`,
                'POST',
                { ...model }
            )
                .then((data) => {
                    let newItem = {...model, billableServiceConfigId: data,} as BillableServiceConfigGridItem;

                    !!onSuccess && onSuccess(newItem);

                    dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_CREATE_SUCCESS', newItem: newItem });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_CREATE_SEND' });
        }
    },
    startDeleteConfig: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementBillableServices) {
            dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_START' });
        }
    },
    submitDeleteConfig: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementBillableServices) {
            apiClientInstance.fetchRequest(
                `/v1/billable-service-configs/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_SEND' });
        }
    },
    cancelDeleteConfig: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementBillableServices) {
            dispatch({ type: 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementBillableServiceConfigState = {
    isLoading: false,
    configs: [],
    count: 0,
    skip: 0,
    createStatus: ServerOperationStatus.NONE,
    updateStatus: ServerOperationStatus.NONE,
    deleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementBillableServiceConfigState> = (state: ManagementBillableServiceConfigState | undefined, incomingAction: Action): ManagementBillableServiceConfigState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_BILLABLESERVICECONFIG_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'MANAGEMENT_BILLABLESERVICECONFIG_RECEIVE':
            return {
                ...state,
                configs: action.configs,
                count: action.count,
                isLoading: false,
            };
        case 'MANAGEMENT_BILLABLESERVICECONFIG_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'MANAGEMENT_BILLABLESERVICECONFIG_UPDATE_SEND':
            return {
                ...state,
                updatedField: action.field,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_BILLABLESERVICECONFIG_UPDATE_SUCCESS':
            {
                let list = [...state.configs];
                let index = state.configs.findIndex(x => x.billableServiceConfigId === action.object.billableServiceConfigId);

                list[index] = action.object;

                return {
                    ...state,
                    configs: list,
                    updatedField: undefined,
                    updateStatus: ServerOperationStatus.SUCCESS
                };
            };
        case 'MANAGEMENT_BILLABLESERVICECONFIG_UPDATE_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_BILLABLESERVICECONFIG_CREATE_SEND':
            return {
                ...state,
                createStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_BILLABLESERVICECONFIG_CREATE_SUCCESS':
            return {
                ...state,
                createStatus: ServerOperationStatus.SUCCESS,
                configs: [...state.configs, action.newItem],
            };
        case 'MANAGEMENT_BILLABLESERVICECONFIG_CREATE_ERROR':
            return {
                ...state,
                createStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_START':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.READY,
            };
        case 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_SEND':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_CANCEL':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.NONE,
            };
        case 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_SUCCESS':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'MANAGEMENT_BILLABLESERVICECONFIG_DELETE_ERROR':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};