import React from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import '~css/components/controls/AMWTabs.scss';


export interface AMWTabsProps {
    id?: string;
    /** If true, adds a class for sticky positioning */
    sticky?: boolean;
    /** Currently selected tab value */
    value: string;
    /** Function on changing selected tab. Value is presumed to be stored outside of the component. */
    onChange: (value: string) => void;
    /** List of tabs to render */
    tabs: Array<AMWTabProps>;
    ariaLabel?: string;
    /** Classes to apply to tab strip */
    className?: string;
    /** Classes to apply to content section */
    contentClassName?: string;
    /** MUI parameter */
    variant?: "standard" | "scrollable" | "fullWidth";
    /** MUI parameter */
    scrollButtons?: boolean | 'auto';
}

export interface AMWTabProps {
    /** Tab identifier */
    value: string;
    /** Text to render for tab in tabstrip */
    label: string;
    /** Flag to render tab as disabled and unable to be selected */
    disabled?: boolean;
    /** Content of the tab to render */
    content: React.ReactNode;
    /** Flag to render tab or not, used for permissions */
    accessible?: boolean;
    /** Classes to apply to tab content */
    contentClassName?: string;
}

const AMWTabs : React.FC<AMWTabsProps> = (props) => {
    const onChange = (event: React.SyntheticEvent<Element, Event>, value: any) => {
        props.onChange(`${value}`);
    };

    return (
        <React.Fragment>
            <TabContext value={props.value}>
                <TabList
                    id={props.id}
                    className={`AMWTabs${!!props.sticky ? ' AMWTabs--sticky' : ''} ${!!props.className ? props.className : ''}`.trim()}
                    onChange={onChange}
                    aria-label={props.ariaLabel}
                    variant={props.variant}
                    scrollButtons={props.scrollButtons}
                >
                    {props.tabs.filter(tab => tab.accessible ?? true).map((tab) => {
                        return (
                            <Tab
                                key={tab.value}
                                label={tab.label}
                                value={tab.value}
                                disabled={tab.disabled}
                            />
                        )
                    })}
                </TabList>
                {props.tabs.filter(tab => tab.accessible ?? true).map((tab) => {
                    return (
                        <TabPanel
                            className={`${props.contentClassName ?? ''} ${tab.contentClassName ?? ''}`.trim()}
                            key={tab.value}
                            value={tab.value}
                        >
                            {tab.content}
                        </TabPanel>
                    );
                })}
            </TabContext>
        </React.Fragment>
    );
};

export default AMWTabs;