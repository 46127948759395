import { PartyType, PartyTypeType } from "~enums/partyType";
import SupportedLocale from "~enums/supportedLocale";
import { UserPermissionType, UserPermissions } from "~enums/userPermissions";

export interface ServerAMWUserData {
    username: string;
    firstName: string;
    lastName: string;
    fullName: string;
    personId: number | undefined | null;
    divisionId: number | undefined | null;
    divisionName: string | undefined | null;
    preferredLengthUnits: string;
    preferredVolumeUnits: string;
    preferredWeightUnits: string;
    preferredLanguageTag: SupportedLocale;
    permissions: Array<string>;
    preferences: {[key:string]:any};

    enforceCustomsStatus: boolean;
    jobInitializeBooker: boolean;
}

export class AMWUserData {
    private userData: ServerAMWUserData;

    constructor(data: ServerAMWUserData) {
        this.userData = data;
    }

    public get username() {
        return this.userData.username;
    }

    public get name() {
        return this.userData.fullName;
    }

    public get personId() {
        return this.userData.personId;
    }

    public get divisionId() {
        return this.userData.divisionId;
    }

    public get divisionName() {
        return this.userData.divisionName;
    }

    public get preferences() {
        return this.userData.preferences;
    }

    public get preferredLengthUnits() {
        return this.userData.preferredLengthUnits;
    }

    public get preferredVolumeUnits() {
        return this.userData.preferredVolumeUnits;
    }

    public get preferredWeightUnits() {
        return this.userData.preferredWeightUnits;
    }

    private set preferences(value: {[key:string]: any}) {
        this.userData.preferences = value;
    }

    public updatePreference(value: {[key: string]: any}) {
        this.preferences = {
            ...this.preferences,
            ...value,
        };
    }

    public get locale() {
        return this.userData.preferredLanguageTag;
    }

    public get enforceCustomsStatus() {
        return this.userData.enforceCustomsStatus;
    }

    public get bookerInitialized() {
        return this.userData.jobInitializeBooker;
    }

    public hasPermission(permission: UserPermissionType) {
        if (Object.keys(UserPermissions.Limitation).includes(permission)) {
            return this.userData.permissions.includes(permission) && !this.userData.permissions.includes(UserPermissions.Admin.SuperAdmin);
        }

        return this.userData.permissions.includes(permission) || this.userData.permissions.includes(UserPermissions.Admin.SuperAdmin);
    }

    public canViewParty(type?: PartyTypeType) {
        switch (type) {
            case PartyType.LegalEntity:
            case PartyType.Division:
                return this.hasPermission(UserPermissions.Parties.TenantParties.View);
            case PartyType.Client:
            case PartyType.Lead:
                return this.hasPermission(UserPermissions.Parties.ClientParties.View);
            case PartyType.Dealer:
                return this.hasPermission(UserPermissions.Parties.DealerParties.View);
            case PartyType.Vendor:
                return this.hasPermission(UserPermissions.Parties.VendorParties.View);
            default:
                return false;
        }
    }

    public canEditParty(type?: PartyTypeType) {
        switch (type) {
            case PartyType.LegalEntity:
            case PartyType.Division:
                return this.hasPermission(UserPermissions.Parties.TenantParties.Edit);
            case PartyType.Client:
            case PartyType.Lead:
                return this.hasPermission(UserPermissions.Parties.ClientParties.Edit);
            case PartyType.Dealer:
                return this.hasPermission(UserPermissions.Parties.DealerParties.Edit);
            case PartyType.Vendor:
                return this.hasPermission(UserPermissions.Parties.VendorParties.Edit);
            default:
                return false;
        }
    }

    public clone() {
        return new AMWUserData({...this.userData});
    }
}