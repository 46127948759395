import React from 'react';
import AppointmentsGridFilters from '~enums/gridFilters/crmAppointments';
import AppointmentStatus from '~enums/appointmentStatus';
import { FilterHandler } from '~models/filters';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import FilterContainer from '~components/filtering/FilterContainer';
import FilterItem from '~components/filtering/FilterItem';
import { FilterPanel, FilterPanelProps } from '~components/filtering/FilterPanel';
import OnDemandMultiSelect from '~controls/OnDemandMultiSelect';
import CheckboxWrapper from '~controls/CheckboxWrapper';
import MultiSelectWrapper from '~controls/MultiSelectWrapper';
import DatePickerWrapper from '~components/controls/DatePickerWrapper';
import LocalizationKeys from '~enums/localizationKeys';
import TextBoxWrapper from '~components/controls/TextBoxWrapper';


type AppointmentsFilterPanelProps = FilterPanelProps;

export default class AppointmentsFilterPanel extends FilterPanel<AppointmentsFilterPanelProps> {

    public render() {
        const localization = provideLocalizationService(this);

        return (
            <FilterContainer
                applyClassName={'Button--orange'}
                clearClassName={'Button--gray'}
                filterApply={this.props.filterApply}
                filterClear={this.props.filterClear}
            >
                <FilterItem>
                    <TextBoxWrapper
                        id="description"
                        name={AppointmentsGridFilters.Description}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Filters.Description, "%Description")}
                        value={FilterHandler.getValue(this.props.filters, AppointmentsGridFilters.Description)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandMultiSelect
                        url="/v1/persons" // TODO filter by permissions
                        // urlParams={{partyTypes: [PartyType.Division]}}
                        dataItemKey={'personId'}
                        textField={'fullName'}
                        id="ownedBy"
                        name={AppointmentsGridFilters.OwnedBy}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Filters.OwnedBy, "%Owned by")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, AppointmentsGridFilters.OwnedBy, 'personId', 'fullName')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <MultiSelectWrapper
                        id="status"
                        name={AppointmentsGridFilters.Status}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Filters.Status, "%Status")}
                        data={Object.values(AppointmentStatus).map(x => { return {
                            id: x,
                            text: localization.toLanguageString(x, x)
                        }})}
                        dataItemKey={'id'}
                        textField={'text'}
                        value={FilterHandler.getMultiselectValue(this.props.filters, AppointmentsGridFilters.Status, 'id', 'text')}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="dateFrom"
                        name={AppointmentsGridFilters.DateFrom}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Filters.DateFrom, "%From")}
                        placeholder={""}
                        value={FilterHandler.getDateValue(this.props.filters, AppointmentsGridFilters.DateFrom)}
                        onChange={this.onDateChange.bind(this)}
                        max={FilterHandler.getDateValue(this.props.filters, AppointmentsGridFilters.DateTo) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="dateTo"
                        name={AppointmentsGridFilters.DateTo}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Filters.DateTo, "%To")}
                        placeholder={""}
                        value={FilterHandler.getDateValue(this.props.filters, AppointmentsGridFilters.DateTo)}
                        onChange={this.onDateChange.bind(this)}
                        min={FilterHandler.getDateValue(this.props.filters, AppointmentsGridFilters.DateFrom) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <CheckboxWrapper
                        id="startDate"
                        name={AppointmentsGridFilters.StartDate}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Filters.StartDate, "%Start date")}
                        value={FilterHandler.getValue(this.props.filters, AppointmentsGridFilters.StartDate)}
                        onChange={this.onCheckChange.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <CheckboxWrapper
                        id="dueDate"
                        name={AppointmentsGridFilters.EndDate}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Filters.EndDate, "%End date")}
                        value={FilterHandler.getValue(this.props.filters, AppointmentsGridFilters.EndDate)}
                        onChange={this.onCheckChange.bind(this)}
                    />
                </FilterItem>
            </FilterContainer>
        );
    }
}
registerForLocalization(AppointmentsFilterPanel as React.ComponentClass<any>);