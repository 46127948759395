import { Action, Reducer } from 'redux';
import { DataRequest } from '~models/dataRequests';
import ResourceItem from '~models/resource/resourceItem';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { AppThunkAction } from './ApplicationState';


export interface ResourcesState {
    isLoading: boolean;
    resources: Array<ResourceItem>;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface RequestResourcesAction {
    type: 'REQUEST_RESOURCES';
}

interface ReceiveResourcesAction {
    type: 'RECEIVE_RESOURCES';
    resources: ResourceItem[];
}

type KnownAction = RequestResourcesAction | ReceiveResourcesAction;

export const actionCreators = {
    requestResources: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.resources && !appState.resources.isLoading) {
            apiClientInstance.fetchRequest<DataRequest<ResourceItem>>('/v1/resources')
                .then(data => {
                    dispatch({ type: 'RECEIVE_RESOURCES', resources: data.data });
                });

            dispatch({ type: 'REQUEST_RESOURCES' });
        }
    },
};

// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ResourcesState = { resources: [], isLoading: false };

export const reducer: Reducer<ResourcesState> = (state: ResourcesState | undefined, incomingAction: Action): ResourcesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_RESOURCES':
            return {
                resources: state.resources,
                isLoading: true,
            };
        case 'RECEIVE_RESOURCES':
            return {
                resources: action.resources,
                isLoading: false,
            };
    }

    return state;
};