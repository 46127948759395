import React from 'react';
import TransactionsGridFilters from '~enums/gridFilters/transactionsFilters';
import LocalizationKeys from '~enums/localizationKeys';
import { FilterHandler } from '~models/filters';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import FilterContainer from '~components/filtering/FilterContainer';
import FilterItem from '~components/filtering/FilterItem';
import { FilterPanel, FilterPanelProps } from '~components/filtering/FilterPanel';
import TextBoxWrapper from '~components/controls/TextBoxWrapper';
import DatePickerWrapper from '~components/controls/DatePickerWrapper';


type TransactionsFilterPanelProps = FilterPanelProps;

export default class TransactionsFilterPanel extends FilterPanel<TransactionsFilterPanelProps> {

    public render() {
        const localization = provideLocalizationService(this);

        return (
            <FilterContainer
                applyClassName={'Button--orange'}
                clearClassName={'Button--gray'}
                filterApply={this.props.filterApply}
                filterClear={this.props.filterClear}
            >
                <FilterItem>
                    <TextBoxWrapper
                        id="trnInternalId"
                        name={TransactionsGridFilters.InternalId}
                        label={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Filters.InternalId, "#Internal ID")}
                        value={FilterHandler.getValue(this.props.filters, TransactionsGridFilters.InternalId)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="trnItem"
                        name={TransactionsGridFilters.Item}
                        label={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Filters.Item, "#Item")}
                        value={FilterHandler.getValue(this.props.filters, TransactionsGridFilters.Item)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="trnShipper"
                        name={TransactionsGridFilters.Shipper}
                        label={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Filters.Shipper, "#Shipper")}
                        value={FilterHandler.getValue(this.props.filters, TransactionsGridFilters.Shipper)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="trnConsignee"
                        name={TransactionsGridFilters.Consignee}
                        label={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Filters.Consignee, "#Consignee")}
                        value={FilterHandler.getValue(this.props.filters, TransactionsGridFilters.Consignee)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="trnStartDate"
                        name={TransactionsGridFilters.StartDate}
                        label={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Filters.StartDate, "%Start date")}
                        value={FilterHandler.getDateValue(this.props.filters, TransactionsGridFilters.StartDate)}
                        onChange={this.onDateChange.bind(this)}
                        max={FilterHandler.getDateValue(this.props.filters, TransactionsGridFilters.EndDate) ?? undefined}
                        disabled={this.props.disabled}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="trnEndDate"
                        name={TransactionsGridFilters.EndDate}
                        label={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Filters.EndDate, "%End date")}
                        value={FilterHandler.getDateValue(this.props.filters, TransactionsGridFilters.EndDate)}
                        onChange={this.onDateChange.bind(this)}
                        min={FilterHandler.getDateValue(this.props.filters, TransactionsGridFilters.StartDate) ?? undefined}
                        disabled={this.props.disabled}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="trnTrackingNumber"
                        name={TransactionsGridFilters.TrackingNumber}
                        label={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Filters.TrackingNumber, "#Client ref")}
                        value={FilterHandler.getValue(this.props.filters, TransactionsGridFilters.TrackingNumber)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="trnTrip"
                        name={TransactionsGridFilters.Trip}
                        label={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Filters.Trip, "#Trip")}
                        value={FilterHandler.getValue(this.props.filters, TransactionsGridFilters.Trip)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="trnProject"
                        name={TransactionsGridFilters.Project}
                        label={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Filters.Project, "#Project")}
                        value={FilterHandler.getValue(this.props.filters, TransactionsGridFilters.Project)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>
            </FilterContainer>
        );
    }
}
registerForLocalization(TransactionsFilterPanel as React.ComponentClass<any>);