const CRMOpportunitiesGridFields = {
    OpportunityId: "opportunityId",
    UniqueId: "uniqueId",
    LeadName: "leadName",
    OwnedByName: "ownedByName",
    Description: "description",
    ExpireOn: "expireOn",
    Stage: "stage",
    Status: "status",
    ForecastTotal: "forecastTotal",
    Certainty: "certainty",
    Priority: "priority",
    CreatedOn: "createdOn",
} as const;

export type CRMOpportunitiesGridFieldsType = typeof CRMOpportunitiesGridFields[keyof typeof CRMOpportunitiesGridFields];

export default CRMOpportunitiesGridFields;