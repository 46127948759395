import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '~store/ApplicationState';
import * as ReportsStore from '~store/infra/reports';
import { ReportContextType } from '~enums/reportContext';
import { provideLocalizationService, registerForLocalization, } from '@progress/kendo-react-intl';
import DropDownButton from './DropDownButton';
import ReportButtonItem from '~models/report/reportButtonItem';
import LocalizationKeys from '~enums/localizationKeys';


type ReportButtonOwnProps = {
    reportContext: ReportContextType;
    disabled?: boolean;
};
type ReportButtonProps = ReportButtonOwnProps
    & ReportsStore.ReportsState
    & typeof ReportsStore.actionCreators;

class ReportButton extends React.Component<ReportButtonProps> {

    render() {
        const localization = provideLocalizationService(this);

        return (
            <DropDownButton<ReportButtonItem>
                label={localization.toLanguageString(LocalizationKeys.Report.Button.Generate, '%Generate')}
                icon={'template-manager'}
                idRetriever={(item) => {return item.reportConfigId;}}
                labelRetriever={(item) => {return item.reportName;}}
                groupRetriever={(item) => {return item.groupName;}}
                isLoading={this.props.isLoading}
                onSelect={(report) => {alert('not implemented');}}
                options={this.props.reports}
                onOpen={() => {this.props.openWindow(this.props.reportContext);}}
                disabled={this.props.disabled}
            />
        );
    }
}

registerForLocalization(ReportButton as React.ComponentClass<any>);

export default connect(
    (state: ApplicationState) => {
        return {
            ...state.reportMenu,
        }
    },
    {
        ...ReportsStore.actionCreators
    },
    (stateProps, dispatchProps, ownProps: ReportButtonOwnProps) => {
        return {
            ...stateProps,
            ...dispatchProps,
            ...ownProps,
        };
    }
)(ReportButton as any);