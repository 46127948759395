import React from 'react';
import { DropDownOption, OptionedDropDownProps, OptionedDropDownState } from './types';

import { IconButton, LinearProgress, Menu, MenuItem } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button } from '@progress/kendo-react-buttons';
import {
    DropDownList,
} from '@progress/kendo-react-dropdowns';

import DropDownListWrapper from './DropDownListWrapper';

import '~css/components/controls/OptionedDropDown.scss';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import LocalizationKeys from '~enums/localizationKeys';



export class OptionedDropDown extends React.Component<OptionedDropDownProps, OptionedDropDownState> {
    __drop?: DropDownList | null;

    constructor(props: OptionedDropDownProps) {
        super(props);
        this.state = {
            menuAnchor: null
        };
    }

    private openMenu(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({
            menuAnchor: event.currentTarget,
        });
    }

    private closeMenu() {
        this.setState({
            menuAnchor: null
        });
    }

    private onOptionSelect(option: DropDownOption) {
        option.onSelect();
        this.setState({
            menuAnchor: null
        })
    }

    private clearValue() {
        !!this.props.onClearClick && this.props.onClearClick(this.props.name);
    }

    render() {
        const localization = provideLocalizationService(this);

        const nonHiddenOptions = this.props.options?.filter(x => !x.hidden) ?? [];
        const hiddenOptions = this.props.options?.filter(x => x.hidden) ?? [];
        !!this.props.clearable && !this.props.clearAdorned && hiddenOptions.splice(0, 0, {
            hidden: true,
            text: localization.toLanguageString(LocalizationKeys.Shared.Buttons.ClearInput, '%Clear'),
            onSelect: this.clearValue.bind(this),
            disabled: !this.props.value,
        } as DropDownOption);

        const buttonCount = nonHiddenOptions.length + (hiddenOptions.length > 0 ? 1 : 0);

        return (
            <div className={`OptionedDropDown OptionedDropDown__root
                ${!!this.props.clearable && this.props.clearAdorned ? `OptionedDropDown--clearable ` : ''}
                ${buttonCount > 0 ? `OptionedDropDown--${buttonCount}-options ` : ''}
                ${this.props.disabled ? 'OptionedDropDown--disabled ' : ''}
                ${!!this.props.label ? `OptionedDropDown--labeled ` : ''}
                ${this.props.className || ' '}`}
            >
                <DropDownListWrapper
                    ref={(drop) => {this.__drop = drop?.__drop;}}
                    {...this.props}
                    className={`OptionedDropDown__drop`}
                    inProgress={false}
                />

                {!!this.props.inProgress && (
                    <LinearProgress className={`OptionedDropDown__Progress`} />
                )}

                {!!this.props.clearable && this.props.clearAdorned && !!this.props.value && !!this.props.value[this.props.dataItemKey ?? ''] && (
                    <IconButton
                        className={"OptionedDropDown__adornment"}
                        onClick={this.clearValue.bind(this)}
                    >
                        <CloseIcon/>
                    </IconButton>
                )}

                {nonHiddenOptions.map(x => (
                    <Button
                        key={this.props.options?.indexOf(x)}
                        icon={x.icon}
                        disabled={x.disabled}
                        title={x.text}
                        className={"OptionedDropDown__option"}
                        onClick={x.onSelect}
                    />
                ))}

                {hiddenOptions.length > 0 && (
                    <React.Fragment>
                        <Button
                            icon={'more-vertical'}
                            className={"OptionedDropDown__option"}
                            onClick={this.openMenu.bind(this)}
                            disabled={hiddenOptions.every(x => x.disabled)}
                        />
                        <Menu
                            open={!!this.state.menuAnchor}
                            anchorEl={this.state.menuAnchor}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            onClose={this.closeMenu.bind(this)}
                        >
                            {hiddenOptions.map(opt => { return (
                                <MenuItem
                                    key={opt.text}
                                    onClick={() => {this.onOptionSelect.apply(this, [opt]);}}
                                    className={`OptionedDropDown__ListItem ${!!opt.className ? opt.className : ''}`}
                                    disabled={opt.disabled}
                                >
                                    {opt.text}
                                </MenuItem>
                            )})}
                        </Menu>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

registerForLocalization(OptionedDropDown);