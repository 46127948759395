import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../../ApplicationState';
import equal from 'fast-deep-equal/react';
import { FilterHandler, FilterList } from '../../../models/filters';
import { apiClientInstance } from '../../../services/auth/ApiClientInstance';
import SchedulingEventItem from '~models/calendarEvent/schedulingEventItem';
import transactionTypes from '~enums/transactionType';
import { SchedulerModelFields } from '@progress/kendo-react-scheduler';

export interface SchedulingState {
    isLoading: boolean;
    events: Array<SchedulingEventItem>;
    filters: FilterList;
}

export const eventFieldMap: SchedulerModelFields = {
    id: "calendarEventId",
    title: "transactionType",
    description: "officeNotes",
    start: "startDate",
    end: "endDate",
};

// -----------------
// ---- ACTIONS ----
// -----------------

interface RequestEventsAction {
    type: 'REQUEST_EVENTS';
    filters: FilterList;
}

interface ReceiveEventsAction {
    type: 'RECEIVE_EVENTS';
    filters: FilterList;
    events: SchedulingEventItem[];
}

type KnownAction = RequestEventsAction | ReceiveEventsAction;

export const buildEventsUrl = (filters: FilterList): string => {
    let url = '/v1/scheduling/events';

    let filterValues = FilterHandler.parseFilterValues(filters);
    let params = new URLSearchParams(filterValues);
    if (Array.from(params).length > 0) url += `?${params}`;

    return url;
};

export const actionCreators = {
    requestEvents: (filters: FilterList): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.scheduling && (!appState.scheduling.isLoading || !equal(filters, appState.scheduling.filters))) {
            let url = buildEventsUrl(filters);

            apiClientInstance.fetchRequest<SchedulingEventItem[]>(url, 'GET')
                .then(data => {
                    data.forEach(ev => {
                        ev.startDate = new Date(ev.startDate);
                        ev.endDate = new Date(ev.endDate);
                    })
                    dispatch({ type: 'RECEIVE_EVENTS', events: data, filters: filters });
                })
                .catch(error => {
                    //TODO do something
                    dispatch({ type: 'RECEIVE_EVENTS', events: appState.scheduling?.events ?? [], filters: filters });
                });

            dispatch({ type: 'REQUEST_EVENTS', filters: filters });
        }
    }
};

// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: SchedulingState =
{
    events: [],
    isLoading: false,
    filters:
    {
        Date: { value: (new Date()).toISOString() },
        ViewMode: { value: 'month' },
        Activity: { value: transactionTypes.Collection },
        ResourceIds: { value: [] }
    }
};

export const reducer: Reducer<SchedulingState> = (state: SchedulingState | undefined, incomingAction: Action): SchedulingState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_EVENTS':
            return {
                filters: action.filters,
                events: state.events,
                isLoading: true
            };
        case 'RECEIVE_EVENTS':
            if(equal(state.filters, action.filters)) {
                console.log(action.events);
                return {
                    isLoading: false,
                    events: action.events,
                    filters: action.filters,
                };
            }
            break;
    }

    return state;
};