const ResourceFields = {
    ResourceName: "ResourceField.ResourceName",
    HourCost: "ResourceField.HourCost",
    ResourceType: "ResourceField.ResourceType",
    Crew: "ResourceField.Crew",
    HomeDivision: "ResourceField.HomeDivision",
    Depth: "ResourceField.Depth",
    Width: "ResourceField.Width",
    Height: "ResourceField.Height",
    Volume: "ResourceField.Volume",
    Tonnage: "ResourceField.Tonnage",
    MOTDate: "ResourceField.MOTDate",
    TaxDate: "ResourceField.TaxDate",
    UsedFor: "ResourceField.UsedFor",
    Divisions: "ResourceField.Divisions",
    Qualifications: "ResourceField.Qualifications",
} as const;

export type ResourceFieldsType = typeof ResourceFields[keyof typeof ResourceFields];

export default ResourceFields;