import { FilterList } from "~models/filters";
import { EditableField, EditableGridColumnProps } from "./types";

export const updateFilters = (filters: {[key: string]: any}, prevFilters: FilterList) => {
    let newFilters = typeof prevFilters === 'object'
        ? {...prevFilters} : {};

    Object.keys(filters).forEach((key) => {
        if (!!filters[key] && !!filters[key].value) {
            newFilters[key] = filters[key];
        } else {
            if (!!newFilters[key]) {
                delete newFilters[key];
            }
        }
    });

    return newFilters;
};

export function parseEditableFields<T>(columns: Array<EditableGridColumnProps<T>>) {
    return columns
        .filter(x => x.editable)
        .sort((a,b) => {
            return (a.orderIndex ?? columns.indexOf(a)) -
                (b.orderIndex ?? columns.indexOf(b));
        })
        .map(x => {
            return {
                field: x.field ?? '',
                mandatory: x.mandatory,
                validationRule: x.validationRule,
                valueChangeParser: x.valueChangeParser,
                ignoreValidityOnChange: x.ignoreValidityOnChange,
            } as EditableField<T>;
        });
};