const UserPermissions = Object.freeze({
    Admin: {
        SuperAdmin: "Permission.SuperAdmin",
    },
    Limitation: {
        Tenant: "Permission.Limitation.Tenant",
        LegalEntity: "Permission.Limitation.LegalEntity",
        Division: "Permission.Limitation.Division",
    },
    ClientFile: {
        JobControlView: "Permission.ClientFile.JobControlView",
        TimesheetsView: "Permission.ClientFile.TimesheetsView",
        ViewOwn: "Permission.ClientFile.ViewOwn",
        ViewAll: "Permission.ClientFile.ViewAll",
        EditOwn: "Permission.ClientFile.EditOwn",
        EditAll: "Permission.ClientFile.EditAll",
        Create: "Permission.ClientFile.Create",
        Delete: "Permission.ClientFile.Delete",
        Reopen: "Permission.ClientFile.Reopen",
        Reassign: "Permission.ClientFile.Reassign",
    },
    Crates: {
        CratesView: "Permission.Crates.CratesView",
    },
    CRM: {
        Opportunities: {
            View: "Permission.CRM.Opportunities.View",
            CompanyView: "Permission.CRM.Opportunities.CompanyView",
        },
        Companies: {
            View: "Permission.CRM.Companies.View",
        },
        Tasks: {
            View: "Permission.CRM.Tasks.View",
            CompanyView: "Permission.CRM.Tasks.CompanyView",
        },
        Appointments: {
            View: "Permission.CRM.Appointments.View",
            CompanyView: "Permission.CRM.Appointments.CompanyView",
        },
        Calendar: {
            View: "Permission.CRM.Calendar.View",
        },
        Campaigns: {
            View: "Permission.CRM.Campaigns.View",
        },
        Recent: {
            View: "Permission.CRM.Recent.View",
        },
    },
    Financials: {
        FinancialsView: "Permission.Financials.FinancialsView",
        InvoicesClientView: "Permission.Financials.InvoicesClientView",
        ExpensesClientView: "Permission.Financials.ExpensesClientView",
    },
    Inventory: {
        InventoryView: "Permission.Inventory.InventoryView",
        InventoryClientView: "Permission.Inventory.InventoryClientView",
    },
    ItemDetails: {
        ItemDetailsView: "Permission.ItemDetails.View",
        ItemDetailsEdit: "Permission.ItemDetails.Edit",
        ItemDetailsSplit: "Permission.ItemDetails.Split",
        ItemDetailsAddPackages: "Permission.ItemDetails.AddPackages",
        ItemDetailsRemoveTransfer: "Permission.ItemDetails.RemoveTrasfer",
        ItemDetailsCustomsDocumentsEdit: "Permission.ItemDetails.ItemDetailsCustomsDocumentsEdit",
        ItemDetailsRegistarNotesEdit: "Permission.ItemDetails.ItemDetailsRegistarNotesEdit",
        ItemDetailsUniqueIdEdit: "Permission.ItemDetails.ItemDetailsUniqueIdEdit",
        ItemDetailsCalendarEdit: "Permission.ItemDetails.ItemDetailsCalendarEdit",
    },
    Management: {
        ManagementView: "Permission.Management.ManagementView",
    },
    Operations: {
        OperationsView: "Permission.Operations.OperationsView",
        TransactionsClientView: "Permission.Operations.TransactionsClientView",
    },
    Parties: {
        TenantParties: {
            View: "Permission.Parties.TenantPartiesView",
            Edit: "Permission.Parties.TenantPartiesEdit",
            Create: "Permission.Parties.TenantPartiesCreate",
        },
        ClientParties: {
            View: "Permission.Parties.ClientPartiesView",
            Edit: "Permission.Parties.ClientPartiesEdit",
            Create: "Permission.Parties.ClientPartiesCreate",
        },
        DealerParties: {
            View: "Permission.Parties.DealerPartiesView",
            Edit: "Permission.Parties.DealerPartiesEdit",
            Create: "Permission.Parties.DealerPartiesCreate",
        },
        VendorParties: {
            View: "Permission.Parties.VendorPartiesView",
            Edit: "Permission.Parties.VendorPartiesEdit",
            Create: "Permission.Parties.VendorPartiesCreate",
        },
    },
    POItems: {
        POItemsView: "Permission.POItems.POItemsView",
    },
    PurchaseOrder: {
        PurchaseOrderView: "Permission.PurchaseOrder.View",
        PurchaseOrderEdit: "Permission.PurchaseOrder.Edit",
        PurchaseOrderEditCompleted: "Permission.PurchaseOrder.EditCompleted",
        PurchaseOrderEditUniqueId: "Permission.PurchaseOrder.EditUniqueId",
        PurchaseOrderEditReassign: "Permission.PurchaseOrder.Reassign",
        PurchaseOrderEditCreate: "Permission.PurchaseOrder.Create",
        PurchaseOrderEditDelete: "Permission.PurchaseOrder.Delete",
    },
    Quotations: {
        QuotationsView: "Permission.Quotations.QuotationsView",
        QuotationsClientView: "Permission.Quotations.QuotationsClientView",
        QuotationsCompanyView: "Permission.Quotations.QuotationsCompanyView",
        ProjectsView: "Permission.Quotations.ProjectsView",
        ProjectsClientView: "Permission.Quotations.ProjectsClientView",
    },
    Shipping: {
        ShippingView: "Permission.Shipping.ShippingView",
        ShippingClientView: "Permission.Shipping.ShippingClientView",
    },
    Surveys: {
        SurveysView: "Permission.Surveys.SurveysView",
        SurveysClientView: "Permission.Surveys.SurveysClientView",
    },
    Tasks: {
        TasksView: "Permission.Tasks.TasksView",
        TasksClientView: "Permission.Tasks.TasksClientView",
    },
    Trips: {
        TripsView: "Permission.Trips.TripsView",
    },
    Warehouses: {
        WarehousesView: "Permission.Warehouses.WarehousesView",
    },
});

export type UserPermissionType =
    typeof UserPermissions.Admin[keyof typeof UserPermissions.Admin] &
    typeof UserPermissions.Limitation[keyof typeof UserPermissions.Limitation] &
    typeof UserPermissions.ClientFile[keyof typeof UserPermissions.ClientFile] &
    typeof UserPermissions.Shipping[keyof typeof UserPermissions.Shipping] &
    typeof UserPermissions.Financials[keyof typeof UserPermissions.Financials] &
    typeof UserPermissions.Inventory[keyof typeof UserPermissions.Inventory] &
    typeof UserPermissions.Operations[keyof typeof UserPermissions.Operations] &
    typeof UserPermissions.Trips[keyof typeof UserPermissions.Trips] &
    typeof UserPermissions.Surveys[keyof typeof UserPermissions.Surveys] &
    typeof UserPermissions.Quotations[keyof typeof UserPermissions.Quotations] &
    typeof UserPermissions.CRM.Appointments[keyof typeof UserPermissions.CRM.Appointments] &
    typeof UserPermissions.CRM.Calendar[keyof typeof UserPermissions.CRM.Calendar] &
    typeof UserPermissions.CRM.Campaigns[keyof typeof UserPermissions.CRM.Campaigns] &
    typeof UserPermissions.CRM.Companies[keyof typeof UserPermissions.CRM.Companies] &
    typeof UserPermissions.CRM.Opportunities[keyof typeof UserPermissions.CRM.Opportunities] &
    typeof UserPermissions.CRM.Recent[keyof typeof UserPermissions.CRM.Recent] &
    typeof UserPermissions.CRM.Tasks[keyof typeof UserPermissions.CRM.Tasks] &
    typeof UserPermissions.Tasks[keyof typeof UserPermissions.Tasks] &
    typeof UserPermissions.Warehouses[keyof typeof UserPermissions.Warehouses] &
    typeof UserPermissions.Crates[keyof typeof UserPermissions.Crates] &
    typeof UserPermissions.Management[keyof typeof UserPermissions.Management];

export {
    UserPermissions,
}