const CRMAppointmentsGridFilters = {
    Description: "Description",
    Status: "Status",
    OwnedBy: "OwnedBy",
    DateFrom: "DateFrom",
    DateTo: "DateTo",
    StartDate: "StartDate",
    EndDate: "EndDate",
} as const;

export type CRMAppointmentsGridFiltersType = typeof CRMAppointmentsGridFilters[keyof typeof CRMAppointmentsGridFilters];

export default CRMAppointmentsGridFilters;