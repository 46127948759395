import * as React from 'react';

const initSize = { width: 0, height: 0 };
const DimensionsWrapper = (ref: React.MutableRefObject<null>, onResize: (width: number, height: number) => any) => {
    const [dimensions, setDimensions] = React.useState(initSize);
    const resizeObserverRef = React.useRef<any>(null);

    React.useEffect(() => {
        resizeObserverRef.current = new ResizeObserver((entries = []) => {
            entries.forEach((entry) => {
                const { width, height } = entry.contentRect;
                setDimensions({ width, height });
                onResize(width, height);
            });
        });

        if (ref.current) resizeObserverRef.current.observe(ref.current);
        return () => {
            if (resizeObserverRef.current) resizeObserverRef.current.disconnect();
        };
    }, [ref, onResize]);

    return dimensions;
};

export default DimensionsWrapper;