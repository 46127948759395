const InventoryItemsGridFields = {
    UniqueId: "uniqueId",
    Description: "description",
    Depth: "depth",
    Width: "width",
    Height: "height",
    PackageDepth: "packageDepth",
    PackageWidth: "packageWidth",
    PackageHeight: "packageHeight",
    SideMark: "sideMark",
    Lot: "lot",
    WarehousePosition: "warehousePosition",
    PONumber: "pONumber",
    DealerLegalName: "dealerLegalName",
    Artist: "artist",
    Title: "title",
    JobId: "jobId",
    CustomsStatus: "customsStatus",
    PackageUniqueId: "packageUniqueId",
    Value: "value",
    LocationUniqueId: "locationUniqueId",
    CollectionDate: "collectionDate",
    DeliveryDate: "deliveryDate",
    HasPhotos: "hasPhotos",
    Parent: "parent",
} as const;

export type InventoryItemsGridFieldsType = typeof InventoryItemsGridFields[keyof typeof InventoryItemsGridFields];

export default InventoryItemsGridFields;