const TaskPriority = Object.freeze({
    //TODO swap when entities are updated
    Low: "Low",
    Medium: "Medium",
    High: "High",
    //Low: "Enum.TaskPriority.Low",
    //Medium: "Enum.TaskPriority.Medium",
    //High: "Enum.TaskPriority.High",
});

export type TaskPriorityType = typeof TaskPriority[keyof typeof TaskPriority];

export default TaskPriority;