import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';

import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { ShowErrorAction } from '~store/infra/errors';

import QuotationConfigGridItem from '~models/quotationConfig/quotationConfigGridItem';
import QuotationConfigDetails from '~models/quotationConfig/quotationConfigDetails';
import QuoteConfigFields, { QuoteConfigFieldsType } from '~enums/fields/quoteConfig';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';
import { ServerOperationStatus } from '~enums/serverOperationStatus';


export interface ManagementQuoteState {
    isLoading: boolean;
    configs: Array<QuotationConfigGridItem>;
    details: QuotationConfigDetails;
    updatedField?: QuoteConfigFieldsType;
    updateStatus: ServerOperationStatus;
}

const EmptyData: QuotationConfigDetails = {
    quotationConfigId: 0,
    serviceType: '',
    serviceCode: '',
    serviceDescription: '',
    units: '',
    rate: 0,
    qty: 0,
    billingBase: 0,
    billingMinimum: 0,
    sequenceNr: 0,
    sourceValue: '',
    sourceValueIterateBy: '',
    sourceRate: '',
    sourceRateIterateBy: '',
};

// -----------------
// ---- ACTIONS ----
// -----------------

interface QuoteRequestAction {
    type: 'MANAGEMENT_QUOTE_REQUEST';
}
interface QuoteReceiveAction {
    type: 'MANAGEMENT_QUOTE_RECEIVE';
    configs: Array<QuotationConfigGridItem>;
}
interface QuoteReceiveErrorAction {
    type: 'MANAGEMENT_QUOTE_RECEIVE_ERROR';
}

interface QuoteDetailsRequestAction {
    type: 'MANAGEMENT_QUOTE_DETAILS_REQUEST';
}
interface QuoteDetailsReceiveAction {
    type: 'MANAGEMENT_QUOTE_DETAILS_RECEIVE';
    data: QuotationConfigDetails;
}
interface QuoteDetailsErrorAction {
    type: 'MANAGEMENT_QUOTE_DETAILS_ERROR';
}

interface StartUpdateDataAction {
    type: 'MANAGEMENT_QUOTE_DETAILS_START_UPDATE_DATA';
    field: QuoteConfigFieldsType;
}
interface UpdateDataAction {
    type: 'MANAGEMENT_QUOTE_DETAILS_UPDATE_DATA';
    data: QuotationConfigDetails;
}
interface UpdateDataErrorAction {
    type: 'MANAGEMENT_QUOTE_DETAILS_UPDATE_DATA_ERROR';
}

type KnownAction = QuoteRequestAction | QuoteReceiveAction | QuoteReceiveErrorAction
    | QuoteDetailsRequestAction | QuoteDetailsReceiveAction | QuoteDetailsErrorAction
    | StartUpdateDataAction | UpdateDataAction | UpdateDataErrorAction
    | ShowErrorAction;

export const actionCreators = {
    requestConfigs: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementQuote && !appState.managementQuote.isLoading) {
            apiClientInstance.fetchRequest<Array<QuotationConfigGridItem>>('/v1/quotation-configs')
                .then(data => {
                    dispatch({ type: 'MANAGEMENT_QUOTE_RECEIVE', configs: data });
                })
                .catch(err => {
                    dispatch({ type: 'MANAGEMENT_QUOTE_RECEIVE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_QUOTE_REQUEST' });
        }
    },
    requestDetails: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementQuote && !appState.managementQuote.isLoading) {
            apiClientInstance.fetchRequest<QuotationConfigDetails>(`/v1/quotation-configs/${id}`)
                .then(data => {
                    dispatch({ type: 'MANAGEMENT_QUOTE_DETAILS_RECEIVE', data: data });
                })
                .catch(err => {
                    dispatch({ type: 'MANAGEMENT_QUOTE_DETAILS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_QUOTE_DETAILS_REQUEST' });
        }
    },
    updateField: (serverField: QuoteConfigFieldsType, value: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementQuote) {
            apiClientInstance.fetchRequest(
                `v1/quotation-configs/${appState.managementQuote.details.quotationConfigId}`,
                'PATCH',
                { field: serverField, value: value }
            )
                .then((data) => {
                    let infoObj = {...(appState.managementQuote?.details ?? {} as QuotationConfigDetails)};

                    switch (serverField) {
                        case QuoteConfigFields.ServiceType:
                            infoObj.serviceType = (data as FieldUpdateResult<string>).value;
                            break;
                        case QuoteConfigFields.ServiceCode:
                            infoObj.serviceCode = (data as FieldUpdateResult<string>).value;
                            break;
                        case QuoteConfigFields.ServiceDescription:
                            infoObj.serviceDescription = (data as FieldUpdateResult<string>).value;
                            break;
                        case QuoteConfigFields.Units:
                            infoObj.units = (data as FieldUpdateResult<string>).value;
                            break;
                        case QuoteConfigFields.Rate:
                            infoObj.rate = (data as FieldUpdateResult<number>).value;
                            break;
                        case QuoteConfigFields.Qty:
                            infoObj.qty = (data as FieldUpdateResult<number>).value;
                            break;
                        case QuoteConfigFields.BillingBase:
                            infoObj.billingBase = (data as FieldUpdateResult<number>).value;
                            break;
                        case QuoteConfigFields.BillingMinimum:
                            infoObj.billingMinimum = (data as FieldUpdateResult<number>).value;
                            break;
                        case QuoteConfigFields.SequenceNr:
                            infoObj.sequenceNr = (data as FieldUpdateResult<number>).value;
                            break;
                        case QuoteConfigFields.SourceValue:
                            infoObj.sourceValue = (data as FieldUpdateResult<string>).value;
                            break;
                        case QuoteConfigFields.SourceValueIterateBy:
                            infoObj.sourceValueIterateBy = (data as FieldUpdateResult<string>).value;
                            break;
                        case QuoteConfigFields.SourceRate:
                            infoObj.sourceRate = (data as FieldUpdateResult<string>).value;
                            break;
                        case QuoteConfigFields.SourceRateIterateBy:
                            infoObj.sourceRateIterateBy = (data as FieldUpdateResult<string>).value;
                            break;
                    }

                    dispatch({ type: 'MANAGEMENT_QUOTE_DETAILS_UPDATE_DATA', data: infoObj });
                })
                .catch((err) => {
                    dispatch({ type: 'MANAGEMENT_QUOTE_DETAILS_UPDATE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_QUOTE_DETAILS_START_UPDATE_DATA', field: serverField });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementQuoteState = {
    isLoading: false,
    configs: [],
    details: EmptyData,
    updateStatus: ServerOperationStatus.NONE,
};

export const reducer: Reducer<ManagementQuoteState> = (state: ManagementQuoteState | undefined, incomingAction: Action): ManagementQuoteState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_QUOTE_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'MANAGEMENT_QUOTE_RECEIVE':
            return {
                ...state,
                configs: action.configs,
                isLoading: false,
            };
        case 'MANAGEMENT_QUOTE_RECEIVE_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'MANAGEMENT_QUOTE_DETAILS_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'MANAGEMENT_QUOTE_DETAILS_RECEIVE':
            return {
                ...state,
                details: action.data,
                isLoading: false,
            };
        case 'MANAGEMENT_QUOTE_DETAILS_ERROR':
            return {
                ...state,
                isLoading: false,
            };
    }

    return state;
};