import * as React from 'react';
import { Suspense } from 'react';
import Preloader from '../infra/Preloader';

type SuspenseWrapperProps = {
    children?: React.ReactNode;
}

export default class SuspenseWrapper extends React.PureComponent<SuspenseWrapperProps> {
    render() {
        return (
            <Suspense fallback={<Preloader/>}>
                {this.props.children}
            </Suspense>
        );
    }
}