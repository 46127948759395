/**
 * Filter set for Client View Transactions
 */
const TransactionsGridFilters = {
    InternalId: "InternalId",
    Item: "Item",
    Shipper: "Shipper",
    Consignee: "Consignee",
    StartDate: "StartDate",
    EndDate: "EndDate",
    TrackingNumber: "TrackingNumber",
    Trip: "Trip",
    Project: "Project",
} as const;

export type TransactionsGridFiltersType = typeof TransactionsGridFilters[keyof typeof TransactionsGridFilters];

export default TransactionsGridFilters;