const units = Object.freeze({
    Each: "each",
    Hrs: "hrs",
    Piece: "piece",
    Cuft: "cuft",
    Cbm: "cbm",
    Lb: "lbs",
    Kg: "kg",
    Flat: "flat",
    Inch: "inch",
    Cm: "cm",
    // TODO Update when backend and DB is updated
    // Each: "Enum.Units.Each",
    // Hrs: "Enum.Units.Hrs",
    // Piece: "Enum.Units.Piece",
    // Cuft: "Enum.Units.Cuft",
    // Cbm: "Enum.Units.Cbm",
    // Lb: "Enum.Units.Lb",
    // Kg: "Enum.Units.Kg",
    // Flat: "Enum.Units.Flat",
    // Inch: "Enum.Units.Inch",
    // Cm: "Enum.Units.Cm",
});

const volumeUnits = [
    units.Cuft,
    units.Cbm
];

const weightUnits = [
    units.Lb,
    units.Kg
];

const lengthUnits = [
    units.Inch,
    units.Cm
];

const metricUnits = [
    units.Cbm,
    units.Kg,
    units.Cm
];

const imperialUnits = [
    units.Cuft,
    units.Lb,
    units.Inch
];

export {
    units as Units,
    volumeUnits as VolumeUnits,
    weightUnits as WeightUnits,
    lengthUnits as LengthUnits,
    metricUnits as MetricUnits,
    imperialUnits as ImperialUnits
}