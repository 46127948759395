import * as React from 'react';
import { connect } from 'react-redux';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { ApplicationState } from '~store/ApplicationState';

const ErrorMessageDialog = React.lazy(() => import('~components/infra/ErrorMessageDialog'));
const OperationWarningDialog = React.lazy(() => import('~components/infra/OperationWarningDialog'));
const CompanyModal = React.lazy(() => import('~components/modals/Company/CompanyModal'));
const NewPartyDialog = React.lazy(() => import('~components/modals/NewPartyDialog'));
const PartySearchModal = React.lazy(() => import('~components/modals/PartySearch/PartySearchModal'));
const TripSearchModal = React.lazy(() => import('~components/modals/TripSearch/TripSearchModal'));

type AppWideModalsProps = {
    showError: boolean;
    showOperations: boolean;
    showNewParty: boolean;
    showPartySearch: boolean;
    showTripSearch: boolean;
    showCompany: boolean;
}

const AppWideModals : React.FC<AppWideModalsProps> = (props) => {
    return (
        <React.Fragment>
            {props.showError && (<React.Suspense><ErrorMessageDialog /></React.Suspense>)}

            {props.showOperations && (<React.Suspense><OperationWarningDialog /></React.Suspense>)}

            {props.showNewParty && (<React.Suspense><NewPartyDialog /></React.Suspense>)}

            {props.showPartySearch && (<React.Suspense><PartySearchModal /></React.Suspense>)}

            {props.showTripSearch && (<React.Suspense><TripSearchModal /></React.Suspense>)}

            {props.showCompany && (<React.Suspense><CompanyModal /></React.Suspense>)}
        </React.Fragment>
    );
};

export default connect(
    (state: ApplicationState) : AppWideModalsProps => {
        return {
            showError: !!state.errors.errorMessage,
            showOperations: state.errors.isWarningShown,
            showNewParty: state.newParty.status === ServerOperationStatus.READY ||
                state.newParty.status === ServerOperationStatus.INPROGRESS ||
                state.newParty.status === ServerOperationStatus.ERROR,
            showPartySearch: state.partySearch.status !== ServerOperationStatus.NONE,
            showTripSearch: state.tripSearch.isOpen,
            showCompany: !!state.companyModal.companyId,
        };
    },
)(AppWideModals);