import reportContexts from '~enums/reportContext';
import { Grid } from '@mui/material';
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization, } from '@progress/kendo-react-intl';
import ReportButton from '~components/controls/ReportButton';
import { AMWGridFilterToolbarWrapperProps } from '~components/datagrid/types';
import AMWGridFilterToolbar from '~components/datagrid/AMWGridFilterToolbar';
import CampaignsFilterPanel from './CampaignsFilterPanel';
import LocalizationKeys from '~enums/localizationKeys';


type CampaignsToolbarProps = {
    onNewCampaignClick: () => void,
    onCloneCampaignClick: () => void,
} & AMWGridFilterToolbarWrapperProps;

const CampaignsToolbar = (props: CampaignsToolbarProps) => {
    const localization = useLocalization();

    return (
        <AMWGridFilterToolbar {...props} filterPanel={CampaignsFilterPanel}>
            <Grid item className={"ButtonGroup"}>
                <Button
                    fillMode={'flat'}
                    className={"Button--bold Button--orange"}
                    onClick={props.onNewCampaignClick}
                    size={"large"}
                    icon={"plus"}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Grid.Toolbar.NewCampaign, "%New campaign")}
                />

                <Button
                    fillMode={'flat'}
                    className={"Button--bold Button--orange"}
                    onClick={props.onCloneCampaignClick}
                    size={"large"}
                    disabled={!props.selectedItem}
                    icon={"copy"}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.Grid.Toolbar.Clone, "%Clone")}
                />
            </Grid>

            <div className="spacer"></div>

            <Grid item className={"ButtonGroup"}>
                <ReportButton reportContext={reportContexts.CRMCampaignsView} />
            </Grid>
        </AMWGridFilterToolbar>
    );
};

export default CampaignsToolbar;