import React from 'react';
import {
    GridCellProps,
    GRID_COL_INDEX_ATTRIBUTE

} from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';


type CustomCellProps = {
    children?: React.ReactNode;
} & GridCellProps;

const CustomCell : React.FunctionComponent<CustomCellProps> = props => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    return (
        <td
            className={props.className}
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{ [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex }}
            {...navigationAttributes}
        >
            <div className="CustomCell CustomCell__root">
                {props.children}
            </div>
        </td>
    );
}

export default CustomCell;