import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { ShowErrorAction } from '~store/infra/errors';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import { DataRequest } from '~models/dataRequests';
import { SortDescriptor } from "@progress/kendo-data-query";
import { FilterHandler, FilterList } from '~models/filters';

import ItemConfigGridItem from '~models/itemConfig/itemConfigGridItem';
import ManagementItemFields, { ManagementItemFieldsType } from '~enums/fields/managementItem';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';
import ItemConfigCreateModel from '~models/itemConfig/itemConfigCreateModel';


export interface ManagementItemConfigState {
    isLoading: boolean;
    items: Array<ItemConfigGridItem>;
    count: number;
    skip: number;
    updatedField?: ManagementItemFieldsType;
    createStatus: ServerOperationStatus;
    updateStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface ManagementItemRequestAction {
    type: 'MANAGEMENT_ITEMCONFIG_REQUEST';
    skip: number;
}
interface ManagementItemReceiveAction {
    type: 'MANAGEMENT_ITEMCONFIG_RECEIVE';
    items: Array<ItemConfigGridItem>;
    count: number;
}
interface ManagementItemErrorAction {
    type: 'MANAGEMENT_ITEMCONFIG_ERROR';
}

interface ManagementItemCreateSendAction {
    type: 'MANAGEMENT_ITEMCONFIG_CREATE_SEND';
}
interface ManagementItemCreateSuccessAction {
    type: 'MANAGEMENT_ITEMCONFIG_CREATE_SUCCESS';
    newItem: ItemConfigGridItem;
}
interface ManagementItemCreateErrorAction {
    type: 'MANAGEMENT_ITEMCONFIG_CREATE_ERROR';
}

interface ManagementItemUpdateSendAction {
    type: 'MANAGEMENT_ITEMCONFIG_UPDATE_SEND';
    field: ManagementItemFieldsType;
}
interface ManagementItemUpdateSuccessAction {
    type: 'MANAGEMENT_ITEMCONFIG_UPDATE_SUCCESS';
    object: ItemConfigGridItem;
}
interface ManagementItemUpdateErrorAction {
    type: 'MANAGEMENT_ITEMCONFIG_UPDATE_ERROR';
}

interface ManagementItemDeleteStartAction {
    type: 'MANAGEMENT_ITEMCONFIG_DELETE_START';
}
interface ManagementItemDeleteSendAction {
    type: 'MANAGEMENT_ITEMCONFIG_DELETE_SEND';
}
interface ManagementItemDeleteSuccessAction {
    type: 'MANAGEMENT_ITEMCONFIG_DELETE_SUCCESS';
}
interface ManagementItemDeleteCancelAction {
    type: 'MANAGEMENT_ITEMCONFIG_DELETE_CANCEL';
}
interface ManagementItemDeleteErrorAction {
    type: 'MANAGEMENT_ITEMCONFIG_DELETE_ERROR';
}


type KnownAction = ManagementItemRequestAction | ManagementItemReceiveAction | ManagementItemErrorAction
    | ManagementItemCreateSendAction | ManagementItemCreateSuccessAction | ManagementItemCreateErrorAction
    | ManagementItemUpdateSendAction | ManagementItemUpdateSuccessAction | ManagementItemUpdateErrorAction
    | ManagementItemDeleteStartAction | ManagementItemDeleteSendAction
    | ManagementItemDeleteSuccessAction | ManagementItemDeleteCancelAction
    | ManagementItemDeleteErrorAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestItems: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementItems && !appState.managementItems.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<ItemConfigGridItem>>('/v1/item-config/search', 'POST', filters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ManagementItemConfig}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ManagementItemConfig}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'MANAGEMENT_ITEMCONFIG_RECEIVE', items: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'MANAGEMENT_ITEMCONFIG_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_ITEMCONFIG_REQUEST', skip: skip, });
        }
    },
    updateField: (id: number, serverField: ManagementItemFieldsType, value: any, text?: string, onSuccess?: () => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementItems) {
            apiClientInstance.fetchRequest(
                `v1/item-config/${id}`,
                'PATCH',
                { field: serverField, value: value }
            )
                .then((data) => {
                    let item = {...appState.managementItems?.items.find(x => x.itemConfigId === id) ?? {} as ItemConfigGridItem};

                    switch (serverField) {
                        case ManagementItemFields.ItemName:
                            item.itemConfigName = (data as FieldUpdateResult<string>).value;
                            break;
                        case ManagementItemFields.Description:
                            item.itemConfigName = (data as FieldUpdateResult<string>).value;
                            break;
                        case ManagementItemFields.Volume:
                            item.volume = (data as FieldUpdateResult<number>).value;
                            break;
                        case ManagementItemFields.Weight:
                            item.weight = (data as FieldUpdateResult<number>).value;
                            break;
                        case ManagementItemFields.Category:
                            item.category = (data as FieldUpdateResult<string>).value;
                            break;
                        case ManagementItemFields.PackingType:
                            item.packingTypeId = (data as FieldUpdateResult<number | null>).value;
                            break;
                    }

                    !!onSuccess && onSuccess();

                    dispatch({ type: 'MANAGEMENT_ITEMCONFIG_UPDATE_SUCCESS', object: item, });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_ITEMCONFIG_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_ITEMCONFIG_UPDATE_SEND', field: serverField });
        }
    },
    createItem: (model: ItemConfigGridItem, onSuccess?: (result: ItemConfigGridItem) => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExtendedDims) {
            apiClientInstance.fetchRequest<number>(
                `v1/itemConfig/`,
                'POST',
                { ...(model as ItemConfigCreateModel) }
            )
                .then((data) => {
                    let newItem = {...model, itemConfigId: data,} as ItemConfigGridItem;

                    !!onSuccess && onSuccess(newItem);

                    dispatch({ type: 'MANAGEMENT_ITEMCONFIG_CREATE_SUCCESS', newItem: newItem });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_ITEMCONFIG_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_ITEMCONFIG_CREATE_SEND' });
        }
    },
    startDeleteItem: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementItems) {
            dispatch({ type: 'MANAGEMENT_ITEMCONFIG_DELETE_START' });
        }
    },
    submitDeleteItem: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementItems) {
            apiClientInstance.fetchRequest(
                `/v1/item-config/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'MANAGEMENT_ITEMCONFIG_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'MANAGEMENT_ITEMCONFIG_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_ITEMCONFIG_DELETE_SEND' });
        }
    },
    cancelDeleteItem: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementItems) {
            dispatch({ type: 'MANAGEMENT_ITEMCONFIG_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementItemConfigState = {
    isLoading: false,
    items: [],
    count: 0,
    skip: 0,
    createStatus: ServerOperationStatus.NONE,
    updateStatus: ServerOperationStatus.NONE,
    deleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementItemConfigState> = (state: ManagementItemConfigState | undefined, incomingAction: Action): ManagementItemConfigState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_ITEMCONFIG_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'MANAGEMENT_ITEMCONFIG_RECEIVE':
            return {
                ...state,
                items: action.items,
                count: action.count,
                isLoading: false,
            };
        case 'MANAGEMENT_ITEMCONFIG_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'MANAGEMENT_ITEMCONFIG_UPDATE_SEND':
            return {
                ...state,
                updatedField: action.field,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_ITEMCONFIG_UPDATE_SUCCESS':
            {
                let list = [...state.items];
                let index = state.items.findIndex(x => x.itemConfigId === action.object.itemConfigId);

                list[index] = action.object;

                return {
                    ...state,
                    items: list,
                    updatedField: undefined,
                    updateStatus: ServerOperationStatus.SUCCESS,
                };
            };
        case 'MANAGEMENT_ITEMCONFIG_UPDATE_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_ITEMCONFIG_CREATE_SEND':
            return {
                ...state,
                createStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_ITEMCONFIG_CREATE_SUCCESS':
            return {
                ...state,
                createStatus: ServerOperationStatus.SUCCESS,
                items: [...state.items, action.newItem],
            };
        case 'MANAGEMENT_ITEMCONFIG_CREATE_ERROR':
            return {
                ...state,
                createStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_ITEMCONFIG_DELETE_START':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.READY,
            };
        case 'MANAGEMENT_ITEMCONFIG_DELETE_SEND':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_ITEMCONFIG_DELETE_CANCEL':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.NONE,
            };
        case 'MANAGEMENT_ITEMCONFIG_DELETE_SUCCESS':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'MANAGEMENT_ITEMCONFIG_DELETE_ERROR':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};