import React from 'react';
import EnumType from '~enums/enumType';
import CRMCompaniesFilters from '~enums/gridFilters/crmCompaniesFilters';
import { nonSystemPartyTypes } from '~enums/partyType';
import { FilterHandler } from '~models/filters';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';

import FilterContainer from '~components/filtering/FilterContainer';
import FilterItem from '~components/filtering/FilterItem';
import { FilterPanel, FilterPanelProps } from '~components/filtering/FilterPanel';
import OnDemandMultiSelect from '~controls/OnDemandMultiSelect';
import MultiSelectWrapper from '~controls/MultiSelectWrapper';
import DatePickerWrapper from '~components/controls/DatePickerWrapper';
import { defaultStringMapper } from '~src/utils/dropdownUtils';
import LocalizationKeys from '~enums/localizationKeys';
import TextBoxWrapper from '~components/controls/TextBoxWrapper';


type CompaniesFilterPanelProps = FilterPanelProps;

export default class CompaniesFilterPanel extends FilterPanel<CompaniesFilterPanelProps> {

    public render() {
        const localization = provideLocalizationService(this);

        return (
            <FilterContainer
                applyClassName={'Button--orange'}
                clearClassName={'Button--gray'}
                filterApply={this.props.filterApply}
                filterClear={this.props.filterClear}
            >

                <FilterItem>
                    <DatePickerWrapper
                        id="crmCompanyFrom"
                        name={CRMCompaniesFilters.From}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Companies.Filters.From, "%From")}
                        value={FilterHandler.getDateValue(this.props.filters, CRMCompaniesFilters.From)}
                        onChange={this.onDateChange.bind(this)}
                        max={FilterHandler.getDateValue(this.props.filters, CRMCompaniesFilters.To) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="crmCompanyTo"
                        name={CRMCompaniesFilters.To}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Companies.Filters.To, "%Created to")}
                        value={FilterHandler.getDateValue(this.props.filters, CRMCompaniesFilters.To)}
                        onChange={this.onDateChange.bind(this)}
                        min={FilterHandler.getDateValue(this.props.filters, CRMCompaniesFilters.From) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="crmCompanyCodeName"
                        name={CRMCompaniesFilters.Filter}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Companies.Filters.CodeName, "%Code / Name")}
                        value={FilterHandler.getValue(this.props.filters, CRMCompaniesFilters.Filter)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="crmCompanyAddress"
                        name={CRMCompaniesFilters.Address}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Companies.Filters.Address, "%Address")}
                        value={FilterHandler.getValue(this.props.filters, CRMCompaniesFilters.Address)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandMultiSelect
                        url={`/v1/addresses/states`}
                        dataItemKey={'id'}
                        textField={'text'}
                        itemMapper={defaultStringMapper}
                        id="crmCompanyState"
                        name={CRMCompaniesFilters.State}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Companies.Filters.State, "%State")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMCompaniesFilters.State, 'id', 'text')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandMultiSelect
                        url={`/v1/configuration`}
                        urlParams={{type: EnumType.Country}}
                        dataItemKey={'id'}
                        textField={'text'}
                        itemMapper={defaultStringMapper}
                        id="crmCompanyCountry"
                        name={CRMCompaniesFilters.Country}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Companies.Filters.Country, "%Country")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMCompaniesFilters.Country, 'id', 'text')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <MultiSelectWrapper
                        id="crmCompanyType"
                        name={CRMCompaniesFilters.PartyType}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Companies.Filters.Type, "%Type")}
                        data={Object.values(nonSystemPartyTypes).map(x => { return {
                            id: x,
                            text: localization.toLanguageString(x, x)
                        }})}
                        dataItemKey={'id'}
                        textField={'text'}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMCompaniesFilters.PartyType, 'id', 'text')}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandMultiSelect
                        url={`/v1/configuration`}
                        urlParams={{ type: EnumType.PartyCategory }}
                        dataItemKey={'id'}
                        textField={'text'}
                        itemMapper={defaultStringMapper}
                        id="crmOpportunityCategory"
                        name={CRMCompaniesFilters.PartyCategory}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Companies.Filters.Category, "%Category")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMCompaniesFilters.PartyCategory, 'id', 'text')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

            </FilterContainer>
        );
    }
}
registerForLocalization(CompaniesFilterPanel as React.ComponentClass<any>);