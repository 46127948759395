const FinancialsFilters = {
    StartDate: "StartDate",
    EndDate: "EndDate",
    Entity: "Entity",
    Client: "Client",
    ClientRef: "ClientRef",
    JobId: "JobId",
    BillTo: "BillTo",
    ClientFileJobType: "ClientFileJobType",
    InvoiceJobType: "InvoiceJobType",
    TransportMode: "TransportMode",
    TotalWithTaxSign: "TotalWithTaxSign",
    TotalWithTaxValue: "TotalWithTaxValue",
    InvoiceNr: "InvoiceNr",
    User: "User",
    Division: "Division",
    GLCode: "GLCode",
    Trip: "Trip",
    Filter: "Filter",
    Status: "Status",
} as const;

export type FinancialsFiltersType = typeof FinancialsFilters[keyof typeof FinancialsFilters];

export default FinancialsFilters;