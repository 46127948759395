import React, { useEffect } from 'react';
import * as ScreenStore from '~store/infra/screen';
import * as NavigationStore from '~store/infra/navigation';

import { Grid } from '@mui/material';
import { useLocalization } from '@progress/kendo-react-intl';

import QuotationsTab from './Quotations/QuotationsTab';
import ProjectsTab from './Projects/ProjectsTab';
import LocalizationKeys from '~enums/localizationKeys';
import AMWTabs from '~components/controls/AMWTabs';
import { AMWUserData } from '~models/user/AMWUserData';
import { UserPermissions } from '~enums/userPermissions';
import { ApplicationState } from '~store/ApplicationState';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


export enum QuotationsTabs {
    Quotations,
    Projects,
};

type QuotationsOwnProps = { tab: number };
type QuotationsStateProps = { userData?: AMWUserData }
    & ScreenStore.ScreenState;
type QuuotationsDispatchProps = typeof NavigationStore.actionCreators;
type QuotationsProps = QuotationsOwnProps
    & QuotationsStateProps
    & QuuotationsDispatchProps;

const Quotations: React.FC<QuotationsProps> = (props) => {
    const localization = useLocalization();
    const topbarHeight = props.topbarHeight;

    useEffect(() => {
        let element = document.querySelector('#QuotationsTabs');
        !!element && element.setAttribute('style', `top: ${topbarHeight}px` );
    }, [topbarHeight]);

    const handleSelect = (value: string) => {
        switch (Number(value)) {
            case QuotationsTabs.Quotations:
                props.navigate('/quotations');
                break;
            case QuotationsTabs.Projects:
                props.navigate('/projects');
                break;
            default:
                return;
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <AMWTabs
                    id={'QuotationsTabs'}
                    sticky={true}
                    value={`${props.tab}`}
                    onChange={handleSelect}
                    tabs={[
                        {
                            label: localization.toLanguageString(LocalizationKeys.Quotations.Tabs.Quotations, "%Quotations"),
                            content: <QuotationsTab />,
                            value: `${QuotationsTabs.Quotations}`,
                            accessible: props.userData?.hasPermission(UserPermissions.Quotations.QuotationsView),
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.Quotations.Tabs.Projects, "%Projects"),
                            content: <ProjectsTab />,
                            value: `${QuotationsTabs.Projects}`,
                            accessible: props.userData?.hasPermission(UserPermissions.Quotations.ProjectsView),
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
}

export default connect(
    (state: ApplicationState) : QuotationsStateProps => {
        return {
            ...state.screen,
            userData: state.user.userData,
        };
    },
    (dispatch) => {
        return {
            ...bindActionCreators({...NavigationStore.actionCreators}, dispatch),
        };
    },
    (stateProps, dispatchProps, ownProps: QuotationsOwnProps) : QuotationsProps => {
        return {
            ...stateProps,
            ...dispatchProps,
            ...ownProps
        };
    }
)(Quotations);