const CRMDocumentHistoryGridFields = {
    Processed: "processed",
    Filename: "fileName",
    DocTitle: "docTitle",
    ProcessedByName: "processedByName",
    ProcessedOn: "processedOn",
    CreatedOn: "createdOn",
    CreatedManually: "createdManually",
    CreatedByName: "createdByName",
    ParentObject: "parentObject",
} as const;

export type CRMDocumentHistoryGridFieldsType = typeof CRMDocumentHistoryGridFields[keyof typeof CRMDocumentHistoryGridFields];

export default CRMDocumentHistoryGridFields;