import React, { useState } from 'react';
import {
    ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';
import {
    CircularProgress,
    Menu,
    MenuItem,
} from '@mui/material';
import { Button } from '@progress/kendo-react-buttons';
import NestedMenuItem from '~src/libs/NestedMenuItem';

import "~css/components/controls/DropDownButton.scss";


type DropDownButtonProps<T> = {
    label: string;
    icon?: string;
    showText?: boolean;
    className?: string;
    isLoading?: boolean;
    noItemsLabel?: string;
    idRetriever: (item: T) => any;
    labelRetriever: (item: T) => string;
    groupRetriever?: (item: T) => string;
    onSelect: (item: T) => any | void;
    options: T[];
    onOpen?: () => any | void;
    disabled?: boolean;
};

const DropDownButton = <T extends object>(props: DropDownButtonProps<T>) => {
    const [ anchorEl, setAnchorEl ] = useState<HTMLElement>();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        const currentTarget = event.currentTarget;
        setAnchorEl(currentTarget);
        !!props.onOpen && props.onOpen();
    };

    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const groups = [] as string[];
    const groupRetriever = props.groupRetriever;
    if (!!groupRetriever) {
        const itemsGroups = new Set(props.options.map(x => groupRetriever(x)).filter(x => !!x));
        groups.push(...Array.from(itemsGroups));
    }

    const ungroupedItems = !!groupRetriever ?
        props.options.filter(x => !groupRetriever(x)) :
        props.options;

    return (
        <React.Fragment>
            <Button
                className={`DropDownButton__button ${props.className ?? ''}`}
                fillMode="flat"
                onClick={handleMenu}
                icon={props.icon}
                title={props.label}
                disabled={props.disabled}
            >
                <ArrowDropDownIcon/>

                {!!props.icon && !!props.showText && (<span>{props.label}</span>)}
            </Button>

            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={handleClose}
                className={"DropDownButton__menu"}
            >
                {!!props.isLoading && (
                    <CircularProgress />
                )}

                {!props.isLoading && props.options.length === 0 && (
                    <MenuItem key={0}>
                        {props.noItemsLabel}
                    </MenuItem>
                )}

                {!props.isLoading && !!groupRetriever && groups.map(group => {
                    let groupItems = props.options.filter(x => groupRetriever(x) === group);

                    return (
                        <NestedMenuItem
                            label={group}
                            className={`DropDownButton__item`}
                            parentMenuOpen={!!anchorEl}
                            onClick={() => {return false;}}
                            key={group}
                        >
                            {groupItems.map(item => {
                                return (
                                    <MenuItem key={props.idRetriever(item)} onClick={() => {props.onSelect(item);}}>
                                        {props.labelRetriever(item)}
                                    </MenuItem>
                                )
                            })}
                        </NestedMenuItem>
                    );
                })}

                {!props.isLoading && ungroupedItems.map(item => {
                    return (
                        <MenuItem key={props.idRetriever(item)} onClick={() => {props.onSelect(item);}}>
                            {props.labelRetriever(item)}
                        </MenuItem>
                    );
                })}
            </Menu>
        </React.Fragment>
    );
};

export default DropDownButton;