const EnumType = Object.freeze({
    ActivityPhoto: "ActivityPhoto",
    ActivityTransfer: "ActivityTransfer",
    AdditionalService: "AdditionalService",
    AppointmentType: "AppointmentType",
    ArtManConfig: "ArtManConfig",
    ClientFileServiceSubType: "ClientFileServiceSubType",
    ClientFileServiceType: "ClientFileServiceType",
    Competitor: "Competitor",
    Condition: "Condition",
    ConditionLocation: "ConditionLocation",
    ContainerType: "ContainerType",
    Country: "Country",
    CraneType: "CraneType",
    CRMConfiguration: "CRMConfiguration",
    Currency: "Currency",
    CustomsDocStatus: "CustomsDocStatus",
    CustomsDocType: "CustomsDocType",
    CustomsOfficeLocation: "CustomsOfficeLocation",
    EventType: "EventType",
    ExpenseEmail: "ExpenseEmail",
    ExpenseQuotationEmail: "ExpenseQuotationEmail",
    HandlingType: "HandlingType",
    HSCode: "HSCode",
    ItemCategory: "ItemCategory",
    ItemCustomsStatus: "ItemCustomsStatus",
    JobType: "JobType",
    JobTypeBillableService: "JobTypeBillableService",
    LoadType: "LoadType",
    OfferedServiceType: "OfferedServiceType",
    OpportunityPriority: "OpportunityPriority",
    OpportunityStage: "OpportunityStage",
    OpportunityStatus: "OpportunityStatus",
    PackageType: "PackageType",
    PackingMethod: "PackingMethod",
    PackingType: "PackingType",
    ParkingType: "ParkingType",
    PartyCategory: "PartyCategory",
    PartyTerms: "PartyTerms",
    PaymentMethod: "PaymentMethod",
    PropertyType: "PropertyType",
    ResourceQualification: "ResourceQualification",
    ResourceType: "ResourceType",
    Room: "Room",
    RouteType: "RouteType",
    Salutation: "Salutation",
    ServiceTransactTypeMap: "ServiceTransactTypeMap",
    ShipperHandlingStatus: "ShipperHandlingStatus",
    SourceOfRequest: "SourceOfRequest",
    TariffType: "TariffType",
    TaskType: "TaskType",
    TaxType: "TaxType",
    TransportMode: "TransportMode",
    TransportModeBillableService: "TransportModeBillableService",
    TripType: "TripType",
    WarehouseLocation: "WarehouseLocation",
    WarehouseZone: "WarehouseZone",

    ActivityConRep: "ActivityConRep",
    ActivityDescription: "ActivityDescription",
    ActivityDisposal: "ActivityDisposal",
    ActivityItemsComing: "ActivityItemsComing",
    ActivityLocate: "ActivityLocate",
    ActivityMeasure: "ActivityMeasure",
    ActivityPrepForDelivery: "ActivityPrepForDelivery",
    ActivityPutInStore: "ActivityPutInStore",
    ActivityRepack: "ActivityRepack",
    ActivitySidemark: "ActivitySidemark",
    ActivityTag: "ActivityTag",
    ActivityUnpack: "ActivityUnpack",
    ActivityViewing: "ActivityViewing",
});

export type EnumTypeType = typeof EnumType[keyof typeof EnumType];

export default EnumType;