import { GridColumnReorderEvent, GridColumnResizeEvent, GridSelectionChangeEvent, getSelectedState } from "@progress/kendo-react-grid";
import { UserPreferenceKey } from "~enums/userPreferenceKeys";

export default class GridUtils {
    public static calculateColumnWidth(field: string, columnWidth: {[field: string] : any}, containerWidth: number, minWidths: {[key:string]: number}) : number | undefined {
        let fieldWidth = columnWidth[field];

        if (!!fieldWidth)
            return containerWidth * fieldWidth / 100;

        let minWidthSum = Object.values(minWidths).reduce((a, b) => { return a + b; });

        if (minWidthSum > containerWidth) {
            return minWidths[field];
        } else {
            return undefined;
        }
    }

    public static columnOrderChange = (event: GridColumnReorderEvent, pageKey: string, action: (prefs: {[key: string]: any}) => void) => {
        let order : {[key:string]: number | undefined} = {};
        event.columns.forEach((col) => {
            !!col.field && (order[col.field] = col.orderIndex);
        });
        action({[`${pageKey}-${UserPreferenceKey.Order}`]: order});
    }

    public static columnResize = (event: GridColumnResizeEvent, pageKey: string, columnWidth: {[key:string] : number | undefined}, action: (prefs: {[key: string]: any}) => void, resizeTimeout: NodeJS.Timeout) => {
        !!resizeTimeout && clearTimeout(resizeTimeout);

        resizeTimeout = setTimeout(() => {
            let result : {[key:string]:number|undefined} = {...columnWidth};

            let containerWidth = event.target.element?.getBoundingClientRect().width ?? 1;

            event.columns.forEach(col => {
                if (event.columns.indexOf(col) === event.index || !result[col.field ?? '']) {
                    let percentWidth =
                        (event.columns.indexOf(col) === event.index ?
                            event.newWidth :
                            Number(col.width ?? 1)
                        ) /
                        containerWidth * 100;

                    result[col.field ?? ''] = percentWidth;
                }
            });

            action({[`${pageKey}-${UserPreferenceKey.ColumnWidth}`]: result});
        }, 1000);
    }

    public static parseSelectedId(selectedState: {[id: string]: boolean | number[];}) {
        let selected = Object.keys(selectedState).length > 0 ?
            Number(Object.keys(selectedState)[0]) : null;

        return selected;
    }

    public static getSelectedIds(event: GridSelectionChangeEvent, selectedIds: Array<number>, dataItemKey: string) {
        const currentState : {[key:string]: boolean} = {};
        selectedIds.forEach(x => { currentState[x] = true; });

        const selectedState = getSelectedState({
            event,
            selectedState: currentState,
            dataItemKey: dataItemKey,
        });

        return Object.keys(selectedState).filter(x => selectedState[x]).map(x => Number(x));
    }

    public static getSelectedIdsString(event: GridSelectionChangeEvent, selectedIds: Array<string>, dataItemKey: string) {
        const currentState : {[key:string]: boolean} = {};
        selectedIds.forEach(x => { currentState[x] = true; });

        const selectedState = getSelectedState({
            event,
            selectedState: currentState,
            dataItemKey: dataItemKey,
        });

        return Object.keys(selectedState).filter(x => selectedState[x]);
    }
}