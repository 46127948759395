const ManagementSupplierRouteFields = {
    OriginCity: "SupplierRouteField.OriginCity",
    OriginState: "SupplierRouteField.OriginState",
    OriginZip: "SupplierRouteField.OriginZip",
    DestCity: "SupplierRouteField.DestCity",
    DestState: "SupplierRouteField.DestState",
    DestZip: "SupplierRouteField.DestZip",
} as const;

export type ManagementSupplierRouteFieldsType = typeof ManagementSupplierRouteFields[keyof typeof ManagementSupplierRouteFields];

export default ManagementSupplierRouteFields;