import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { ShowErrorAction } from '~store/infra/errors';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import SystemUserItem from '~models/user/SystemUserItem';
import ManagementSystemUsersFilters from '~enums/gridFilters/managementSystemUsersFilters';


export interface ManagementSystemUsersState {
    isLoading: boolean;
    users: Array<SystemUserItem>;
    count: number;
    skip: number;
    createStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface SystemsUsersRequestAction {
    type: 'MANAGEMENT_SYSTEMUSERS_REQUEST';
    skip: number;
}
interface SystemsUsersReceiveAction {
    type: 'MANAGEMENT_SYSTEMUSERS_RECEIVE';
    users: Array<SystemUserItem>;
    count: number;
}
interface SystemsUsersErrorAction {
    type: 'MANAGEMENT_SYSTEMUSERS_ERROR';
}

interface SystemsUsersCreateStartAction {
    type: 'MANAGEMENT_SYSTEMUSERS_CREATE_START';
}
interface SystemsUsersCreateSendAction {
    type: 'MANAGEMENT_SYSTEMUSERS_CREATE_SEND';
}
interface SystemsUsersCreateSuccessAction {
    type: 'MANAGEMENT_SYSTEMUSERS_CREATE_SUCCESS';
}
interface SystemsUsersCreateCancelAction {
    type: 'MANAGEMENT_SYSTEMUSERS_CREATE_CANCEL';
}
interface SystemsUsersCreateErrorAction {
    type: 'MANAGEMENT_SYSTEMUSERS_CREATE_ERROR';
}

interface SystemsUsersDeleteStartAction {
    type: 'MANAGEMENT_SYSTEMUSERS_DELETE_START';
}
interface SystemsUsersDeleteSendAction {
    type: 'MANAGEMENT_SYSTEMUSERS_DELETE_SEND';
}
interface SystemsUsersDeleteSuccessAction {
    type: 'MANAGEMENT_SYSTEMUSERS_DELETE_SUCCESS';
}
interface SystemsUsersDeleteCancelAction {
    type: 'MANAGEMENT_SYSTEMUSERS_DELETE_CANCEL';
}
interface SystemsUsersDeleteErrorAction {
    type: 'MANAGEMENT_SYSTEMUSERS_DELETE_ERROR';
}

type KnownAction = SystemsUsersRequestAction | SystemsUsersReceiveAction | SystemsUsersErrorAction
    | SystemsUsersCreateStartAction | SystemsUsersCreateSendAction
    | SystemsUsersCreateSuccessAction | SystemsUsersCreateCancelAction
    | SystemsUsersCreateErrorAction
    | SystemsUsersDeleteStartAction | SystemsUsersDeleteSendAction
    | SystemsUsersDeleteSuccessAction | SystemsUsersDeleteCancelAction
    | SystemsUsersDeleteErrorAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestUsers: (filters: FilterList, skip: number, pageSize: number): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementSystemUsers && !appState.managementSystemUsers.isLoading) {
            let tenantValue = FilterHandler.getDropDownValue(filters, ManagementSystemUsersFilters.Tenant, 'id', 'username');
            if (!tenantValue) {
                dispatch({ type: 'MANAGEMENT_SYSTEMUSERS_RECEIVE', users: [], count: 0, });
            } else {
                FilterHandler.getApiFilterRequestPromise<DataRequest<SystemUserItem>>('/v1/users/system', 'POST', filters, skip, pageSize)
                    .then(data => {
                        dispatch(UserActions.updateUserPreferences({
                            [`${UserPreferencePage.ManagementSystemUsers}-${UserPreferenceKey.Filters}`]: filters,
                            [`${UserPreferencePage.ManagementSystemUsers}-${UserPreferenceKey.PageSize}`]: pageSize,
                        }));

                        dispatch({ type: 'MANAGEMENT_SYSTEMUSERS_RECEIVE', users: data.data, count: data.count, });
                    })
                    .catch(err => {
                        dispatch({ type: 'MANAGEMENT_SYSTEMUSERS_ERROR' });
                        dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                    });

                dispatch({ type: 'MANAGEMENT_SYSTEMUSERS_REQUEST', skip: skip, });
            }
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementSystemUsersState = {
    isLoading: false,
    users: [],
    count: 0,
    skip: 0,
    createStatus: ServerOperationStatus.NONE,
    deleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementSystemUsersState> = (state: ManagementSystemUsersState | undefined, incomingAction: Action): ManagementSystemUsersState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_SYSTEMUSERS_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'MANAGEMENT_SYSTEMUSERS_RECEIVE':
            return {
                ...state,
                users: action.users,
                isLoading: false,
                count: action.count,
            };
        case 'MANAGEMENT_SYSTEMUSERS_ERROR':
            return {
                ...state,
                isLoading: false,
            };
    }

    return state;
};