import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { ShowErrorAction } from '../infra/errors';


export interface GalleryState {
    updateStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

export interface GalleryUpdateAction {
    type: 'GALLERY_UPDATE_SUCCESS';
    objectId: number;
    galleryType: string;
    photoList: Array<string>;
}

type KnownAction = GalleryUpdateAction
    | ShowErrorAction;

export const actionCreators = {
    onListUpdate: (objectId: number, type: string, photoList: Array<string>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.gallery) {
            dispatch({
                type: 'GALLERY_UPDATE_SUCCESS',
                objectId: objectId,
                galleryType: type,
                photoList: photoList,
            });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: GalleryState = {
    updateStatus: ServerOperationStatus.NONE,
};

export const reducer: Reducer<GalleryState> = (state: GalleryState | undefined, incomingAction: Action): GalleryState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GALLERY_UPDATE_SUCCESS':
            return {
                ...state,
            };
    }

    return state;
};