const TransactionType = Object.freeze({
    //TODO swap when entities are updated
    CertificateOfShipment: "CertificateOfShipment",
    Collection: "Collection",
    CommercialInvoice: "CommercialInvoice",
    Delivery: "Delivery",
    InnerTransfer: "InnerTransfer",
    OnSite: "OnSite",
    Packing: "Packing",
    ReceiveIn: "ReceiveIn",
    Release: "Release",
    Viewing: "Viewing",
    WHWorksheet: "WHWorksheet",
    //CertificateOfShipment: "Enum.TransactionType.CertificateOfShipment",
    //Collection: "Enum.TransactionType.Collection",
    //CommercialInvoice: "Enum.TransactionType.CommercialInvoice",
    //Delivery: "Enum.TransactionType.Delivery",
    //InnerTransfer: "Enum.TransactionType.InnerTransfer",
    //OnSite: "Enum.TransactionType.OnSite",
    //Packing: "Enum.TransactionType.Packing",
    //ReceiveIn: "Enum.TransactionType.ReceiveIn",
    //Release: "Enum.TransactionType.Release",
    //Viewing: "Enum.TransactionType.Viewing",
    //WHWorksheet: "Enum.TransactionType.WHWorksheet",
});

export default TransactionType;