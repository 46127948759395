import React from 'react';
import equal from 'fast-deep-equal/react';
import {
    InputHandle,
    InputPrefix,
    InputSuffix,
    TextBox,
    TextBoxChangeEvent,
    TextBoxProps
} from '@progress/kendo-react-inputs';

import '~css/components/controls/TextBoxWrapper.scss';
import { Button } from '@progress/kendo-react-buttons';
import { LinearProgress } from '@mui/material';
import { FloatingLabel } from '@progress/kendo-react-labels';


export interface TextBoxWrapperEvent {
    id: string;
    name: string;
    value: string | number | readonly string[];
    element: HTMLInputElement;
    syntheticEvent: React.SyntheticEvent<any, Event>;
}

export type TextBoxWrapperProps = {
    inProgress?: boolean;
    onInputChange?: (event: TextBoxWrapperEvent) => Promise<any> | void;
    label?: string;
    containerClassName?: string;
} &
({
    clearable: boolean,
    onClearClick: (name?: string) => any,
} |
{
    clearable?: never,
    onClearClick?: never,
}) & TextBoxProps;

export type TextBoxWrapperState = {
    value: string | number | readonly string[] | undefined,
};

const customProps = ['onInputChange', 'clearable', 'onClearClick', 'inProgress', 'containerClassName'];

export default class TextBoxWrapper extends React.Component<TextBoxWrapperProps, TextBoxWrapperState> {
    __input: InputHandle | null = null;

    constructor(props: TextBoxWrapperProps) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    componentDidUpdate(prevProps: TextBoxWrapperProps) {
        if(!equal(prevProps.value, this.props.value)) {
            this.setState({
                value: this.props.value
            });
        }
    }

    private onChange(event: TextBoxChangeEvent) {
        const value = event.target.value;
        this.setState({
            value: value
        });
    }

    private onBlurCapture(event: React.FocusEvent<HTMLInputElement>) {
        if (!!this.props.onInputChange && this.props.value !== this.state.value) {
            let promise = this.props.onInputChange({
                id: event.target.id,
                name: event.target.name,
                value: this.state.value ?? '',
                element: event.target,
                syntheticEvent: event
            });

            if(!!promise) {
                promise.catch((err: any) => {
                    this.setState({value: this.props.value});
                });
            }
        }
    }

    private onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.code === "Escape") {
            this.setState({
                value: this.props.value
            });
        }

        if (event.code === "Enter")
        {
            if(!!this.props.onInputChange && this.props.value !== this.state.value) {
                let promise = this.props.onInputChange({
                    id: event.currentTarget?.id,
                    name: event.currentTarget.name,
                    value: this.state.value ?? '',
                    element: event.currentTarget,
                    syntheticEvent: event
                });

                if(!!promise) {
                    promise.catch((err: any) => {
                        this.setState({value: this.props.value});
                    });
                }
            }
        }
    }

    private clearValue() {
        !!this.props.onClearClick && this.props.onClearClick(this.props.name);
    }

    private convertInputProps() {
        let defaultProps: {[key:string]: any} = {};

        for(let key in this.props){
            if (!customProps.includes(key)) {
                let entry = Object.entries(this.props).find(x => x[0] === key);

                defaultProps[key] = !!entry ? entry[1] : undefined;
            }
        }

        return defaultProps;
    }

    private get textbox() {
        const defaultProps = this.convertInputProps();

        const className=`TextBoxWrapper${!!this.props.readOnly ? ' TextBoxWrapper--readonly' : ''} ${this.props.className || ''}`.trim();

        return (
            <TextBox
                {...defaultProps}
                value={this.state.value}
                onChange={this.onChange.bind(this)}
                onKeyDown={this.onKeyDown.bind(this)}
                onBlurCapture={this.onBlurCapture.bind(this)}
                className={className}
                prefix={() => (
                    <InputPrefix>
                        {!!this.props.prefix && (React.createElement(this.props.prefix as React.FunctionComponent<any>))}

                        {!!this.props.inProgress && (
                            <LinearProgress className={`TextBoxWrapper__Progress`} />
                        )}
                    </InputPrefix>
                )}
                suffix={() => (
                    <InputSuffix>
                        {!!this.props.clearable && !!this.props.value && (
                            <Button
                                fillMode="flat"
                                icon='close'
                                onClick={this.clearValue.bind(this)}>
                            </Button>
                        )}

                        {!!this.props.suffix && (React.createElement(this.props.suffix as React.FunctionComponent<any>))}
                    </InputSuffix>
                )}
            />
        );
    }

    render() {
        return (
            <>
                {!!this.props.label ?
                    (
                        <FloatingLabel
                            label={this.props.label}
                            editorId={this.props.id}
                            editorValue={`${this.state.value}`}
                            editorValid={this.props.valid}
                            editorDisabled={this.props.disabled}
                            className={`TextBoxWrapper__Label${!!this.props.readOnly ? ' TextBoxWrapper__Label--readonly' : ''} ${this.props.containerClassName ?? ''}`.trim()}
                            // optional={optional}
                        >
                            {this.textbox}
                        </FloatingLabel>
                    ) :
                    this.textbox
                }
            </>
        );
    }
}