import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';
import { ShowErrorAction } from '~store/infra/errors';
import { NavigateAction } from '~store/infra/navigation';
import { apiClientInstance } from '~services/auth/ApiClientInstance';

import ShipmentsGridFilters from '~enums/gridFilters/shipmentFilters';
import ShippingGridFilters from '~enums/gridFilters/shippingGridFilters';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import ShipmentGridItem from '~models/shipment/shipmentGridItem';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';

import { SortDescriptor } from "@progress/kendo-data-query";


export interface ShippingState {
    isLoading: boolean;
    shipments: Array<ShipmentGridItem>;
    count: number;
    skip: number;
    shipmentCreateStatus: ServerOperationStatus;
    shipmentDeleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface ShippingRequestAction {
    type: 'SHIPPING_REQUEST';
    skip: number;
}
interface ShippingReceiveAction {
    type: 'SHIPPING_RECEIVE';
    shipments: ShipmentGridItem[];
    count: number;
}
interface ShippingErrorAction {
    type: 'SHIPPING_ERROR';
}

interface ShippingCreateSendAction {
    type: 'SHIPPING_CREATE_SEND';
}
interface ShippingCreateSuccessAction {
    type: 'SHIPPING_CREATE_SUCCESS';
}
interface ShippingCreateErrorAction {
    type: 'SHIPPING_CREATE_ERROR'
}

interface ShippingDeleteStartAction {
    type: 'SHIPPING_DELETE_START';
}
interface ShippingDeleteSendAction {
    type: 'SHIPPING_DELETE_SEND';
}
interface ShippingDeleteSuccessAction {
    type: 'SHIPPING_DELETE_SUCCESS';
}
interface ShippingDeleteCancelAction {
    type: 'SHIPPING_DELETE_CANCEL';
}
interface ShippingDeleteErrorAction {
    type: 'SHIPPING_DELETE_ERROR';
}


type KnownAction = ShippingRequestAction | ShippingReceiveAction | ShippingErrorAction
    | ShippingCreateSendAction | ShippingCreateSuccessAction | ShippingCreateErrorAction
    | ShippingDeleteStartAction | ShippingDeleteSendAction
    | ShippingDeleteSuccessAction | ShippingDeleteCancelAction
    | ShippingDeleteErrorAction
    | NavigateAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestShipments: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipping && !appState.shipping.isLoading) {
            let mvFilters = {
                ...filters,
                // [ShipmentsGridFilters.Groupages]: {value: true},
            };
            mvFilters[ShipmentsGridFilters.Groupages] = {value: true};

            if (!!mvFilters[ShippingGridFilters.EndDate]?.value) {
                const dateValue = new Date(mvFilters[ShippingGridFilters.EndDate].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                mvFilters[ShippingGridFilters.EndDate] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<ShipmentGridItem>>('/v1/shipping/search', 'POST', mvFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.Shipping}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.Shipping}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.Shipping}-${UserPreferenceKey.PageSize}`]: pageSize
                    }));

                    data.data.forEach((file) => {
                        !!file.eta && (file.eta = new Date(file.eta));
                        !!file.etd && (file.etd = new Date(file.etd));
                    });
                    dispatch({ type: 'SHIPPING_RECEIVE', shipments: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'SHIPPING_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'SHIPPING_REQUEST', skip: skip, });
        }
    },
    createShipment: ():  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipping) {
            apiClientInstance.fetchRequest(
                '/v1/shipping/',
                'POST',
            )
                .then((newId) => {
                    dispatch({ type: 'NAVIGATION_NAVIGATE', route: `/shipping/${newId}` });

                    dispatch({ type: 'SHIPPING_CREATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'SHIPPING_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'SHIPPING_CREATE_SEND' });
        }
    },
    startDeleteShipments: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipping) {
            dispatch({ type: 'SHIPPING_DELETE_START' });
        }
    },
    deleteShipments: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipping) {
            apiClientInstance.fetchRequest(
                `/v1/shipping/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'SHIPPING_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'SHIPPING_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'SHIPPING_CREATE_SEND' });
        }
    },
    cancelDeleteShipments: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.shipping) {
            dispatch({ type: 'SHIPPING_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ShippingState = {
    isLoading: false,
    shipments: [],
    count: 0,
    skip: 0,
    shipmentCreateStatus: ServerOperationStatus.NONE,
    shipmentDeleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ShippingState> = (state: ShippingState | undefined, incomingAction: Action): ShippingState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SHIPPING_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'SHIPPING_RECEIVE':
            return {
                ...state,
                shipments: action.shipments,
                count: action.count,
                isLoading: false,
            };
        case 'SHIPPING_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'SHIPPING_CREATE_SEND':
            return {
                ...state,
                isLoading: true,
                shipmentCreateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'SHIPPING_CREATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                shipmentCreateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'SHIPPING_CREATE_ERROR':
            return {
                ...state,
                isLoading: false,
                shipmentCreateStatus: ServerOperationStatus.ERROR,
            };

        case 'SHIPPING_DELETE_START':
            return {
                ...state,
                shipmentDeleteStatus: ServerOperationStatus.READY,
            };
        case 'SHIPPING_DELETE_SEND':
            return {
                ...state,
                shipmentDeleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'SHIPPING_DELETE_CANCEL':
            return {
                ...state,
                shipmentDeleteStatus: ServerOperationStatus.NONE,
            };
        case 'SHIPPING_DELETE_SUCCESS':
            return {
                ...state,
                shipmentDeleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'SHIPPING_DELETE_ERROR':
            return {
                ...state,
                shipmentDeleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};