import reportContexts from '~enums/reportContext';
import { Grid } from '@mui/material';
import ReportButton from '~components/controls/ReportButton';
import { AMWGridFilterToolbarWrapperProps } from '~components/datagrid/types';
import AMWGridFilterToolbar from '~components/datagrid/AMWGridFilterToolbar';
import InventoryPackagesFilterPanel from './InventoryPackagesFilterPanel';


type InventoryPackagesToolbarProps = AMWGridFilterToolbarWrapperProps;

const InventoryPackagesToolbar = (props: InventoryPackagesToolbarProps) => {
    return (
        <AMWGridFilterToolbar {...props} filterPanel={InventoryPackagesFilterPanel}>

            <div className="spacer"></div>                    
            
            <Grid item className={"ButtonGroup"}>
                <ReportButton reportContext={reportContexts.ShipmentView} />
            </Grid>

        </AMWGridFilterToolbar>
    );
};

export default InventoryPackagesToolbar;