import { Grid } from '@mui/material';
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import { AMWGridFilterToolbarWrapperProps } from '~components/datagrid/types';
import AMWGridFilterToolbar from '~components/datagrid/AMWGridFilterToolbar';
import QuotationsFilterPanel from './QuotationsFilterPanel';
import LocalizationKeys from '~enums/localizationKeys';


type QuotationsToolbarProps = {
    onNewQuoteClick: () => void,
    onCloneQuoteClick: () => void,
    onDeleteQuoteClick: () => void,
} & AMWGridFilterToolbarWrapperProps;

const QuotationsToolbar = (props: QuotationsToolbarProps) => {
    const localization = useLocalization();

    return (
        <AMWGridFilterToolbar {...props} filterPanel={QuotationsFilterPanel}>
            <Grid item className={"ButtonGroup"}>
                <Button
                    fillMode={'flat'}
                    className={"Button--bold Button--orange"}
                    onClick={props.onNewQuoteClick}
                    disabled={props.isGridBusy}
                    size={"large"}
                    icon={"plus"}
                    title={localization.toLanguageString(LocalizationKeys.Quotations.Grid.Toolbar.New, "%New quotation")}
                />

                <Button
                    fillMode={'flat'}
                    className={"Button--bold Button--orange"}
                    onClick={props.onCloneQuoteClick}
                    disabled={!props.selectedItem || props.isGridBusy}
                    icon={"copy"}
                    title={localization.toLanguageString(LocalizationKeys.Shared.Buttons.Clone, "%Clone")}
                />
            </Grid>

            <Grid item className={"ButtonGroup"}>
                <Button
                    onClick={props.onDeleteQuoteClick}
                    disabled={!props.selectedItem || props.isGridBusy}
                    icon={"delete"}
                    title={localization.toLanguageString(LocalizationKeys.Shared.Buttons.Delete, "%Delete")}
                />
            </Grid>
        </AMWGridFilterToolbar>
    );
};

export default QuotationsToolbar;