import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useOidc } from '@axa-fr/react-oidc';
import { ApplicationState } from '~store/ApplicationState';
import * as UserStore from '~store/auth/user';
import * as ScreenStore from '~store/infra/screen';

import Preloader from '~components/infra/Preloader';
import AppSwitch from '~components/baseStructure/AppSwitch';
import LocalizationWrapper from '~components/baseStructure/LocalizationWrapper';
import StyleWrapper from '~components/baseStructure/StyleWrapper';
import AppWideModals from '~components/baseStructure/AppWideModals';


type AuthCheckWrapperProps = UserStore.UserState &
    typeof UserStore.actionCreators &
    typeof ScreenStore.actionCreators;

const AuthCheckWrapper: React.FC<AuthCheckWrapperProps> = (props: AuthCheckWrapperProps) => {
    const oidc = useOidc();

    const updateScreenWidth = props.updateScreenWidth;
    const requestUserData = props.requestUserData;

    useEffect(() => {
        window.addEventListener("resize", updateScreenWidth);
        window.addEventListener("orientationchange", updateScreenWidth);

        return () => {
            window.removeEventListener('resize', updateScreenWidth);
            window.removeEventListener('orientationchange', updateScreenWidth);
        };
    }, [updateScreenWidth]);

    useEffect(() => {
        if (oidc.isAuthenticated) {
            requestUserData();
        }
    }, [oidc.isAuthenticated, requestUserData]);

    return (
        <React.Fragment>
            {!props.userData && (
                <Preloader />
            )}

            {!!props.userData && (
                <LocalizationWrapper locale={props.userData.locale}>
                    <StyleWrapper>
                        <AppSwitch />

                        <AppWideModals />
                    </StyleWrapper>
                </LocalizationWrapper>
            )}
        </React.Fragment>
    );
}

export default connect(
    (state: ApplicationState) => {
        return {
            ...state.user,
        };
    },
    (dispatch) => {
        let user = bindActionCreators({...UserStore.actionCreators}, dispatch);
        let screen = bindActionCreators({...ScreenStore.actionCreators}, dispatch);

        return {
            ...user,
            ...screen,
        };
    },
)(AuthCheckWrapper as any);