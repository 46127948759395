import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '~store/ApplicationState';
import * as ClientFileStore from '~store/pages/clientFile/clientFile';
import * as ErrorsStore from '~store/infra/errors';
import * as NavigationStore from '~store/infra/navigation';
import { useEffectOnce } from '~src/utils/hooks';

import LocalizationKeys from '~enums/localizationKeys';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPermissions } from '~enums/userPermissions';
import { AMWUserData } from '~models/user/AMWUserData';

import { Grid, Typography } from '@mui/material';
import { useLocalization } from '@progress/kendo-react-intl';
import {
    Menu,
    MenuItem,
    MenuSelectEvent,
} from "@progress/kendo-react-layout";
import BreadcrumbsWrapper from '~components/layout/BreadcrumbsWrapper';

import '~css/pages/ClientFile.scss';


export enum ClientFilePage {
    GENERAL_INFO,
    PO,
    POITEMS,
    TRANSACTIONS,
    SHIPPING,
    SHIPMENTDETAILS,
};

type ClientFileOwnProps = {
    fileId: number,
    page: ClientFilePage,
    children?: React.ReactNode,
};

type ClientFileStateProps = ClientFileStore.ClientFileState
    & { userData?: AMWUserData; isBusy: boolean; };
type ClientFileDispatchProps = typeof ClientFileStore.actionCreators
    & typeof NavigationStore.actionCreators
    & typeof ErrorsStore.operationWarnings;

type ClientFileProps = ClientFileOwnProps
    & ClientFileStateProps
    & ClientFileDispatchProps;

const isNoCrumbPage = (page: ClientFilePage) => {
    switch(page)
    {
        case ClientFilePage.POITEMS:
        case ClientFilePage.SHIPPING:
        case ClientFilePage.TRANSACTIONS:
            return true;
        default:
            return false;
    }
}

const ClientFile = (props: ClientFileProps) => {
    const onMenuSelect = (e: MenuSelectEvent) => {
        if (props.isBusy) {
            props.showOperationWarning();
        }
        else {
            switch (e.item.data.page) {
                case ClientFilePage.GENERAL_INFO:
                    props.navigate(`/files/${props.fileId}`);
                    break;
                case ClientFilePage.POITEMS:
                    props.navigate(`/files/${props.fileId}/po`);
                    break;
                case ClientFilePage.SHIPPING:
                    props.navigate(`/files/${props.fileId}/shipments`);
                    break;
                case ClientFilePage.TRANSACTIONS:
                    props.navigate(`/files/${props.fileId}/transactions`);
                    break;
            }
        }
    };

    let localization = useLocalization();

    useEffectOnce(() => {
        props.checkEditPermissions(props.fileId);
    });

    return (
        <Grid container>
            <Grid item xs={12} className={"ClientFile ClientFile__root"}>
                <BreadcrumbsWrapper hide={isNoCrumbPage(props.page)}>
                    <Grid item xs={12} className={"ClientFile__Title"}>
                        <Typography>
                            {props.headerText}
                        </Typography>
                    </Grid>

                    <Grid item container xs={12}>
                        <Grid item className={"ClientFile__Menu"}>
                            <Menu vertical={true} onSelect={onMenuSelect}>
                                <MenuItem
                                    data={{page: ClientFilePage.GENERAL_INFO}}
                                    cssClass={`${props.page === ClientFilePage.GENERAL_INFO ? 'ClientFile__Menu__Item--active' : ''}`}
                                    text={localization.toLanguageString(LocalizationKeys.Navigation.ClientFile.GeneralInfo, "%General Info")}
                                />

                                {props.userData?.hasPermission(UserPermissions.POItems.POItemsView) && (
                                    <MenuItem
                                        data={{page: ClientFilePage.POITEMS}}
                                        cssClass={`${[ClientFilePage.PO, ClientFilePage.POITEMS].includes(props.page) ?
                                            'ClientFile__Menu__Item--active' : ''}`}
                                        text={localization.toLanguageString(LocalizationKeys.Navigation.ClientFile.POItems, "%POs/Items")}
                                    />
                                )}

                                {props.userData?.hasPermission(UserPermissions.Operations.TransactionsClientView) && (
                                    <MenuItem
                                        data={{page: ClientFilePage.TRANSACTIONS}}
                                        cssClass={`${[ClientFilePage.TRANSACTIONS, ClientFilePage.TRANSACTIONS].includes(props.page) ?
                                            'ClientFile__Menu__Item--active' : ''}`}
                                        text={localization.toLanguageString(LocalizationKeys.Navigation.ClientFile.Transactions, "%Transactions")}
                                    />
                                )}

                                {props.userData?.hasPermission(UserPermissions.Financials.InvoicesClientView) && (
                                    <MenuItem
                                        text={localization.toLanguageString(LocalizationKeys.Navigation.ClientFile.Invoices, "%Invoices")}
                                    />
                                )}

                                {props.userData?.hasPermission(UserPermissions.Surveys.SurveysClientView) && (
                                    <MenuItem
                                        text={localization.toLanguageString(LocalizationKeys.Navigation.ClientFile.Surveys, "%Surveys")}
                                    />
                                )}

                                {props.userData?.hasPermission(UserPermissions.Quotations.QuotationsClientView) && (
                                    <MenuItem
                                        text={localization.toLanguageString(LocalizationKeys.Navigation.ClientFile.Quotations, "%Quotations")}
                                    />
                                )}

                                {props.userData?.hasPermission(UserPermissions.Shipping.ShippingClientView) && (
                                    <MenuItem
                                        data={{page: ClientFilePage.SHIPPING}}
                                        cssClass={`${[ClientFilePage.SHIPPING, ClientFilePage.SHIPMENTDETAILS].includes(props.page) ? 'ClientFile__Menu__Item--active' : ''}`}
                                        text={localization.toLanguageString(LocalizationKeys.Navigation.ClientFile.Shipments, "%Shipments")}
                                    />
                                )}

                                {props.userData?.hasPermission(UserPermissions.Inventory.InventoryClientView) && (
                                    <MenuItem
                                        text={localization.toLanguageString(LocalizationKeys.Navigation.ClientFile.Inventory, "%Inventory")}
                                    />
                                )}

                                {props.userData?.hasPermission(UserPermissions.Quotations.ProjectsClientView) && (
                                    <MenuItem
                                        text={localization.toLanguageString(LocalizationKeys.Navigation.ClientFile.Projects, "%Projects")}
                                    />
                                )}

                                {props.userData?.hasPermission(UserPermissions.Financials.ExpensesClientView) && (
                                    <MenuItem
                                        text={localization.toLanguageString(LocalizationKeys.Navigation.ClientFile.Expenses, "%Expenses")}
                                    />
                                )}

                                {props.userData?.hasPermission(UserPermissions.ClientFile.TimesheetsView) && (
                                    <MenuItem
                                        text={localization.toLanguageString(LocalizationKeys.Navigation.ClientFile.Timesheets, "%Timesheets")}
                                    />
                                )}

                                {props.userData?.hasPermission(UserPermissions.Tasks.TasksClientView) && (
                                    <MenuItem
                                        text={localization.toLanguageString(LocalizationKeys.Navigation.ClientFile.Tasks, "%Tasks")}
                                    />
                                )}
                            </Menu>
                        </Grid>

                        <Grid item className={"ClientFile__Content"}>
                            {props.children}
                        </Grid>
                    </Grid>
                </BreadcrumbsWrapper>
            </Grid>
        </Grid>
    );
}

export default connect(
    (state: ApplicationState) : ClientFileStateProps => {
        return {
            ...state.clientFile,
            userData: state.user.userData,
            isBusy: state.generalInfo.updateStatus === ServerOperationStatus.INPROGRESS ||
                state.poItems.orderCreateStatus === ServerOperationStatus.INPROGRESS ||
                state.poItems.orderDeleteStatus === ServerOperationStatus.INPROGRESS ||
                state.purchaseOrder.itemCreateStatus === ServerOperationStatus.INPROGRESS ||
                state.purchaseOrder.itemCloneStatus === ServerOperationStatus.INPROGRESS ||
                state.purchaseOrder.itemDeleteStatus === ServerOperationStatus.INPROGRESS ||
                state.purchaseOrder.itemGenerateIdStatus === ServerOperationStatus.INPROGRESS ||
                state.purchaseOrder.itemSetOwnerStatus === ServerOperationStatus.INPROGRESS,
        };
    },
    (dispatch) : ClientFileDispatchProps => {
        return {
            ...bindActionCreators({...ClientFileStore.actionCreators}, dispatch),
            ...bindActionCreators({...ErrorsStore.operationWarnings}, dispatch),
            ...bindActionCreators({...NavigationStore.actionCreators}, dispatch),
        };
    },
    (stateProps, dispatchProps, ownProps: ClientFileOwnProps) : ClientFileProps => {
        return {
            ...stateProps,
            ...dispatchProps,
            ...ownProps
        }
    }
)(ClientFile)