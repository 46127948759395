const CRMOpportunitiesFilters = {
    Description: "Description",
    Source: "Source",
    JobType: "JobType",
    Territory: "Territory",
    Department: "Department",
    Stage: "Stage",
    Status: "Status",
    CreatedFrom: "CreatedFrom",
    CreatedTo: "CreatedTo",
    RelevantTillFrom: "RelevantTillFrom",
    RelevantTillTo: "RelevantTillTo",
    ForecastTotal: "ForecastTotal",
    Certainty: "Certainty",
} as const;

export type CRMOpportunitiesFiltersType = typeof CRMOpportunitiesFilters[keyof typeof CRMOpportunitiesFilters];

export default CRMOpportunitiesFilters;