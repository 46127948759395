import config from "~src/config/config";

export function buildApiUrl(path: string) {
    if(path.startsWith("http")) return path;
    if(path.startsWith("/")) return `${config.api.uri}${path}`;
    return `${config.api.uri}/${path}`;
}

export function getNonEmptyParams(objectToParse: {[key: string]: any}) {
    let params = new URLSearchParams(objectToParse);
    let keysForDel = Array<string>();
    params.forEach((v, k) => {
        if (v === '') keysForDel.push(k);
    });
    keysForDel.forEach(k => {
        params.delete(k);
    });
    return params;
}

export function getResponseFileName(disposition: string): string | undefined {
    // eslint-disable-next-line
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    let fileName: string | undefined = undefined;
    if (utf8FilenameRegex.test(disposition)) {
        fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)![1]);
    } else {
        // prevent ReDos attacks by anchoring the ascii regex to string start and
        //  slicing off everything before 'filename='
        const filenameStart = disposition.toLowerCase().indexOf('filename=');

        if (filenameStart >= 0) {
            const partialDisposition = disposition.slice(filenameStart);
            const matches = asciiFilenameRegex.exec(partialDisposition );
            if (matches != null && matches[2]) {
                fileName = matches[2];
            }
        }
    }
    return fileName;
}