import React from 'react';
import { Grid } from '@mui/material';
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from '@progress/kendo-react-intl';
import { AMWGridFilterToolbarWrapperProps } from '~components/datagrid/types';
import AMWGridFilterToolbar from '~components/datagrid/AMWGridFilterToolbar';
import LocalizationKeys from '~enums/localizationKeys';
import TransactionsFilterPanel from './TransactionsFilterPanel';
import DropDownButton from '~components/controls/DropDownButton';
import TransactionType from '~enums/transactionType';
import ReportContext from '~enums/reportContext';
import ReportButton from '~components/controls/ReportButton';


type TransactionsToolbarProps = {
    canEdit: boolean;
    onCreateClick: (type: string) => void;
    onDeleteClick: () => void;
    canCreate: boolean,
    canDelete: boolean,
} & AMWGridFilterToolbarWrapperProps;

const TransactionsToolbar = (props: TransactionsToolbarProps) => {
    const localization = useLocalization();

    return (
        <AMWGridFilterToolbar {...props} noSidePadding={true} filterPanel={TransactionsFilterPanel}>
            <Grid item className={"ButtonGroup"}>
                <DropDownButton<{id: string; text: string;}>
                    className="Button--bold Button--orange"
                    icon='plus'
                    label={localization.toLanguageString(LocalizationKeys.Shared.Buttons.Create, "%Create")}
                    idRetriever={(item) => {return item.id;}}
                    labelRetriever={(item) => {return item.text;}}
                    onSelect={(item) => {
                        props.onCreateClick(item.id);
                    }}
                    options={Object.keys(TransactionType).map(x => { return { id: x, text: localization.toLanguageString(x, x) }; })}
                    disabled={props.isGridBusy}
                />
            </Grid>

            <Grid item className={"ButtonGroup"}>
                <Button
                    onClick={props.onDeleteClick}
                    disabled={!props.selectedItem || props.isGridBusy || !props.canEdit || !props.canDelete}
                    icon={"delete"}
                    title={localization.toLanguageString(LocalizationKeys.Shared.Buttons.Delete, "%Delete")}
                />
            </Grid>

            <Grid item className={"ButtonGroup"}>
                <Button
                    onClick={() => { alert('Not implemented'); }}
                    disabled={!props.selectedItem || props.isGridBusy}
                    icon={"table-properties"}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Toolbar.SetStatus, "%Set status")}
                />

                <ReportButton
                    reportContext={ReportContext.TransactionsView}
                    disabled={props.isGridBusy}
                />

                <Button
                    onClick={() => { alert('Not implemented'); }}
                    disabled={props.isGridBusy}
                    icon={"calendar"}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Toolbar.Calendar, "%Calendar")}
                />
            </Grid>
        </AMWGridFilterToolbar>
    );
};

export default TransactionsToolbar;