import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { ShowErrorAction } from '~store/infra/errors';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import TenantUserItem from '~models/user/TenantUserItem';


export interface ManagementUsersState {
    isLoading: boolean;
    users: Array<TenantUserItem>;
    count: number;
    skip: number;
    createStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface UsersRequestAction {
    type: 'MANAGEMENT_USERS_REQUEST';
    skip: number;
}
interface UsersReceiveAction {
    type: 'MANAGEMENT_USERS_RECEIVE';
    users: Array<TenantUserItem>;
    count: number;
}
interface UsersErrorAction {
    type: 'MANAGEMENT_USERS_ERROR';
}

interface UsersCreateStartAction {
    type: 'MANAGEMENT_USERS_CREATE_START';
}
interface UsersCreateSendAction {
    type: 'MANAGEMENT_USERS_CREATE_SEND';
}
interface UsersCreateSuccessAction {
    type: 'MANAGEMENT_USERS_CREATE_SUCCESS';
}
interface UsersCreateCancelAction {
    type: 'MANAGEMENT_USERS_CREATE_CANCEL';
}
interface UsersCreateErrorAction {
    type: 'MANAGEMENT_USERS_CREATE_ERROR';
}

interface UsersDeleteStartAction {
    type: 'MANAGEMENT_USERS_DELETE_START';
}
interface UsersDeleteSendAction {
    type: 'MANAGEMENT_USERS_DELETE_SEND';
}
interface UsersDeleteSuccessAction {
    type: 'MANAGEMENT_USERS_DELETE_SUCCESS';
}
interface UsersDeleteCancelAction {
    type: 'MANAGEMENT_USERS_DELETE_CANCEL';
}
interface UsersDeleteErrorAction {
    type: 'MANAGEMENT_USERS_DELETE_ERROR';
}

type KnownAction = UsersRequestAction | UsersReceiveAction | UsersErrorAction
    | UsersCreateStartAction | UsersCreateSendAction
    | UsersCreateSuccessAction | UsersCreateCancelAction
    | UsersCreateErrorAction
    | UsersDeleteStartAction | UsersDeleteSendAction
    | UsersDeleteSuccessAction | UsersDeleteCancelAction
    | UsersDeleteErrorAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestUsers: (filters: FilterList, skip: number, pageSize: number): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementUsers && !appState.managementUsers.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<TenantUserItem>>('/v1/users/search', 'POST', filters, skip, pageSize)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ManagementUsers}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.ManagementUsers}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'MANAGEMENT_USERS_RECEIVE', users: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'MANAGEMENT_USERS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_USERS_REQUEST', skip: skip, });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementUsersState = {
    isLoading: false,
    users: [],
    count: 0,
    skip: 0,
    createStatus: ServerOperationStatus.NONE,
    deleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementUsersState> = (state: ManagementUsersState | undefined, incomingAction: Action): ManagementUsersState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_USERS_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'MANAGEMENT_USERS_RECEIVE':
            return {
                ...state,
                users: action.users,
                isLoading: false,
                count: action.count,
            };
        case 'MANAGEMENT_USERS_ERROR':
            return {
                ...state,
                isLoading: false,
            };
    }

    return state;
};