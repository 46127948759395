const CRMCampaignsGridFields = {
    UniqueId: "uniqueId",
    CampaignName: "campaignName",
    Description: "description",
    CampaignType: "campaignType",
    OwnedByName: "ownedByName",
    CreatedOn: "createdOn",
    Status: "status",
    ExecutionDate: "executionDate",
} as const;

export type CRMCampaignsGridFieldsType = typeof CRMCampaignsGridFields[keyof typeof CRMCampaignsGridFields];

export default CRMCampaignsGridFields;