import React from 'react';

import enumTypes from '~enums/enumType';
import InventoryGridFilters from '~enums/gridFilters/inventoryFilters';
import { PartyType } from '~enums/partyType';
import { FilterHandler } from '~models/filters';

import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';

import FilterContainer from '~components/filtering/FilterContainer';
import FilterItem from '~components/filtering/FilterItem';
import OnDemandDropDown from '~controls/OnDemandDropdown';
import { FilterPanel, FilterPanelProps } from '~components/filtering/FilterPanel';
import OnDemandMultiSelect from '~controls/OnDemandMultiSelect';
import CheckboxWrapper from '~controls/CheckboxWrapper';
import DatePickerWrapper from '~components/controls/DatePickerWrapper';
import { defaultStringMapper } from '~src/utils/dropdownUtils';
import LocalizationKeys from '~enums/localizationKeys';
import TextBoxWrapper from '~components/controls/TextBoxWrapper';


type InventoryItemsFilterPanelOwnProps = FilterPanelProps;

export default class InventoryItemsFilterPanel extends FilterPanel<InventoryItemsFilterPanelOwnProps> {

    public render() {
        const localization = provideLocalizationService(this);

        return (
            <FilterContainer
                applyClassName={'Button--orange'}
                clearClassName={'Button--gray'}
                filterApply={this.props.filterApply}
                filterClear={this.props.filterClear}
            >
                <FilterItem>
                    <TextBoxWrapper
                        id="uniqueId"
                        name={InventoryGridFilters.UniqueId}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.UniqueId, "%Unique ID/Object #")}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.UniqueId)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="locationId"
                        name={InventoryGridFilters.Location}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.LocationId, "%Location ID")}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.Location)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="lot"
                        name={InventoryGridFilters.Lot}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.Lot, "%Lot")}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.Lot)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="sidemark"
                        name={InventoryGridFilters.SideMark}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.Sidemark, "%Side mark/Sale")}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.SideMark)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="description"
                        name={InventoryGridFilters.Description}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.Description, "%Description")}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.Description)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="owner"
                        name={InventoryGridFilters.Owner}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.Owner, "%Owner")}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.Owner)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="client"
                        name={InventoryGridFilters.Client}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.Client, "%Client")}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.Client)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="dealer"
                        name={InventoryGridFilters.Dealer}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.Dealer, "%Dealer")}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.Dealer)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandDropDown
                        url="/v1/parties"
                        urlParams={{partyType: PartyType.Division}}
                        dataItemKey={'partyId'}
                        textField={'code'}
                        id="division"
                        name={InventoryGridFilters.DivisionId}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.Division, "%Division")}
                        value={FilterHandler.getDropDownValue(this.props.filters, InventoryGridFilters.DivisionId, 'partyId', 'code')}
                        pageSize={10}
                        onChange={this.onDropChange.bind(this)}
                        clearable={true}
                        clearAdorned={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="customsEntry"
                        name={InventoryGridFilters.CustomsEntry}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.CustomsEntry, "%Customs entry")}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.CustomsEntry)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="roomDepartment"
                        name={InventoryGridFilters.RoomDepartment}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.RoomDepartment, "%Room/Department")}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.RoomDepartment)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandMultiSelect
                        url="/v1/configuration"
                        urlParams={{ type: enumTypes.CustomsDocStatus }}
                        id="status"
                        name={InventoryGridFilters.CustomsStatus}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.CustomsStatus, "%Customs status")}
                        textField="text"
                        dataItemKey="id"
                        itemMapper={defaultStringMapper}
                        value={FilterHandler.getMultiselectValue(this.props.filters, InventoryGridFilters.CustomsStatus, 'id', 'text')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        autoClose={false}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="startDate"
                        name={InventoryGridFilters.StartDate}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.StartDate, "%Start date")}
                        value={FilterHandler.getDateValue(this.props.filters, InventoryGridFilters.StartDate)}
                        onChange={this.onDateChange.bind(this)}
                        max={FilterHandler.getDateValue(this.props.filters, InventoryGridFilters.EndDate) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="endDate"
                        name={InventoryGridFilters.EndDate}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.EndDate, "%End Date")}
                        value={FilterHandler.getDateValue(this.props.filters, InventoryGridFilters.EndDate)}
                        onChange={this.onDateChange.bind(this)}
                        min={FilterHandler.getDateValue(this.props.filters, InventoryGridFilters.StartDate) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <CheckboxWrapper
                        id="inCareOnly"
                        name={InventoryGridFilters.InCareOnly}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.InCareOnly)}
                        onChange={this.onCheckChange.bind(this)}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.InCareOnly, "%In care only")}
                    />
                </FilterItem>

                <FilterItem>
                    <CheckboxWrapper
                        id="hideParents"
                        name={InventoryGridFilters.HideParent}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.HideParent)}
                        onChange={this.onCheckChange.bind(this)}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.HideParents, "%Hide parents")}
                    />
                </FilterItem>

                <FilterItem>
                    <CheckboxWrapper
                        id="showFullHistory"
                        name={InventoryGridFilters.ShowFullHistory}
                        value={FilterHandler.getValue(this.props.filters, InventoryGridFilters.ShowFullHistory)}
                        onChange={this.onCheckChange.bind(this)}
                        label={localization.toLanguageString(LocalizationKeys.Inventory.Items.Filters.ShowFullHistory, "%Show full history")}
                    />
                </FilterItem>
            </FilterContainer>
        );
    }
}

registerForLocalization(InventoryItemsFilterPanel as React.ComponentClass<any>);