import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';
import { ShowErrorAction } from '../infra/errors';
import { actionCreators as UserActions } from '~store/auth/user';

import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import { SortDescriptor } from "@progress/kendo-data-query";
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import TripSearchItem from '~models/trip/tripSearchItem';
import TariffItem from '~models/tariff/tariffItem';
import ServiceConfig from '~models/serviceConfig/serviceConfig';
import TariffRangeItem from '~models/tariffRange/tariffRangeItem';


export interface TripSearchTripState {
    trips: TripSearchItem[];
    count: number;
    skip: number;
    isLoading: boolean;
}
export interface TripSearchServicesState {
    services: ServiceConfig[];
    isLoading: boolean;
}
export interface TripSearchTariffsState {
    tariffs: TariffItem[];
    isLoading: boolean;
}

export interface TripSearchState {
    isOpen: boolean;
    transactionType: string;
    trips: TripSearchTripState;
    services: TripSearchServicesState;
    tariffs: TripSearchTariffsState;
    onApply: (trip: TripSearchItem, service: ServiceConfig, tariff: TariffItem, range?: TariffRangeItem) => void;
}

export type TripSearchStateProps = TripSearchState;
export type TripSearchDispatchProps = typeof actionCreators;
export type TripSearchProps = TripSearchStateProps & TripSearchDispatchProps;

// -----------------
// ---- ACTIONS ----
// -----------------

interface TripSearchOpenAction {
    type: 'TRIP_SEARCH_OPEN';
    transactionType: string;
    onApply: (trip: TripSearchItem, service: ServiceConfig, tariff: TariffItem, range?: TariffRangeItem) => void;
}
interface TripSearchRequestAction {
    type: 'TRIP_SEARCH_REQUEST_SENT';
    skip: number;
}
interface TripSearchReceiveAction {
    type: 'TRIP_SEARCH_REQUEST_SUCCESS';
    trips: TripSearchItem[];
    count: number;
}
interface TripSearchErrorAction {
    type: 'TRIP_SEARCH_REQUEST_ERROR';
}

interface ServiceSearchRequestAction {
    type: 'TRIP_SEARCH_SERVICES_REQUEST_SENT';
}
interface ServiceSearchReceiveAction {
    type: 'TRIP_SEARCH_SERVICES_REQUEST_SUCCESS';
    services: ServiceConfig[];
}
interface ServiceSearchClearAction {
    type: 'TRIP_SEARCH_SERVICES_CLEAR';
}
interface ServiceSearchErrorAction {
    type: 'TRIP_SEARCH_SERVICES_ERROR';
}

interface TariffSearchRequestAction {
    type: 'TRIP_SEARCH_TARIFFS_REQUEST_SENT';
}
interface TariffSearchReceiveAction {
    type: 'TRIP_SEARCH_TARIFFS_REQUEST_SUCCESS';
    tariffs: TariffItem[];
}
interface TariffSearchClearAction {
    type: 'TRIP_SEARCH_TARIFFS_CLEAR';
}
interface TariffSearchErrorAction {
    type: 'TRIP_SEARCH_TARIFFS_ERROR';
}

interface TripSearchCloseAction {
    type: 'TRIP_SEARCH_CLOSE';
}

type KnownAction = TripSearchOpenAction
    | TripSearchRequestAction | TripSearchReceiveAction | TripSearchErrorAction
    | ServiceSearchRequestAction | ServiceSearchReceiveAction | ServiceSearchClearAction | ServiceSearchErrorAction
    | TariffSearchRequestAction | TariffSearchReceiveAction | TariffSearchClearAction | TariffSearchErrorAction
    | TripSearchCloseAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;

let tripAbortController: AbortController = new AbortController();
let serviceAbortController: AbortController = new AbortController();
let tariffAbortController: AbortController = new AbortController();

export const actionCreators = {
    openSearchDialog: (transactionType: string,
        onApply: (trip: TripSearchItem, service: ServiceConfig, tariff: TariffItem, range?: TariffRangeItem) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.tripSearch) {
            dispatch({ type: 'TRIP_SEARCH_OPEN', transactionType, onApply });
        }
    },
    searchTrips: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.tripSearch) {
            !!tripAbortController && tripAbortController.abort();
            tripAbortController = new AbortController();

            dispatch(UserActions.updateUserPreferences({
                [`${UserPreferencePage.TripSearchTrips}-${UserPreferenceKey.Sorting}`]: sorting,
                [`${UserPreferencePage.TripSearchTrips}-${UserPreferenceKey.PageSize}`]: pageSize,
            }));

            const fullFilters = {...filters, TransactionType: {value: appState.tripSearch.transactionType} }  ;

            FilterHandler.getApiFilterRequestPromise<DataRequest<TripSearchItem>>(`/v1/trips/search`, 'POST', fullFilters, skip, pageSize, sorting, undefined, undefined, tripAbortController)
                .then((result) => {
                    result.data.forEach((item) => {
                        !!item.loadedBy && (item.loadedBy = new Date(item.loadedBy));
                    })
                    dispatch({ type: 'TRIP_SEARCH_REQUEST_SUCCESS', trips: result.data, count: result.count });
                })
                .catch((err) => {
                    dispatch({ type: 'TRIP_SEARCH_REQUEST_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'TRIP_SEARCH_REQUEST_SENT', skip: skip });
        }
    },
    searchServices: (tripId: number | null): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.tripSearch) {
            !!serviceAbortController && serviceAbortController.abort();
            serviceAbortController = new AbortController();

            const fullFilters = {TransactionType: {value: appState.tripSearch.transactionType} };

            if (tripId == null) {
                dispatch({ type: 'TRIP_SEARCH_SERVICES_CLEAR' });
            }
            else {
                FilterHandler.getApiFilterRequestPromise<DataRequest<ServiceConfig>>(`/v1/trips/${tripId}/subservices`, 'GET', fullFilters, undefined, undefined, undefined, undefined, undefined, serviceAbortController)
                    .then((result) => {
                        dispatch({ type: 'TRIP_SEARCH_SERVICES_REQUEST_SUCCESS', services: result.data });
                    })
                    .catch((err) => {
                        dispatch({ type: 'TRIP_SEARCH_SERVICES_ERROR' });
                        dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                    });

                dispatch({ type: 'TRIP_SEARCH_SERVICES_REQUEST_SENT' });
            }
        }
    },
    searchTariffs: (serviceId: number | null): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.tripSearch) {
            !!tariffAbortController && tariffAbortController.abort();
            tariffAbortController = new AbortController();

            const fullFilters = {TransactionType: {value: appState.tripSearch.transactionType} };

            if (serviceId == null) {
                dispatch({ type: 'TRIP_SEARCH_TARIFFS_CLEAR' });
            }
            else {
                FilterHandler.getApiFilterRequestPromise<DataRequest<TariffItem>>(`/v1/serviceconfigs/${serviceId}/tariffs`, 'GET', fullFilters, undefined, undefined, undefined, tariffAbortController)
                    .then((result) => {
                        dispatch({ type: 'TRIP_SEARCH_TARIFFS_REQUEST_SUCCESS', tariffs: result.data });
                    })
                    .catch((err) => {
                        dispatch({ type: 'TRIP_SEARCH_TARIFFS_ERROR' });
                        dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                    });

                dispatch({ type: 'TRIP_SEARCH_TARIFFS_REQUEST_SENT' });
            }
        }
    },
    closeSearchDialog: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.partySearch) {
            dispatch({ type: 'TRIP_SEARCH_CLOSE' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: TripSearchState = {
    isOpen: false,

    transactionType: '',
    trips: {
        trips: [],
        count: 0,
        skip: 0,
        isLoading: false,
    },
    services: {
        services: [],
        isLoading: false,
    },
    tariffs: {
        tariffs: [],
        isLoading: false,
    },
    onApply: () => {console.error('please supply function for apply');}
};

export const reducer: Reducer<TripSearchState> = (state: TripSearchState | undefined, incomingAction: Action): TripSearchState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'TRIP_SEARCH_OPEN':
            return {
                ...state,
                isOpen: true,
                transactionType: action.transactionType,
                trips: {
                    ...state.trips,
                    isLoading: false,
                },
                services: {
                    ...state.services,
                    isLoading: false,
                },
                tariffs: {
                    ...state.tariffs,
                    isLoading: false,
                },
                onApply: action.onApply,
            };
        case 'TRIP_SEARCH_REQUEST_SENT':
            return {
                ...state,
                trips: {
                    ...state.trips,
                    isLoading: true,
                    skip: action.skip,
                },
            };
        case 'TRIP_SEARCH_REQUEST_SUCCESS':
            return {
                ...state,
                trips: {
                    ...state.trips,
                    isLoading: false,
                    trips: action.trips,
                    count: action.count,
                },
                services: {
                    ...state.services,
                    services: [],
                },
                tariffs: {
                    ...state.tariffs,
                    tariffs: [],
                },
            };
        case 'TRIP_SEARCH_REQUEST_ERROR':
            return {
                ...state,
                trips: {
                    ...state.trips,
                    isLoading: false,
                },
            };

        case "TRIP_SEARCH_SERVICES_REQUEST_SENT":
            return {
                ...state,
                services: {
                    ...state.services,
                    isLoading: true,
                },
            };
        case "TRIP_SEARCH_SERVICES_REQUEST_SUCCESS":
            return {
                ...state,
                services: {
                    ...state.services,
                    isLoading: false,
                    services: action.services,
                },
                tariffs: {
                    ...state.tariffs,
                    tariffs: [],
                },
            };
        case "TRIP_SEARCH_SERVICES_CLEAR":
            return {
                ...state,
                services: {
                    ...unloadedState.services,
                },
            };
        case "TRIP_SEARCH_SERVICES_ERROR":
            return {
                ...state,
                services: {
                    ...state.services,
                    isLoading: false,
                },
            };

        case "TRIP_SEARCH_TARIFFS_REQUEST_SENT":
            return {
                ...state,
                tariffs: {
                    ...state.tariffs,
                    isLoading: true,
                },
            };
        case "TRIP_SEARCH_TARIFFS_REQUEST_SUCCESS":
            return {
                ...state,
                tariffs: {
                    ...state.tariffs,
                    isLoading: false,
                    tariffs: action.tariffs,
                },
            };
        case "TRIP_SEARCH_TARIFFS_CLEAR":
            return {
                ...state,
                tariffs: {
                    ...unloadedState.tariffs,
                },
            };
        case "TRIP_SEARCH_TARIFFS_ERROR":
            return {
                ...state,
                tariffs: {
                    ...state.tariffs,
                    isLoading: false,
                },
            };

        case 'TRIP_SEARCH_CLOSE':
            return {
                ...unloadedState,
            };
    }

    return state;
};