import reportContexts from '~enums/reportContext';
import { Grid } from '@mui/material';
import ReportButton from '~components/controls/ReportButton';
import { AMWGridFilterToolbarWrapperProps } from '~components/datagrid/types';
import AMWGridFilterToolbar from '~components/datagrid/AMWGridFilterToolbar';
import AppointmentsFilterPanel from './AppointmentsFilterPanel';


type AppointmentsToolbarProps = AMWGridFilterToolbarWrapperProps;

const AppointmentsToolbar = (props: AppointmentsToolbarProps) => {
    return (
        <AMWGridFilterToolbar {...props} filterPanel={AppointmentsFilterPanel}>
            <div className="spacer"></div>                    
            
            <Grid item className={"ButtonGroup"}>
                <ReportButton reportContext={reportContexts.CRMAppointmentsView} />
            </Grid>
        </AMWGridFilterToolbar>
    );
};

export default AppointmentsToolbar;