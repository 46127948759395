const QuotationStatus = Object.freeze({
    Created: "Enum.QuotationStatus.Created",
    Issued: "Enum.QuotationStatus.Issued",
    Accepted: "Enum.QuotationStatus.Accepted",
    Declined: "Enum.QuotationStatus.Declined",
    Collected: "Enum.QuotationStatus.Collected",
    Underway: "Enum.QuotationStatus.Underway",
    Delivered: "Enum.QuotationStatus.Delivered",
    Completed: "Enum.QuotationStatus.Completed",
    Cancelled: "Enum.QuotationStatus.Cancelled",
    Budgetary: "Enum.QuotationStatus.Budgetary",
});

export default QuotationStatus;