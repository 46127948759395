import reportContexts from '~enums/reportContext';
import { Grid } from '@mui/material';
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization, } from '@progress/kendo-react-intl';
import ReportButton from '~components/controls/ReportButton';
import { AMWGridFilterToolbarWrapperProps } from '~components/datagrid/types';
import AMWGridFilterToolbar from '~components/datagrid/AMWGridFilterToolbar';
import CompaniesFilterPanel from './CompaniesFilterPanel';
import LocalizationKeys from '~enums/localizationKeys';


type CompaniesToolbarProps = {
    onNewCompanyClick: () => void,
} & AMWGridFilterToolbarWrapperProps;

const CompaniesToolbar = (props: CompaniesToolbarProps) => {
    const localization = useLocalization();

    return (
        <AMWGridFilterToolbar {...props} filterPanel={CompaniesFilterPanel}>
            <Grid item className={"ButtonGroup"}>
                <Button
                    fillMode={'flat'}
                    className={"Button--bold Button--orange"}
                    onClick={props.onNewCompanyClick}
                    size={"large"}
                    icon={"plus"}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Companies.Grid.Toolbar.New, "%New company")}
                />
            </Grid>

            <div className="spacer"></div>

            <Grid item className={"ButtonGroup"}>
                <ReportButton reportContext={reportContexts.CRMCompaniesView} />
            </Grid>
        </AMWGridFilterToolbar>
    );
};

export default CompaniesToolbar;