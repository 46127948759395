import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import OperationsCFGridItem from '~models/transaction/operationsCFGridItem';
import { DataRequest } from '~models/dataRequests';
import { FilterData, FilterHandler, FilterList } from '~models/filters';
import { ShowErrorAction } from '~store/infra/errors';
import { SortDescriptor } from "@progress/kendo-data-query";
import TransactionsGridFilters from '~enums/gridFilters/transactionsFilters';


export interface CFTransactionsState {
    isLoading: boolean;
    transactions: Array<OperationsCFGridItem>;
    count: number;
    skip: number;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface TransactionsRequestAction {
    type: 'CF_TRANSACTIONS_REQUEST';
    skip: number;
}
interface TransactionsReceiveAction {
    type: 'CF_TRANSACTIONS_RECEIVE';
    transactions: OperationsCFGridItem[];
    count: number;
}
interface TransactionsErrorAction {
    type: 'CF_TRANSACTIONS_ERROR';
}

// interface ShippingCreateSendAction {
//     type: 'CF_SHIPPING_CREATE_SEND';
// }
// interface ShippingCreateSuccessAction {
//     type: 'CF_SHIPPING_CREATE_SUCCESS';
// }
// interface ShippingCreateErrorAction {
//     type: 'CF_SHIPPING_CREATE_ERROR'
// }

// interface ShippingDeleteStartAction {
//     type: 'CF_SHIPPING_DELETE_START';
// }
// interface ShippingDeleteSendAction {
//     type: 'CF_SHIPPING_DELETE_SEND';
// }
// interface ShippingDeleteSuccessAction {
//     type: 'CF_SHIPPING_DELETE_SUCCESS';
// }
// interface ShippingDeleteCancelAction {
//     type: 'CF_SHIPPING_DELETE_CANCEL';
// }
// interface ShippingDeleteErrorAction {
//     type: 'CF_SHIPPING_DELETE_ERROR';
// }


type KnownAction = TransactionsRequestAction | TransactionsReceiveAction | TransactionsErrorAction
    // | ShippingCreateSendAction | ShippingCreateSuccessAction | ShippingCreateErrorAction
    // | ShippingDeleteStartAction | ShippingDeleteSendAction | ShippingDeleteSuccessAction | ShippingDeleteCancelAction | ShippingDeleteErrorAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestTransactions: (fileId: number, filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.cfTransactions && !appState.cfTransactions.isLoading) {
            let newFilters = {...(filters as FilterList)} as {[key: string]: FilterData};
            let endDateFilter = newFilters[TransactionsGridFilters.EndDate];

            if (!!(endDateFilter?.value)) {
                const dateValue = new Date(new Date(newFilters[TransactionsGridFilters.EndDate].value).toISOString());
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                newFilters[TransactionsGridFilters.EndDate] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<OperationsCFGridItem>>(`/v1/${fileId}/transactions/search`, 'POST', newFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ClientFileTransactions}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ClientFileTransactions}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((trn) => {
                        !!trn.actualDate && (trn.actualDate = new Date(trn.actualDate));
                        !!trn.creationDate && (trn.creationDate = new Date(trn.creationDate));
                        !!trn.scheduledDate && (trn.scheduledDate = new Date(trn.scheduledDate));
                    });
                    dispatch({ type: 'CF_TRANSACTIONS_RECEIVE', transactions: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'CF_TRANSACTIONS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CF_TRANSACTIONS_REQUEST', skip: skip, });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: CFTransactionsState = {
    isLoading: false,
    transactions: [],
    count: 0,
    skip: 0,
    // shipmentCreateStatus: ServerOperationStatus.NONE,
    // shipmentDeleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<CFTransactionsState> = (state: CFTransactionsState | undefined, incomingAction: Action): CFTransactionsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CF_TRANSACTIONS_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'CF_TRANSACTIONS_RECEIVE':
            return {
                ...state,
                transactions: action.transactions,
                count: action.count,
                isLoading: false,
            };
        case 'CF_TRANSACTIONS_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        // case 'CF_SHIPPING_CREATE_SEND':
        //     return {
        //         ...state,
        //         isLoading: true,
        //         shipmentCreateStatus: ServerOperationStatus.INPROGRESS,
        //     };
        // case 'CF_SHIPPING_CREATE_SUCCESS':
        //     return {
        //         ...state,
        //         isLoading: false,
        //         shipmentCreateStatus: ServerOperationStatus.SUCCESS,
        //     };
        // case 'CF_SHIPPING_CREATE_ERROR':
        //     return {
        //         ...state,
        //         isLoading: false,
        //         shipmentCreateStatus: ServerOperationStatus.ERROR,
        //     };

        // case 'CF_SHIPPING_DELETE_START':
        //     return {
        //         ...state,
        //         shipmentDeleteStatus: ServerOperationStatus.READY,
        //     };
        // case 'CF_SHIPPING_DELETE_SEND':
        //     return {
        //         ...state,
        //         shipmentDeleteStatus: ServerOperationStatus.INPROGRESS,
        //     };
        // case 'CF_SHIPPING_DELETE_CANCEL':
        //     return {
        //         ...state,
        //         shipmentDeleteStatus: ServerOperationStatus.NONE,
        //     };
        // case 'CF_SHIPPING_DELETE_SUCCESS':
        //     return {
        //         ...state,
        //         shipmentDeleteStatus: ServerOperationStatus.SUCCESS,
        //     };
        // case 'CF_SHIPPING_DELETE_ERROR':
        //     return {
        //         ...state,
        //         shipmentDeleteStatus: ServerOperationStatus.ERROR,
        //     };
    }

    return state;
};