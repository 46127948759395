import reportContexts from '~enums/reportContext';
import { Grid } from '@mui/material';
import ReportButton from '~components/controls/ReportButton';
import { AMWGridFilterToolbarWrapperProps } from '~components/datagrid/types';
import AMWGridFilterToolbar from '~components/datagrid/AMWGridFilterToolbar';
import TasksFilterPanel from './TasksFilterPanel';


type TasksToolbarProps = AMWGridFilterToolbarWrapperProps;

const TasksToolbar = (props: TasksToolbarProps) => {
    return (
        <AMWGridFilterToolbar {...props} filterPanel={TasksFilterPanel}>
            <div className="spacer"></div>                    
            
            <Grid item className={"ButtonGroup"}>
                <ReportButton reportContext={reportContexts.CRMTasksView} />
            </Grid>
        </AMWGridFilterToolbar>
    );
};

export default TasksToolbar;