import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { apiClientInstance } from '~services/auth/ApiClientInstance';

import TransactionFields from '~enums/fields/transaction';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';
import SetStatusModel from '~models/transaction/setStatusModel';
import OperationsGridItem from '~models/transaction/operationsGridItem';

import { ShowErrorAction } from '~store/infra/errors';
import { SortDescriptor } from "@progress/kendo-data-query";
import OperationGridFilters from '~enums/gridFilters/operationsFilters';


export interface OperationsState {
    isLoading: boolean;
    operations: Array<OperationsGridItem>;
    count: number;
    skip: number;
    updateStatus: ServerOperationStatus;
    setStatusStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface OperationsRequestAction {
    type: 'OPERATIONS_REQUEST';
    skip: number;
}
interface OperationsReceiveAction {
    type: 'OPERATIONS_RECEIVE';
    operations: OperationsGridItem[];
    count: number;
}
interface OperationsErrorAction {
    type: 'OPERATIONS_ERROR';
}

interface OperationsUpdateRequestAction {
    type: 'OPERATIONS_UPDATE_REQUEST';
}
interface OperationsUpdateResultAction {
    type: 'OPERATIONS_UPDATE_RESULT';
    operations: OperationsGridItem[];
}
interface OperationsUpdateErrorAction {
    type: 'OPERATIONS_UPDATE_ERROR';
}

interface OperationsSetStatusStartAction {
    type: 'OPERATIONS_SETSTATUS_START';
}
interface OperationsSetStatusSendAction {
    type: 'OPERATIONS_SETSTATUS_SEND';
}
interface OperationsSetStatusSuccessAction {
    type: 'OPERATIONS_SETSTATUS_SUCCESS';
}
interface OperationsSetStatusCancelAction {
    type: 'OPERATIONS_SETSTATUS_CANCEL';
}
interface OperationsSetStatusErrorAction {
    type: 'OPERATIONS_SETSTATUS_ERROR';
}

type KnownAction = OperationsRequestAction | OperationsReceiveAction | OperationsErrorAction
    | OperationsUpdateRequestAction | OperationsUpdateResultAction | OperationsUpdateErrorAction
    | OperationsSetStatusStartAction | OperationsSetStatusSendAction
    | OperationsSetStatusSuccessAction | OperationsSetStatusCancelAction
    | OperationsSetStatusErrorAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestOperations: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.operations && !appState.operations.isLoading) {
            let opFilters = {...filters};

            if (!!opFilters[OperationGridFilters.EndDate]?.value) {
                const dateValue = new Date(opFilters[OperationGridFilters.EndDate].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                opFilters[OperationGridFilters.EndDate] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<OperationsGridItem>>('/v1/operations/search', 'POST', opFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.Operations}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.Operations}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.Operations}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((ops) => {
                        !!ops.creationDate && (ops.creationDate = new Date(ops.creationDate));
                        !!ops.scheduledDate && (ops.scheduledDate = new Date(ops.scheduledDate));
                        !!ops.actualDate && (ops.actualDate = new Date(ops.actualDate));
                        !!ops.neededBy && (ops.neededBy = new Date(ops.neededBy));
                    });
                    dispatch({ type: 'OPERATIONS_RECEIVE', operations: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'OPERATIONS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'OPERATIONS_REQUEST', skip: skip, });
        }
    },
    updateStatus: (id: number, status: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.operations && !appState.operations.isLoading) {

            apiClientInstance.fetchRequest<FieldUpdateResult<string>>(
                `/v1/operations/${id}`,
                'PATCH',
                { field: TransactionFields.Status, value: status }
            )
                .then(data => {
                    if (!!appState.operations) {
                        let transaction = appState.operations?.operations.find(x => x.transactionOpsId === id);

                        if (!!transaction) {
                            let newOperations = [...appState.operations.operations];

                            newOperations[appState.operations.operations.indexOf(transaction)] = {
                                ...transaction,
                                status: data.value
                            };

                            dispatch({ type: 'OPERATIONS_UPDATE_RESULT', operations: newOperations });
                        }
                    }
                })
                .catch(err => {
                    dispatch({ type: 'OPERATIONS_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'OPERATIONS_UPDATE_REQUEST' });
        }
    },
    updateStaffNr: (id: number, staffNr: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.operations && !appState.operations.isLoading) {

            apiClientInstance.fetchRequest<FieldUpdateResult<number>>(
                `/v1/operations/${id}`,
                'PATCH',
                { field: TransactionFields.StaffNr, value: staffNr }
            )
                .then(data => {
                    if (!!appState.operations) {
                        let transaction = appState.operations?.operations.find(x => x.transactionOpsId === id);

                        if (!!transaction) {
                            let newOperations = [...appState.operations.operations];

                            newOperations[appState.operations.operations.indexOf(transaction)] = {
                                ...transaction,
                                requiredResourcesNumber: data.value
                            };

                            dispatch({ type: 'OPERATIONS_UPDATE_RESULT', operations: newOperations });
                        }
                    }
                })
                .catch(err => {
                    dispatch({ type: 'OPERATIONS_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'OPERATIONS_UPDATE_REQUEST' });
        }
    },
    startSetStatus: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.operations) {
            dispatch({ type: 'OPERATIONS_SETSTATUS_START' });
        }
    },
    submitSetStatus: (transactionIds: Array<number>, data: SetStatusModel):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.operations) {
            apiClientInstance.fetchRequest(
                '/v1/operations/status',
                'POST',
                {
                    transactionIds,
                    ...data
                }
            )
                .then(() => {
                    dispatch({ type: 'OPERATIONS_SETSTATUS_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'OPERATIONS_SETSTATUS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'OPERATIONS_SETSTATUS_SEND' });
        }
    },
    cancelSetStatus: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.operations) {
            dispatch({ type: 'OPERATIONS_SETSTATUS_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: OperationsState = {
    isLoading: false,
    operations: [],
    count: 0,
    skip: 0,
    updateStatus: ServerOperationStatus.NONE,
    setStatusStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<OperationsState> = (state: OperationsState | undefined, incomingAction: Action): OperationsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'OPERATIONS_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'OPERATIONS_RECEIVE':
            return {
                ...state,
                operations: action.operations,
                count: action.count,
                isLoading: false,
            };
        case 'OPERATIONS_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'OPERATIONS_UPDATE_REQUEST':
            return {
                ...state,
                isLoading: true,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'OPERATIONS_UPDATE_RESULT':
            return {
                ...state,
                isLoading: false,
                operations: action.operations,
                updateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'OPERATIONS_UPDATE_ERROR':
            return {
                ...state,
                isLoading: false,
                updateStatus: ServerOperationStatus.ERROR,
            };

        case 'OPERATIONS_SETSTATUS_START':
            return {
                ...state,
                setStatusStatus: ServerOperationStatus.READY,
            };
        case 'OPERATIONS_SETSTATUS_SEND':
            return {
                ...state,
                isLoading: true,
                setStatusStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'OPERATIONS_SETSTATUS_CANCEL':
            return {
                ...state,
                setStatusStatus: ServerOperationStatus.NONE,
            };
        case 'OPERATIONS_SETSTATUS_SUCCESS':
            return {
                ...state,
                isLoading: false,
                setStatusStatus: ServerOperationStatus.SUCCESS,
            };
    }

    return state;
};