import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '~store/ApplicationState';
import * as NewPartyStore from "~store/modals/newParty";
import * as CompanyModalStore from "~store/modals/companyModal";
import * as PartySearchStore from "~store/modals/partySearch";
import { PartySearchDispatchProps } from '~store/modals/partySearch';
import { handlePartyCreateResult } from "~store/modals/newPartyResultHandler";

import LocalizationKeys from "~enums/localizationKeys";
import { NewPartyKeys } from "~enums/newPartyKeys";
import { nonSystemPartyTypes } from "~enums/partyType";
import { ServerOperationStatus } from "~enums/serverOperationStatus";
import NewOpportunityData from "~models/opportunity/newOpportunityData";
import { PartyListItem } from "~models/party/partyListItem";
import { PersonListItem } from "~models/person/personListItem";

import { Button } from "@progress/kendo-react-buttons";
import { provideLocalizationService, registerForLocalization } from "@progress/kendo-react-intl";
import { Close as CloseIcon } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";

import DateTimePickerWrapper from "~components/controls/DateTimePickerWrapper";
import OnDemandDropDown from "~controls/OnDemandDropdown";
import TextBoxWrapper from "~controls/TextBoxWrapper";
import AMWDialog from "~controls/AMWDialog";


type NewOpportunityModalOwnProps = {
    open: boolean;
    onSubmit: (model: NewOpportunityData) => any;
    onClose: () => void;
    createStatus: ServerOperationStatus;
};

type NewOpportunityModalStateProps = NewPartyStore.NewPartyElementStateProps;
type NewOpportunityModalDispatchProps = PartySearchDispatchProps &
    NewPartyStore.NewPartyElementDispatchProps &
    typeof CompanyModalStore.outsideActions;

type NewOpportunityModalProps = NewOpportunityModalOwnProps &
    NewOpportunityModalStateProps &
    NewOpportunityModalDispatchProps;

interface NewOpportunityState {
    companyId: number | null,
    companyName: string,
    contactId: number | null,
    contactName: string,
    description: string,
    relevantTill: Date | null,

    isValid: boolean,
}

class NewOpportunityModal extends React.Component<NewOpportunityModalProps, NewOpportunityState> {
    companyDrop: OnDemandDropDown | null = null;
    contactDrop: OnDemandDropDown | null = null;

    constructor(props: NewOpportunityModalProps) {
        super(props);
        this.state = {
            companyId: null,
            companyName: '',
            contactId: null,
            contactName: '',
            description: '',
            relevantTill: null,

            isValid: true,
        };
    }

    componentDidUpdate() {
        handlePartyCreateResult.apply(this, [NewPartyKeys.NewOpportunityCompany, (result) => {
            this.companyDrop?.resetData();
            this.contactDrop?.resetData();
            this.setState({
                companyId: result.partyId,
                companyName: result.fullName,
                contactId: result.personId,
                contactName: result.personName,
            });
        }]);
    }

    private isFormValid() {
        return this.state.companyId !== null &&
            this.state.contactId !== null &&
            this.state.description !== '' &&
            this.state.relevantTill !== null;
    }

    private onOpportunitySubmit() {
        this.setState({
            isValid: this.isFormValid()
        }, () => {
            if (this.state.isValid) {
                this.props.onSubmit({
                    companyId: this.state.companyId ?? 0,
                    contactId: this.state.contactId ?? 0,
                    description: this.state.description,
                    relevantTill: this.state.relevantTill ?? new Date(),
                });
            }
        });
    }

    private onClose() {
        this.setState({
            companyId: null,
            companyName: '',
            contactId: null,
            contactName: '',
            description: '',
            relevantTill: null,

            isValid: true
        }, () => {
            this.companyDrop?.resetData();
            this.contactDrop?.resetData();
            this.props.onClose();
        });
    }

    render() {
        const localization = provideLocalizationService(this);

        return (
            <AMWDialog
                open={this.props.open}
                onClose={this.onClose}
                titleContent={
                    <React.Fragment>
                        <span>{localization.toLanguageString(LocalizationKeys.CRM.Opportunities.New.Title, "%New opportunity")}</span>

                        <IconButton onClick={this.props.onClose}>
                            <CloseIcon></CloseIcon>
                        </IconButton>
                    </React.Fragment>
                }
                content={
                    <Grid container direction={"column"}>
                        <Grid item className="InputHolder">
                            <OnDemandDropDown
                                ref={(drop) => {
                                    this.companyDrop = drop;
                                }}
                                url="/v1/parties"
                                urlParams={{partyType: nonSystemPartyTypes}}
                                dataItemKey={'partyId'}
                                textField={'fullName'}
                                id="newOpportunityCompany"
                                name="company"
                                label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.New.Company, "%Company")}
                                value={{legalName: this.state.companyName, partyId: this.state.companyId}}
                                pageSize={10}
                                onChange={(event) => {
                                    this.setState({
                                        companyId: (event.value as PartyListItem).partyId,
                                        companyName: (event.value as PartyListItem).legalName,
                                        contactId: null,
                                        contactName: ''
                                    }, () => {
                                        this.contactDrop?.resetData();
                                    })
                                }}
                                options={[
                                    {
                                        icon: 'search',
                                        text: localization.toLanguageString(LocalizationKeys.Shared.Buttons.PartySearch, "%Party search"),
                                        onSelect: () => { alert('Not implemented!'); }
                                    },
                                    {
                                        hidden: true,
                                        text: localization.toLanguageString(LocalizationKeys.Shared.Buttons.Create, "%Create"),
                                        onSelect: () => { this.props.partyCreation.openDialog(NewPartyKeys.NewOpportunityCompany) },
                                    },
                                    {
                                        hidden: true,
                                        text: localization.toLanguageString(LocalizationKeys.Shared.Buttons.CompanyDetails, "%Details"),
                                        onSelect: () => {!!this.state.companyId && this.props.openCompanyModal(this.state.companyId);},
                                        disabled: !this.state.companyId
                                    },
                                ]}
                                valid={this.state.isValid || this.state.companyId !== null}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <OnDemandDropDown
                                ref={(drop) => {
                                    this.contactDrop = drop;
                                }}
                                url={`/v1/parties/${this.state.companyId}/persons`}
                                dataItemKey={'personId'}
                                textField={'fullName'}
                                id="newOpportunityContact"
                                name="contact"
                                label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.New.Contact, "%Contact")}
                                value={{fullName: this.state.contactName, personId: this.state.contactId}}
                                pageSize={10}
                                onChange={(event) => {
                                    this.setState({
                                        contactId: (event.value as PersonListItem).personId,
                                        contactName: (event.value as PersonListItem).fullName,
                                    })
                                }}
                                valid={this.state.isValid || this.state.contactId !== null}
                                disabled={this.state.companyId === null}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <TextBoxWrapper
                                id="newOpportunityDescription"
                                name="description"
                                label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.New.Description, "%Description")}
                                value={this.state.description}
                                onInputChange={(event) => {
                                    this.setState({
                                        description: event.value as string
                                    })
                                }}
                                valid={this.state.isValid || this.state.description !== ''}
                                maxLength={150}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <DateTimePickerWrapper
                                id="newOpportunityRelevantTill"
                                name="relevantTill"
                                label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.New.RelevantTill, "%Relevant till")}
                                value={this.state.relevantTill}
                                onChange={(event) => {
                                    this.setState({
                                        relevantTill: event.value
                                    })
                                }}
                                valid={this.state.isValid || this.state.relevantTill !== null}
                            />
                        </Grid>
                    </Grid>
                }
                actionsContent={
                    <React.Fragment>
                        <Button
                            className={"Button--bold Button--orange"}
                            onClick={this.onOpportunitySubmit.bind(this)}
                            disabled={this.props.createStatus === ServerOperationStatus.INPROGRESS}
                        >
                            {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Create, "%Create")}
                        </Button>
                        <Button
                            onClick={this.onClose.bind(this)}
                            disabled={this.props.createStatus === ServerOperationStatus.INPROGRESS}
                        >
                            {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Cancel, "%Cancel")}
                        </Button>
                    </React.Fragment>
                }
            />
        );
    }
}

registerForLocalization(NewOpportunityModal as React.ComponentClass<any>);

export default connect(
    (state: ApplicationState) : NewOpportunityModalStateProps => {
        return {
            partyCreation: state.newParty,
        };
    },
    (dispatch) : NewOpportunityModalDispatchProps => {
        return {
            partyCreation: {...bindActionCreators({...NewPartyStore.actionCreators}, dispatch)},
            ...bindActionCreators({...PartySearchStore.actionCreators}, dispatch),
            ...bindActionCreators({...CompanyModalStore.outsideActions}, dispatch),
        };
    },
    (stateProps, dispatchProps, ownProps: NewOpportunityModalOwnProps) : NewOpportunityModalProps => {
        return {
            ...ownProps,
            ...stateProps,
            ...dispatchProps,
            partyCreation: {
                ...stateProps.partyCreation,
                ...dispatchProps.partyCreation
            },
        };
    }
)(NewOpportunityModal as any);