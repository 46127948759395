import * as React from 'react';
import Layout from './Layout';
import SuspenseWrapper from './SuspenseWrapper';

type PageWrapperProps = {
    children?: React.ReactNode;
    topBarChildren?: React.ReactElement;
}

export default class PageWrapper extends React.PureComponent<PageWrapperProps> {
    render() {
        return (
            <Layout topBarChildren={this.props.topBarChildren}>
                <SuspenseWrapper>
                    {this.props.children}
                </SuspenseWrapper>
            </Layout>
        );
    }
}