import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal/react';
import GridUtils from '~src/utils/gridUtils';
import { ApplicationState } from '~store/ApplicationState';
import * as CRMStore from '~store/pages/mainViews/crm';
import * as UsersStore from '~store/auth/user';
import * as ScreenStore from '~store/infra/screen';

import CRMDocumentGridFields from '~enums/gridFields/crmDocuments';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import DocumentRecentGridItem from '~models/documentHistory/documentHistoryRecentGridItem';

import {
    GridCellProps,
    GridColumn,
    GridPageChangeEvent,
    GridSortChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { provideLocalizationService, registerForLocalization, } from '@progress/kendo-react-intl';
import { Link } from 'react-router-dom';

import AMWGrid from '~components/datagrid/AMWGrid';
import CustomCell from '~components/datagrid/CustomCell';
import { GridFilterable, GridPageable, GridSortable } from '~components/datagrid/types';
import { GridContainer, GridContainerDispatchProps, GridContainerStateProps } from '~components/infra/GridContainer';
import RecentToolbar from './RecentToolbar';
import ParentObjectUtils from '~src/utils/parentObjectUtils';
import LocalizationKeys from '~enums/localizationKeys';


type DocumentsStateProps = CRMStore.RecentDocumentsState &
    ScreenStore.ScreenState &
    GridFilterable &
    GridPageable &
    GridSortable &
    GridContainerStateProps;

type DocumentsDispatchProps = typeof CRMStore.recentActionCreators &
    GridContainerDispatchProps;

type DocumentsTabProps = DocumentsStateProps & DocumentsDispatchProps;

type DocumentsTabState = {
    containerWidth: number,
};

const minColumnWidth = {
    [CRMDocumentGridFields.CreatedOn]: 160,
    [CRMDocumentGridFields.CreatedByName]: 120,
    [CRMDocumentGridFields.DocTitle]: 150,
    [CRMDocumentGridFields.Filename]: 150,
    [CRMDocumentGridFields.ParentObject]: 100,
};

class DocumentsTab extends GridContainer<DocumentsTabProps, DocumentsTabState>  {
    constructor(props: Readonly<DocumentsTabProps>) {
        super(props);
        this.state = {
            containerWidth: 1,
        };

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.requestDocuments(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);

        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.resize);

        let element = document.querySelector('#DocumentsGridContainer .k-grid-header');
        let tabs = document.querySelector('#CRMTabs');
        let recenttabs = document.querySelector('#CRMRecentTabs');
        !!element && !!tabs && !!recenttabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight + recenttabs.clientHeight}px` );

        this.setState({
            containerWidth: document.getElementById('DocumentsGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    componentDidUpdate(prevProps: DocumentsTabProps) {
        if (!equal(prevProps.topbarHeight, this.props.topbarHeight)) {
            let element = document.querySelector('#DocumentsGridContainer .k-grid-header');
            let tabs = document.querySelector('#CRMTabs');
            let recenttabs = document.querySelector('#CRMRecentTabs');
            !!element && !!tabs && !!recenttabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight + recenttabs.clientHeight}px` );
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("orientationchange", this.resize);
    }

    private resize() {
        this.setState({
            containerWidth: document.getElementById('DocumentsGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    private calculateColumnWidth(field: string) : number | undefined {
        return GridUtils.calculateColumnWidth(
            field,
            this.props.columnWidth,
            this.state.containerWidth,
            minColumnWidth
        );
    }

    private onPageChange = (event: GridPageChangeEvent) => {
        this.props.requestDocuments(this.props.filters, event.page.skip, event.page.take, this.props.sorting);
    }

    private onSortChange = (event: GridSortChangeEvent) => {
        this.props.requestDocuments(this.props.filters, this.props.skip, this.props.pageSize, event.sort);
    }

    private get columns() {
        const localization = provideLocalizationService(this);

        let columns = [
            (
                <GridColumn
                    key={"recentdocuments-createdon"}
                    field={CRMDocumentGridFields.CreatedOn}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Documents.CreatedOn, "%Created on")}
                    format="{0:g}"
                    width={this.calculateColumnWidth(CRMDocumentGridFields.CreatedOn) ?? 160}
                    orderIndex={this.props.columnOrder[CRMDocumentGridFields.CreatedOn]} />
            ),
            (
                <GridColumn
                    key={"recentdocuments-createdby"}
                    field={CRMDocumentGridFields.CreatedByName}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Documents.CreatedBy, "%Created by")}
                    width={this.calculateColumnWidth(CRMDocumentGridFields.CreatedByName)}
                    orderIndex={this.props.columnOrder[CRMDocumentGridFields.CreatedByName]} />
            ),
            (
                <GridColumn
                    key={"recentcommunications-title"}
                    field={CRMDocumentGridFields.DocTitle}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Documents.Title, "%Title")}
                    width={this.calculateColumnWidth(CRMDocumentGridFields.DocTitle)}
                    orderIndex={this.props.columnOrder[CRMDocumentGridFields.DocTitle]} />
            ),
            (
                <GridColumn
                    key={"recentdocuments-filename"}
                    field={CRMDocumentGridFields.Filename}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Documents.Filename, "%Filename")}
                    width={this.calculateColumnWidth(CRMDocumentGridFields.Filename)}
                    orderIndex={this.props.columnOrder[CRMDocumentGridFields.Filename]} />
            ),
            (
                <GridColumn
                    key={"recentdocuments-parent"}
                    field={CRMDocumentGridFields.ParentObject}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Documents.ParentObject, "%Parent object")}
                    cell={(
                        (props: GridCellProps) => {
                            const document = props.dataItem as DocumentRecentGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Link to={ParentObjectUtils.getParentObjectLink(document.parentObjectId, document.parentObjectType)}>
                                        {localization.toLanguageString(document.parentObjectType, document.parentObjectType)}
                                    </Link>
                                </CustomCell>
                            );
                        }
                    )}
                    className={'grid__cell--link'}
                    width={this.calculateColumnWidth(CRMDocumentGridFields.ParentObject)}
                    orderIndex={this.props.columnOrder[CRMDocumentGridFields.ParentObject]} />
            ),
        ];

        return columns;
    }

    render() {
        let gridData = this.props.data;

        let columns = this.columns;

        return (
            <div id={'DocumentsGridContainer'}>
                <AMWGrid
                    sticky={true}
                    scrollable={'scrollable'}

                    data={gridData}
                    total={this.props.count}

                    pageable={{ buttonCount: 4, pageSizes: true }}
                    skip={this.props.skip}
                    pageSize={this.props.pageSize}
                    onPageChange={this.onPageChange.bind(this)}

                    sortable={true}
                    sort={this.props.sorting}
                    onSortChange={this.onSortChange.bind(this)}

                    reorderable={true}
                    onColumnReorder={this.onColumnReorder.bind(this)}

                    resizable={true}
                    onColumnResize={this.onColumnResize.bind(this)}
                >
                    <GridToolbar>
                        <RecentToolbar
                            isGridBusy={this.props.isLoading}
                            filters={this.props.filters}
                            requestData={(filters) => {
                                this.props.requestDocuments(filters, this.props.skip, this.props.pageSize, this.props.sorting);
                            }}
                        />
                    </GridToolbar>

                    {columns}
                </AMWGrid>
            </div>
        );
    }
}

registerForLocalization(DocumentsTab as React.ComponentClass<any>);

export default connect(
    (state: ApplicationState) : DocumentsStateProps => {
        return {
            pageKey: UserPreferencePage.RecentDocuments,
            ...state.crm.recent.documents,
            ...state.screen,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.RecentDocuments}-${UserPreferenceKey.Filters}`] ?? {},
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.RecentDocuments}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.RecentDocuments}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.RecentDocuments}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.RecentDocuments}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        };
    },
    (dispatch) : DocumentsDispatchProps => {
        let crmActions = bindActionCreators({...CRMStore.recentActionCreators}, dispatch);
        let usersActions = bindActionCreators({...UsersStore.actionCreators}, dispatch);

        return {
            ...crmActions,
            ...usersActions
        };
    },
)(DocumentsTab as any);