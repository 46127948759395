import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';
import { apiClientInstance } from '~services/auth/ApiClientInstance';

import { ShowErrorAction } from '~store/infra/errors';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import ExcelFieldFields, { ExcelFieldFieldsType } from '~enums/fields/excelField';

import { SortDescriptor } from "@progress/kendo-data-query";
import { DataRequest } from '~models/dataRequests';
import ExcelFieldGridItem from '~models/excelField/excelFieldGridItem';
import ExcelFieldCreateModel from '~models/excelField/excelFieldCreateModel';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';
import { FilterHandler } from '~models/filters';


export interface ManagementExcelFieldsState {
    isLoading: boolean;
    fields: Array<ExcelFieldGridItem>;
    count: number;
    skip: number;
    updatedField?: ExcelFieldFieldsType;
    createStatus: ServerOperationStatus;
    updateStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface ExcelFieldsRequestAction {
    type: 'MANAGEMENT_EXCELFIELDS_REQUEST';
    skip: number;
}
interface ExcelFieldsReceiveAction {
    type: 'MANAGEMENT_EXCELFIELDS_RECEIVE';
    fields: Array<ExcelFieldGridItem>;
    count: number;
}
interface ExcelFieldsErrorAction {
    type: 'MANAGEMENT_EXCELFIELDS_ERROR';
}

interface ExcelFieldsCreateSendAction {
    type: 'MANAGEMENT_EXCELFIELDS_CREATE_SEND';
}
interface ExcelFieldsCreateSuccessAction {
    type: 'MANAGEMENT_EXCELFIELDS_CREATE_SUCCESS';
    newItem: ExcelFieldGridItem;
}
interface ExcelFieldsCreateErrorAction {
    type: 'MANAGEMENT_EXCELFIELDS_CREATE_ERROR';
}

interface ExcelFieldsUpdateSendAction {
    type: 'MANAGEMENT_EXCELFIELDS_UPDATE_SEND';
    field: ExcelFieldFieldsType;
}
interface ExcelFieldsUpdateSuccessAction {
    type: 'MANAGEMENT_EXCELFIELDS_UPDATE_SUCCESS';
    object: ExcelFieldGridItem;
}
interface ExcelFieldsUpdateErrorAction {
    type: 'MANAGEMENT_EXCELFIELDS_UPDATE_ERROR';
}

interface ExcelFieldsDeleteStartAction {
    type: 'MANAGEMENT_EXCELFIELDS_DELETE_START';
}
interface ExcelFieldsDeleteSendAction {
    type: 'MANAGEMENT_EXCELFIELDS_DELETE_SEND';
}
interface ExcelFieldsDeleteSuccessAction {
    type: 'MANAGEMENT_EXCELFIELDS_DELETE_SUCCESS';
}
interface ExcelFieldsDeleteCancelAction {
    type: 'MANAGEMENT_EXCELFIELDS_DELETE_CANCEL';
}
interface ExcelFieldsDeleteErrorAction {
    type: 'MANAGEMENT_EXCELFIELDS_DELETE_ERROR';
}

type KnownAction = ExcelFieldsRequestAction | ExcelFieldsReceiveAction | ExcelFieldsErrorAction
    | ExcelFieldsCreateSendAction | ExcelFieldsCreateSuccessAction | ExcelFieldsCreateErrorAction
    | ExcelFieldsUpdateSendAction | ExcelFieldsUpdateSuccessAction | ExcelFieldsUpdateErrorAction
    | ExcelFieldsDeleteStartAction | ExcelFieldsDeleteSendAction
    | ExcelFieldsDeleteSuccessAction | ExcelFieldsDeleteCancelAction
    | ExcelFieldsDeleteErrorAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestFields: (sourceId: number | undefined, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExcelFields && !appState.managementExcelFields.isLoading) {
            if (!sourceId) {
                dispatch({ type: 'MANAGEMENT_EXCELFIELDS_RECEIVE', fields: [], count: 0, });
            } else {
                FilterHandler.getApiFilterRequestPromise<DataRequest<ExcelFieldGridItem>>(`/v1/excel-sources/${sourceId}/fields`, 'GET', {}, skip, pageSize, sorting)
                    .then(data => {
                        dispatch(UserActions.updateUserPreferences({
                            [`${UserPreferencePage.ManagementExcelFields}-${UserPreferenceKey.Sorting}`]: sorting,
                            [`${UserPreferencePage.ManagementExcelFields}-${UserPreferenceKey.PageSize}`]: pageSize,
                        }));

                        dispatch({ type: 'MANAGEMENT_EXCELFIELDS_RECEIVE', fields: data.data, count: data.count, });
                    })
                    .catch(err => {
                        dispatch({ type: 'MANAGEMENT_EXCELFIELDS_ERROR' });
                        dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                    });

                dispatch({ type: 'MANAGEMENT_EXCELFIELDS_REQUEST', skip: skip, });
            }
        }
    },
    updateField: (id: number, serverField: ExcelFieldFieldsType, value: any, onSuccess?: () => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExcelFields) {
            apiClientInstance.fetchRequest(
                `v1/excel-fields/${id}`,
                'PATCH',
                { field: serverField, value: value }
            )
                .then((data) => {
                    let source = {...appState.managementExcelFields?.fields.find(x => x.excelFieldConfigId === id) ?? {} as ExcelFieldGridItem};

                    switch (serverField) {
                        case ExcelFieldFields.FieldName:
                            source.fieldName = (data as FieldUpdateResult<string>).value;
                            break;
                        case ExcelFieldFields.FieldType:
                            source.fieldType = (data as FieldUpdateResult<string>).value;
                            break;
                        case ExcelFieldFields.Concatenate:
                            source.concatenate = (data as FieldUpdateResult<boolean>).value;
                            break;
                    }

                    !!onSuccess && onSuccess();

                    dispatch({ type: 'MANAGEMENT_EXCELFIELDS_UPDATE_SUCCESS', object: source, });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_EXCELFIELDS_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_EXCELFIELDS_UPDATE_SEND', field: serverField });
        }
    },
    createField: (sourceId: number, model: ExcelFieldCreateModel, onSuccess?: (result: ExcelFieldGridItem) => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExcelFields) {
            apiClientInstance.fetchRequest<number>(
                `v1/excel-sources/${sourceId}/fields`,
                'POST',
                { ...model }
            )
                .then((data) => {
                    let newItem = {...model, excelFieldConfigId: data,} as ExcelFieldGridItem;

                    !!onSuccess && onSuccess(newItem);

                    dispatch({ type: 'MANAGEMENT_EXCELFIELDS_CREATE_SUCCESS', newItem: newItem });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_EXCELFIELDS_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_EXCELFIELDS_CREATE_SEND' });
        }
    },
    startDeleteField: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExcelFields) {
            dispatch({ type: 'MANAGEMENT_EXCELFIELDS_DELETE_START' });
        }
    },
    submitDeleteField: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExcelFields) {
            apiClientInstance.fetchRequest(
                `/v1/excel-fields/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'MANAGEMENT_EXCELFIELDS_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'MANAGEMENT_EXCELFIELDS_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_EXCELFIELDS_DELETE_SEND' });
        }
    },
    cancelDeleteField: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExcelFields) {
            dispatch({ type: 'MANAGEMENT_EXCELFIELDS_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementExcelFieldsState = {
    isLoading: false,
    fields: [],
    count: 0,
    skip: 0,
    createStatus: ServerOperationStatus.NONE,
    updateStatus: ServerOperationStatus.NONE,
    deleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementExcelFieldsState> = (state: ManagementExcelFieldsState | undefined, incomingAction: Action): ManagementExcelFieldsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_EXCELFIELDS_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'MANAGEMENT_EXCELFIELDS_RECEIVE':
            return {
                ...state,
                fields: action.fields,
                count: action.count,
                isLoading: false,
            };
        case 'MANAGEMENT_EXCELFIELDS_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'MANAGEMENT_EXCELFIELDS_UPDATE_SEND':
            return {
                ...state,
                updatedField: action.field,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_EXCELFIELDS_UPDATE_SUCCESS':
            {
                let list = [...state.fields];
                let index = state.fields.findIndex(x => x.excelFieldConfigId === action.object.excelFieldConfigId);

                list[index] = action.object;

                return {
                    ...state,
                    fields: list,
                    updatedField: undefined,
                    updateStatus: ServerOperationStatus.SUCCESS,
                };
            };
        case 'MANAGEMENT_EXCELFIELDS_UPDATE_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_EXCELFIELDS_CREATE_SEND':
            return {
                ...state,
                createStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_EXCELFIELDS_CREATE_SUCCESS':
            return {
                ...state,
                createStatus: ServerOperationStatus.SUCCESS,
                fields: [...state.fields, action.newItem],
            };
        case 'MANAGEMENT_EXCELFIELDS_CREATE_ERROR':
            return {
                ...state,
                createStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_EXCELFIELDS_DELETE_START':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.READY,
            };
        case 'MANAGEMENT_EXCELFIELDS_DELETE_SEND':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_EXCELFIELDS_DELETE_CANCEL':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.NONE,
            };
        case 'MANAGEMENT_EXCELFIELDS_DELETE_SUCCESS':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'MANAGEMENT_EXCELFIELDS_DELETE_ERROR':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};