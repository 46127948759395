import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';
import { ShowErrorAction } from '../infra/errors';
import { actionCreators as UserActions } from '~store/auth/user';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import { PartyListItem } from '~models/party/partyListItem';
import { SortDescriptor } from "@progress/kendo-data-query";
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';


export interface PartySearchState {
    partyTypes: string[];
    addressTypes: string[];
    onSelectFunction: (id: number, party: PartyListItem) => any;
    parties: PartyListItem[];
    count: number;
    skip: number;
    status: ServerOperationStatus;
}

export type PartySearchStateProps = PartySearchState;
export type PartySearchDispatchProps = typeof actionCreators;
export type PartySearchProps = PartySearchStateProps & PartySearchDispatchProps;

// -----------------
// ---- ACTIONS ----
// -----------------

interface PartySearchOpenAction {
    type: 'PARTY_SEARCH_OPEN';
    partyTypes: string[];
    addressTypes?: string[];
    onSelect: (id: number, party: PartyListItem) => any;
}

interface PartySearchRequestAction {
    type: 'PARTY_SEARCH_REQUEST_SENT';
    skip: number;
}

interface PartySearchReceiveAction {
    type: 'PARTY_SEARCH_REQUEST_SUCCESS';
    parties: any[];
    count: number;
}

interface PartySearchCloseAction {
    type: 'PARTY_SEARCH_CLOSE';
}

interface PartySearchErrorAction {
    type: 'PARTY_SEARCH_ERROR';
}

type KnownAction = PartySearchOpenAction | PartySearchRequestAction
    | PartySearchReceiveAction | PartySearchCloseAction
    | PartySearchErrorAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;

export const actionCreators = {
    openSearchDialog: (onSelect: (id: number, party: PartyListItem) => any, partyTypes: string[], addressTypes?: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.partySearch) {
            dispatch({ type: 'PARTY_SEARCH_OPEN', partyTypes, addressTypes, onSelect });
        }
    },
    searchParties: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.partySearch && appState.partySearch.status !== ServerOperationStatus.INPROGRESS) {
            dispatch(UserActions.updateUserPreferences({
                [`${UserPreferencePage.PartySearch}-${UserPreferenceKey.Sorting}`]: sorting,
                [`${UserPreferencePage.PartySearch}-${UserPreferenceKey.PageSize}`]: pageSize,
            }));

            const fullFilters = {...filters, PartyTypes: {value: appState.partySearch.partyTypes}, PreferredAddressTypes: {value: appState.partySearch.addressTypes} };

            FilterHandler.getApiFilterRequestPromise<DataRequest<PartyListItem>>(`/v1/parties/search`, 'POST', fullFilters, skip, pageSize, sorting)
                .then((result) => {
                    dispatch({ type: 'PARTY_SEARCH_REQUEST_SUCCESS', parties: result.data, count: result.count });
                })
                .catch((err) => {
                    dispatch({ type: 'PARTY_SEARCH_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'PARTY_SEARCH_REQUEST_SENT', skip: skip });
        }
    },
    closeSearchDialog: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.partySearch) {
            dispatch({ type: 'PARTY_SEARCH_CLOSE' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: PartySearchState = {
    partyTypes: [],
    addressTypes: [],
    onSelectFunction: () => {},
    parties: [],
    count: 0,
    skip: 0,
    status: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<PartySearchState> = (state: PartySearchState | undefined, incomingAction: Action): PartySearchState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'PARTY_SEARCH_OPEN':
            return {
                ...state,
                status: ServerOperationStatus.READY,
                partyTypes: action.partyTypes,
                addressTypes: action.addressTypes ?? [],
                onSelectFunction: action.onSelect,
            };
        case 'PARTY_SEARCH_REQUEST_SENT':
            return {
                ...state,
                status: ServerOperationStatus.INPROGRESS,
                skip: action.skip,
            };
        case 'PARTY_SEARCH_REQUEST_SUCCESS':
            return {
                ...state,
                status: ServerOperationStatus.SUCCESS,
                parties: action.parties,
                count: action.count,
            };
        case 'PARTY_SEARCH_CLOSE':
            return {
                ...unloadedState,
            };
        case 'PARTY_SEARCH_ERROR':
            return {
                ...state,
                status: ServerOperationStatus.ERROR,
            };
    }

    return state;
};