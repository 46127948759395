const CRMAppointmentsGridFields = {
    StartDate: "startDate",
    OwnerName: "ownedByName",
    Subject: "subject",
    Priority: "priority",
    Status: "status",
    OpportunityDescription: "opportunityDescription",
    OpportunityCompany: "opportunityCompany",
    OriginalAppointmentSubject: "originalAppointmentSubject",
    FollowupAppointmentSubject: "followupAppointmentSubject",
    HasDocuments: "hasDocuments",
    CreatedOn: "createdOn",
    Details: "details",
} as const;

export type CRMAppointmentsGridFieldsType = typeof CRMAppointmentsGridFields[keyof typeof CRMAppointmentsGridFields];

export default CRMAppointmentsGridFields;