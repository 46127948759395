const TasksGridFields = {
    StartDate: "startDate",
    DueDate: "dueDate",
    OwnerName: "ownedByName",
    Subject: "subject",
    Priority: "priority",
    Status: "status",
    OpportunityDescription: "opportunityDescription",
    OpportunityCompany: "opportunityCompany",
    OpportunityContact: "opportunityContact",
    OriginalTask: "originalTask",
    FollowUpTask: "followUpTask",
    HasDocuments: "hasDocuments",
    CreatedOn: "createdOn",
    Details: "details",
} as const;

export type TasksGridFieldsType = typeof TasksGridFields[keyof typeof TasksGridFields];

export default TasksGridFields;