import reportContexts from '~enums/reportContext';
import { Grid } from '@mui/material';
import ReportButton from '~components/controls/ReportButton';
import { AMWGridFilterToolbarWrapperProps } from '~components/datagrid/types';
import AMWGridFilterToolbar from '~components/datagrid/AMWGridFilterToolbar';
import InventoryItemsFilterPanel from './InventoryItemsFilterPanel';


type InventoryItemsToolbarProps = AMWGridFilterToolbarWrapperProps;

const InventoryItemsToolbar = (props: InventoryItemsToolbarProps) => {
    return (
        <AMWGridFilterToolbar {...props} filterPanel={InventoryItemsFilterPanel}>

            <div className="spacer"></div>
            
            <Grid item className={"ButtonGroup"}>
                <ReportButton reportContext={reportContexts.InventoryView} />
            </Grid>

        </AMWGridFilterToolbar>
    );
};

export default InventoryItemsToolbar;