import React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from '~store/ApplicationState';
import * as TaskModalStore from '~store/modals/taskModal';
import { apiClientInstance } from "~services/auth/ApiClientInstance";
import { defaultStringMapper } from "~src/utils/dropdownUtils";

import EnumType from "~enums/enumType";
import LocalizationKeys from "~enums/localizationKeys";
import { ServerOperationStatus } from "~enums/serverOperationStatus";
import TaskStatus, { TaskStatusType } from "~enums/taskStatus";
import TaskPriority, { TaskPriorityType } from "~enums/taskPriority";
import TaskDetails from "~models/task/taskDetails";

import { Button } from "@progress/kendo-react-buttons";
import { provideIntlService, provideLocalizationService, registerForIntl, registerForLocalization } from "@progress/kendo-react-intl";
import { Input } from "@progress/kendo-react-inputs";
import { Close as CloseIcon } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";

import AMWDialog from "~controls/AMWDialog";
import DateTimePickerWrapper from "~controls/DateTimePickerWrapper";
import DropDownListWrapper from "~controls/DropDownListWrapper";
import OnDemandDropDown from "~controls/OnDemandDropdown";
import TextAreaWrapper from "~controls/TextAreaWrapper";
import TextBoxWrapper from "~controls/TextBoxWrapper";


type TaskModalOwnProps = {
    taskId: number | null;
    open: boolean;
    onClose: () => void;
};

type TaskModalProps = TaskModalOwnProps &
    TaskModalStore.TaskModalState &
    typeof TaskModalStore.actionCreators;

interface TaskModalState {
    ownedById: number | null;
    ownedByName: string;
    createdDate: Date | null;
    startDate: Date | null;
    dueDate: Date | null;
    reminderDate: Date | null;
    taskType: string;
    taskTypeName: string;
    subject: string;
    details: string;
    clientFeedback: string;
    status: string;
    priority: string;

    isValid: boolean,
    closeOnSuccess: boolean,
}

class TaskModal extends React.Component<TaskModalProps, TaskModalState> {

    constructor(props: TaskModalProps) {
        super(props);
        this.state = {
            ownedById: null,
            ownedByName: '',
            createdDate: null,
            startDate: null,
            dueDate: null,
            reminderDate: null,
            taskType: '',
            taskTypeName: '',
            subject: '',
            details: '',
            clientFeedback: '',
            status: '',
            priority: '',

            isValid: true,
            closeOnSuccess: false,
        };
    }

    componentDidUpdate(prevProps: TaskModalProps) {
        if(!!this.props.taskId && this.props.taskId !== prevProps.taskId) {
            this.requestData(this.props.taskId);
        }

        if(this.props.updateStatus !== prevProps.updateStatus &&
            this.props.updateStatus === ServerOperationStatus.SUCCESS &&
            this.state.closeOnSuccess) {
            this.props.onClose();
        }
    }

    private requestData(id: number) {
        const localization = provideLocalizationService(this);

        apiClientInstance.fetchRequest<TaskDetails>(`/v1/tasks/${id}`)
            .then((data) => {
                data.createdDate = new Date(data.createdDate);
                data.startDate = new Date(data.startDate);
                data.dueDate = new Date(data.dueDate);
                data.reminderDate = new Date(data.reminderDate);

                this.setState({
                    ...data,
                    taskTypeName: localization.toLanguageString(data.taskType, data.taskType),
                    isValid: true,
                    closeOnSuccess: false,
                });
            })
    }

    private isFormValid() {
        return !!this.state.ownedById &&
            !!this.state.startDate && !!this.state.dueDate && !!this.state.reminderDate &&
            this.state.taskType !== '' && this.state.subject !== '' &&
            this.state.status !== '' && this.state.priority !== '';
    }

    private onSubmit(close: boolean) {
        this.setState({
            closeOnSuccess: close,
            isValid: this.isFormValid()
        }, () => {
            if (!!this.props.taskId && this.state.isValid) {
                this.props.updateTask({
                    taskId: this.props.taskId,
                    ownedById: this.state.ownedById ?? 0,
                    startDate: this.state.startDate ?? new Date(),
                    dueDate: this.state.dueDate ?? new Date(),
                    reminderDate: this.state.reminderDate ?? new Date(),
                    taskType: this.state.taskType,
                    subject: this.state.subject,
                    details: this.state.details,
                    clientFeedback: this.state.clientFeedback,
                    status: this.state.status,
                    priority: this.state.priority,
                });
            }
        });
    }

    private onClose() {
        this.setState({
            ownedById: null,
            ownedByName: '',
            startDate: null,
            dueDate: null,
            reminderDate: null,
            taskType: '',
            subject: '',
            details: '',
            clientFeedback: '',
            status: '',
            priority: '',

            isValid: true
        }, () => {
            this.props.onClose();
        });
    }

    render() {
        const localization = provideLocalizationService(this);
        const intl = provideIntlService(this);

        return (
            <AMWDialog
                open={this.props.open}
                onClose={this.onClose.bind(this)}
                titleContent={
                    <React.Fragment>
                        <span>{localization.toLanguageString(LocalizationKeys.Tasks.TaskForm.Title, "%Task form")}</span>

                        <IconButton onClick={this.props.onClose}>
                            <CloseIcon></CloseIcon>
                        </IconButton>
                    </React.Fragment>
                }
                content={
                    <Grid container direction={"column"}>
                        <Grid item className="InputHolder">
                            <OnDemandDropDown
                                url="/v1/persons"//todo Filter by proper permissions
                                // urlParams={{partyType: [PartyType.Division]}}
                                dataItemKey={'partyId'}
                                textField={'legalName'}
                                id="taskFormOwnedBy"
                                name="taskFormOwnedBy"
                                label={localization.toLanguageString(LocalizationKeys.Tasks.TaskForm.OwnedBy, "%Owned by")}
                                value={{legalName: this.state.ownedByName, partyId: this.state.ownedById}}
                                pageSize={10}
                                onChange={(event) => {
                                    this.setState({
                                        ownedById: event.value.id,
                                        ownedByName: event.value.text,
                                    });
                                }}
                                valid={this.state.isValid || this.state.ownedById !== null}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <Input
                                id="taskFormCreatedDate"
                                name="taskFormCreatedDate"
                                label={localization.toLanguageString(LocalizationKeys.Tasks.TaskForm.CreatedDate, "%Created")}
                                value={!!this.state.createdDate ? intl.formatDate(this.state.createdDate, 'g') : ''}
                                readOnly={true}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <OnDemandDropDown
                                url="/v1/configuration"
                                urlParams={{ type: EnumType.TaskType }}
                                dataItemKey={'id'}
                                textField={'text'}
                                itemMapper={defaultStringMapper}
                                localizeText={true}
                                id="taskFormType"
                                name="taskFormType"
                                label={localization.toLanguageString(LocalizationKeys.Tasks.TaskForm.Type, "%Type")}
                                value={{text: this.state.taskType, id: `${this.state.taskType}`}}
                                pageSize={10}
                                onChange={(event) => {
                                    this.setState({
                                        taskType: event.value.id,
                                        taskTypeName: event.value.text,
                                    });
                                }}
                                valid={this.state.isValid || this.state.taskType !== ''}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <TextBoxWrapper
                                id="taskFormSubject"
                                name="taskFormSubject"
                                label={localization.toLanguageString(LocalizationKeys.Tasks.TaskForm.Subject, "%Subject")}
                                value={this.state.subject}
                                onInputChange={(event) => {
                                    this.setState({
                                        subject: event.value as string
                                    });
                                }}
                                maxLength={150}
                                valid={this.state.isValid || this.state.subject !== ''}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <TextAreaWrapper
                                id="taskFormDetails"
                                name="taskFormDetails"
                                label={localization.toLanguageString(LocalizationKeys.Tasks.TaskForm.Details, "%Details")}
                                value={this.state.details}
                                maxLength={2048}
                                onInputChange={(event) => {
                                    this.setState({
                                        details: event.value as string
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <TextAreaWrapper
                                id="taskFormClientFeedback"
                                name="taskFormClientFeedback"
                                label={localization.toLanguageString(LocalizationKeys.Tasks.TaskForm.ClientFeedback, "%Client feedback")}
                                value={this.state.clientFeedback}
                                onInputChange={(event) => {
                                    this.setState({
                                        clientFeedback: event.value as string
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <DropDownListWrapper
                                id="taskFormStatus"
                                name="taskFormStatus"
                                label={localization.toLanguageString(LocalizationKeys.Tasks.TaskForm.Status, "%Status")}
                                dataItemKey='id'
                                textField="text"
                                data={Object.values(TaskStatus).map(x => {
                                    return {
                                        id: x,
                                        text: localization.toLanguageString(x, x)
                                    };
                                })}
                                value={!!this.state.status ? {
                                    id: this.state.status,
                                    text: localization.toLanguageString(this.state.status, this.state.status)
                                } : null}
                                onChange={(event) => {
                                    this.setState({
                                        status: (event.value as any).id as TaskStatusType
                                    });
                                }}
                                valid={this.state.isValid || !!this.state.status} />
                        </Grid>

                        <Grid item className="InputHolder">
                            <DropDownListWrapper
                                id="taskFormPriority"
                                name="taskFormPriority"
                                label={localization.toLanguageString(LocalizationKeys.Tasks.TaskForm.Priority, "%Priority")}
                                dataItemKey='id'
                                textField="text"
                                data={Object.values(TaskPriority).map(x => {
                                    return {
                                        id: x,
                                        text: localization.toLanguageString(x, x)
                                    };
                                })}
                                value={!!this.state.priority ? {
                                    id: this.state.priority,
                                    text: localization.toLanguageString(this.state.priority, this.state.priority)
                                } : null}
                                onChange={(event) => {
                                    this.setState({
                                        priority: (event.value as any).id as TaskPriorityType
                                    });
                                }}
                                valid={this.state.isValid || !!this.state.priority} />
                        </Grid>

                        <Grid item className="InputHolder">
                            <DateTimePickerWrapper
                                id="taskFormStartDate"
                                name="taskFormStartDate"
                                label={localization.toLanguageString(LocalizationKeys.Tasks.TaskForm.StartDate, "%Start date")}
                                value={this.state.startDate}
                                onChange={(event) => {
                                    this.setState({
                                        startDate: event.value
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <DateTimePickerWrapper
                                id="taskFormDueDate"
                                name="taskFormDueDate"
                                label={localization.toLanguageString(LocalizationKeys.Tasks.TaskForm.DueDate, "%Due date")}
                                value={this.state.dueDate}
                                onChange={(event) => {
                                    this.setState({
                                        dueDate: event.value
                                    });
                                }}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <DateTimePickerWrapper
                                id="taskFormReminderDate"
                                name="taskFormReminderDate"
                                label={localization.toLanguageString(LocalizationKeys.Tasks.TaskForm.ReminderDate, "%Reminder date")}
                                value={this.state.reminderDate}
                                onChange={(event) => {
                                    this.setState({
                                        reminderDate: event.value
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                }
                actionsContent={
                    <React.Fragment>
                        <Button
                            className={"Button--bold Button--orange"}
                            onClick={() => {this.onSubmit.apply(this, [false])}}
                            disabled={this.props.updateStatus === ServerOperationStatus.INPROGRESS}
                        >
                            {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Save, "%Save")}
                        </Button>

                        <Button
                            onClick={() => {this.onSubmit.apply(this, [true])}}
                            disabled={this.props.updateStatus === ServerOperationStatus.INPROGRESS}
                        >
                            {localization.toLanguageString(LocalizationKeys.Shared.Buttons.SaveAndClose, "%Save and close")}
                        </Button>

                        <Button
                            onClick={this.onClose.bind(this)}
                            disabled={this.props.updateStatus === ServerOperationStatus.INPROGRESS}
                        >
                            {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Cancel, "%Cancel")}
                        </Button>
                    </React.Fragment>
                }
            />
        )
    }
}

registerForLocalization(TaskModal as React.ComponentClass<any>);
registerForIntl(TaskModal as React.ComponentClass<any>);

export default connect(
    (state: ApplicationState, ownProps: TaskModalOwnProps) => {
        return {
            ...ownProps,
            ...state.taskModal,
        };
    },
    {
        ...TaskModalStore.actionCreators
    }
)(TaskModal as any);