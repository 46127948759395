import React from "react";
import * as UsersStore from '~store/auth/user';
import { GridColumnReorderEvent, GridColumnResizeEvent } from "@progress/kendo-react-grid";
import { UserPreferenceKey } from "~enums/userPreferenceKeys";
import { GridOrderable, GridResizable } from "~components/datagrid/types";

export type GridContainerStateProps = {pageKey: string;} &
    GridOrderable &
    GridResizable;
export type GridContainerDispatchProps = typeof UsersStore.actionCreators;

export type GridContainerProps = GridContainerStateProps & GridContainerDispatchProps;


export class GridContainer<T1 extends GridContainerProps, S = {}, SS = any> extends React.Component<T1, S, SS> {
    resizeTimeout?: NodeJS.Timeout;

    onColumnReorder(event: GridColumnReorderEvent) {
        let order : {[key:string]: number | undefined} = {};
        event.columns.forEach((col) => {
            !!col.field && (order[col.field] = col.orderIndex);
        });
        this.props.updateUserPreferences({[`${this.props.pageKey}-${UserPreferenceKey.Order}`]: order});
    }

    onColumnResize(event: GridColumnResizeEvent) {
        !!this.resizeTimeout && clearTimeout(this.resizeTimeout);

        this.resizeTimeout = setTimeout(() => {
            let result : {[key:string]:number|undefined} = {...this.props.columnWidth};

            let containerWidth = event.target.element?.getBoundingClientRect().width ?? 1;

            event.columns.forEach(col => {
                if (event.columns.indexOf(col) === event.index || !result[col.field ?? '']) {
                    let percentWidth =
                        (event.columns.indexOf(col) === event.index ?
                            event.newWidth :
                            Number(col.width ?? 1)
                        ) /
                        containerWidth * 100;

                    result[col.field ?? ''] = percentWidth;
                }
            });
            this.props.updateUserPreferences({
                [`${this.props.pageKey}-${UserPreferenceKey.ColumnWidth}`]: result,
            });
        }, 1000);
    }
}