import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal/react';
import GridUtils from '~src/utils/gridUtils';
import { ApplicationState } from '~store/ApplicationState';
import * as CRMStore from '~store/pages/mainViews/crm';
import * as UsersStore from '~store/auth/user';
import * as ScreenStore from '~store/infra/screen';

import CRMTasksGridFields from '~enums/gridFields/tasks';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import {
    GridColumn,
    GridPageChangeEvent,
    GridSortChangeEvent,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { provideLocalizationService, registerForLocalization, } from '@progress/kendo-react-intl';

import AMWGrid from '~components/datagrid/AMWGrid';
import { GridFilterable, GridPageable, GridSortable } from '~components/datagrid/types';
import { GridContainer, GridContainerDispatchProps, GridContainerStateProps } from '~components/infra/GridContainer';
import RecentToolbar from './RecentToolbar';
import LocalizationKeys from '~enums/localizationKeys';


type TasksStateProps = CRMStore.RecentTasksState &
    ScreenStore.ScreenState &
    GridFilterable &
    GridPageable &
    GridSortable &
    GridContainerStateProps;

type TasksDispatchProps = typeof CRMStore.recentActionCreators &
    GridContainerDispatchProps;

type TasksTabProps = TasksStateProps & TasksDispatchProps;

type TasksTabState = {
    containerWidth: number,
};

const minColumnWidth = {
    [CRMTasksGridFields.CreatedOn]: 160,
    [CRMTasksGridFields.OwnerName]: 100,
    [CRMTasksGridFields.OpportunityCompany]: 150,
    [CRMTasksGridFields.Subject]: 100,
    [CRMTasksGridFields.Details]: 150,
};

class TasksTab extends GridContainer<TasksTabProps, TasksTabState>  {
    constructor(props: Readonly<TasksTabProps>) {
        super(props);
        this.state = {
            containerWidth: 1,
        };

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.requestTasks(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);

        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.resize);

        let element = document.querySelector('#TasksGridContainer .k-grid-header');
        let tabs = document.querySelector('#CRMTabs');
        let recenttabs = document.querySelector('#CRMRecentTabs');
        !!element && !!tabs && !!recenttabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight + recenttabs.clientHeight}px` );

        this.setState({
            containerWidth: document.getElementById('TasksGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    componentDidUpdate(prevProps: TasksTabProps) {
        if (!equal(prevProps.topbarHeight, this.props.topbarHeight)) {
            let element = document.querySelector('#TasksGridContainer .k-grid-header');
            let tabs = document.querySelector('#CRMTabs');
            let recenttabs = document.querySelector('#CRMRecentTabs');
            !!element && !!tabs && !!recenttabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight + recenttabs.clientHeight}px` );
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("orientationchange", this.resize);
    }

    private resize() {
        this.setState({
            containerWidth: document.getElementById('TasksGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    private calculateColumnWidth(field: string) : number | undefined {
        return GridUtils.calculateColumnWidth(
            field,
            this.props.columnWidth,
            this.state.containerWidth,
            minColumnWidth
        );
    }

    private onPageChange = (event: GridPageChangeEvent) => {
        this.props.requestTasks(this.props.filters, event.page.skip, event.page.take, this.props.sorting);
    }

    private onSortChange = (event: GridSortChangeEvent) => {
        this.props.requestTasks(this.props.filters, this.props.skip, this.props.pageSize, event.sort);
    }

    private get columns() {
        const localization = provideLocalizationService(this);

        let columns = [
            (
                <GridColumn
                    key={"recenttasks-createdon"}
                    field={CRMTasksGridFields.CreatedOn}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Tasks.CreatedOn, "%Created on")}
                    format="{0:g}"
                    width={this.calculateColumnWidth(CRMTasksGridFields.CreatedOn) ?? 160}
                    orderIndex={this.props.columnOrder[CRMTasksGridFields.CreatedOn]} />
            ),
            (
                <GridColumn
                    key={"recenttasks-ownedby"}
                    field={CRMTasksGridFields.OwnerName}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Tasks.OwnedBy, "%Owned by")}
                    width={this.calculateColumnWidth(CRMTasksGridFields.OwnerName)}
                    orderIndex={this.props.columnOrder[CRMTasksGridFields.OwnerName]} />
            ),
            (
                <GridColumn
                    key={"recenttasks-partyname"}
                    field={CRMTasksGridFields.OpportunityCompany}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Tasks.CompanyName, "%Company name")}
                    width={this.calculateColumnWidth(CRMTasksGridFields.OpportunityCompany)}
                    orderIndex={this.props.columnOrder[CRMTasksGridFields.OpportunityCompany]} />
            ),
            (
                <GridColumn
                    key={"recenttasks-subject"}
                    field={CRMTasksGridFields.Subject}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Tasks.Subject, "%Subject")}
                    width={this.calculateColumnWidth(CRMTasksGridFields.Subject)}
                    orderIndex={this.props.columnOrder[CRMTasksGridFields.Subject]} />
            ),
            (
                <GridColumn
                    key={"recenttasks-details"}
                    field={CRMTasksGridFields.Details}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Recent.Tasks.Details, "%Details")}
                    width={this.calculateColumnWidth(CRMTasksGridFields.Details)}
                    orderIndex={this.props.columnOrder[CRMTasksGridFields.Details]} />
            ),
        ];

        return columns;
    }

    render() {
        let gridData = this.props.data;

        let columns = this.columns;

        return (
            <div id={'TasksGridContainer'}>
                <AMWGrid
                    sticky={true}
                    scrollable={'scrollable'}

                    data={gridData}
                    total={this.props.count}

                    pageable={{ buttonCount: 4, pageSizes: true }}
                    skip={this.props.skip}
                    pageSize={this.props.pageSize}
                    onPageChange={this.onPageChange.bind(this)}

                    sortable={true}
                    sort={this.props.sorting}
                    onSortChange={this.onSortChange.bind(this)}

                    reorderable={true}
                    onColumnReorder={this.onColumnReorder.bind(this)}

                    resizable={true}
                    onColumnResize={this.onColumnResize.bind(this)}
                >
                    <GridToolbar>
                        <RecentToolbar
                            isGridBusy={this.props.isLoading}
                            filters={this.props.filters}
                            requestData={(filters) => {
                                this.props.requestTasks(filters, this.props.skip, this.props.pageSize, this.props.sorting);
                            }}
                        />
                    </GridToolbar>

                    {columns}
                </AMWGrid>
            </div>
        );
    }
}

registerForLocalization(TasksTab as React.ComponentClass<any>);

export default connect(
    (state: ApplicationState) : TasksStateProps => {
        return {
            pageKey: UserPreferencePage.RecentTasks,
            ...state.crm.recent.tasks,
            ...state.screen,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.RecentTasks}-${UserPreferenceKey.Filters}`] ?? {},
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.RecentTasks}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.RecentTasks}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.RecentTasks}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.RecentTasks}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        };
    },
    (dispatch) : TasksDispatchProps => {
        let crmActions = bindActionCreators({...CRMStore.recentActionCreators}, dispatch);
        let usersActions = bindActionCreators({...UsersStore.actionCreators}, dispatch);

        return {
            ...crmActions,
            ...usersActions
        };
    },
)(TasksTab as any);