const CompanyPersonFields = Object.freeze({
    Salutation: "CompanyPersonField.Salutation",
    FirstName: "CompanyPersonField.FirstName",
    LastName: "CompanyPersonField.LastName",
    PersonRole: "CompanyPersonField.PersonRole",
    IsGeneralType: "CompanyPersonField.IsGeneralType",
    IsMailingType: "CompanyPersonField.IsMailingType",
    IsBillingType: "CompanyPersonField.IsBillingType",
    IsShippingType: "CompanyPersonField.IsShippingType",
    Address: "CompanyPersonField.Address",
    Contact: "CompanyPersonField.Contact",
    Email: "CompanyPersonField.Email",
    Phone: "CompanyPersonField.Phone",
    MobilePhone: "CompanyPersonField.MobilePhone",
    Fax: "CompanyPersonField.Fax",
});

export type CompanyPersonFieldsType = typeof CompanyPersonFields[keyof typeof CompanyPersonFields];

export default CompanyPersonFields;