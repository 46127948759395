import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { ShowErrorAction } from '~store/infra/errors';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import { DataRequest } from '~models/dataRequests';
import { SortDescriptor } from "@progress/kendo-data-query";
import { FilterHandler, FilterList } from '~models/filters';

import ManagementCompanyGridItem from '~models/party/managementCompanyGridItem';


export interface ManagementCompaniesState {
    isLoading: boolean;
    companies: Array<ManagementCompanyGridItem>;
    count: number;
    skip: number;

    createStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface CompaniesRequestAction {
    type: 'MANAGEMENT_COMPANIES_REQUEST';
    skip: number;
}
interface CompaniesReceiveAction {
    type: 'MANAGEMENT_COMPANIES_RECEIVE';
    companies: Array<ManagementCompanyGridItem>;
    count: number;
}
interface CompaniesErrorAction {
    type: 'MANAGEMENT_COMPANIES_ERROR';
}

interface CompaniesCreateStartAction {
    type: 'MANAGEMENT_COMPANIES_CREATE_START';
}
interface CompaniesCreateSendAction {
    type: 'MANAGEMENT_COMPANIES_CREATE_SEND';
}
interface CompaniesCreateSuccessAction {
    type: 'MANAGEMENT_COMPANIES_CREATE_SUCCESS';
}
interface CompaniesCreateCancelAction {
    type: 'MANAGEMENT_COMPANIES_CREATE_CANCEL';
}
interface CompaniesCreateErrorAction {
    type: 'MANAGEMENT_COMPANIES_CREATE_ERROR';
}

interface CompaniesDeleteStartAction {
    type: 'MANAGEMENT_COMPANIES_DELETE_START';
}
interface CompaniesDeleteSendAction {
    type: 'MANAGEMENT_COMPANIES_DELETE_SEND';
}
interface CompaniesDeleteSuccessAction {
    type: 'MANAGEMENT_COMPANIES_DELETE_SUCCESS';
}
interface CompaniesDeleteCancelAction {
    type: 'MANAGEMENT_COMPANIES_DELETE_CANCEL';
}
interface CompaniesDeleteErrorAction {
    type: 'MANAGEMENT_COMPANIES_DELETE_ERROR';
}

type KnownAction = CompaniesRequestAction | CompaniesReceiveAction | CompaniesErrorAction
    | CompaniesCreateStartAction | CompaniesCreateSendAction
    | CompaniesCreateSuccessAction | CompaniesCreateCancelAction
    | CompaniesCreateErrorAction
    | CompaniesDeleteStartAction | CompaniesDeleteSendAction
    | CompaniesDeleteSuccessAction | CompaniesDeleteCancelAction
    | CompaniesDeleteErrorAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestCompanies: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementCompanies && !appState.managementCompanies.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<ManagementCompanyGridItem>>('/v1/parties/management', 'POST', filters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ManagementCompanies}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.ManagementCompanies}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ManagementCompanies}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'MANAGEMENT_COMPANIES_RECEIVE', companies: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'MANAGEMENT_COMPANIES_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_COMPANIES_REQUEST', skip: skip, });
        }
    },
    startDeleteCompany: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementCompanies) {
            dispatch({ type: 'MANAGEMENT_COMPANIES_DELETE_START' });
        }
    },
    deleteCompany: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementCompanies) {
            apiClientInstance.fetchRequest(
                `/v1/parties/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'MANAGEMENT_COMPANIES_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'MANAGEMENT_COMPANIES_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_COMPANIES_DELETE_SEND' });
        }
    },
    cancelDeleteCompany: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementCompanies) {
            dispatch({ type: 'MANAGEMENT_COMPANIES_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementCompaniesState = {
    isLoading: false,
    companies: [],
    count: 0,
    skip: 0,
    createStatus: ServerOperationStatus.NONE,
    deleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementCompaniesState> = (state: ManagementCompaniesState | undefined, incomingAction: Action): ManagementCompaniesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_COMPANIES_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'MANAGEMENT_COMPANIES_RECEIVE':
            return {
                ...state,
                companies: action.companies,
                count: action.count,
                isLoading: false,
            };
        case 'MANAGEMENT_COMPANIES_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'MANAGEMENT_COMPANIES_DELETE_START':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.READY,
            };
        case 'MANAGEMENT_COMPANIES_DELETE_SEND':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_COMPANIES_DELETE_CANCEL':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.NONE,
            };
        case 'MANAGEMENT_COMPANIES_DELETE_SUCCESS':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'MANAGEMENT_COMPANIES_DELETE_ERROR':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};