import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import PackageReusableGridItem from '~models/package/packageReusableGridItem';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import { ShowErrorAction } from '~store/infra/errors';
import { SortDescriptor } from "@progress/kendo-data-query";


export interface ReusableCratesState {
    isLoading: boolean;
    crates: Array<PackageReusableGridItem>;
    count: number;
    skip: number;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface ReusableCratesRequestAction {
    type: 'REUSABLECRATES_REQUEST';
    skip: number;
}

interface ReusableCratesReceiveAction {
    type: 'REUSABLECRATES_RECEIVE';
    crates: PackageReusableGridItem[];
    count: number;
}

interface ReusableCratesErrorAction {
    type: 'REUSABLECRATES_ERROR';
}

type KnownAction = ReusableCratesRequestAction | ReusableCratesReceiveAction
    | ReusableCratesErrorAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestCrates: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.reusableCrates && !appState.reusableCrates.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<PackageReusableGridItem>>('/v1/packages/reusable', 'POST', filters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ReusableCrates}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.ReusableCrates}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ReusableCrates}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'REUSABLECRATES_RECEIVE', crates: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'REUSABLECRATES_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'REUSABLECRATES_REQUEST', skip: skip });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ReusableCratesState = {
    isLoading: false,
    crates: [],
    count: 0,
    skip: 0,
 };

export const reducer: Reducer<ReusableCratesState> = (state: ReusableCratesState | undefined, incomingAction: Action): ReusableCratesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REUSABLECRATES_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'REUSABLECRATES_RECEIVE':
            return {
                ...state,
                crates: action.crates,
                count: action.count,
                isLoading: false,
            };
        case 'REUSABLECRATES_ERROR':
            return {
                ...state,
                isLoading: false,
            };
    }

    return state;
};