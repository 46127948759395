import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { ShowErrorAction } from '~store/infra/errors';

import ConfigItemFields, { ConfigItemFieldsType } from '~enums/fields/managementConfigItem';
import ManagementConfigurationFilters from '~enums/gridFilters/managementConfiguration';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import ConfigItemGridItem from '~models/configItem/configItemGridItem';
import { DataRequest } from '~models/dataRequests';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';
import { FilterHandler, FilterList } from '~models/filters';
import ConfigItemCreateModel from '~models/configItem/configItemCreateModel';
import { PartyListItem } from '~models/party/partyListItem';

import { SortDescriptor } from "@progress/kendo-data-query";


export interface ManagementConfigurationState {
    isLoading: boolean;
    items: Array<ConfigItemGridItem>;
    count: number;
    skip: number;
    updatedField?: ConfigItemFieldsType;
    createStatus: ServerOperationStatus;
    updateStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface ConfigurationRequestAction {
    type: 'MANAGEMENT_CONFIGURATION_REQUEST';
    skip: number;
}
interface ConfigurationReceiveAction {
    type: 'MANAGEMENT_CONFIGURATION_RECEIVE';
    items: Array<ConfigItemGridItem>;
    count: number;
}
interface ConfigurationErrorAction {
    type: 'MANAGEMENT_CONFIGURATION_ERROR';
}

interface ConfigurationCreateSendAction {
    type: 'MANAGEMENT_CONFIGURATION_CREATE_SEND';
}
interface ConfigurationCreateSuccessAction {
    type: 'MANAGEMENT_CONFIGURATION_CREATE_SUCCESS';
    newItem: ConfigItemGridItem;
}
interface ConfigurationCreateErrorAction {
    type: 'MANAGEMENT_CONFIGURATION_CREATE_ERROR';
}

interface ConfigurationUpdateSendAction {
    type: 'MANAGEMENT_CONFIGURATION_UPDATE_SEND';
    field: ConfigItemFieldsType;
}
interface ConfigurationUpdateSuccessAction {
    type: 'MANAGEMENT_CONFIGURATION_UPDATE_SUCCESS';
    object: ConfigItemGridItem;
}
interface ConfigurationUpdateErrorAction {
    type: 'MANAGEMENT_CONFIGURATION_UPDATE_ERROR';
}

interface ConfigurationDeleteStartAction {
    type: 'MANAGEMENT_CONFIGURATION_DELETE_START';
}
interface ConfigurationDeleteSendAction {
    type: 'MANAGEMENT_CONFIGURATION_DELETE_SEND';
}
interface ConfigurationDeleteSuccessAction {
    type: 'MANAGEMENT_CONFIGURATION_DELETE_SUCCESS';
}
interface ConfigurationDeleteCancelAction {
    type: 'MANAGEMENT_CONFIGURATION_DELETE_CANCEL';
}
interface ConfigurationDeleteErrorAction {
    type: 'MANAGEMENT_CONFIGURATION_DELETE_ERROR';
}

type KnownAction = ConfigurationRequestAction | ConfigurationReceiveAction | ConfigurationErrorAction
    | ConfigurationCreateSendAction | ConfigurationCreateSuccessAction | ConfigurationCreateErrorAction
    | ConfigurationUpdateSendAction | ConfigurationUpdateSuccessAction | ConfigurationUpdateErrorAction
    | ConfigurationDeleteStartAction | ConfigurationDeleteSendAction
    | ConfigurationDeleteSuccessAction | ConfigurationDeleteCancelAction
    | ConfigurationDeleteErrorAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestItems: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementConfiguration && !appState.managementConfiguration.isLoading) {
            let typeFilter = FilterHandler.getDropDownValue(filters, ManagementConfigurationFilters.ItemType, 'id', 'text');
            if (!!typeFilter) {
                FilterHandler.getApiFilterRequestPromise<DataRequest<ConfigItemGridItem>>('/v1/configuration/search', 'POST', filters, skip, pageSize, sorting)
                    .then(data => {
                        dispatch(UserActions.updateUserPreferences({
                            [`${UserPreferencePage.ManagementConfiguration}-${UserPreferenceKey.Filters}`]: filters,
                            [`${UserPreferencePage.ManagementConfiguration}-${UserPreferenceKey.Sorting}`]: sorting,
                            [`${UserPreferencePage.ManagementConfiguration}-${UserPreferenceKey.PageSize}`]: pageSize,
                        }));

                        dispatch({ type: 'MANAGEMENT_CONFIGURATION_RECEIVE', items: data.data, count: data.count, });
                    })
                    .catch(err => {
                        dispatch({ type: 'MANAGEMENT_CONFIGURATION_ERROR' });
                        dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                    });

                dispatch({ type: 'MANAGEMENT_CONFIGURATION_REQUEST', skip: skip, });
            } else {
                dispatch(UserActions.updateUserPreferences({
                    [`${UserPreferencePage.ManagementConfiguration}-${UserPreferenceKey.Filters}`]: filters,
                    [`${UserPreferencePage.ManagementConfiguration}-${UserPreferenceKey.Sorting}`]: sorting,
                    [`${UserPreferencePage.ManagementConfiguration}-${UserPreferenceKey.PageSize}`]: pageSize,
                }));
                dispatch({ type: 'MANAGEMENT_CONFIGURATION_RECEIVE', items: [], count: 0, });
            }
        }
    },
    updateField: (id: number, serverField: ConfigItemFieldsType, value: any, onSuccess?: () => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementConfiguration) {
            let sentValue = serverField === ConfigItemFields.Divisions ? (value as Array<PartyListItem>).map(x => x.partyId) : value;

            apiClientInstance.fetchRequest(
                `v1/configuration/${id}`,
                'PATCH',
                { field: serverField, value: sentValue }
            )
                .then((data) => {
                    let config = {...appState.managementConfiguration?.items.find(x => x.configItemId === id) ?? {} as ConfigItemGridItem};

                    switch (serverField) {
                        case ConfigItemFields.Code:
                            config.code = (data as FieldUpdateResult<string>).value;
                            break;
                        case ConfigItemFields.Description:
                            config.description = (data as FieldUpdateResult<string>).value;
                            break;
                        case ConfigItemFields.ItemType:
                            config.itemType = (data as FieldUpdateResult<string>).value;
                            break;
                        case ConfigItemFields.StringValue:
                            config.stringValue = (data as FieldUpdateResult<string>).value;
                            break;
                        case ConfigItemFields.NumericValue:
                            config.numericValue = (data as FieldUpdateResult<number>).value;
                            break;
                        case ConfigItemFields.Divisions:
                            config.divisions = value as Array<PartyListItem>;
                            break;
                    }

                    !!onSuccess && onSuccess();

                    dispatch({ type: 'MANAGEMENT_CONFIGURATION_UPDATE_SUCCESS', object: config, });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_CONFIGURATION_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_CONFIGURATION_UPDATE_SEND', field: serverField });
        }
    },
    createConfigItem: (model: ConfigItemGridItem, onSuccess?: (result: ConfigItemGridItem) => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementConfiguration) {
            let createModel = { ...model, divisions: model.divisions.map(x => x.partyId) } as ConfigItemCreateModel;
            apiClientInstance.fetchRequest<number>(
                `v1/configuration/`,
                'POST',
                { ...createModel }
            )
                .then((data) => {
                    let newItem = {...model, configItemId: data,} as ConfigItemGridItem;

                    !!onSuccess && onSuccess(newItem);

                    dispatch({ type: 'MANAGEMENT_CONFIGURATION_CREATE_SUCCESS', newItem: newItem });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_CONFIGURATION_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_CONFIGURATION_CREATE_SEND' });
        }
    },
    startDeleteConfigItem: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementConfiguration) {
            dispatch({ type: 'MANAGEMENT_CONFIGURATION_DELETE_START' });
        }
    },
    submitDeleteConfigItem: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementConfiguration) {
            apiClientInstance.fetchRequest(
                `/v1/configuration/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'MANAGEMENT_CONFIGURATION_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'MANAGEMENT_CONFIGURATION_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_CONFIGURATION_DELETE_SEND' });
        }
    },
    cancelDeleteConfigItem: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementConfiguration) {
            dispatch({ type: 'MANAGEMENT_CONFIGURATION_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementConfigurationState = {
    isLoading: false,
    items: [],
    count: 0,
    skip: 0,
    createStatus: ServerOperationStatus.NONE,
    updateStatus: ServerOperationStatus.NONE,
    deleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementConfigurationState> = (state: ManagementConfigurationState | undefined, incomingAction: Action): ManagementConfigurationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_CONFIGURATION_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'MANAGEMENT_CONFIGURATION_RECEIVE':
            return {
                ...state,
                items: action.items,
                count: action.count,
                isLoading: false,
            };
        case 'MANAGEMENT_CONFIGURATION_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'MANAGEMENT_CONFIGURATION_UPDATE_SEND':
            return {
                ...state,
                updatedField: action.field,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_CONFIGURATION_UPDATE_SUCCESS':
            {
                let list = [...state.items];
                let index = state.items.findIndex(x => x.configItemId === action.object.configItemId);

                list[index] = action.object;

                return {
                    ...state,
                    items: list,
                    updatedField: undefined,
                    updateStatus: ServerOperationStatus.SUCCESS
                };
            };
        case 'MANAGEMENT_CONFIGURATION_UPDATE_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_CONFIGURATION_CREATE_SEND':
            return {
                ...state,
                createStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_CONFIGURATION_CREATE_SUCCESS':
            return {
                ...state,
                createStatus: ServerOperationStatus.SUCCESS,
                items: [...state.items, action.newItem],
            };
        case 'MANAGEMENT_CONFIGURATION_CREATE_ERROR':
            return {
                ...state,
                createStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_CONFIGURATION_DELETE_START':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.READY,
            };
        case 'MANAGEMENT_CONFIGURATION_DELETE_SEND':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_CONFIGURATION_DELETE_CANCEL':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.NONE,
            };
        case 'MANAGEMENT_CONFIGURATION_DELETE_SUCCESS':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'MANAGEMENT_CONFIGURATION_DELETE_ERROR':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};