import reportContexts from '~enums/reportContext';
import { Grid, List, ListItem, ListItemText } from '@mui/material';
import { Button } from "@progress/kendo-react-buttons";
import { useInternationalization, useLocalization, } from '@progress/kendo-react-intl';
import ReportButton from '~components/controls/ReportButton';
import { AMWGridFilterToolbarWrapperProps } from '~components/datagrid/types';
import AMWGridFilterToolbar from '~components/datagrid/AMWGridFilterToolbar';
import OpportunitiesFilterPanel from './OpportunitiesFilterPanel';
import LocalizationKeys from '~enums/localizationKeys';


type OpportunitiesToolbarProps = {
    onNewOpportunityClick: () => void,
    onDeleteOpportunityClick: () => void,
    opportunitiesNr: number;
    forecastTotal: number;
    approvalsNr: number;
    approvalTotal: number;
    declinesNr: number;
    declineTotal: number;
    proposalsNr: number;
    proposalTotal: number;
} & AMWGridFilterToolbarWrapperProps;

const OpportunitiesToolbar = (props: OpportunitiesToolbarProps) => {
    const localization = useLocalization();
    const intl = useInternationalization();

    return (
        <AMWGridFilterToolbar {...props}
            filterPanel={OpportunitiesFilterPanel}
            totalsRow={
                <Grid item>
                    <List className={'Totals'}>
                        <ListItem>
                            <ListItemText
                                primary={props.opportunitiesNr}
                                secondary="Opportunities #" />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={intl.formatNumber(props.forecastTotal, 'n2')}
                                secondary="Forecast total" />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={props.approvalsNr}
                                secondary="Approvals #" />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={intl.formatNumber(props.approvalTotal, 'n2')}
                                secondary="Approvals total" />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={props.declinesNr}
                                secondary="Declines #" />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={intl.formatNumber(props.declineTotal, 'n2')}
                                secondary="Declines total" />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={props.proposalsNr}
                                secondary="Proposals #" />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary={intl.formatNumber(props.proposalTotal, 'n2')}
                                secondary="Proposals total" />
                        </ListItem>
                    </List>
                </Grid>
            }
        >
            <Grid item className={"ButtonGroup"}>
                <Button
                    fillMode={'flat'}
                    className={"Button--bold Button--orange"}
                    onClick={props.onNewOpportunityClick}
                    disabled={props.isGridBusy}
                    size={"large"}
                    icon={"plus"}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Grid.Toolbar.New, "%New opportunity")}
                />
            </Grid>

            <Grid item className={"ButtonGroup"}>
                <Button
                    onClick={props.onDeleteOpportunityClick}
                    disabled={!props.selectedItem || props.isGridBusy}
                    icon={"delete"}
                    title={localization.toLanguageString(LocalizationKeys.Shared.Buttons.Delete, "%Delete")}
                />
            </Grid>

            <div className="spacer"></div>

            <Grid item className={"ButtonGroup"}>
                <ReportButton reportContext={reportContexts.CRMOpportunitiesView} />
            </Grid>
        </AMWGridFilterToolbar>
    );
};

export default OpportunitiesToolbar;