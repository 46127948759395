import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { ShowErrorAction } from '~store/infra/errors';
import { actionCreators as UserActions } from '~store/auth/user';

import { apiClientInstance } from '~services/auth/ApiClientInstance';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import POGridItem from '~models/purchaseOrder/poGridItem';
import { SortDescriptor } from "@progress/kendo-data-query";
import { NavigateAction } from '~store/infra/navigation';


export interface POItemsState {
    isLoading: boolean;
    orders: Array<POGridItem>;
    count: number;
    skip: number;
    orderCreateStatus: ServerOperationStatus;
    orderDeleteStatus: ServerOperationStatus;
}


// -----------------
// ---- ACTIONS ----
// -----------------

interface RequestDataAction {
    type: 'PO_ITEMS_REQUEST_DATA';
    skip: number;
}
interface ReceiveDataAction {
    type: 'PO_ITEMS_RECEIVE_DATA';
    orders: Array<POGridItem>;
    count: number;
}
interface ReceiveDataErrorAction {
    type: 'PO_ITEMS_RECEIVE_DATA_ERROR';
}

interface OrderCreateStartAction {
    type: 'PO_ITEMS_CREATE_START';
}
interface OrderCreateSendAction {
    type: 'PO_ITEMS_CREATE_SEND';
}
interface OrderCreateSuccessAction {
    type: 'PO_ITEMS_CREATE_SUCCESS';
}
interface OrderCreateCancelAction {
    type: 'PO_ITEMS_CREATE_CANCEL';
}
interface OrderCreateErrorAction {
    type: 'PO_ITEMS_CREATE_ERROR';
}

interface OrderDeleteStartAction {
    type: 'PO_ITEMS_DELETE_START';
}
interface OrderDeleteSendAction {
    type: 'PO_ITEMS_DELETE_SEND';
}
interface OrderDeleteSuccessAction {
    type: 'PO_ITEMS_DELETE_SUCCESS';
}
interface OrderDeleteCancelAction {
    type: 'PO_ITEMS_DELETE_CANCEL';
}
interface OrderDeleteErrorAction {
    type: 'PO_ITEMS_DELETE_ERROR';
}

type KnownAction = RequestDataAction | ReceiveDataAction | ReceiveDataErrorAction
    | OrderCreateStartAction | OrderCreateSendAction | OrderCreateSuccessAction | OrderCreateCancelAction | OrderCreateErrorAction
    | OrderDeleteStartAction | OrderDeleteSendAction | OrderDeleteSuccessAction | OrderDeleteCancelAction | OrderDeleteErrorAction
    | NavigateAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;

export const actionCreators = {
    requestData: (clientFileId: number, filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.poItems && !appState.poItems.isLoading) {

            let cfFilters = {
                ...filters,
            };

            FilterHandler.getApiFilterRequestPromise<DataRequest<POGridItem>>(`/v1/files/${clientFileId}/po/search`, 'POST', cfFilters, skip, pageSize, sorting)
                .then((data) => {
                    dispatch(UserActions.updateUserPreferences({
                        // [`${UserPreferencePage.POItems}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.POItems}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.POItems}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach(x => {
                        x.createdOn = new Date(x.createdOn);
                    });
                    dispatch({ type: 'PO_ITEMS_RECEIVE_DATA', orders: data.data, count: data.count });
                })
                .catch((err) => {
                    dispatch({ type: 'PO_ITEMS_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'PO_ITEMS_REQUEST_DATA', skip: skip });
        }
    },
    startCreateOrder: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.poItems) {
            dispatch({type: 'PO_ITEMS_CREATE_START'})
        }
    },
    createOrder: (fileId: number, dealerId: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.poItems) {
            apiClientInstance.fetchRequest(
                `/v1/files/${fileId}/po/`,
                'POST',
                {
                    dealerId: dealerId
                }
            )
                .then((newId) => {
                    dispatch({ type: 'NAVIGATION_NAVIGATE', route: `/files/${fileId}/po/${newId}` });

                    dispatch({ type: 'PO_ITEMS_CREATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'PO_ITEMS_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'PO_ITEMS_CREATE_SEND' });
        }
    },
    cancelCreateOrder: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.poItems) {
            dispatch({type: 'PO_ITEMS_CREATE_CANCEL'})
        }
    },
    startDeleteOrder: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.poItems) {
            dispatch({type: 'PO_ITEMS_DELETE_START'})
        }
    },
    deleteOrder: (orderId: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.poItems) {
            apiClientInstance.fetchRequest(
                `/v1/po/${orderId}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'PO_ITEMS_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'PO_ITEMS_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'PO_ITEMS_DELETE_SEND' });
        }
    },
    cancelDeleteOrder: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.poItems) {
            dispatch({type: 'PO_ITEMS_DELETE_CANCEL'})
        }
    },
};

// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: POItemsState = {
    isLoading: false,
    orders: [],
    count: 0,
    skip: 0,
    orderCreateStatus: ServerOperationStatus.NONE,
    orderDeleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<POItemsState> = (state: POItemsState | undefined, incomingAction: Action): POItemsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'PO_ITEMS_REQUEST_DATA':
            return {
                orders: state.orders,
                count: state.count,
                isLoading: true,
                skip: action.skip,
                orderCreateStatus: ServerOperationStatus.NONE,
                orderDeleteStatus: ServerOperationStatus.NONE,
            };
        case 'PO_ITEMS_RECEIVE_DATA':
            return {
                orders: action.orders,
                count: action.count,
                isLoading: false,
                skip: state.skip,
                orderCreateStatus: ServerOperationStatus.NONE,
                orderDeleteStatus: ServerOperationStatus.NONE,
            };
        case 'PO_ITEMS_RECEIVE_DATA_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'PO_ITEMS_CREATE_START':
            return {
                ...state,
                orderCreateStatus: ServerOperationStatus.READY,
            };
        case 'PO_ITEMS_CREATE_SEND':
            return {
                ...state,
                orderCreateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'PO_ITEMS_CREATE_CANCEL':
            return {
                ...state,
                orderCreateStatus: ServerOperationStatus.NONE,
            };
        case 'PO_ITEMS_CREATE_SUCCESS':
            return {
                ...state,
                orderCreateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'PO_ITEMS_CREATE_ERROR':
            return {
                ...state,
                orderCreateStatus: ServerOperationStatus.ERROR,
            };

        case 'PO_ITEMS_DELETE_START':
            return {
                ...state,
                orderDeleteStatus: ServerOperationStatus.READY,
            };
        case 'PO_ITEMS_DELETE_SEND':
            return {
                ...state,
                orderDeleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'PO_ITEMS_DELETE_CANCEL':
            return {
                ...state,
                orderDeleteStatus: ServerOperationStatus.NONE,
            };
        case 'PO_ITEMS_DELETE_SUCCESS':
            return {
                ...state,
                orderDeleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'PO_ITEMS_DELETE_ERROR':
            return {
                ...state,
                orderDeleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};