const InventoryPackagesGridFields = {
    UniqueId: "uniqueId",
    LabelNumber: "labelNumber",
    PackageType: "packageType",
    IsCrate: "isCrate",
    Location: "location",
    DateIn: "dateIn",
    DateOut: "dateOut",
} as const;

export type InventoryPackagesGridFieldsType = typeof InventoryPackagesGridFields[keyof typeof InventoryPackagesGridFields];

export default InventoryPackagesGridFields;