import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';
import { apiClientInstance } from '~services/auth/ApiClientInstance';

import ReportButtonItem from "~models/report/reportButtonItem";
import { ShowErrorAction } from './errors';
import { ReportContextType } from "~enums/reportContext";


export type ReportsState = {
    isLoading: boolean,
    reports: ReportButtonItem[],
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface ReportButtonRequestAction {
    type: 'REPORTS_MENU_RETRIEVE_REQUEST',
}

interface ReportButtonRetrieveAction {
    type: 'REPORTS_MENU_RETRIEVE_RESULT',
    reports: ReportButtonItem[],
}

type KnownAction = ReportButtonRequestAction | 
    ReportButtonRetrieveAction | 
    ShowErrorAction;

export const actionCreators = {
    openWindow: (context: ReportContextType): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.reportMenu && !appState.reportMenu.isLoading) {            
            apiClientInstance.fetchRequest<ReportButtonItem[]>(`/v1/reports/?context=${context}`, 'GET')
                .then(data => {
                    dispatch({ type: 'REPORTS_MENU_RETRIEVE_RESULT', reports: data });
                });
            
            dispatch({ type: 'REPORTS_MENU_RETRIEVE_REQUEST' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ReportsState = {
    isLoading: false,
    reports: [],
 };

export const reducer: Reducer<ReportsState> = (state: ReportsState | undefined, incomingAction: Action): ReportsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'REPORTS_MENU_RETRIEVE_REQUEST':
            return {
                ...state,
                isLoading: true,
                reports: []
            };

        case 'REPORTS_MENU_RETRIEVE_RESULT':
            return {
                ...state,
                isLoading: false,
                reports: action.reports,
            };
    }

    return state;
};