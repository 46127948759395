import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal/react';
import GridUtils from '~src/utils/gridUtils';
import * as CRMStore from '~store/pages/mainViews/crm';
import * as UsersStore from '~store/auth/user';
import * as ScreenStore from '~store/infra/screen';
import { ApplicationState } from '~store/ApplicationState';

import TasksGridFields from '~enums/gridFields/tasks';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import TaskGridItem from '~models/task/taskGridItem';

import { Grid } from '@mui/material';
import { FilePresent as DocsIcon } from '@mui/icons-material';
import { Button } from '@progress/kendo-react-buttons';
import {
    GridColumn,
    GridToolbar,
    GridPageChangeEvent,
    GridCellProps,
    GridRowDoubleClickEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { provideLocalizationService, registerForLocalization, } from '@progress/kendo-react-intl';

import AMWGrid from '~components/datagrid/AMWGrid';
import CustomCell from '~components/datagrid/CustomCell';
import { GridFilterable, GridPageable, GridSortable } from '~components/datagrid/types';
import { GridContainer, GridContainerDispatchProps, GridContainerStateProps } from '~components/infra/GridContainer';
import TaskModal from '~components/modals/TaskModal';
import TasksToolbar from './TasksToolbar';
import LocalizationKeys from '~enums/localizationKeys';


type TasksTabStateProps = ScreenStore.ScreenState &
    CRMStore.TasksTabState &
    GridFilterable &
    GridPageable &
    GridSortable &
    GridContainerStateProps;

type TasksTabDispatchProps = typeof CRMStore.tasksActionCreators &
    GridContainerDispatchProps;

type TasksTabProps = TasksTabStateProps & TasksTabDispatchProps;

type TasksTabState = {
    containerWidth: number,
    openedTask: number | null
};

const minColumnWidth = {
    [TasksGridFields.StartDate]: 160,
    [TasksGridFields.DueDate]: 160,
    [TasksGridFields.OwnerName]: 120,
    [TasksGridFields.Subject]: 120,
    [TasksGridFields.Priority]: 100,
    [TasksGridFields.Status]: 100,
    [TasksGridFields.OpportunityDescription]: 150,
    [TasksGridFields.OpportunityCompany]: 150,
    [TasksGridFields.OpportunityContact]: 120,
    [TasksGridFields.OriginalTask]: 100,
    [TasksGridFields.FollowUpTask]: 100,
    [TasksGridFields.HasDocuments]: 80,
};

class TasksTab extends GridContainer<TasksTabProps, TasksTabState> {
    constructor(props: Readonly<TasksTabProps>) {
        super(props);
        this.state = {
            containerWidth: 1,
            openedTask: null,
        };

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.requestTasks(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);

        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.resize);

        let element = document.querySelector('#TasksGridContainer .k-grid-header');
        let tabs = document.querySelector('#CRMTabs');
        !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );

        this.setState({
            containerWidth: document.getElementById('TasksGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    componentDidUpdate(prevProps: TasksTabProps) {
        if (!equal(prevProps.topbarHeight, this.props.topbarHeight)) {
            let element = document.querySelector(`#TasksGridContainer .k-grid-header`);
            let tabs = document.querySelector('#CRMTabs');
            !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("orientationchange", this.resize);
    }

    private resize() {
        this.setState({
            containerWidth: document.getElementById('TasksGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    private onPageChange = (event: GridPageChangeEvent) => {
        this.props.requestTasks(this.props.filters, event.page.skip, event.page.take, this.props.sorting);
    }

    private onSortChange = (event: GridSortChangeEvent) => {
        this.props.requestTasks(this.props.filters, this.props.skip, this.props.pageSize, event.sort);
    }

    private onRowDoubleClick(event: GridRowDoubleClickEvent) {
        let task = event.dataItem as TaskGridItem;
        this.setState({
            openedTask: task.taskId,
        });
    }

    private onGridLinkClick(id: number) {
        this.setState({
            openedTask: id,
        });
    }

    private calculateColumnWidth(field: string) : number | undefined {
        return GridUtils.calculateColumnWidth(
            field,
            this.props.columnWidth,
            this.state.containerWidth,
            minColumnWidth
        );
    }

    private get columns() {
        const localization = provideLocalizationService(this);

        let columns = [
            (
                <GridColumn
                    key={"tasks-startDate"}
                    field={TasksGridFields.StartDate}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Grid.StartDate, "%Start date")}
                    format="{0:g}"
                    width={this.calculateColumnWidth(TasksGridFields.StartDate) ?? 160}
                    orderIndex={this.props.columnOrder[TasksGridFields.StartDate]} />
            ),
            (
                <GridColumn
                    key={"tasks-dueDate"}
                    field={TasksGridFields.DueDate}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Grid.DueDate, "%Due date")}
                    format="{0:g}"
                    width={this.calculateColumnWidth(TasksGridFields.DueDate) ?? 160}
                    orderIndex={this.props.columnOrder[TasksGridFields.DueDate]} />
            ),
            (
                <GridColumn
                    key={"tasks-owner"}
                    field={TasksGridFields.OwnerName}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Grid.Owner, "%Owner")}
                    width={this.calculateColumnWidth(TasksGridFields.OwnerName)}
                    orderIndex={this.props.columnOrder[TasksGridFields.OwnerName]} />
            ),
            (
                <GridColumn
                    key={"tasks-subject"}
                    field={TasksGridFields.Subject}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Grid.Subject, "%Subject")}
                    width={this.calculateColumnWidth(TasksGridFields.Subject)}
                    orderIndex={this.props.columnOrder[TasksGridFields.Subject]} />
            ),
            (
                <GridColumn
                    key={"tasks-priority"}
                    field={TasksGridFields.Priority}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Grid.Priority, "%Priority")}
                    cell={(
                        (props: GridCellProps) => {
                            const task = props.dataItem as TaskGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(task.priority, task.priority)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(TasksGridFields.Priority)}
                    orderIndex={this.props.columnOrder[TasksGridFields.Priority]} />
            ),
            (
                <GridColumn
                    key={"tasks-status"}
                    field={TasksGridFields.Status}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Grid.Status, "%Status")}
                    cell={(
                        (props: GridCellProps) => {
                            const task = props.dataItem as TaskGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(task.status, task.status)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(TasksGridFields.Status)}
                    orderIndex={this.props.columnOrder[TasksGridFields.Status]} />
            ),
            (
                <GridColumn
                    key={"tasks-opportunityDescription"}
                    field={TasksGridFields.OpportunityDescription}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Grid.OpportunityDescription, "%Opportunity description")}
                    width={this.calculateColumnWidth(TasksGridFields.OpportunityDescription)}
                    orderIndex={this.props.columnOrder[TasksGridFields.OpportunityDescription]} />
            ),
            (
                <GridColumn
                    key={"tasks-opportunityCompany"}
                    field={TasksGridFields.OpportunityCompany}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Grid.OpportunityCompany, "%Opportunity company")}
                    width={this.calculateColumnWidth(TasksGridFields.OpportunityCompany)}
                    orderIndex={this.props.columnOrder[TasksGridFields.OpportunityCompany]} />
            ),
            (
                <GridColumn
                    key={"tasks-opportunityContact"}
                    field={TasksGridFields.OpportunityContact}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Grid.OpportunityContact, "%Opportunity contact")}
                    width={this.calculateColumnWidth(TasksGridFields.OpportunityContact)}
                    orderIndex={this.props.columnOrder[TasksGridFields.OpportunityContact]} />
            ),
            (
                <GridColumn
                    key={"tasks-original"}
                    field={TasksGridFields.OriginalTask}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Grid.OriginalTask, "%Original task")}
                    cell={(
                        (props: GridCellProps) => {
                            const task = props.dataItem as TaskGridItem;
                            return (
                                <CustomCell {...props}>
                                    {!!task.originalTaskId && (
                                        <Button
                                            size={'small'}
                                            fillMode={'link'}
                                            onClick={() => {this.onGridLinkClick(task.originalTaskId ?? 0)}}
                                        >
                                            {task.originalTask}
                                        </Button>
                                    )}
                                </CustomCell>
                            );
                        }
                    )}
                    className={'grid__cell--link'}
                    width={this.calculateColumnWidth(TasksGridFields.OriginalTask)}
                    orderIndex={this.props.columnOrder[TasksGridFields.OriginalTask]} />
            ),
            (
                <GridColumn
                    key={"tasks-followup"}
                    field={TasksGridFields.FollowUpTask}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Grid.FollowUpTask, "%Follow up task")}
                    cell={(
                        (props: GridCellProps) => {
                            const task = props.dataItem as TaskGridItem;
                            return (
                                <CustomCell {...props}>
                                    {!!task.followUpTaskId && (
                                        <Button
                                            size={'small'}
                                            fillMode={'link'}
                                            onClick={() => {this.onGridLinkClick(task.followUpTaskId ?? 0)}}
                                        >
                                            {task.followUpTask}
                                        </Button>
                                    )}
                                </CustomCell>
                            );
                        }
                    )}
                    className={'grid__cell--link'}
                    width={this.calculateColumnWidth(TasksGridFields.FollowUpTask)}
                    orderIndex={this.props.columnOrder[TasksGridFields.FollowUpTask]} />
            ),
            (
                <GridColumn
                    key={"tasks-hasDocuments"}
                    field={TasksGridFields.HasDocuments}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Tasks.Grid.HasDocuments, "%Docs")}
                    cell={(
                        (props: GridCellProps) => {
                            const task = props.dataItem as TaskGridItem;
                            return (
                                <CustomCell {...props}>
                                    {task.hasDocuments && (<DocsIcon />)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(TasksGridFields.HasDocuments)}
                    orderIndex={this.props.columnOrder[TasksGridFields.HasDocuments]} />
            ),
        ];

        return columns;
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12} id={'TasksGridContainer'}>
                    <AMWGrid
                        sticky={true}
                        scrollable={'scrollable'}

                        data={this.props.tasks}
                        total={this.props.count}

                        pageable={{ buttonCount: 4, pageSizes: true }}
                        skip={this.props.skip}
                        pageSize={this.props.pageSize}
                        onPageChange={this.onPageChange.bind(this)}

                        sortable={true}
                        sort={this.props.sorting}
                        onSortChange={this.onSortChange.bind(this)}

                        reorderable={true}
                        onColumnReorder={this.onColumnReorder.bind(this)}

                        resizable={true}
                        onColumnResize={this.onColumnResize.bind(this)}

                        onRowDoubleClick={this.onRowDoubleClick.bind(this)}
                    >
                        <GridToolbar>
                            <TasksToolbar
                                isGridBusy={this.props.isLoading}
                                filters={this.props.filters}
                                requestData={(filters) => {
                                    this.props.requestTasks(filters, this.props.skip, this.props.pageSize, this.props.sorting);
                                }}
                            />
                        </GridToolbar>

                        {this.columns}
                    </AMWGrid>

                    <TaskModal
                        taskId={this.state.openedTask}
                        open={!!this.state.openedTask}
                        onClose={() => {
                            this.setState({
                                openedTask: null
                            });
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
}
registerForLocalization(TasksTab);

export default connect(
    (state: ApplicationState) : TasksTabStateProps => {
        return {
            pageKey: UserPreferencePage.CRMTasks,
            ...state.crm?.tasks,
            ...state.screen,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.CRMTasks}-${UserPreferenceKey.Filters}`] ?? {},
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.CRMTasks}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.CRMTasks}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.CRMTasks}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.CRMTasks}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        };
    },
    (dispatch) : TasksTabDispatchProps => {
        let tasksActions = bindActionCreators({...CRMStore.tasksActionCreators}, dispatch);
        let usersActions = bindActionCreators({...UsersStore.actionCreators}, dispatch);

        return {
            ...tasksActions,
            ...usersActions
        };
    },
)(TasksTab as any);