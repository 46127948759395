import React from 'react';

import '~css/components/controls/MultiSelectWrapper.scss';
import { Button } from '@progress/kendo-react-buttons';
import { MultiSelect, MultiSelectChangeEvent, MultiSelectCloseEvent, MultiSelectFilterChangeEvent, MultiSelectHandle } from '@progress/kendo-react-dropdowns';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import { MultiSelectWrapperProps, MultiSelectWrapperState } from './types';
import { LinearProgress } from '@mui/material';
import LocalizationKeys from '~enums/localizationKeys';



const customProps = ['onInputChange', 'containerClasses', 'onSelectAllClick'];

export default class MultiSelectWrapper extends React.Component<MultiSelectWrapperProps, MultiSelectWrapperState> {
    __input: MultiSelectHandle | null = null;

    constructor(props: MultiSelectWrapperProps) {
        super(props);

        this.state = {
            filter: ''
        };
    }

    private convertInputProps() {
        let defaultProps: {[key:string]: any} = {};

        for(let key in this.props){
            if (!customProps.includes(key)) {
                let entry = Object.entries(this.props).find(x => x[0] === key);

                defaultProps[key] = !!entry ? entry[1] : undefined;
            }
        }

        return defaultProps;
    }

    private onSelectAllClick(event: React.MouseEvent) {
        let value = this.props.data ?? [];

        if (!!this.props.getAllValues) {
            this.props.getAllValues()
                .then((values) => {
                    value = values;

                    if (!!this.props.onSelectAll) {
                        this.props.onSelectAll(value);
                        return;
                    }

                    this.props.onInputChange({
                        id: this.props.id ?? '',
                        name: this.props.name ?? '',
                        value: values,
                        syntheticEvent: event,
                        target: this.__input,
                    });
                })
        } else {
            if (!!this.props.onSelectAll) {
                this.props.onSelectAll(value);
                return;
            }

            this.props.onInputChange({
                id: this.props.id ?? '',
                name: this.props.name ?? '',
                value: value,
                syntheticEvent: event,
                target: this.__input,
            });
        }
    }

    private onChange(event: MultiSelectChangeEvent) {
        this.props.onInputChange({
            id: event.target.props.id ?? '',
            name: event.target.props.name ?? '',
            value: event.value,
            syntheticEvent: event.syntheticEvent,
            target: this.__input,
        });
    }

    private onFilterChange(event: MultiSelectFilterChangeEvent) {
        if (this.props.onFilterChange) {
            return this.props.onFilterChange(event);
        }

        const filter = event.nativeEvent.type === "click" ?
            this.state.filter :
            event.filter.value;

        this.setState({
            filter: filter,
        });
    }

    private onClose(event: MultiSelectCloseEvent) {
        if (!!this.props.onClose)
            return this.props.onClose(event);

        this.setState({
            filter: ''
        });
    }

    render() {
        let defaultProps = this.convertInputProps();
        const localization = provideLocalizationService(this);

        let value = this.props.value ?? [];
        const filteredData = !!this.state.filter ?
            this.props.data?.filter(x => x.text.toLowerCase().includes(this.state.filter.toLowerCase())) ?? [] :
            this.props.data;

        return (
            <div className={`MultiSelectWrapper ${this.props.containerClasses || ' '}`}>

                <MultiSelect
                    ref={(select) => {
                        this.__input = select;
                    }}
                    textField={this.props.textField}
                    dataItemKey={this.props.dataItemKey}
                    tags={
                        value.length > 0 ?
                        [
                            {
                                text: `${value.length} ${localization.toLanguageString(LocalizationKeys.Kendo.Dropdown.Selected, "items selected")}`,
                                data: [...value]
                            },
                        ] :
                        []
                    }
                    autoClose={false}
                    filterable={true}
                    filter={this.state.filter}
                    {...defaultProps}
                    onChange={this.onChange.bind(this)}
                    onFilterChange={this.onFilterChange.bind(this)}
                    data={filteredData}
                    onClose={this.onClose.bind(this)}
                />

                {!!this.props.enableSelectAll && (
                    <div className={`MultiSelectWrapper__adornmentContainer`}>
                        <Button
                            fillMode="flat"
                            icon='foreground-color'
                            onClick={this.onSelectAllClick.bind(this)}
                        >
                        </Button>
                    </div>
                )}

                {!!this.props.inProgress && (
                    <LinearProgress className={`MultiSelectWrapper__Progress`} />
                )}
            </div>
        );
    }
}

registerForLocalization(MultiSelectWrapper as React.ComponentClass<any>);