const CustomsDocumentsFields = {
    JobFile: "CustomsDocumentsFields.JobFile",
    ItemNr: "CustomsDocumentsFields.ItemNr",
    DocNr: "CustomsDocumentsFields.DocNr",
    DocumentType: "CustomsDocumentsFields.DocumentType",
    CreationDate: "CustomsDocumentsFields.CreationDate",
    ExpiryDate: "CustomsDocumentsFields.ExpiryDate",
    PrevStatus: "CustomsDocumentsFields.PrevStatus",
    CurrentStatus: "CustomsDocumentsFields.CurrentStatus",
    TransitDocNr: "CustomsDocumentsFields.TransitDocNr",
    DocIssuedBy: "CustomsDocumentsFields.DocIssuedBy",
    AFC: "CustomsDocumentsFields.AFC",
    CustomsOfficeLocationDeparture: "CustomsDocumentsFields.CustomsOfficeLocationDeparture",
    CountryOfDeparture: "CustomsDocumentsFields.CountryOfDeparture",
    CustomsOfficeLocationDestination: "CustomsDocumentsFields.CustomsOfficeLocationDestination",
    CountryOfDestination: "CustomsDocumentsFields.CountryOfDestination",
    ReplacedDocumentNr: "CustomsDocumentsFields.ReplacedDocumentNr",
    ReplacedDocumentDate: "CustomsDocumentsFields.ReplacedDocumentDate",
    TempExtensionNr: "CustomsDocumentsFields.TempExtensionNr",
    TempDocSentTo: "CustomsDocumentsFields.TempDocSentTo",
    ReceivedFrom: "CustomsDocumentsFields.ReceivedFrom",
    ReleasedTo: "CustomsDocumentsFields.ReleasedTo",
} as const;

export type CustomsDocumentsFieldsType = typeof CustomsDocumentsFields[keyof typeof CustomsDocumentsFields];

export default CustomsDocumentsFields;