import React from 'react';
import {
    NumericTextBox,
    NumericTextBoxBlurEvent,
    NumericTextBoxChangeEvent,
    NumericTextBoxFocusEvent,
    NumericTextBoxHandle,
    NumericTextBoxProps
} from '@progress/kendo-react-inputs';
import equal from 'fast-deep-equal/react';
import { LinearProgress } from '@mui/material';

import '~css/components/controls/NumericInputWrapper.scss';
import { Button } from '@progress/kendo-react-buttons';


export interface NumericInputWrapperEvent {
    id: string;
    name: string;
    value: number | null | undefined;
    element: HTMLInputElement | null;
    syntheticEvent: React.SyntheticEvent<any, Event>;
}

export type NumericInputWrapperProps = {
    containerClasses?: string;
    onInputChange?: (event: NumericInputWrapperEvent) => Promise<any> | void;
    inProgress?: boolean;
    adornment?: React.ReactNode[];
} & ({
    clearable: boolean,
    onClearClick: (name?: string) => any,
} | {
    clearable?: never,
    onClearClick?: never,
}) & NumericTextBoxProps;

const customProps = ['onInputChange', 'containerClasses', 'clearable', 'onClearClick', 'adornment', 'inProgress'];

export default class NumericInputWrapper extends React.Component<NumericInputWrapperProps, {value: number | null | undefined}> {
    __input: NumericTextBoxHandle | null = null;

    constructor(props: NumericInputWrapperProps) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    componentDidUpdate(prevProps: NumericInputWrapperProps) {
        if(!equal(prevProps.value, this.props.value)) {
            this.setState({
                value: this.props.value
            });
        }
    }

    private onChange(event: NumericTextBoxChangeEvent) {
        const value = event.target.value;
        this.setState({
            value: value
        });
    }

    private onBlur(event: NumericTextBoxBlurEvent) {
        if(!!this.props.onInputChange && this.props.value !== this.state.value) {
            let promise = this.props.onInputChange({
                id: event.target.props.id ?? '',
                name: event.target.props.name ?? '',
                value: this.state.value,
                element: event.target.element,
                syntheticEvent: event.syntheticEvent,
            })

            if(!!promise) {
                promise.catch((err: any) => {
                    this.setState({value: this.props.value});
                });
            }
        }
    }

    private onFocus(event: NumericTextBoxFocusEvent) {
        event.target?.element?.select();
    }

    private convertInputProps() {
        let defaultProps: {[key:string]: any} = {};

        for (let key in this.props) {
            if (!customProps.includes(key)) {
                let entry = Object.entries(this.props).find(x => x[0] === key);

                defaultProps[key] = !!entry ? entry[1] : undefined;
            }
        }

        return defaultProps;
    }

    private clearValue() {
        !!this.props.onClearClick && this.props.onClearClick(this.props.name);
    }

    render() {
        let defaultProps = this.convertInputProps();

        let adornmentCount = (this.props.adornment?.length ?? 0) +
            ((!!this.props.clearable && !!this.props.value) ? 1 : 0);

        !!this.__input && (this.__input.element?.setAttribute('style', `padding-right: ${1.8*adornmentCount}rem`));

        return (
            <div className={`NumericInputWrapper
                ${this.props.containerClasses || ' '}
                ${!!this.props.label ? 'NumericInputWrapper--labeled ' : ''}
                ${(!!this.props.clearable) ? 'NumericInputWrapper--adorned' : ''}`}
            >
                <NumericTextBox
                    ref={(inp) => {
                        this.__input = inp;
                    }}
                    {...defaultProps}
                    value={this.state.value}
                    onChange={this.onChange.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    onFocus={this.onFocus.bind(this)}
                />
{/*
                {!!this.props.clearable && !!this.props.value && (
                    <IconButton
                        className={"NumericInputWrapper__adornment"}
                        onClick={this.clearValue.bind(this)}
                    >
                        <CloseIcon/>
                    </IconButton>
                )} */}

                <div className={`NumericInputWrapper__adornmentContainer`}>
                    {!!this.props.adornment && this.props.adornment}

                    {!!this.props.clearable && !!this.props.value && (
                        <Button
                            fillMode="flat"
                            icon='close'
                            onClick={this.clearValue.bind(this)}>
                        </Button>
                    )}
                </div>

                {!!this.props.inProgress && (
                    <LinearProgress className={`NumericInputWrapper__Progress`} />
                )}
        </div>
        );
    }
}