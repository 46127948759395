import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { actionCreators as UserActions } from '~store/auth/user';

import { ShowErrorAction } from '~store/infra/errors';
import ResourceFields, { ResourceFieldsType } from '~enums/fields/resource';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import { DataRequest } from '~models/dataRequests';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';
import ResourceDetails from '~models/resource/resourceDetails';
import ResourceBlackoutGridItem from '~models/resourceBlackout/resourceBlackoutGridItem';
import ResourceBlackoutCreateModel from '~models/resourceBlackout/resourceBlackoutCreateModel';
import ResourceBlackoutFields, { ResourceBlackoutFieldsType } from '~enums/fields/resourceBlackout';
import { PartyListItem } from '~models/party/partyListItem';


export interface ResourceDetailsBlackoutsState {
    data: Array<ResourceBlackoutGridItem>;
    count: number;
    skip: number;
    isLoading: boolean;
    updatedField?: ResourceBlackoutFieldsType;
    createStatus: ServerOperationStatus;
    updateStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

export interface ResourceDetailsState {
    isLoading: boolean;
    data: ResourceDetails;
    updatedField?: ResourceFieldsType;
    blackouts: ResourceDetailsBlackoutsState;
    updateStatus: ServerOperationStatus;
}

const EmptyData: ResourceDetails = {
    resourceId: 0,
    resourceName: '',
    hourCost: 0,
    resourceType: '',
    homeDivisionId: 0,
    homeDivisionName: '',
    depth: 0,
    width: 0,
    height: 0,
    tonnage: 0,
    volume: 0,
    motdate: null,
    taxDate: null,
    crew: [],
    eventTypeUsedFor: [],
    qualifications: [],
    divisions: [],
};


// -----------------
// ---- ACTIONS ----
// -----------------

interface RequestDataAction {
    type: 'RESOURCE_DETAILS_REQUEST_DATA';
}
interface ReceiveDataAction {
    type: 'RESOURCE_DETAILS_RECEIVE_DATA';
    data: ResourceDetails;
}
interface ReceiveDataErrorAction {
    type: 'RESOURCE_DETAILS_RECEIVE_DATA_ERROR';
}

interface StartUpdateDataAction {
    type: 'RESOURCE_DETAILS_START_UPDATE_DATA';
    field: ResourceFieldsType;
}
interface UpdateDataAction {
    type: 'RESOURCE_DETAILS_UPDATE_DATA';
    data: ResourceDetails;
}
interface UpdateDataErrorAction {
    type: 'RESOURCE_DETAILS_UPDATE_DATA_ERROR';
}

interface RequestBlackoutsAction {
    type: 'RESOURCE_DETAILS_BLACKOUTS_REQUEST';
    skip: number;
}
interface ReceiveBlackoutsAction {
    type: 'RESOURCE_DETAILS_BLACKOUTS_RECEIVE';
    blackouts: ResourceBlackoutGridItem[];
    count: number;
}
interface ReceiveBlackoutsErrorAction {
    type: 'RESOURCE_DETAILS_BLACKOUTS_RECEIVE_ERROR';
}

interface CreateBlackoutSendAction {
    type: 'RESOURCE_DETAILS_BLACKOUT_CREATE_SEND';
}
interface CreateBlackoutSuccessAction {
    type: 'RESOURCE_DETAILS_BLACKOUT_CREATE_SUCCESS';
    newItem: ResourceBlackoutGridItem;
}
interface CreateBlackoutErrorAction {
    type: 'RESOURCE_DETAILS_BLACKOUT_CREATE_ERROR';
}

interface UpdateBlackoutSendAction {
    type: 'RESOURCE_DETAILS_BLACKOUT_UPDATE_SEND';
    field: ResourceBlackoutFieldsType;
}
interface UpdateBlackoutSuccessAction {
    type: 'RESOURCE_DETAILS_BLACKOUT_UPDATE_SUCCESS';
    updatedItem: ResourceBlackoutGridItem;
}
interface UpdateBlackoutErrorAction {
    type: 'RESOURCE_DETAILS_BLACKOUT_UPDATE_ERROR';
}

interface DeleteBlackoutStartAction {
    type: 'RESOURCE_DETAILS_BLACKOUT_DELETE_START';
}
interface DeleteBlackoutSendAction {
    type: 'RESOURCE_DETAILS_BLACKOUT_DELETE_SEND';
}
interface DeleteBlackoutSuccessAction {
    type: 'RESOURCE_DETAILS_BLACKOUT_DELETE_SUCCESS';
    deletedId: number;
}
interface DeleteBlackoutCancelAction {
    type: 'RESOURCE_DETAILS_BLACKOUT_DELETE_CANCEL';
}
interface DeleteBlackoutErrorAction {
    type: 'RESOURCE_DETAILS_BLACKOUT_DELETE_ERROR';
}

type KnownAction = RequestDataAction | ReceiveDataAction | ReceiveDataErrorAction
    | StartUpdateDataAction | UpdateDataAction | UpdateDataErrorAction
    | RequestBlackoutsAction | ReceiveBlackoutsAction | ReceiveBlackoutsErrorAction
    | CreateBlackoutSendAction | CreateBlackoutSuccessAction | CreateBlackoutErrorAction
    | UpdateBlackoutSendAction | UpdateBlackoutSuccessAction | UpdateBlackoutErrorAction
    | DeleteBlackoutStartAction | DeleteBlackoutSendAction
    | DeleteBlackoutSuccessAction | DeleteBlackoutCancelAction
    | DeleteBlackoutErrorAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;

export const actionCreators = {
    requestData: (fileId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementResourceDetails && !appState.managementResourceDetails.isLoading) {
            apiClientInstance.fetchRequest<ResourceDetails>(`v1/resources/${fileId}`)
                .then((data) => {
                    !!data.motdate && (data.motdate = new Date(data.motdate));
                    !!data.taxDate && (data.taxDate = new Date(data.taxDate));
                    dispatch({ type: 'RESOURCE_DETAILS_RECEIVE_DATA', data: data });
                })
                .catch((err) => {
                    dispatch({ type: 'RESOURCE_DETAILS_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'RESOURCE_DETAILS_REQUEST_DATA' });
        }
    },
    clearData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementResourceDetails && !appState.managementResourceDetails.isLoading) {
            dispatch({ type: 'RESOURCE_DETAILS_RECEIVE_DATA', data: EmptyData });
        }
    },
    updateField: (serverField: ResourceFieldsType, value: any, selectedItem?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementResourceDetails) {
            apiClientInstance.fetchRequest(
                `v1/resources/${appState.managementResourceDetails.data.resourceId}`,
                'PATCH',
                { field: serverField, value: value }
            )
                .then((data) => {
                    let infoObj = {...(appState.managementResourceDetails?.data ?? {} as ResourceDetails)};

                    switch (serverField) {
                        case ResourceFields.ResourceName:
                            infoObj.resourceName = (data as FieldUpdateResult<string>).value;
                            break;
                        case ResourceFields.HourCost:
                            infoObj.hourCost = (data as FieldUpdateResult<number>).value;
                            break;
                        case ResourceFields.ResourceType:
                            infoObj.resourceType = (data as FieldUpdateResult<string>).value;
                            break;
                        case ResourceFields.Crew:
                            infoObj.crew = (data as FieldUpdateResult<Array<number>>).value;
                            break;
                        case ResourceFields.HomeDivision:
                            let homeDiv = selectedItem as PartyListItem;
                            infoObj.homeDivisionId = (data as FieldUpdateResult<number>).value;
                            infoObj.homeDivisionName = homeDiv?.legalName ?? '';
                            break;
                        case ResourceFields.Depth:
                            infoObj.depth = (data as FieldUpdateResult<number>).value;
                            break;
                        case ResourceFields.Width:
                            infoObj.width = (data as FieldUpdateResult<number>).value;
                            break;
                        case ResourceFields.Height:
                            infoObj.height = (data as FieldUpdateResult<number>).value;
                            break;
                        case ResourceFields.Volume:
                            infoObj.volume = (data as FieldUpdateResult<number>).value;
                            break;
                        case ResourceFields.Tonnage:
                            infoObj.tonnage = (data as FieldUpdateResult<number>).value;
                            break;
                        case ResourceFields.MOTDate:
                            infoObj.motdate = (data as FieldUpdateResult<Date | null>).value;
                            break;
                        case ResourceFields.TaxDate:
                            infoObj.taxDate = (data as FieldUpdateResult<Date | null>).value;
                            break;
                        case ResourceFields.UsedFor:
                            infoObj.eventTypeUsedFor = (data as FieldUpdateResult<Array<string>>).value;
                            break;
                        case ResourceFields.Divisions:
                            infoObj.divisions = (data as FieldUpdateResult<Array<number>>).value;
                            break;
                        case ResourceFields.Qualifications:
                            infoObj.qualifications = (data as FieldUpdateResult<Array<string>>).value;
                            break;
                    }

                    dispatch({ type: 'RESOURCE_DETAILS_UPDATE_DATA', data: infoObj, });
                })
                .catch((err) => {
                    dispatch({ type: 'RESOURCE_DETAILS_UPDATE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'RESOURCE_DETAILS_START_UPDATE_DATA', field: serverField });
        }
    },
    requestBlackouts: (resourceId: number, skip: number, pageSize: number): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementResourceDetails && !appState.managementResourceDetails.blackouts.isLoading) {
            apiClientInstance.fetchRequest<DataRequest<ResourceBlackoutGridItem>>(`/v1/resources/${resourceId}/blackouts?pageSize=${pageSize}&skip=${skip}`)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ResourceDetailsBlackouts}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((blackout) => {
                        blackout.startDate = new Date(blackout.startDate);
                        blackout.endDate = new Date(blackout.endDate);
                    });

                    dispatch({
                        type: 'RESOURCE_DETAILS_BLACKOUTS_RECEIVE',
                        blackouts: data.data,
                        count: data.count,
                    });
                })
                .catch(err => {
                    dispatch({ type: 'RESOURCE_DETAILS_BLACKOUTS_RECEIVE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'RESOURCE_DETAILS_BLACKOUTS_REQUEST', skip: skip, });
        }
    },
    createBlackout: (model: ResourceBlackoutCreateModel, onSuccess?: (item: ResourceBlackoutGridItem) => any, onError?: () => any):  AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementResourceDetails && !appState.managementResourceDetails.blackouts.isLoading) {
            apiClientInstance.fetchRequest<number>(`/v1/resource-blackouts/`, 'POST', model)
                .then(data => {
                    let newItem = {...model, resourceBlackoutId: data} as ResourceBlackoutGridItem;

                    !!onSuccess && onSuccess(newItem);

                    dispatch({ type: 'RESOURCE_DETAILS_BLACKOUT_CREATE_SUCCESS', newItem: newItem });
                })
                .catch(err => {
                    !!onError && onError();

                    dispatch({ type: 'RESOURCE_DETAILS_BLACKOUT_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'RESOURCE_DETAILS_BLACKOUT_CREATE_SEND' });
        }
    },
    updateBlackout: (id: number, serverField: ResourceBlackoutFieldsType, value: any, onSuccess?: () => any, onError?: () => any):  AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementResourceDetails && !appState.managementResourceDetails.blackouts.isLoading) {
            apiClientInstance.fetchRequest(
                `v1/resource-blackouts/${id}`,
                'PATCH',
                { field: serverField, value: value }
            )
                .then((data) => {
                    let blackout = {...appState.managementResourceDetails?.blackouts.data.find(x => x.resourceBlackoutId === id) ?? {} as ResourceBlackoutGridItem};

                    switch (serverField) {
                        case ResourceBlackoutFields.StartDate:
                            blackout.startDate = (data as FieldUpdateResult<Date>).value;
                            break;
                        case ResourceBlackoutFields.EndDate:
                            blackout.endDate = (data as FieldUpdateResult<Date>).value;
                            break;
                        case ResourceBlackoutFields.Reason:
                            blackout.reason = (data as FieldUpdateResult<string>).value;
                            break;
                    }

                    !!onSuccess && onSuccess();

                    dispatch({ type: 'RESOURCE_DETAILS_BLACKOUT_UPDATE_SUCCESS', updatedItem: blackout, });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'RESOURCE_DETAILS_BLACKOUT_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'RESOURCE_DETAILS_BLACKOUT_UPDATE_SEND', field: serverField });
        }
    },
    startDeleteBlackout: ():  AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementResourceDetails && !appState.managementResourceDetails.blackouts.isLoading) {
            dispatch({ type: 'RESOURCE_DETAILS_BLACKOUT_DELETE_START' });
        }
    },
    deleteBlackout: (blackoutId: number):  AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementResourceDetails && !appState.managementResourceDetails.blackouts.isLoading) {
            apiClientInstance.fetchRequest(`/v1/resource-blackouts/${blackoutId}`, 'DELETE')
                .then(data => {
                    dispatch({ type: 'RESOURCE_DETAILS_BLACKOUT_DELETE_SUCCESS', deletedId: blackoutId });
                })
                .catch(err => {
                    dispatch({ type: 'RESOURCE_DETAILS_BLACKOUT_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'RESOURCE_DETAILS_BLACKOUT_DELETE_SEND' });
        }
    },
    cancelDeleteBlackout: ():  AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementResourceDetails && !appState.managementResourceDetails.blackouts.isLoading) {
            dispatch({ type: 'RESOURCE_DETAILS_BLACKOUT_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ResourceDetailsState = {
    data: EmptyData,
    isLoading: false,
    blackouts: {
        data: [],
        count: 0,
        skip: 0,
        isLoading: false,
        createStatus: ServerOperationStatus.NONE,
        updateStatus: ServerOperationStatus.NONE,
        deleteStatus: ServerOperationStatus.NONE,
    },
    updateStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ResourceDetailsState> = (state: ResourceDetailsState | undefined, incomingAction: Action): ResourceDetailsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RESOURCE_DETAILS_REQUEST_DATA':
            return {
                ...state,
                isLoading: true,
                updatedField: undefined
            };
        case 'RESOURCE_DETAILS_RECEIVE_DATA':
            return {
                ...state,
                data: action.data,
                isLoading: false,
                updatedField: undefined
            };
        case 'RESOURCE_DETAILS_RECEIVE_DATA_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'RESOURCE_DETAILS_START_UPDATE_DATA':
            return {
                ...state,
                updatedField: action.field,
            };
        case 'RESOURCE_DETAILS_UPDATE_DATA':
            return {
                ...state,
                data: action.data,
            };
        case 'RESOURCE_DETAILS_UPDATE_DATA_ERROR':
            return {
                ...state,
                updatedField: undefined,
            };

        case 'RESOURCE_DETAILS_BLACKOUTS_REQUEST':
            return {
                ...state,
                blackouts: {
                    ...state.blackouts,
                    skip: action.skip,
                },
            };
        case 'RESOURCE_DETAILS_BLACKOUTS_RECEIVE':
            return {
                ...state,
                blackouts: {
                    ...state.blackouts,
                    data: action.blackouts,
                    count: action.count,
                },
            };
        case 'RESOURCE_DETAILS_BLACKOUTS_RECEIVE_ERROR':
            return {
                ...state,
                blackouts: {
                    ...state.blackouts,
                    isLoading: false,
                },
            };

        case 'RESOURCE_DETAILS_BLACKOUT_UPDATE_SEND':
            return {
                ...state,
                blackouts: {
                    ...state.blackouts,
                    updatedField: action.field,
                    updateStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'RESOURCE_DETAILS_BLACKOUT_UPDATE_SUCCESS':
            {
                let list = [...state.blackouts.data];
                let index = state.blackouts.data.findIndex(x => x.resourceBlackoutId === action.updatedItem.resourceBlackoutId);

                list[index] = action.updatedItem;

                return {
                    ...state,
                    blackouts: {
                        ...state.blackouts,
                        data: list,
                        updatedField: undefined,
                        updateStatus: ServerOperationStatus.SUCCESS,
                    },
                };
            }
        case 'RESOURCE_DETAILS_BLACKOUT_UPDATE_ERROR':
            return {
                ...state,
                blackouts: {
                    ...state.blackouts,
                    updatedField: undefined,
                    updateStatus: ServerOperationStatus.ERROR
                },
            };

        case 'RESOURCE_DETAILS_BLACKOUT_CREATE_SEND':
            return {
                ...state,
                blackouts: {
                    ...state.blackouts,
                    createStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'RESOURCE_DETAILS_BLACKOUT_CREATE_SUCCESS':
            return {
                ...state,
                blackouts: {
                    ...state.blackouts,
                    createStatus: ServerOperationStatus.SUCCESS,
                    data: [...state.blackouts.data, action.newItem],
                },
            };
        case 'RESOURCE_DETAILS_BLACKOUT_CREATE_ERROR':
            return {
                ...state,
                blackouts: {
                    ...state.blackouts,
                    createStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'RESOURCE_DETAILS_BLACKOUT_DELETE_START':
            return {
                ...state,
                blackouts: {
                    ...state.blackouts,
                    deleteStatus: ServerOperationStatus.READY,
                },
            };
        case 'RESOURCE_DETAILS_BLACKOUT_DELETE_SEND':
            return {
                ...state,
                blackouts: {
                    ...state.blackouts,
                    deleteStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'RESOURCE_DETAILS_BLACKOUT_DELETE_SUCCESS':
            return {
                ...state,
                blackouts: {
                    ...state.blackouts,
                    data: state.blackouts.data.filter(x => x.resourceBlackoutId !== action.deletedId),
                    deleteStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'RESOURCE_DETAILS_BLACKOUT_DELETE_CANCEL':
            return {
                ...state,
                blackouts: {
                    ...state.blackouts,
                    deleteStatus: ServerOperationStatus.NONE,
                },
            };
        case 'RESOURCE_DETAILS_BLACKOUT_DELETE_ERROR':
            return {
                ...state,
                blackouts: {
                    ...state.blackouts,
                    deleteStatus: ServerOperationStatus.ERROR,
                },
            };
    }

    return state;
};