import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { ShowErrorAction } from '~store/infra/errors';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';

import ResourceItem from '~models/resource/resourceItem';
import ManagementResourcesFilters from '~enums/gridFilters/managementResourcesFilters';


export interface ManagementResourcesState {
    isLoading: boolean;
    resources: Array<ResourceItem>;
    createStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface ResourcesRequestAction {
    type: 'MANAGEMENT_RESOURCES_REQUEST';
}
interface ResourcesReceiveAction {
    type: 'MANAGEMENT_RESOURCES_RECEIVE';
    resources: Array<ResourceItem>;
}
interface ResourcesErrorAction {
    type: 'MANAGEMENT_RESOURCES_ERROR';
}

interface ResourcesCreateStartAction {
    type: 'MANAGEMENT_RESOURCES_CREATE_START';
}
interface ResourcesCreateSendAction {
    type: 'MANAGEMENT_RESOURCES_CREATE_SEND';
}
interface ResourcesCreateSuccessAction {
    type: 'MANAGEMENT_RESOURCES_CREATE_SUCCESS';
}
interface ResourcesCreateCancelAction {
    type: 'MANAGEMENT_RESOURCES_CREATE_CANCEL';
}
interface ResourcesCreateErrorAction {
    type: 'MANAGEMENT_RESOURCES_CREATE_ERROR';
}

interface ResourcesDeleteStartAction {
    type: 'MANAGEMENT_RESOURCES_DELETE_START';
}
interface ResourcesDeleteSendAction {
    type: 'MANAGEMENT_RESOURCES_DELETE_SEND';
}
interface ResourcesDeleteSuccessAction {
    type: 'MANAGEMENT_RESOURCES_DELETE_SUCCESS';
}
interface ResourcesDeleteCancelAction {
    type: 'MANAGEMENT_RESOURCES_DELETE_CANCEL';
}
interface ResourcesDeleteErrorAction {
    type: 'MANAGEMENT_RESOURCES_DELETE_ERROR';
}

type KnownAction = ResourcesRequestAction | ResourcesReceiveAction | ResourcesErrorAction
    | ResourcesCreateStartAction | ResourcesCreateSendAction
    | ResourcesCreateSuccessAction | ResourcesCreateCancelAction
    | ResourcesCreateErrorAction
    | ResourcesDeleteStartAction | ResourcesDeleteSendAction
    | ResourcesDeleteSuccessAction | ResourcesDeleteCancelAction
    | ResourcesDeleteErrorAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestResources: (filters: FilterList): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementResources && !appState.managementResources.isLoading) {
            let divisionId = FilterHandler.getDropDownValue(filters, ManagementResourcesFilters.HomeDivision, 'partyId', 'legalName')?.partyId;

            apiClientInstance.fetchRequest<DataRequest<ResourceItem>>(`/v1/resources${!!divisionId ? '?homeDivisionId='+divisionId : ''}`)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ManagementResources}-${UserPreferenceKey.Filters}`]: filters,
                    }));

                    dispatch({ type: 'MANAGEMENT_RESOURCES_RECEIVE', resources: data.data });
                })
                .catch(err => {
                    dispatch({ type: 'MANAGEMENT_RESOURCES_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_RESOURCES_REQUEST', });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementResourcesState = {
    isLoading: false,
    resources: [],
    createStatus: ServerOperationStatus.NONE,
    deleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementResourcesState> = (state: ManagementResourcesState | undefined, incomingAction: Action): ManagementResourcesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_RESOURCES_REQUEST':
            return {
                ...state,
                isLoading: true,
            };
        case 'MANAGEMENT_RESOURCES_RECEIVE':
            return {
                ...state,
                resources: action.resources,
                isLoading: false,
            };
        case 'MANAGEMENT_RESOURCES_ERROR':
            return {
                ...state,
                isLoading: false,
            };
    }

    return state;
};