import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { ShowErrorAction } from '~store/infra/errors';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import { DataRequest } from '~models/dataRequests';
import { SortDescriptor } from "@progress/kendo-data-query";
import { FilterHandler, FilterList } from '~models/filters';

import ExtendedDimGridItem from '~models/extendedDim/extendedDimGridItem';
import ExtendedDimFields, { ExtendedDimFieldsType } from '~enums/fields/extendedDim';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';
import ExtendedDimCreateModel from '~models/extendedDim/extendedDimCreateModel';


export interface ManagementExtendedDimsState {
    isLoading: boolean;
    dims: Array<ExtendedDimGridItem>;
    count: number;
    skip: number;
    updatedField?: ExtendedDimFieldsType;
    createStatus: ServerOperationStatus;
    updateStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface ExtendedDimsRequestAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_REQUEST';
    skip: number;
}
interface ExtendedDimsReceiveAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_RECEIVE';
    dims: Array<ExtendedDimGridItem>;
    count: number;
}
interface ExtendedDimsErrorAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_ERROR';
}

interface ExtendedDimsCreateSendAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_CREATE_SEND';
}
interface ExtendedDimsCreateSuccessAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_CREATE_SUCCESS';
    newItem: ExtendedDimGridItem;
}
interface ExtendedDimsCreateErrorAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_CREATE_ERROR';
}

interface ExtendedDimsUpdateSendAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_UPDATE_SEND';
    field: ExtendedDimFieldsType;
}
interface ExtendedDimsUpdateSuccessAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_UPDATE_SUCCESS';
    object: ExtendedDimGridItem;
}
interface ExtendedDimsUpdateErrorAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_UPDATE_ERROR';
}

interface ExtendedDimsDeleteStartAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_DELETE_START';
}
interface ExtendedDimsDeleteSendAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_DELETE_SEND';
}
interface ExtendedDimsDeleteSuccessAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_DELETE_SUCCESS';
}
interface ExtendedDimsDeleteCancelAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_DELETE_CANCEL';
}
interface ExtendedDimsDeleteErrorAction {
    type: 'MANAGEMENT_EXTENDEDDIMS_DELETE_ERROR';
}

type KnownAction = ExtendedDimsRequestAction | ExtendedDimsReceiveAction | ExtendedDimsErrorAction
    | ExtendedDimsCreateSendAction | ExtendedDimsCreateSuccessAction | ExtendedDimsCreateErrorAction
    | ExtendedDimsUpdateSendAction | ExtendedDimsUpdateSuccessAction | ExtendedDimsUpdateErrorAction
    | ExtendedDimsDeleteStartAction | ExtendedDimsDeleteSendAction
    | ExtendedDimsDeleteSuccessAction | ExtendedDimsDeleteCancelAction
    | ExtendedDimsDeleteErrorAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestDims: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExtendedDims && !appState.managementExtendedDims.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<ExtendedDimGridItem>>('/v1/extended-dims/search', 'POST', filters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ManagementExtendedDims}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.ManagementExtendedDims}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ManagementExtendedDims}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_RECEIVE', dims: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_REQUEST', skip: skip, });
        }
    },
    updateField: (id: number, serverField: ExtendedDimFieldsType, value: any, onSuccess?: () => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExtendedDims) {
            apiClientInstance.fetchRequest(
                `v1/extended-dims/${id}`,
                'PATCH',
                { field: serverField, value: value }
            )
                .then((data) => {
                    let dim = {...appState.managementExtendedDims?.dims.find(x => x.extendedDimId === id) ?? {} as ExtendedDimGridItem};

                    switch (serverField) {
                        case ExtendedDimFields.HandlingType:
                            dim.handlingType = (data as FieldUpdateResult<string>).value;
                            break;
                        case ExtendedDimFields.ItemCategory:
                            dim.itemCategory = (data as FieldUpdateResult<string>).value;
                            break;
                        case ExtendedDimFields.Depth:
                            dim.depth = (data as FieldUpdateResult<number>).value;
                            break;
                        case ExtendedDimFields.Width:
                            dim.width = (data as FieldUpdateResult<number>).value;
                            break;
                        case ExtendedDimFields.Height:
                            dim.height = (data as FieldUpdateResult<number>).value;
                            break;
                        case ExtendedDimFields.OverheadPercentage:
                            dim.overheadPercentage = (data as FieldUpdateResult<number>).value;
                            break;
                    }

                    !!onSuccess && onSuccess();

                    dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_UPDATE_SUCCESS', object: dim, });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_UPDATE_SEND', field: serverField });
        }
    },
    createDim: (model: ExtendedDimCreateModel, onSuccess?: (result: ExtendedDimGridItem) => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExtendedDims) {
            apiClientInstance.fetchRequest<number>(
                `v1/extended-dims/`,
                'POST',
                { ...model }
            )
                .then((data) => {
                    let newItem = {...model, extendedDimId: data,} as ExtendedDimGridItem;

                    !!onSuccess && onSuccess(newItem);

                    dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_CREATE_SUCCESS', newItem: newItem });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_CREATE_SEND' });
        }
    },
    startDeleteDim: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExtendedDims) {
            dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_DELETE_START' });
        }
    },
    submitDeleteDim: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExtendedDims) {
            apiClientInstance.fetchRequest(
                `/v1/extended-dims/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_DELETE_SEND' });
        }
    },
    cancelDeleteDim: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementExtendedDims) {
            dispatch({ type: 'MANAGEMENT_EXTENDEDDIMS_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementExtendedDimsState = {
    isLoading: false,
    dims: [],
    count: 0,
    skip: 0,
    createStatus: ServerOperationStatus.NONE,
    updateStatus: ServerOperationStatus.NONE,
    deleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementExtendedDimsState> = (state: ManagementExtendedDimsState | undefined, incomingAction: Action): ManagementExtendedDimsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_EXTENDEDDIMS_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'MANAGEMENT_EXTENDEDDIMS_RECEIVE':
            return {
                ...state,
                dims: action.dims,
                count: action.count,
                isLoading: false,
            };
        case 'MANAGEMENT_EXTENDEDDIMS_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'MANAGEMENT_EXTENDEDDIMS_UPDATE_SEND':
            return {
                ...state,
                updatedField: action.field,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_EXTENDEDDIMS_UPDATE_SUCCESS':
            {
                let list = [...state.dims];
                let index = state.dims.findIndex(x => x.extendedDimId === action.object.extendedDimId);

                list[index] = action.object;

                return {
                    ...state,
                    dims: list,
                    updatedField: undefined,
                    updateStatus: ServerOperationStatus.SUCCESS
                };
            };
        case 'MANAGEMENT_EXTENDEDDIMS_UPDATE_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_EXTENDEDDIMS_CREATE_SEND':
            return {
                ...state,
                createStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_EXTENDEDDIMS_CREATE_SUCCESS':
            return {
                ...state,
                createStatus: ServerOperationStatus.SUCCESS,
                dims: [...state.dims, action.newItem],
            };
        case 'MANAGEMENT_EXTENDEDDIMS_CREATE_ERROR':
            return {
                ...state,
                createStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_EXTENDEDDIMS_DELETE_START':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.READY,
            };
        case 'MANAGEMENT_EXTENDEDDIMS_DELETE_SEND':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_EXTENDEDDIMS_DELETE_CANCEL':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.NONE,
            };
        case 'MANAGEMENT_EXTENDEDDIMS_DELETE_SUCCESS':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'MANAGEMENT_EXTENDEDDIMS_DELETE_ERROR':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};