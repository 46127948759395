import * as React from 'react';
import { IntlProvider, load, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';

import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import ruNumbers from "cldr-numbers-full/main/ru/numbers.json";
import ruLocalCurrency from "cldr-numbers-full/main/ru/currencies.json";
import ruCaGregorian from "cldr-dates-full/main/ru/ca-gregorian.json";
import ruDateFields from "cldr-dates-full/main/ru/dateFields.json";

import grNumbers from "cldr-numbers-full/main/el/numbers.json";
import grLocalCurrency from "cldr-numbers-full/main/el/currencies.json";
import grCaGregorian from "cldr-dates-full/main/el/ca-gregorian.json";
import grDateFields from "cldr-dates-full/main/el/dateFields.json";

import enKendoMessages from "../../config/localization/kendo/en-US.json";
import grKendoMessages from "../../config/localization/kendo/el-GR.json";
import ruKendoMessages from "../../config/localization/kendo/ru-RU.json";

import { apiClientInstance } from '~services/auth/ApiClientInstance';
import SupportedLocale from '~enums/supportedLocale';
import { useEffect, useState } from 'react';

load(
    likelySubtags,
    currencyData,
    weekData,
    ruNumbers,
    ruLocalCurrency,
    ruCaGregorian,
    ruDateFields,
    grNumbers,
    grLocalCurrency,
    grCaGregorian,
    grDateFields,
);

type LocalizationWrapperProps = {
    children?: React.ReactNode;
    locale: SupportedLocale;
}

type LocalizationResource = {
    key: string;
    label: string;
}

const LocalizationWrapper = (props: LocalizationWrapperProps) => {
    const [currentLocale, setCurrentLocale] = useState<string>();

    const locale = props.locale;

    const requestData = React.useCallback((locale: string) => {
        apiClientInstance.fetchRequest<Array<LocalizationResource>>(`/v1/localization?locale=${locale}`, 'GET')
            .then((data) => {
                let messages = {} as {[key:string]: string};
                data.forEach(res => {
                    messages[res.key] = res.label;
                })
                loadMessages(messages, locale);
                switch (locale) {
                    case "en-US":
                        loadMessages(enKendoMessages, locale);
                        break;
                    case "ru-RU":
                        loadMessages(ruKendoMessages, locale);
                        break;
                    case "el-GR":
                        loadMessages(grKendoMessages, locale);
                        break;
                }
                setCurrentLocale(locale);
            });
    }, []);

    useEffect(() => {
        requestData(locale);
    }, [locale, requestData]);

    return (
        <LocalizationProvider language={props.locale}>
            <IntlProvider locale={props.locale}>
                {!!currentLocale && (props.children)}
            </IntlProvider>
        </LocalizationProvider>
    );
}

export default LocalizationWrapper;