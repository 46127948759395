import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '~store/ApplicationState';
import * as NewPartyStore from "~store/modals/newParty";
import * as CompanyModalStore from "~store/modals/companyModal";
import * as PartySearchStore from "~store/modals/partySearch";
import { PartySearchDispatchProps } from '~store/modals/partySearch';
import { handlePartyCreateResult } from "~store/modals/newPartyResultHandler";
import { defaultStringMapper } from "~src/utils/dropdownUtils";

import EnumType from "~enums/enumType";
import LocalizationKeys from "~enums/localizationKeys";
import { NewPartyKeys, getPartyTypesForKey } from "~enums/newPartyKeys";
import NewQuotationData from "~models/quotation/newQuotationData";
import { PartyType } from "~enums/partyType";
import QuotationStatus from "~enums/quotationStatus";
import { ServerOperationStatus } from "~enums/serverOperationStatus";
import { PartyListItem } from "~models/party/partyListItem";
import { PersonListItem } from "~models/person/personListItem";

import { Button } from "@progress/kendo-react-buttons";
import { provideLocalizationService, registerForLocalization } from "@progress/kendo-react-intl";
import { Close as CloseIcon } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";

import AMWDialog from "~controls/AMWDialog";
import OnDemandDropDown from "~controls/OnDemandDropdown";
import DropDownListWrapper from "~controls/DropDownListWrapper";


type NewQuotationModalOwnProps = {
    open: boolean;
    onSubmit: (model: NewQuotationData) => any;
    onClose: () => void;
    createStatus: ServerOperationStatus;
};

type NewQuotationModalStateProps = NewPartyStore.NewPartyElementStateProps;
type NewQuotationModalDispatchProps = PartySearchDispatchProps &
    NewPartyStore.NewPartyElementDispatchProps &
    typeof CompanyModalStore.outsideActions;

type NewQuotationModalProps = NewQuotationModalOwnProps &
    NewQuotationModalStateProps &
    NewQuotationModalDispatchProps;

interface NewQuotationState {
    clientId: number | null,
    clientName: string,
    clientContactId: number | null,
    clientContactName: string,
    shipperId: number | null,
    shipperName: string,
    shipperContactId: number | null,
    shipperContactName: string,
    jobType: string,
    currency: string,
    status: string,

    isValid: boolean,
}

class NewQuotationModal extends React.Component<NewQuotationModalProps, NewQuotationState> {
    clientDrop: OnDemandDropDown | null = null;
    clientContactDrop: OnDemandDropDown | null = null;
    shipperDrop: OnDemandDropDown | null = null;
    shipperContactDrop: OnDemandDropDown | null = null;

    constructor(props: NewQuotationModalProps) {
        super(props);
        this.state = {
            clientId: null,
            clientName: '',
            clientContactId: null,
            clientContactName: '',
            shipperId: null,
            shipperName: '',
            shipperContactId: null,
            shipperContactName: '',
            jobType: '',
            currency: '',
            status: '',

            isValid: true,
        };
    }

    componentDidUpdate() {
        handlePartyCreateResult.apply(this, [NewPartyKeys.NewQuoteClient, (result) => {
            this.clientDrop?.resetData();
            this.setState({
                clientId: result.partyId,
                clientName: result.fullName,
                clientContactId: result.personId,
                clientContactName: result.personName,
            });
        }]);

        handlePartyCreateResult.apply(this, [NewPartyKeys.NewQuoteShipper, (result) => {
            this.shipperDrop?.resetData();
            this.setState({
                shipperId: result.partyId,
                shipperName: result.fullName,
                shipperContactId: result.personId,
                shipperContactName: result.personName,
            });
        }]);
    }

    private isFormValid() {
        return this.state.clientId !== null &&
            this.state.clientContactId !== null &&
            this.state.shipperId !== null &&
            this.state.shipperContactId !== null &&
            this.state.status !== '' &&
            this.state.jobType !== '' &&
            this.state.currency !== '';
    }

    private onQuoteSubmit() {
        this.setState({
            isValid: this.isFormValid()
        }, () => {
            if (this.state.isValid) {
                this.props.onSubmit({
                    clientId: this.state.clientId ?? 0,
                    clientContactId: this.state.clientContactId,
                    shipperId: this.state.shipperId ?? 0,
                    shipperContactId: this.state.shipperContactId ?? 0,
                    status: this.state.status,
                    jobType: this.state.jobType,
                    currency: this.state.currency,
                });
            }
        });
    }

    private onClose() {
        this.setState({
            clientId: null,
            clientName: '',
            clientContactId: null,
            clientContactName: '',
            shipperId: null,
            shipperName: '',
            shipperContactId: null,
            shipperContactName: '',
            status: '',
            jobType: '',
            currency: '',

            isValid: true
        }, () => {
            this.clientContactDrop?.resetData();
            this.shipperContactDrop?.resetData();
            this.props.onClose();
        });
    }

    render() {
        const localization = provideLocalizationService(this);

        return (
            <AMWDialog
                open={this.props.open}
                onClose={this.onClose}
                titleContent={
                    <React.Fragment>
                        <span>{localization.toLanguageString(LocalizationKeys.Quotations.New.Title, "%New quotation")}</span>

                        <IconButton onClick={this.props.onClose}>
                            <CloseIcon></CloseIcon>
                        </IconButton>
                    </React.Fragment>
                }
                content={
                    <Grid container direction={"column"}>
                        <Grid item className="InputHolder">
                            <OnDemandDropDown
                                ref={(drop) => {
                                    this.clientDrop = drop;
                                }}
                                url="/v1/parties"
                                urlParams={{partyType: [PartyType.Client, PartyType.Dealer]}}
                                dataItemKey={'partyId'}
                                textField={'fullName'}
                                id="newQuoteClient"
                                name="client"
                                label={localization.toLanguageString(LocalizationKeys.Quotations.New.Client, "%Client")}
                                value={{legalName: this.state.clientName, partyId: this.state.clientId}}
                                pageSize={10}
                                onChange={(event) => {
                                    let value = event.value as PartyListItem;
                                    this.setState({
                                        clientId: value.partyId,
                                        clientName: value.legalName,
                                        clientContactId: null,
                                        clientContactName: ''
                                    }, () => {
                                        this.clientContactDrop?.resetData();
                                    })
                                }}
                                options={[
                                    {
                                        icon: 'search',
                                        text: localization.toLanguageString(LocalizationKeys.Shared.Buttons.PartySearch, "%Party search"),
                                        onSelect: () => { this.props.openSearchDialog(
                                            (id, party) => {
                                                this.setState({
                                                    clientId: id,
                                                    clientName: party.legalName,
                                                    clientContactId: null,
                                                    clientContactName: ''
                                                }, () => {
                                                    this.clientContactDrop?.resetData();
                                                })
                                            },
                                            getPartyTypesForKey(NewPartyKeys.NewQuoteClient)); }
                                    },
                                    {
                                        hidden: true,
                                        text: localization.toLanguageString(LocalizationKeys.Shared.Buttons.Create, "%Create"),
                                        onSelect: () => { this.props.partyCreation.openDialog(NewPartyKeys.NewQuoteClient) },
                                    },
                                    {
                                        hidden: true,
                                        text: localization.toLanguageString(LocalizationKeys.Shared.Buttons.CompanyDetails, "%Details"),
                                        onSelect: () => {!!this.state.clientId && this.props.openCompanyModal(this.state.clientId);},
                                        disabled: !this.state.clientId
                                    },
                                ]}
                                valid={this.state.isValid || this.state.clientId !== null}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <OnDemandDropDown
                                ref={(drop) => {
                                    this.clientContactDrop = drop;
                                }}
                                url={`/v1/parties/${this.state.clientId}/persons`}
                                dataItemKey={'personId'}
                                textField={'fullName'}
                                id="newQuotationClientContact"
                                name="clientContact"
                                label={localization.toLanguageString(LocalizationKeys.Quotations.New.ClientContact, "%Contact")}
                                value={{fullName: this.state.clientContactName, personId: `${this.state.clientContactId}`}}
                                pageSize={10}
                                onChange={(event) => {
                                    let value = event.value as PersonListItem;

                                    this.setState({
                                        clientContactId: value.personId,
                                        clientContactName: value.fullName,
                                    })
                                }}
                                disabled={this.state.clientId === null}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <OnDemandDropDown
                                ref={(drop) => {
                                    this.shipperDrop = drop;
                                }}
                                url="/v1/parties"
                                urlParams={{partyType: [PartyType.Client, PartyType.Dealer, PartyType.Vendor]}}
                                dataItemKey={'partyId'}
                                textField={'fullName'}
                                id="newQuotationShipper"
                                name="shipper"
                                label={localization.toLanguageString(LocalizationKeys.Quotations.New.Shipper, "%Shipper")}
                                value={{text: this.state.shipperName, id: `${this.state.shipperId}`}}
                                pageSize={10}
                                onChange={(event) => {
                                    let value = event.value as PartyListItem;

                                    this.setState({
                                        shipperId: value.partyId,
                                        shipperName: value.legalName,
                                        shipperContactId: null,
                                        shipperContactName: ''
                                    }, () => {
                                        this.shipperContactDrop?.resetData();
                                    })
                                }}
                                options={[
                                    {
                                        icon: 'search',
                                        text: localization.toLanguageString(LocalizationKeys.Shared.Buttons.PartySearch, "%Party search"),
                                        onSelect: () => { this.props.openSearchDialog(
                                            (id, party) => {
                                                this.setState({
                                                    shipperId: id,
                                                    shipperName: party.legalName,
                                                    shipperContactId: null,
                                                    shipperContactName: ''
                                                }, () => {
                                                    this.shipperContactDrop?.resetData();
                                                })
                                            },
                                            getPartyTypesForKey(NewPartyKeys.NewQuoteShipper)); }
                                    },
                                    {
                                        hidden: true,
                                        text: localization.toLanguageString(LocalizationKeys.Shared.Buttons.Create, "%Create"),
                                        onSelect: () => { this.props.partyCreation.openDialog(NewPartyKeys.NewQuoteShipper) },
                                    },
                                    {
                                        hidden: true,
                                        text: localization.toLanguageString(LocalizationKeys.Shared.Buttons.CompanyDetails, "%Details"),
                                        onSelect: () => {!!this.state.shipperId && this.props.openCompanyModal(this.state.shipperId);},
                                        disabled: !this.state.shipperId
                                    },
                                ]}
                                valid={this.state.isValid || this.state.shipperId !== null}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <OnDemandDropDown
                                ref={(drop) => {
                                    this.shipperContactDrop = drop;
                                }}
                                url={`/v1/parties/${this.state.shipperId}/persons`}
                                dataItemKey={'personId'}
                                textField={'fullName'}
                                id="newQuotationShipperContact"
                                name="shipperContact"
                                label={localization.toLanguageString(LocalizationKeys.Quotations.New.ShipperContact, "%Contact")}
                                value={{text: this.state.shipperContactName, id: `${this.state.shipperContactId}`}}
                                pageSize={10}
                                onChange={(event) => {
                                    let value = event.value as PersonListItem;

                                    this.setState({
                                        shipperContactId: value.personId,
                                        shipperContactName: value.fullName,
                                    })
                                }}
                                disabled={this.state.shipperId === null}
                                valid={this.state.isValid || this.state.shipperContactId !== null}
                            />
                        </Grid>

                        <Grid item className="InputHolder">
                            <OnDemandDropDown
                                url="/v1/configuration/"
                                urlParams={{type: EnumType.JobType}}
                                dataItemKey={'id'}
                                textField={'text'}
                                itemMapper={defaultStringMapper}
                                localizeText={true}
                                id="newQuotationJobType"
                                name="jobType"
                                label={localization.toLanguageString(LocalizationKeys.Quotations.New.JobType, "%Job type")}
                                value={{
                                    id: this.state.jobType,
                                    text: localization.toLanguageString(this.state.jobType, this.state.jobType)
                                }}
                                pageSize={10}
                                onChange={(event) => {
                                    this.setState({
                                        jobType: event.value.id
                                    });
                                }}
                                valid={this.state.isValid || this.state.jobType !== ''} />
                        </Grid>

                        <Grid item className="InputHolder">
                            <OnDemandDropDown
                                url="/v1/configuration/"
                                urlParams={{type: EnumType.Currency}}
                                dataItemKey={'id'}
                                textField={'text'}
                                itemMapper={defaultStringMapper}
                                localizeText={true}
                                id="newQuotationCurrency"
                                name="currency"
                                label={localization.toLanguageString(LocalizationKeys.Quotations.New.Currency, "%Currency")}
                                value={{
                                    id: this.state.currency,
                                    text: localization.toLanguageString(this.state.currency, this.state.currency)
                                }}
                                pageSize={10}
                                onChange={(event) => {
                                    this.setState({
                                        currency: event.value.id
                                    });
                                }}
                                valid={this.state.isValid || this.state.currency !== ''} />
                        </Grid>

                        <Grid item className="InputHolder">
                            <DropDownListWrapper
                                id="newQuotationStatus"
                                name="status"
                                label={localization.toLanguageString(LocalizationKeys.Quotations.New.Status, "%Status")}
                                dataItemKey='id'
                                textField="text"
                                data={Object.values(QuotationStatus).map(x => {
                                    return {
                                        id: x,
                                        text: localization.toLanguageString(x, x)
                                    };
                                })}
                                value={{
                                    id: this.state.status,
                                    text: localization.toLanguageString(this.state.status, this.state.status)
                                }}
                                onChange={(event) => {
                                    this.setState({
                                        status: event.value.id
                                    });
                                }}
                                valid={this.state.isValid || this.state.status !== ''} />
                        </Grid>
                    </Grid>
                }
                actionsContent={
                    <React.Fragment>
                        <Button
                            className={"Button--bold Button--orange"}
                            onClick={this.onQuoteSubmit.bind(this)}
                            disabled={this.props.createStatus === ServerOperationStatus.INPROGRESS}
                        >
                            {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Create, "%Create")}
                        </Button>

                        <Button
                            onClick={this.onClose.bind(this)}
                            disabled={this.props.createStatus === ServerOperationStatus.INPROGRESS}
                        >
                            {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Cancel, "%Cancel")}
                        </Button>
                    </React.Fragment>
                }
            />
        );
    }
}

registerForLocalization(NewQuotationModal as React.ComponentClass<any>);

export default connect(
    (state: ApplicationState) : NewQuotationModalStateProps => {
        return {
            partyCreation: state.newParty,
        };
    },
    (dispatch) : NewQuotationModalDispatchProps => {
        return {
            partyCreation: {...bindActionCreators({...NewPartyStore.actionCreators}, dispatch)},
            ...bindActionCreators({...PartySearchStore.actionCreators}, dispatch),
            ...bindActionCreators({...CompanyModalStore.outsideActions}, dispatch),
        };
    },
    (stateProps, dispatchProps, ownProps: NewQuotationModalOwnProps) : NewQuotationModalProps => {
        return {
            ...ownProps,
            ...stateProps,
            ...dispatchProps,
            partyCreation: {
                ...stateProps.partyCreation,
                ...dispatchProps.partyCreation
            },
        };
    }
)(NewQuotationModal as any);