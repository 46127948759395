const ShipmentFields = {
    Status: "ShipmentField.Status",
    TransportMode: "ShipmentField.TransportMode",
    Shipper: "ShipmentField.Shipper",
    Consignee: "ShipmentField.Consignee",
    Trucker: "ShipmentField.Trucker",
    Carrier: "ShipmentField.Carrier",
    CustomsOffice: "ShipmentField.CustomsOffice",
    CustomsOfficeAddress: "ShipmentField.CustomsOfficeAddress",
    ForwardingAgent: "ShipmentField.ForwardingAgent",
    ForwardingAgentAddress: "ShipmentField.ForwardingAgentAddress",
    DestAgent: "ShipmentField.DestAgent",
    DestAgentAddress: "ShipmentField.DestAgentAddress",
    ETD: "ShipmentField.ETD",
    ETA: "ShipmentField.ETA",
    DeliveryCutOff: "ShipmentField.DeliveryCutOff",
    DocumentsCutOff: "ShipmentField.DocumentsCutOff",
    DocumentFolder: "ShipmentField.DocumentFolder",
    Groupage: "ShipmentField.Groupage",
    Reference: "ShipmentField.Reference",
    Voyage: "ShipmentField.Voyage",
    Vessel: "ShipmentField.Vessel",
    HarmonizationCode: "ShipmentField.HarmonizationCode",
    ExternalRef: "ShipmentField.ExternalRef",
    POL: "ShipmentField.POL",
    POE: "ShipmentField.POE",
    POD: "ShipmentField.POD",
    ContentDescription: "ShipmentField.ContentDescription",
    Notes: "ShipmentField.Notes",
} as const;

export type ShipmentFieldsType = typeof ShipmentFields[keyof typeof ShipmentFields];

export default ShipmentFields;