const AppointmentPriority = Object.freeze({
    //TODO swap when entities are updated
    Low: "Low",
    Medium: "Medium",
    High: "High",
    //Low: "Enum.AppointmentPriority.Low",
    //Medium: "Enum.AppointmentPriority.Medium",
    //High: "Enum.AppointmentPriority.High",
});

export type AppointmentPriorityType = typeof AppointmentPriority[keyof typeof AppointmentPriority];

export default AppointmentPriority;