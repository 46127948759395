import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        xs: true;
        sm: true;
        smx: true;
        md: true;
        mdx: true;
        lg: true;
        lgx: true;
        xl: true;
    }
}

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            smx: 780,
            md: 960,
            mdx: 1024,
            lg: 1366,
            lgx: 1620,
            xl: 1920,
        },
    }
});

export default theme;