const QuickEntryFields = {
    Route: "QuickEntryField.Route",
    JobType: "QuickEntryField.JobType",
    Client: "QuickEntryField.Client",
    ClientAddress: "QuickEntryField.ClientAddress",
    ClientContact: "QuickEntryField.ClientContact",
    Ref1: "QuickEntryField.Ref1",
    NextDivision: "QuickEntryField.NextDivision",
    BypassWarehouse: "QuickEntryField.BypassWarehouse",

    InsuranceRequired: "QuickEntryField.InsuranceRequired",
    CIF: "QuickEntryField.CIF",
    InsuranceValue: "QuickEntryField.InsuranceValue",
    DeclinedOn: "QuickEntryField.DeclinedOn",
    DeclinedBy: "QuickEntryField.DeclinedBy",

    Shipper: "QuickEntryField.Shipper",
    ShipperAddress: "QuickEntryField.ShipperAddress",
    ShipperContact: "QuickEntryField.ShipperContact",
    Consignee: "QuickEntryField.Consignee",
    ConsigneeAddress: "QuickEntryField.ConsigneeAddress",
    ConsigneeContact: "QuickEntryField.ConsigneeContact",


    FileReminder: "QuickEntryField.FileReminder",
    Comments: "QuickEntryField.Comments",

    CreateInvoice: "QuickEntryField.CreateInvoice",
    InvoiceJobType: "QuickEntryField.InvoiceJobType",
    InvoiceTransportMode: "QuickEntryField.InvoiceTransportMode",
    InvoiceDescription: "QuickEntryField.InvoiceDescription",

    CRisCollection: "QuickEntryField.CRisCollection",
    CRtripId: "QuickEntryField.CRtripId",
    CRdivisionId: "QuickEntryField.CRdivisionId",
    CRfixedFlag: "QuickEntryField.CRfixedFlag",
    CRscheduled: "QuickEntryField.CRscheduled",
    CRresourceId: "QuickEntryField.CRresourceId",
    CRtariff: "QuickEntryField.CRtariff",
    CRtariffRange: "QuickEntryField.CRtariffRange",
    CRsuggestedCharge: "QuickEntryField.CRsuggestedCharge",
    CRuseZoneTariff: "QuickEntryField.CRuseZoneTariff",
    CRinstructions: "QuickEntryField.CRinstructions",

    DRisDelivery: "QuickEntryField.IsDelivery",
    DRtripId: "QuickEntryField.DRtripId",
    DRdivisionId: "QuickEntryField.DRdivisionId",
    DRfixedFlag: "QuickEntryField.DRfixedFlag",
    DRscheduled: "QuickEntryField.DRscheduled",
    DRresourceId: "QuickEntryField.DRresourceId",
    DRtariff: "QuickEntryField.DRtariff",
    DRtariffRange: "QuickEntryField.DRtariffRange",
    DRsuggestedCharge: "QuickEntryField.DRsuggestedCharge",
    DRuseZoneTariff: "QuickEntryField.DRuseZoneTariff",
    DRinstructions: "QuickEntryField.DRinstructions",

    PisPacking: "QuickEntryField.PisPacking",
    PtripId: "QuickEntryField.PtripId",
    PdivisionId: "QuickEntryField.PdivisionId",
    Pscheduled: "QuickEntryField.Pscheduled",
    Ptariff: "QuickEntryField.Ptariff",
    PtariffRange: "QuickEntryField.PtariffRange",
    PsuggestedCharge: "QuickEntryField.PsuggestedCharge",
    Pinstructions: "QuickEntryField.Pinstructions",

    TripSelection: "QuickEntryField.TripSelection",
} as const;

export type QuickEntryFieldsType = typeof QuickEntryFields[keyof typeof QuickEntryFields];

export default QuickEntryFields;