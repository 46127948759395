import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { actionCreators as UserActions } from '~store/auth/user';
import { ShowErrorAction } from '~store/infra/errors';

import PurchaseOrderFields, { PurchaseOrderFieldsType } from '~enums/fields/purchaseOrder';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import { DataRequest } from '~models/dataRequests';
import POItem from '~models/item/poItem';
import PurchaseOrderData from '~models/purchaseOrder/purchaseOrderData';
import { PartyListItem } from '~models/party/partyListItem';
import PurchaseOrderPatch from '~models/purchaseOrder/purchaseOrderPatch';
import PartyUpdateResult from '~models/updateResult/partyUpdateResult';
import AddressDetailsData from '~models/address/addressDetailsData';
import { SortDescriptor } from "@progress/kendo-data-query";
import { FilterHandler } from '~models/filters';
import { ItemDetailsUpdateDataAction } from '~store/modals/itemDetails';
import { GalleryUpdateAction } from '~store/modals/gallery';
import PhotoGalleryType from '~enums/photoGalleryType';


export interface PurchaseOrderState {
    isLoading: boolean;
    data: PurchaseOrderData;
    updatedField?: PurchaseOrderFieldsType;
    updateStatus: ServerOperationStatus;

    isLoadingItems: boolean;
    items: Array<POItem>;
    itemsCount: number;
    itemsSkip: number;
    itemsValue: number;

    itemCreateStatus: ServerOperationStatus;
    itemDeleteStatus: ServerOperationStatus;
    itemCloneStatus: ServerOperationStatus;
    itemSetOwnerStatus: ServerOperationStatus;
    itemGenerateIdStatus: ServerOperationStatus;
}

const EmptyData: PurchaseOrderData = {
    purchaseOrderId: 0,
    purchaseOrderNumber: '',
    clientFileId: 0,
    jobId: '',
    clientId: 0,
    clientName: '',
    dealerId: 0,
    dealerName: '',
    dealerAddressId: null,
    dealerAddress: undefined,
    creationDate: new Date(),
    divisionId: 0,
    divisionCode: '',
    currency: '',
    amountToPay: 0,
    status: '',
    dealerContactName: '',
    chequeNumber: '',
    reference: '',
    ownedBy: 0,
    ownedByName: '',
    comments: '',

    canEdit: false,
    canEditStatus: false,
};

// -----------------
// ---- ACTIONS ----
// -----------------

interface RequestDataAction {
    type: 'PURCHASE_ORDER_REQUEST_DATA';
}
interface ReceiveDataAction {
    type: 'PURCHASE_ORDER_RECEIVE_DATA';
    data: PurchaseOrderData;
}
interface ReceiveDataErrorAction {
    type: 'PURCHASE_ORDER_RECEIVE_DATA_ERROR';
}

interface UpdateDataAction {
    type: 'PURCHASE_ORDER_UPDATE_DATA';
    data: PurchaseOrderData;
}
interface UpdateDataRequestAction {
    type: 'PURCHASE_ORDER_UPDATE_DATA_REQUEST';
    field: PurchaseOrderFieldsType;
}
interface UpdateDataErrorAction {
    type: 'PURCHASE_ORDER_UPDATE_DATA_ERROR';
}

interface RequestItemsAction {
    type: 'PURCHASE_ORDER_REQUEST_ITEMS';
    pageSize: number;
    skip: number;
}
interface ReceiveItemsAction {
    type: 'PURCHASE_ORDER_RECEIVE_ITEMS';
    items: Array<POItem>;
    count: number;
}
interface ReceiveItemsErrorAction {
    type: 'PURCHASE_ORDER_RECEIVE_ITEMS_ERROR';
}

interface RequestValueAction {
    type: 'PURCHASE_ORDER_REQUEST_VALUE';
}
interface ReceiveValueAction {
    type: 'PURCHASE_ORDER_RECEIVE_VALUE';
    itemsValue: number;
}
interface ReceiveValueErrorAction {
    type: 'PURCHASE_ORDER_RECEIVE_VALUE_ERROR';
}

interface ItemCreateStartAction {
    type: 'PURCHASE_ORDER_ITEM_CREATE_START';
}
interface ItemCreateSendAction {
    type: 'PURCHASE_ORDER_ITEM_CREATE_SEND';
}
interface ItemCreateSuccessAction {
    type: 'PURCHASE_ORDER_ITEM_CREATE_SUCCESS';
}
interface ItemCreateCancelAction {
    type: 'PURCHASE_ORDER_ITEM_CREATE_CANCEL';
}
interface ItemCreateErrorAction {
    type: 'PURCHASE_ORDER_ITEM_CREATE_ERROR';
}

interface ItemDeleteStartAction {
    type: 'PURCHASE_ORDER_ITEM_DELETE_START';
}
interface ItemDeleteSendAction {
    type: 'PURCHASE_ORDER_ITEM_DELETE_SEND';
}
interface ItemDeleteSuccessAction {
    type: 'PURCHASE_ORDER_ITEM_DELETE_SUCCESS';
}
interface ItemDeleteCancelAction {
    type: 'PURCHASE_ORDER_ITEM_DELETE_CANCEL';
}
interface ItemDeleteErrorAction {
    type: 'PURCHASE_ORDER_ITEM_DELETE_ERROR';
}

interface ItemCloneSendAction {
    type: 'PURCHASE_ORDER_ITEM_CLONE_SEND';
}
interface ItemCloneSuccessAction {
    type: 'PURCHASE_ORDER_ITEM_CLONE_SUCCESS';
}
interface ItemCloneErrorAction {
    type: 'PURCHASE_ORDER_ITEM_CLONE_ERROR';
}

interface ItemSetOwnerSendAction {
    type: 'PURCHASE_ORDER_ITEM_SETOWNER_SEND';
}
interface ItemSetOwnerSuccessAction {
    type: 'PURCHASE_ORDER_ITEM_SETOWNER_SUCCESS';
}
interface ItemSetOwnerErrorAction {
    type: 'PURCHASE_ORDER_ITEM_SETOWNER_ERROR';
}

interface ItemGenerateIdSendAction {
    type: 'PURCHASE_ORDER_ITEM_GENERATEID_SEND';
}
interface ItemGenerateIdSuccessAction {
    type: 'PURCHASE_ORDER_ITEM_GENERATEID_SUCCESS';
}
interface ItemGenerateIdErrorAction {
    type: 'PURCHASE_ORDER_ITEM_GENERATEID_ERROR';
}

type KnownAction = RequestDataAction | ReceiveDataAction | ReceiveDataErrorAction
    | UpdateDataAction | UpdateDataRequestAction | UpdateDataErrorAction
    | RequestItemsAction | ReceiveItemsAction | ReceiveItemsErrorAction
    | RequestValueAction | ReceiveValueAction | ReceiveValueErrorAction

    | ItemCreateStartAction | ItemCreateSendAction | ItemCreateSuccessAction | ItemCreateCancelAction | ItemCreateErrorAction

    | ItemDeleteStartAction | ItemDeleteSendAction | ItemDeleteSuccessAction | ItemDeleteCancelAction | ItemDeleteErrorAction

    | ItemCloneSendAction | ItemCloneSuccessAction | ItemCloneErrorAction
    | ItemSetOwnerSendAction | ItemSetOwnerSuccessAction | ItemSetOwnerErrorAction
    | ItemGenerateIdSendAction | ItemGenerateIdSuccessAction | ItemGenerateIdErrorAction

    | ItemDetailsUpdateDataAction | GalleryUpdateAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;

const buildPatch = (field: PurchaseOrderFieldsType, value: any) => {
    const patchModel = {field: field} as PurchaseOrderPatch;

    switch (field) {
        case PurchaseOrderFields.PurchaseOrderNumber:
            patchModel.purchaseOrderNumber = value as string;
            break;
        case PurchaseOrderFields.Dealer:
            patchModel.dealerId = value as number;
            break;
        case PurchaseOrderFields.Status:
            patchModel.status = value as string;
            break;
        case PurchaseOrderFields.Division:
            patchModel.divisionId = value as number;
            break;
        case PurchaseOrderFields.DealerAddress:
            patchModel.dealerAddressId = value as number;
            break;
        case PurchaseOrderFields.DealerContact:
            patchModel.dealerContactName = value as string;
            break;
        case PurchaseOrderFields.Currency:
            patchModel.currency = value as string;
            break;
        case PurchaseOrderFields.AmountToPay:
            patchModel.amountToPay = value as number;
            break;
        case PurchaseOrderFields.ChequeNumber:
            patchModel.chequeNumber = value as string;
            break;
        case PurchaseOrderFields.Reference:
            patchModel.reference = value as string;
            break;
        case PurchaseOrderFields.Comments:
            patchModel.comments = value as string;
            break;
    }

    return patchModel;
};

export const actionCreators = {
    requestData: (orderId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.purchaseOrder && !appState.purchaseOrder.isLoading) {
            apiClientInstance.fetchRequest<PurchaseOrderData>(`v1/po/${orderId}`)
                .then((data) => {
                    data.creationDate = new Date(data.creationDate);
                    dispatch({ type: 'PURCHASE_ORDER_RECEIVE_DATA', data: data });
                })
                .catch((err) => {
                    dispatch({ type: 'PURCHASE_ORDER_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'PURCHASE_ORDER_REQUEST_DATA' });
        }
    },
    updateField: (serverField: PurchaseOrderFieldsType, value: any, selectedItem?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.purchaseOrder) {
            const patchModel = buildPatch(serverField, value);

            apiClientInstance.fetchRequest(
                `v1/po/${appState.purchaseOrder.data.purchaseOrderId}`,
                'PATCH',
                patchModel
            )
                .then((data) => {
                    let infoObj = {...(appState.purchaseOrder?.data ?? {} as PurchaseOrderData)};

                    switch (serverField) {
                        case PurchaseOrderFields.PurchaseOrderNumber:
                            infoObj.purchaseOrderNumber = value as string;
                            break;
                        case PurchaseOrderFields.Dealer:
                            let dealer = selectedItem as PartyListItem;
                            let dealerData = (data as PartyUpdateResult);
                            infoObj.dealerId = dealerData.partyId;
                            infoObj.dealerName = dealer?.fullName ?? '';
                            infoObj.dealerAddressId = dealerData.address?.addressId ?? 0;
                            infoObj.dealerAddress = dealerData.address;
                            break;
                        case PurchaseOrderFields.DealerAddress:
                            infoObj.dealerAddressId = value as number;
                            infoObj.dealerAddress = selectedItem as AddressDetailsData;
                            break;
                        case PurchaseOrderFields.Status:
                            infoObj.status = value as string;
                            break;
                        case PurchaseOrderFields.Division:
                            let division = selectedItem as PartyListItem;
                            infoObj.divisionId = division?.partyId;
                            infoObj.divisionCode = division?.code ?? '';
                            break;
                        case PurchaseOrderFields.DealerContact:
                            infoObj.dealerContactName = value as string;
                            break;
                        case PurchaseOrderFields.Currency:
                            infoObj.currency = value as string;
                            break;
                        case PurchaseOrderFields.ChequeNumber:
                            infoObj.chequeNumber = value as string;
                            break;
                        case PurchaseOrderFields.Reference:
                            infoObj.reference = value as string;
                            break;
                        case PurchaseOrderFields.Comments:
                            infoObj.comments = value as string;
                            break;
                    }

                    dispatch({ type: 'PURCHASE_ORDER_UPDATE_DATA', data: infoObj });
                })
                .catch((err) => {
                    dispatch({ type: 'PURCHASE_ORDER_UPDATE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'PURCHASE_ORDER_UPDATE_DATA_REQUEST', field: serverField });
        }
    },
    requestItems: (orderId: number, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.purchaseOrder && !appState.purchaseOrder.isLoadingItems) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<POItem>>(`/v1/po/${orderId}/items`, '', {}, skip, pageSize, sorting)
                .then((data) => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.PurchaseOrderItems}-${UserPreferenceKey.PageSize}`]: pageSize,
                        [`${UserPreferencePage.PurchaseOrderItems}-${UserPreferenceKey.Sorting}`]: sorting,
                    }));

                    dispatch({ type: 'PURCHASE_ORDER_RECEIVE_ITEMS', items: data.data, count: data.count });
                })
                .catch((err) => {
                    dispatch({ type: 'PURCHASE_ORDER_RECEIVE_ITEMS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'PURCHASE_ORDER_REQUEST_ITEMS', pageSize: pageSize, skip: skip });
        }
    },
    requestValue: (orderId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.purchaseOrder) {
            apiClientInstance.fetchRequest<number>(`v1/po/${orderId}/items/value`)
                .then((data) => {
                    dispatch({ type: 'PURCHASE_ORDER_RECEIVE_VALUE', itemsValue: data });
                })
                .catch((err) => {
                    dispatch({ type: 'PURCHASE_ORDER_RECEIVE_VALUE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'PURCHASE_ORDER_REQUEST_VALUE' });
        }
    },

    startCreateItem: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.purchaseOrder) {
            dispatch({type: 'PURCHASE_ORDER_ITEM_CREATE_START'})
        }
    },
    createItem: (orderId: number, description: string):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.purchaseOrder) {
            apiClientInstance.fetchRequest(
                `/v1/po/${orderId}/items`,
                'POST',
                {
                    description: description,
                }
            )
                .then((newId) => {
                    dispatch({ type: 'PURCHASE_ORDER_ITEM_CREATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'PURCHASE_ORDER_ITEM_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'PURCHASE_ORDER_ITEM_CREATE_SEND' });
        }
    },
    cancelCreateItem: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.purchaseOrder) {
            dispatch({ type: 'PURCHASE_ORDER_ITEM_CREATE_CANCEL' });
        }
    },

    startDeleteItem: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.purchaseOrder) {
            dispatch({ type: 'PURCHASE_ORDER_ITEM_DELETE_START' })
        }
    },
    deleteItems: (itemIds: Array<number>):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.purchaseOrder) {
            apiClientInstance.fetchRequest(
                `/v1/items/delete?ItemIds=${itemIds.join(',')}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'PURCHASE_ORDER_ITEM_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'PURCHASE_ORDER_ITEM_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'PURCHASE_ORDER_ITEM_DELETE_SEND' });
        }
    },
    cancelDeleteItem: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.purchaseOrder) {
            dispatch({ type: 'PURCHASE_ORDER_ITEM_DELETE_CANCEL' })
        }
    },

    cloneItems: (itemIds: Array<number>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.purchaseOrder && (appState.purchaseOrder.itemCloneStatus !== ServerOperationStatus.INPROGRESS)) {
            apiClientInstance.fetchRequest(
                `/v1/items/clone`,
                'POST',
                {
                    itemIds
                }
            )
                .then(() => {
                    dispatch({ type: 'PURCHASE_ORDER_ITEM_CLONE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'PURCHASE_ORDER_ITEM_CLONE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'PURCHASE_ORDER_ITEM_CLONE_SEND' });
        }
    },
    setOwner: (itemId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.purchaseOrder && (appState.purchaseOrder.itemSetOwnerStatus !== ServerOperationStatus.INPROGRESS)) {
            apiClientInstance.fetchRequest(
                `/v1/items/set-owner`,
                'POST',
                {
                    itemId
                }
            )
                .then(() => {
                    dispatch({ type: 'PURCHASE_ORDER_ITEM_SETOWNER_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'PURCHASE_ORDER_ITEM_SETOWNER_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'PURCHASE_ORDER_ITEM_SETOWNER_SEND' });
        }
    },

    generateItemIds: (itemIds: Array<number>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.purchaseOrder && (appState.purchaseOrder.itemGenerateIdStatus !== ServerOperationStatus.INPROGRESS)) {
            apiClientInstance.fetchRequest(
                `/v1/items/id-generate`,
                'POST',
                {
                    itemIds
                }
            )
                .then(() => {
                    dispatch({ type: 'PURCHASE_ORDER_ITEM_GENERATEID_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'PURCHASE_ORDER_ITEM_GENERATEID_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'PURCHASE_ORDER_ITEM_GENERATEID_SEND' });
        }
    },
};

// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: PurchaseOrderState = {
    isLoading: false,
    data: EmptyData,
    updateStatus: ServerOperationStatus.NONE,
    isLoadingItems: false,
    items: [],
    itemsValue: 0,
    itemsCount: 0,
    itemsSkip: 0,
    itemCreateStatus: ServerOperationStatus.NONE,
    itemDeleteStatus: ServerOperationStatus.NONE,
    itemCloneStatus: ServerOperationStatus.NONE,
    itemSetOwnerStatus: ServerOperationStatus.NONE,
    itemGenerateIdStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<PurchaseOrderState> = (state: PurchaseOrderState | undefined, incomingAction: Action): PurchaseOrderState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'PURCHASE_ORDER_REQUEST_DATA':
            return {
                ...state,
                isLoading: true,
                updatedField: undefined,
                isLoadingItems: false,
                items: [],
                itemsValue: 0,
                itemsCount: 0,
                itemsSkip: 0,
            };
        case 'PURCHASE_ORDER_RECEIVE_DATA':
            return {
                ...state,
                data: action.data,
                isLoading: false,
                updatedField: undefined,
                isLoadingItems: false,
            };
        case 'PURCHASE_ORDER_RECEIVE_DATA_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'PURCHASE_ORDER_UPDATE_DATA_REQUEST':
            return {
                ...state,
                updatedField: action.field,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'PURCHASE_ORDER_UPDATE_DATA':
            return {
                ...state,
                data: action.data,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'PURCHASE_ORDER_UPDATE_DATA_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            };

        case 'PURCHASE_ORDER_REQUEST_ITEMS':
            return {
                ...state,
                isLoadingItems: true,
                itemsSkip: action.skip,
            };
        case 'PURCHASE_ORDER_RECEIVE_ITEMS':
            return {
                ...state,
                isLoadingItems: false,
                items: action.items,
                itemsCount: action.count,
            };
        case 'PURCHASE_ORDER_RECEIVE_ITEMS_ERROR':
            return {
                ...state,
                isLoadingItems: false,
            };

        case 'PURCHASE_ORDER_REQUEST_VALUE':
            return {
                ...state,
            };
        case 'PURCHASE_ORDER_RECEIVE_VALUE':
            return {
                ...state,
                itemsValue: action.itemsValue,
            };
        case 'PURCHASE_ORDER_RECEIVE_VALUE_ERROR':
            return {
                ...state,
            };

        case 'PURCHASE_ORDER_ITEM_CREATE_START':
            return {
                ...state,
                itemCreateStatus: ServerOperationStatus.READY
            };
        case 'PURCHASE_ORDER_ITEM_CREATE_SEND':
            return {
                ...state,
                itemCreateStatus: ServerOperationStatus.INPROGRESS
            };
        case 'PURCHASE_ORDER_ITEM_CREATE_SUCCESS':
            return {
                ...state,
                itemCreateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'PURCHASE_ORDER_ITEM_CREATE_CANCEL':
            return {
                ...state,
                itemCreateStatus: ServerOperationStatus.NONE,
            };
        case 'PURCHASE_ORDER_ITEM_CREATE_ERROR':
            return {
                ...state,
                itemCreateStatus: ServerOperationStatus.ERROR,
            };

        case 'PURCHASE_ORDER_ITEM_DELETE_START':
            return {
                ...state,
                itemDeleteStatus: ServerOperationStatus.READY,
            };
        case 'PURCHASE_ORDER_ITEM_DELETE_SEND':
            return {
                ...state,
                itemDeleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'PURCHASE_ORDER_ITEM_DELETE_SUCCESS':
            return {
                ...state,
                itemDeleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'PURCHASE_ORDER_ITEM_DELETE_CANCEL':
            return {
                ...state,
                itemDeleteStatus: ServerOperationStatus.NONE,
            };
        case 'PURCHASE_ORDER_ITEM_DELETE_ERROR':
            return {
                ...state,
                itemDeleteStatus: ServerOperationStatus.ERROR,
            };

        case 'PURCHASE_ORDER_ITEM_CLONE_SEND':
            return {
                ...state,
                itemCloneStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'PURCHASE_ORDER_ITEM_CLONE_SUCCESS':
            return {
                ...state,
                itemCloneStatus: ServerOperationStatus.SUCCESS,
            };
        case 'PURCHASE_ORDER_ITEM_CLONE_ERROR':
            return {
                ...state,
                itemCloneStatus: ServerOperationStatus.ERROR,
            };

        case 'PURCHASE_ORDER_ITEM_SETOWNER_SEND':
            return {
                ...state,
                itemSetOwnerStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'PURCHASE_ORDER_ITEM_SETOWNER_SUCCESS':
            return {
                ...state,
                itemSetOwnerStatus: ServerOperationStatus.SUCCESS,
            };
        case 'PURCHASE_ORDER_ITEM_SETOWNER_ERROR':
            return {
                ...state,
                itemSetOwnerStatus: ServerOperationStatus.ERROR,
            };

        case 'PURCHASE_ORDER_ITEM_GENERATEID_SEND':
            return {
                ...state,
                itemGenerateIdStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'PURCHASE_ORDER_ITEM_GENERATEID_SUCCESS':
            return {
                ...state,
                itemGenerateIdStatus: ServerOperationStatus.SUCCESS,
            };
        case 'PURCHASE_ORDER_ITEM_GENERATEID_ERROR':
            return {
                ...state,
                itemGenerateIdStatus: ServerOperationStatus.ERROR,
            };

        case 'ITEM_DETAILS_UPDATE_DATA':
            {
                const items = state.items;
                const newItems = [...items];
                const updatedItem = newItems.find(x => x.itemId === action.data.itemId);
                if (!!updatedItem) {
                    const index = newItems.indexOf(updatedItem);
                    newItems[index] = {
                        itemId: action.data.itemId,
                        uniqueId: action.data.uniqueId,
                        partsQty: action.data.partsQty,
                        piecesQty: action.data.piecesQty,
                        description: action.data.description,
                        sidemark: action.data.sidemark,
                        locationName: action.data.locationRef,
                        lot: action.data.lot,
                        artist: action.data.artist,
                        title: action.data.title,
                        packageType: action.data.packageType,
                        volume: action.data.volume,
                        weight: action.data.weight,
                        value: action.data.value,
                        grossVolume: action.data.grossVolume,
                        category: action.data.category,
                        hasPhotos: updatedItem.hasPhotos,
                    };
                    return {
                        ...state,
                        items: newItems,
                    };
                } else {
                    return {
                        ...state,
                    };
                }
            }
        case 'GALLERY_UPDATE_SUCCESS':
            {
                if (action.galleryType === PhotoGalleryType.Item) {
                    const items = state.items;
                    const newItems = [...items];
                    const updatedItem = newItems.find(x => x.itemId === action.objectId);

                    if (!!updatedItem) {
                        const index = newItems.indexOf(updatedItem);
                        newItems[index] = {
                            ...updatedItem,
                            hasPhotos: action.photoList.length > 0,
                        };
                        return {
                            ...state,
                            items: newItems,
                        };
                    }
                }

                return {
                    ...state,
                };
            }
    }

    return state;
};