const ExcelSourceFields = Object.freeze({
    SourceName: "ExcelSourceConfigField.SourceName",
    TableInv: "ExcelSourceConfigField.TableInv",
    TableGeneralInfo: "ExcelSourceConfigField.TableGeneralInfo",
    TableSkids: "ExcelSourceConfigField.TableSkids",
    FieldEmfId: "ExcelSourceConfigField.FieldEmfId",
    ItemType: "ExcelSourceConfigField.ItemType",
    SkidType: "ExcelSourceConfigField.SkidType",
    ShipmentType: "ExcelSourceConfigField.ShipmentType",
    MaxRecords: "ExcelSourceConfigField.MaxRecords",
});

export type ExcelSourceFieldsType = typeof ExcelSourceFields[keyof typeof ExcelSourceFields];

export default ExcelSourceFields;