import React, { useEffect } from 'react';

import LocalizationKeys from '~enums/localizationKeys';
import { CRMRecentTabs } from '../CRM';
import { Grid } from '@mui/material';
import { useLocalization } from '@progress/kendo-react-intl';

import AMWTabs from '~components/controls/AMWTabs';
import CompaniesTab from './Tabs/CompaniesTab';
import ContactsTab from './Tabs/ContactsTab';
import OpportunitiesTab from './Tabs/OpportunitiesTab';
import TasksTab from './Tabs/TasksTab';
import AppointmentsTab from './Tabs/AppointmentsTab';
import CommunicationsTab from './Tabs/CommunicationsTab';
import DocumentsTab from './Tabs/DocumentsTab';

type RecentTabProps = {
    selectedTab: number;
    onTabSelect: (value: string) => void;
    topbarHeight: number;
};

const RecentTab: React.FC<RecentTabProps> = (props) => {
    const localization = useLocalization();
    const topbarHeight = props.topbarHeight;

    useEffect(() => {
        let element = document.querySelector('#CRMRecentTabs');
        let tabs = document.querySelector('#CRMTabs');
        !!element && !!tabs && element.setAttribute('style', `top: ${topbarHeight + tabs.clientHeight}px` );
    }, [topbarHeight]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <AMWTabs
                    id={'CRMRecentTabs'}
                    sticky={true}
                    className={"AMWTabs--Second"}
                    value={`${props.selectedTab}`}
                    onChange={props.onTabSelect}
                    tabs={[
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Recent.Tabs.Companies, "%Companies"),
                            content: <CompaniesTab />,
                            value: `${CRMRecentTabs.Companies}`,
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Recent.Tabs.Contacts, "%Contacts"),
                            content: <ContactsTab />,
                            value: `${CRMRecentTabs.Contacts}`,
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Recent.Tabs.Opportunities, "%Opportunities"),
                            content: <OpportunitiesTab />,
                            value: `${CRMRecentTabs.Opportunities}`,
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Recent.Tabs.Tasks, "%Tasks"),
                            content: <TasksTab />,
                            value: `${CRMRecentTabs.Tasks}`,
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Recent.Tabs.Appointments, "%Appointments"),
                            content: <AppointmentsTab />,
                            value: `${CRMRecentTabs.Appointments}`,
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Recent.Tabs.Communications, "%Communications"),
                            content: <CommunicationsTab />,
                            value: `${CRMRecentTabs.Communications}`,
                        },
                        {
                            label: localization.toLanguageString(LocalizationKeys.CRM.Recent.Tabs.Documents, "%Documents"),
                            content: <DocumentsTab />,
                            value: `${CRMRecentTabs.Documents}`,
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
}

export default RecentTab;