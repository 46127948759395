import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';
import history from '~services/history/history';

export interface NavigationState {
    isLocked: boolean;
    message: string;
}

// -----------------
// ---- ACTIONS ----
// -----------------

export interface LockNavigationAction {
    type: 'NAVIGATION_LOCK';
    message: string;
}

export interface UnlockNavigationAction {
    type: 'NAVIGATION_UNLOCK';
    route?: string;
}

export interface NavigateAction {
    type: 'NAVIGATION_NAVIGATE';
    route: string;
}

type KnownAction = LockNavigationAction | UnlockNavigationAction
    | NavigateAction;

export const actionCreators = {
    lockNavigation: (message: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.navigation) {
            dispatch({ type: 'NAVIGATION_LOCK', message: message });
        }
    },
    unlockNavigation: (route?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.navigation) {
            dispatch({ type: 'NAVIGATION_UNLOCK', route: route, });
        }
    },
    navigate: (route: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.navigation) {
            if (appState.navigation.isLocked && window.confirm(appState.navigation.message)) {
                dispatch({ type: 'NAVIGATION_UNLOCK', route: route, });
            } else {
                dispatch({ type: 'NAVIGATION_NAVIGATE', route: route });
            }
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: NavigationState = {
    message: '',
    isLocked: false,
 };

export const reducer: Reducer<NavigationState> = (state: NavigationState | undefined, incomingAction: Action): NavigationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'NAVIGATION_LOCK':
            return {
                ...state,
                isLocked: true,
                message: action.message,
            };

        case 'NAVIGATION_UNLOCK':
            setTimeout(() => {
                action.route !== undefined && history.push(action.route);
            }, 0);

            return {
                ...state,
                isLocked: false,
                message: '',
            };

        case 'NAVIGATION_NAVIGATE':
            setTimeout(() => {
                history.push(action.route);
            }, 0);

            return {
                ...state,
            };
    }

    return state;
};