const CRMCompaniesFilters = {
    From: "From",
    To: "To",
    Filter: "Filter",
    Address: "Address",
    State: "State",
    Country: "Country",
    PartyType: "PartyType",
    PartyCategory: "PartyCategory",
    ExcludeSystemTypes: "ExcludeSystemTypes",
} as const;

export type CRMCompaniesFiltersType = typeof CRMCompaniesFilters[keyof typeof CRMCompaniesFilters];

export default CRMCompaniesFilters;