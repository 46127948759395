import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { useOidc } from "@axa-fr/react-oidc";
import * as UserStore from '~store/auth/user';
import { ApplicationState } from "~store/ApplicationState";

import {
    ArrowDropDown as ArrowDropDownIcon,
    FileCopy as SOPIcon,
    Folder as ReportsIcon,
    Settings as SettingsIcon,
    Logout as LogoutIcon
 } from '@mui/icons-material';
import { Divider, Menu, MenuItem } from '@mui/material';
import { Button } from "@progress/kendo-react-buttons";
import { useLocalization } from "@progress/kendo-react-intl";

import '~css/components/navigation/AccountMenu.scss';
import LocalizationKeys from "~enums/localizationKeys";


type AccountMenuStateProps = UserStore.UserState
type AccountMenuDispatchProps = typeof UserStore.actionCreators;
type AccountMenuProps = AccountMenuStateProps & AccountMenuDispatchProps;

const AccountMenu = (props: AccountMenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const localization = useLocalization();
    const oidc = useOidc();

    const onMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onMenuClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        oidc.logout("/");
    };

    return (
        <div className="AccountMenu AccountMenu__root">
            <Button
                className="AccountMenu__button"
                fillMode="flat"
                onClick={onMenuClick}
            >
                {props.userData?.name}

                <ArrowDropDownIcon />
            </Button>

            <Menu
                id="account-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={!!anchorEl}
                onClose={onMenuClose}
                className={"AccountMenu__menu"}
            >
                <MenuItem onClick={onMenuClose}>
                    <ReportsIcon />
                    {localization.toLanguageString(LocalizationKeys.AccountMenu.Reports, "%Reports")}
                </MenuItem>

                <MenuItem onClick={onMenuClose}>
                    <SOPIcon />
                    {localization.toLanguageString(LocalizationKeys.AccountMenu.SOP, "%SOP")}
                </MenuItem>

                <Divider />

                <MenuItem onClick={onMenuClose}>
                    <SettingsIcon />
                    {localization.toLanguageString(LocalizationKeys.AccountMenu.Settings, "Settings")}
                </MenuItem>

                <Divider />

                <MenuItem onClick={logout}>
                    <LogoutIcon />
                    {localization.toLanguageString(LocalizationKeys.AccountMenu.Logout, "Logout")}
                </MenuItem>
            </Menu>
        </div>
    );
};

export default connect(
    (state: ApplicationState) : AccountMenuStateProps => {
        return {
            ...state.user
        }
    },
    (dispatch) : AccountMenuDispatchProps => {
        return {
            ...bindActionCreators({...UserStore.actionCreators}, dispatch),
        };
    }
)(AccountMenu);