const TaskStatus = Object.freeze({
    //TODO swap when entities are updated    
    Pending: "Pending",
    InProgress: "InProgress",
    Completed: "Completed",
    Cancelled: "Cancelled",
    //Pending: "Enum.TaskStatus.Pending",
    //InProgress: "Enum.TaskStatus.InProgress",
    //Completed: "Enum.TaskStatus.Completed",
    //Cancelled: "Enum.TaskStatus.Cancelled",
});

export type TaskStatusType = typeof TaskStatus[keyof typeof TaskStatus];

export default TaskStatus;