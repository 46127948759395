import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';


export type ScreenState = {
    screenWidth: number;
    topbarHeight: number;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface ScreenResizeAction {
    type: 'SCREEN_RESIZE',
    width: number,
}

interface TopBarResizeAction {
    type: 'SCREEN_TOPBAR_RESIZE',
    height: number,
}

type KnownAction = ScreenResizeAction | TopBarResizeAction;

export const actionCreators = {
    updateScreenWidth: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.screen) {
            dispatch({ type: 'SCREEN_RESIZE', width: window.innerWidth });
        }
    },
    updateTopBarHeight: (height: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.screen) {
            dispatch({ type: 'SCREEN_TOPBAR_RESIZE', height: height });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ScreenState = {
    screenWidth: window?.innerWidth ?? 0,
    topbarHeight: 0,
 };

export const reducer: Reducer<ScreenState> = (state: ScreenState | undefined, incomingAction: Action): ScreenState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {

        case 'SCREEN_RESIZE':
            return {
                ...state,
                screenWidth: action.width,
            };

        case 'SCREEN_TOPBAR_RESIZE':
            return {
                ...state,
                topbarHeight: action.height,
            };

    }

    return state;
};