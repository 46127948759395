import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal/react';
import GridUtils from '~src/utils/gridUtils';
import { ApplicationState } from '~store/ApplicationState';
import * as CRMStore from '~store/pages/mainViews/crm';
import * as UsersStore from '~store/auth/user';
import * as ScreenStore from '~store/infra/screen';

import AppointmentsGridFields from '~enums/gridFields/crmAppointments';
import AppointmentGridItem from '~models/appointment/appointmentGridItem';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import { Grid } from '@mui/material';
import { FilePresent as DocsIcon } from '@mui/icons-material';
import { Button } from '@progress/kendo-react-buttons';
import {
    GridColumn,
    GridToolbar,
    GridPageChangeEvent,
    GridCellProps,
    GridRowDoubleClickEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { provideLocalizationService, registerForLocalization, } from '@progress/kendo-react-intl';

import AMWGrid from '~components/datagrid/AMWGrid';
import CustomCell from '~components/datagrid/CustomCell';
import { GridFilterable, GridPageable, GridSortable } from '~components/datagrid/types';
import { GridContainer, GridContainerDispatchProps, GridContainerStateProps } from '~components/infra/GridContainer';
import AppointmentModal from '../../../../modals/AppointmentModal';
import AppointmentsToolbar from './AppointmentsToolbar';
import LocalizationKeys from '~enums/localizationKeys';


type AppointmentsTabStateProps = ScreenStore.ScreenState &
    CRMStore.AppointmentTabState &
    GridFilterable &
    GridPageable &
    GridSortable &
    GridContainerStateProps;

type AppointmentsTabDispatchProps = typeof CRMStore.appointmentsActionCreators &
    GridContainerDispatchProps;

type AppointmentsTabProps = AppointmentsTabStateProps & AppointmentsTabDispatchProps;

const minColumnWidth = {
    [AppointmentsGridFields.StartDate]: 160,
    [AppointmentsGridFields.OwnerName]: 120,
    [AppointmentsGridFields.Subject]: 120,
    [AppointmentsGridFields.Priority]: 100,
    [AppointmentsGridFields.Status]: 100,
    [AppointmentsGridFields.OpportunityDescription]: 150,
    [AppointmentsGridFields.OpportunityCompany]: 150,
    [AppointmentsGridFields.OriginalAppointmentSubject]: 100,
    [AppointmentsGridFields.FollowupAppointmentSubject]: 100,
    [AppointmentsGridFields.HasDocuments]: 80,
};

type AppointmentsTabState = {
    containerWidth: number,
    openedAppointment: number | null
};

class AppointmentsTab extends GridContainer<AppointmentsTabProps, AppointmentsTabState> {
    constructor(props: Readonly<AppointmentsTabProps>) {
        super(props);
        this.state = {
            containerWidth: 1,
            openedAppointment: null,
        };

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.requestAppointments(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);

        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.resize);

        let element = document.querySelector('#AppointmentsGridContainer .k-grid-header');
        let tabs = document.querySelector('#CRMTabs');
        !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );

        this.setState({
            containerWidth: document.getElementById('AppointmentsGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    componentDidUpdate(prevProps: Readonly<AppointmentsTabProps>, prevState: Readonly<AppointmentsTabState>, snapshot?: any): void {
        if (!equal(prevProps.topbarHeight, this.props.topbarHeight)) {
            let element = document.querySelector('#AppointmentsGridContainer .k-grid-header');
            let tabs = document.querySelector('#CRMTabs');
            !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("orientationchange", this.resize);
    }

    private resize() {
        this.setState({
            containerWidth: document.getElementById('AppointmentsGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    private onPageChange = (event: GridPageChangeEvent) => {
        this.props.requestAppointments(this.props.filters, event.page.skip, event.page.take, this.props.sorting);
    }

    private onSortChange = (event: GridSortChangeEvent) => {
        this.props.requestAppointments(this.props.filters, this.props.skip, this.props.pageSize, event.sort);
    }

    private onRowDoubleClick(event: GridRowDoubleClickEvent) {
        let appointment = event.dataItem as AppointmentGridItem;
        this.setState({
            openedAppointment: appointment.appointmentId,
        });
    }

    private onGridLinkClick(id: number) {
        this.setState({
            openedAppointment: id,
        });
    }

    private calculateColumnWidth(field: string) : number | undefined {
        return GridUtils.calculateColumnWidth(
            field,
            this.props.columnWidth,
            this.state.containerWidth,
            minColumnWidth
        );
    }

    private get columns() {
        const localization = provideLocalizationService(this);

        let columns = [
            (
                <GridColumn
                    key={"appointments-startDate"}
                    field={AppointmentsGridFields.StartDate}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Grid.StartDate, "%Start date")}
                    format="{0:g}"
                    width={this.calculateColumnWidth(AppointmentsGridFields.StartDate) ?? 160}
                    orderIndex={this.props.columnOrder[AppointmentsGridFields.StartDate]} />
            ),
            (
                <GridColumn
                    key={"appointments-owner"}
                    field={AppointmentsGridFields.OwnerName}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Grid.Owner, "%Owner")}
                    width={this.calculateColumnWidth(AppointmentsGridFields.OwnerName)}
                    orderIndex={this.props.columnOrder[AppointmentsGridFields.OwnerName]} />
            ),
            (
                <GridColumn
                    key={"appointments-subject"}
                    field={AppointmentsGridFields.Subject}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Grid.Subject, "%Subject")}
                    width={this.calculateColumnWidth(AppointmentsGridFields.Subject)}
                    orderIndex={this.props.columnOrder[AppointmentsGridFields.Subject]} />
            ),
            (
                <GridColumn
                    key={"appointments-priority"}
                    field={AppointmentsGridFields.Priority}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Grid.Priority, "%Priority")}
                    cell={(
                        (props: GridCellProps) => {
                            const appointment = props.dataItem as AppointmentGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(appointment.priority, appointment.priority)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(AppointmentsGridFields.Priority)}
                    orderIndex={this.props.columnOrder[AppointmentsGridFields.Priority]} />
            ),
            (
                <GridColumn
                    key={"appointments-status"}
                    field={AppointmentsGridFields.Status}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Grid.Status, "%Status")}
                    cell={(
                        (props: GridCellProps) => {
                            const appointment = props.dataItem as AppointmentGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(appointment.status, appointment.status)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(AppointmentsGridFields.Status)}
                    orderIndex={this.props.columnOrder[AppointmentsGridFields.Status]} />
            ),
            (
                <GridColumn
                    key={"appointments-opportunityDescription"}
                    field={AppointmentsGridFields.OpportunityDescription}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Grid.OpportunityDescription, "%Opportunity description")}
                    width={this.calculateColumnWidth(AppointmentsGridFields.OpportunityDescription)}
                    orderIndex={this.props.columnOrder[AppointmentsGridFields.OpportunityDescription]} />
            ),
            (
                <GridColumn
                    key={"appointments-opportunityCompany"}
                    field={AppointmentsGridFields.OpportunityCompany}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Grid.OpportunityCompany, "%Opportunity company")}
                    width={this.calculateColumnWidth(AppointmentsGridFields.OpportunityCompany)}
                    orderIndex={this.props.columnOrder[AppointmentsGridFields.OpportunityCompany]} />
            ),
            (
                <GridColumn
                    key={"appointments-original"}
                    field={AppointmentsGridFields.OriginalAppointmentSubject}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Grid.OriginalAppointment, "%Original appointment")}
                    cell={(
                        (props: GridCellProps) => {
                            const appointment = props.dataItem as AppointmentGridItem;
                            return (
                                <CustomCell {...props}>
                                    {!!appointment.originalAppointmentId && (
                                        <Button
                                            size={'small'}
                                            fillMode={'link'}
                                            onClick={() => {this.onGridLinkClick(appointment.originalAppointmentId ?? 0)}}
                                        >
                                            {appointment.originalAppointmentSubject}
                                        </Button>
                                    )}
                                </CustomCell>
                            );
                        }
                    )}
                    className={'grid__cell--link'}
                    width={this.calculateColumnWidth(AppointmentsGridFields.OriginalAppointmentSubject)}
                    orderIndex={this.props.columnOrder[AppointmentsGridFields.OriginalAppointmentSubject]} />
            ),
            (
                <GridColumn
                    key={"appointments-followup"}
                    field={AppointmentsGridFields.FollowupAppointmentSubject}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Grid.FollowUpAppointment, "%Follow up appointment")}
                    cell={(
                        (props: GridCellProps) => {
                            const appointment = props.dataItem as AppointmentGridItem;
                            return (
                                <CustomCell {...props}>
                                    {!!appointment.followupAppointmentId && (
                                        <Button
                                            size={'small'}
                                            fillMode={'link'}
                                            onClick={() => {this.onGridLinkClick(appointment.followupAppointmentId ?? 0)}}
                                        >
                                            {appointment.followupAppointmentSubject}
                                        </Button>
                                    )}
                                </CustomCell>
                            );
                        }
                    )}
                    className={'grid__cell--link'}
                    width={this.calculateColumnWidth(AppointmentsGridFields.FollowupAppointmentSubject)}
                    orderIndex={this.props.columnOrder[AppointmentsGridFields.FollowupAppointmentSubject]} />
            ),
            (
                <GridColumn
                    key={"appointments-hasDocuments"}
                    field={AppointmentsGridFields.HasDocuments}
                    title={localization.toLanguageString(LocalizationKeys.CRM.Appointments.Grid.HasDocuments, "%Docs")}
                    cell={(
                        (props: GridCellProps) => {
                            const appointment = props.dataItem as AppointmentGridItem;
                            return (
                                <CustomCell {...props}>
                                    {appointment.hasDocuments && (<DocsIcon />)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(AppointmentsGridFields.HasDocuments)}
                    orderIndex={this.props.columnOrder[AppointmentsGridFields.HasDocuments]} />
            ),
        ];

        return columns;
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12} id={'AppointmentsGridContainer'}>
                    <AMWGrid
                        sticky={true}
                        scrollable={'scrollable'}

                        data={this.props.appointments}
                        total={this.props.count}

                        pageable={{ buttonCount: 4, pageSizes: true }}
                        skip={this.props.skip}
                        pageSize={this.props.pageSize}
                        onPageChange={this.onPageChange.bind(this)}

                        sortable={true}
                        sort={this.props.sorting}
                        onSortChange={this.onSortChange.bind(this)}

                        reorderable={true}
                        onColumnReorder={this.onColumnReorder.bind(this)}

                        resizable={true}
                        onColumnResize={this.onColumnResize.bind(this)}

                        onRowDoubleClick={this.onRowDoubleClick.bind(this)}
                    >
                        <GridToolbar>
                            <AppointmentsToolbar
                                isGridBusy={this.props.isLoading}
                                filters={this.props.filters}
                                requestData={(filters) => {
                                    this.props.requestAppointments(filters, this.props.skip, this.props.pageSize, this.props.sorting);
                                }}
                            />
                        </GridToolbar>

                        {this.columns}
                    </AMWGrid>

                    <AppointmentModal
                        appointmentId={this.state.openedAppointment}
                        open={!!this.state.openedAppointment}
                        onClose={() => {
                            this.setState({
                                openedAppointment: null
                            });
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
}
registerForLocalization(AppointmentsTab);

export default connect(
    (state: ApplicationState) : AppointmentsTabStateProps => {
        return {
            pageKey: UserPreferencePage.CRMAppointments,
            ...state.crm?.appointments,
            ...state.screen,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.CRMAppointments}-${UserPreferenceKey.Filters}`] ?? {},
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.CRMAppointments}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.CRMAppointments}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.CRMAppointments}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.CRMAppointments}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        };
    },
    (dispatch) : AppointmentsTabDispatchProps => {
        let crmActions = bindActionCreators({...CRMStore.appointmentsActionCreators}, dispatch);
        let usersActions = bindActionCreators({...UsersStore.actionCreators}, dispatch);

        return {
            ...crmActions,
            ...usersActions
        };
    },
)(AppointmentsTab as any);