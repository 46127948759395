const ReportContext = Object.freeze({
    ClientFile: "ClientFile",
    ClientFileView: "ClientFileView",
    ClientFileInventory: "ClientFileInventory",
    Expense: "Expense",
    PurchaseOrder: "PurchaseOrder",
    Invoice: "Invoice",
    Item: "Item",
    Shipment: "Shipment",
    ShipmentContainers: "ShipmentContainers",
    GroupageShipments: "GroupageShipments",
    CertificateOfShipment: "CertificateOfShipment",
    Collection: "Collection",
    CollectionCrates: "CollectionCrates",
    CollectionPackages: "CollectionPackages",
    CommercialInvoice: "CommercialInvoice",
    Delivery: "Delivery",
    DeliveryCrates: "DeliveryCrates",
    DeliveryPackages: "DeliveryPackages",
    InnerTransfer: "InnerTransfer",
    InnerTransferCrates: "InnerTransferCrates",
    InnerTransferPackages: "InnerTransferPackages",
    OnSite: "OnSite",
    OnSitePackages: "OnSitePackages",
    OnSiteCrates: "OnSiteCrates",
    Packing: "Packing",
    PackingPackages: "PackingPackages",
    Project: "Project",
    Quotation: "Quotation",
    ReceiveIn: "ReceiveIn",
    ReceiveInCrates: "ReceiveInCrates",
    ReceiveInPackages: "ReceiveInPackages",
    Release: "Release",
    ReleaseCrates: "ReleaseCrates",
    ReleasePackages: "ReleasePackages",
    Survey: "Survey",
    Viewing: "Viewing",
    ViewingPackages: "ViewingPackages",
    ViewingCrates: "ViewingCrates",
    WarehouseWorksheet: "WarehouseWorksheet",
    OperationsView: "OperationsView",
    QuotationView: "QuotationView",
    InventoryView: "InventoryView",
    ShipmentView: "ShipmentView",
    FinancialsView: "FinancialsView",
    TransactionsView: "TransactionsView",
    TripsView: "TripsView",
    Opportunity: "Opportunity",
    CRMOpportunitiesView: "CRMOpportunitiesView",
    CRMCompaniesView: "CRMCompaniesView",
    CRMTasksView: "CRMTasksView",
    CRMAppointmentsView: "CRMAppointmentsView",
    CRMCampaignsView: "CRMCampaignsView",
    CRMRecentEntriesView: "CRMRecentEntriesView",
});

export type ReportContextType = typeof ReportContext[keyof typeof ReportContext];

export default ReportContext;