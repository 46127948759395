import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { apiClientInstance } from '~services/auth/ApiClientInstance';

import JobFields, { JobFieldsType } from '~enums/fields/job';
import GeneralInfoData from '~models/clientFile/generalInfoData';
import { ShowErrorAction } from '~store/infra/errors';
import AddressDetailsData from '~models/address/addressDetailsData';
import { PersonListItem } from '~models/person/personListItem';
import { parsePersonString } from '~src/utils/personUtils';
import { PartyListItem } from '~models/party/partyListItem';
import GeneralInfoPatch from '~models/clientFile/generalInfoPatch';
import PartyUpdateResult from '~models/updateResult/partyUpdateResult';
import ContactUpdateResult from '~models/updateResult/contactUpdateResult';
import DivisionUpdateResult from '~models/clientFile/updateResult/divisionUpdateResult';
import ClientUpdateResult from '~models/clientFile/updateResult/clientUpdateResult';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { PartyTypeType } from '~enums/partyType';


export interface GeneralInfoState {
    isLoading: boolean;
    data: GeneralInfoData;
    updateStatus: ServerOperationStatus;
    updatedField?: JobFieldsType;
}

const EmptyData: GeneralInfoData = {
    clientFileId: 0,
    jobId: '', //'',
    createdOn: new Date(),
    closedOn: null,
    statusDate: null,

    jobType: '',
    serviceType: '',
    serviceSubType: '',
    jobStatus: '',
    ownedById: null,
    ownerName: '',
    currentDivisionId: 0,
    currentDivisionCode: '',
    nextDivisionId: null,
    nextDivisionCode: '',
    billedTo: 0,
    billedToName: '',
    billedToType: undefined,
    groupageNumber: '',
    expectedRevenue: 0,

    clientId: 0,
    clientName: '',
    clientType: undefined,
    clientAddressId: null,
    clientAddress: undefined,
    contactId: null,
    contactName: '',
    phone: '',
    mobile: '',
    fax: '',
    email: '',
    clientRef1: '',
    clientRef2: '',

    bookerId: 0,
    bookerName: '',
    bookerType: undefined,
    bookerContactId: 0,
    bookerContactName: '',
    quotedTo: '',

    shipperId: null,
    shipperName: '',
    shipperType: undefined,
    shipperAddressId: null,
    shipperAddress: undefined,
    shipperContactId: null,
    shipperContactName: '',
    consigneeId: null,
    consigneeName: '',
    consigneeType: undefined,
    consigneeAddressId: null,
    consigneeAddress: undefined,
    consigneeContactId: null,
    consigneeContactName: '',
    route: '',
    alwaysShow: false,

    insuranceRequired: true,
    cif: '',
    insuranceValue: 0,
    declinedOn: null,
    declinedBy: '',

    fileReminder: '',
    comments: '',

    hasDifferentCollections: false,
    hasDifferentReleases: false,

    clientWarning: '',

    canEdit: false,
    canEditStatus: false,
};


// -----------------
// ---- ACTIONS ----
// -----------------

interface RequestDataAction {
    type: 'GENERAL_INFO_REQUEST_DATA';
}

interface ReceiveDataAction {
    type: 'GENERAL_INFO_RECEIVE_DATA';
    data: GeneralInfoData;
}

interface ReceiveDataErrorAction {
    type: 'GENERAL_INFO_RECEIVE_DATA_ERROR';
}

interface UpdateDataAction {
    type: 'GENERAL_INFO_UPDATE_DATA';
    data: GeneralInfoData;
}

interface UpdateDataRequestAction {
    type: 'GENERAL_INFO_UPDATE_DATA_REQUEST';
    field: JobFieldsType;
}

interface UpdateDataErrorAction {
    type: 'GENERAL_INFO_UPDATE_DATA_ERROR';
}

interface ClearDataAction {
    type: 'GENERAL_INFO_CLEAR_DATA';
}

type KnownAction = RequestDataAction | ReceiveDataAction | ReceiveDataErrorAction
    | UpdateDataAction | UpdateDataRequestAction | UpdateDataErrorAction
    | ClearDataAction | ShowErrorAction;

const buildPatch = (field: JobFieldsType, value: any) => {
    const patchModel = {field: field} as GeneralInfoPatch;

    switch (field) {
        case JobFields.JobType:
            patchModel.jobType = value as string;
            break;
        case JobFields.ServiceType:
            patchModel.serviceType = value as string;
            break;
        case JobFields.ServiceSubType:
            patchModel.serviceSubType = value as string;
            break;
        case JobFields.JobStatus:
            patchModel.fileStatus = value as string;
            break;
        case JobFields.OwnedBy:
            patchModel.ownedBy = value as number;
            break;
        case JobFields.CurrentDivision:
            patchModel.divisionId = value as number;
            break;
        case JobFields.NextDivision:
            patchModel.nextDivisionId = value as number | null;
            break;
        case JobFields.BilledTo:
            patchModel.billingPartyId = value as number;
            break;
        case JobFields.GroupageNumber:
            patchModel.groupageRef = value as string;
            break;
        case JobFields.ExpectedRevenue:
            patchModel.expectedRevenue = value as number;
            break;

        case JobFields.Client:
            patchModel.clientId = value as number;
            break;
        case JobFields.ClientAddress:
            patchModel.clientAddressId = value as number;
            break;
        case JobFields.ClientContact:
            patchModel.clientContactId = value as number;
            break;
        case JobFields.Ref1:
            patchModel.clientRef = value as string;
            break;
        case JobFields.Ref2:
            patchModel.clientRef1 = value as string;
            break;
        case JobFields.Booker:
            patchModel.bookerId = value as number;
            break;
        case JobFields.BookerContact:
            patchModel.bookerContactId = value as number;
            break;
        case JobFields.QuotedTo:
            patchModel.quotedTo = value as string;
            break;
        case JobFields.Shipper:
            patchModel.shipperId = value as number;
            break;
        case JobFields.ShipperAddress:
            patchModel.shipperAddressId = value as number;
            break;
        case JobFields.ShipperContact:
            patchModel.shipperContactId = value as number;
            break;
        case JobFields.Consignee:
            patchModel.consigneeId = value as number;
            break;
        case JobFields.ConsigneeAddress:
            patchModel.consigneeAddressId = value as number;
            break;
        case JobFields.ConsigneeContact:
            patchModel.consigneeContactId = value as number;
            break;
        case JobFields.Route:
            patchModel.route = value as string;
            break;
        // case JobFields.AlwaysShow:
        //     infoObj.alwaysShow = (data as FieldUpdateResult<boolean>).value;
        //     break;
        case JobFields.InsuranceRequired:
            patchModel.insuranceRequired = value as boolean;
            break;
        case JobFields.CIF:
            patchModel.cif = value as string;
            break;
        case JobFields.InsuranceValue:
            patchModel.insuranceValue = value as number;
            break;
        case JobFields.DeclinedOn:
            patchModel.insuranceDeclinedDate = value as Date | null;
            break;
        case JobFields.DeclinedBy:
            patchModel.insuranceDeclinedBy = value as string;
            break;
        case JobFields.FileReminder:
            patchModel.jobMessage = value as string;
            break;
        case JobFields.Comments:
            patchModel.comments = value as string;
            break;
    }

    return patchModel;
};

let abortController : AbortController;

export const actionCreators = {
    requestData: (fileId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.generalInfo && !appState.generalInfo.isLoading) {
            !!abortController && abortController.abort();
            abortController = new AbortController();
            apiClientInstance.fetchRequest<GeneralInfoData>(`v1/files/${fileId}`, 'GET', undefined, undefined, abortController)
                .then((data) => {
                    data.createdOn = new Date(data.createdOn);
                    !!data.closedOn && (data.closedOn = new Date(data.closedOn));
                    !!data.statusDate && (data.statusDate = new Date(data.statusDate));
                    dispatch({ type: 'GENERAL_INFO_RECEIVE_DATA', data: data });
                })
                .catch((err) => {
                    dispatch({ type: 'GENERAL_INFO_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'GENERAL_INFO_REQUEST_DATA' });
        }
    },
    updateField: (serverField: JobFieldsType, value: any, selectedItem?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.generalInfo) {
            const patchModel = buildPatch(serverField, value);

            apiClientInstance.fetchRequest(
                `v1/files/${appState.generalInfo.data.clientFileId}`,
                'PATCH',
                patchModel
            )
                .then((data) => {
                    let infoObj = {...(appState.generalInfo?.data ?? {} as GeneralInfoData)};

                    switch (serverField) {
                        case JobFields.JobType:
                            infoObj.jobType = value as string;
                            break;
                        case JobFields.ServiceType:
                            infoObj.serviceType = value as string;
                            break;
                        case JobFields.ServiceSubType:
                            infoObj.serviceSubType = value as string;
                            break;
                        case JobFields.JobStatus:
                            infoObj.jobStatus = value as string;
                            break;
                        case JobFields.OwnedBy:
                            infoObj.ownedById = value as number;
                            infoObj.ownerName = parsePersonString(selectedItem as PersonListItem);
                            break;
                        case JobFields.CurrentDivision:
                            let divUpdateResult = data as DivisionUpdateResult;
                            infoObj.currentDivisionId = value as number;
                            infoObj.currentDivisionCode = (selectedItem as PartyListItem)?.code ?? '';
                            infoObj.ownedById = null;
                            infoObj.ownerName = '';
                            infoObj.jobType = divUpdateResult.jobType;
                            infoObj.serviceType = divUpdateResult.serviceType;
                            infoObj.serviceSubType = divUpdateResult.serviceSubType;
                            infoObj.route = divUpdateResult.route;
                            break;
                        case JobFields.NextDivision:
                            let nextDivUpdateResult = data as DivisionUpdateResult;
                            infoObj.nextDivisionId = value as number | null;
                            infoObj.nextDivisionCode = (selectedItem as PartyListItem)?.code ?? '';
                            infoObj.jobType = nextDivUpdateResult.jobType;
                            infoObj.serviceType = nextDivUpdateResult.serviceType;
                            infoObj.serviceSubType = nextDivUpdateResult.serviceSubType;
                            infoObj.route = nextDivUpdateResult.route;
                            break;
                        case JobFields.BilledTo:
                            infoObj.billedTo = value as number;
                            infoObj.billedToName = (selectedItem as PartyListItem)?.fullName ?? '';
                            infoObj.billedToType = (selectedItem as PartyListItem)?.partyType as PartyTypeType ?? undefined;
                            break;
                        case JobFields.GroupageNumber:
                            infoObj.groupageNumber = value as string;
                            break;

                        case JobFields.Client:
                            let result = data as ClientUpdateResult;
                            infoObj.clientId = result.partyId;
                            infoObj.clientName = (selectedItem as PartyListItem)?.fullName ?? '';
                            infoObj.clientType = (selectedItem as PartyListItem)?.partyType as PartyTypeType ?? undefined;
                            infoObj.clientAddressId = result.address?.addressId ?? null;
                            infoObj.clientAddress = result.address ?? undefined;
                            infoObj.contactId = null;
                            infoObj.contactName = '';
                            infoObj.email = '';
                            infoObj.phone = '';
                            infoObj.mobile = '';
                            infoObj.fax = '';
                            infoObj.clientWarning = result.warning;
                            break;
                        case JobFields.ClientAddress:
                            infoObj.clientAddressId = value as number;
                            infoObj.clientAddress = (selectedItem as AddressDetailsData) ?? undefined;
                            break;
                        case JobFields.ClientContact:
                            let clientContactData = data as ContactUpdateResult;
                            infoObj.contactId = clientContactData.contactId;
                            infoObj.contactName = parsePersonString(selectedItem as PersonListItem);
                            infoObj.email = clientContactData.email;
                            infoObj.phone = clientContactData.phone;
                            infoObj.mobile = clientContactData.mobile;
                            infoObj.fax = clientContactData.fax;
                            break;
                        case JobFields.Ref1:
                            infoObj.clientRef1 = value as string;
                            break;
                        case JobFields.Ref2:
                            infoObj.clientRef2 = value as string;
                            break;

                        case JobFields.Booker:
                            infoObj.bookerId = value as number;
                            infoObj.bookerName = (selectedItem as PartyListItem)?.fullName ?? '';
                            infoObj.bookerType = (selectedItem as PartyListItem)?.partyType as PartyTypeType ?? undefined;
                            infoObj.bookerContactId = null;
                            infoObj.bookerContactName = '';
                            break;
                        case JobFields.BookerContact:
                            infoObj.bookerContactId = value as number;
                            infoObj.bookerContactName = parsePersonString(selectedItem as PersonListItem);
                            break;
                        case JobFields.QuotedTo:
                            infoObj.quotedTo = value as string;
                            break;
                        case JobFields.ExpectedRevenue:
                            infoObj.expectedRevenue = value as number;
                            break;

                        case JobFields.Shipper:
                            let shipperData = data as PartyUpdateResult;
                            infoObj.shipperId = shipperData.partyId;
                            infoObj.shipperName = (selectedItem as PartyListItem)?.fullName ?? '';
                            infoObj.shipperType = (selectedItem as PartyListItem)?.partyType as PartyTypeType ?? undefined;
                            infoObj.shipperAddressId = shipperData.address?.addressId ?? null;
                            infoObj.shipperAddress = shipperData.address ?? undefined;
                            infoObj.shipperContactId = null;
                            infoObj.shipperContactName = '';
                            break;
                        case JobFields.ShipperAddress:
                            infoObj.shipperAddressId = value as number;
                            infoObj.shipperAddress = (selectedItem as AddressDetailsData) ?? undefined;
                            break;
                        case JobFields.ShipperContact:
                            infoObj.shipperContactId = value as number;
                            infoObj.shipperContactName = parsePersonString(selectedItem as PersonListItem);
                            break;
                        case JobFields.Consignee:
                            let consigneeData = data as PartyUpdateResult;
                            infoObj.consigneeId = consigneeData.partyId;
                            infoObj.consigneeName = (selectedItem as PartyListItem)?.fullName ?? '';
                            infoObj.consigneeType = (selectedItem as PartyListItem)?.partyType as PartyTypeType ?? undefined;
                            infoObj.consigneeAddressId = consigneeData.address?.addressId ?? null;
                            infoObj.consigneeAddress = consigneeData.address ?? undefined;
                            infoObj.consigneeContactId = null;
                            infoObj.consigneeContactName = '';
                            break;
                        case JobFields.ConsigneeAddress:
                            infoObj.consigneeAddressId = value as number;
                            infoObj.consigneeAddress = (selectedItem as AddressDetailsData) ?? undefined;
                            break;
                        case JobFields.ConsigneeContact:
                            infoObj.consigneeContactId = value as number;
                            infoObj.consigneeContactName = parsePersonString(selectedItem as PersonListItem);
                            break;
                        case JobFields.Route:
                            infoObj.route = value as string;
                            break;
                        // case JobFields.AlwaysShow:
                        //     infoObj.alwaysShow = (data as FieldUpdateResult<boolean>).value;
                        //     break;

                        case JobFields.InsuranceRequired:
                            let required = value as boolean;
                            infoObj.insuranceRequired = required;
                            infoObj.declinedOn = required ? null : infoObj.declinedOn;
                            infoObj.declinedBy = required ? '' : infoObj.declinedBy;
                            break;
                        case JobFields.CIF:
                            infoObj.cif = value as string;
                            break;
                        case JobFields.InsuranceValue:
                            infoObj.insuranceValue = value as number;
                            break;
                        case JobFields.DeclinedOn:
                            let decData = value as Date | null;
                            infoObj.declinedOn = decData;
                            infoObj.insuranceRequired = false;
                            break;
                        case JobFields.DeclinedBy:
                            infoObj.declinedBy = value as string;
                            break;

                        case JobFields.FileReminder:
                            infoObj.fileReminder = value as string;
                            break;
                        case JobFields.Comments:
                            infoObj.comments = value as string;
                            break;
                    }

                    dispatch({ type: 'GENERAL_INFO_UPDATE_DATA', data: infoObj, });
                })
                .catch((err) => {
                    dispatch({ type: 'GENERAL_INFO_UPDATE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'GENERAL_INFO_UPDATE_DATA_REQUEST', field: serverField });
        }
    },
    clearData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        !!abortController && !abortController.signal.aborted && abortController.abort();
        dispatch({ type: 'GENERAL_INFO_CLEAR_DATA' });
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: GeneralInfoState = {
    data: EmptyData,
    isLoading: false,
    updateStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<GeneralInfoState> = (state: GeneralInfoState | undefined, incomingAction: Action): GeneralInfoState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GENERAL_INFO_REQUEST_DATA':
            return {
                ...state,
                isLoading: true,
                updatedField: undefined,
            };
        case 'GENERAL_INFO_RECEIVE_DATA':
            return {
                ...state,
                data: action.data,
                isLoading: false,
                updatedField: undefined,
            };
        case 'GENERAL_INFO_RECEIVE_DATA_ERROR':
            return {
                ...state,
                isLoading: false,
            };
        case 'GENERAL_INFO_UPDATE_DATA':
            return {
                ...state,
                data: action.data,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'GENERAL_INFO_UPDATE_DATA_REQUEST':
            return {
                ...state,
                updatedField: action.field,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'GENERAL_INFO_UPDATE_DATA_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            };
        case 'GENERAL_INFO_CLEAR_DATA':
            return unloadedState;
    }

    return state;
};