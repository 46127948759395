const ConfigItemFields = Object.freeze({
    Code: "ConfigItemField.Code",
    Description: "ConfigItemField.Description",
    ItemType: "ConfigItemField.ItemType",
    StringValue: "ConfigItemField.StringValue",
    NumericValue: "ConfigItemField.NumericValue",
    Divisions: "ConfigItemField.Divisions",
});

export type ConfigItemFieldsType = typeof ConfigItemFields[keyof typeof ConfigItemFields];

export default ConfigItemFields;