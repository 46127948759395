import React from 'react';
import { DatePicker, DatePickerProps } from '@progress/kendo-react-dateinputs';
import { LinearProgress } from '@mui/material';

import '~css/components/controls/DatePickerWrapper.scss';


type DatePickerWrapperProps = DatePickerProps &
{
    inProgress?: boolean;
};

const customProps = ['inProgress'];

export default class DatePickerWrapper extends React.Component<DatePickerWrapperProps> {
    __drop?: DatePicker | null;

    private convertInputProps() {
        let defaultProps: {[key:string]: any} = {};

        for(let key in this.props){
            if (!customProps.includes(key)) {
                let entry = Object.entries(this.props).find(x => x[0] === key);

                defaultProps[key] = !!entry ? entry[1] : undefined;
            }
        }

        return defaultProps;
    }

    render() {
        const defaultProps = this.convertInputProps();

        return (
            <div className={'DatePickerWrapper'}>
                <DatePicker
                    ref={(drop) => {this.__drop = drop;}}
                    {...defaultProps}
                    placeholder={this.props.placeholder ?? ''}
                />

                {!!this.props.inProgress && (
                    <LinearProgress className={`DatePickerWrapper__Progress`} />
                )}
            </div>
        );
    }
}