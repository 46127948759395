const CampaignStatus = Object.freeze({
    //TODO swap when entities are updated
    Created: "Created",
    InProgress: "InProgress",
    Completed: "Completed",
    //Created: "Enum.CampaignStatus.Created",
    //InProgress: "Enum.CampaignStatus.InProgress",
    //Completed: "Enum.CampaignStatus.Completed",
});

export type CampaignStatusType = typeof CampaignStatus[keyof typeof CampaignStatus];

export default CampaignStatus;