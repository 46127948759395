import * as React from 'react';

export default class AccessDenied extends React.Component {
    render() {
        return (
            <React.Fragment>
                you cant
            </React.Fragment>
        );
    }
}