import React from "react";
import { Grid } from "@mui/material";
import { Button } from '@progress/kendo-react-buttons';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import FilterItem from "./FilterItem";

import '~css/components/filtering/FilterContainer.scss';
import LocalizationKeys from "~enums/localizationKeys";

type FilterContainerProps = {
    clearClassName?: string,
    applyClassName?: string,
    filterClear: () => void,
    filterApply: () => void,
    children?: React.ReactNode,
    className?: string,
    /**
     * If true, buttons to apply and clear filters will render in the same rows as filters
     */
    sameRowButtons?: boolean,
    disabled?: boolean,
};

export default class FilterContainer extends React.PureComponent<FilterContainerProps, { children?: typeof FilterItem }> {
    public render() {
        const localization = provideLocalizationService(this);

        return (
            <Grid container className={`FilterContainer FilterContainer__root ${this.props.className ?? ''}`.trim()}>
                <Grid item container spacing={2} className="FilterContainer__filters">
                    {this.props.children}

                    {!!this.props.sameRowButtons && (
                        <Grid item flex={1} container className="FilterContainer__buttons FilterContainer__buttons--same-row" spacing={2}>
                            <Grid item>
                                <Button
                                    fillMode={'flat'}
                                    onClick={this.props.filterClear}
                                    className={this.props.clearClassName}
                                    disabled={this.props.disabled}
                                >
                                    {localization.toLanguageString(LocalizationKeys.Shared.Buttons.ShowAll, "Show all")}
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    fillMode={'flat'}
                                    onClick={this.props.filterApply}
                                    className={this.props.applyClassName}
                                    disabled={this.props.disabled}
                                >
                                    {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Apply, "Apply")}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>

                {!this.props.sameRowButtons && (
                    <Grid container className="FilterContainer__buttons" direction={'row-reverse'} spacing={2}>
                        <Grid item>
                            <Button
                                fillMode={'flat'}
                                onClick={this.props.filterApply}
                                className={this.props.applyClassName}
                                disabled={this.props.disabled}
                            >
                                {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Apply, "Apply")}
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button
                                fillMode={'flat'}
                                onClick={this.props.filterClear}
                                className={this.props.clearClassName}
                                disabled={this.props.disabled}
                            >
                                {localization.toLanguageString(LocalizationKeys.Shared.Buttons.ShowAll, "Show all")}
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }
}

registerForLocalization(FilterContainer as React.ComponentClass<any>);