import * as React from 'react';
import { ThemeProvider } from '@mui/material';
import theme from '~src/theme';
import '@progress/kendo-theme-material/dist/all.css';
import "@progress/kendo-font-icons/dist/index.css";
import '~css/main.scss';

export default class StyleWrapper extends React.Component<{children?: React.ReactNode}> {
    render() {
        return (
            <ThemeProvider theme={theme}>
                {this.props.children}
            </ThemeProvider>
        );
    }
}