const PersonFields = Object.freeze({
    Salutation: "PersonField.Salutation",
    FirstName: "PersonField.FirstName",
    LastName: "PersonField.LastName",
    PersonRole: "PersonField.PersonRole",
    IsGeneralType: "PersonField.IsGeneralType",
    IsMailingType: "PersonField.IsMailingType",
    IsBillingType: "PersonField.IsBillingType",
    IsShippingType: "PersonField.IsShippingType",
    AddressId: "PersonField.AddressId",
    IsWebUser: "PersonField.IsWebUser",
    WebUsername: "PersonField.WebUsername",
    WebPassword: "PersonField.WebPassword",
    WebIsActive: "PersonField.WebIsActive"
});

export default PersonFields;