export class ApiClient  {
    private baseUrl: string;
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

    constructor(baseUrl?: string) {
        this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "";
    }

    private __fetchWithTimeout(url: string, options: RequestInit, timeout: number, controller?: AbortController): Promise<Response> {
        const abortController = controller || new AbortController();

        const timeoutId = setTimeout(() => abortController.abort(), timeout);

        return fetch(url, { ...options, signal: abortController.signal } as RequestInit)
            .then((response) => {
                clearTimeout(timeoutId);
                return response;
            });
    }

    private __handleFetch<T>(response: Response): Promise<T> {
        const status = response.status;
        let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };

        return response.text()
            .then((_responseText) => {

                if (status === 200) {
                    let result200: any = null;
                    result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as T;
                    return result200;
                }
                if (status === 204) {
                    return null;
                }

                else throw _responseText;
            })
            .catch((_responseText) => {

                if (status === 400) {
                    let result400: any = null;
                    result400 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as ErrorResponseVm;
                    return throwException("Bad Request", status, _responseText, _headers, result400);
                }

                else if (status === 401) {
                    let result401: any = null;
                    result401 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as ErrorResponseVm;
                    return throwException("Unauthorized", status, _responseText, _headers, result401);
                }

                else if (status === 500) {
                    let result500: any = null;
                    result500 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver) as ErrorResponseVm;
                    return throwException("Server Error", status, _responseText, _headers, result500);
                }

                return throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
    }

    private fetchBase(url: string, requestMethod?: string, requestData?: {[key: string]: any}, timeout?: number, controller?: AbortController) {
        let _url = buildUrl(url, this.baseUrl);

        const fetchOptions: RequestInit = {
            method: requestMethod || 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (!!controller) {
            fetchOptions.signal = controller.signal;
        }

        if (!!requestData) {
            if (fetchOptions.method !== 'GET') {
                fetchOptions.body = JSON.stringify(requestData);
            } else {
                const url = new URL(_url);
                Object.keys(requestData).forEach((key) => {
                    // const value = requestData[key];
                    // if (Array.isArray(value)) {
                    //     (value as Array<any>).forEach(item => {
                    //         urlParams.append()
                    //     })
                    // }
                    url.searchParams.append(key, requestData[key]);
                });
                console.log(`RESULTING URL: ${url.toString()}`);
                _url = url.toString();
            }
        }

        let promise = !!timeout ?
            this.__fetchWithTimeout(_url, fetchOptions, timeout, controller) :
            fetch(_url, fetchOptions);

        return promise;
    }

    /**
     * For most of requests of ClientApp; stringifies body, text() response; handles most errors
     * @param url part of url without the base
     * @param requestMethod
     * @param requestData only for POST requests, don't use with GET
     * @param timeout request timeout
     * @param controller abort controller
     * @returns
     */
    public fetchRequest<T>(url: string, requestMethod?: string, requestData?: {[key: string]: any}, timeout?: number, controller?: AbortController): Promise<T> {
        const promise = this.fetchBase(url, requestMethod, requestData, timeout, controller);

        return promise.then((response) => {
                return this.__handleFetch(response);
            });
    }

    /**
     * Same as fetchRequest, but returns a generic response without handling errors
     * @param url part of url without the base
     * @param requestMethod
     * @param requestData only for POST requests, don't use with GET
     * @param timeout request timeout
     * @param controller abort controller
     * @returns
     */
    public fetchRequestGeneric(url: string, requestMethod?: string, requestData?: {[key: string]: any}, timeout?: number, controller?: AbortController) {
        const promise = this.fetchBase(url, requestMethod, requestData, timeout, controller);

        return promise;
    }

    public buildUrl(url: string) : string {
        return buildUrl(url, this.baseUrl);
    }
}

export interface ErrorResponseVm {
    type: string;
    title: string;
    status: number;
    traceId: string;
}

export class ApiException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: ErrorResponseVm) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isApiException = true;

    static isApiException(obj: any): obj is ApiException {
        return obj.isApiException === true;
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: ErrorResponseVm): any {
    if (!!result) {
        throw new ApiException(result.title, result.status, response, headers, result);
    } else {
        throw new ApiException(message, status, response, headers);
    }
}

function buildUrl(path: string, baseUrl: string) {
    if(path.startsWith("http")) return path;
    if(path.startsWith("/")) return `${baseUrl}${path}`;
    return `${baseUrl}/${path}`;
}
