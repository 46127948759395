import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import { ShowErrorAction } from '~store/infra/errors';
import { SortDescriptor } from "@progress/kendo-data-query";
import ItemInventoryGridItem from '~models/item/itemInventory';
import PackageInventoryGridItem from '~models/package/packageInventory';
import InventoryGridFilters from '~enums/gridFilters/inventoryFilters';


export interface InventoryItemsState {
    isLoading: boolean;
    items: Array<ItemInventoryGridItem>;
    count: number;
    skip: number;
}

export interface InventoryPackagesState {
    isLoading: boolean;
    packages: Array<PackageInventoryGridItem>;
    count: number;
    skip: number;
}

export interface InventoryState {
    items: InventoryItemsState;
    packages: InventoryPackagesState;
    crates: InventoryPackagesState;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface InventoryItemRequestAction {
    type: 'INVENTORY_ITEM_REQUEST';
    skip: number;
}
interface InventoryItemReceiveAction {
    type: 'INVENTORY_ITEM_RECEIVE';
    items: ItemInventoryGridItem[];
    count: number;
}
interface InventoryItemErrorAction {
    type: 'INVENTORY_ITEM_ERROR';
}

interface InventoryPackageRequestAction {
    type: 'INVENTORY_PACKAGE_REQUEST';
    filters: FilterList;
    pageSize: number;
    skip: number;
    sorting: Array<SortDescriptor>;
    isCrate: boolean;
}
interface InventoryPackageReceiveAction {
    type: 'INVENTORY_PACKAGE_RECEIVE';
    filters: FilterList;
    packages: PackageInventoryGridItem[];
    count: number;
    isCrate: boolean;
}
interface InventoryPackageErrorAction {
    type: 'INVENTORY_PACKAGE_ERROR';
    isCrate: boolean;
}

type KnownAction = InventoryItemRequestAction | InventoryItemReceiveAction | InventoryItemErrorAction
    | InventoryPackageRequestAction | InventoryPackageReceiveAction | InventoryPackageErrorAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestItems: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.inventory?.items && !appState.inventory.items.isLoading) {
            let invFilters = {...filters};

            if (!!invFilters[InventoryGridFilters.EndDate]?.value) {
                const dateValue = new Date(invFilters[InventoryGridFilters.EndDate].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                invFilters[InventoryGridFilters.EndDate] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<ItemInventoryGridItem>>('/v1/items/search', 'POST', invFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.InventoryItems}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.InventoryItems}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.InventoryItems}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((file) => {
                        !!file.collectionDate && (file.collectionDate = new Date(file.collectionDate));
                        !!file.deliveryDate && (file.deliveryDate = new Date(file.deliveryDate));
                    });
                    dispatch({ type: 'INVENTORY_ITEM_RECEIVE', items: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'INVENTORY_ITEM_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'INVENTORY_ITEM_REQUEST', skip: skip, });
        }
    },
    requestPackages: (filters: FilterList, isCrate: boolean, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        let storeField = isCrate ? appState?.inventory?.crates : appState?.inventory?.packages;

        if (appState && storeField && !storeField.isLoading) {
            let invFilters = {...filters};

            if (!!invFilters[InventoryGridFilters.EndDate]?.value) {
                const dateValue = new Date(invFilters[InventoryGridFilters.EndDate].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                invFilters[InventoryGridFilters.EndDate] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<PackageInventoryGridItem>>('/v1/packages/search', 'POST', invFilters, skip, pageSize, sorting, {EmptyCrates: isCrate})
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${isCrate ? UserPreferencePage.InventoryCrates : UserPreferencePage.InventoryPackages}-${UserPreferenceKey.Filters}`]: filters,
                        [`${isCrate ? UserPreferencePage.InventoryCrates : UserPreferencePage.InventoryPackages}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${isCrate ? UserPreferencePage.InventoryCrates : UserPreferencePage.InventoryPackages}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((pack) => {
                        !!pack.dateIn && (pack.dateIn = new Date(pack.dateIn));
                        !!pack.dateOut && (pack.dateOut = new Date(pack.dateOut));
                    });
                    dispatch({ type: 'INVENTORY_PACKAGE_RECEIVE', isCrate: isCrate, filters: filters, packages: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'INVENTORY_PACKAGE_ERROR', isCrate: isCrate });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'INVENTORY_PACKAGE_REQUEST', isCrate: isCrate, filters: filters, pageSize: pageSize, skip: skip, sorting: sorting });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: InventoryState = {
    items: {
        isLoading: false,
        items: [],
        count: 0,
        skip: 0,
    },
    packages: {
        isLoading: false,
        packages: [],
        count: 0,
        skip: 0,
    },
    crates: {
        isLoading: false,
        packages: [],
        count: 0,
        skip: 0,
    },
 };

export const reducer: Reducer<InventoryState> = (state: InventoryState | undefined, incomingAction: Action): InventoryState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'INVENTORY_ITEM_REQUEST':
            return {
                ...state,
                items: {
                    ...state.items,
                    isLoading: true,
                    skip: action.skip,
                },
            };
        case 'INVENTORY_ITEM_RECEIVE':
            return {
                ...state,
                items: {
                    ...state.items,
                    items: action.items,
                    count: action.count,
                    isLoading: false,
                },
            };
        case 'INVENTORY_ITEM_ERROR':
            return {
                ...state,
                items: {
                    ...state.items,
                    isLoading: false,
                },
            };

        case 'INVENTORY_PACKAGE_REQUEST':
            return {
                ...state,
                packages: action.isCrate ?
                    state.packages :
                    {
                        ...state.packages,
                        isLoading: true,
                        skip: action.skip,
                    },
                crates: action.isCrate ?
                    {
                        ...state.crates,
                        isLoading: true,
                        skip: action.skip,
                    } :
                    state.crates,
            };
        case 'INVENTORY_PACKAGE_RECEIVE':
            return {
                ...state,
                packages: action.isCrate ?
                    state.packages :
                    {
                        ...state.packages,
                        packages: action.packages,
                        count: action.count,
                        isLoading: false,
                    },
                crates: action.isCrate ?
                    {
                        ...state.crates,
                        packages: action.packages,
                        count: action.count,
                        isLoading: false,
                    } :
                    state.crates,
            };
        case 'INVENTORY_PACKAGE_ERROR':
            return {
                ...state,
                packages: action.isCrate
                    ? state.packages
                    : {
                        ...state.packages,
                        isLoading: false,
                    },
                crates: action.isCrate
                    ? {
                        ...state.crates,
                        isLoading: false,
                    }
                    : state.crates,
            };
    }

    return state;
};