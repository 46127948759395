const ProjectsGridFilters = {
    StartDate: "StartDate",
    EndDate: "EndDate",
    ProjectNr: "ProjectNr",
    Surveyor: "Surveyor",
    ClientCode: "ClientCode",
    ClientName: "ClientName",
} as const;

export type ProjectsGridFiltersType = typeof ProjectsGridFilters[keyof typeof ProjectsGridFilters];

export default ProjectsGridFilters;