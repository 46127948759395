import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { ShowErrorAction } from '~store/infra/errors';
import { parseAddressString } from '~src/utils/addressUtils';
import { parsePersonString } from '~src/utils/personUtils';
import { LockNavigationAction, UnlockNavigationAction } from '~store/infra/navigation';
import { v4 as uuidv4 } from 'uuid';

import { apiClientInstance } from '~services/auth/ApiClientInstance';

import QuickEntryFields, { QuickEntryFieldsType } from '~enums/fields/quickEntry';
import LocalizationKeys from '~enums/localizationKeys';
import TransactionType from '~enums/transactionType';
import AddressListItem from '~models/address/addressListItem';
import QuickEntryData from '~models/clientFile/quickEntry/quickEntryData';
import { PartyListItem } from '~models/party/partyListItem';
import { PersonListItem } from '~models/person/personListItem';
import ResourceItem from '~models/resource/resourceItem';
import ServiceConfig from '~models/serviceConfig/serviceConfig';
import TariffItem from '~models/tariff/tariffItem';
import TariffRangeItem, { getTariffRangeString } from '~models/tariffRange/tariffRangeItem';
import TripSearchItem from '~models/trip/tripSearchItem';
import ItemQuickEntry from '~models/item/itemQuickEntry';
import QuickEntryRequest from '~models/clientFile/quickEntry/quickEntryRequest';


export interface QuickEntryState {
    isLoading: boolean;
    newJobId?: number;
    data: StateData;
    error?: Error;
}

interface StateData extends QuickEntryData {
    clientFileId?: number;

    clientName: string;
    clientAddress: string;
    contactName: string;
    nextDivisionName: string;
    shipperName: string;
    shipperAddress: string;
    shipperContactName: string;
    consigneeName: string;
    consigneeAddress: string;
    consigneeContactName: string;
    CRtripName: string;
    CRdivisionName: string;
    CRresourceName: string;
    CRtariffName: string;
    CRtariffRangeName: string;
    DRtripName: string;
    DRdivisionName: string;
    DRresourceName: string;
    DRtariffName: string;
    DRtariffRangeName: string;
    PtripName: string;
    PdivisionName: string;
    PtariffName: string;
    PtariffRangeName: string;
}

const EmptyData: StateData = {
    uid: uuidv4(),

    clientFileId: undefined,

    route: '',
    jobType: '',
    clientId: null,
    clientName: '',
    clientAddressId: null,
    clientAddress: '',
    contactId: null,
    contactName: '',
    clientRef: '',
    nextDivisionId: null,
    nextDivisionName: '',
    bypassWarehouse: false,

    insuranceRequired: true,
    cif: '',
    insuranceValue: 0,
    declinedOn: null,
    declinedBy: '',

    shipperId: null,
    shipperName: '',
    shipperAddressId: null,
    shipperAddress: '',
    shipperContactId: null,
    shipperContactName: '',
    consigneeId: null,
    consigneeName: '',
    consigneeAddressId: null,
    consigneeAddress: '',
    consigneeContactId: null,
    consigneeContactName: '',

    fileReminder: '',
    comments: '',

    createInvoice: false,
    invoiceJobType: '',
    invoiceTransportMode: '',
    invoiceDescription: '',

    CRtype: TransactionType.Collection,
    CRtripId: null,
    CRtripName: '',
    CRtripServiceId: null,
    CRdivisionId: null,
    CRdivisionName: '',
    CRfixedFlag: '',
    CRscheduled: null,
    CRresourceId: null,
    CRresourceName: '',
    CRtariffId: null,
    CRtariffName: '',
    CRtariffRangeId: null,
    CRtariffRangeName: '',
    CRsuggestedCharge: 0,
    CRuseZoneTariff: false,
    CRinstructions: '',

    DRtype: '',
    DRtripId: null,
    DRtripName: '',
    DRtripServiceId: null,
    DRdivisionId: null,
    DRdivisionName: '',
    DRfixedFlag: '',
    DRscheduled: null,
    DRresourceId: null,
    DRresourceName: '',
    DRtariffId: null,
    DRtariffName: '',
    DRtariffRangeId: null,
    DRtariffRangeName: '',
    DRsuggestedCharge: 0,
    DRuseZoneTariff: false,
    DRinstructions: '',

    Ptype: '',
    PtripId: null,
    PtripName: '',
    PtripServiceId: null,
    PdivisionId: null,
    PdivisionName: '',
    Pscheduled: null,
    PtariffId: null,
    PtariffName: '',
    PtariffRangeId: null,
    PtariffRangeName: '',
    PsuggestedCharge: 0,
    Pinstructions: '',

    items: [],
};


// -----------------
// ---- ACTIONS ----
// -----------------

interface ResetAction {
    type: 'QUICK_ENTRY_RESET';
}

interface InitAction {
    type: 'QUICK_ENTRY_INIT';
    data: StateData;
}

interface UpdateAction {
    type: 'QUICK_ENTRY_FIELD_UPDATE';
    data: StateData;
    field: QuickEntryFieldsType;
}

interface SubmitAction {
    type: 'QUICK_ENTRY_SUBMIT';
}

interface SubmitErrorAction {
    type: 'QUICK_ENTRY_SUBMIT_ERROR';
}

interface SaveItemAction {
    type: "QUICK_ENTRY_ITEM_SAVE";
    newItems: Array<ItemQuickEntry>;
}

interface RemoveItemAction {
    type: "QUICK_ENTRY_ITEM_REMOVE";
    newItems: Array<ItemQuickEntry>;
}

interface CloneItemAction {
    type: "QUICK_ENTRY_ITEM_CLONE";
    newItems: Array<ItemQuickEntry>;
}


type KnownAction = ResetAction
    | InitAction
    | SubmitAction | SubmitErrorAction | UpdateAction
    | SaveItemAction | RemoveItemAction | CloneItemAction
    | LockNavigationAction | UnlockNavigationAction | ShowErrorAction;

export const actionCreators = {
    resetData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quickEntry && !appState.quickEntry.isLoading) {
            dispatch({ type: 'QUICK_ENTRY_RESET' });
        }
    },
    initData: (lockMessage: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quickEntry && !appState.quickEntry.isLoading) {
            const currentData = {...appState.quickEntry.data};
            const userData = appState.user.userData;

            if (!!userData) {
                currentData.CRdivisionId = userData.divisionId!;
                currentData.CRdivisionName = userData.divisionName!;

                currentData.DRdivisionId = userData.divisionId!;
                currentData.DRdivisionName = userData.divisionName!;

                currentData.PdivisionId = userData.divisionId!;
                currentData.PdivisionName = userData.divisionName!;

                dispatch({ type: 'NAVIGATION_LOCK', message: lockMessage });

                dispatch({ type: 'QUICK_ENTRY_INIT', data: currentData });
            }
        }
    },
    postData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quickEntry && !appState.quickEntry.isLoading) {
            const data = appState.quickEntry.data;
            const requestData : QuickEntryRequest = {
                uid: data.uid,
                route: data.route,
                jobType: data.jobType,
                clientId: data.clientId,
                clientAddressId: data.clientAddressId,
                contactId: data.contactId,
                clientRef: data.clientRef,
                nextDivisionId: data.nextDivisionId,
                bypassWarehouse: data.bypassWarehouse,

                insuranceRequired: data.insuranceRequired,
                cif: data.cif,
                insuranceValue: data.insuranceValue,
                declinedOn: data.declinedOn,
                declinedBy: data.declinedBy,

                shipperId: data.shipperId,
                shipperAddressId: data.shipperAddressId,
                shipperContactId: data.shipperContactId,
                consigneeId: data.consigneeId,
                consigneeAddressId: data.consigneeAddressId,
                consigneeContactId: data.consigneeContactId,

                fileReminder: data.fileReminder,
                comments: data.comments,

                invoice: data.createInvoice
                    ? {
                        jobType: data.invoiceJobType,
                        transportMode: data.invoiceTransportMode,
                        description: data.invoiceDescription,
                    }
                    : undefined,

                collectionTransaction: {
                    type: data.CRtype,
                    tripId: data.CRtripId,
                    tripServiceId: data.CRtripServiceId,
                    divisionId: data.CRdivisionId,
                    fixedFlag: data.CRfixedFlag,
                    scheduled: data.CRscheduled,
                    resourceId: data.CRresourceId,
                    tariffId: data.CRtariffId,
                    tariffRangeId: data.CRtariffRangeId,
                    suggestedCharge: data.CRsuggestedCharge,
                    useZoneTariff: data.CRuseZoneTariff,
                    instructions: data.CRinstructions,
                },

                deliveryTransaction: !!data.DRtype
                    ? {
                        type: data.DRtype,
                        tripId: data.DRtripId,
                        tripServiceId: data.DRtripServiceId,
                        divisionId: data.DRdivisionId,
                        fixedFlag: data.DRfixedFlag,
                        scheduled: data.DRscheduled,
                        resourceId: data.DRresourceId,
                        tariffId: data.DRtariffId,
                        tariffRangeId: data.DRtariffRangeId,
                        suggestedCharge: data.DRsuggestedCharge,
                        useZoneTariff: data.DRuseZoneTariff,
                        instructions: data.DRinstructions,
                    }
                    : undefined,

                packingTransaction: !!data.Ptype
                    ? {
                        tripId: data.PtripId,
                        tripServiceId: data.PtripServiceId,
                        divisionId: data.PdivisionId,
                        scheduled: data.Pscheduled,
                        tariffId: data.PtariffId,
                        tariffRangeId: data.PtariffRangeId,
                        suggestedCharge: data.PsuggestedCharge,
                        instructions: data.Pinstructions,
                    }
                    : undefined,

                items: data.items,
            };

            apiClientInstance.fetchRequest<number>(`v1/files/quick-entry`, 'POST', requestData)
                .then((newId) => {
                    dispatch({ type: 'QUICK_ENTRY_RESET' });
                    dispatch({ type: 'NAVIGATION_UNLOCK', route: `/files/${newId}` });
                })
                .catch((err) => {
                    dispatch({ type: 'QUICK_ENTRY_SUBMIT_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'QUICK_ENTRY_SUBMIT' });
        }
    },
    updateField: (field: QuickEntryFieldsType, value: any, selectedItem?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quickEntry) {
            let infoObj = {...(appState.quickEntry?.data ?? {} as QuickEntryData)};

            switch (field) {
                case QuickEntryFields.Route:
                    infoObj.route = value as string;
                    break;
                case QuickEntryFields.JobType:
                    infoObj.jobType = value as string;
                    break;
                case QuickEntryFields.Client:
                    let client = selectedItem as PartyListItem;
                    infoObj.clientId = value as number;
                    infoObj.clientName = client?.fullName ?? '';
                    infoObj.clientAddressId = null;
                    infoObj.clientAddress = '';
                    infoObj.contactId = null;
                    infoObj.contactName = '';
                    break;
                case QuickEntryFields.ClientAddress:
                    let clientAddress = selectedItem as AddressListItem;
                    infoObj.clientAddressId = value;
                    infoObj.clientAddress = parseAddressString(clientAddress);
                    break;
                case QuickEntryFields.ClientContact:
                    let clientContact = selectedItem as PersonListItem;
                    infoObj.contactId = value as number;
                    infoObj.contactName = parsePersonString(clientContact);
                    break;
                case QuickEntryFields.Ref1:
                    infoObj.clientRef = value as string;
                    break;
                case QuickEntryFields.NextDivision:
                    let nextDiv = selectedItem as PartyListItem;
                    infoObj.nextDivisionId = value as number;
                    infoObj.nextDivisionName = nextDiv?.legalName ?? '';
                    break;
                case QuickEntryFields.BypassWarehouse:
                    infoObj.bypassWarehouse = value as boolean;
                    break;

                case QuickEntryFields.InsuranceRequired:
                    infoObj.insuranceRequired = value as boolean;
                    if (!!value) {
                        infoObj.declinedOn = null;
                        infoObj.declinedBy = "";
                    }
                    break;
                case QuickEntryFields.CIF:
                    infoObj.cif = value as string;
                    break;
                case QuickEntryFields.InsuranceValue:
                    infoObj.insuranceValue = value as number;
                    break;
                case QuickEntryFields.DeclinedOn:
                    if (!!value) {
                        infoObj.declinedOn = value as Date;
                        infoObj.insuranceRequired = false;
                    } else {
                        infoObj.declinedOn = null;
                        infoObj.insuranceRequired = true;
                    }
                    break;
                case QuickEntryFields.DeclinedBy:
                    infoObj.declinedBy = value as string;
                    break;

                case QuickEntryFields.Shipper:
                    let shipper = selectedItem as PartyListItem;
                    infoObj.shipperId = value as number;
                    infoObj.shipperName = shipper?.fullName ?? '';
                    infoObj.shipperAddressId = null;
                    infoObj.shipperAddress = '';
                    infoObj.shipperContactId = null;
                    infoObj.shipperContactName = '';
                    if (infoObj.CRuseZoneTariff) {
                        infoObj.CRtariffId = null;
                        infoObj.CRtariffName = '';
                        infoObj.CRtariffRangeId = null;
                        infoObj.CRtariffRangeName = '';
                        infoObj.CRuseZoneTariff = false;
                    }
                    if (infoObj.DRuseZoneTariff) {
                        infoObj.DRtariffId = null;
                        infoObj.DRtariffName = '';
                        infoObj.DRtariffRangeId = null;
                        infoObj.DRtariffRangeName = '';
                        infoObj.DRuseZoneTariff = false;
                    }
                    break;
                case QuickEntryFields.ShipperAddress:
                    let shipperAddress = selectedItem as AddressListItem;
                    infoObj.shipperAddressId = value;
                    infoObj.shipperAddress = parseAddressString(shipperAddress);
                    if (infoObj.CRuseZoneTariff) {
                        infoObj.CRtariffId = null;
                        infoObj.CRtariffName = '';
                        infoObj.CRtariffRangeId = null;
                        infoObj.CRtariffRangeName = '';
                        infoObj.CRuseZoneTariff = false;
                    }
                    if (infoObj.DRuseZoneTariff) {
                        infoObj.DRtariffId = null;
                        infoObj.DRtariffName = '';
                        infoObj.DRtariffRangeId = null;
                        infoObj.DRtariffRangeName = '';
                        infoObj.DRuseZoneTariff = false;
                    }
                    break;
                case QuickEntryFields.ShipperContact:
                    let shipperContact = selectedItem as PersonListItem;
                    infoObj.shipperContactId = value as number;
                    infoObj.shipperContactName = parsePersonString(shipperContact);
                    break;
                case QuickEntryFields.Consignee:
                    let consignee = selectedItem as PartyListItem;
                    infoObj.consigneeId = value as number;
                    infoObj.consigneeName = consignee?.fullName ?? '';
                    infoObj.consigneeAddressId = null;
                    infoObj.consigneeAddress = '';
                    infoObj.consigneeContactId = null;
                    infoObj.consigneeContactName = '';
                    if (infoObj.CRuseZoneTariff) {
                        infoObj.CRtariffId = null;
                        infoObj.CRtariffName = '';
                        infoObj.CRtariffRangeId = null;
                        infoObj.CRtariffRangeName = '';
                        infoObj.CRuseZoneTariff = false;
                    }
                    if (infoObj.DRuseZoneTariff) {
                        infoObj.DRtariffId = null;
                        infoObj.DRtariffName = '';
                        infoObj.DRtariffRangeId = null;
                        infoObj.DRtariffRangeName = '';
                        infoObj.DRuseZoneTariff = false;
                    }
                    break;
                case QuickEntryFields.ConsigneeAddress:
                    let consigneeAddress = selectedItem as AddressListItem;
                    infoObj.consigneeAddressId = value;
                    infoObj.consigneeAddress = parseAddressString(consigneeAddress);
                    if (infoObj.CRuseZoneTariff) {
                        infoObj.CRtariffId = null;
                        infoObj.CRtariffName = '';
                        infoObj.CRtariffRangeId = null;
                        infoObj.CRtariffRangeName = '';
                        infoObj.CRuseZoneTariff = false;
                    }
                    if (infoObj.DRuseZoneTariff) {
                        infoObj.DRtariffId = null;
                        infoObj.DRtariffName = '';
                        infoObj.DRtariffRangeId = null;
                        infoObj.DRtariffRangeName = '';
                        infoObj.DRuseZoneTariff = false;
                    }
                    break;
                case QuickEntryFields.ConsigneeContact:
                    let consigneeContact = selectedItem as PersonListItem;
                    infoObj.consigneeContactId = value as number;
                    infoObj.consigneeContactName = parsePersonString(consigneeContact);
                    break;

                case QuickEntryFields.FileReminder:
                    infoObj.fileReminder = value as string;
                    break;
                case QuickEntryFields.Comments:
                    infoObj.comments = value as string;
                    break;

                case QuickEntryFields.CreateInvoice:
                    infoObj.createInvoice = value as boolean;
                    if (infoObj.createInvoice === false) {
                        infoObj.invoiceJobType = '';
                        infoObj.invoiceTransportMode = '';
                        infoObj.invoiceDescription = '';
                    }
                    break;
                case QuickEntryFields.InvoiceJobType:
                    infoObj.invoiceJobType = value as string;
                    break;
                case QuickEntryFields.InvoiceTransportMode:
                    infoObj.invoiceTransportMode = value as string;
                    break;
                case QuickEntryFields.InvoiceDescription:
                    infoObj.invoiceDescription = value as string;
                    break;

                case QuickEntryFields.CRisCollection:
                    if (infoObj.CRtype !== value as string) {
                        infoObj.CRtariffId = null;
                        infoObj.CRtariffName = '';
                        infoObj.CRtariffRangeId = null;
                        infoObj.CRtariffRangeName = '';
                        infoObj.CRuseZoneTariff = false;
                        infoObj.CRtripId = null;
                        infoObj.CRtripName = '';
                        infoObj.CRtripServiceId = null;
                    }
                    infoObj.CRtype = value as string;
                    break;
                case QuickEntryFields.CRtripId:
                    if (value === null) {
                        infoObj.CRtripId = null;
                        infoObj.CRtripName = '';
                        infoObj.CRtripServiceId = null;
                        infoObj.CRtariffId = null;
                        infoObj.CRtariffName = '';
                        infoObj.CRtariffRangeId = null;
                        infoObj.CRtariffRangeName = '';
                    }
                    break;
                case QuickEntryFields.CRdivisionId:
                    let crDivision = selectedItem as PartyListItem;
                    infoObj.CRdivisionId = !!value ? value as number : null;
                    infoObj.CRdivisionName = crDivision?.legalName ?? '';
                    if (!infoObj.CRtripId) {
                        infoObj.CRtariffId = null;
                        infoObj.CRtariffName = '';
                        infoObj.CRtariffRangeId = null;
                        infoObj.CRtariffRangeName = '';
                        infoObj.CRuseZoneTariff = false;
                    }
                    infoObj.CRresourceId = null;
                    infoObj.CRresourceName = '';
                    break;
                case QuickEntryFields.CRfixedFlag:
                    infoObj.CRfixedFlag = value as string;
                    break;
                case QuickEntryFields.CRscheduled:
                    infoObj.CRscheduled = !!value ? value as Date : null;
                    infoObj.CRresourceId = null;
                    infoObj.CRresourceName = '';
                    break;
                case QuickEntryFields.CRresourceId:
                    let crResource = selectedItem as ResourceItem;
                    infoObj.CRresourceId = !!value ? value as number : null;
                    infoObj.CRresourceName = crResource?.resourceName ?? '';
                    break;
                case QuickEntryFields.CRtariff:
                    let crTariff = selectedItem as TariffItem;
                    infoObj.CRtariffId = !!value ? value as number : null;
                    infoObj.CRtariffName = crTariff?.tariffName ?? '';
                    if (crTariff.ranges.length === 1) {
                        infoObj.CRtariffRangeId = crTariff.ranges[0].tariffRangeId;
                        infoObj.CRtariffRangeName = getTariffRangeString(crTariff.ranges[0]);
                    } else {
                        infoObj.CRtariffRangeId = null;
                        infoObj.CRtariffRangeName = "";
                    }
                    break;
                case QuickEntryFields.CRtariffRange:
                    let range = selectedItem as TariffRangeItem;
                    infoObj.CRtariffRangeId = range.tariffRangeId;
                    infoObj.CRtariffRangeName = getTariffRangeString(range);
                    break;
                case QuickEntryFields.CRsuggestedCharge:
                    infoObj.CRsuggestedCharge = value as number;
                    break;
                case QuickEntryFields.CRuseZoneTariff:
                    infoObj.CRuseZoneTariff = value as boolean;
                    break;
                case QuickEntryFields.CRinstructions:
                    infoObj.CRinstructions = value as string;
                    break;

                case QuickEntryFields.DRisDelivery:
                    if (value === "") {
                        infoObj.DRtype = '';
                        infoObj.DRtripId = null;
                        infoObj.DRtripName = '';
                        infoObj.DRtripServiceId = null;
                        infoObj.DRdivisionId = null;
                        infoObj.DRdivisionName = '';
                        infoObj.DRfixedFlag = '';
                        infoObj.DRscheduled = null;
                        infoObj.DRresourceId = null;
                        infoObj.DRresourceName = '';
                        infoObj.DRtariffId = null;
                        infoObj.DRtariffName = '';
                        infoObj.DRtariffRangeId = null;
                        infoObj.DRtariffRangeName = '';
                        infoObj.DRsuggestedCharge = 0;
                        infoObj.DRuseZoneTariff = false;
                        infoObj.DRinstructions = '';
                    } else if (infoObj.DRtype !== value as string) {
                        infoObj.DRtariffId = null;
                        infoObj.DRtariffName = '';
                        infoObj.DRtariffRangeId = null;
                        infoObj.DRtariffRangeName = '';
                        infoObj.DRuseZoneTariff = false;
                        infoObj.DRtripId = null;
                        infoObj.DRtripName = '';
                        infoObj.DRtripServiceId = null;
                    }
                    infoObj.DRtype = value as string;
                    break;
                case QuickEntryFields.DRtripId:
                    if (value === null) {
                        infoObj.DRtripId = null;
                        infoObj.DRtripName = '';
                        infoObj.DRtripServiceId = null;
                        infoObj.DRtariffId = null;
                        infoObj.DRtariffName = '';
                        infoObj.DRtariffRangeId = null;
                        infoObj.DRtariffRangeName = '';
                    }
                    break;
                case QuickEntryFields.DRdivisionId:
                    let drDivision = selectedItem as PartyListItem;
                    infoObj.DRdivisionId = value as number;
                    infoObj.DRdivisionName = drDivision?.legalName ?? '';
                    if (!infoObj.DRtripId) {
                        infoObj.DRtariffId = null;
                        infoObj.DRtariffName = '';
                        infoObj.DRtariffRangeId = null;
                        infoObj.DRtariffRangeName = '';
                        infoObj.DRuseZoneTariff = false;
                    }
                    break;
                case QuickEntryFields.DRfixedFlag:
                    infoObj.DRfixedFlag = value as string;
                    break;
                case QuickEntryFields.DRscheduled:
                    infoObj.DRscheduled = !!value ? value as Date : null;
                    break;
                case QuickEntryFields.DRresourceId:
                    let drResource = selectedItem as ResourceItem;
                    infoObj.DRresourceId = !!value ? value as number : null;
                    infoObj.DRresourceName = drResource?.resourceName ?? '';
                    break;
                case QuickEntryFields.DRtariff:
                    let drTariff = selectedItem as TariffItem;
                    infoObj.DRtariffId = !!value ? value as number : null;
                    infoObj.DRtariffName = drTariff?.tariffName ?? '';
                    if (drTariff.ranges.length === 1) {
                        infoObj.DRtariffRangeId = drTariff.ranges[0].tariffRangeId;
                        infoObj.DRtariffRangeName = getTariffRangeString(drTariff.ranges[0]);
                    } else {
                        infoObj.DRtariffRangeId = null;
                        infoObj.DRtariffRangeName = "";
                    }
                    break;
                case QuickEntryFields.DRsuggestedCharge:
                    infoObj.DRsuggestedCharge = value as number;
                    break;
                case QuickEntryFields.DRuseZoneTariff:
                    infoObj.DRuseZoneTariff = value as boolean;
                    break;
                case QuickEntryFields.DRinstructions:
                    infoObj.DRinstructions = value as string;
                    break;

                case QuickEntryFields.PisPacking:
                    if (value === "") {
                        infoObj.Ptype = '';
                        infoObj.PtripId = null;
                        infoObj.PtripName = '';
                        infoObj.PtripServiceId = null;
                        infoObj.PdivisionId = null;
                        infoObj.PdivisionName = '';
                        infoObj.Pscheduled = null;
                        infoObj.PtariffId = null;
                        infoObj.PtariffName = '';
                        infoObj.PtariffRangeId = null;
                        infoObj.PtariffRangeName = '';
                        infoObj.PsuggestedCharge = 0;
                        infoObj.Pinstructions = '';
                    } else {
                        infoObj.Ptype = value as string;
                    }
                    break;
                case QuickEntryFields.PtripId:
                    if (value === null) {
                        infoObj.PtripId = null;
                        infoObj.PtripName = '';
                        infoObj.PtripServiceId = null;
                        infoObj.PtariffId = null;
                        infoObj.PtariffName = '';
                        infoObj.PtariffRangeId = null;
                        infoObj.PtariffRangeName = '';
                    }
                    break;
                case QuickEntryFields.PdivisionId:
                    let pDivision = selectedItem as PartyListItem;
                    infoObj.PdivisionId = value as number;
                    infoObj.PdivisionName = pDivision?.legalName ?? '';
                    if (!infoObj.PtripId) {
                        infoObj.PtariffId = null;
                        infoObj.PtariffName = '';
                        infoObj.PtariffRangeId = null;
                        infoObj.PtariffRangeName = '';
                    }
                    break;
                case QuickEntryFields.Pscheduled:
                    infoObj.Pscheduled = !!value ? value as Date : null;
                    break;
                case QuickEntryFields.Ptariff:
                    let pTariff = selectedItem as TariffItem;
                    infoObj.PtariffId = !!value ? value as number : null;
                    infoObj.PtariffName = pTariff?.tariffName ?? '';
                    if (pTariff.ranges.length === 1) {
                        infoObj.PtariffRangeId = pTariff.ranges[0].tariffRangeId;
                        infoObj.PtariffRangeName = getTariffRangeString(pTariff.ranges[0]);
                    } else {
                        infoObj.PtariffRangeId = null;
                        infoObj.PtariffRangeName = "";
                    }
                    break;
                case QuickEntryFields.PsuggestedCharge:
                    infoObj.PsuggestedCharge = value as number;
                    break;
                case QuickEntryFields.Pinstructions:
                    infoObj.Pinstructions = value as string;
                    break;
            }

            dispatch({ type: 'QUICK_ENTRY_FIELD_UPDATE', data: infoObj, field: field });
        }
    },
    selectTrip: (transactionType: string, trip: TripSearchItem, service: ServiceConfig, tariff: TariffItem, range?: TariffRangeItem) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quickEntry) {
            const data = appState.quickEntry?.data ?? {} as QuickEntryData;
            let infoObj = {...(data)};
            switch (transactionType)
            {
                case TransactionType.Collection:
                case TransactionType.ReceiveIn:
                    infoObj.CRtripId = trip.tripId;
                    infoObj.CRtripName = trip.tripNumber;
                    infoObj.CRtripServiceId = service.serviceConfigId;
                    infoObj.CRtariffId = tariff.tariffId;
                    infoObj.CRtariffName = tariff.tariffName;
                    infoObj.CRtariffRangeId = range?.tariffRangeId ?? null;
                    infoObj.CRtariffRangeName = !!range ? getTariffRangeString(range) : "";
                    infoObj.CRuseZoneTariff = false;
                    break;
                case TransactionType.Delivery:
                case TransactionType.Release:
                    infoObj.DRtripId = trip.tripId;
                    infoObj.DRtripName = trip.tripNumber;
                    infoObj.DRtripServiceId = service.serviceConfigId;
                    infoObj.DRtariffId = tariff.tariffId;
                    infoObj.DRtariffName = tariff.tariffName;
                    infoObj.DRtariffRangeId = range?.tariffRangeId ?? null;
                    infoObj.DRtariffRangeName = !!range ? getTariffRangeString(range) : "";
                    infoObj.DRuseZoneTariff = false;
                    break;
                case TransactionType.Packing:
                    infoObj.PtripId = trip.tripId;
                    infoObj.PtripName = trip.tripNumber;
                    infoObj.PtripServiceId = service.serviceConfigId;
                    infoObj.PtariffId = tariff.tariffId;
                    infoObj.PtariffName = tariff.tariffName;
                    infoObj.PtariffRangeId = range?.tariffRangeId ?? null;
                    infoObj.PtariffRangeName = !!range ? getTariffRangeString(range) : "";
                    break;
            }

            dispatch({ type: 'QUICK_ENTRY_FIELD_UPDATE', data: infoObj, field: QuickEntryFields.TripSelection });
        }
    },
    setZoneTariff: (field: QuickEntryFieldsType) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quickEntry) {
            const data = appState.quickEntry?.data ?? {} as QuickEntryData;
            const requestModel = {
                shipperAddressId: data.shipperAddressId,
                consigneeAddressId: data.consigneeAddressId,
            } as {[key:string]: any};

            switch (field)
            {
                case QuickEntryFields.CRuseZoneTariff:
                    requestModel.divisionId = data.CRdivisionId;
                    requestModel.transactionType = data.CRtype;
                    !!data.CRtripServiceId && (requestModel.serviceConfigId = data.CRtripServiceId);
                    break;
                case QuickEntryFields.DRuseZoneTariff:
                    requestModel.divisionId = data.DRdivisionId;
                    requestModel.transactionType = data.DRtype;
                    !!data.DRtripServiceId && (requestModel.serviceConfigId = data.DRtripServiceId);
                    break;
            }

            let url = `/v1/tariffs/zone-tariff`;
            const urlParams = new URLSearchParams(requestModel);
            if (Array.from(urlParams).length > 0) url += `?${urlParams}`;

            apiClientInstance.fetchRequest<TariffItem>(url, 'GET')
                .then((tariff) => {
                    let infoObj = {...(data)};

                    if (tariff === null)
                    {
                        dispatch({ type: "ERROR_MESSAGE_SHOW", message: LocalizationKeys.Jobs.QuickEntry.NoZoneTariffFound });
                    }
                    else
                    {
                        switch (field)
                        {
                            case QuickEntryFields.CRuseZoneTariff:
                                infoObj.CRtariffId = tariff?.tariffId ?? null;
                                infoObj.CRtariffName = tariff?.tariffName ?? "";
                                infoObj.CRtariffRangeId = tariff?.ranges.length === 1 ? tariff?.ranges[0].tariffRangeId : null;
                                infoObj.CRtariffRangeName = tariff?.ranges.length === 1 ? getTariffRangeString(tariff.ranges[0]) : "";
                                infoObj.CRuseZoneTariff = true;
                                break;
                            case QuickEntryFields.DRuseZoneTariff:
                                infoObj.DRtariffId = tariff?.tariffId ?? null;
                                infoObj.DRtariffName = tariff?.tariffName ?? "";
                                infoObj.DRtariffRangeId = tariff?.ranges.length === 1 ? tariff?.ranges[0].tariffRangeId : null;
                                infoObj.DRtariffRangeName = tariff?.ranges.length === 1 ? getTariffRangeString(tariff.ranges[0]) : "";
                                infoObj.DRuseZoneTariff = true;
                                break;
                        }

                        dispatch({ type: 'QUICK_ENTRY_FIELD_UPDATE', data: infoObj, field: field });
                    }
                });
        }
    },
    saveItem: (item: ItemQuickEntry): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quickEntry) {
            let newItems = [...appState.quickEntry.data.items];
            const itemIndex = newItems.findIndex(x => x.uid === item.uid);
            if (itemIndex === -1) {
                newItems = [...newItems, item];
            } else {
                newItems[itemIndex] = item;
            }

            dispatch({ type: 'QUICK_ENTRY_ITEM_SAVE', newItems: newItems });
        }
    },
    removeItem: (uid: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quickEntry && !appState.quickEntry.isLoading) {
            const newItems = appState.quickEntry.data.items.filter(x => x.uid !== uid);

            apiClientInstance.fetchRequest(`v1/photos/clear-quick-entry/${appState.quickEntry.data.uid}/${uid}`, 'POST');

            dispatch({ type: 'QUICK_ENTRY_ITEM_REMOVE', newItems: newItems, });
        }
    },
    cloneItem: (uid: number, newUid: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quickEntry && !appState.quickEntry.isLoading) {
            const original = appState.quickEntry.data.items.find(x => x.uid === uid);
            const clone = {...original!};
            clone.uid = newUid;

            const newItems = [...appState.quickEntry.data.items, clone];

            dispatch({ type: 'QUICK_ENTRY_ITEM_CLONE', newItems: newItems, });
        }
    },
    clearGallery: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quickEntry) {
            apiClientInstance.fetchRequest(`v1/photos/clear-quick-entry/${appState.quickEntry.data.uid}`, 'POST');
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: QuickEntryState = {
    isLoading: false,
    data: EmptyData,
 };

export const reducer: Reducer<QuickEntryState> = (state: QuickEntryState | undefined, incomingAction: Action): QuickEntryState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'QUICK_ENTRY_RESET':
            return {
                ...unloadedState,
                data: {
                    ...unloadedState.data,
                    uid: uuidv4(),
                }
            };
        case 'QUICK_ENTRY_INIT':
            return {
                ...state,
                data: action.data,
            };
        case 'QUICK_ENTRY_FIELD_UPDATE':
            return {
                data: action.data,
                isLoading: state.isLoading,
            };
        case 'QUICK_ENTRY_SUBMIT':
            return {
                ...state,
                isLoading: true,
            };
        case 'QUICK_ENTRY_SUBMIT_ERROR':
            return {
                ...state,
                isLoading: false,
            };
        case 'QUICK_ENTRY_ITEM_SAVE':
            return {
                ...state,
                data: {
                    ...state.data,
                    items: action.newItems,
                },
            };
        case 'QUICK_ENTRY_ITEM_REMOVE':
            return {
                ...state,
                data: {
                    ...state.data,
                    items: action.newItems,
                },
            };
        case 'QUICK_ENTRY_ITEM_CLONE':
            return {
                ...state,
                data: {
                    ...state.data,
                    items: action.newItems,
                },
            };
    }

    return state;
};