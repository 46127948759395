const JobFields = {
    JobType: "JobField.JobType",
    ServiceType: "JobField.ServiceType",
    ServiceSubType: "JobField.ServiceSubType",
    JobStatus: "JobField.JobStatus",
    OwnedBy: "JobField.OwnedBy",
    CurrentDivision: "JobField.CurrentDivision",
    NextDivision: "JobField.NextDivision",
    BilledTo: "JobField.BilledTo",
    GroupageNumber: "JobField.GroupageNumber",
    ExpectedRevenue: "JobField.ExpectedRevenue",

    Client: "JobField.Client",
    ClientAddress: "JobField.ClientAddress",
    ClientContact: "JobField.ClientContact",
    Ref1: "JobField.Ref1",
    Ref2: "JobField.Ref2",

    Booker: "JobField.Booker",
    BookerContact: "JobField.BookerContact",
    QuotedTo: "JobField.QuotedTo",

    Shipper: "JobField.Shipper",
    ShipperAddress: "JobField.ShipperAddress",
    ShipperContact: "JobField.ShipperContact",
    Consignee: "JobField.Consignee",
    ConsigneeAddress: "JobField.ConsigneeAddress",
    ConsigneeContact: "JobField.ConsigneeContact",
    Route: "JobField.Route",
    // AlwaysShow: "JobField.AlwaysShowShipping",

    InsuranceRequired: "JobField.InsuranceRequired",
    CIF: "JobField.CIF",
    InsuranceValue: "JobField.InsuranceValue",
    DeclinedOn: "JobField.DeclinedOn",
    DeclinedBy: "JobField.DeclinedBy",

    FileReminder: "JobField.FileReminder",
    Comments: "JobField.Comments"
} as const;

export type JobFieldsType = typeof JobFields[keyof typeof JobFields];

export default JobFields;