const ProjectsGridFields = {
    UniqueId: "uniqueId",
    Status: "status",
    StartDate: "startDate",
    CompletedDate: "completedDate",
    Description: "description",
    ClientRef1: "clientRef1",
    ClientRef2: "clientRef2",
} as const;

export type ProjectsGridFieldsType = typeof ProjectsGridFields[keyof typeof ProjectsGridFields];

export default ProjectsGridFields;