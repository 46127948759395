import AddressBase from "~models/address/addressBase";

const parseAddressString = (address?: AddressBase) => {
    return !!address ? 
        `${address.street1} ${address.city} ${address.state} ${address.country} ${address.zip}`.replaceAll('  ', ' ').trim() : 
        '';
};

export {
    parseAddressString
}