import { SortDescriptor } from "@progress/kendo-data-query";
import { LocalizationService } from "@progress/kendo-react-intl";
import { apiClientInstance } from "~services/auth/ApiClientInstance";

export interface FilterData {
    value: any;
    text?: string;
}

export interface FilterList {
    [key: string]: FilterData;
}

export class FilterHandler {
    public static getValue(filters: FilterList, key: string) {
        return !!filters[key] ? filters[key].value : '';
    }

    public static getDateValue(filters: FilterList, key: string) {
        return !!filters[key] ? new Date(filters[key].value) : null;
    }

    public static getDropDownValue(filters: FilterList, key: string, keyField: string, textField: string) {
        return !!filters[key] ?
            {[keyField]: filters[key].value, [textField]: filters[key].text ?? ''} :
            null;
    }

    public static getMultiselectValue(filters: FilterList, key: string, keyField: string, textField: string, localization?: LocalizationService) {
        return (FilterHandler.getValue(filters, key) || [])
            .map((x:any) => { return { [keyField]: x, [textField]: localization?.toLanguageString(x, x) ?? x }});
    }

    public static parseFilterValues(filters: FilterList) {
        let result: {[key:string]: any} = {};

        Object.keys(filters).forEach((key) => {
            let value = filters[key].value;
            if(value instanceof Date) value = value.toISOString();
            result[key] = value;
        });

        return result;
    }

    public static getNumericValue(filters: FilterList, key: string) {
        return !!filters[key] ? filters[key].value as number : null;
    }

    public static getApiFilterRequestPromise<T>(url: string, requestMethod: string, filters: FilterList, skip?: number, pageSize?: number, sorting?: Array<SortDescriptor>, otherParams?: {[key:string]: any}, timeout?: number, abortController?: AbortController) {
        let body: {[key:string]: any} = {};

        let filterValues = FilterHandler.parseFilterValues(filters);
        Object.keys(filterValues).forEach((key) => {
            body[key] = filterValues[key];
        });

        if (skip !== undefined) body['skip'] = skip;
        if (pageSize !== undefined) body['pageSize'] = pageSize;
        if (sorting !== undefined && sorting.length > 0) {
            body['sortBy'] = [
                {
                    field: `${sorting[0].field}`,
                    direction: `${sorting[0].dir ?? 'asc'}`
                }];
        }
        if (!!otherParams) {
            Object.keys(otherParams).forEach((key) => {
                body[key] = otherParams[key];
            })
        }

        if (requestMethod === 'POST') {
            return apiClientInstance.fetchRequest<T>(url, requestMethod, body, timeout, abortController);
        } else {
            if(!!body['sortBy']) {
                body['sortBy'] = (body['sortBy'] as any[]).map(x => `${x.field},${x.direction}`);
            }
            let params = new URLSearchParams(body);
            return apiClientInstance.fetchRequest<T>(`${url}?${params.toString()}`, requestMethod, undefined, timeout, abortController);
        }
    }
}