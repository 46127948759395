import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import equal from 'fast-deep-equal/react';
import GridUtils from '~src/utils/gridUtils';
import * as TransactionsStore from '~store/pages/clientFile/transactions';
import * as UsersStore from '~store/auth/user';
import * as ScreenStore from '~store/infra/screen';
import * as ClientFileStore from '~store/pages/clientFile/clientFile';
import { ApplicationState } from '~store/ApplicationState';

import { checkboxColumnWidth } from '~enums/constants';
import CFTransactionsGridFields from '~enums/gridFields/cfTransactions';
import LocalizationKeys from '~enums/localizationKeys';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import { FilterList } from '~models/filters';
import OperationsCFGridItem from '~models/transaction/operationsCFGridItem';

import { Grid } from '@mui/material';
import { getter } from "@progress/kendo-react-common";
import {
    GridColumn,
    GridToolbar,
    GridPageChangeEvent,
    GridCellProps,
    GridSelectionChangeEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { Checkbox } from '@progress/kendo-react-inputs';
import { provideLocalizationService, registerForLocalization, } from '@progress/kendo-react-intl';

import AMWGrid from '~components/datagrid/AMWGrid';
import CustomCell from '~components/datagrid/CustomCell';
import { GridFilterable, GridPageable, GridSortable, SELECTED_FIELD } from '~components/datagrid/types';
import { GridContainer, GridContainerDispatchProps, GridContainerStateProps } from '~components/infra/GridContainer';
import TransactionsToolbar from './TransactionsToolbar';


const DATA_ITEM_KEY: string = "transactionOpsId";
const idGetter = getter(DATA_ITEM_KEY);

type TransactionsOwnProps = { clientFileId: number; };

type TransactionsStateProps = TransactionsStore.CFTransactionsState
    & ClientFileStore.ClientFileState
    & ScreenStore.ScreenState
    & GridPageable
    & GridSortable
    & GridContainerStateProps;

type TransactionsDispatchProps = typeof TransactionsStore.actionCreators
    & typeof ClientFileStore.actionCreators
    & GridContainerDispatchProps;

type TransactionsProps = TransactionsOwnProps & TransactionsStateProps & TransactionsDispatchProps;

type TransactionsState = {
    containerWidth: number,
    selectedId?: number,
} & GridFilterable;

const minColumnWidth = {
    [SELECTED_FIELD]: checkboxColumnWidth,
    [CFTransactionsGridFields.TransactionType]: 125,
    [CFTransactionsGridFields.InternalId]: 125,
    [CFTransactionsGridFields.ScheduledDate]: 125,
    [CFTransactionsGridFields.ActualDate]: 125,
    [CFTransactionsGridFields.TrackingNumber]: 100,
    [CFTransactionsGridFields.InvoiceRef]: 100,
    [CFTransactionsGridFields.Status]: 125,
    [CFTransactionsGridFields.DivisionCode]: 125,
    [CFTransactionsGridFields.ShipperName]: 125,
    [CFTransactionsGridFields.ConsigneeName]: 125,
    [CFTransactionsGridFields.CarrierName]: 125,
    [CFTransactionsGridFields.OwnedByName]: 125,
    [CFTransactionsGridFields.CreationDate]: 125,
    [CFTransactionsGridFields.ClientBillable]: 100,
    [CFTransactionsGridFields.ProjectRef]: 125,
};

class Transactions extends GridContainer<TransactionsProps, TransactionsState>  {
    constructor(props: TransactionsProps) {
        super(props);
        this.state = {
            containerWidth: 1,
            selectedId: undefined,
            filters: {},
        };

        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.props.requestTransactions(this.props.clientFileId, this.state.filters, this.props.skip, this.props.pageSize, this.props.sorting);

        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.resize);

        let element = document.querySelector('#TransactionsGridContainer .k-grid-header');
        !!element && element.setAttribute('style', `top: ${this.props.topbarHeight}px` )

        this.setState({
            containerWidth: document.getElementById('TransactionsGridContainer')?.getBoundingClientRect().width ?? 1,
        });

        const localization = provideLocalizationService(this);
        this.props.setHeader(localization.toLanguageString(LocalizationKeys.ClientFile.Headers.Transactions, "%Transactions"))
    }

    componentDidUpdate(prevProps: TransactionsProps) {
        if (!equal(prevProps.transactions, this.props.transactions)) {
            this.setState({ selectedId: undefined, });
        }

        // if (prevProps.shipmentDeleteStatus !== this.props.shipmentDeleteStatus && this.props.shipmentDeleteStatus === ServerOperationStatus.SUCCESS) {
        //     this.props.requestShipments(this.props.clientFileId, this.props.skip, this.props.pageSize, this.props.sorting);
        // }

        if (!equal(prevProps.topbarHeight, this.props.topbarHeight)) {
            let element = document.querySelector('#TransactionsGridContainer .k-grid-header');
            !!element && element.setAttribute('style', `top: ${this.props.topbarHeight}px` )
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("orientationchange", this.resize);
    }

    private resize() {
        this.setState({
            containerWidth: document.getElementById('TransactionsGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    private onFilterApply = (filters: FilterList) => {
        this.setState({filters: filters});
        this.props.requestTransactions(this.props.clientFileId, filters, this.props.skip, this.props.pageSize, this.props.sorting);
    };

    // private onNewShipmentClick() {
    //     this.props.createShipment(this.props.clientFileId);
    // }

    // private onDeleteShipmentClick = () => {
    //     this.props.startDeleteShipments();
    // }

    // private onDeleteShipmentClose = () => {
    //     this.props.cancelDeleteShipments();
    // }

    // private onDeleteShipmentSubmit = () => {
    //     !!this.state.selectedId && this.props.deleteShipments(this.state.selectedId);
    // }

    private onPageChange = (event: GridPageChangeEvent) => {
        this.props.requestTransactions(this.props.clientFileId, this.state.filters, event.page.skip, event.page.take, this.props.sorting);
    }

    private onSortChange = (event: GridSortChangeEvent) => {
        this.props.requestTransactions(this.props.clientFileId, this.state.filters, this.props.skip, this.props.pageSize, event.sort);
    }

    // private onRowDoubleClick(event: GridRowDoubleClickEvent) {
    //     let shipment = event.dataItem as CFShipmentGridItem;
    // }

    private onRowSelect(event: GridSelectionChangeEvent) {
        if (event.nativeEvent.target.tagName === 'A') {
            return false;
        }

        this.setState({
            selectedId: GridUtils.getSelectedIds(event, !!this.state.selectedId ? [this.state.selectedId] : [], DATA_ITEM_KEY)[0],
        });
    }

    private calculateColumnWidth(field: string) : number | undefined {
        return GridUtils.calculateColumnWidth(
            field,
            this.props.columnWidth,
            this.state.containerWidth,
            minColumnWidth
        );
    }

    private get columns() {
        const localization = provideLocalizationService(this);

        let columns = [
            (
                <GridColumn
                    key={"transactions-check"}
                    field={SELECTED_FIELD}
                    width={checkboxColumnWidth}
                    headerClassName={'grid__header--empty'}
                    className={'grid__cell--checkbox'}
                    reorderable={false}
                    orderIndex={0}
                    resizable={false}
                />
            ),
            (
                <GridColumn
                    key={"transactions-type"}
                    field={CFTransactionsGridFields.TransactionType}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Type, "%Type")}
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.TransactionType]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.TransactionType)} />
            ),
            (
                <GridColumn
                    key={"transactions-internalid"}
                    field={CFTransactionsGridFields.InternalId}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.InternalId, "%Internal ID")}
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.InternalId]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.InternalId)} />
            ),
            (
                <GridColumn
                    key={"transactions-scheduledDate"}
                    field={CFTransactionsGridFields.ScheduledDate}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.ScheduledDate, "%Scheduled")}
                    format="{0:d}"
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.ScheduledDate]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.ScheduledDate) ?? 125} />
            ),
            (
                <GridColumn
                    key={"transactions-actualDate"}
                    field={CFTransactionsGridFields.ScheduledDate}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.ActualDate, "%Actual")}
                    format="{0:d}"
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.ActualDate]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.ActualDate) ?? 125} />
            ),
            (
                <GridColumn
                    key={"transactions-trackingNumber"}
                    field={CFTransactionsGridFields.TrackingNumber}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.ClientRef, "%Client ref")}
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.TrackingNumber]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.TrackingNumber)} />
            ),
            (
                <GridColumn
                    key={"transactions-invoiceRef"}
                    field={CFTransactionsGridFields.InvoiceRef}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.InvoiceRef, "%Invoice nr")}
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.InvoiceRef]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.InvoiceRef)} />
            ),
            (
                <GridColumn
                    key={"transactions-status"}
                    field={CFTransactionsGridFields.Status}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Status, "%Status")}
                    cell={(
                        (props: GridCellProps) => {
                            const item = props.dataItem as OperationsCFGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(item.status, item.status)}
                                </CustomCell>
                            );
                        }
                    )}
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.Status]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.Status)} />
            ),
            (
                <GridColumn
                    key={"transactions-divisionCode"}
                    field={CFTransactionsGridFields.DivisionCode}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Division, "%Division")}
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.DivisionCode]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.DivisionCode)} />
            ),
            (
                <GridColumn
                    key={"transactions-shipper"}
                    field={CFTransactionsGridFields.ShipperName}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Shipper, "%Shipper")}
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.DivisionCode]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.DivisionCode)} />
            ),
            (
                <GridColumn
                    key={"transactions-consignee"}
                    field={CFTransactionsGridFields.ConsigneeName}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Consignee, "%Consignee/Receiving party")}
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.ConsigneeName]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.ConsigneeName)} />
            ),
            (
                <GridColumn
                    key={"transactions-carrier"}
                    field={CFTransactionsGridFields.CarrierName}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.Carrier, "%Carrier")}
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.CarrierName]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.CarrierName)} />
            ),
            (
                <GridColumn
                    key={"transactions-ownedBy"}
                    field={CFTransactionsGridFields.OwnedByName}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.OwnedBy, "%Owned by")}
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.OwnedByName]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.OwnedByName)} />
            ),
            (
                <GridColumn
                    key={"transactions-creationDate"}
                    field={CFTransactionsGridFields.CreationDate}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.CreationDate, "%Created")}
                    format="{0:d}"
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.CreationDate]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.CreationDate) ?? 125} />
            ),
            (
                <GridColumn
                    key={"transactions-clientBillable"}
                    field={CFTransactionsGridFields.ClientBillable}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.ClientBillable, "%Billable")}
                    cell={(
                        (props: GridCellProps) => {
                            const operation = props.dataItem as OperationsCFGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Checkbox checked={operation.clientBillable} />
                                </CustomCell>
                            );
                        }
                    )}
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.ClientBillable]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.ClientBillable) ?? 100} />
            ),
            (
                <GridColumn
                    key={"transactions-projectRef"}
                    field={CFTransactionsGridFields.ProjectRef}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Transactions.ProjectRef, "%Project")}
                    orderIndex={this.props.columnOrder[CFTransactionsGridFields.ProjectRef]}
                    width={this.calculateColumnWidth(CFTransactionsGridFields.ProjectRef)} />
            ),
        ];

        return columns;
    }

    render() {
        let gridData = this.props.transactions;

        let columns = this.columns;

        let isGridBusy = this.props.isLoading
            // || this.props.shipmentCreateStatus === ServerOperationStatus.INPROGRESS
            // || this.props.shipmentDeleteStatus === ServerOperationStatus.INPROGRESS;

        return (
            <Grid id={'TransactionsGridContainer'}>
                <AMWGrid
                    sticky={true}
                    scrollable={'scrollable'}

                    data={gridData.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: this.state.selectedId === idGetter(item),
                    }))}
                    dataItemKey={DATA_ITEM_KEY}
                    total={this.props.count}

                    pageable={{ buttonCount: 4, pageSizes: true }}
                    skip={this.props.skip}
                    pageSize={this.props.pageSize}
                    onPageChange={this.onPageChange.bind(this)}

                    sortable={true}
                    sort={this.props.sorting}
                    onSortChange={this.onSortChange.bind(this)}

                    selectedField={SELECTED_FIELD}
                    selectable={{enabled: true, mode:'single'}}
                    onSelectionChange={this.onRowSelect.bind(this)}

                    reorderable={true}
                    onColumnReorder={this.onColumnReorder.bind(this)}

                    resizable={true}
                    onColumnResize={this.onColumnResize.bind(this)}

                    // onRowDoubleClick={this.onRowDoubleClick.bind(this)}
                >
                    <GridToolbar>
                        <TransactionsToolbar
                            isGridBusy={isGridBusy}
                            selectedItem={this.state.selectedId}
                            requestData={this.onFilterApply.bind(this)}
                            filters={this.state.filters}
                            onCreateClick={() => alert('Not implemented')}
                            onDeleteClick={() => alert('Not implemented')}
                            canEdit={this.props.permissions.canEdit}
                            canCreate={true} // TODO
                            canDelete={true} // TODO
                        />
                    </GridToolbar>

                    {columns}
                </AMWGrid>

                {/* <ConfirmationDialog
                    open={this.props.shipmentDeleteStatus !== ServerOperationStatus.NONE &&
                        this.props.shipmentDeleteStatus !== ServerOperationStatus.SUCCESS}
                    title={localization.toLanguageString(LocalizationKeys.ClientFile.Shipping.Delete.Title, "%Delete shipment")}
                    text={localization.toLanguageString(LocalizationKeys.ClientFile.Shipping.Delete.Text, "%Selected shipment will be permanently deleted. Proceed?")}
                    onSubmit={this.onDeleteShipmentSubmit.bind(this)}
                    onCancel={this.onDeleteShipmentClose.bind(this)}
                /> */}
            </Grid>
        );
    }
}
registerForLocalization(Transactions as React.ComponentClass<any>);

export default connect(
    (state: ApplicationState) : TransactionsStateProps => {
        return {
            pageKey: UserPreferencePage.ClientFileTransactions,
            ...state.cfTransactions,
            ...state.screen,
            ...state.clientFile,
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.ClientFileTransactions}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.ClientFileTransactions}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.ClientFileTransactions}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.ClientFileTransactions}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        };
    },
    (dispatch) : TransactionsDispatchProps => {
        return {
            ...bindActionCreators({...TransactionsStore.actionCreators}, dispatch),
            ...bindActionCreators({...UsersStore.actionCreators}, dispatch),
            ...bindActionCreators({...ClientFileStore.actionCreators}, dispatch),
        };
    },
    (stateProps, dispatchProps, ownProps: TransactionsOwnProps) : TransactionsProps => {
        return {
            ...stateProps,
            ...dispatchProps,
            ...ownProps,
        }
    }
)(Transactions as any);