const SurveyGridFilters = {
    StartDate: "StartDate",
    EndDate: "EndDate",
    SurveyNr: "SurveyNr",
    Surveyor: "Surveyor",
    ClientCode: "ClientCode",
    ClientName: "ClientName",
} as const;

export type SurveyGridFiltersType = typeof SurveyGridFilters[keyof typeof SurveyGridFilters];

export default SurveyGridFilters;