import React from 'react';

import QuotationsGridFilters from '~enums/gridFilters/quotationsFilters';
import { PartyType } from '~enums/partyType';
import QuotationStatus from '~enums/quotationStatus';
import { FilterHandler } from '~models/filters';

import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';

import MultiSelectWrapper from '~controls/MultiSelectWrapper';
import OnDemandDropDown from '~controls/OnDemandDropdown';
import FilterContainer from '~components/filtering/FilterContainer';
import FilterItem from '~components/filtering/FilterItem';
import { FilterPanel, FilterPanelProps } from '~components/filtering/FilterPanel';
import DatePickerWrapper from '~components/controls/DatePickerWrapper';
import { PartyListItem } from '~models/party/partyListItem';
import LocalizationKeys from '~enums/localizationKeys';
import TextBoxWrapper from '~components/controls/TextBoxWrapper';


type QuotationsFilterPanelProps = FilterPanelProps;

export default class QuotationsFilterPanel extends FilterPanel<QuotationsFilterPanelProps> {

    private onDivisionChange(event: DropDownListChangeEvent) {
        const value = event.value as PartyListItem;

        this.props.filterChange({
            [QuotationsGridFilters.Division] : { value: value.partyId, text: value.code },
            [QuotationsGridFilters.User] : { value: null }
        });
    }

    private onDivisionClear() {
        this.props.filterChange({
            [QuotationsGridFilters.Division] : null,
            [QuotationsGridFilters.User] : null
        });
    }

    public render() {
        const localization = provideLocalizationService(this);

        const divisionValue = FilterHandler.getDropDownValue(this.props.filters, QuotationsGridFilters.Division, 'partyId', 'code');

        return (
            <FilterContainer
                applyClassName={'Button--orange'}
                clearClassName={'Button--gray'}
                filterApply={this.props.filterApply}
                filterClear={this.props.filterClear}
            >
                <FilterItem>
                    <TextBoxWrapper
                        id="clientCode"
                        name={QuotationsGridFilters.ClientCode}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.ClientCode, "%Client code")}
                        value={FilterHandler.getValue(this.props.filters, QuotationsGridFilters.ClientCode)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="clientName"
                        name={QuotationsGridFilters.ClientName}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.ClientName, "%Client name")}
                        value={FilterHandler.getValue(this.props.filters, QuotationsGridFilters.ClientName)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="clientRef"
                        name={QuotationsGridFilters.ClientRef}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.ClientRef, "%Client ref")}
                        value={FilterHandler.getValue(this.props.filters, QuotationsGridFilters.ClientRef)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="quotationNr"
                        name={QuotationsGridFilters.QuotationNr}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.QuotationNr, "%Quotation #")}
                        value={FilterHandler.getValue(this.props.filters, QuotationsGridFilters.QuotationNr)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="project"
                        name={QuotationsGridFilters.Project}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.Project, "%Project")}
                        value={FilterHandler.getValue(this.props.filters, QuotationsGridFilters.Project)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="description"
                        name={QuotationsGridFilters.Description}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.Description, "%Description")}
                        value={FilterHandler.getValue(this.props.filters, QuotationsGridFilters.Description)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="expenseDetails"
                        name={QuotationsGridFilters.ExpenseDetails}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.ExpenseDetails, "%Expense details")}
                        value={FilterHandler.getValue(this.props.filters, QuotationsGridFilters.Description)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandDropDown
                        url="/v1/parties"
                        urlParams={{partyType: PartyType.Division}}
                        dataItemKey={'partyId'}
                        textField={'code'}
                        id="division"
                        name={QuotationsGridFilters.Division}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.Division, "%Division")}
                        value={divisionValue}
                        pageSize={10}
                        onChange={this.onDivisionChange.bind(this)}
                        clearable={true}
                        clearAdorned={true}
                        onClearClick={this.onDivisionClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandDropDown
                        url={`/v1/parties/${divisionValue?.partyId}/persons`}
                        dataItemKey={'personId'}
                        textField={'fullName'}
                        id="user"
                        name={QuotationsGridFilters.User}
                        disabled={!divisionValue}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.User, "%User")}
                        value={FilterHandler.getDropDownValue(this.props.filters, QuotationsGridFilters.User, 'personId', 'fullName')}
                        pageSize={10}
                        onChange={this.onDropChange.bind(this)}
                        clearable={true}
                        clearAdorned={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <MultiSelectWrapper
                        id="status"
                        name={QuotationsGridFilters.Status}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.Status, "%Status")}
                        data={Object.values(QuotationStatus).map(x => { return {
                            id: x,
                            text: localization.toLanguageString(x, x)
                        }})}
                        textField="text"
                        dataItemKey="id"
                        value={FilterHandler.getMultiselectValue(this.props.filters, QuotationsGridFilters.Status, 'id', 'text')}
                        onInputChange={this.onMultiChange.bind(this)}
                        autoClose={false}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="startDate"
                        name={QuotationsGridFilters.StartDate}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.StartDate, "%Start Date")}
                        value={FilterHandler.getDateValue(this.props.filters, QuotationsGridFilters.StartDate)}
                        onChange={this.onDateChange.bind(this)}
                        max={FilterHandler.getDateValue(this.props.filters, QuotationsGridFilters.EndDate) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="endDate"
                        name={QuotationsGridFilters.EndDate}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.EndDate, "%End Date")}
                        value={FilterHandler.getDateValue(this.props.filters, QuotationsGridFilters.EndDate)}
                        onChange={this.onDateChange.bind(this)}
                        min={FilterHandler.getDateValue(this.props.filters, QuotationsGridFilters.StartDate) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="itemObjectNr"
                        name={QuotationsGridFilters.ItemObjectNr}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.ItemObjectNr, "%Item/Object #")}
                        value={FilterHandler.getValue(this.props.filters, QuotationsGridFilters.ItemObjectNr)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="lot"
                        name={QuotationsGridFilters.ItemLot}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.Lot, "%Lot")}
                        value={FilterHandler.getValue(this.props.filters, QuotationsGridFilters.ItemLot)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="customsEntryNr"
                        name={QuotationsGridFilters.ItemCustomsEntryNr}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.CustomsEntryNr, "%Customs entry #")}
                        value={FilterHandler.getValue(this.props.filters, QuotationsGridFilters.ItemCustomsEntryNr)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="sidemark"
                        name={QuotationsGridFilters.ItemSidemark}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.SideMark, "%Side mark/Sale")}
                        value={FilterHandler.getValue(this.props.filters, QuotationsGridFilters.ItemSidemark)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <TextBoxWrapper
                        id="itemDescription"
                        name={QuotationsGridFilters.ItemDescription}
                        label={localization.toLanguageString(LocalizationKeys.Quotations.Filters.ItemDescription, "%Item description")}
                        value={FilterHandler.getValue(this.props.filters, QuotationsGridFilters.ItemDescription)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>
            </FilterContainer>
        );
    }
}
registerForLocalization(QuotationsFilterPanel as React.ComponentClass<any>);