import React from 'react';
import { Box } from '@mui/material';

export const AuthCallbackSilent: React.FC = () => {
    console.log("silently creeping in to callback your auths")
    return (
        <Box textAlign="center">
            Silent auth: OK
        </Box>
    );
};