import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { ShowErrorAction } from '~store/infra/errors';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import QuotationGridItem from '~models/quotation/quotationGridItem';
import ProjectGridItem from '~models/project/projectGridItem';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import NewQuotationData from '~models/quotation/newQuotationData';
import { SortDescriptor } from "@progress/kendo-data-query";
import QuotationsGridFilters from '~enums/gridFilters/quotationsFilters';
import ProjectsGridFilters from '~enums/gridFilters/projectsFilters';
import { NavigateAction } from '~store/infra/navigation';


export interface QuotationsTabState {
    isLoading: boolean;
    quotations: Array<QuotationGridItem>;
    count: number;
    skip: number;
    quotationCreateStatus: ServerOperationStatus;
    quotationCloneStatus: ServerOperationStatus;
    quotationDeleteStatus: ServerOperationStatus;
}

export interface ProjectsTabState {
    isLoading: boolean;
    projects: Array<ProjectGridItem>;
    count: number;
    skip: number;
    projectCreateStatus: ServerOperationStatus;
    projectDeleteStatus: ServerOperationStatus;
}

export interface QuotationsState {
    quotations: QuotationsTabState;
    projects: ProjectsTabState;
}


// -----------------
// ---- ACTIONS ----
// -----------------

interface QuotationsRequestAction {
    type: 'QUOTATIONS_REQUEST';
    skip: number;
}
interface QuotationsReceiveAction {
    type: 'QUOTATIONS_RECEIVE';
    quotations: QuotationGridItem[];
    count: number;
}
interface QuotationsErrorAction {
    type: 'QUOTATIONS_ERROR';
}

interface QuotationsCreateStartAction {
    type: 'QUOTATIONS_CREATE_START';
}
interface QuotationsCreateSendAction {
    type: 'QUOTATIONS_CREATE_SEND';
}
interface QuotationsCreateSuccessAction {
    type: 'QUOTATIONS_CREATE_SUCCESS';
}
interface QuotationsCreateCancelAction {
    type: 'QUOTATIONS_CREATE_CANCEL';
}
interface QuotationsCreateErrorAction {
    type: 'QUOTATIONS_CREATE_ERROR';
}

interface QuotationsCloneSendAction {
    type: 'QUOTATIONS_CLONE_SEND';
}
interface QuotationsCloneSuccessAction {
    type: 'QUOTATIONS_CLONE_SUCCESS';
}
interface QuotationsCloneErrorAction {
    type: 'QUOTATIONS_CLONE_ERROR';
}

interface QuotationsDeleteStartAction {
    type: 'QUOTATIONS_DELETE_START';
}
interface QuotationsDeleteSendAction {
    type: 'QUOTATIONS_DELETE_SEND';
}
interface QuotationsDeleteSuccessAction {
    type: 'QUOTATIONS_DELETE_SUCCESS';
}
interface QuotationsDeleteCancelAction {
    type: 'QUOTATIONS_DELETE_CANCEL';
}
interface QuotationsDeleteErrorAction {
    type: 'QUOTATIONS_DELETE_ERROR';
}


interface ProjectsRequestAction {
    type: 'QUOTATIONS_PROJECTS_REQUEST';
    skip: number;
}
interface ProjectsReceiveAction {
    type: 'QUOTATIONS_PROJECTS_RECEIVE';
    projects: ProjectGridItem[];
    count: number;
}
interface ProjectsErrorAction {
    type: 'QUOTATIONS_PROJECTS_ERROR';
}

interface ProjectsCreateStartAction {
    type: 'QUOTATIONS_PROJECTS_CREATE_START';
}
interface ProjectsCreateSendAction {
    type: 'QUOTATIONS_PROJECTS_CREATE_SEND';
}
interface ProjectsCreateSuccessAction {
    type: 'QUOTATIONS_PROJECTS_CREATE_SUCCESS';
}
interface ProjectsCreateCancelAction {
    type: 'QUOTATIONS_PROJECTS_CREATE_CANCEL';
}
interface ProjectsCreateErrorAction {
    type: 'QUOTATIONS_PROJECTS_CREATE_ERROR';
}

interface ProjectsDeleteStartAction {
    type: 'QUOTATIONS_PROJECTS_DELETE_START';
}
interface ProjectsDeleteSendAction {
    type: 'QUOTATIONS_PROJECTS_DELETE_SEND';
}
interface ProjectsDeleteSuccessAction {
    type: 'QUOTATIONS_PROJECTS_DELETE_SUCCESS';
}
interface ProjectsDeleteCancelAction {
    type: 'QUOTATIONS_PROJECTS_DELETE_CANCEL';
}
interface ProjectsDeleteErrorAction {
    type: 'QUOTATIONS_PROJECTS_DELETE_ERROR';
}


type KnownAction = QuotationsRequestAction | QuotationsReceiveAction | QuotationsErrorAction

    | QuotationsCreateStartAction | QuotationsCreateSendAction
    | QuotationsCreateSuccessAction | QuotationsCreateCancelAction
    | QuotationsCreateErrorAction

    | QuotationsCloneSendAction | QuotationsCloneSuccessAction | QuotationsCloneErrorAction

    | QuotationsDeleteStartAction | QuotationsDeleteSendAction
    | QuotationsDeleteSuccessAction | QuotationsDeleteCancelAction
    | QuotationsDeleteErrorAction

    | ProjectsRequestAction | ProjectsReceiveAction | ProjectsErrorAction

    | ProjectsCreateStartAction | ProjectsCreateSendAction
    | ProjectsCreateSuccessAction | ProjectsCreateCancelAction
    | ProjectsCreateErrorAction

    | ProjectsDeleteStartAction | ProjectsDeleteSendAction
    | ProjectsDeleteSuccessAction | ProjectsDeleteCancelAction
    | ProjectsDeleteErrorAction

    | NavigateAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestQuotations: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quotations && !appState.quotations.quotations.isLoading) {
            let quoteFilters = {...filters};

            if (!!quoteFilters[QuotationsGridFilters.EndDate]?.value) {
                const dateValue = new Date(quoteFilters[QuotationsGridFilters.EndDate].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                quoteFilters[QuotationsGridFilters.EndDate] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<QuotationGridItem>>('/v1/quotations/search', 'POST', quoteFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.Quotations}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.Quotations}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.Quotations}-${UserPreferenceKey.PageSize}`]: pageSize
                    }));

                    data.data.forEach((file) => {
                        !!file.createdOn && (file.createdOn = new Date(file.createdOn));
                    });
                    dispatch({ type: 'QUOTATIONS_RECEIVE', quotations: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'QUOTATIONS_REQUEST', skip: skip, });
        }
    },
    startCreateQuotations: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quotations) {
            dispatch({ type: 'QUOTATIONS_CREATE_START' });
        }
    },
    createQuotation: (data: NewQuotationData):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quotations) {
            apiClientInstance.fetchRequest(
                '/v1/quotations/',
                'POST',
                data
            )
                .then((newId) => {
                    dispatch({ type: 'NAVIGATION_NAVIGATE', route: `/quotations/${newId}` });

                    dispatch({ type: 'QUOTATIONS_CREATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'QUOTATIONS_CREATE_SEND' });
        }
    },
    cancelCreateQuotations: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quotations) {
            dispatch({ type: 'QUOTATIONS_CREATE_CANCEL' });
        }
    },
    startDeleteQuotations: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quotations) {
            dispatch({ type: 'QUOTATIONS_DELETE_START' });
        }
    },
    deleteQuotations: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quotations) {
            apiClientInstance.fetchRequest(
                `/v1/quotations/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'QUOTATIONS_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'QUOTATIONS_DELETE_SEND' });
        }
    },
    cancelDeleteQuotations: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quotations) {
            dispatch({ type: 'QUOTATIONS_DELETE_CANCEL' });
        }
    },
    cloneQuotations: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quotations) {
            apiClientInstance.fetchRequest(
                `/v1/quotations/${id}/clone`,
                'POST',
            )
                .then((newId) => {
                    dispatch({ type: 'NAVIGATION_NAVIGATE', route: `/quotations/${newId}` });

                    dispatch({ type: 'QUOTATIONS_CREATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'QUOTATIONS_CLONE_SEND' });
        }
    },

    requestProjects: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quotations && !appState.quotations.projects.isLoading) {
            let quoteFilters = {...filters};

            if (!!quoteFilters[ProjectsGridFilters.EndDate]?.value) {
                const dateValue = new Date(quoteFilters[ProjectsGridFilters.EndDate].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                quoteFilters[ProjectsGridFilters.EndDate] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<ProjectGridItem>>('/v1/projects/search', 'POST', quoteFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.Projects}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.Projects}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.Projects}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach((file) => {
                        !!file.startDate && (file.startDate = new Date(file.startDate));
                        !!file.completedDate && (file.completedDate = new Date(file.completedDate));
                    });
                    dispatch({ type: 'QUOTATIONS_PROJECTS_RECEIVE', projects: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'QUOTATIONS_PROJECTS_REQUEST', skip: skip, });
        }
    },
    createProject: ():  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quotations) {
            apiClientInstance.fetchRequest(
                '/v1/projects/',
                'POST',
            )
                .then((newId) => {
                    dispatch({ type: 'NAVIGATION_NAVIGATE', route: `/projects/${newId}` });

                    dispatch({ type: 'QUOTATIONS_PROJECTS_CREATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'QUOTATIONS_PROJECTS_CREATE_SEND' });
        }
    },
    startDeleteProject: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quotations) {
            dispatch({ type: 'QUOTATIONS_PROJECTS_DELETE_START' });
        }
    },
    deleteProject: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quotations) {
            apiClientInstance.fetchRequest(
                `/v1/projects/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'QUOTATIONS_PROJECTS_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'QUOTATIONS_PROJECTS_DELETE_SEND' });
        }
    },
    cancelDeleteProject: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.quotations) {
            dispatch({ type: 'QUOTATIONS_PROJECTS_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: QuotationsState = {
    quotations: {
        isLoading: false,
        quotations: [],
        count: 0,
        skip: 0,
        quotationCreateStatus: ServerOperationStatus.NONE,
        quotationCloneStatus: ServerOperationStatus.NONE,
        quotationDeleteStatus: ServerOperationStatus.NONE,
    },
    projects: {
        isLoading: false,
        projects: [],
        count: 0,
        skip: 0,
        projectCreateStatus: ServerOperationStatus.NONE,
        projectDeleteStatus: ServerOperationStatus.NONE,
    },
 };

export const reducer: Reducer<QuotationsState> = (state: QuotationsState | undefined, incomingAction: Action): QuotationsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'QUOTATIONS_REQUEST':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    isLoading: true,
                    skip: action.skip,
                }
            };
        case 'QUOTATIONS_RECEIVE':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    quotations: action.quotations,
                    count: action.count,
                    isLoading: false,
                }
            };

        case 'QUOTATIONS_CREATE_START':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    quotationCreateStatus: ServerOperationStatus.READY,
                }
            };
        case 'QUOTATIONS_CREATE_SEND':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    isLoading: true,
                    quotationCreateStatus: ServerOperationStatus.INPROGRESS,
                }
            };
        case 'QUOTATIONS_CREATE_CANCEL':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    quotationCreateStatus: ServerOperationStatus.NONE,
                }
            };
        case 'QUOTATIONS_CREATE_SUCCESS':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    isLoading: false,
                    quotationCreateStatus: ServerOperationStatus.SUCCESS,
                }
            };

        case 'QUOTATIONS_CLONE_SEND':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    isLoading: true,
                    quotationCloneStatus: ServerOperationStatus.INPROGRESS,
                }
            };
        case 'QUOTATIONS_CLONE_SUCCESS':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    isLoading: false,
                    quotationCloneStatus: ServerOperationStatus.SUCCESS,
                }
            };

        case 'QUOTATIONS_DELETE_START':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    quotationDeleteStatus: ServerOperationStatus.READY,
                }
            };
        case 'QUOTATIONS_DELETE_SEND':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    quotationDeleteStatus: ServerOperationStatus.INPROGRESS,
                }
            };
        case 'QUOTATIONS_DELETE_CANCEL':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    quotationDeleteStatus: ServerOperationStatus.NONE,
                }
            };
        case 'QUOTATIONS_DELETE_SUCCESS':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    quotationDeleteStatus: ServerOperationStatus.SUCCESS,
                }
            };

        case 'QUOTATIONS_PROJECTS_REQUEST':
            return {
                ...state,
                projects: {
                    ...state.projects,
                    isLoading: true,
                    skip: action.skip,
                }
            };
        case 'QUOTATIONS_PROJECTS_RECEIVE':
            return {
                ...state,
                projects: {
                    ...state.projects,
                    projects: action.projects,
                    count: action.count,
                    isLoading: false,
                }
            };

        case 'QUOTATIONS_PROJECTS_CREATE_SEND':
            return {
                ...state,
                projects: {
                    ...state.projects,
                    isLoading: true,
                    projectCreateStatus: ServerOperationStatus.INPROGRESS,
                }
            };
        case 'QUOTATIONS_PROJECTS_CREATE_SUCCESS':
            return {
                ...state,
                projects: {
                    ...state.projects,
                    isLoading: false,
                    projectCreateStatus: ServerOperationStatus.SUCCESS,
                }
            };

        case 'QUOTATIONS_PROJECTS_DELETE_START':
            return {
                ...state,
                projects: {
                    ...state.projects,
                    projectDeleteStatus: ServerOperationStatus.READY,
                }
            };
        case 'QUOTATIONS_PROJECTS_DELETE_SEND':
            return {
                ...state,
                projects: {
                    ...state.projects,
                    projectDeleteStatus: ServerOperationStatus.INPROGRESS,
                }
            };
        case 'QUOTATIONS_PROJECTS_DELETE_CANCEL':
            return {
                ...state,
                projects: {
                    ...state.projects,
                    projectDeleteStatus: ServerOperationStatus.NONE,
                }
            };
        case 'QUOTATIONS_PROJECTS_DELETE_SUCCESS':
            return {
                ...state,
                projects: {
                    ...state.projects,
                    projectDeleteStatus: ServerOperationStatus.SUCCESS,
                }
            };

        case 'ERROR_MESSAGE_SHOW':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    isLoading: false,
                    quotationCreateStatus: state.quotations.quotationCreateStatus === ServerOperationStatus.INPROGRESS
                        ? ServerOperationStatus.ERROR : state.quotations.quotationCreateStatus,
                    quotationCloneStatus: state.quotations.quotationCloneStatus === ServerOperationStatus.INPROGRESS
                        ? ServerOperationStatus.ERROR : state.quotations.quotationCloneStatus,
                    quotationDeleteStatus: state.quotations.quotationDeleteStatus === ServerOperationStatus.INPROGRESS
                        ? ServerOperationStatus.ERROR : state.quotations.quotationDeleteStatus,
                },
                projects: {
                    ...state.projects,
                    isLoading: false,
                    projectCreateStatus: state.projects.projectCreateStatus === ServerOperationStatus.INPROGRESS
                        ? ServerOperationStatus.ERROR : state.projects.projectCreateStatus,
                    projectDeleteStatus: state.projects.projectDeleteStatus === ServerOperationStatus.INPROGRESS
                        ? ServerOperationStatus.ERROR : state.projects.projectDeleteStatus,
                },
            };
    }

    return state;
};