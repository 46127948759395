import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';
import { apiClientInstance } from '~services/auth/ApiClientInstance';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { ShowErrorAction } from '../infra/errors';
import ContainerFields, { ContainerFieldsType } from '~enums/fields/container';
import ContainerDetails from '~models/container/containerDetails';


export interface ContainerModalState {
    isLoading: boolean,
    data: ContainerDetails,
    createStatus: ServerOperationStatus,
    updateStatus: ServerOperationStatus,
}

const EmptyData: ContainerDetails = {
    containerId: 0,
    barcode: '',
    containerType: '',
    loadType: '',
    netVolume: 0,
    grossVolume: 0,
    chargeableVolume: 0,
    netWeight: 0,
    grossWeight: 0,
    tareWeight: 0,
    length: 0,
    height: 0,
    width: 0,
    numberOfPieces: 0,
    serialNumber: '',
    sealNumber: '',
    billOfLading: '',
    airwayBill: '',
    bookingReference: '',
    photo: '',
    shipmentId: null,
    tripId: null,
};

// -----------------
// ---- ACTIONS ----
// -----------------

interface ContainerRequestSend {
    type: 'CONTAINER_MODAL_REQUEST_SEND';
}

interface ContainerRequestReceive {
    type: 'CONTAINER_MODAL_REQUEST_RECEIVE';
    data: ContainerDetails;
}

interface ContainerCreateSend {
    type: 'CONTAINER_MODAL_CREATE_SEND';
}

interface ContainerCreateSuccess {
    type: 'CONTAINER_MODAL_CREATE_SUCCESS';
    newItem: ContainerDetails;
}

interface ContainerFieldUpdateSuccess {
    type: 'CONTAINER_MODAL_FIELD_UPDATE_SUCCESS';
    object: ContainerDetails;
}

interface ContainerUpdateSend {
    type: 'CONTAINER_MODAL_UPDATE_SEND';
}

export interface ContainerUpdateSuccess {
    type: 'CONTAINER_MODAL_UPDATE_SUCCESS';
    object: ContainerDetails;
}

interface ContainerReset {
    type: 'CONTAINER_MODAL_RESET';
}


type KnownAction = ContainerRequestSend | ContainerRequestReceive |
    ContainerCreateSend | ContainerCreateSuccess |
    ContainerFieldUpdateSuccess | ContainerUpdateSend | ContainerUpdateSuccess |
    ContainerReset | ShowErrorAction;

export const actionCreators = {
    requestData: (id?: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.containerModal && !appState.containerModal.isLoading) {
            if (!id) {
                dispatch({ type: 'CONTAINER_MODAL_REQUEST_RECEIVE', data: EmptyData });
            }
            else {
                apiClientInstance.fetchRequest<ContainerDetails>(
                    `v1/containers/${id}`,
                    'GET',
                )
                    .then((data) => {
                        dispatch({ type: 'CONTAINER_MODAL_REQUEST_RECEIVE', data: data });
                    })
                    .catch((err) => {
                        dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                    });

                dispatch({ type: 'CONTAINER_MODAL_REQUEST_SEND' });
            }
        }
    },
    createShipmentContainer: (shipmentId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.containerModal) {
            apiClientInstance.fetchRequest<number>(
                `v1/shipping/${shipmentId}/containers`,
                'POST',
                { ...appState.containerModal.data }
            )
                .then((data) => {
                    let newItem = {...appState.containerModal.data, shipmentId: shipmentId, containerId: data,} as ContainerDetails;

                    dispatch({ type: 'CONTAINER_MODAL_CREATE_SUCCESS', newItem: newItem });
                })
                .catch((err) => {
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CONTAINER_MODAL_CREATE_SEND' });
        }
    },
    updateContainer: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.containerModal) {
            apiClientInstance.fetchRequest<number>(
                `v1/containers/${appState.containerModal.data.containerId}`,
                'PATCH',
                { ...appState.containerModal.data }
            )
                .then((data) => {
                    let newItem = {...appState.containerModal.data, containerId: data,} as ContainerDetails;

                    dispatch({ type: 'CONTAINER_MODAL_UPDATE_SUCCESS', object: newItem });
                })
                .catch((err) => {
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CONTAINER_MODAL_UPDATE_SEND' });
        }
    },
    updateContainerField: (field: ContainerFieldsType, value: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.containerModal) {
            let container = {...(appState.containerModal?.data ?? {})} as ContainerDetails;

            switch (field) {
                case ContainerFields.ContainerType:
                    container.containerType = value;
                    break;
                case ContainerFields.LoadType:
                    container.loadType = value;
                    break;
                case ContainerFields.NumberOfPieces:
                    container.numberOfPieces = value;
                    break;
                case ContainerFields.SerialNumber:
                    container.serialNumber = value;
                    break;
                case ContainerFields.SealNumber:
                    container.sealNumber = value;
                    break;
                case ContainerFields.NetVolume:
                    container.netVolume = value;
                    break;
                case ContainerFields.GrossVolume:
                    container.grossVolume = value;
                    break;
                case ContainerFields.ChargeableVolume:
                    container.chargeableVolume = value;
                    break;
                case ContainerFields.NetWeight:
                    container.netWeight = value;
                    break;
                case ContainerFields.GrossWeight:
                    container.grossWeight = value;
                    break;
                case ContainerFields.TareWeight:
                    container.tareWeight = value;
                    break;
                case ContainerFields.Length:
                    container.length = value;
                    break;
                case ContainerFields.Width:
                    container.width = value;
                    break;
                case ContainerFields.Height:
                    container.height = value;
                    break;
                case ContainerFields.BillOfLading:
                    container.billOfLading = value;
                    break;
                case ContainerFields.AirwayBill:
                    container.airwayBill = value;
                    break;
                case ContainerFields.BookingRef:
                    container.bookingReference = value;
                    break;
                case ContainerFields.PhotoRef:
                    container.photo = value;
                    break;
            }

            dispatch({ type: 'CONTAINER_MODAL_FIELD_UPDATE_SUCCESS', object: container });
        }
    },
    resetData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.containerModal) {
            dispatch({ type: 'CONTAINER_MODAL_RESET' });
        }
    }
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ContainerModalState = {
    isLoading: false,
    data: EmptyData,
    createStatus: ServerOperationStatus.NONE,
    updateStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ContainerModalState> = (state: ContainerModalState | undefined, incomingAction: Action): ContainerModalState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch (action.type) {
        case 'CONTAINER_MODAL_REQUEST_SEND':
            return {
                ...state,
                isLoading: true,
            };
        case 'CONTAINER_MODAL_REQUEST_RECEIVE':
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };
        case 'CONTAINER_MODAL_CREATE_SEND':
            return {
                ...state,
                createStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'CONTAINER_MODAL_CREATE_SUCCESS':
            return {
                ...state,
                createStatus: ServerOperationStatus.SUCCESS,
            };
        case 'CONTAINER_MODAL_FIELD_UPDATE_SUCCESS':
            return {
                ...state,
                data: action.object,
            };
        case 'CONTAINER_MODAL_UPDATE_SEND':
            return {
                ...state,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'CONTAINER_MODAL_UPDATE_SUCCESS':
            return {
                ...state,
                updateStatus: ServerOperationStatus.SUCCESS
            };
        case 'CONTAINER_MODAL_RESET':
            return {
                ...state,
                createStatus: ServerOperationStatus.NONE,
                updateStatus: ServerOperationStatus.NONE,
                isLoading: false,
                data: EmptyData,
            };
        case 'ERROR_MESSAGE_SHOW':
            return {
                ...state,
                createStatus: state.createStatus === ServerOperationStatus.INPROGRESS ?
                    ServerOperationStatus.ERROR : state.createStatus,
                updateStatus: state.updateStatus === ServerOperationStatus.INPROGRESS ?
                    ServerOperationStatus.ERROR : state.updateStatus,
            };
    }

    return state;
};