const QuotationsGridFields = {
    UniqueId: "uniqueId",
    Status: "status",
    Description: "description",
    TotalAfterTax: "totalAfterTax",
    CreatedOn: "createdOn",
    ClientRef: "clientRef",
    JobId: "jobId",
    ProjectNr: "projectNr",
    ClientName: "clientName",
    ClientCode: "clientCode",
    CreatedByName: "createdByName",
    ShipperCity: "shipperCity",
    ShipperCountry: "shipperCountry",
    ConsigneeCity: "consigneeCity",
    ConsigneeCountry: "consigneeCountry",
} as const;

export type QuotationsGridFieldsType = typeof QuotationsGridFields[keyof typeof QuotationsGridFields];

export default QuotationsGridFields;