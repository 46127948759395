import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import TaskGridItem from '~models/task/taskGridItem';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import { ShowErrorAction } from '~store/infra/errors';
import { SortDescriptor } from "@progress/kendo-data-query";
import TasksGridFilters from '~enums/gridFilters/tasksFilters';


export interface TasksState {
    isLoading: boolean;
    tasks: Array<TaskGridItem>;
    count: number;
    skip: number;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface TasksRequestAction {
    type: 'TASKS_REQUEST';
    skip: number;
}

interface TasksReceiveAction {
    type: 'TASKS_RECEIVE';
    tasks: TaskGridItem[];
    count: number;
}

interface TasksErrorAction {
    type: 'TASKS_ERROR';
}

type KnownAction = TasksRequestAction | TasksReceiveAction
    | TasksErrorAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestTasks: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.tasks && !appState.tasks.isLoading) {
            let tasksFilters = {...filters};

            if (!!tasksFilters[TasksGridFilters.DateTo]?.value) {
                const dateValue = new Date(tasksFilters[TasksGridFilters.DateTo].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                tasksFilters[TasksGridFilters.DateTo] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<TaskGridItem>>('/v1/tasks/search', 'POST', tasksFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.Tasks}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.Tasks}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.Tasks}-${UserPreferenceKey.PageSize}`]: pageSize
                    }));

                    data.data.forEach((task) => {
                        !!task.startDate && (task.startDate = new Date(task.startDate));
                        !!task.dueDate && (task.dueDate = new Date(task.dueDate));
                    });

                    dispatch({ type: 'TASKS_RECEIVE', tasks: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'TASKS_ERROR', });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'TASKS_REQUEST', skip: skip });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: TasksState = {
    isLoading: false,
    tasks: [],
    count: 0,
    skip: 0,
 };

export const reducer: Reducer<TasksState> = (state: TasksState | undefined, incomingAction: Action): TasksState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'TASKS_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'TASKS_RECEIVE':
            return {
                ...state,
                tasks: action.tasks,
                count: action.count,
                isLoading: false,
            };
        case 'TASKS_ERROR':
            return {
                ...state,
                isLoading: false,
            };
    }

    return state;
};