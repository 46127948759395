import React from 'react';
import { provideIntlService, registerForIntl, registerForLocalization } from '@progress/kendo-react-intl';
import { SchedulerItem, SchedulerItemContent, SchedulerItemProps } from '@progress/kendo-react-scheduler';
import { CalendarEventItem } from './Calendar';

class CalendarEventTemplate extends React.Component<SchedulerItemProps> {
    render() {
        const intl = provideIntlService(this);
        
        const event = this.props.dataItem as CalendarEventItem;
        
        let startDateFormatted = `${intl.formatDate(event.start, "t")}`;
        let endDateFormatted = `${intl.formatDate(event.end, "t")}`;
        
        let eventTitle = `${event.title}`;
        let eventTooltip = `${startDateFormatted} - ${endDateFormatted} ${eventTitle}\n` +
            `${event.description}`;
            
        return (
            <SchedulerItem {...this.props}>
                {!this.props.isAllDay && (
                    <SchedulerItemContent title={eventTooltip}>
                        {eventTitle}
                    </SchedulerItemContent>
                )}
                
                {this.props.isAllDay && (
                    <SchedulerItemContent>
                        {eventTitle}
                    </SchedulerItemContent>
                )}
            </SchedulerItem>
        );
    }    
}

registerForIntl(CalendarEventTemplate as React.ComponentClass<any>);
registerForLocalization(CalendarEventTemplate as React.ComponentClass<any>);

export { CalendarEventTemplate };