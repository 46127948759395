import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useOidcIdToken } from "@axa-fr/react-oidc";
import { ApplicationState } from "~store/ApplicationState";
import * as NotificationsStore from "~store/infra/notifications";

import {
    Notifications as NotificationsIcon,
    MailOutline as UnreadIcon,
    DraftsOutlined as ReadIcon
} from '@mui/icons-material';
import { Chip, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Button } from "@progress/kendo-react-buttons";
import { useInternationalization, useLocalization } from "@progress/kendo-react-intl";

import '~css/components/navigation/NotificationMenu.scss';


type NotificationMenuProps = NotificationsStore.NotificationsState &
    typeof NotificationsStore.actionCreators;

const NotificationMenu: React.FC<NotificationMenuProps> = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { idToken } = useOidcIdToken();

    const connect = props.connect;
    const disconnect = props.disconnect;

    useEffect(() => {
        connect(idToken);

        return () => {
            disconnect();
        };
    }, [idToken, connect, disconnect]);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        props.openWindow();
    };

    const handleClose = () => {
        props.closeWindow();
    };

    const markAsReadClick = (id: number) => {
        props.markAsRead(id);
    };

    const intl = useInternationalization();
    const localization = useLocalization();

    return (
        <div className="NotificationMenu NotificationMenu__root">
            <Button
                className="NotificationMenu__button"
                fillMode="flat"
                onClick={handleMenu}
            >
                <NotificationsIcon />

                {props.notificationCount > 99 ? '99+' : `${props.notificationCount}`}

                <Chip
                    label={localization.toLanguageString(`Notification.Menu.NewIndicator`, 'New')}
                    size="small"
                    className={`NotificationMenu__indicator ${props.notificationCount > 0 ? 'NotificationMenu__indicator--active' : ''}`}
                />
            </Button>

            <Menu
                id="notification-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={props.isMenuOpen}
                onClose={handleClose}
                className={"NotificationMenu__menu"}
            >
                {props.notificationCount === 0 && (
                    <MenuItem key={0}>
                        {localization.toLanguageString(`Notification.Menu.NoNewNotifs`, 'No new notifications')}
                    </MenuItem>
                )}

                {props.notifications.map(notif => {
                    return (
                        <MenuItem
                            className={`NotificationMenu__item NotificationMenu__item-${notif.notificationId} ${notif.isRead ? 'NotificationMenu__item--read' : ''}`}
                            key={notif.notificationId}
                        >
                            <Grid container>
                                <Grid item xs={12} className={`NotificationMenu__item__text ${notif.isRead ? 'NotificationMenu__item__text--read' : ''}`} title={notif.notificationText}>
                                    <IconButton
                                        title={"Mark as read"}
                                        className="NotificationMenu__item__button"
                                        onClick={(event) => {markAsReadClick.apply(this, [notif.notificationId])}}
                                    >
                                        {notif.isRead ? (<ReadIcon />) : (<UnreadIcon />)}
                                    </IconButton>

                                    <Typography>
                                        {notif.notificationText}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} className="NotificationMenu__item__date">
                                    {intl.formatDate(notif.createdOn, 'g')}
                                </Grid>
                            </Grid>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

export default connect(
    (state: ApplicationState) => {
        return {
            ...state.notificationMenu
        }
    },
    {
        ...NotificationsStore.actionCreators
    }
)(NotificationMenu as any);