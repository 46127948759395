import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';
import { ShowErrorAction } from '~store/infra/errors';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { SortDescriptor } from "@progress/kendo-data-query";

import SupplierCostFields, { ManagementSupplierCostFieldsType as SupplierCostFieldsType } from '~enums/fields/managementSupplierCost';
import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import SupplierCostCreateModel from '~models/supplierCost/supplierCostCreateModel';
import SupplierCostGridItem from '~models/supplierCost/supplierCostGridItem';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler } from '~models/filters';
import FieldUpdateResult from '~models/fieldUpdate/fieldUpdateResult';


export interface ManagementSupplierCostState {
    isLoading: boolean;
    costs: Array<SupplierCostGridItem>;
    count: number;
    skip: number;
    updatedField?: SupplierCostFieldsType;
    createStatus: ServerOperationStatus;
    updateStatus: ServerOperationStatus;
    deleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface SupplierCostRequestAction {
    type: 'MANAGEMENT_SUPPLIERCOST_REQUEST';
    skip: number;
}
interface SupplierCostReceiveAction {
    type: 'MANAGEMENT_SUPPLIERCOST_RECEIVE';
    costs: Array<SupplierCostGridItem>;
    count: number;
}
interface SupplierCostErrorAction {
    type: 'MANAGEMENT_SUPPLIERCOST_ERROR';
}

interface SupplierCostCreateSendAction {
    type: 'MANAGEMENT_SUPPLIERCOST_CREATE_SEND';
}
interface SupplierCostCreateSuccessAction {
    type: 'MANAGEMENT_SUPPLIERCOST_CREATE_SUCCESS';
    newItem: SupplierCostGridItem;
}
interface SupplierCostCreateErrorAction {
    type: 'MANAGEMENT_SUPPLIERCOST_CREATE_ERROR';
}

interface SupplierCostUpdateSendAction {
    type: 'MANAGEMENT_SUPPLIERCOST_UPDATE_SEND';
    field: SupplierCostFieldsType;
}
interface SupplierCostUpdateSuccessAction {
    type: 'MANAGEMENT_SUPPLIERCOST_UPDATE_SUCCESS';
    object: SupplierCostGridItem;
}
interface SupplierCostUpdateErrorAction {
    type: 'MANAGEMENT_SUPPLIERCOST_UPDATE_ERROR';
}

interface SupplierCostDeleteStartAction {
    type: 'MANAGEMENT_SUPPLIERCOST_DELETE_START';
}
interface SupplierCostDeleteSendAction {
    type: 'MANAGEMENT_SUPPLIERCOST_DELETE_SEND';
}
interface SupplierCostDeleteSuccessAction {
    type: 'MANAGEMENT_SUPPLIERCOST_DELETE_SUCCESS';
}
interface SupplierCostDeleteCancelAction {
    type: 'MANAGEMENT_SUPPLIERCOST_DELETE_CANCEL';
}
interface SupplierCostDeleteErrorAction {
    type: 'MANAGEMENT_SUPPLIERCOST_DELETE_ERROR';
}

type KnownAction = SupplierCostRequestAction | SupplierCostReceiveAction | SupplierCostErrorAction
    | SupplierCostCreateSendAction | SupplierCostCreateSuccessAction | SupplierCostCreateErrorAction
    | SupplierCostUpdateSendAction | SupplierCostUpdateSuccessAction | SupplierCostUpdateErrorAction
    | SupplierCostDeleteStartAction | SupplierCostDeleteSendAction
    | SupplierCostDeleteSuccessAction | SupplierCostDeleteCancelAction
    | SupplierCostDeleteErrorAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestCosts: (routeId: number | undefined, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementSupplierCosts && !appState.managementSupplierCosts.isLoading) {
            if (!routeId) {
                dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_RECEIVE', costs: [], count: 0, });
            }
            else {
                FilterHandler.getApiFilterRequestPromise<DataRequest<SupplierCostGridItem>>('/v1/supplier-costs/search', 'POST', {'routeId': {value: routeId}}, skip, pageSize, sorting)
                    .then(data => {
                        dispatch(UserActions.updateUserPreferences({
                            [`${UserPreferencePage.ManagementSupplierCosts}-${UserPreferenceKey.Sorting}`]: sorting,
                            [`${UserPreferencePage.ManagementSupplierCosts}-${UserPreferenceKey.PageSize}`]: pageSize,
                        }));

                        dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_RECEIVE', costs: data.data, count: data.count, });
                    })
                    .catch(err => {
                        dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_ERROR' });
                        dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                    });

                dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_REQUEST', skip: skip, });
            }
        }
    },
    updateField: (id: number, serverField: SupplierCostFieldsType, value: any, text?: string, onSuccess?: () => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementSupplierCosts) {
            apiClientInstance.fetchRequest(
                `v1/supplier-costs/${id}`,
                'PATCH',
                { field: serverField, value: value }
            )
                .then((data) => {
                    let config = {...appState.managementSupplierCosts?.costs.find(x => x.supplierCostId === id) ?? {} as SupplierCostGridItem};

                    switch (serverField) {
                        case SupplierCostFields.CostRef:
                            config.costRef = (data as FieldUpdateResult<string>).value;
                            break;
                        case SupplierCostFields.SupplierId:
                            config.supplierId = (data as FieldUpdateResult<number>).value;
                            config.supplierName = text ?? '';
                            break;
                        case SupplierCostFields.SupplierRole:
                            config.supplierRole = (data as FieldUpdateResult<string>).value;
                            break;
                        case SupplierCostFields.Option1:
                            config.option1 = (data as FieldUpdateResult<string>).value;
                            break;
                        case SupplierCostFields.Rate1:
                            config.rate1 = (data as FieldUpdateResult<number>).value;
                            break;
                        case SupplierCostFields.Option2:
                            config.option2 = (data as FieldUpdateResult<string>).value;
                            break;
                        case SupplierCostFields.Rate2:
                            config.rate2 = (data as FieldUpdateResult<number>).value;
                            break;
                        case SupplierCostFields.Option3:
                            config.option3 = (data as FieldUpdateResult<string>).value;
                            break;
                        case SupplierCostFields.Rate3:
                            config.rate3 = (data as FieldUpdateResult<number>).value;
                            break;
                    }

                    !!onSuccess && onSuccess();

                    dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_UPDATE_SUCCESS', object: config, });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_UPDATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_UPDATE_SEND', field: serverField });
        }
    },
    createCost: (model: SupplierCostGridItem, onSuccess?: (result: SupplierCostGridItem) => any, onError?: () => any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementSupplierCosts) {
            apiClientInstance.fetchRequest<number>(
                `v1/supplier-costs/`,
                'POST',
                { ...(model as SupplierCostCreateModel) }
            )
                .then((data) => {
                    let newItem = {...model, supplierRouteId: data,} as SupplierCostGridItem;

                    !!onSuccess && onSuccess(newItem);

                    dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_CREATE_SUCCESS', newItem: newItem });
                })
                .catch((err) => {
                    !!onError && onError();

                    dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_CREATE_SEND' });
        }
    },
    startDeleteCost: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementSupplierCosts) {
            dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_DELETE_START' });
        }
    },
    submitDeleteCost: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementSupplierCosts) {
            apiClientInstance.fetchRequest(
                `/v1/supplier-costs/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_DELETE_SEND' });
        }
    },
    cancelDeleteCost: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementSupplierCosts) {
            dispatch({ type: 'MANAGEMENT_SUPPLIERCOST_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementSupplierCostState = {
    isLoading: false,
    costs: [],
    count: 0,
    skip: 0,
    createStatus: ServerOperationStatus.NONE,
    updateStatus: ServerOperationStatus.NONE,
    deleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementSupplierCostState> = (state: ManagementSupplierCostState | undefined, incomingAction: Action): ManagementSupplierCostState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_SUPPLIERCOST_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'MANAGEMENT_SUPPLIERCOST_RECEIVE':
            return {
                ...state,
                costs: action.costs,
                count: action.count,
                isLoading: false,
            };
        case 'MANAGEMENT_SUPPLIERCOST_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'MANAGEMENT_SUPPLIERCOST_UPDATE_SEND':
            return {
                ...state,
                updatedField: action.field,
                updateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_SUPPLIERCOST_UPDATE_SUCCESS':
            {
                let list = [...state.costs];
                let index = state.costs.findIndex(x => x.supplierCostId === action.object.supplierCostId);

                list[index] = action.object;

                return {
                    ...state,
                    costs: list,
                    updatedField: undefined,
                    updateStatus: ServerOperationStatus.SUCCESS
                };
            }
        case 'MANAGEMENT_SUPPLIERCOST_UPDATE_ERROR':
            return {
                ...state,
                updatedField: undefined,
                updateStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_SUPPLIERCOST_CREATE_SEND':
            return {
                ...state,
                createStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_SUPPLIERCOST_CREATE_SUCCESS':
            return {
                ...state,
                createStatus: ServerOperationStatus.SUCCESS,
                costs: [...state.costs, action.newItem],
            };
        case 'MANAGEMENT_SUPPLIERCOST_CREATE_ERROR':
            return {
                ...state,
                createStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_SUPPLIERCOST_DELETE_START':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.READY,
            };
        case 'MANAGEMENT_SUPPLIERCOST_DELETE_SEND':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_SUPPLIERCOST_DELETE_CANCEL':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.NONE,
            };
        case 'MANAGEMENT_SUPPLIERCOST_DELETE_SUCCESS':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'MANAGEMENT_SUPPLIERCOST_DELETE_ERROR':
            return {
                ...state,
                deleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};