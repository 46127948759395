const CRMCompaniesGridFields = {
    Code: "code",
    LegalName: "legalName",
    PartyType: "partyType",
    Warning: "warning",
    BillingInstructions: "billingInstructions",
    Deactivated: "deactivated",
    IsBadPayer: "isBadPayer",
} as const;

export type CRMCompaniesGridFieldsType = typeof CRMCompaniesGridFields[keyof typeof CRMCompaniesGridFields];

export default CRMCompaniesGridFields;