import React from "react";
import { ServerOperationStatus } from "~enums/serverOperationStatus";
import { Button } from "@progress/kendo-react-buttons";
import { provideLocalizationService, registerForLocalization } from "@progress/kendo-react-intl";
import { Close as CloseIcon } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import LocalizationKeys from "~enums/localizationKeys";
import TextBoxWrapper from "~components/controls/TextBoxWrapper";
import AMWDialog from "~controls/AMWDialog";


type NewCampaignModalProps = {
    open: boolean;
    onSubmit: (name: string) => any;
    onClose: () => void;
    createStatus: ServerOperationStatus;
};

interface NewCampaignState {
    name: string,
    isValid: boolean,
}

export default class NewCampaignModal extends React.Component<NewCampaignModalProps, NewCampaignState> {

    constructor(props: NewCampaignModalProps) {
        super(props);
        this.state = {
            name: '',
            isValid: true,
        };
    }

    private isFormValid() {
        return this.state.name !== '';
    }

    private onCampaignSubmit() {
        this.setState({
            isValid: this.isFormValid()
        }, () => {
            if (this.state.isValid) {
                this.props.onSubmit(this.state.name);
            }
        });
    }

    private onClose() {
        this.setState({
            name: '',
            isValid: true
        }, () => {
            this.props.onClose();
        });
    }

    render() {
        const localization = provideLocalizationService(this);

        return (
            <AMWDialog
                open={this.props.open}
                onClose={this.onClose}
                titleContent={
                    <React.Fragment>
                        <span>{localization.toLanguageString(LocalizationKeys.CRM.Campaigns.New.Title, "%New campaign")}</span>

                        <IconButton onClick={this.props.onClose}>
                            <CloseIcon></CloseIcon>
                        </IconButton>
                    </React.Fragment>
                }
                content={
                    <Grid container direction={"column"}>
                        <Grid item className="InputHolder">
                            <TextBoxWrapper
                                id="newCampaignName"
                                name="campaignName"
                                label={localization.toLanguageString(LocalizationKeys.CRM.Campaigns.New.CampaignName, "%Campaign name")}
                                value={this.state.name}
                                onInputChange={(event) => {
                                    this.setState({
                                        name: event.value as string
                                    })
                                }}
                                valid={this.state.isValid || this.state.name !== ''}
                                maxLength={100}
                            />
                        </Grid>
                    </Grid>
                }
                actionsContent={
                    <React.Fragment>
                        <Button
                                className={"Button--bold Button--orange"}
                            onClick={this.onCampaignSubmit.bind(this)}
                            disabled={this.props.createStatus === ServerOperationStatus.INPROGRESS}
                        >
                            {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Create, "%Create")}
                        </Button>
                        <Button
                            onClick={this.onClose.bind(this)}
                            disabled={this.props.createStatus === ServerOperationStatus.INPROGRESS}
                        >
                            {localization.toLanguageString(LocalizationKeys.Shared.Buttons.Cancel, "%Cancel")}
                        </Button>
                    </React.Fragment>
                }
            />
        );
    }
}

registerForLocalization(NewCampaignModal as React.ComponentClass<any>);