import React from "react";
import { connect } from 'react-redux';
import { ApplicationState } from "~store/ApplicationState";
import * as CrumbsStore from '~store/infra/breadcrumbs';

import { useLocalization } from "@progress/kendo-react-intl";
import { Breadcrumb, BreadcrumbLinkMouseEvent } from "@progress/kendo-react-layout";
import { Grid, GridDirection } from "@mui/material";
import { bindActionCreators } from "redux";

type CrumbsOwnProps = {
    className?: string;
    children?: React.ReactNode;
    direction?: GridDirection;
    hide?: boolean;
};

type CrumbsStateProps = CrumbsStore.BreadcrumbsState;
type CrumbsDispatchProps = typeof CrumbsStore.actionCreators;

type CrumbsProps = CrumbsOwnProps & CrumbsStateProps & CrumbsDispatchProps;

const BreadcrumbWrapper = (props: CrumbsProps) => {
    const localization = useLocalization();

    const handleItemSelect = (event: BreadcrumbLinkMouseEvent) => {
        props.goToCrumb(event.id ?? '');
    };

    let localizedCrumbs = props.crumbs.map(x => {
        let localizedText = `${localization.toLanguageString(x.text ?? '', x.text ?? '')}${!!x.appendText ? ` ${x.appendText}` : ''}`;
        
        return {
            ...x,
            text: localizedText
        };
    });
    console.log(props.hide);
    return (
        <Grid container>
            {(props.hide === undefined || props.hide === false) && (
                <Grid item xs={12}>
                    <Breadcrumb 
                        data={localizedCrumbs}
                        onItemSelect={handleItemSelect}
                    />
                </Grid>
            )}
            <Grid item container xs={12} className={props.className} direction={props.direction}>
                {props.children}
            </Grid>
        </Grid>
    );
};

export default connect(
    (state: ApplicationState) : CrumbsStateProps => {
        return {
            ...state.breadcrumbs,
        };
    },
    (dispatch) : CrumbsDispatchProps => {
        const crumbsActions = bindActionCreators({...CrumbsStore.actionCreators}, dispatch);
        return {
            ...crumbsActions,
        };
    },
    (stateProps, dispatchProps, ownProps : CrumbsOwnProps) => {
        return {
            ...ownProps,
            ...stateProps,
            ...dispatchProps,
        };
    }
)(BreadcrumbWrapper as any);