import React from 'react';
import { Grid, GridProps } from "@progress/kendo-react-grid";
import '~css/components/datagrid/AMWGrid.scss';


export type AMWGridProps = {
    /** sets sticky header class to grid */
    sticky?: boolean,
    /** if set, y-scrollable hiding is not set */
    yScrollable?: boolean;
} & GridProps;

export default class AMWGrid extends React.PureComponent<AMWGridProps> {
    render() {
        return (
            <Grid
                {...this.props}
                className={`AMWGrid${this.props.sticky ? ' AMWGrid--sticky' : ''}${this.props.yScrollable ? ' AMWGrid--yScrollable' : ''} ${this.props.className ?? ''}`.trim()}
            >
                {this.props.children}
            </Grid>
        );
    }
}