import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';
import { ShowErrorAction } from '../infra/errors';
import { SortDescriptor } from "@progress/kendo-data-query";
import { actionCreators as UserActions } from '~store/auth/user';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import ItemSearchGridItem from '~models/item/ItemSearchGridItem';
import { FilterHandler, FilterList } from '~models/filters';
import { DataRequest } from '~models/dataRequests';


export interface ItemSearchState {
    items: Array<ItemSearchGridItem>;
    count: number;
    skip: number;
    status: ServerOperationStatus;
    amountAssigned: number;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface ItemSearchOpenAction {
    type: 'ITEM_SEARCH_OPEN';
}
export interface ItemSearchCloseAction {
    type: 'ITEM_SEARCH_CLOSE';
}
interface ItemSearchRequestAction {
    type: 'ITEM_SEARCH_REQUEST';
    skip: number;
}
interface ItemSearchReceiveAction {
    type: 'ITEM_SEARCH_RECEIVE';
    items: Array<ItemSearchGridItem>;
    count: number;
}
interface ItemSearchErrorAction {
    type: 'ITEM_SEARCH_ERROR';
}

type KnownAction = ItemSearchOpenAction | ItemSearchCloseAction
    | ItemSearchRequestAction | ItemSearchReceiveAction | ItemSearchErrorAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;

let abortController: AbortController;

export const outsideActionCreators = {
    openItemSearch: () : AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemSearch) {
            abortController = new AbortController();
            dispatch({ type: 'ITEM_SEARCH_OPEN' });
        }
    },
    closeItemSearch: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemSearch) {
            !!abortController && abortController.abort();
            dispatch({ type: 'ITEM_SEARCH_CLOSE' });
        }
    },
};

export const insideActionCreators = {
    requestItems: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.itemSearch && appState.itemSearch.status !== ServerOperationStatus.INPROGRESS) {
            abortController = new AbortController();
            FilterHandler.getApiFilterRequestPromise<DataRequest<ItemSearchGridItem>>('/v1/items/universal-search', 'GET', filters, skip, pageSize, sorting, undefined, undefined, abortController)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ItemSearch}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.ItemSearch}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'ITEM_SEARCH_RECEIVE', items: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'ITEM_SEARCH_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'ITEM_SEARCH_REQUEST', skip: skip, });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ItemSearchState = {
    items: [],
    count: 0,
    skip: 0,
    status: ServerOperationStatus.NONE,
    amountAssigned: 0,
};

export const reducer: Reducer<ItemSearchState> = (state: ItemSearchState | undefined, incomingAction: Action): ItemSearchState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ITEM_SEARCH_OPEN':
            return {
                ...state,
                status: ServerOperationStatus.READY,
            };
        case 'ITEM_SEARCH_REQUEST':
            return {
                ...state,
                status: ServerOperationStatus.INPROGRESS,
                skip: action.skip,
            };
        case 'ITEM_SEARCH_RECEIVE':
            return {
                ...state,
                items: action.items,
                count: action.count,
                status: ServerOperationStatus.READY,
            };
        case 'ITEM_SEARCH_ERROR':
            return {
                ...state,
                status: ServerOperationStatus.ERROR,
            };
        case 'ITEM_SEARCH_CLOSE':
            return {
                ...unloadedState,
            };
    }

    return state;
};