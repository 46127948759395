const UserPreferencePage = Object.freeze({
    ClientFileShipping: "ClientFileShipping",
    ClientFileTransactions: "ClientFileTransactions",
    Communications: "Communications",
    CompanyDetailsAppointments: "CompanyDetailsAppointments",
    CompanyDetailsOpportunities: "CompanyDetailsOpportunities",
    CompanyDetailsPersons: "CompanyDetailsPersons",
    CompanyDetailsQuotations: "CompanyDetailsQuotations",
    CompanyDetailsTasks: "CompanyDetailsTasks",
    CRMOpportunities: "CRMOpportunities",
    CRMCompanies: "CRMCompanies",
    CRMTasks: "CRMTasks",
    CRMAppointments: "CRMAppointments",
    CRMCalendar: "CRMCalendar",
    CRMCampaigns: "CRMCampaigns",
    DocumentHistory: "DocumentHistory",
    Financials: "Financials",
    GroupageShipments: "GroupageShipments",
    InventoryItems: "InventoryItems",
    InventoryPackages: "InventoryPackages",
    InventoryCrates: "InventoryCrates",
    ItemActionLog: "ItemActionLog",
    ItemChildren: "ItemChildren",
    ItemConditions: "ItemConditions",
    ItemCustomsDocuments: "ItemCustomsDocuments",
    ItemLocationHistory: "ItemLocationHistory",
    ItemPackageHistory: "ItemPackageHistory",
    ItemSearch: "ItemSearch",
    ItemTransactionHistory: "ItemTransactionHistory",
    JobControl: "JobControl",
    LoadItems: "LoadItems",
    ManagementBillableServiceConfig: "ManagementBillableServiceConfig",
    ManagementCompanies: "ManagementCompanies",
    ManagementConfiguration: "ManagementConfiguration",
    ManagementExcelFields: "ManagementExcelFields",
    ManagementExcelSources: "ManagementExcelSources",
    ManagementExcelFieldSources: "ManagementExcelFieldSources",
    ManagementExtendedDims: "ManagementExtendedDims",
    ManagementItemConfig: "ManagementItemConfig",
    ManagementPacking: "ManagementPacking",
    ManagementResources: "ManagementResources",
    ManagementRoles: "ManagementRoles",
    ManagementSupplierRoutes: "ManagementSupplierRoutes",
    ManagementSupplierCosts: "ManagementSupplierCosts",
    ManagementSystemUsers: "ManagementSystemUsers",
    ManagementUsers: "ManagementUsers",
    Operations: "Operations",
    PartySearch: "PartySearch",
    POItems: "POItems",
    Projects: "Projects",
    PurchaseOrderItems: "PurchaseOrderItems",
    QuickEntryItems: "QuickEntryItems",
    Quotations: "Quotations",
    QuotationConfig: "QuotationConfig",
    RecentGrids: "RecentGrids",
    RecentCompanies: "RecentCompanies",
    RecentContacts: "RecentContacts",
    RecentOpportunities: "RecentOpportunities",
    RecentAppointments: "RecentAppointments",
    RecentTasks: "RecentTasks",
    RecentCommunications: "RecentCommunications",
    RecentDocuments: "RecentDocuments",
    ResourceDetailsBlackouts: "ResourceDetailsBlackouts",
    ReusableCrates: "ReusableCrates",
    Shipping: "Shipping",
    ShippingContainers: "ShippingContainers",
    Surveys: "Surveys",
    Tasks: "Tasks",
    TripSearchTrips: "TripSearchTrips",
    TripSearchServices: "TripSearchServices",
    TripSearchTariffs: "TripSearchTariffs",
    TripSearchRanges: "TripSearchRanges",
    Warehouses: "Warehouses",
    WarehouseLocations: "WarehouseLocations",
});
const UserPreferenceKey = Object.freeze({
    ColumnWidth: "ColumnWidth", //local
    Filters: "Filters", //server
    Order: "Order", //server
    PageSize: "PageSize", //local
    Sorting: "Sorting", //server
    Settings: "Settings", //local
});

export {
    UserPreferencePage,
    UserPreferenceKey,
}