const JobControlGridFilters = {
    FileNumber: "FileNumber",
    JobType: "JobType",
    Transaction: "Transaction",
    ClientRef: "ClientRef",
    Client: "Client",
    Shipper: "Shipper",
    InvoiceNumber: "InvoiceNumber",
    TrackingNumber: "TrackingNumber",
    Consignee: "Consignee",
    DivisionId: "DivisionId",
    UserId: "UserId",
    Trip: "Trip",
    ShipmentRef: "ShipmentRef",
    GroupageNumber: "GroupageNumber",
    ObjectNumber: "ObjectNumber",
    PO: "PO",
    PORef: "PORef",
    Status: "Status",
    StartDate: "StartDate",
    EndDate: "EndDate",
} as const;

export type JobControlGridFiltersType = typeof JobControlGridFilters[keyof typeof JobControlGridFilters];

export default JobControlGridFilters;