import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';

export interface ErrorsState {
    errorMessage: string;
    isWarningShown: boolean;
}

// -----------------
// ---- ACTIONS ----
// -----------------

export interface ShowErrorAction {
    type: 'ERROR_MESSAGE_SHOW';
    message: string;
}

interface ShowErrorActionInternal {
    type: 'ERROR_MESSAGE_SHOW_INTERNAL';
    message: string;
}

interface DismissAction {
    type: 'ERROR_MESSAGE_DISMISS';
}

interface ServerOperationWarningAction {
    type: 'SERVER_OPERATION_WARNING_SHOW';
}

interface ServerOperationWarningDismissAction {
    type: 'SERVER_OPERATION_WARNING_DISMISS';
}

type KnownAction = ShowErrorAction | DismissAction | ShowErrorActionInternal |
    ServerOperationWarningAction | ServerOperationWarningDismissAction;

export const actionCreators = {
    showError: (message: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.errors) {
            dispatch({ type: 'ERROR_MESSAGE_SHOW_INTERNAL', message: message });
        }
    },
    dismissError: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.errors) {
            dispatch({ type: 'ERROR_MESSAGE_DISMISS' });
        }
    },
};

export const operationWarnings = {
    showOperationWarning: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.errors) {
            dispatch({ type: 'SERVER_OPERATION_WARNING_SHOW' });
        }
    },
    hideOperationWarning: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.errors) {
            dispatch({ type: 'SERVER_OPERATION_WARNING_DISMISS' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ErrorsState = {
    errorMessage: '',
    isWarningShown: false,
 };

export const reducer: Reducer<ErrorsState> = (state: ErrorsState | undefined, incomingAction: Action): ErrorsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ERROR_MESSAGE_SHOW':
            return {
                ...state,
                errorMessage: action.message,
            };
        case 'ERROR_MESSAGE_SHOW_INTERNAL':
            return {
                ...state,
                errorMessage: action.message,
            };
        case 'ERROR_MESSAGE_DISMISS':
            return {
                ...state,
                errorMessage: ''
            };
        case 'SERVER_OPERATION_WARNING_SHOW':
            return {
                ...state,
                isWarningShown: true,
            };
        case 'SERVER_OPERATION_WARNING_DISMISS':
            return {
                ...state,
                isWarningShown: false,
            };
    }

    return state;
};