import React from 'react';
import { DateTimePicker, DateTimePickerProps } from '@progress/kendo-react-dateinputs';

import '~css/components/controls/DateTimePickerWrapper.scss';
import { LinearProgress } from '@mui/material';


type DateTimePickerWrapperProps = DateTimePickerProps &
{
    inProgress?: boolean;
};

export default class DateTimePickerWrapper extends React.Component<DateTimePickerWrapperProps> {
    __drop?: DateTimePicker | null;

    render() {
        return (
            <div className={'DateTimePickerWrapper'}>
                <DateTimePicker
                    ref={(drop) => {this.__drop = drop;}}
                    {...this.props}
                    placeholder={this.props.placeholder ?? ''}
                />

                {!!this.props.inProgress && (
                    <LinearProgress className={`DateTimePickerWrapper__Progress`} />
                )}
            </div>
        );
    }
}