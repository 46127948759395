import React from 'react';
import {
    InputProps,
    InputState,
    TextArea,
    TextAreaBlurEvent,
    TextAreaChangeEvent
} from '@progress/kendo-react-inputs';
import equal from 'fast-deep-equal/react';
import { FloatingLabel } from '@progress/kendo-react-labels';

import '~css/components/controls/TextAreaWrapper.scss';
import { LinearProgress } from '@mui/material';


export interface TextAreaWrapperEvent {
    id: string;
    name: string;
    value: string | number | string[];
    element: HTMLTextAreaElement | null
}

export interface TextAreaWrapperProps extends InputProps {
    onInputChange?: (event: TextAreaWrapperEvent) => Promise<any> | void;
    inProgress?: boolean;
}

export default class TextAreaWrapper extends React.Component<TextAreaWrapperProps, InputState> {

    constructor(props: TextAreaWrapperProps) {
        super(props);

        this.state = {
            value: props.value
        };
    }

    componentDidUpdate(prevProps: TextAreaWrapperProps) {
        if(!equal(prevProps.value, this.props.value)) {
            this.setState({
                value: this.props.value
            });
        }
    }

    private onChange(event: TextAreaChangeEvent) {
        const value = event.value;
        this.setState({
            value: value
        });
    }

    private onBlur(event: TextAreaBlurEvent) {
        if(!!this.props.onInputChange && this.props.value !== this.state.value) {
            let promise = this.props.onInputChange({
                id: event.target.element?.current?.id ?? '',
                name: event.target.element?.current?.name ?? '',
                value: this.state.value ?? '',
                element: event.target.element?.current ?? null
            });
            if(!!promise) {
                promise.catch((err: any) => {
                    this.setState({value: this.props.value});
                });
            }
        }
    }

    private onKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (event.code === "Escape") {
            this.setState({
                value: this.props.value
            });
        }
    }

    private convertInputProps() {
        let defaultProps: {[key:string]: any} = {};

        for(let key in this.props){
            if (key !== 'onInputChange' && key !== 'inProgress') {
                let entry = Object.entries(this.props).find(x => x[0] === key);

                defaultProps[key] = !!entry ? entry[1] : undefined;
            }
        }

        return defaultProps;
    }

    render() {
        let defaultProps = this.convertInputProps();

        return (
            <div className={'TextAreaWrapper'}>
                {!!this.props.label ?
                    (
                        <FloatingLabel
                            label={this.props.label}
                            editorId={defaultProps.id}
                            editorValue={this.state.value as string}
                        >
                            <TextArea
                                {...defaultProps}
                                value={this.state.value}
                                onChange={this.onChange.bind(this)}
                                onKeyDown={this.onKeyDown.bind(this)}
                                onBlur={this.onBlur.bind(this)}
                            />
                        </FloatingLabel>
                    ) :
                    (
                        <TextArea
                            {...defaultProps}
                            value={this.state.value}
                            onChange={this.onChange.bind(this)}
                            onKeyDown={this.onKeyDown.bind(this)}
                            onBlur={this.onBlur.bind(this)}
                        />
                    )
                }

                {!!this.props.inProgress && (
                    <LinearProgress className={`TextAreaWrapper__Progress`} />
                )}
            </div>
        )
    }
}