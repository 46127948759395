const CampaignFields = Object.freeze({
    OwnedBy: "CampaignField.OwnedBy",
    CampaignType: "CampaignField.CampaignType",
    Status: "CampaignField.Status",
    ExecutionDate: "CampaignField.ExecutionDate",
    CampaignName: "CampaignField.CampaignName",
    Description: "CampaignField.Description",
});

export type CampaignFieldsType = typeof CampaignFields[keyof typeof CampaignFields];

export default CampaignFields;