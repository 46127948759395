import React from 'react';
import EnumType from '~enums/enumType';
import CRMOpportunitiesFilters from '~enums/gridFilters/crmOpportunitiesFilters';
import { PartyType } from '~enums/partyType';
import { FilterHandler } from '~models/filters';
import { provideLocalizationService, registerForLocalization } from '@progress/kendo-react-intl';
import FilterContainer from '~components/filtering/FilterContainer';
import FilterItem from '~components/filtering/FilterItem';
import { FilterPanel, FilterPanelProps } from '~components/filtering/FilterPanel';
import OnDemandMultiSelect from '~controls/OnDemandMultiSelect';
import NumericInputWrapper from '~controls/NumericInputWrapper';
import DatePickerWrapper from '~components/controls/DatePickerWrapper';
import { defaultStringMapper } from '~src/utils/dropdownUtils';
import LocalizationKeys from '~enums/localizationKeys';
import TextBoxWrapper from '~components/controls/TextBoxWrapper';


type OpportunitiesFilterPanelProps = FilterPanelProps;

export default class OpportunitiesFilterPanel extends FilterPanel<OpportunitiesFilterPanelProps> {

    public render() {
        const localization = provideLocalizationService(this);

        return (
            <FilterContainer
                applyClassName={'Button--orange'}
                clearClassName={'Button--gray'}
                filterApply={this.props.filterApply}
                filterClear={this.props.filterClear}
            >
                <FilterItem>
                    <TextBoxWrapper
                        id="description"
                        name={CRMOpportunitiesFilters.Description}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Filters.Description, "%Description")}
                        value={FilterHandler.getValue(this.props.filters, CRMOpportunitiesFilters.Description)}
                        onInputChange={this.onInputChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandMultiSelect
                        url={`/v1/configuration`}
                        urlParams={{ type: EnumType.SourceOfRequest }}
                        dataItemKey={'id'}
                        textField={'text'}
                        itemMapper={defaultStringMapper}
                        id="source"
                        name={CRMOpportunitiesFilters.Source}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Filters.Source, "%Source")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMOpportunitiesFilters.Source, 'id', 'text')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandMultiSelect
                        url={`/v1/configuration`}
                        urlParams={{ type: EnumType.JobType }}
                        dataItemKey={'id'}
                        textField={'text'}
                        itemMapper={defaultStringMapper}
                        id="jobType"
                        name={CRMOpportunitiesFilters.JobType}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Filters.JobType, "%Job type")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMOpportunitiesFilters.JobType, 'id', 'text')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandMultiSelect
                        url="/v1/parties"
                        urlParams={{partyType: PartyType.Division}}
                        dataItemKey={'partyId'}
                        textField={'legalName'}
                        id="territory"
                        name={CRMOpportunitiesFilters.Territory}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Filters.Territory, "%Territory")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMOpportunitiesFilters.Territory, 'partyId', 'legalName')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandMultiSelect
                        url={`/v1/billable-service-configs/job-types`}
                        dataItemKey={'id'}
                        textField={'text'}
                        itemMapper={defaultStringMapper}
                        id="department"
                        name={CRMOpportunitiesFilters.Department}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Filters.Department, "%Department")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMOpportunitiesFilters.Department, 'id', 'text')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandMultiSelect
                        url={`/v1/configuration`}
                        urlParams={{ type: EnumType.OpportunityStage }}
                        dataItemKey={'id'}
                        textField={'text'}
                        itemMapper={defaultStringMapper}
                        id="stage"
                        name={CRMOpportunitiesFilters.Stage}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Filters.Stage, "%Stage")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMOpportunitiesFilters.Stage, 'id', 'text')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <OnDemandMultiSelect
                        url={`/v1/configuration`}
                        urlParams={{ type: EnumType.OpportunityStatus }}
                        dataItemKey={'id'}
                        textField={'text'}
                        itemMapper={defaultStringMapper}
                        id="status"
                        name={CRMOpportunitiesFilters.Status}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Filters.Status, "%Status")}
                        value={FilterHandler.getMultiselectValue(this.props.filters, CRMOpportunitiesFilters.Status, 'id', 'text')}
                        pageSize={10}
                        onInputChange={this.onMultiChange.bind(this)}
                        enableSelectAll={true}
                    />
                </FilterItem>

                <FilterItem>
                    <NumericInputWrapper
                        id="forecastTotal"
                        name={CRMOpportunitiesFilters.ForecastTotal}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Filters.ForecastTotal, "%Forecast total")}
                        value={FilterHandler.getNumericValue(this.props.filters, CRMOpportunitiesFilters.ForecastTotal)}
                        onInputChange={this.onNumericChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                    />
                </FilterItem>

                <FilterItem>
                    <NumericInputWrapper
                        id="certainty"
                        name={CRMOpportunitiesFilters.Certainty}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Filters.Certainty, "%Certainty")}
                        value={FilterHandler.getNumericValue(this.props.filters, CRMOpportunitiesFilters.Certainty)}
                        onInputChange={this.onNumericChange.bind(this)}
                        clearable={true}
                        onClearClick={this.onFilterClear.bind(this)}
                        min={0}
                        max={100}
                        format={'{0:n0}'}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="createdFrom"
                        name={CRMOpportunitiesFilters.CreatedFrom}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Filters.CreatedFrom, "%Created from")}
                        value={FilterHandler.getDateValue(this.props.filters, CRMOpportunitiesFilters.CreatedFrom)}
                        onChange={this.onDateChange.bind(this)}
                        max={FilterHandler.getDateValue(this.props.filters, CRMOpportunitiesFilters.CreatedTo) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="createdTo"
                        name={CRMOpportunitiesFilters.CreatedTo}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Filters.CreatedTo, "%Created to")}
                        value={FilterHandler.getDateValue(this.props.filters, CRMOpportunitiesFilters.CreatedTo)}
                        onChange={this.onDateChange.bind(this)}
                        min={FilterHandler.getDateValue(this.props.filters, CRMOpportunitiesFilters.CreatedFrom) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="relevantTillFrom"
                        name={CRMOpportunitiesFilters.RelevantTillFrom}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Filters.RelevantTillFrom, "%Relevant till from")}
                        value={FilterHandler.getDateValue(this.props.filters, CRMOpportunitiesFilters.RelevantTillFrom)}
                        onChange={this.onDateChange.bind(this)}
                        max={FilterHandler.getDateValue(this.props.filters, CRMOpportunitiesFilters.RelevantTillTo) ?? undefined}
                    />
                </FilterItem>

                <FilterItem>
                    <DatePickerWrapper
                        id="relevantTillTo"
                        name={CRMOpportunitiesFilters.RelevantTillTo}
                        label={localization.toLanguageString(LocalizationKeys.CRM.Opportunities.Filters.RelevantTillTo, "%Relevant till to")}
                        value={FilterHandler.getDateValue(this.props.filters, CRMOpportunitiesFilters.RelevantTillTo)}
                        onChange={this.onDateChange.bind(this)}
                        min={FilterHandler.getDateValue(this.props.filters, CRMOpportunitiesFilters.RelevantTillFrom) ?? undefined}
                    />
                </FilterItem>
            </FilterContainer>
        );
    }
}
registerForLocalization(OpportunitiesFilterPanel as React.ComponentClass<any>);