import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';

import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { ShowErrorAction } from '~store/infra/errors';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import SurveyGridItem from '~models/survey/surveyGridItem';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import NewSurveyData from '~models/survey/newSurveyData';
import { SortDescriptor } from "@progress/kendo-data-query";
import SurveyGridFilters from '~enums/gridFilters/surveysFilters';
import { NavigateAction } from '~store/infra/navigation';


export interface SurveysState {
    isLoading: boolean;
    surveys: Array<SurveyGridItem>;
    count: number;
    skip: number;
    surveyCreateStatus: ServerOperationStatus;
    surveyDeleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface SurveysRequestAction {
    type: 'SURVEYS_REQUEST';
    skip: number;
}
interface SurveysReceiveAction {
    type: 'SURVEYS_RECEIVE';
    surveys: SurveyGridItem[];
    count: number;
}
interface SurveyErrorAction {
    type: 'SURVEYS_ERROR';
}

interface SurveysCreateStartAction {
    type: 'SURVEYS_CREATE_START';
}
interface SurveysCreateSendAction {
    type: 'SURVEYS_CREATE_SEND';
}
interface SurveysCreateSuccessAction {
    type: 'SURVEYS_CREATE_SUCCESS';
}
interface SurveysCreateCancelAction {
    type: 'SURVEYS_CREATE_CANCEL';
}
interface SurveysCreateErrorAction {
    type: 'SURVEYS_CREATE_ERROR';
}

interface SurveysDeleteStartAction {
    type: 'SURVEYS_DELETE_START';
}
interface SurveysDeleteSendAction {
    type: 'SURVEYS_DELETE_SEND';
}
interface SurveysDeleteSuccessAction {
    type: 'SURVEYS_DELETE_SUCCESS';
}
interface SurveysDeleteCancelAction {
    type: 'SURVEYS_DELETE_CANCEL';
}
interface SurveysDeleteErrorAction {
    type: 'SURVEYS_DELETE_ERROR';
}

type KnownAction = SurveysRequestAction | SurveysReceiveAction | SurveyErrorAction
    | SurveysCreateStartAction | SurveysCreateSendAction
    | SurveysCreateSuccessAction | SurveysCreateCancelAction
    | SurveysCreateErrorAction
    | SurveysDeleteStartAction | SurveysDeleteSendAction
    | SurveysDeleteSuccessAction | SurveysDeleteCancelAction
    | SurveysDeleteErrorAction
    | NavigateAction | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestSurveys: (filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.surveys && !appState.surveys.isLoading) {
            let surveysFilters = {...filters};

            if (!!surveysFilters[SurveyGridFilters.EndDate]?.value) {
                const dateValue = new Date(surveysFilters[SurveyGridFilters.EndDate].value);
                dateValue.setUTCHours(dateValue.getUTCHours() + 24);
                surveysFilters[SurveyGridFilters.EndDate] = { value: dateValue };
            }

            FilterHandler.getApiFilterRequestPromise<DataRequest<SurveyGridItem>>('/v1/surveys/search', 'POST', surveysFilters, skip, pageSize, sorting)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.Surveys}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.Surveys}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.Surveys}-${UserPreferenceKey.PageSize}`]: pageSize
                    }));

                    data.data.forEach((file) => {
                        !!file.surveyDate && (file.surveyDate = new Date(file.surveyDate));
                    });
                    dispatch({ type: 'SURVEYS_RECEIVE', surveys: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'SURVEYS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'SURVEYS_REQUEST', skip: skip, });
        }
    },
    startCreateSurveys: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.surveys) {
            dispatch({ type: 'SURVEYS_CREATE_START' });
        }
    },
    createSurvey: (data: NewSurveyData):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.surveys) {
            apiClientInstance.fetchRequest(
                '/v1/surveys/',
                'POST',
                data
            )
                .then((newId) => {
                    dispatch({ type: 'NAVIGATION_NAVIGATE', route: `/surveys/${newId}` });

                    dispatch({ type: 'SURVEYS_CREATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'SURVEYS_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'SURVEYS_CREATE_SEND' });
        }
    },
    cancelCreateSurveys: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.surveys) {
            dispatch({ type: 'SURVEYS_CREATE_CANCEL' });
        }
    },
    startDeleteSurveys: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.surveys) {
            dispatch({ type: 'SURVEYS_DELETE_START' });
        }
    },
    deleteSurveys: (id: number):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.surveys) {
            apiClientInstance.fetchRequest(
                `/v1/surveys/${id}`,
                'DELETE',
            )
                .then(() => {
                    dispatch({ type: 'SURVEYS_DELETE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'SURVEYS_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'SURVEYS_DELETE_SEND' });
        }
    },
    cancelDeleteSurveys: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.surveys) {
            dispatch({ type: 'SURVEYS_DELETE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: SurveysState = {
    isLoading: false,
    surveys: [],
    count: 0,
    skip: 0,
    surveyCreateStatus: ServerOperationStatus.NONE,
    surveyDeleteStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<SurveysState> = (state: SurveysState | undefined, incomingAction: Action): SurveysState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'SURVEYS_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'SURVEYS_RECEIVE':
            return {
                ...state,
                surveys: action.surveys,
                count: action.count,
                isLoading: false,
            };
        case 'SURVEYS_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'SURVEYS_CREATE_START':
            return {
                ...state,
                surveyCreateStatus: ServerOperationStatus.READY,
            };
        case 'SURVEYS_CREATE_SEND':
            return {
                ...state,
                isLoading: true,
                surveyCreateStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'SURVEYS_CREATE_CANCEL':
            return {
                ...state,
                surveyCreateStatus: ServerOperationStatus.NONE,
            };
        case 'SURVEYS_CREATE_SUCCESS':
            return {
                ...state,
                isLoading: false,
                surveyCreateStatus: ServerOperationStatus.SUCCESS,
            };
        case 'SURVEYS_CREATE_ERROR':
            return {
                ...state,
                isLoading: false,
                surveyCreateStatus: ServerOperationStatus.ERROR,
            };

        case 'SURVEYS_DELETE_START':
            return {
                ...state,
                surveyDeleteStatus: ServerOperationStatus.READY,
            };
        case 'SURVEYS_DELETE_SEND':
            return {
                ...state,
                surveyDeleteStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'SURVEYS_DELETE_CANCEL':
            return {
                ...state,
                surveyDeleteStatus: ServerOperationStatus.NONE,
            };
        case 'SURVEYS_DELETE_SUCCESS':
            return {
                ...state,
                surveyDeleteStatus: ServerOperationStatus.SUCCESS,
            };
        case 'SURVEYS_DELETE_ERROR':
            return {
                ...state,
                surveyDeleteStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};