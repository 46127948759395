import * as React from 'react';
import { useLocation } from 'react-router';
import LocalizationKeys from '~enums/localizationKeys';
import { UserPermissions } from '~enums/userPermissions';
import { AMWUserData } from '~models/user/AMWUserData';

import {
    BusinessCenter as JobsIcon,
    DirectionsBoat as ShippingIcon,
    Calculate as FinancialsIcon,
    Inventory as InventoryIcon,
    LocalShipping as OperationsIcon,
    CallSplit as TripsIcon,
    Hail as SurveysIcon,
    AttachMoney as QuotationsIcon,
    Campaign as CRMIcon,
    FormatListBulleted as TasksIcon,
    Warehouse as WarehouseIcon,
    Drafts as CratesIcon,
    ManageAccounts as ManagementIcon,
} from '@mui/icons-material';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { useLocalization } from "@progress/kendo-react-intl";

import '~css/components/navigation/SideNav.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Navigation from '~store/infra/navigation';
import { ApplicationState } from '~store/ApplicationState';

interface SideNavItemOwnProps {
    route: string;
    title: string;
    active: boolean;
    icon: React.ReactElement;
}
type SideNavItemProps = SideNavItemOwnProps & typeof Navigation.actionCreators;

const SideNavItemBase = (props: SideNavItemProps) => {
    const onMenuSelect = (route: string) => {
        props.navigate(route);
    }

    return (
        <ListItem
            button
            className={`SideNav__item ${props.active ? 'SideNav__item--active' : ''}`}
            onClick={() => {onMenuSelect(props.route)}}
        >
            <ListItemIcon>
                {props.icon}
            </ListItemIcon>

            <ListItemText
                primary={props.title}
            />
        </ListItem>
    );
};

const SideNavItem = connect(
    undefined,
    (dispatch) => {
        return {
            ...bindActionCreators({...Navigation.actionCreators}, dispatch),
        };
    },
    (stateProps, dispatchProps, ownProps: SideNavItemOwnProps) : SideNavItemProps => {
        return {
            ...dispatchProps,
            ...ownProps,
        };
    }
)(SideNavItemBase as any);

type SideNavStateProps = {userData?: AMWUserData};
type SideNavDispatchProps = typeof Navigation.actionCreators;
type SideNavProps = SideNavStateProps & SideNavDispatchProps;

const SideNav = (props: SideNavProps) => {
    const location = useLocation();
    const localization = useLocalization();
    const currentLocation = location.pathname;

    const canViewCrm = props.userData?.hasPermission(UserPermissions.CRM.Appointments.View)
        || props.userData?.hasPermission(UserPermissions.CRM.Campaigns.View)
        || props.userData?.hasPermission(UserPermissions.CRM.Calendar.View)
        || props.userData?.hasPermission(UserPermissions.CRM.Companies.View)
        || props.userData?.hasPermission(UserPermissions.CRM.Opportunities.View)
        || props.userData?.hasPermission(UserPermissions.CRM.Tasks.View)
        || props.userData?.hasPermission(UserPermissions.CRM.Recent.View);

    return (
        <Drawer
            className="SideNav SideNav__root"
            variant="permanent"
            anchor="left"
        >
            <List>
                <ListItem
                    className={"SideNav__logo"}
                    onClick={() => {props.navigate('/')}}
                >
                    <img alt="artmanager logo" src="artman-icon.svg"></img>
                </ListItem>

                {props.userData?.hasPermission(UserPermissions.ClientFile.JobControlView) && (
                    <SideNavItem
                        icon={<JobsIcon />}
                        route={'/files'}
                        active={currentLocation.startsWith('/files')}
                        title={localization.toLanguageString(LocalizationKeys.Navigation.SideNav.JobControl, "%Job control")}
                    />
                )}

                {props.userData?.hasPermission(UserPermissions.Shipping.ShippingView) && (
                    <SideNavItem
                        icon={<ShippingIcon />}
                        route={'/shipping'}
                        active={currentLocation.startsWith('/shipping')}
                        title={localization.toLanguageString(LocalizationKeys.Navigation.SideNav.Shipping, "%Shipping")}
                    />
                )}

                {props.userData?.hasPermission(UserPermissions.Financials.FinancialsView) && (
                    <SideNavItem
                        icon={<FinancialsIcon />}
                        route={'/financials'}
                        active={currentLocation.startsWith('/financials')}
                        title={localization.toLanguageString(LocalizationKeys.Navigation.SideNav.Financials, "%Financials")}
                    />
                )}

                {props.userData?.hasPermission(UserPermissions.Inventory.InventoryView) && (
                    <SideNavItem
                        icon={<InventoryIcon />}
                        route={'/inventory'}
                        active={currentLocation.startsWith('/inventory')}
                        title={localization.toLanguageString(LocalizationKeys.Navigation.SideNav.Inventory, "%Inventory")}
                    />
                )}

                {props.userData?.hasPermission(UserPermissions.Operations.OperationsView) && (
                    <SideNavItem
                        icon={<OperationsIcon />}
                        route={'/operations'}
                        active={currentLocation.startsWith('/operations')}
                        title={localization.toLanguageString(LocalizationKeys.Navigation.SideNav.Operations, "%Operations")}
                    />
                )}

                {props.userData?.hasPermission(UserPermissions.Trips.TripsView) && (
                    <SideNavItem
                        icon={<TripsIcon />}
                        route={'/trips'}
                        active={currentLocation.startsWith('/trips')}
                        title={localization.toLanguageString(LocalizationKeys.Navigation.SideNav.Trips, "%Trips")}
                    />
                )}

                {props.userData?.hasPermission(UserPermissions.Surveys.SurveysView) && (
                    <SideNavItem
                        icon={<SurveysIcon />}
                        route={'/surveys'}
                        active={currentLocation.startsWith('/surveys')}
                        title={localization.toLanguageString(LocalizationKeys.Navigation.SideNav.Surveys, "%Surveys")}
                    />
                )}

                {(props.userData?.hasPermission(UserPermissions.Quotations.QuotationsView) || props.userData?.hasPermission(UserPermissions.Quotations.ProjectsView)) && (
                    <SideNavItem
                        icon={<QuotationsIcon />}
                        route={props.userData?.hasPermission(UserPermissions.Quotations.QuotationsView) ? '/quotations' : '/projects'}
                        active={currentLocation.startsWith('/quotations') || currentLocation.startsWith('/projects')}
                        title={localization.toLanguageString(LocalizationKeys.Navigation.SideNav.Quotations, "%Quotations")}
                    />
                )}

                {canViewCrm && (
                    <SideNavItem
                        icon={<CRMIcon />}
                        route={'/crm'}
                        active={currentLocation.startsWith('/crm')}
                        title={localization.toLanguageString(LocalizationKeys.Navigation.SideNav.CRM, "%CRM")}
                    />
                )}

                {props.userData?.hasPermission(UserPermissions.Tasks.TasksView) && (
                    <SideNavItem
                        icon={<TasksIcon />}
                        route={'/tasks'}
                        active={currentLocation.startsWith('/tasks')}
                        title={localization.toLanguageString(LocalizationKeys.Navigation.SideNav.Tasks, "%Tasks")}
                    />
                )}

                {props.userData?.hasPermission(UserPermissions.Warehouses.WarehousesView) && (
                    <SideNavItem
                        icon={<WarehouseIcon />}
                        route={'/warehouses'}
                        active={currentLocation.startsWith('/warehouses')}
                        title={localization.toLanguageString(LocalizationKeys.Navigation.SideNav.Warehouses, "%Warehouses")}
                    />
                )}

                {props.userData?.hasPermission(UserPermissions.Crates.CratesView) && (
                    <SideNavItem
                        icon={<CratesIcon />}
                        route={'/reusable-crates'}
                        active={currentLocation.startsWith('/reusable-crates')}
                        title={localization.toLanguageString(LocalizationKeys.Navigation.SideNav.ReusableCrates, "%Crates")}
                    />
                )}

                {props.userData?.hasPermission(UserPermissions.Management.ManagementView) && (
                    <SideNavItem
                        icon={<ManagementIcon />}
                        route={'/management'}
                        active={currentLocation.startsWith('/management')}
                        title={localization.toLanguageString(LocalizationKeys.Navigation.SideNav.Management, "%Management")}
                    />
                )}
            </List>
        </Drawer>
    );
}

export default connect(
    (state: ApplicationState) : SideNavStateProps => {
        return {
            userData: state.user.userData,
        };
    },
    (dispatch) : SideNavDispatchProps => {
        return {
            ...bindActionCreators({...Navigation.actionCreators}, dispatch),
        };
    }
)(SideNav);