import { Action, Reducer } from 'redux';
import { ClientFilePermissionData } from '~models/clientFile/clientFilePermissionData';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { AppThunkAction } from '~store/ApplicationState';


export interface ClientFileState {
    headerText: string;
    permissions: ClientFilePermissionData;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface SetHeaderAction {
    type: 'CLIENT_FILE_SET_HEADER';
    text: string;
}

interface CheckPermissionsReceive {
    type: 'CLIENT_FILE_CHECK_PERMISSIONS_RECEIVE';
    data: ClientFilePermissionData;
}

interface CheckPermissionsRequest {
    type: 'CLIENT_FILE_CHECK_PERMISSIONS_REQUEST';
}

type KnownAction = SetHeaderAction | CheckPermissionsReceive | CheckPermissionsRequest;

let abortController : AbortController;

export const actionCreators = {
    checkEditPermissions: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.clientFile) {
            !!abortController && abortController.abort();
            abortController = new AbortController();
            apiClientInstance.fetchRequest<ClientFilePermissionData>(`v1/files/${id}/permissions`, 'GET', undefined, undefined, abortController)
                .then((data) => {
                    dispatch({ type: 'CLIENT_FILE_CHECK_PERMISSIONS_RECEIVE', data: data });
                });

            dispatch({ type: 'CLIENT_FILE_CHECK_PERMISSIONS_REQUEST' });
        }
    },
    setHeader: (text: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.clientFile) {
            dispatch({ type: 'CLIENT_FILE_SET_HEADER', text: text });
        }
    }
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ClientFileState = {
    headerText: '',
    permissions: {
        canEdit: false,
    },
 };

export const reducer: Reducer<ClientFileState> = (state: ClientFileState | undefined, incomingAction: Action): ClientFileState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CLIENT_FILE_SET_HEADER':
            return {
                ...state,
                headerText: action.text,
            };
        case 'CLIENT_FILE_CHECK_PERMISSIONS_RECEIVE':
            return {
                ...state,
                permissions: action.data,
            };
        case 'CLIENT_FILE_CHECK_PERMISSIONS_REQUEST':
            return {
                ...state,
                permissions: {
                    canEdit: false,
                },
            };
    }

    return state;
};