import { Action, Reducer } from 'redux';
import { AppThunkAction } from '../ApplicationState';
import { apiClientInstance } from '~services/auth/ApiClientInstance';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { NewPartyKeysType } from '~enums/newPartyKeys';
import { ShowErrorAction } from '../infra/errors';
import NewPartyData from '~models/party/newPartyData';
import PartyCreateResult from '~models/party/partyCreateResult';


export interface NewPartyState {
    controlKey?: NewPartyKeysType,
    createResult?: PartyCreateResult,
    status: ServerOperationStatus;
}

export type NewPartyElementStateProps = { partyCreation: NewPartyState };
export type NewPartyElementDispatchProps = { partyCreation: typeof actionCreators };
export type NewPartyElementProps = { partyCreation: NewPartyState & typeof actionCreators };

// -----------------
// ---- ACTIONS ----
// -----------------

interface NewPartyStartAction {
    type: 'NEW_PARTY_CREATE_START';
    key: NewPartyKeysType;
}

interface NewPartySendAction {
    type: 'NEW_PARTY_CREATE_SEND';
}

interface NewPartySuccessAction {
    type: 'NEW_PARTY_CREATE_SUCCESS';
    result: PartyCreateResult;
}

interface NewPartyCancelAction {
    type: 'NEW_PARTY_CREATE_CANCEL';
}

interface NewPartyClearResultAction {
    type: 'NEW_PARTY_CLEAR_RESULT';
}

interface NewPartyErrorAction {
    type: 'NEW_PARTY_CREATE_ERROR';
}

type KnownAction = NewPartyStartAction | NewPartySendAction
    | NewPartySuccessAction | NewPartyCancelAction | NewPartyClearResultAction
    | NewPartyErrorAction | ShowErrorAction;

export const actionCreators = {
    openDialog: (key: NewPartyKeysType): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.newParty) {
            dispatch({ type: 'NEW_PARTY_CREATE_START', key: key });
        }
    },
    submitParty: (model: NewPartyData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.newParty && appState.newParty.status !== ServerOperationStatus.INPROGRESS) {
            apiClientInstance.fetchRequest<PartyCreateResult>(
                '/v1/parties/',
                'POST',
                {
                    ...model,
                }
            )
                .then((result) => {
                    dispatch({ type: 'NEW_PARTY_CREATE_SUCCESS', result: result });
                })
                .catch((err) => {
                    dispatch({ type: 'NEW_PARTY_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'NEW_PARTY_CREATE_SEND' });
        }
    },
    cancelDialog: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.newParty) {
            dispatch({ type: 'NEW_PARTY_CREATE_CANCEL' });
        }
    },
    clearResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.newParty) {
            dispatch({ type: 'NEW_PARTY_CLEAR_RESULT' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: NewPartyState = {
    status: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<NewPartyState> = (state: NewPartyState | undefined, incomingAction: Action): NewPartyState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'NEW_PARTY_CREATE_START':
            return {
                status: ServerOperationStatus.READY,
                controlKey: action.key
            };
        case 'NEW_PARTY_CREATE_CANCEL':
            return {
                status: ServerOperationStatus.NONE,
            };
        case 'NEW_PARTY_CREATE_SEND':
            return {
                ...state,
                status: ServerOperationStatus.INPROGRESS,
            };
        case 'NEW_PARTY_CREATE_SUCCESS':
            return {
                ...state,
                status: ServerOperationStatus.SUCCESS,
                createResult: action.result,
            };
        case 'NEW_PARTY_CLEAR_RESULT':
            return {
                status: ServerOperationStatus.NONE,
            };
        case 'NEW_PARTY_CREATE_ERROR':
            return {
                status: ServerOperationStatus.ERROR,
            };
    }

    return state;
};