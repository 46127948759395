import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';

import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { ShowErrorAction } from '~store/infra/errors';
import { EnumTypeType } from '~enums/enumType';
import { DataRequest } from '~models/dataRequests';


export interface ConfigurationState {
    configs: {
        [key: string]: Array<string>
    }
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface ConfigurationEnumRequestAction {
    type: 'CONFIGURATION_ENUM_REQUEST';
}

interface ConfigurationEnumReceiveAction {
    type: 'CONFIGURATION_ENUM_RECEIVE';
    enumType: EnumTypeType;
    config: Array<string>;
}

type KnownAction = ConfigurationEnumRequestAction | ConfigurationEnumReceiveAction | 
    ShowErrorAction;

export const actionCreators = {
    requestConfigs: (type: EnumTypeType): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.configuration) {
            apiClientInstance.fetchRequest<DataRequest<string>>(`/v1/configuration/?type=${type}`, 'GET')
                .then(data => {
                    dispatch({ 
                        type: 'CONFIGURATION_ENUM_RECEIVE',
                        enumType: type,
                        config: data.data,
                    });
                })
                .catch(err => {
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'CONFIGURATION_ENUM_REQUEST', });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ConfigurationState = {
    configs: {},
 };

export const reducer: Reducer<ConfigurationState> = (state: ConfigurationState | undefined, incomingAction: Action): ConfigurationState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'CONFIGURATION_ENUM_REQUEST':
            return {
                ...state,
            };
        case 'CONFIGURATION_ENUM_RECEIVE':
            return {
                configs: {
                    ...state.configs,
                    [action.enumType]: action.config
                }
            };
    }

    return state;
};