import React from 'react';
import {
    Checkbox,
    CheckboxProps,
} from '@progress/kendo-react-inputs';

import '~css/components/controls/CheckboxWrapper.scss';
import { CircularProgress } from '@mui/material';


type CheckboxWrapperProps = CheckboxProps & {
    inProgress?: boolean;
};

const customProps = ['inProgress'];

export default class CheckboxWrapper extends React.Component<CheckboxWrapperProps> {
    private convertInputProps() {
        let defaultProps: {[key:string]: any} = {};

        for(let key in this.props){
            if (!customProps.includes(key)) {
                let entry = Object.entries(this.props).find(x => x[0] === key);

                defaultProps[key] = !!entry ? entry[1] : undefined;
            }
        }

        return defaultProps;
    }

    render() {
        const defaultProps = this.convertInputProps();

        return (
            <div className={`CheckboxWrapper ${this.props.className ?? ''}`}>
                <Checkbox
                    {...defaultProps}
                    value={!!this.props.value ? true : false}
                    className={undefined}
                />

                {!!this.props.inProgress && (
                    <CircularProgress className={`CheckboxWrapper__Progress`} />
                )}
            </div>
        );
    }
}