import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { actionCreators as UserActions } from '~store/auth/user';
import { apiClientInstance } from '~services/auth/ApiClientInstance';

import { ShowErrorAction } from '~store/infra/errors';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import RoleItem from '~models/role/roleItem';


export interface ManagementRolesState {
    isLoading: boolean;
    roles: Array<RoleItem>;
    count: number;
    skip: number;
    createStatus: ServerOperationStatus;
    cloneStatus: ServerOperationStatus;
    // deleteStatus: ServerOperationStatus;
}

// -----------------
// ---- ACTIONS ----
// -----------------

interface RolesRequestAction {
    type: 'MANAGEMENT_ROLES_REQUEST';
    skip: number;
}
interface RolesReceiveAction {
    type: 'MANAGEMENT_ROLES_RECEIVE';
    roles: Array<RoleItem>;
    count: number;
}
interface RolesErrorAction {
    type: 'MANAGEMENT_ROLES_ERROR';
}

interface RolesCreateStartAction {
    type: 'MANAGEMENT_ROLES_CREATE_START';
}
interface RolesCreateSendAction {
    type: 'MANAGEMENT_ROLES_CREATE_SEND';
}
interface RolesCreateSuccessAction {
    type: 'MANAGEMENT_ROLES_CREATE_SUCCESS';
}
interface RolesCreateCancelAction {
    type: 'MANAGEMENT_ROLES_CREATE_CANCEL';
}
interface RolesCreateErrorAction {
    type: 'MANAGEMENT_ROLES_CREATE_ERROR';
}

interface RolesCloneStartAction {
    type: 'MANAGEMENT_ROLES_CLONE_START';
}
interface RolesCloneSendAction {
    type: 'MANAGEMENT_ROLES_CLONE_SEND';
}
interface RolesCloneSuccessAction {
    type: 'MANAGEMENT_ROLES_CLONE_SUCCESS';
}
interface RolesCloneCancelAction {
    type: 'MANAGEMENT_ROLES_CLONE_CANCEL';
}
interface RolesCloneErrorAction {
    type: 'MANAGEMENT_ROLES_CLONE_ERROR';
}

type KnownAction = RolesRequestAction | RolesReceiveAction | RolesErrorAction
    | RolesCreateStartAction | RolesCreateSendAction
    | RolesCreateSuccessAction | RolesCreateCancelAction
    | RolesCreateErrorAction
    | RolesCloneStartAction | RolesCloneSendAction
    | RolesCloneSuccessAction | RolesCloneCancelAction
    | RolesCloneErrorAction
    | ShowErrorAction;

type Dispatchables = KnownAction | AppThunkAction<any>;


export const actionCreators = {
    requestRoles: (filters: FilterList, skip: number, pageSize: number): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementRoles && !appState.managementRoles.isLoading) {
            FilterHandler.getApiFilterRequestPromise<DataRequest<RoleItem>>('/v1/roles', 'GET', filters, skip, pageSize)
                .then(data => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.ManagementRoles}-${UserPreferenceKey.Filters}`]: filters,
                        [`${UserPreferencePage.ManagementRoles}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'MANAGEMENT_ROLES_RECEIVE', roles: data.data, count: data.count, });
                })
                .catch(err => {
                    dispatch({ type: 'MANAGEMENT_ROLES_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_ROLES_REQUEST', skip: skip, });
        }
    },

    startCreateRole: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementRoles) {
            dispatch({ type: 'MANAGEMENT_ROLES_CREATE_START' });
        }
    },
    submitCreateRole: (name: string, description: string):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementRoles) {
            apiClientInstance.fetchRequest(
                `/v1/roles/`,
                'POST',
                { name, description }
            )
                .then(() => {
                    dispatch({ type: 'MANAGEMENT_ROLES_CREATE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'MANAGEMENT_ROLES_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_ROLES_CREATE_SEND' });
        }
    },
    cancelCreateRole: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementRoles) {
            dispatch({ type: 'MANAGEMENT_ROLES_CREATE_CANCEL' });
        }
    },

    startCloneRole: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementRoles) {
            dispatch({ type: 'MANAGEMENT_ROLES_CLONE_START' });
        }
    },
    submitCloneRole: (id:number, name: string, description: string):  AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementRoles) {
            apiClientInstance.fetchRequest(
                `/v1/roles/${id}/clone`,
                'POST',
                { name, description }
            )
                .then(() => {
                    dispatch({ type: 'MANAGEMENT_ROLES_CLONE_SUCCESS' });
                })
                .catch((err) => {
                    dispatch({ type: 'MANAGEMENT_ROLES_CLONE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'MANAGEMENT_ROLES_CLONE_SEND' });
        }
    },
    cancelCloneRole: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.managementRoles) {
            dispatch({ type: 'MANAGEMENT_ROLES_CLONE_CANCEL' });
        }
    },
};


// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: ManagementRolesState = {
    isLoading: false,
    roles: [],
    count: 0,
    skip: 0,
    createStatus: ServerOperationStatus.NONE,
    cloneStatus: ServerOperationStatus.NONE,
 };

export const reducer: Reducer<ManagementRolesState> = (state: ManagementRolesState | undefined, incomingAction: Action): ManagementRolesState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'MANAGEMENT_ROLES_REQUEST':
            return {
                ...state,
                isLoading: true,
                skip: action.skip,
            };
        case 'MANAGEMENT_ROLES_RECEIVE':
            return {
                ...state,
                roles: action.roles,
                isLoading: false,
                count: action.count,
            };
        case 'MANAGEMENT_ROLES_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'MANAGEMENT_ROLES_CREATE_START':
            return {
                ...state,
                createStatus: ServerOperationStatus.READY,
            };
        case 'MANAGEMENT_ROLES_CREATE_SEND':
            return {
                ...state,
                createStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_ROLES_CREATE_CANCEL':
            return {
                ...state,
                createStatus: ServerOperationStatus.NONE,
            };
        case 'MANAGEMENT_ROLES_CREATE_SUCCESS':
            return {
                ...state,
                createStatus: ServerOperationStatus.SUCCESS,
            };
        case 'MANAGEMENT_ROLES_CREATE_ERROR':
            return {
                ...state,
                createStatus: ServerOperationStatus.ERROR,
            };

        case 'MANAGEMENT_ROLES_CLONE_START':
            return {
                ...state,
                cloneStatus: ServerOperationStatus.READY,
            };
        case 'MANAGEMENT_ROLES_CLONE_SEND':
            return {
                ...state,
                cloneStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'MANAGEMENT_ROLES_CLONE_CANCEL':
            return {
                ...state,
                cloneStatus: ServerOperationStatus.NONE,
            };
        case 'MANAGEMENT_ROLES_CLONE_SUCCESS':
            return {
                ...state,
                cloneStatus: ServerOperationStatus.SUCCESS,
            };
        case 'MANAGEMENT_ROLES_CLONE_ERROR':
            return {
                ...state,
                cloneStatus: ServerOperationStatus.ERROR,
            };
    }

    return state;
};