import { Action, Reducer } from 'redux';
import { AppThunkAction } from '~store/ApplicationState';
import { ShowErrorAction } from '~store/infra/errors';
import { apiClientInstance } from '~services/auth/ApiClientInstance';
import { SortDescriptor } from "@progress/kendo-data-query";
import { actionCreators as UserActions } from '~store/auth/user';

import { ServerOperationStatus } from '~enums/serverOperationStatus';
import AddressFields, { AddressFieldsType } from '~enums/fields/address';
import CompanyFields, { CompanyFieldsType } from '~enums/fields/company';
import ContactFields, { ContactFieldsType } from '~enums/fields/contact';
import CompanyPersonFields, { CompanyPersonFieldsType } from '~enums/fields/companyPerson';
import PersonFields from '~enums/fields/person';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import AddressPatch from '~models/address/addressPatch';
import AddressData from '~models/address/addressData';
import PartyAddressItem from '~models/address/partyAddressItem';
import AppointmentCompanyGridItem from '~models/appointment/appointmentCompanyGridItem';
import ContactData from '~models/contact/contactData';
import ContactPatch from '~models/contact/contactPatch';
import PartyContactItem from '~models/contact/partyContactItem';
import { DataRequest } from '~models/dataRequests';
import { FilterHandler, FilterList } from '~models/filters';
import GenericPatch from '~models/genericPatch';
import OpportunityCompanyGridItem from '~models/opportunity/opportunityCompanyGridItem';
import CompanyDetailsData from '~models/party/companyDetailsData';
import CompanyPatch from '~models/party/companyPatch';
import { PartyListItem } from '~models/party/partyListItem';
import { PersonCompanyGridItem } from '~models/person/personCompanyGridItem';
import { PersonAddressData, PersonContactData, PersonData } from '~models/person/personData';
import { PersonPatch } from '~models/person/personPatch';
import QuotationCompanyGridItem from '~models/quotation/quotationCompanyGridItem';
import TaskCompanyGridItem from '~models/task/taskCompanyGridItem';


export interface CompanyAddressesState {
    addresses: PartyAddressItem[];
    contacts: PartyContactItem[];

    isLoadingAddresses: boolean;
    isLoadingContacts: boolean;

    addressCreateStatus: ServerOperationStatus;
    addressUpdateStatus: ServerOperationStatus;
    addressDeleteStatus: ServerOperationStatus;
    contactCreateStatus: ServerOperationStatus;
    contactUpdateStatus: ServerOperationStatus;
    contactDeleteStatus: ServerOperationStatus;

    addressDetails: CompanyAddressDetailsState;
    contactDetails: CompanyContactDetailsState;
}

export interface CompanyAddressDetailsState {
    isLoading: boolean;
    data: AddressData;
}

export interface CompanyContactDetailsState {
    isLoading: boolean;
    data: ContactData;
}

export interface CompanyQuotationsState {
    isLoading: boolean;
    quotations: QuotationCompanyGridItem[];
    count: number;
    skip: number;
}

export interface CompanyOpportunitiesState {
    isLoading: boolean;
    opportunities: OpportunityCompanyGridItem[];
    count: number;
    skip: number;
}

export interface CompanyPersonsState {
    isLoading: boolean;
    persons: PersonCompanyGridItem[];
    count: number;
    skip: number;
    personCreateStatus: ServerOperationStatus;
    personUpdateStatus: ServerOperationStatus;
    personDeleteStatus: ServerOperationStatus;
    personInitializeStatus: ServerOperationStatus;

    personDetails: CompanyPersonDetailsState;
}

export interface CompanyPersonDetailsState {
    isLoading: boolean;
    data: PersonData;
}

export interface CompanyTasksState {
    isLoading: boolean;
    tasks: TaskCompanyGridItem[];
    count: number;
    skip: number;
}

export interface CompanyAppointmentsState {
    isLoading: boolean;
    appointments: AppointmentCompanyGridItem[];
    count: number;
    skip: number;
}

export interface CompanyModalState {
    isLoading: boolean;
    companyId: number;
    data: CompanyDetailsData;
    updateStatus: ServerOperationStatus;
    generateCodeStatus: ServerOperationStatus;
    updatingField?: CompanyFieldsType | AddressFieldsType | ContactFieldsType | CompanyPersonFieldsType;

    addresses: CompanyAddressesState;
    appointments: CompanyAppointmentsState;
    opportunities: CompanyOpportunitiesState;
    persons: CompanyPersonsState;
    quotations: CompanyQuotationsState;
    tasks: CompanyTasksState;
}

const EmptyData: CompanyDetailsData = {
    partyId: 0,
    code: '',
    legalName: '',
    partyType: '',
    partyCategory: '',
    source: '',

    terms: '',
    currency: '',
    creditLimit: 0,
    webViewInventory: '',
    defaultTaxType: '',

    createdBy: '',
    createdOn: new Date(),
    balance: 0,
    vatNumber: '',
    einNumber: '',

    lengthUnits: '',
    weightUnits: '',
    volumeUnits: '',
    deactivated: false,
    insuranceRequired: false,
    taxExempt: false,
    badPayer: false,

    warning: '',
    billingInstructions: '',

    lmCode: '',
    legalEntityId: null,
    legalEntityName: '',

    isEditable: false,
};

export const EmptyAddressData : AddressData = {
    addressId: 0,
    street1: '',
    street2: '',
    street3: '',
    city: '',
    zip: '',
    country: '',
    state: '',
    addressType: '',
    disabled: false,
    comments: '',
    isBillingType: true,
    isMailingType: true,
    isShippingType: true,
    isBillingDefault: false,
    isMailingDefault: false,
    isShippingDefault: false,
};

export const EmptyContactData : ContactData = {
    contactInfoId: 0,
    phone: '',
    mobile: '',
    email: '',
    fax: '',
    web: '',
    useForGeneral: true,
    useForMailing: true,
    useForShipping: true,
    useForBilling: true,
};

export const EmptyPersonData : PersonData = {
    personId: 0,
    firstName: "",
    lastName: "",
    fullName: "",
    title: "",
    personRole: "",
    isGeneralType: false,
    isMailingType: false,
    isBillingType: false,
    isShippingType: false,
    address: null,
    contactInfo: {
        contactInfoId: 0,
        email: "",
        phone: "",
        mobilePhone: "",
        fax: "",
    },
};

// -----------------
// ---- ACTIONS ----
// -----------------


interface RequestDataAction {
    type: 'COMPANY_REQUEST_DATA';
    companyId: number;
}
interface ReceiveDataAction {
    type: 'COMPANY_RECEIVE_DATA';
    data: CompanyDetailsData;
}
interface ReceiveDataErrorAction {
    type: 'COMPANY_RECEIVE_DATA_ERROR';
}

interface ClearDataAction {
    type: 'COMPANY_CLEAR_DATA';
}
interface ClearAddressDataAction {
    type: 'COMPANY_CLEAR_ADDRESS';
}
interface ClearPersonDataAction {
    type: 'COMPANY_CLEAR_PERSON';
}

interface UpdateDataAction {
    type: 'COMPANY_UPDATE_DATA';
    data: CompanyDetailsData;
}
interface UpdateDataRequestAction {
    type: 'COMPANY_UPDATE_DATA_REQUEST';
    field: CompanyFieldsType;
}
interface UpdateDataErrorAction {
    type: 'COMPANY_UPDATE_DATA_ERROR';
}

interface GenerateCodeRequestAction {
    type: 'COMPANY_GENERATE_CODE_REQUEST';
}
interface GenerateCodeSuccessAction {
    type: 'COMPANY_GENERATE_CODE_SUCCESS';
    newCode: string;
}
interface GenerateCodeErrorAction {
    type: 'COMPANY_GENERATE_CODE_ERROR';
}

interface AddressesRequestAction {
    type: 'COMPANY_ADDRESSES_REQUEST_DATA';
}
interface AddressesReceiveAction {
    type: 'COMPANY_ADDRESSES_RECEIVE_DATA';
    data: PartyAddressItem[];
}
interface AddressesErrorAction {
    type: 'COMPANY_ADDRESSES_ERROR';
}

interface AddressesCreateStartAction {
    type: 'COMPANY_ADDRESSES_CREATE_START';
}
interface AddressesCreateSubmitAction {
    type: 'COMPANY_ADDRESSES_CREATE_SUBMIT';
}
interface AddressesCreateSuccessAction {
    type: 'COMPANY_ADDRESSES_CREATE_SUCCESS';
    newId: number;
    model: AddressData;
}
interface AddressesCreateCancelAction {
    type: 'COMPANY_ADDRESSES_CREATE_CANCEL';
}
interface AddressesCreateErrorAction {
    type: 'COMPANY_ADDRESSES_CREATE_ERROR';
}

interface AddressesDeleteSubmitAction {
    type: 'COMPANY_ADDRESSES_DELETE_SUBMIT';
}
interface AddressesDeleteSuccessAction {
    type: 'COMPANY_ADDRESSES_DELETE_SUCCESS';
    deletedId: number;
}
interface AddressesDeleteErrorAction {
    type: 'COMPANY_ADDRESSES_DELETE_ERROR';
}

interface AddressesDetailsRequestAction {
    type: 'COMPANY_ADDRESSES_REQUEST_DETAILS';
}
interface AddressesDetailsReceiveAction {
    type: 'COMPANY_ADDRESSES_RECEIVE_DETAILS';
    data: AddressData;
}
interface AddressesDetailsErrorAction {
    type: 'COMPANY_ADDRESSES_RECEIVE_DETAILS_ERROR';
}

interface AddressesDetailsUpdateAction {
    type: 'COMPANY_ADDRESSES_UPDATE_DETAILS';
    data: AddressData;
}
interface AddressesDetailsUpdateRequestAction {
    type: 'COMPANY_ADDRESSES_UPDATE_DETAILS_REQUEST';
    field: AddressFieldsType;
}
interface AddressesDetailsUpdateErrorAction {
    type: 'COMPANY_ADDRESSES_UPDATE_DETAILS_ERROR';
}

interface AppointmentsRequestAction {
    type: 'COMPANY_APPOINTMENTS_REQUEST_DATA';
    skip: number;
}
interface AppointmentsReceiveAction {
    type: 'COMPANY_APPOINTMENTS_RECEIVE_DATA';
    count: number;
    data: AppointmentCompanyGridItem[];
}
interface AppointmentsErrorAction {
    type: 'COMPANY_APPOINTMENTS_RECEIVE_DATA_ERROR';
}

interface ContactsRequestAction {
    type: 'COMPANY_CONTACTS_REQUEST_DATA';
}
interface ContactsReceiveAction {
    type: 'COMPANY_CONTACTS_RECEIVE_DATA';
    data: PartyContactItem[];
}
interface ContactsErrorAction {
    type: 'COMPANY_CONTACTS_RECEIVE_DATA_ERROR';
}

interface ContactsCreateStartAction {
    type: 'COMPANY_CONTACTS_CREATE_START';
}
interface ContactsCreateSubmitAction {
    type: 'COMPANY_CONTACTS_CREATE_SUBMIT';
}
interface ContactsCreateSuccessAction {
    type: 'COMPANY_CONTACTS_CREATE_SUCCESS';
    newId: number;
    model: ContactData;
}
interface ContactsCreateCancelAction {
    type: 'COMPANY_CONTACTS_CREATE_CANCEL';
}
interface ContactsCreateErrorAction {
    type: 'COMPANY_CONTACTS_CREATE_ERROR';
}

interface ContactsDeleteSubmitAction {
    type: 'COMPANY_CONTACTS_DELETE_SUBMIT';
}
interface ContactsDeleteSuccessAction {
    type: 'COMPANY_CONTACTS_DELETE_SUCCESS';
    deletedId: number;
}
interface ContactsDeleteErrorAction {
    type: 'COMPANY_CONTACTS_DELETE_ERROR';
}

interface ContactsDetailsRequestAction {
    type: 'COMPANY_CONTACTS_REQUEST_DETAILS';
}
interface ContactsDetailsReceiveAction {
    type: 'COMPANY_CONTACTS_RECEIVE_DETAILS';
    data: ContactData;
}
interface ContactsDetailsErrorAction {
    type: 'COMPANY_CONTACTS_RECEIVE_DETAILS_ERROR';
}

interface ContactsDetailsUpdateAction {
    type: 'COMPANY_CONTACTS_UPDATE_DETAILS';
    data: ContactData;
}
interface ContactsDetailsUpdateRequestAction {
    type: 'COMPANY_CONTACTS_UPDATE_DETAILS_REQUEST';
    field: ContactFieldsType;
}
interface ContactsDetailsUpdateErrorAction {
    type: 'COMPANY_CONTACTS_UPDATE_DETAILS_ERROR';
}

interface OpportunitiesRequestAction {
    type: 'COMPANY_OPPORTUNITIES_REQUEST_DATA';
    skip: number;
}
interface OpportunitiesReceiveAction {
    type: 'COMPANY_OPPORTUNITIES_RECEIVE_DATA';
    count: number;
    data: OpportunityCompanyGridItem[];
}
interface OpportunitiesErrorAction {
    type: 'COMPANY_OPPORTUNITIES_RECEIVE_DATA_ERROR';
}

interface PersonsRequestAction {
    type: 'COMPANY_PERSONS_REQUEST_DATA';
    skip: number;
}
interface PersonsReceiveAction {
    type: 'COMPANY_PERSONS_RECEIVE_DATA';
    count: number;
    data: PersonCompanyGridItem[];
}
interface PersonsErrorAction {
    type: 'COMPANY_PERSONS_RECEIVE_DATA_ERROR';
}
interface PersonsCreateStartAction {
    type: 'COMPANY_PERSONS_CREATE_START';
}
interface PersonsCreateSubmitAction {
    type: 'COMPANY_PERSONS_CREATE_SUBMIT';
}
interface PersonsCreateSuccessAction {
    type: 'COMPANY_PERSONS_CREATE_SUCCESS';
    newId: number;
    model: PersonData;
}
interface PersonsCreateCancelAction {
    type: 'COMPANY_PERSONS_CREATE_CANCEL';
}
interface PersonsCreateErrorAction {
    type: 'COMPANY_PERSONS_CREATE_ERROR';
}
interface PersonsDeleteSubmitAction {
    type: 'COMPANY_PERSONS_DELETE_SUBMIT';
}
interface PersonsDeleteSuccessAction {
    type: 'COMPANY_PERSONS_DELETE_SUCCESS';
    deletedId: number;
}
interface PersonsDeleteErrorAction {
    type: 'COMPANY_PERSONS_DELETE_ERROR';
}
interface PersonsInitializeSubmitAction {
    type: 'COMPANY_PERSONS_INITIALIZE_SUBMIT';
}
interface PersonsInitializeSuccessAction {
    type: 'COMPANY_PERSONS_INITIALIZE_SUCCESS';
}
interface PersonsInitializeErrorAction {
    type: 'COMPANY_PERSONS_INITIALIZE_ERROR';
}

interface PersonDetailsRequestAction {
    type: 'COMPANY_PERSONS_REQUEST_DETAILS';
}
interface PersonDetailsReceiveAction {
    type: 'COMPANY_PERSONS_RECEIVE_DETAILS';
    data: PersonData;
}
interface PersonDetailsErrorAction {
    type: 'COMPANY_PERSONS_RECEIVE_DETAILS_ERROR'
}
interface PersonDetailsUpdateAction {
    type: 'COMPANY_PERSONS_UPDATE_DETAILS';
    data: PersonData;
}
interface PersonDetailsUpdateRequestAction {
    type: 'COMPANY_PERSONS_UPDATE_DETAILS_REQUEST';
    field: CompanyPersonFieldsType;
}
interface PersonDetailsUpdateErrorAction {
    type: 'COMPANY_PERSONS_UPDATE_DETAILS_ERROR';
}

interface QuotationsRequestAction {
    type: 'COMPANY_QUOTATIONS_REQUEST_DATA';
    skip: number;
}
interface QuotationsReceiveAction {
    type: 'COMPANY_QUOTATIONS_RECEIVE_DATA';
    count: number;
    data: QuotationCompanyGridItem[];
}
interface QuotationsErrorAction {
    type: 'COMPANY_QUOTATIONS_RECEIVE_DATA_ERROR';
}

interface TasksRequestAction {
    type: 'COMPANY_TASKS_REQUEST_DATA';
    skip: number;
}
interface TasksReceiveAction {
    type: 'COMPANY_TASKS_RECEIVE_DATA';
    count: number;
    data: TaskCompanyGridItem[];
}
interface TasksErrorAction {
    type: 'COMPANY_TASKS_RECEIVE_DATA_ERROR';
}


type KnownAction = RequestDataAction | ReceiveDataAction | ReceiveDataErrorAction

    | ClearDataAction | ClearAddressDataAction | ClearPersonDataAction
    | UpdateDataAction | UpdateDataRequestAction | UpdateDataErrorAction
    | GenerateCodeRequestAction | GenerateCodeSuccessAction | GenerateCodeErrorAction

    | AddressesRequestAction | AddressesReceiveAction | AddressesErrorAction
    | AddressesCreateStartAction | AddressesCreateSubmitAction | AddressesCreateSuccessAction | AddressesCreateCancelAction | AddressesCreateErrorAction
    | AddressesDeleteSubmitAction | AddressesDeleteSuccessAction | AddressesDeleteErrorAction
    | AddressesDetailsRequestAction | AddressesDetailsReceiveAction | AddressesDetailsErrorAction
    | AddressesDetailsUpdateAction | AddressesDetailsUpdateRequestAction | AddressesDetailsUpdateErrorAction

    | AppointmentsRequestAction | AppointmentsReceiveAction | AppointmentsErrorAction

    | ContactsRequestAction | ContactsReceiveAction | ContactsErrorAction
    | ContactsCreateStartAction | ContactsCreateSubmitAction | ContactsCreateSuccessAction | ContactsCreateCancelAction | ContactsCreateErrorAction
    | ContactsDeleteSubmitAction | ContactsDeleteSuccessAction | ContactsDeleteErrorAction
    | ContactsDetailsRequestAction | ContactsDetailsReceiveAction | ContactsDetailsErrorAction
    | ContactsDetailsUpdateRequestAction | ContactsDetailsUpdateAction | ContactsDetailsUpdateErrorAction

    | OpportunitiesRequestAction | OpportunitiesReceiveAction | OpportunitiesErrorAction

    | PersonsRequestAction | PersonsReceiveAction | PersonsErrorAction
    | PersonsCreateStartAction | PersonsCreateSubmitAction | PersonsCreateSuccessAction | PersonsCreateCancelAction | PersonsCreateErrorAction
    | PersonsDeleteSubmitAction | PersonsDeleteSuccessAction | PersonsDeleteErrorAction
    | PersonsInitializeSubmitAction | PersonsInitializeSuccessAction | PersonsInitializeErrorAction
    | PersonDetailsRequestAction | PersonDetailsReceiveAction | PersonDetailsErrorAction
    | PersonDetailsUpdateAction | PersonDetailsUpdateRequestAction | PersonDetailsUpdateErrorAction

    | QuotationsRequestAction | QuotationsReceiveAction | QuotationsErrorAction
    | TasksRequestAction | TasksReceiveAction | TasksErrorAction

    | ShowErrorAction;

const buildPatch = (field: CompanyFieldsType, value: any) => {
    const patchModel = {field: field} as CompanyPatch;

    switch (field) {
        case CompanyFields.Code:
            patchModel.code = value as string;
            break;
        case CompanyFields.LegalName:
            patchModel.legalName = value as string;
            break;
        case CompanyFields.PartyType:
            patchModel.partyType = value as string;
            break;
        case CompanyFields.PartyCategory:
            patchModel.partyCategory = value as string;
            break;
        case CompanyFields.Source:
            patchModel.source = value as string;
            break;
        case CompanyFields.Terms:
            patchModel.terms = value as string;
            break;
        case CompanyFields.Currency:
            patchModel.currency = value as string;
            break;
        case CompanyFields.WebViewInventory:
            patchModel.webViewInventory = value as string;
            break;
        case CompanyFields.DefaultTaxType:
            patchModel.defaultTaxType = value as string;
            break;
        case CompanyFields.VatNumber:
            patchModel.vatNumber = value as string;
            break;
        case CompanyFields.EinNumber:
            patchModel.einNumber = value as string;
            break;
        case CompanyFields.LengthUnits:
            patchModel.lengthUnits = value as string;
            break;
        case CompanyFields.WeightUnits:
            patchModel.weightUnits = value as string;
            break;
        case CompanyFields.VolumeUnits:
            patchModel.volumeUnits = value as string;
            break;
        case CompanyFields.Deactivated:
            patchModel.deactivated = value as boolean;
            break;
        case CompanyFields.InsuranceRequired:
            patchModel.insuranceRequired = value as boolean;
            break;
        case CompanyFields.TaxExempt:
            patchModel.taxExempt = value as boolean;
            break;
        case CompanyFields.BadPayer:
            patchModel.badPayer = value as boolean;
            break;
        case CompanyFields.Warning:
            patchModel.warning = value as string;
            break;
        case CompanyFields.BillingInstructions:
            patchModel.billingInstructions = value as string;
            break;
        case CompanyFields.LMCode:
            patchModel.lMCode = value as string;
            break;
        case CompanyFields.LegalEntity:
            patchModel.legalEntityId = value as number;
            break;
    }

    return patchModel;
};

const buildAddressPatch = (field: AddressFieldsType, value: any) => {
    const patchModel = {field: field} as AddressPatch;

    switch (field) {
        case AddressFields.Street1:
            patchModel.street1 = value as string;
            break;
        case AddressFields.Street2:
            patchModel.street2 = value as string;
            break;
        case AddressFields.Street3:
            patchModel.street3 = value as string;
            break;
        case AddressFields.City:
            patchModel.city = value as string;
            break;
        case AddressFields.ZIP:
            patchModel.zip = value as string;
            break;
        case AddressFields.Country:
            patchModel.country = value as string;
            break;
        case AddressFields.State:
            patchModel.state = value as string;
            break;
        case AddressFields.AddressType:
            patchModel.addressType = value as string;
            break;
        case AddressFields.Disabled:
            patchModel.disabled = value as boolean;
            break;
        case AddressFields.Comments:
            patchModel.comments = value as string;
            break;
        case AddressFields.UseForBilling:
            patchModel.isBillingType = value as boolean;
            break;
        case AddressFields.UseForMailing:
            patchModel.isMailingType = value as boolean;
            break;
        case AddressFields.UseForShipping:
            patchModel.isShippingType = value as boolean;
            break;
        case AddressFields.BillingDefault:
            patchModel.isBillingDefault = value as boolean;
            break;
        case AddressFields.MailingDefault:
            patchModel.isMailingDefault = value as boolean;
            break;
        case AddressFields.ShippingDefault:
            patchModel.isShippingDefault = value as boolean;
            break;
    }

    return patchModel;
};

const buildContactPatch = (field: ContactFieldsType, value: any) => {
    const patchModel = {field: field} as ContactPatch;

    switch (field) {
        case ContactFields.Phone:
            patchModel.phone = value as string;
            break;
        case ContactFields.Mobile:
            patchModel.mobile = value as string;
            break;
        case ContactFields.Email:
            patchModel.email = value as string;
            break;
        case ContactFields.Fax:
            patchModel.fax = value as string;
            break;
        case ContactFields.Web:
            patchModel.web = value as string;
            break;
        case ContactFields.UseForGeneral:
            patchModel.useForGeneral = value as boolean;
            break;
        case ContactFields.UseForMailing:
            patchModel.useForMailing = value as boolean;
            break;
        case ContactFields.UseForBilling:
            patchModel.useForBilling = value as boolean;
            break;
        case ContactFields.UseForShipping:
            patchModel.useForShipping = value as boolean;
            break;
    }

    return patchModel;
};

const convertCompanyPersonFields = (field: CompanyPersonFieldsType) => {
    switch (field) {
        case CompanyPersonFields.Salutation:
            return PersonFields.Salutation;
        case CompanyPersonFields.FirstName:
            return PersonFields.FirstName;
        case CompanyPersonFields.LastName:
            return PersonFields.LastName;
        case CompanyPersonFields.PersonRole:
            return PersonFields.PersonRole;
        case CompanyPersonFields.IsGeneralType:
            return PersonFields.IsGeneralType;
        case CompanyPersonFields.IsMailingType:
            return PersonFields.IsMailingType;
        case CompanyPersonFields.IsBillingType:
            return PersonFields.IsBillingType;
        case CompanyPersonFields.IsShippingType:
            return PersonFields.IsShippingType;
        case CompanyPersonFields.Address:
            return PersonFields.AddressId;
        case CompanyPersonFields.Email:
            return ContactFields.Email;
        case CompanyPersonFields.Phone:
            return ContactFields.Phone;
        case CompanyPersonFields.MobilePhone:
            return ContactFields.Mobile;
        case CompanyPersonFields.Fax:
            return ContactFields.Fax;
        default:
            throw new Error("Invalid field");
    }
};

const buildPersonPatch = (field: CompanyPersonFieldsType, value: any) => {
    const patchModel = {field: convertCompanyPersonFields(field)} as PersonPatch;

    switch (field) {
        case CompanyPersonFields.Salutation:
            patchModel.salutation = value as string;
            break;
        case CompanyPersonFields.FirstName:
            patchModel.firstName = value as string;
            break;
        case CompanyPersonFields.LastName:
            patchModel.lastName = value as string;
            break;
        case CompanyPersonFields.PersonRole:
            patchModel.personRole = value as string;
            break;
        case CompanyPersonFields.IsGeneralType:
            patchModel.isGeneralType = value as boolean;
            break;
        case CompanyPersonFields.IsMailingType:
            patchModel.isMailingType = value as boolean;
            break;
        case CompanyPersonFields.IsBillingType:
            patchModel.isBillingType = value as boolean;
            break;
        case CompanyPersonFields.IsShippingType:
            patchModel.isShippingType = value as boolean;
            break;
        case CompanyPersonFields.Address:
            patchModel.addressId = value as number | undefined;
            break;
    }

    return patchModel;
};

const buildPersonContactPatch = (field: CompanyPersonFieldsType, value: any) => {
    const patchModel = {field: convertCompanyPersonFields(field)} as ContactPatch;

    switch (field) {
        case CompanyPersonFields.Phone:
            patchModel.phone = value as string;
            break;
        case CompanyPersonFields.MobilePhone:
            patchModel.mobile = value as string;
            break;
        case CompanyPersonFields.Email:
            patchModel.email = value as string;
            break;
        case CompanyPersonFields.Fax:
            patchModel.fax = value as string;
            break;
    }

    return patchModel;
};

let abortController : AbortController;
let addressesAbortController: AbortController;
let contactsAbortController: AbortController;
let appointmentsAbortController: AbortController;
let opportunitiesAbortController: AbortController;
let personsAbortController: AbortController;
let quotationsAbortController: AbortController;
let tasksAbortController: AbortController;

type Dispatchables = KnownAction | AppThunkAction<any>;

export const outsideActions = {
    openCompanyModal: (companyId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.isLoading) {
            abortController = new AbortController();
            apiClientInstance.fetchRequest<CompanyDetailsData>(`v1/parties/${companyId}`, 'GET', undefined, undefined, abortController)
                .then((data) => {
                    data.createdOn = !!data.createdOn ? new Date(data.createdOn) : null;
                    dispatch({ type: 'COMPANY_RECEIVE_DATA', data: data });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_REQUEST_DATA', companyId: companyId });
        }
    },
};
export const insideActions = {
    closeWindow: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {
            !!abortController && abortController.abort();
            !!addressesAbortController && addressesAbortController.abort();
            !!appointmentsAbortController && appointmentsAbortController.abort();
            !!contactsAbortController && contactsAbortController.abort();
            !!opportunitiesAbortController && opportunitiesAbortController.abort();
            !!personsAbortController && personsAbortController.abort();
            !!quotationsAbortController && quotationsAbortController.abort();
            !!tasksAbortController && tasksAbortController.abort();
            dispatch({ type: 'COMPANY_CLEAR_DATA' });
        }
    },
    updateField: (serverField: CompanyFieldsType, value: any, selectedItem?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {
            const patchModel = buildPatch(serverField, value);

            dispatch({ type: 'COMPANY_UPDATE_DATA_REQUEST', field: serverField });

            apiClientInstance.fetchRequest(
                `v1/parties/${appState.companyModal.data.partyId}`,
                'PATCH',
                patchModel
            )
                .then((data) => {
                    let infoObj = {...(appState.companyModal?.data ?? {} as CompanyDetailsData)};

                    switch (serverField) {
                        case CompanyFields.Code:
                            infoObj.code = value as string;
                            break;
                        case CompanyFields.LegalName:
                            infoObj.legalName = value as string;
                            break;
                        case CompanyFields.PartyType:
                            infoObj.partyType = value as string;
                            break;
                        case CompanyFields.PartyCategory:
                            infoObj.partyCategory = value as string;
                            break;
                        case CompanyFields.Source:
                            infoObj.source = value as string;
                            break;
                        case CompanyFields.Terms:
                            infoObj.terms = value as string;
                            break;
                        case CompanyFields.Currency:
                            infoObj.currency = value as string;
                            break;
                        case CompanyFields.WebViewInventory:
                            infoObj.webViewInventory = value as string;
                            break;
                        case CompanyFields.DefaultTaxType:
                            infoObj.defaultTaxType = value as string;
                            break;
                        case CompanyFields.VatNumber:
                            infoObj.vatNumber = value as string;
                            break;
                        case CompanyFields.EinNumber:
                            infoObj.einNumber = value as string;
                            break;
                        case CompanyFields.LengthUnits:
                            infoObj.lengthUnits = value as string;
                            break;
                        case CompanyFields.WeightUnits:
                            infoObj.weightUnits = value as string;
                            break;
                        case CompanyFields.VolumeUnits:
                            infoObj.volumeUnits = value as string;
                            break;
                        case CompanyFields.Deactivated:
                            infoObj.deactivated = value as boolean;
                            break;
                        case CompanyFields.InsuranceRequired:
                            infoObj.insuranceRequired = value as boolean;
                            break;
                        case CompanyFields.TaxExempt:
                            infoObj.taxExempt = value as boolean;
                            break;
                        case CompanyFields.BadPayer:
                            infoObj.badPayer = value as boolean;
                            break;
                        case CompanyFields.Warning:
                            infoObj.warning = value as string;
                            break;
                        case CompanyFields.BillingInstructions:
                            infoObj.billingInstructions = value as string;
                            break;
                        case CompanyFields.LMCode:
                            infoObj.lmCode = value as string;
                            break;
                        case CompanyFields.LegalEntity:
                            infoObj.legalEntityId = value as number;
                            infoObj.legalEntityName = (selectedItem as PartyListItem)?.fullName ?? '';
                            break;
                    }

                    dispatch({ type: 'COMPANY_UPDATE_DATA', data: infoObj });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_UPDATE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });
        }
    },
    generateCode: () : AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {

            apiClientInstance.fetchRequest<string>(
                `v1/parties/${appState.companyModal.data.partyId}/generate-code`,
                'POST'
            )
                .then((data) => {
                    dispatch({ type: 'COMPANY_GENERATE_CODE_SUCCESS', newCode: data });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_GENERATE_CODE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_GENERATE_CODE_REQUEST' });
        }
    },
    clearAddressData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {
            !!addressesAbortController && addressesAbortController.abort();
            !!contactsAbortController && contactsAbortController.abort();

            dispatch({ type: 'COMPANY_CLEAR_ADDRESS' });
        }
    },
    clearPersonData: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {
            !!personsAbortController && personsAbortController.abort();

            dispatch({ type: 'COMPANY_CLEAR_PERSON' });
        }
    },
};

export const addressesActions = {
    requestAddresses: (companyId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.addresses.isLoadingAddresses) {
            addressesAbortController = new AbortController();
            FilterHandler.getApiFilterRequestPromise<DataRequest<PartyAddressItem>>(`v1/parties/${companyId}/addresses`, 'GET', {includeDeactivated: {value: true}}, undefined, undefined, undefined, addressesAbortController)
                .then((data) => {
                    dispatch({ type: 'COMPANY_ADDRESSES_RECEIVE_DATA', data: data.data });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_ADDRESSES_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_ADDRESSES_REQUEST_DATA', });
        }
    },
    startCreateAddress: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {
            dispatch({ type: 'COMPANY_ADDRESSES_CREATE_START', });
        }
    },
    submitCreateAddress: (companyId: number, address: AddressData, contact: ContactData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && appState.companyModal.addresses.addressCreateStatus !== ServerOperationStatus.INPROGRESS) {
            apiClientInstance.fetchRequest<number>(
                `/v1/parties/${companyId}/addresses`,
                'POST',
                {
                    ...address,
                    contact: {...contact},
                }
            )
                .then((result) => {
                    dispatch({ type: 'COMPANY_ADDRESSES_CREATE_SUCCESS', newId: result, model: address });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_ADDRESSES_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_ADDRESSES_CREATE_SUBMIT', });
        }
    },
    cancelCreateAddress: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {
            dispatch({ type: 'COMPANY_ADDRESSES_CREATE_CANCEL', });
        }
    },
    deleteAddress: (addressId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.addresses.isLoadingAddresses) {
            FilterHandler.getApiFilterRequestPromise(`v1/addresses/${addressId}`, 'DELETE', {}, undefined, undefined, undefined)
                .then((data) => {
                    dispatch({ type: 'COMPANY_ADDRESSES_DELETE_SUCCESS', deletedId: addressId });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_ADDRESSES_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_ADDRESSES_DELETE_SUBMIT', });
        }
    },
};
export const addressDetailsActions = {
    requestDetails: (addressId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.addresses.isLoadingAddresses && !appState.companyModal.addresses.isLoadingContacts) {
            apiClientInstance.fetchRequest<AddressData>(`v1/addresses/${addressId}`, 'GET', undefined, undefined)
                .then((data) => {
                    dispatch({ type: 'COMPANY_ADDRESSES_RECEIVE_DETAILS', data: data });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_ADDRESSES_REQUEST_DETAILS' });
        }
    },
    updateField: (addressId: number, serverField: AddressFieldsType, value: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {
            const patchModel = buildAddressPatch(serverField, value);

            apiClientInstance.fetchRequest(
                `v1/addresses/${addressId}`,
                'PATCH',
                patchModel
            )
                .then(() => {
                    let infoObj = {...(appState.companyModal?.addresses.addressDetails.data ?? {} as AddressData)};

                    switch (serverField) {
                        case AddressFields.Street1:
                            infoObj.street1 = value as string;
                            break;
                        case AddressFields.Street2:
                            infoObj.street2 = value as string;
                            break;
                        case AddressFields.Street3:
                            infoObj.street3 = value as string;
                            break;
                        case AddressFields.City:
                            infoObj.city = value as string;
                            break;
                        case AddressFields.ZIP:
                            infoObj.zip = value as string;
                            break;
                        case AddressFields.Country:
                            infoObj.country = value as string;
                            break;
                        case AddressFields.State:
                            infoObj.state = value as string;
                            break;
                        case AddressFields.AddressType:
                            infoObj.addressType = value as string;
                            break;
                        case AddressFields.Disabled:
                            infoObj.disabled = value as boolean;
                            break;
                        case AddressFields.Comments:
                            infoObj.comments = value as string;
                            break;
                        case AddressFields.UseForMailing:
                            infoObj.isMailingType = value as boolean;
                            break;
                        case AddressFields.UseForShipping:
                            infoObj.isShippingType = value as boolean;
                            break;
                        case AddressFields.UseForBilling:
                            infoObj.isBillingType = value as boolean;
                            break;
                        case AddressFields.MailingDefault:
                            infoObj.isMailingDefault = value as boolean;
                            break;
                        case AddressFields.ShippingDefault:
                            infoObj.isShippingDefault = value as boolean;
                            break;
                        case AddressFields.BillingDefault:
                            infoObj.isBillingDefault = value as boolean;
                            break;
                    }

                    dispatch({ type: 'COMPANY_ADDRESSES_UPDATE_DETAILS', data: infoObj });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_ADDRESSES_UPDATE_DETAILS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

                dispatch({ type: 'COMPANY_ADDRESSES_UPDATE_DETAILS_REQUEST', field: serverField });
        }
    },
};

export const appointmentsActions = {
    requestAppointments: (companyId: number, filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.appointments.isLoading) {
            appointmentsAbortController = new AbortController();
            FilterHandler.getApiFilterRequestPromise<DataRequest<AppointmentCompanyGridItem>>(`v1/parties/${companyId}/appointments`, 'POST', filters, skip, pageSize, sorting, appointmentsAbortController)
                .then((data) => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.CompanyDetailsAppointments}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.CompanyDetailsAppointments}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach(x => x.startDate = new Date(x.startDate));
                    dispatch({ type: 'COMPANY_APPOINTMENTS_RECEIVE_DATA', data: data.data, count: data.count });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_APPOINTMENTS_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_APPOINTMENTS_REQUEST_DATA', skip: skip });
        }
    },
};

export const contactsActions = {
    requestContacts: (addressId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {
            !!contactsAbortController && contactsAbortController.abort();
            contactsAbortController = new AbortController();
            FilterHandler.getApiFilterRequestPromise<DataRequest<PartyContactItem>>(`v1/addresses/${addressId}/contacts`, 'GET', {}, undefined, undefined, undefined, contactsAbortController)
                .then((data) => {
                    dispatch({ type: 'COMPANY_CONTACTS_RECEIVE_DATA', data: data.data });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_CONTACTS_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_CONTACTS_REQUEST_DATA', });
        }
    },
    startCreateContact: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {
            dispatch({ type: 'COMPANY_CONTACTS_CREATE_START', });
        }
    },
    submitCreateContact: (addressId: number, model: ContactData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && appState.companyModal.addresses.contactCreateStatus !== ServerOperationStatus.INPROGRESS) {
            apiClientInstance.fetchRequest<number>(
                `/v1/addresses/${addressId}/contacts`,
                'POST',
                {
                    ...model,
                }
            )
                .then((result) => {
                    dispatch({ type: 'COMPANY_CONTACTS_CREATE_SUCCESS', newId: result, model: model });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_CONTACTS_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });
            dispatch({ type: 'COMPANY_CONTACTS_CREATE_SUBMIT', });
        }
    },
    cancelCreateContact: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {
            dispatch({ type: 'COMPANY_CONTACTS_CREATE_CANCEL', });
        }
    },
    deleteContact: (contactId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.addresses.isLoadingAddresses && !appState.companyModal.addresses.isLoadingContacts) {
            FilterHandler.getApiFilterRequestPromise(`v1/contacts/${contactId}`, 'DELETE', {}, undefined, undefined, undefined)
                .then((data) => {
                    dispatch({ type: 'COMPANY_CONTACTS_DELETE_SUCCESS', deletedId: contactId });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_CONTACTS_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_CONTACTS_DELETE_SUBMIT', });
        }
    },
};
export const contactDetailsActions = {
    requestDetails: (contactId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.addresses.isLoadingAddresses && !appState.companyModal.addresses.isLoadingContacts && !appState.companyModal.addresses.isLoadingContacts) {
            apiClientInstance.fetchRequest<ContactData>(`v1/contacts/${contactId}`, 'GET', undefined, undefined)
                .then((data) => {
                    dispatch({ type: 'COMPANY_CONTACTS_RECEIVE_DETAILS', data: data });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_CONTACTS_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_CONTACTS_REQUEST_DETAILS' });
        }
    },
    updateField: (contactId: number, serverField: ContactFieldsType, value: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {
            const patchModel = buildContactPatch(serverField, value);

            apiClientInstance.fetchRequest(
                `v1/contacts/${contactId}`,
                'PATCH',
                patchModel
            )
                .then(() => {
                    let infoObj = {...(appState.companyModal?.addresses.contactDetails.data ?? {})} as ContactData;

                    switch (serverField) {
                        case ContactFields.Phone:
                            infoObj.phone = value as string;
                            break;
                        case ContactFields.Mobile:
                            infoObj.mobile = value as string;
                            break;
                        case ContactFields.Email:
                            infoObj.email = value as string;
                            break;
                        case ContactFields.Fax:
                            infoObj.fax = value as string;
                            break;
                        case ContactFields.Web:
                            infoObj.web = value as string;
                            break;
                        case ContactFields.UseForGeneral:
                            infoObj.useForGeneral = value as boolean;
                            break;
                        case ContactFields.UseForMailing:
                            infoObj.useForMailing = value as boolean;
                            break;
                        case ContactFields.UseForBilling:
                            infoObj.useForBilling = value as boolean;
                            break;
                        case ContactFields.UseForShipping:
                            infoObj.useForShipping = value as boolean;
                            break;
                    }

                    dispatch({ type: 'COMPANY_CONTACTS_UPDATE_DETAILS', data: infoObj });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_CONTACTS_UPDATE_DETAILS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

                dispatch({ type: 'COMPANY_CONTACTS_UPDATE_DETAILS_REQUEST', field: serverField });
        }
    },
};

export const opportunitiesActions = {
    requestOpportunities: (companyId: number, filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.opportunities.isLoading) {
            opportunitiesAbortController = new AbortController();
            FilterHandler.getApiFilterRequestPromise<DataRequest<OpportunityCompanyGridItem>>(`v1/parties/${companyId}/opportunities`, 'POST', filters, skip, pageSize, sorting, opportunitiesAbortController)
                .then((data) => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.CompanyDetailsOpportunities}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.CompanyDetailsOpportunities}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach(x => x.expireOn = new Date(x.expireOn));
                    dispatch({ type: 'COMPANY_OPPORTUNITIES_RECEIVE_DATA', data: data.data, count: data.count });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_OPPORTUNITIES_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_OPPORTUNITIES_REQUEST_DATA', skip: skip });
        }
    },
};

export const personsActions = {
    requestPersons: (companyId: number, filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.persons.isLoading) {
            personsAbortController = new AbortController();
            FilterHandler.getApiFilterRequestPromise<DataRequest<PersonCompanyGridItem>>(`v1/parties/${companyId}/company-persons`, 'GET', filters, skip, pageSize, sorting, personsAbortController)
                .then((data) => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.CompanyDetailsPersons}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.CompanyDetailsPersons}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    dispatch({ type: 'COMPANY_PERSONS_RECEIVE_DATA', data: data.data, count: data.count });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_PERSONS_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_PERSONS_REQUEST_DATA', skip: skip });
        }
    },
    startCreatePerson: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {
            dispatch({ type: 'COMPANY_PERSONS_CREATE_START', });
        }
    },
    submitCreatePerson: (companyId: number, model: PersonData): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && appState.companyModal.persons.personCreateStatus !== ServerOperationStatus.INPROGRESS) {
            apiClientInstance.fetchRequest<number>(
                `/v1/parties/${companyId}/company-persons`,
                'POST',
                {
                    ...{
                        ...model,
                        ...model.address,
                        ...model.contactInfo,
                    },
                }
            )
                .then((result) => {
                    dispatch({ type: 'COMPANY_PERSONS_CREATE_SUCCESS', newId: result, model: model });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_PERSONS_CREATE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });
            dispatch({ type: 'COMPANY_PERSONS_CREATE_SUBMIT', });
        }
    },
    cancelCreatePerson: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal) {
            dispatch({ type: 'COMPANY_PERSONS_CREATE_CANCEL', });
        }
    },
    deletePerson: (personId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.persons.isLoading) {
            FilterHandler.getApiFilterRequestPromise(`v1/persons/${personId}`, 'DELETE', {}, undefined, undefined, undefined)
                .then((data) => {
                    dispatch({ type: 'COMPANY_PERSONS_DELETE_SUCCESS', deletedId: personId });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_PERSONS_DELETE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_PERSONS_DELETE_SUBMIT', });
        }
    },
    initializePerson: (companyId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.persons.isLoading) {
            FilterHandler.getApiFilterRequestPromise(`v1/parties/${companyId}/company-persons/initialize`, 'POST', {}, undefined, undefined, undefined)
                .then(() => {
                    dispatch({ type: 'COMPANY_PERSONS_INITIALIZE_SUCCESS', });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_PERSONS_INITIALIZE_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_PERSONS_INITIALIZE_SUBMIT', });
        }
    },
};
export const personDetailsActions = {
    requestDetails: (personId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.persons.isLoading) {
            apiClientInstance.fetchRequest<PersonData>(`v1/persons/${personId}`, 'GET', undefined, undefined)
                .then((data) => {
                    dispatch({ type: 'COMPANY_PERSONS_RECEIVE_DETAILS', data: data });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_PERSONS_RECEIVE_DETAILS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_PERSONS_REQUEST_DETAILS' });
        }
    },
    updateField: (serverField: CompanyPersonFieldsType, value: any, item?: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        let patchModel : GenericPatch;
        let url : string;

        if (appState && appState.companyModal) {
            switch (serverField) {
                case CompanyPersonFields.Salutation:
                case CompanyPersonFields.FirstName:
                case CompanyPersonFields.LastName:
                case CompanyPersonFields.PersonRole:
                case CompanyPersonFields.IsGeneralType:
                case CompanyPersonFields.IsMailingType:
                case CompanyPersonFields.IsBillingType:
                case CompanyPersonFields.IsShippingType:
                case CompanyPersonFields.Address:
                    {
                        patchModel = buildPersonPatch(serverField, value);
                        url = `/v1/persons/${appState.companyModal.persons.personDetails.data.personId}`;
                        break;
                    }
                case CompanyPersonFields.Email:
                case CompanyPersonFields.Phone:
                case CompanyPersonFields.MobilePhone:
                case CompanyPersonFields.Fax:
                    {
                        patchModel = buildPersonContactPatch(serverField, value);
                        url = `/v1/contacts/${appState.companyModal.persons.personDetails.data.contactInfo.contactInfoId}`;
                        break;
                    }
                default:
                    throw new Error("invalid field");
            }

            apiClientInstance.fetchRequest(
                url,
                'PATCH',
                patchModel
            )
                .then(() => {
                    let infoObj = {
                        ...(appState.companyModal?.persons.personDetails.data ?? {} as PersonData),
                        contactInfo: {
                            ...(appState.companyModal?.persons.personDetails.data.contactInfo ?? {} as PersonContactData)
                        }
                    };

                    switch (serverField) {
                        case CompanyPersonFields.Salutation:
                            infoObj.title = value as string;
                            break;
                        case CompanyPersonFields.FirstName:
                            infoObj.firstName = value as string;
                            break;
                        case CompanyPersonFields.LastName:
                            infoObj.lastName = value as string;
                            break;
                        case CompanyPersonFields.PersonRole:
                            infoObj.personRole = value as string;
                            break;
                        case CompanyPersonFields.IsGeneralType:
                            infoObj.isGeneralType = value as boolean;
                            break;
                        case CompanyPersonFields.IsMailingType:
                            infoObj.isMailingType = value as boolean;
                            break;
                        case CompanyPersonFields.IsBillingType:
                            infoObj.isBillingType = value as boolean;
                            break;
                        case CompanyPersonFields.IsShippingType:
                            infoObj.isShippingType = value as boolean;
                            break;
                        case CompanyPersonFields.Address:
                            infoObj.address = !!item ? (item as PersonAddressData) : null;
                            break;
                        case CompanyPersonFields.Email:
                            infoObj.contactInfo.email = value as string;
                            break;
                        case CompanyPersonFields.Phone:
                            infoObj.contactInfo.phone = value as string;
                            break;
                        case CompanyPersonFields.MobilePhone:
                            infoObj.contactInfo.mobilePhone = value as string;
                            break;
                        case CompanyPersonFields.Fax:
                            infoObj.contactInfo.fax = value as string;
                            break;
                    }

                    dispatch({ type: 'COMPANY_PERSONS_UPDATE_DETAILS', data: infoObj });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_PERSONS_UPDATE_DETAILS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_PERSONS_UPDATE_DETAILS_REQUEST', field: serverField });
        }
    },
    initializeContact: (personId: number, contact: PartyContactItem): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();

        if (appState && appState.companyModal) {

            apiClientInstance.fetchRequest(
                `/v1/persons/${personId}/initialize-contact`,
                'POST',
                {
                    contactId: contact.contactInfoId
                }
            )
                .then(() => {
                    let infoObj = {
                        ...(appState.companyModal?.persons.personDetails.data ?? {} as PersonData),
                        contactInfo: {
                            ...(appState.companyModal?.persons.personDetails.data.contactInfo ?? {} as PersonContactData)
                        }
                    };

                    infoObj.contactInfo.email = contact.email;
                    infoObj.contactInfo.phone = contact.phone;
                    infoObj.contactInfo.mobilePhone = contact.mobile;
                    infoObj.contactInfo.fax = contact.fax;

                    dispatch({ type: 'COMPANY_PERSONS_UPDATE_DETAILS', data: infoObj });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_PERSONS_UPDATE_DETAILS_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_PERSONS_UPDATE_DETAILS_REQUEST', field: CompanyPersonFields.Contact });
        }
    },
};

export const quotationsActions = {
    requestQuotations: (companyId: number, filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.quotations.isLoading) {
            quotationsAbortController = new AbortController();
            FilterHandler.getApiFilterRequestPromise<DataRequest<QuotationCompanyGridItem>>(`v1/parties/${companyId}/quotations`, 'POST', filters, skip, pageSize, sorting, quotationsAbortController)
                .then((data) => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.CompanyDetailsQuotations}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.CompanyDetailsQuotations}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach(x => x.createdOn = new Date(x.createdOn));
                    dispatch({ type: 'COMPANY_QUOTATIONS_RECEIVE_DATA', data: data.data, count: data.count });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_QUOTATIONS_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_QUOTATIONS_REQUEST_DATA', skip: skip });
        }
    },
};

export const tasksActions = {
    requestTasks: (companyId: number, filters: FilterList, skip: number, pageSize: number, sorting: Array<SortDescriptor>): AppThunkAction<Dispatchables> => (dispatch, getState) => {
        const appState = getState();
        if (appState && appState.companyModal && !appState.companyModal.tasks.isLoading) {
            tasksAbortController = new AbortController();
            FilterHandler.getApiFilterRequestPromise<DataRequest<TaskCompanyGridItem>>(`v1/parties/${companyId}/tasks`, 'POST', filters, skip, pageSize, sorting, tasksAbortController)
                .then((data) => {
                    dispatch(UserActions.updateUserPreferences({
                        [`${UserPreferencePage.CompanyDetailsTasks}-${UserPreferenceKey.Sorting}`]: sorting,
                        [`${UserPreferencePage.CompanyDetailsTasks}-${UserPreferenceKey.PageSize}`]: pageSize,
                    }));

                    data.data.forEach(x => x.startDate = new Date(x.startDate));
                    data.data.forEach(x => x.dueDate = new Date(x.dueDate));
                    dispatch({ type: 'COMPANY_TASKS_RECEIVE_DATA', data: data.data, count: data.count });
                })
                .catch((err) => {
                    dispatch({ type: 'COMPANY_TASKS_RECEIVE_DATA_ERROR' });
                    dispatch({ type: 'ERROR_MESSAGE_SHOW', message: err.message });
                });

            dispatch({ type: 'COMPANY_TASKS_REQUEST_DATA', skip: skip });
        }
    },
};

// -----------------
// ---- REDUCER ----
// -----------------

const unloadedState: CompanyModalState = {
    isLoading: false,
    companyId: 0,
    data: EmptyData,
    updateStatus: ServerOperationStatus.NONE,
    generateCodeStatus: ServerOperationStatus.NONE,

    addresses: {
        addresses: [],
        contacts: [],
        isLoadingAddresses: false,
        isLoadingContacts: false,
        addressCreateStatus: ServerOperationStatus.NONE,
        addressUpdateStatus: ServerOperationStatus.NONE,
        addressDeleteStatus: ServerOperationStatus.NONE,
        contactCreateStatus: ServerOperationStatus.NONE,
        contactUpdateStatus: ServerOperationStatus.NONE,
        contactDeleteStatus: ServerOperationStatus.NONE,

        addressDetails: {
            isLoading: false,
            data: EmptyAddressData,
        },
        contactDetails: {
            isLoading: false,
            data: EmptyContactData,
        },
    },
    appointments: {
        appointments: [],
        count: 0,
        skip: 0,
        isLoading: false,
    },
    opportunities: {
        opportunities: [],
        count: 0,
        skip: 0,
        isLoading: false,
    },
    persons: {
        persons: [],
        count: 0,
        skip: 0,
        isLoading: false,
        personCreateStatus: ServerOperationStatus.NONE,
        personUpdateStatus: ServerOperationStatus.NONE,
        personDeleteStatus: ServerOperationStatus.NONE,
        personInitializeStatus: ServerOperationStatus.NONE,

        personDetails: {
            isLoading: false,
            data: EmptyPersonData,
        },
    },
    quotations: {
        quotations: [],
        count: 0,
        skip: 0,
        isLoading: false,
    },
    tasks: {
        tasks: [],
        count: 0,
        skip: 0,
        isLoading: false,
    },
};

export const reducer: Reducer<CompanyModalState> = (state: CompanyModalState | undefined, incomingAction: Action): CompanyModalState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'COMPANY_REQUEST_DATA':
            return {
                ...state,
                isLoading: true,
                companyId: action.companyId,
                data: EmptyData,

                appointments: unloadedState.appointments,
                tasks: unloadedState.tasks,
            };
        case 'COMPANY_RECEIVE_DATA':
            return {
                ...state,
                data: action.data,
                isLoading: false,
                updateStatus: ServerOperationStatus.NONE,
                generateCodeStatus: ServerOperationStatus.NONE,
            };
        case 'COMPANY_RECEIVE_DATA_ERROR':
            return {
                ...state,
                isLoading: false,
            };

        case 'COMPANY_CLEAR_DATA':
            return {
                ...state,
                companyId: 0,
                data: EmptyData,
            };
        case 'COMPANY_CLEAR_ADDRESS':
            return {
                ...state,
                addresses: unloadedState.addresses,
            };
        case 'COMPANY_CLEAR_PERSON':
            return {
                ...state,
                persons: unloadedState.persons,
            };

        case 'COMPANY_UPDATE_DATA':
            return {
                ...state,
                data: action.data,
                updateStatus: ServerOperationStatus.SUCCESS,
                updatingField: undefined,
            };
        case 'COMPANY_UPDATE_DATA_REQUEST':
            return {
                ...state,
                updateStatus: ServerOperationStatus.INPROGRESS,
                updatingField: action.field,
            };
        case 'COMPANY_UPDATE_DATA_ERROR':
            return {
                ...state,
                updateStatus: ServerOperationStatus.ERROR,
                updatingField: undefined,
            };

        case 'COMPANY_GENERATE_CODE_SUCCESS':
            return {
                ...state,
                generateCodeStatus: ServerOperationStatus.SUCCESS,
                data: {
                    ...state.data,
                    code: action.newCode,
                },
            };
        case 'COMPANY_GENERATE_CODE_REQUEST':
            return {
                ...state,
                generateCodeStatus: ServerOperationStatus.INPROGRESS,
            };
        case 'COMPANY_GENERATE_CODE_ERROR':
            return {
                ...state,
                generateCodeStatus: ServerOperationStatus.ERROR,
            };

        case 'COMPANY_ADDRESSES_REQUEST_DATA':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contacts: [],
                    isLoadingAddresses: true,
                },
            };
        case 'COMPANY_ADDRESSES_RECEIVE_DATA':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    addresses: action.data,
                    isLoadingAddresses: false,
                },
            };
        case 'COMPANY_ADDRESSES_ERROR':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    isLoadingAddresses: false,
                },
            };

        case 'COMPANY_ADDRESSES_CREATE_START':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    addressCreateStatus: ServerOperationStatus.READY,
                },
            };
        case 'COMPANY_ADDRESSES_CREATE_SUBMIT':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    addressCreateStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'COMPANY_ADDRESSES_CREATE_CANCEL':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    addressCreateStatus: ServerOperationStatus.NONE,
                },
            };
        case 'COMPANY_ADDRESSES_CREATE_SUCCESS':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    addresses: [
                        ...state.addresses.addresses,
                        {
                            ...action.model,
                            addressId: action.newId,
                        }
                    ],
                    addressCreateStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'COMPANY_ADDRESSES_CREATE_ERROR':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    addressCreateStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'COMPANY_ADDRESSES_DELETE_SUBMIT':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    addressDeleteStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'COMPANY_ADDRESSES_DELETE_SUCCESS':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    addresses: state.addresses.addresses.filter(x => x.addressId !== action.deletedId),
                    addressDeleteStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'COMPANY_ADDRESSES_DELETE_ERROR':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    addressDeleteStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'COMPANY_ADDRESSES_REQUEST_DETAILS':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    addressDetails: {
                        ...state.addresses.addressDetails,
                        isLoading: true,
                    },
                },
            };
        case 'COMPANY_ADDRESSES_RECEIVE_DETAILS':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    addressDetails: {
                        ...state.addresses.addressDetails,
                        data: action.data,
                        isLoading: false,
                    },
                },
            };
        case 'COMPANY_ADDRESSES_RECEIVE_DETAILS_ERROR':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    addressDetails: {
                        ...state.addresses.addressDetails,
                        isLoading: false,
                    },
                },
            };

        case 'COMPANY_ADDRESSES_UPDATE_DETAILS':
            const currentAddress = state.addresses.addresses.find(x => x.addressId === action.data.addressId);
            if (!currentAddress) throw new Error("Edited address not found");

            const index = state.addresses.addresses.indexOf(currentAddress);
            const newAddresses = [...state.addresses.addresses];
            newAddresses[index] = {...action.data};

            return {
                ...state,
                updatingField: undefined,
                addresses: {
                    ...state.addresses,
                    addresses: newAddresses,
                    addressDetails: {
                        ...state.addresses.addressDetails,
                        data: action.data,
                    },
                    addressUpdateStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'COMPANY_ADDRESSES_UPDATE_DETAILS_REQUEST':
            return {
                ...state,
                updatingField: action.field,
                addresses: {
                    ...state.addresses,
                    addressUpdateStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'COMPANY_ADDRESSES_UPDATE_DETAILS_ERROR':
            return {
                ...state,
                updatingField: undefined,
                addresses: {
                    ...state.addresses,
                    addressUpdateStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'COMPANY_APPOINTMENTS_REQUEST_DATA':
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    skip: action.skip,
                    isLoading: true,
                },
            };
        case 'COMPANY_APPOINTMENTS_RECEIVE_DATA':
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    isLoading: false,
                    appointments: action.data,
                    count: action.count,
                },
            };
        case 'COMPANY_APPOINTMENTS_RECEIVE_DATA_ERROR':
            return {
                ...state,
                appointments: {
                    ...state.appointments,
                    isLoading: false,
                },
            };

        case 'COMPANY_CONTACTS_REQUEST_DATA':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contacts: [],
                    isLoadingContacts: true,
                    contactCreateStatus: ServerOperationStatus.NONE,
                    contactUpdateStatus: ServerOperationStatus.NONE,
                    contactDeleteStatus: ServerOperationStatus.NONE,
                },
            };
        case 'COMPANY_CONTACTS_RECEIVE_DATA':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contacts: action.data,
                    isLoadingContacts: false,
                },
            };
        case 'COMPANY_CONTACTS_RECEIVE_DATA_ERROR':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    isLoadingContacts: false,
                },
            };

        case 'COMPANY_CONTACTS_CREATE_START':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contactCreateStatus: ServerOperationStatus.READY,
                },
            };
        case 'COMPANY_CONTACTS_CREATE_SUBMIT':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contactCreateStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'COMPANY_CONTACTS_CREATE_CANCEL':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contactCreateStatus: ServerOperationStatus.NONE,
                },
            };
        case 'COMPANY_CONTACTS_CREATE_SUCCESS':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contacts: [
                        ...state.addresses.contacts,
                        {
                            ...action.model,
                            contactInfoId: action.newId,
                        }
                    ],
                    contactCreateStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'COMPANY_CONTACTS_CREATE_ERROR':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contactCreateStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'COMPANY_CONTACTS_DELETE_SUBMIT':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contactDeleteStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'COMPANY_CONTACTS_DELETE_SUCCESS':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contacts: state.addresses.contacts.filter(x => x.contactInfoId !== action.deletedId),
                    contactDeleteStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'COMPANY_CONTACTS_DELETE_ERROR':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contactDeleteStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'COMPANY_CONTACTS_REQUEST_DETAILS':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contactDetails: {
                        ...state.addresses.contactDetails,
                        isLoading: true,
                    },
                },
            };
        case 'COMPANY_CONTACTS_RECEIVE_DETAILS':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contactDetails: {
                        ...state.addresses.contactDetails,
                        data: action.data,
                        isLoading: false,
                    },
                },
            };
        case 'COMPANY_CONTACTS_RECEIVE_DETAILS_ERROR':
            return {
                ...state,
                addresses: {
                    ...state.addresses,
                    contactDetails: {
                        ...state.addresses.contactDetails,
                        isLoading: false
                    },
                },
            };
        case 'COMPANY_CONTACTS_UPDATE_DETAILS':
            {
                const currentContact = state.addresses.contacts.find(x => x.contactInfoId === action.data.contactInfoId);
                if (!currentContact) throw new Error("Edited contact not found");

                const index = state.addresses.contacts.indexOf(currentContact);
                const newContacts = [...state.addresses.contacts];
                newContacts[index] = {...action.data};

                return {
                    ...state,
                    updatingField: undefined,
                    addresses: {
                        ...state.addresses,
                        contacts: newContacts,
                        contactDetails: {
                            ...state.addresses.contactDetails,
                            data: action.data,
                        },
                        contactUpdateStatus: ServerOperationStatus.SUCCESS,
                    },
                };
            }
        case 'COMPANY_CONTACTS_UPDATE_DETAILS_REQUEST':
            return {
                ...state,
                updatingField: action.field,
                addresses: {
                    ...state.addresses,
                    contactUpdateStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'COMPANY_CONTACTS_UPDATE_DETAILS_ERROR':
            return {
                ...state,
                updatingField: undefined,
                addresses: {
                    ...state.addresses,
                    contactUpdateStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'COMPANY_OPPORTUNITIES_REQUEST_DATA':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    skip: action.skip,
                    isLoading: true,
                },
            };
        case 'COMPANY_OPPORTUNITIES_RECEIVE_DATA':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    isLoading: false,
                    opportunities: action.data,
                    count: action.count,
                },
            };
        case 'COMPANY_OPPORTUNITIES_RECEIVE_DATA_ERROR':
            return {
                ...state,
                opportunities: {
                    ...state.opportunities,
                    isLoading: false,
                },
            };

        case 'COMPANY_PERSONS_REQUEST_DATA':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    skip: action.skip,
                    isLoading: true,
                },
            };
        case 'COMPANY_PERSONS_RECEIVE_DATA':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    isLoading: false,
                    persons: action.data,
                    count: action.count,
                },
            };
        case 'COMPANY_PERSONS_RECEIVE_DATA_ERROR':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    isLoading: false,
                },
            };

        case 'COMPANY_PERSONS_CREATE_START':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    personCreateStatus: ServerOperationStatus.READY,
                },
            };
        case 'COMPANY_PERSONS_CREATE_SUBMIT':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    personCreateStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'COMPANY_PERSONS_CREATE_CANCEL':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    personCreateStatus: ServerOperationStatus.NONE,
                },
            };
        case 'COMPANY_PERSONS_CREATE_SUCCESS':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    personCreateStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'COMPANY_PERSONS_CREATE_ERROR':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    personCreateStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'COMPANY_PERSONS_DELETE_SUBMIT':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    personDeleteStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'COMPANY_PERSONS_DELETE_SUCCESS':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    persons: state.persons.persons.filter(x => x.personId !== action.deletedId),
                    personDeleteStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'COMPANY_PERSONS_DELETE_ERROR':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    personDeleteStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'COMPANY_PERSONS_INITIALIZE_SUBMIT':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    personInitializeStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'COMPANY_PERSONS_INITIALIZE_SUCCESS':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    personInitializeStatus: ServerOperationStatus.SUCCESS,
                },
            };
        case 'COMPANY_PERSONS_INITIALIZE_ERROR':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    personInitializeStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'COMPANY_PERSONS_REQUEST_DETAILS':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    personDetails: {
                        ...state.persons.personDetails,
                        isLoading: true,
                    },
                },
            };
        case 'COMPANY_PERSONS_RECEIVE_DETAILS':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    personDetails: {
                        ...state.persons.personDetails,
                        data: action.data,
                        isLoading: false,
                    },
                },
            };
        case 'COMPANY_PERSONS_RECEIVE_DETAILS_ERROR':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    personDetails: {
                        ...state.persons.personDetails,
                        isLoading: false,
                    },
                },
            };

        case 'COMPANY_PERSONS_UPDATE_DETAILS':
            {
                const currentPerson = state.persons.persons.find(x => x.personId === action.data.personId);
                if (!currentPerson) throw new Error("Edited person not found");

                const index = state.persons.persons.indexOf(currentPerson);
                const newPersons = [...state.persons.persons];
                newPersons[index] = {...action.data, fullName: `${action.data.firstName} ${action.data.lastName}`};

                return {
                    ...state,
                    updatingField: undefined,
                    persons: {
                        ...state.persons,
                        persons: newPersons,
                        personDetails: {
                            ...state.persons.personDetails,
                            data: action.data,
                        },
                        personUpdateStatus: ServerOperationStatus.SUCCESS,
                    },
                };
            }
        case 'COMPANY_PERSONS_UPDATE_DETAILS_REQUEST':
            return {
                ...state,
                updatingField: action.field,
                persons: {
                    ...state.persons,
                    personUpdateStatus: ServerOperationStatus.INPROGRESS,
                },
            };
        case 'COMPANY_PERSONS_UPDATE_DETAILS_ERROR':
            return {
                ...state,
                updatingField: undefined,
                persons: {
                    ...state.persons,
                    personUpdateStatus: ServerOperationStatus.ERROR,
                },
            };

        case 'COMPANY_QUOTATIONS_REQUEST_DATA':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    skip: action.skip,
                    isLoading: true,
                },
            };
        case 'COMPANY_QUOTATIONS_RECEIVE_DATA':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    isLoading: false,
                    quotations: action.data,
                    count: action.count,
                },
            };
        case 'COMPANY_QUOTATIONS_RECEIVE_DATA_ERROR':
            return {
                ...state,
                quotations: {
                    ...state.quotations,
                    isLoading: false,
                },
            };

        case 'COMPANY_TASKS_REQUEST_DATA':
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    skip: action.skip,
                    isLoading: true,
                },
            };
        case 'COMPANY_TASKS_RECEIVE_DATA':
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    isLoading: false,
                    tasks: action.data,
                    count: action.count,
                },
            };
        case 'COMPANY_TASKS_RECEIVE_DATA_ERROR':
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    isLoading: false,
                },
            };
    }

    return state;
};