import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { History } from 'history';
import { ApplicationState, reducers } from './ApplicationState';
import { createRouterMiddleware, createRouterReducer } from '@lagunovsky/redux-react-router';


export default function configureStore(history: History, initialState?: ApplicationState) {
    const routerMiddleware = createRouterMiddleware(history);
    const routerReducer = createRouterReducer(history);

    const middleware = process.env.NODE_ENV !== 'production' ?
    [
        require('redux-immutable-state-invariant').default(),
        thunk,
        routerMiddleware
    ] :
    [
        thunk,
        routerMiddleware
    ];

    const rootReducer = combineReducers({
        ...reducers,
        router: routerReducer
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
