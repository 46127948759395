const BillableServiceConfigFields = {
    DivisionId: "BillableServiceConfigField.DivisionId",
    JobType: "BillableServiceConfigField.JobType",
    TransportMode: "BillableServiceConfigField.TransportMode",
    Rate: "BillableServiceConfigField.Rate",
    Uom: "BillableServiceConfigField.Uom",
    VoxmeTransaction: "BillableServiceConfigField.VoxmeTransaction",
    Context: "BillableServiceConfigField.Context",
    CodeDescription: "BillableServiceConfigField.CodeDescription",
    TransactionDescription: "BillableServiceConfigField.TransactionDescription",
    TariffId: "BillableServiceConfigField.TariffId",
    Gl: "BillableServiceConfigField.Gl",
    TaxStatus: "BillableServiceConfigField.TaxStatus",
    TaxType: "BillableServiceConfigField.TaxType",
} as const;

export type BillableServiceConfigFieldsType = typeof BillableServiceConfigFields[keyof typeof BillableServiceConfigFields];

export default BillableServiceConfigFields;