const CRMCalendarGridFilters = {
    OwnedBy: "OwnedBy",
    Company: "Company",
    Description: "Description",
    DateFrom: "DateFrom",
    DateTo: "DateTo",
} as const;

export type CRMCalendarGridFiltersType = typeof CRMCalendarGridFilters[keyof typeof CRMCalendarGridFilters];

export default CRMCalendarGridFilters;