import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GridUtils from '~src/utils/gridUtils';
import equal from 'fast-deep-equal/react';
import { ApplicationState } from '~store/ApplicationState';
import * as InventoryStore from '~store/pages/mainViews/inventory';
import * as UsersStore from '~store/auth/user';
import * as NavigationStore from '~store/infra/navigation';
import * as ScreenStore from '~store/infra/screen';

import InventoryGridFields from '~enums/gridFields/inventoryItems';
import LocalizationKeys from '~enums/localizationKeys';
import ItemInventoryGridItem from '~models/item/itemInventory';
import { UserPreferenceKey, UserPreferencePage } from '~enums/userPreferenceKeys';

import { Grid } from '@mui/material';
import {
    GridColumn,
    GridToolbar,
    GridPageChangeEvent,
    GridCellProps,
    GridRowDoubleClickEvent,
    GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import { Checkbox } from '@progress/kendo-react-inputs';
import { provideLocalizationService, registerForLocalization, } from '@progress/kendo-react-intl';
import { Link } from 'react-router-dom';

import AMWGrid from '~components/datagrid/AMWGrid';
import CustomCell from '~components/datagrid/CustomCell';
import { GridFilterable, GridPageable, GridSortable } from '~components/datagrid/types';
import { GridContainer, GridContainerDispatchProps, GridContainerStateProps } from '~components/infra/GridContainer';
import InventoryItemsToolbar from './InventoryItemsToolbar';


type InventoryItemsStateProps = ScreenStore.ScreenState
    & InventoryStore.InventoryItemsState
    & GridFilterable
    & GridPageable
    & GridSortable
    & GridContainerStateProps;

type InventoryItemsDispatchProps = typeof InventoryStore.actionCreators
    & typeof NavigationStore.actionCreators
    & GridContainerDispatchProps;

type InventoryItemsTabProps = InventoryItemsStateProps
    & InventoryItemsDispatchProps;

type InventoryItemsTabState = {
    containerWidth: number,
};

const minColumnWidth = {
    [InventoryGridFields.UniqueId]: 125,
    [InventoryGridFields.Description]: 250,
    [InventoryGridFields.Depth]: 100,
    [InventoryGridFields.Width]: 100,
    [InventoryGridFields.Height]: 100,
    [InventoryGridFields.PackageDepth]: 100,
    [InventoryGridFields.PackageWidth]: 100,
    [InventoryGridFields.PackageHeight]: 100,
    [InventoryGridFields.SideMark]: 100,
    [InventoryGridFields.Lot]: 150,
    [InventoryGridFields.WarehousePosition]: 125,
    [InventoryGridFields.PONumber]: 125,
    [InventoryGridFields.DealerLegalName]: 160,
    [InventoryGridFields.Artist]: 100,
    [InventoryGridFields.Title]: 100,
    [InventoryGridFields.JobId]: 120,
    [InventoryGridFields.CustomsStatus]: 100,
    [InventoryGridFields.PackageUniqueId]: 120,
    [InventoryGridFields.Value]: 120,
    [InventoryGridFields.LocationUniqueId]: 120,
    [InventoryGridFields.CollectionDate]: 100,
    [InventoryGridFields.DeliveryDate]: 100,
    [InventoryGridFields.HasPhotos]: 80,
    [InventoryGridFields.Parent]: 80,
};

class InventoryItemsTab extends GridContainer<InventoryItemsTabProps, InventoryItemsTabState> {
    constructor(props: Readonly<InventoryItemsTabProps>) {
        super(props);
        this.state = {
            containerWidth: 1,
        };
    }

    componentDidMount() {
        this.props.requestItems(this.props.filters, this.props.skip, this.props.pageSize, this.props.sorting);

        window.addEventListener("resize", this.resize);
        window.addEventListener("orientationchange", this.resize);

        let element = document.querySelector('#ItemsGridContainer .k-grid-header');
        let tabs = document.querySelector('#InventoryTabs');
        !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );

        this.setState({
            containerWidth: document.getElementById('ItemsGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    componentDidUpdate(prevProps: InventoryItemsTabProps) {
        if (!equal(prevProps.topbarHeight, this.props.topbarHeight)) {
            let element = document.querySelector('#ItemsGridContainer .k-grid-header');
            let tabs = document.querySelector('#InventoryTabs');
            !!element && !!tabs && element.setAttribute('style', `top: ${this.props.topbarHeight + tabs.clientHeight}px` );
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize);
        window.removeEventListener("orientationchange", this.resize);
    }

    private resize() {
        this.setState({
            containerWidth: document.getElementById('ItemsGridContainer')?.getBoundingClientRect().width ?? 1,
        });
    }

    private onPageChange = (event: GridPageChangeEvent) => {
        this.props.requestItems(this.props.filters, event.page.skip, event.page.take, this.props.sorting);
    }

    private onSortChange = (event: GridSortChangeEvent) => {
        this.props.requestItems(this.props.filters, this.props.skip, this.props.pageSize, event.sort);
    }

    private onRowDoubleClick(event: GridRowDoubleClickEvent) {
        let item = event.dataItem as ItemInventoryGridItem;
        this.props.navigate(`/items/${item.itemId}`);
    }

    private calculateColumnWidth(field: string) : number | undefined {
        return GridUtils.calculateColumnWidth(
            field,
            this.props.columnWidth,
            this.state.containerWidth,
            minColumnWidth
        );
    }

    private get columns() {
        const localization = provideLocalizationService(this);

        let columns = [
            (
                <GridColumn
                    key={"inventory-number"}
                    field={InventoryGridFields.UniqueId}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.ItemNr, "%Item/Object #")}
                    cell={(
                        (props: GridCellProps) => {
                            const item = props.dataItem as ItemInventoryGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Link to={`/items/${item.itemId}`}>{item.uniqueId}</Link>
                                </CustomCell>
                            );
                        }
                    )}
                    className={'grid__cell--link'}
                    width={this.calculateColumnWidth(InventoryGridFields.UniqueId) ?? 125}
                    orderIndex={this.props.columnOrder[InventoryGridFields.UniqueId]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-description"}
                    field={InventoryGridFields.Description}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.Description, "%Description")}
                    width={this.calculateColumnWidth(InventoryGridFields.Description) ?? 250}
                    orderIndex={this.props.columnOrder[InventoryGridFields.Description]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-depth"}
                    field={InventoryGridFields.Depth}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.Depth, "%Depth")}
                    format={'{0:n2}'}
                    width={this.calculateColumnWidth(InventoryGridFields.Depth) ?? 100}
                    orderIndex={this.props.columnOrder[InventoryGridFields.Depth]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-width"}
                    field={InventoryGridFields.Width}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.Width, "%Width")}
                    format={'{0:n2}'}
                    width={this.calculateColumnWidth(InventoryGridFields.Width) ?? 100}
                    orderIndex={this.props.columnOrder[InventoryGridFields.Width]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-height"}
                    field={InventoryGridFields.Height}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.Height, "%Height")}
                    format={'{0:n2}'}
                    width={this.calculateColumnWidth(InventoryGridFields.Height) ?? 100}
                    orderIndex={this.props.columnOrder[InventoryGridFields.Height]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-extdepth"}
                    field={InventoryGridFields.PackageDepth}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.ExtDepth, "%Ext. Depth")}
                    format={'{0:n2}'}
                    width={this.calculateColumnWidth(InventoryGridFields.PackageDepth) ?? 100}
                    orderIndex={this.props.columnOrder[InventoryGridFields.PackageDepth]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-extwidth"}
                    field={InventoryGridFields.PackageWidth}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.ExtWidth, "%Ext. Width")}
                    format={'{0:n2}'}
                    width={this.calculateColumnWidth(InventoryGridFields.PackageWidth) ?? 100}
                    orderIndex={this.props.columnOrder[InventoryGridFields.PackageWidth]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-extheight"}
                    field={InventoryGridFields.PackageHeight}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.ExtHeight, "%Ext. Height")}
                    format={'{0:n2}'}
                    width={this.calculateColumnWidth(InventoryGridFields.PackageHeight) ?? 100}
                    orderIndex={this.props.columnOrder[InventoryGridFields.PackageHeight]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-sideMark"}
                    field={InventoryGridFields.SideMark}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.Sidemark, "%Side Mark/Sale")}
                    width={this.calculateColumnWidth(InventoryGridFields.SideMark) ?? 100}
                    orderIndex={this.props.columnOrder[InventoryGridFields.SideMark]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-lot"}
                    field={InventoryGridFields.Lot}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.Lot, "%Lot")}
                    width={this.calculateColumnWidth(InventoryGridFields.Lot) ?? 100}
                    orderIndex={this.props.columnOrder[InventoryGridFields.Lot]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-warehousePosition"}
                    field={InventoryGridFields.WarehousePosition}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.WarehousePosition, "%Warehouse position")}
                    width={this.calculateColumnWidth(InventoryGridFields.WarehousePosition) ?? 120}
                    orderIndex={this.props.columnOrder[InventoryGridFields.WarehousePosition]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-ponumber"}
                    field={InventoryGridFields.PONumber}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.PONumber, "%P/O Nr")}
                    width={this.calculateColumnWidth(InventoryGridFields.PONumber) ?? 120}
                    orderIndex={this.props.columnOrder[InventoryGridFields.PONumber]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-dealer"}
                    field={InventoryGridFields.DealerLegalName}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.Dealer, "%Dealer")}
                    width={this.calculateColumnWidth(InventoryGridFields.DealerLegalName) ?? 160}
                    orderIndex={this.props.columnOrder[InventoryGridFields.DealerLegalName]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-artist"}
                    field={InventoryGridFields.Artist}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.Artist, "%Artist")}
                    width={this.calculateColumnWidth(InventoryGridFields.Artist) ?? 100}
                    orderIndex={this.props.columnOrder[InventoryGridFields.Artist]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-title"}
                    field={InventoryGridFields.Title}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.Title, "%Title")}
                    width={this.calculateColumnWidth(InventoryGridFields.Title) ?? 100}
                    orderIndex={this.props.columnOrder[InventoryGridFields.Title]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-job"}
                    field={InventoryGridFields.JobId}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.Job, "%Job")}
                    cell={(
                        (props: GridCellProps) => {
                            const item = props.dataItem as ItemInventoryGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Link to={`/files/${item.clientFileId}`}>{item.jobId}</Link>
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(InventoryGridFields.JobId) ?? 120}
                    orderIndex={this.props.columnOrder[InventoryGridFields.JobId]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-customsstatus"}
                    field={InventoryGridFields.CustomsStatus}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.CustomsStatus, "%Customs status")}
                    cell={(
                        (props: GridCellProps) => {
                            const item = props.dataItem as ItemInventoryGridItem;
                            return (
                                <CustomCell {...props}>
                                    {localization.toLanguageString(item.customsStatus, item.customsStatus)}
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(InventoryGridFields.CustomsStatus) ?? 100}
                    orderIndex={this.props.columnOrder[InventoryGridFields.CustomsStatus]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-crate"}
                    field={InventoryGridFields.PackageUniqueId}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.Crate, "%Crate")}
                    width={this.calculateColumnWidth(InventoryGridFields.PackageUniqueId) ?? 120}
                    orderIndex={this.props.columnOrder[InventoryGridFields.PackageUniqueId]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-insurancevalue"}
                    field={InventoryGridFields.Value}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.InsuranceValue, "%Insurance value")}
                    format={'{0:n2}'}
                    width={this.calculateColumnWidth(InventoryGridFields.Value) ?? 120}
                    orderIndex={this.props.columnOrder[InventoryGridFields.Value]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-location"}
                    field={InventoryGridFields.LocationUniqueId}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.Location, "%Location")}
                    width={this.calculateColumnWidth(InventoryGridFields.LocationUniqueId) ?? 120}
                    orderIndex={this.props.columnOrder[InventoryGridFields.LocationUniqueId]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-datein"}
                    field={InventoryGridFields.CollectionDate}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.DateIn, "%Date in")}
                    format={'{0:d}'}
                    width={this.calculateColumnWidth(InventoryGridFields.CollectionDate) ?? 100}
                    orderIndex={this.props.columnOrder[InventoryGridFields.CollectionDate]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-dateout"}
                    field={InventoryGridFields.DeliveryDate}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.DateOut, "%Date out")}
                    format={'{0:d}'}
                    width={this.calculateColumnWidth(InventoryGridFields.DeliveryDate) ?? 100}
                    orderIndex={this.props.columnOrder[InventoryGridFields.DeliveryDate]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-hasphotos"}
                    field={InventoryGridFields.HasPhotos}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.HasPhotos, "%Has photos")}
                    cell={(
                        (props: GridCellProps) => {
                            const item = props.dataItem as ItemInventoryGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Checkbox checked={item.hasPhotos} />
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(InventoryGridFields.HasPhotos) ?? 80}
                    orderIndex={this.props.columnOrder[InventoryGridFields.HasPhotos]}
                />
            ),
            (
                <GridColumn
                    key={"inventory-parent"}
                    field={InventoryGridFields.Parent}
                    title={localization.toLanguageString(LocalizationKeys.Inventory.Items.Grid.Parent, "%Parent")}
                    cell={(
                        (props: GridCellProps) => {
                            const item = props.dataItem as ItemInventoryGridItem;
                            return (
                                <CustomCell {...props}>
                                    <Checkbox checked={item.parent} />
                                </CustomCell>
                            );
                        }
                    )}
                    width={this.calculateColumnWidth(InventoryGridFields.Parent) ?? 80}
                    orderIndex={this.props.columnOrder[InventoryGridFields.Parent]}
                />
            ),
        ];

        return columns;
    }

    render() {
        let isGridBusy = this.props.isLoading;

        return (
            <Grid container>
                <Grid item xs={12} id={'ItemsGridContainer'}>
                    <AMWGrid
                        sticky={true}
                        scrollable={'scrollable'}

                        data={this.props.items}
                        total={this.props.count}

                        pageable={{ buttonCount: 4, pageSizes: true }}
                        skip={this.props.skip}
                        pageSize={this.props.pageSize}
                        onPageChange={this.onPageChange.bind(this)}

                        sortable={true}
                        sort={this.props.sorting}
                        onSortChange={this.onSortChange.bind(this)}

                        reorderable={true}
                        onColumnReorder={this.onColumnReorder.bind(this)}

                        resizable={true}
                        onColumnResize={this.onColumnResize.bind(this)}

                        onRowDoubleClick={this.onRowDoubleClick.bind(this)}
                    >
                        <GridToolbar>
                            <InventoryItemsToolbar
                                isGridBusy={isGridBusy}
                                filters={this.props.filters}
                                requestData={(filters) => {
                                    this.props.requestItems(filters, this.props.skip, this.props.pageSize, this.props.sorting);
                                }}
                            />
                        </GridToolbar>

                        {this.columns}
                    </AMWGrid>
                </Grid>
            </Grid>
        );
    }
}
registerForLocalization(InventoryItemsTab);

export default connect(
    (state: ApplicationState) : InventoryItemsStateProps => {
        return {
            pageKey: UserPreferencePage.InventoryItems,
            ...state.inventory?.items,
            ...state.screen,
            filters: state.user?.userData?.preferences[`${UserPreferencePage.InventoryItems}-${UserPreferenceKey.Filters}`] ?? {},
            pageSize: state.user?.userData?.preferences[`${UserPreferencePage.InventoryItems}-${UserPreferenceKey.PageSize}`] ?? 10,
            sorting: state.user?.userData?.preferences[`${UserPreferencePage.InventoryItems}-${UserPreferenceKey.Sorting}`] ?? [],
            columnOrder: state.user?.userData?.preferences[`${UserPreferencePage.InventoryItems}-${UserPreferenceKey.Order}`] ?? {},
            columnWidth: state.user?.userData?.preferences[`${UserPreferencePage.InventoryItems}-${UserPreferenceKey.ColumnWidth}`] ?? {},
        };
    },
    (dispatch) : InventoryItemsDispatchProps => {
        return {
            ...bindActionCreators({...InventoryStore.actionCreators}, dispatch),
            ...bindActionCreators({...NavigationStore.actionCreators}, dispatch),
            ...UsersStore.actionCreators,
        };
    },
)(InventoryItemsTab as any);